<template>
    <search-item
        :name="item.name"
        :item="item"
        :type="SearchType.Discipline"
        :item-page-url="disciplinePageUrl"
        @on-item-selected="$emit('on-item-selected')"
    >
        <template #avatar>
            <discipline-avatar
                :photo="item.photoUrl"
                :isArchive="item.isArchive"
                :isActive="item.isCurrent"
            />
        </template>
        <template #info>
            <div class="info">{{ item.programName }} • {{ item.cohortName }}</div>
        </template>
        <template #append><slot name="append"></slot></template>
    </search-item>
</template>

<script lang="ts" setup>
    import {
        RoutePageNameEnum,
        SearchDisciplineDto,
        SearchType,
    } from 'src/api/ApiClient';
    import { Common } from 'src/helpers/Common';
    import { useGetEntityPageUrl } from '../../hooks/useGetEntityPageUrl';
    import SearchItem from '../SearchItem.vue';
    // Компонент для отображение данных о найденной дисциплине

    const props = defineProps<{
        // Данные отображаемого элемента поиска
        item: SearchDisciplineDto;
    }>();
    defineEmits<{
        (e: 'on-item-selected'): void;
    }>();
    const {
        getEntityPageUrl: getDisciplinePageUrl,
    } = useGetEntityPageUrl(Common.getRouteName(RoutePageNameEnum.DisciplineInfo));

    // Ссылка на страницу дисциплины
    const disciplinePageUrl = getDisciplinePageUrl(props.item.id);
</script>
