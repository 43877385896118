<template>
    <div class="participants">
        <ParticipantsImage class="participants__picture" />
        <h1 class="participants__title page-title text-white">
            {{ localize('Ожидаем участников видеоконференции') }}
        </h1>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { localize } from 'src/services/LocalizationService/LocalizationService';

    // Заглушка для страницы
    // участников звонка
    export default defineComponent({
        name: 'ParticipantsNoitems',
        setup() {
            return { localize };
        },
    });
</script>

<style lang="scss">
.participants {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    align-items: center;
    height: 100vh;
    justify-content: center;

    &__picture {
        width: 100%;
        max-width: 660px;
    }

    &__title {
        text-align: center;
        max-width: 323px;
        margin-top: 32px;
    }
}
</style>
