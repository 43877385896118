<template>
    <div ref="messagesBlockRef" class="content-container">
        <q-card
            v-if="isHasBeenInitialized && !isLoading && isActivitySolutionChat && !hasMessages"
            class="empty-solution-chat absolute-center"
        >
            <q-card-section>
                {{
                    localize('Здесь вы можете обсудить решение. Также здесь будет отмечаться история изменения решения.')
                }}
            </q-card-section>
        </q-card>
        <q-card
            v-if="isHasBeenInitialized && !isLoading && isSelfChat && !hasMessages"
            class="empty-self-chat absolute-center"
        >
            <q-card-section class="flex justify-center">
                <NoteIcon class="q-mb-xl" aria-hidden="true" />
                <span class="text-center">
                    {{ localize('Создай свою первую заметку! Конспектируй, отмечай важное, записывай свои мысли') }}
                </span>
            </q-card-section>
        </q-card>
        <div
            v-if="messages.length"
            ref="floatMessageDateBlockRef"
            key="float-message-date-block"
            class="float-message-date-block"
        >
            {{ floatFormattedDate }}
        </div>
        <q-scroll-area
            ref="scrollAreaRef"
            class="scroll-container q-pa-md"
            :style="{ height: isSearchInMobileChat ? 'calc(100% - 56px)' : '100%' }"
            @scroll="onScroll"
        >
            <MessageItem
                v-if="mainMessageInThread && !isLoading"
                :message="mainMessageInThread"
                :is-thread-chat="true"
                :is-main-message-in-thread-chat="true"
                :is-archive-chat="isArchiveChat"
                :chat-info="chatInfo"
                :is-chat-in-muted-section-or-discipline="isChatInMutedSectionOrDiscipline"
                :mode-chat="modeChat"
                :is-mobile-view="isMobileView"
                @scroll-to-answer-message="$emit('scroll-mo-main-message')"
                @on-update-message="updateMessage"
                class="main-message"
            />
            <div v-if="isLoading" class="text-center q-mt-xl">
                <q-spinner-dots color="primary" size="4em" />
            </div>
            <template v-else-if="chatInfo">
                <div v-if="isLoadingUpMessages" class="text-center q-mt-md">
                    <q-spinner-dots color="primary" size="4em" />
                </div>
                <template v-for="(message, index) in messages" :key="'message' + message.id">
                    <div
                        v-if="isShowMessageSeparator(index)"
                        :key="index"
                        class="message-item-separator q-mt-md text-center"
                    >
                        {{ localize('Новые сообщения') }}
                    </div>
                    <div
                        v-if="isShowDateSeparator(index)"
                        :key="message.id + 'date'"
                        :data-id="message.id"
                        class="message-date-separator q-mt-md text-center"
                    >
                        <div>{{ getFormattedDate(message.createDateTime) }}</div>
                    </div>
                    <MessageItem
                        :ref="messageItemRefPrefix + message.id"
                        :message="message"
                        :id="'message' + message.id"
                        :is-archive-chat="isArchiveChat"
                        :is-activity-solution-chat="isActivitySolutionChat"
                        :is-thread-chat="isThreadChat"
                        :chat-info="chatInfo"
                        :mode-chat="modeChat"
                        :is-mobile-view="isMobileView"
                        @on-send-message-error="setErrorMessage"
                        @scroll-to-answer-message="scrollToAnswerMessage"
                        @on-update-message="updateMessage"
                        @select-emoji="selectEmoji"
                    />
                </template>
                <div v-if="isLoadingDownMessages" class="text-center q-mt-md">
                    <q-spinner-dots color="primary" size="4em" />
                </div>
                <div
                    ref="scrollMessagesToDownRef"
                    v-if="(countUnreadedMessages || newUnreadedMessages.length) && currentScrollPercent < 1"
                    class="scroll-to-down-button"
                    @click="scrollMessagesToDown"
                    :style="{
                        right: !isThreadChat && hasMainMessageInThread ? '330px' : '8px',
                        bottom: isInlineModeChat ? '110px' : '68px'
                    }"
                >
                    <div class="count-unreaded-messages">{{ countUnreadedMessagesForDownButton }}</div>
                    <q-icon name="keyboard_arrow_down" size="26px" />
                </div>
            </template>
        </q-scroll-area>
        <div
            v-if="isSearchInMobileChat"
            key="search-navigation"
            class="search-navigation q-px-md flex items-center justify-between"
        >
            <div v-if="searchedMessageIds?.length" class="text-shade-8">
                {{ localize('Найдено:') }}
                {{ currentSearchedMessageIndex + 1 }}
                {{ localize('из') }}
                {{ searchedMessageIds.length }}
            </div>
            <div v-else class="text-shade-8">
                {{ localize('Нет результатов') }}
            </div>
            <div>
                <q-icon
                    name="keyboard_arrow_up"
                    @click="nextSearchedMessage"
                    :disabled="!searchedMessageIds?.length"
                    size="22px"
                    :color="searchedMessageIds && currentSearchedMessageIndex !== searchedMessageIds.length - 1 ? 'grey-7' : ''"
                    class="cursor-pointer q-mr-sm"
                />
                <q-icon
                    name="keyboard_arrow_down"
                    @click="prevSearchedMessage"
                    :disabled="!searchedMessageIds?.length"
                    size="22px"
                    :color="currentSearchedMessageIndex !== 0 ? 'grey-7' : ''"
                    class="cursor-pointer"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {
        CallMessageDto,
        ChatBaseInfoResponseModel,
        ChatFileDto,
        ChatMessageClient,
        ChatMessageDto,
        ChatMessageReactionDto,
        ChatMessageType,
        ChatType,
        ChatUnreadedMessagesCountDto,
        RoutePageNameEnum,
        UserBaseInfoDto,
    } from 'src/api/ApiClient';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { IChatMessage } from 'components/Chat/types/interfaces';
    import MessageItem from './MessageItem.vue';
    import { date, debounce, QScrollArea } from 'quasar';
    import { localize } from 'src/services/LocalizationService';
    import { Common } from 'src/helpers/Common';
    import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, PropType, ref, watch } from 'vue';
    import _, { minBy } from 'lodash';
    import DateUtil from 'src/helpers/DateUtil';
    import { IErrorMessageDto } from 'src/types/generated/hubs/chatPartialHub/models/IErrorMessageDto';
    import { ChatPartialHub } from 'src/services/hubs/ChatPartialHub';
    import { useRoute } from 'vue-router';
    import { ModeChat } from 'components/Chat/types/enums';
    import { useAccountStore } from 'src/store/module-account';
    import { useMainLayoutStore } from 'src/store/module-main-layout';
    import { useChatStore } from 'src/store/module-chat';
    import { useCallStore } from 'src/store/module-call';
    import { LiveDataPartialHub } from 'src/services/hubs/LiveDataPartialHub';
    import { ILiveDataMessageCallMembers } from 'src/types/generated/hubs/liveDataPartialHub/models/ILiveDataMessageCallMembers';
    import { ILiveDataUserInfo } from 'src/types/generated/hubs/liveDataPartialHub/models/ILiveDataUserInfo';
    import { LiveDataUtil } from 'src/helpers/LiveDataUtil';

    export default defineComponent({
        name: 'MessagesBlock',

        components: {
            MessageItem,
        },

        emits: ['scroll-mo-main-message'],

        props: {
            // Модель базовой информации о чате
            chatInfo: {
                type: Object as PropType<ChatBaseInfoResponseModel | undefined | null>,
                default: undefined,
            },
            // Главное сообщение обсуждения, т.е. то, которое обсуждают
            mainMessageInThread: {
                type: Object as PropType<IChatMessage | undefined | null>,
                default: undefined,
            },
            // Архивный чат, показывается в левой панели на странице чатов
            isArchiveChat: {
                type: Boolean,
                default: false,
            },
            // Является ли чат чатом обсуждения
            isThreadChat: {
                type: Boolean,
                default: false,
            },
            // Является ли чат чатом решения
            isActivitySolutionChat: {
                type: Boolean,
                default: false,
            },
            // Мобильный вид чата
            isMobileView: {
                type: Boolean,
                default: false
            },
            //Замьючена ли чат секция или дисциплина, в которой находится чат
            isChatInMutedSectionOrDiscipline: {
                type: Boolean,
                required: false
            },
            // Режим отображения чата
            modeChat: {
                type: String,
                default: ModeChat.Inline
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props) {
            const app = getCurrentInstance();
            const messagesBlockRef = ref<HTMLElement>();
            const floatMessageDateBlockRef = ref<HTMLElement>();
            const scrollMessagesToDownRef = ref<HTMLElement>();
            const scrollAreaRef = ref<QScrollArea>();

            const $route = useRoute();
            const chatStore = useChatStore();
            const mainLayoutStore = useMainLayoutStore();
            const accountStore = useAccountStore();
            const callStore = useCallStore();

            const messages = ref<IChatMessage[]>([]);
            const messageItemRefPrefix = 'messageItem';
            const isLoading = ref<boolean>(true);
            const isLoadingUpMessages = ref<boolean>(false);
            const isLoadingDownMessages = ref<boolean>(false);

            // Идет ли анимация скрытия/раскрытия чата
            let isOpenAnimating: boolean = false;

            let isCanLoadDownMessages: boolean = false;
            let isCanLoadUpMessages: boolean = false;

            // Дата для плавающего блока
            const floatFormattedDate = ref<string | null>(null);
            let floatBlockAnimationFadeTimeoutId: ReturnType<typeof setTimeout> | null = null;

            // Признак, что производилось получение сообщений, чтобы не отображать заглушку об отсутствии сообщений до результата их запроса
            const isHasBeenInitialized = ref<boolean>(false);

            // id последнего прочитанного сообщения, которое мы получили из запроса
            let lastReadMessageId: number = 0;

            // id последнего прочитанного сообщения на данный момент
            // это поле мы будем обновлять,
            // а если мы будем обновлять lastReadMessageId то плашка
            // "новые сообщения" будет скакать
            const currentLastReadMessageId = ref<number>(0);

            // предыдущий id последнего прочитанного сообщения
            // нужен, что бы вычислять количество прочитанных
            let previousLastReadMessageId: number = 0;

            // на сколько прокручена область в данный момент в процентах
            const currentScrollPercent = ref<number>(0);

            // на сколько прокручена область в данный момент в пикселях
            let currentScrollPixels: number = 0;

            // используется при навигации по найденным сообщениямв мобильном поиске
            const currentSearchedMessageIndex = ref<number>(0);

            const readMessageOnScroll: () => void = debounce(() => {
                checkUnreadedMessagesOnScroll();
            }, 500);

            const isSelfChat = computed<boolean>(() => {
                return props.chatInfo ? props.chatInfo.type === ChatType.Self : false;
            });

            const chatId = computed<number>(() => {
                return props.chatInfo ? props.chatInfo.id : 0;
            });

            const currentUserId = computed<number>(() => {
                return accountStore.getAccountInfo?.id ?? 0;
            });

            const isSearchInMobileChat = computed<boolean>(() => {
                return searchedMessageIds.value !== null;
            });

            const searchedMessageIds = computed<number[] | null>(() => {
                return chatStore.searchedMessageIds;
            });

            const newMessageBlockHeight = computed<number>(() => {
                return chatStore.newMessageBlockHeight;
            });

            const hasNewMessages = computed<boolean>(() => {
                if (currentLastReadMessageId.value) {
                    return newUnreadedMessages.value.length > 0;
                } else {
                    return false;
                }
            });

            const newUnreadedMessages = computed<IChatMessage[]>(() => {
                return messages.value.filter((x: ChatMessageDto) => {
                    return !isMyMessage(x) && x.id! > currentLastReadMessageId.value;
                });
            });

            const hasMessages = computed<boolean>(() => {
                return messages.value.filter((message: IChatMessage) => !message.isDeleted).length > 0;
            });

            const isInlineModeChat = computed<boolean>(() => {
                return props.isMobileView || props.modeChat === ModeChat.Inline;
            });

            // Если есть количество непрочитанных сообщений в сторе - показываем его
            // Если его нет, выводим количество непрочитанных сообщений, которое вычисляем из массива messages
            // Складывать эти значения нельзя, иначе будет дублирование непрочитанных сообщений
            const countUnreadedMessagesForDownButton = computed<number>(() => {
                if (countUnreadedMessages.value) {
                    return countUnreadedMessages.value;
                }

                return newUnreadedMessages.value.length;
            });

            const countUnreadedMessages = computed<number>(() => {
                let count = 0;
                const ureadedMessages: ChatUnreadedMessagesCountDto[] = chatStore.unreadedMessagesCount;

                for (let i = 0; i < ureadedMessages.length; i++) {
                    if (ureadedMessages[i].chatId === chatId.value) {
                        count += ureadedMessages[i].count;
                    }
                }

                return count;
            });

            // Открыт ли чат обсуждения
            const hasMainMessageInThread = computed<boolean>(() => {
                return !!chatStore.mainMessageInThread;
            });

            const isVisibleChat = computed<boolean>(() => {
                let isChatVisible;

                // На странице чатов и решения чат виден всегда
                if ($route.name === Common.getRouteName(RoutePageNameEnum.Chat) ||
                    $route.name === Common.getRouteName(RoutePageNameEnum.ActivitySolution)) {
                    isChatVisible = true;
                } else {
                    // Открыт ли чат на странице звонка
                    if ($route.name === Common.getRouteName(RoutePageNameEnum.CallEnter)) {
                        isChatVisible = callStore.chatIsVisible;
                    } else {
                        // Открыт ли миничат на странице
                        isChatVisible = mainLayoutStore.isVisibleChatBlock;
                    }
                }

                return isChatVisible;
            });

            const chatInfoComp = computed<ChatBaseInfoResponseModel | undefined | null>(() => {
                return props.chatInfo;
            });

            watch(chatInfoComp, () => {
                // При переключении чата, считаем что сообщения еще не были загружены
                isHasBeenInitialized.value = false;
                messages.value = [];
            });

            watch(searchedMessageIds, (values: number[] | null) => {
                currentSearchedMessageIndex.value = 0;

                if (values && values.length) {
                    setMessageToView(values[currentSearchedMessageIndex.value]);
                }
            });

            // Делаем первоначальную проверку для блока после загрузки и ререндера сообщений
            watch(isLoading, () => {
                nextTick(() => {
                    setFloatMessageBlock(false);
                });
            });

            watch(newMessageBlockHeight, (value: number) => {
                if (scrollMessagesToDownRef.value) {
                    // У кнопки отступ от низа экрана 68px на странице чатов
                    // А в чате, где кнопки под текстовым сообщением - 110 пикселей
                    const bottomValue = isInlineModeChat.value ? 110 : 68;

                    if (value > bottomValue) {
                        // добавляем 10 пикселей чтобы кнопка не вплотную прилипала к блоку с сообщением
                        value += 10;
                        // опускаем кнопку вниз
                        scrollMessagesToDownRef.value.style.bottom = value + 'px';
                    } else {
                        // выставляем первоначальное значение
                        scrollMessagesToDownRef.value.style.bottom = bottomValue + 'px';
                    }
                }
            });

            // Обработка ситуации, когда в закрытый активный чат приходят сообщения
            // что проскролить в начало непрочитанных
            watch(isVisibleChat, (isVisible: boolean) => {
                isOpenAnimating = true;

                if (isVisible) {
                    // ставим задержку на случай, если отрабатывает какая-то анимация
                    setTimeout(() => {
                        const count = checkUnreadedMessages();

                        // Если нет новых сообщений скролим просто в самый низ
                        if (!count && messages.value.length) {
                            scrollToMessage(messages.value[messages.value.length - 1].id!);
                        }
                    }, 500);
                }

                setTimeout(() => {
                    isOpenAnimating = false;
                }, 600);
            });

            // Вызывается когда добавлен emoji
            function selectEmoji(): void {
                const scrollPosition = scrollAreaRef.value?.getScrollPosition();
                const getScroll: number = (scrollPosition?.top ?? 0) + 26;
                scrollAreaRef.value?.setScrollPosition('vertical', getScroll, 100);
            }

            // Вызывается из родительского компонента для получения уровня прокрутки
            function getScrollPercent(): number {
                return currentScrollPercent.value;
            }

            function isMyMessage(message: ChatMessageDto): boolean {
                return message.author.id === currentUserId.value && message.type !== ChatMessageType.Service;
            }

            function isShowMessageSeparator(index: number): boolean {
                // this.messages[index - 1].id - здесь -1
                // потому что последнее прочитанное сообщение
                // это предыдущее от первого непрочитанного
                return (
                    messages.value[index - 1] &&
                    !isMyMessage(messages.value[index]) &&
                    lastReadMessageId === messages.value[index - 1].id &&
                    lastReadMessageId !== messages.value[messages.value.length - 1].id
                );
            }

            // Нужно ли показывать разделитель в виде даты,
            // Нужно если отличаются даты у соседних сообщений
            function isShowDateSeparator(index: number): boolean {
                const prevMessage = messages.value[index - 1];
                const currentMessage = messages.value[index];

                if (prevMessage) {
                    const prevMessageDate = prevMessage.createDateTime.split('T')[0];
                    const currentMessageDate = currentMessage.createDateTime.split('T')[0];
                    return prevMessageDate !== currentMessageDate;
                } else {
                    // Так же показываем дату для самого первого сообщения
                    return index === 0;
                }
            }

            function getFormattedDate(dateString: string): string {
                return DateUtil.getSinceDate(dateString);
            }

            function onScroll({ verticalPercentage, verticalPosition, verticalSize }: { verticalPercentage: number, verticalPosition: number, verticalSize: number }): void {
                // Если чат скрыт или идет анимация открытия чата, ни чего не делаем
                if (!isVisibleChat.value || isOpenAnimating) {
                    return;
                }

                const isUpDirection = verticalPercentage <= currentScrollPercent.value;
                currentScrollPercent.value = verticalPercentage;

                setFloatMessageBlock(verticalPosition <= currentScrollPixels, verticalPosition);
                currentScrollPixels = verticalPosition;

                //отключаем пагинацию при мобильном поиске
                if (!isSearchInMobileChat.value) {
                    // скролим вниз
                    if (!isUpDirection && verticalPercentage > 0.9) {
                        loadDownMessages();
                    }

                    // когда скролим вверх, берем процент не от области прокрутки, а от размера скрола
                    // тк увеличении кол-ва сообщений размера скрола увеличивается
                    if (isUpDirection && ((verticalPosition * 100) / verticalSize < 0.09)) {
                        loadUpMessages();
                    }

                    readMessageOnScroll();
                }

                messages.value.forEach(({ id }) => {
                    const refKey = messageItemRefPrefix + id;

                    if (app?.refs[refKey]) {
                        const [messageRef] = app?.refs[refKey] as InstanceType<typeof MessageItem>[];

                        if (messageRef) {
                            messageRef.hideMenu();
                        }
                    }
                });
            }

            /**
             * Проскролить до нужного сообщения
             * @param messageId - id сообщения, до которого нужно проскролить
             * @param isHighlight - нужно подсветить сообщние
             * @param isScrollToDown - сколим ли мы в самый низ
             */
            function scrollToMessage(messageId: number, isHighlight?: boolean, isScrollToDown?: boolean): void {
                if (!messagesBlockRef.value) {
                    return;
                }

                const messageElements: NodeListOf<HTMLDivElement> = messagesBlockRef.value.querySelectorAll('.message-block');

                let topMessagesHeight = 0;
                let messageEl: HTMLDivElement | null = null;

                // складываем высоту всех сообщений, которые находятся сверху
                // нашего message - это количество пикселей
                // на которое надо проскролить
                for (let i = 0; i < messageElements.length; i++) {
                    if (Number(messageElements[i].dataset.id) === messageId) {
                        messageEl = messageElements[i];

                        // Если не скролим вниз, то не учитвыем высоту сообщений
                        // ниже нужно messageId и его тоже
                        if (!isScrollToDown) {
                            break;
                        }
                    }

                    topMessagesHeight += messageElements[i].clientHeight;
                }

                // ещё 1px - это элемент message-observer, который есть в каждом сообщении
                // поэтому добавляем такое же количество пикселей
                topMessagesHeight += messageElements.length;
                topMessagesHeight += 16; // 16 - нижний padding у блока прокрутки

                const separatorEl: HTMLDivElement | null = messagesBlockRef.value?.querySelector('.message-item-separator');

                if (separatorEl) {
                    if (isScrollToDown) {
                        // Если скролим в самый низ, то учитываем плашку 'Новые сообщения'
                        topMessagesHeight += separatorEl.offsetHeight;
                    } else {
                        // Иначе мы скроли до неё
                        topMessagesHeight -= separatorEl.offsetHeight;
                    }
                }

                const dateSeparatorElements: NodeListOf<HTMLDivElement> = messagesBlockRef.value.querySelectorAll('.message-date-separator');

                // Если передан messageId, то учитываем только те элементы с датами
                // которые находятся выше сообщения, к которому скролим
                for (let i = 0; i < dateSeparatorElements.length; i++) {
                    if (messageId) {
                        if (Number(dateSeparatorElements[i].dataset.id) < messageId) {
                            topMessagesHeight += dateSeparatorElements[i].clientHeight;
                        }
                    } else {
                        topMessagesHeight += dateSeparatorElements[i].clientHeight;
                    }
                }

                scrollAreaRef.value?.setScrollPosition('vertical', topMessagesHeight, 0);

                if (scrollAreaRef.value && topMessagesHeight < scrollAreaRef.value.getScrollTarget().scrollHeight) {
                    // Вызываем функцию прочтения сообщения на случай
                    // если сообщений в чате мало и они все умещаются в область прокрутки
                    // тк тогда событие onScroll не будет вызываться
                    readMessageOnScroll();
                }

                if (messageEl && isHighlight) {
                    messageEl.classList.add('highlight');

                    setTimeout(function () {
                        messageEl?.classList.remove('highlight');
                    }, 1000);
                }
            }

            function prepareLoadedMessages(messageItems: ChatMessageDto[]): IChatMessage[] {
                return messageItems
                    .sort((a: ChatMessageDto, b: ChatMessageDto) => {
                        return new Date(a.createDateTime).getTime() - new Date(b.createDateTime).getTime();
                    })
                    .map((x: ChatMessageDto) => {
                        return {
                            ...x,
                            call: setDefaultFields(x.call),
                            isMyMessage: isMyMessage(x),
                            messageReactions: x.messageReactions || [],
                            forcedPushNotificationUserIds: [],
                        };
                    });
            }

            // загружаем сообщения, которые выше (раньше) текущих
            // происходит когда мы сролим вверх
            async function loadUpMessages(): Promise<void> {
                if (!chatId.value || !messages.value.length || isLoadingUpMessages.value || !isCanLoadUpMessages) {
                    return;
                }

                isLoadingUpMessages.value = true;

                const result = await new ChatMessageClient(getApiClientInitialParams()).getMessages(
                    chatId.value,
                    null,
                    messages.value[0].id,
                );

                if (result.isSuccess) {
                    const messageItems = prepareLoadedMessages(result.entity.messages);

                    messageItems.reverse().forEach((x: IChatMessage) => {
                        messages.value.unshift(x);
                    });

                    isCanLoadUpMessages = !!result.entity.hasPrevPage;
                }

                const currentScrollHeight = scrollAreaRef.value?.getScrollTarget().scrollHeight ?? 0;

                // ставим небольшую задержку, что бы сообщения отрисовались
                // и можно было корректно проскролить
                // $nextTick не помогает
                setTimeout(() => {
                    // скролим до последнего верхнего прочитаннаго сообщения
                    scrollAreaRef.value?.setScrollPosition(
                        'vertical',
                        scrollAreaRef.value?.getScrollTarget().scrollHeight - currentScrollHeight,
                        0,
                    );

                    // ставим небольшую задержку, потому setScrollPosition
                    // вызывает событие onScroll и посылаются не нужные запросы
                    setTimeout(() => {
                        isLoadingUpMessages.value = false;
                    }, 100);
                }, 100);
            }

            // загружаем сообщения, которые ниже (позже) текущих
            // происходит когда мы сролим вниз
            async function loadDownMessages(): Promise<void> {
                if (!chatId.value || !messages.value.length || isLoadingDownMessages.value || !isCanLoadDownMessages) {
                    return;
                }

                const lastDownMessage = messages.value[messages.value.length - 1];

                if (lastDownMessage.id! < 0) {
                    return;
                }

                isLoadingDownMessages.value = true;

                const result = await new ChatMessageClient(getApiClientInitialParams()).getMessages(
                    chatId.value,
                    lastDownMessage.id,
                );

                if (result.isSuccess) {
                    const messageItems = prepareLoadedMessages(result.entity.messages);

                    messageItems.forEach((x: IChatMessage) => {
                        messages.value.push(x);
                    });

                    isCanLoadDownMessages = !!result.entity.hasNextPage;
                }

                // ставим небольшую задержку, потому что onScroll может отработать еще раз
                // и могут послаться не нужные запросы
                setTimeout(() => {
                    isLoadingDownMessages.value = false;
                }, 100);
            }

            // Эта функция вызывается из родительского компонента,
            // Что бы инициализировать чат для переданных параметров
            async function setMessageToView(messageId?: number | null, scrollToDown: boolean = false): Promise<void> {
                if (!chatId.value) {
                    return;
                }

                isLoading.value = true;
                isCanLoadUpMessages = false;
                isLoadingUpMessages.value = false;
                isCanLoadDownMessages = false;
                isLoadingDownMessages.value = false;
                messages.value = [];

                if (scrollToDown) {
                    lastReadMessageId = currentLastReadMessageId.value;
                } else {
                    setLastReadId();
                }

                const result = await new ChatMessageClient(getApiClientInitialParams()).getMessages(
                    chatId.value,
                    undefined,
                    undefined,
                    scrollToDown,
                    messageId,
                );

                if (result.isSuccess) {
                    messages.value = prepareLoadedMessages(result.entity.messages);

                    // Выставляем признак, что данные чата были загружены
                    // (чтобы не отображать заглушку об отсутствии сообщений, пока они еще не загружены)
                    isHasBeenInitialized.value = true;

                    // ставим небольшую задержку, что бы сообщения отрисовались
                    // и можно было корректно проскролить
                    // $nextTick не помогает
                    setTimeout(() => {
                        if (messageId) {
                            // если передали сообщение, то скролим до него
                            scrollToMessage(messageId, true);
                        } else {
                            // скролим в самый низ в начало чата
                            if (messages.value.length && isNeedScrollMessages()) {
                                scrollToMessage(messages.value[messages.value.length - 1].id!, false, true);
                            }
                        }

                        if (!scrollToDown && !messageId) {
                            checkUnreadedMessages();
                        }

                        // ставим небольшую задержку, потому setScrollPosition
                        // вызывает событие onScroll и посылаются не нужные запросы
                        setTimeout(() => {
                            isCanLoadUpMessages = !!result.entity.hasPrevPage;
                            isCanLoadDownMessages = !!result.entity.hasNextPage;
                        }, 500);
                    }, 100);
                } else {
                    NotifyErrors(result);
                }

                isLoading.value = false;
            }

            // Обработчик удаления файла из сообщения
            function onMessageFileDeleted(file: ChatFileDto): void {
                const message = messages.value.find((x: IChatMessage) => x.id === file.chatMessageId);

                if (message && message.files) {
                    const fileIndex = message.files.findIndex((x: ChatFileDto) => x.id === file.id);

                    if (fileIndex !== -1) {
                        message.files.splice(fileIndex, 1);

                        // Если в сообщении больше ничего нет - удаляем его
                        if (message.files.length === 0 && !!message.text === false) {
                            messages.value.splice(messages.value.indexOf(message), 1);
                        }
                    }
                }
            }

            // функция вызывается когда мы в сообщениях
            // кликаем по сообщению, на которое есть ответ,
            // чтобы прокрутить к тому сообщению
            // на которое кто-то ответил
            function scrollToAnswerMessage(message: ChatMessageDto): void {
                // переиспользуем функцию с текущеми параметрами
                setMessageToView(message.id);
            }

            // функция загружает последние сообщения
            function scrollMessagesToDown(): void {
                if (countUnreadedMessages.value || newUnreadedMessages.value.length) {
                    // переиспользуем функцию с текущими параметрами
                    setMessageToView(undefined, true);
                }
            }

            // Эта функция вызывается из родительского компонента,
            // Из обработчика onMessageReceivedHandler
            // Что бы добавить сообщение
            function addMessage(message: ChatMessageDto): void {
                // Делаем проверку на случай, если сообщение с таким id уже есть в чате (ODIN-7695, ODIN-8765)
                // Дублирование бывает, когда создается чат решения или обсуждения,
                // при этом происходит запрос на получение сообщений и после этого прилетает сообщение из хаба о сообщении
                if (message.id !== -1 && messages.value.find((m: ChatMessageDto) => m.id === message.id)) {
                    return;
                }

                if (message.call) {
                    message.call = setDefaultFields(message.call);
                }

                // мы не добавляем сообщения если мы можем загрузить ещё,
                // то есть если есть в пагинации следующая страница,
                // иначе эти сообщения будут в неправильном месте
                if (!isCanLoadDownMessages) {
                    const newMessage = {
                        ...message,
                        isMyMessage: isMyMessage(message),
                    };

                    /*
                    * Если существует дата отправки нового сообщения,
                    * то переводим его к часовому поясу пользователя
                    * */
                    if (!!newMessage.createDateTime) {
                        const userTimeZoneOffset = accountStore.getAccountInfo?.timeZoneOffsetMinutes;
                        newMessage.createDateTime = date.addToDate(newMessage.createDateTime, { minutes: userTimeZoneOffset }).toServerFormat(true);
                    }

                    // если это наше сообщение, то есть два варианта
                    // либо это новое сообщение, которое мы добавили сразу перед отправкой в хаб,
                    // либо это наше сообщение, которое уже пришло из хаба
                    if (newMessage.isMyMessage) {
                        // если это новое сообщение, то сначала ищем
                        // нет ли уже такого, и если есть меняем id
                        if (newMessage.id === -1) {
                            const newMes = minBy(messages.value, 'id');

                            // у сообщений, которые добавлены в чат, но не созданы - отрицательные id
                            if (newMes && newMes.id! < 0) {
                                newMessage.id = newMes.id! - 1;
                            }

                            messages.value.push(newMessage);
                        } else {
                            const newMesAdded = messages.value.find((x: ChatMessageDto) => x.frontendId === newMessage.frontendId);

                            // если есть новое добавленное, то обновляем его
                            if (newMessage.type !== ChatMessageType.Call && newMesAdded) {
                                const index = messages.value.indexOf(newMesAdded);

                                // Если у сообщения есть ошибка и нам пришло новое сообщение с таким же frontendId
                                // значит его отправили по кнопке "Отправить повторно" - в этом случаем мы должны удалить
                                // это неотправленное сообщение, а новое добавить в конец, как самое последнее сообщение
                                // чтобы оно правильно отображалось в истории, тк неотправленные сообщения у нас ни где не сохраняются
                                if (newMesAdded.error) {
                                    if (index !== -1) {
                                        messages.value.splice(index, 1);
                                    }

                                    messages.value.push(newMessage);
                                } else {
                                    // обновляем сообщение данными, которые пришли по сокетам
                                    if (index !== -1) {
                                        messages.value.splice(index, 1, newMessage);
                                    }
                                }
                            } else {
                                messages.value.push(newMessage);
                            }
                        }
                    } else {
                        messages.value.push(newMessage);
                    }

                    // Вызываем функцию прочтения сообщения на случай
                    // если сообщений в чате мало и они все умещаются в область прокрутки
                    // тк тогда событие onScroll не будет вызываться
                    if (currentScrollPercent.value === 0) {
                        readMessageOnScroll();
                    }

                    scrollOnAddMessage(newMessage);
                }
            }

            // Проверяет, нужно ли скролить сообщения
            function isNeedScrollMessages(): boolean {
                // Проверяем видимость чата на странице,
                // если он закрыт, то скролл будет при раскрытии чата в вотчере на isVisibleChat
                // в противном случае чат будет скролиться сразу
                return isVisibleChat.value;
            }

            function scrollOnAddMessage(message: IChatMessage): void {
                if (!isNeedScrollMessages()) {
                    return;
                }

                if (message.isMyMessage) {
                    // если это наше сообщение, то мы всегда прокручиваем вниз
                    setTimeout(() => {
                        scrollToMessage(message.id!);
                    }, 100);
                } else {
                    // если сообщение пришло нам от кого-то
                    // и если пользователь не находится где-то вверху,
                    // то прокручиваем до нового сообщения
                    if (currentScrollPercent.value > 0.95) {
                        setTimeout(() => {
                            scrollToMessage(message.id!);
                        }, 100);
                    }
                }
            }

            // Обновляем количество сообщения в обсуждении при удалении сообщения
            function removeMessageFromDiscussion(messageInDiscussion: ChatMessageDto): void {
                const message = messages.value.find((x: ChatMessageDto) => x.id === messageInDiscussion.parentMessageId);

                if (message && message.discussion) {
                    message.discussion.countOfMessages -= 1;
                }
            }

            // Обновляем информацию об обсуждении в сообщении
            function updateDiscussionForMessage(messageInDiscussion: ChatMessageDto): void {
                const message = messages.value.find((x: ChatMessageDto) => x.id === messageInDiscussion.parentMessageId);

                if (message) {
                    // Если это первое сообщение, создаём объект с начальными данными
                    if (!message.discussion) {
                        message.discussion = {
                            id: messageInDiscussion.chatId,
                            countOfMessages: 1,
                            countOfUsers: 1,
                            lastMessageDateTime: messageInDiscussion.createDateTime,
                            users: [messageInDiscussion.author],
                            isDeleted: false,
                        };

                        // Обновляем так же сообщения для треда в сторе, если оно является в нём главным
                        const mainMessageInThread: IChatMessage | null = chatStore.mainMessageInThread;

                        if (mainMessageInThread && mainMessageInThread.id === message.id) {
                            const mainMessage = _.cloneDeep(mainMessageInThread);
                            mainMessage.discussion = _.cloneDeep(message.discussion);
                            chatStore.mainMessageInThread = mainMessage;
                        }
                    } else {
                        // Обновляем количество сообщений
                        message.discussion.countOfMessages += 1;
                        // Обновляем даты последнего сообщения
                        message.discussion.lastMessageDateTime = messageInDiscussion.createDateTime;

                        // Если пользователей меньше 3 (а в этом поле первые три возвращаются)
                        // То проверяем нет ли такого пользователя и добавлем его
                        if (message.discussion.users.length < 3) {
                            const even = message.discussion.users.find((x: UserBaseInfoDto) => x.id === messageInDiscussion.author.id);

                            if (!even) {
                                message.discussion.users.push(messageInDiscussion.author);
                                message.discussion.countOfUsers += 1;
                            }
                        } else if (message.discussion.countOfUsers === 3) {
                            // Если пользователей три, то просто проверяем, нет ли в них такого
                            const even = message.discussion.users.find((x: UserBaseInfoDto) => x.id === messageInDiscussion.author.id);
                            // и если нет, обновляем количество пользователей
                            if (!even) {
                                message.discussion.countOfUsers += 1;
                            }
                        }
                    }
                }
            }

            // Эта функция вызывается из родительского компонента,
            // Из обработчика onEditMessageReceivedHandler
            // Что бы обновить сообщение
            function updateMessage(message: ChatMessageDto): void {
                const index = messages.value.findIndex((x: ChatMessageDto) => x.id === message.id);
                const userTimezoneOffset = accountStore.getAccountInfo?.timeZoneOffsetMinutes;

                if (index !== -1) {
                    messages.value.splice(index, 1, {
                        ...message,
                        isMyMessage: isMyMessage(message),
                        createDateTime: date.addToDate(message.createDateTime, { minutes: userTimezoneOffset }).toServerFormat(),
                    });

                    // Обновляем так же сообщения для треда в сторе, если оно является в нём главным
                    const mainMessageInThread: IChatMessage | null = chatStore.mainMessageInThread;

                    if (mainMessageInThread && mainMessageInThread.id === message.id) {
                        const mainMessage = _.cloneDeep(mainMessageInThread);
                        mainMessage.text = message.text;
                        chatStore.mainMessageInThread = mainMessage;
                    }
                }
            }

            // Эта функция вызывается из родительского компонента,
            // Из обработчика onRemoveMessageReceivedHandler
            // Что бы удалить сообщение
            function removeMessage(message: ChatMessageDto): void {
                const index = messages.value.findIndex((x: ChatMessageDto) => x.id === message.id);

                //Поиск удаленных сообщений в отвеченных
                const indexAnswerToMessage = messages.value.filter((x: ChatMessageDto) => x.answerToMessage?.id === message.id);

                if (index !== -1) {
                    messages.value[index].isDeleted = true;
                    messages.value.splice(index, 1);
                }

                indexAnswerToMessage.forEach((i: ChatMessageDto) => {
                    i.answerToMessage!.isDeleted = true;
                });
            }

            // Эта функция вызывается из родительского компонента,
            // Из обработчика onSendMessageReactionReceivedHandler
            // Что бы обновить реакции
            function updateMessageReaction(reaction: ChatMessageReactionDto): void {
                const editedMessage = messages.value.find((x: ChatMessageDto) => x.id === reaction.messageId);

                if (editedMessage) {
                    if (!editedMessage.messageReactions) {
                        editedMessage.messageReactions = [];
                    }

                    editedMessage.messageReactions.push(reaction);
                    setTimeout(selectEmoji, 100);
                }

                // Обновляем так же сообщения для треда в сторе, если оно является в нём главным
                updateReactionInMainThreadMessage(reaction);
            }

            function updateReactionInMainThreadMessage(reaction: ChatMessageReactionDto): void {
                const mainMessageInThread: IChatMessage | null = chatStore.mainMessageInThread;

                if (mainMessageInThread && mainMessageInThread.id === reaction.messageId) {
                    const message = _.cloneDeep(mainMessageInThread);

                    if (!message.messageReactions) {
                        message.messageReactions = [];
                    }

                    message.messageReactions.push(reaction);
                    chatStore.mainMessageInThread = message;
                }
            }

            // Эта функция вызывается из родительского компонента,
            // Из обработчика removeMessageReaction
            // Что бы удалить реакции
            function removeMessageReaction(reaction: ChatMessageReactionDto): void {
                const editedMessage = messages.value.find((x: ChatMessageDto) => x.id === reaction.messageId);

                if (editedMessage && editedMessage.messageReactions) {
                    const index = editedMessage.messageReactions.findIndex(
                        (x: ChatMessageReactionDto) => x.id === reaction.id,
                    );

                    if (index !== -1) {
                        editedMessage.messageReactions.splice(index, 1);
                    }
                }

                // Обновляем так же сообщения для треда в сторе, если оно является в нём главным
                removeMessageReactionFromMainThreadMessage(reaction);
            }

            function removeMessageReactionFromMainThreadMessage(reaction: ChatMessageReactionDto): void {
                const mainMessageInThread: IChatMessage | null = chatStore.mainMessageInThread;

                if (mainMessageInThread && mainMessageInThread.id === reaction.messageId) {
                    const message = _.cloneDeep(mainMessageInThread);

                    const index = message.messageReactions?.findIndex((x: ChatMessageReactionDto) => x.id === reaction.id) ?? -1;

                    if (index !== -1) {
                        message.messageReactions?.splice(index, 1);
                    }

                    chatStore.mainMessageInThread = message;
                }
            }

            // Если произошла какая-то ошибка на сервере и сообщение не сохранилось,
            // то выводим ошибку, что сообщение не отправлено
            function setErrorMessage(data: IErrorMessageDto): void {
                const index = messages.value.findIndex((x: IChatMessage) => x.frontendId === data.frontendId);

                if (index !== -1) {
                    messages.value[index].isSending = false;
                    messages.value[index].error = data.error;
                }
            }

            // Эта функция вызывается из родительского компонента,
            // Из обработчика onUpdateReadedMessageHandler
            // Что бы обновить lastReadMessageId
            function setUpdateLastReadedMessage(messageId: number): void {
                if (messageId === lastReadMessageId) {
                    return;
                }

                let countReaded = 0;

                if (previousLastReadMessageId) {
                    if (messageId >= previousLastReadMessageId) {
                        const oldIndex = messages.value.findIndex(
                            (x: ChatMessageDto) => x.id === previousLastReadMessageId,
                        );
                        const newIndex = messages.value.findIndex((x: ChatMessageDto) => x.id === messageId);
                        countReaded = newIndex - oldIndex;
                    }

                    // читаем включительно по текущее сообщение
                    if (messageId === lastReadMessageId) {
                        countReaded++;
                    }
                } else {
                    // если нет this.lastReadMessageId, то скоре всего мы зашли сюда первый раз
                    // и прочитанные - это все до нового (а теперь текущего) lastReadMessageId
                    for (let i = 0; i < messages.value.length && messages.value[i].id! <= messageId; i++) {
                        countReaded++;
                    }
                }

                const lastMessage = messages.value[messages.value.length - 1];
                const isLastMessage = messageId === lastMessage.id;
                if (isLastMessage && countUnreadedMessages.value > 0) {
                    countReaded += countUnreadedMessages.value;
                }

                if (countReaded > 0) {
                    chatStore.decrementUnreadedCountMessage({
                        chatId: chatId.value,
                        count: countReaded,
                    });

                    // Отправляем сообщение в хаб, чтобы обновить счетчик непрочитанных во всех вкладках
                    (app?.appContext.config.globalProperties.$commonHub as ChatPartialHub)?.updateCountUnreadMessagesAsync(chatId.value, countReaded);
                }

                previousLastReadMessageId = messageId;

                // lastReadMessageId обновляем только если прочитали все сообщения
                // иначе плашка "новые сообщения" будет скакать
                if (isLastMessage) {
                    lastReadMessageId = messageId;
                }
            }

            function checkUnreadedMessages(): number {
                let unreadedMessages: ChatMessageDto[];

                // если есть lastReadMessageId
                // ищем непрочитанные
                if (lastReadMessageId) {
                    unreadedMessages = messages.value.filter((x: ChatMessageDto) => x.id! > lastReadMessageId);
                } else {
                    // если нет lastReadMessageId ,то все сообщения непрочитанные
                    unreadedMessages = messages.value;
                }

                if (unreadedMessages.length && isNeedScrollMessages()) {
                    // скролим до первого непрочитанного
                    scrollToMessage(unreadedMessages[0].id!);
                }

                return unreadedMessages.length;
            }

            // при скроле мы должны обновить последнее прочитанное сообщение
            // если есть непрочитанные
            function checkUnreadedMessagesOnScroll(): void {
                let unreadedMessages: ChatMessageDto[] = [];

                // если есть lastReadMessageId, ищем непрочитанные
                if (currentLastReadMessageId.value) {
                    unreadedMessages = messages.value.filter((x: ChatMessageDto) => x.id! > currentLastReadMessageId.value);
                } else {
                    // если нет this.lastReadMessageId то скоре всего мы зашли сюда первый раз
                    // и все сообщения непрочитанные
                    if (messages.value.length) {
                        unreadedMessages = messages.value;
                    }
                }

                if (unreadedMessages.length) {
                    readMessages(unreadedMessages);
                }
            }

            // находим элементы непрочитанных сообщений
            // и если они в поле видимости то считаем их прочитанными
            function readMessages(unreadedMessages: ChatMessageDto[]): void {
                // ищем все элементы всех непрочитанных сообщений
                const messageElements: HTMLDivElement[] = [];

                // для того, что бы отметить сообщение как прочитанное
                // отслеживаем элемент message-observer в сообщении, потому что:
                // 1. если он в зоне видимости, то и сообщение тоже
                // 2. решает проблему прочтения огромных сообщений, которые не умещаются в область прокрутки
                unreadedMessages.forEach((x: ChatMessageDto) => {
                    const el = messagesBlockRef.value?.querySelector('#message-observer' + x.id);

                    if (el) {
                        messageElements.push(el as HTMLDivElement);
                    }
                });

                for (let i = 0; i < messageElements.length; i++) {
                    checkElementInViewBox(messageElements[i]);
                }

                // ставим задержку чтобы много раз не вызывать хаб
                setTimeout(() => {
                    const message = messages.value.find((x: ChatMessageDto) => x.id === currentLastReadMessageId.value);

                    if (message) {
                        (app?.appContext.config.globalProperties.$commonHub as ChatPartialHub)?.updateLastReadMessageAsync(message.id!);
                    }
                }, 1000);
            }

            //region Навигация по найденным сообещниям
            function nextSearchedMessage(): void {
                if (searchedMessageIds.value) {
                    if (currentSearchedMessageIndex.value < searchedMessageIds.value.length - 1) {
                        currentSearchedMessageIndex.value++;
                        setMessageToView(searchedMessageIds.value[currentSearchedMessageIndex.value]);
                    }
                }
            }

            function prevSearchedMessage(): void {
                if (searchedMessageIds.value) {
                    if (currentSearchedMessageIndex.value > 0) {
                        currentSearchedMessageIndex.value--;
                        setMessageToView(searchedMessageIds.value[currentSearchedMessageIndex.value]);
                    }
                }
            }

            //endregion

            // функция проверят, в области видимости элемент или нет
            // если да, то обновляем currentLastReadMessageId
            function checkElementInViewBox(element: HTMLDivElement): void {
                const options = {
                    root: scrollAreaRef.value?.$el,
                    rootMargin: '0px',
                    threshold: 1.0,
                };

                const callback: IntersectionObserverCallback = (
                    entries: IntersectionObserverEntry[],
                    observer: IntersectionObserver
                ) => {

                    let checkIsVisible = true;
                    // Если мы в звонке, то проверяем видимость чата
                    if ($route.name === Common.getRouteName(RoutePageNameEnum.CallEnter)) {
                        checkIsVisible = callStore.chatIsVisible;
                    }

                    // 0.5 - чтобы обойти увеличение масшбата в браузере/системе, когда возникают неточности вычислений
                    // вообще это число можно сделать и меньше, потому что если у элемента есть хоть какой-то процент видимости
                    // то сообщение уже прочитано, потому что элемент message-observer на ходится в самом конце сообщения
                    // и у него ещё есть отступ сверху
                    if (checkIsVisible && entries.length && (entries[0].isIntersecting || entries[0].intersectionRatio > 0.5)) {
                        const messageId = Number((entries[0].target as HTMLDivElement).dataset.id);

                        if (messageId > currentLastReadMessageId.value) {
                            currentLastReadMessageId.value = messageId;
                        }
                    }

                    // отключаем observer так как нам не нужно постоянно
                    // следить за изменениям элемента
                    observer.disconnect();
                };

                const intersectionObserver = new IntersectionObserver(callback, options);
                try {
                    intersectionObserver.observe(element);
                } catch (e) {
                    console.warn(e);
                }
            }

            function setFloatMessageBlock(isUpScrollDirection: boolean, verticalPosition?: number): void {
                if (floatMessageDateBlockRef.value) {
                    const messageElements: HTMLDivElement[] = [];

                    // ищем все элементы сообщений по ссылкам
                    for (let i = 0; i < messages.value.length; i++) {
                        const refEl = messageItemRefPrefix + messages.value[i].id;
                        if (app?.refs[refEl]) {
                            const [messageRef] = (app.refs[refEl] as InstanceType<typeof MessageItem>[]);

                            if (messageRef && messageRef.$el) {
                                messageElements.push(messageRef.$el as HTMLDivElement);
                            }
                        }
                    }

                    if (floatBlockAnimationFadeTimeoutId) {
                        clearTimeout(floatBlockAnimationFadeTimeoutId);
                    }

                    const floatBlockRect = floatMessageDateBlockRef.value.getBoundingClientRect();

                    for (let i = 0; i < messageElements.length; i++) {
                        const currentMessageRect = messageElements[i].getBoundingClientRect();
                        let yOffset = currentMessageRect.y + floatBlockRect.height;
                        if (!isUpScrollDirection) {
                            yOffset = currentMessageRect.y - (floatBlockRect.height * 2);
                        }
                        // Ищем пересечение плавающего блока с каким-нибудь сообщением либо устанавливаем дату в соответствии с первым сообщением
                        if (floatBlockRect.y > yOffset && floatBlockRect.y < yOffset + currentMessageRect.height) {
                            const message = messages.value.find((x: IChatMessage) => x.id === Number(messageElements[i].dataset.id));
                            if (message) {
                                floatFormattedDate.value = getFormattedDate(message.createDateTime);
                                floatMessageDateBlockRef.value.style.top = '10px';

                                floatBlockAnimationFadeTimeoutId = setTimeout(() => {
                                    if (floatMessageDateBlockRef.value) {
                                        floatMessageDateBlockRef.value.style.top = '-40px';
                                    }
                                }, 2000);
                            }
                            break;
                        }
                    }

                    // Скрываем если проскролили в самое начало чата
                    if (verticalPosition === 0) {
                        floatBlockAnimationFadeTimeoutId = setTimeout(() => {
                            if (floatMessageDateBlockRef.value) {
                                floatMessageDateBlockRef.value.style.top = '-40px';
                            }
                        }, 2000);
                    }
                }
            }

            function setLastReadId(): void {
                if (props.chatInfo) {
                    lastReadMessageId = props.chatInfo.lastReadMessageId || 0;
                    previousLastReadMessageId = props.chatInfo.lastReadMessageId || 0;
                    currentLastReadMessageId.value = props.chatInfo.lastReadMessageId || 0;
                } else {
                    // Если нет chatInfo, то не вызывается запрос сообщений, можно считать что они уже инициализированы
                    isHasBeenInitialized.value = true;

                    const mainMessageInThread: ChatMessageDto | null = chatStore.mainMessageInThread;

                    // Если чат треда или чат решения не создан, запрос на получение сообщений не посылается
                    if (props.isActivitySolutionChat || (mainMessageInThread && !mainMessageInThread.discussion)) {
                        isLoading.value = false;
                    }
                }
            }

            function setDefaultFields(call?: CallMessageDto | null): CallMessageDto | null | undefined {
                if (call && !call.endedDateTimeUtc) {
                    call.endedDateTimeUtc = '';
                }

                return call;
            }

            // Получить последнее сообщение в чате, вызывается из TopPanel
            function getLastMessage(): ChatMessageDto | null {
                return messages.value[messages.value.length - 1];
            }

            // Обработка сообщения "живых данных" о том что изменился список участников звонка
            function handleCallMembersLiveData(callMembersMessage: ILiveDataMessageCallMembers): void {
                if (!messages.value) {
                    return;
                }
                // Ищем в текущем чате сообщение со звонком, данные о котором пришли в хаб
                const callMessage = messages.value.find((x: IChatMessage) => x.call?.id === callMembersMessage.callId &&
                    (x.call.isActive || callMembersMessage.isFinished));
                if (!callMessage) {
                    return;
                }

                callMessage.call!.callMembers = callMembersMessage.members.map((member: ILiveDataUserInfo) => LiveDataUtil.liveDataUserToBaseUser(member)!);
            }

            onMounted(() => {
                setLastReadId();
                const hub = getCurrentInstance()?.appContext.config.globalProperties.$commonHub as LiveDataPartialHub;
                hub.onCallMembersLiveUpdate(handleCallMembersLiveData);
            });

            return {
                messagesBlockRef,
                floatMessageDateBlockRef,
                scrollMessagesToDownRef,
                scrollAreaRef,
                messages,
                isLoading,
                isLoadingUpMessages,
                isLoadingDownMessages,
                isHasBeenInitialized,
                currentLastReadMessageId,
                currentScrollPercent,
                currentSearchedMessageIndex,
                isSelfChat,
                chatId,
                isSearchInMobileChat,
                searchedMessageIds,
                hasNewMessages,
                hasMessages,
                countUnreadedMessages,
                countUnreadedMessagesForDownButton,
                newUnreadedMessages,
                hasMainMessageInThread,
                messageItemRefPrefix,
                floatFormattedDate,
                isInlineModeChat,
                selectEmoji,
                getScrollPercent,
                isMyMessage,
                isShowMessageSeparator,
                isShowDateSeparator,
                getFormattedDate,
                onScroll,
                setMessageToView,
                onMessageFileDeleted,
                scrollToAnswerMessage,
                scrollMessagesToDown,
                addMessage,
                removeMessageFromDiscussion,
                updateDiscussionForMessage,
                updateMessage,
                removeMessage,
                updateMessageReaction,
                removeMessageReaction,
                setErrorMessage,
                setUpdateLastReadedMessage,
                nextSearchedMessage,
                prevSearchedMessage,
                getLastMessage,
                localize,
            };

        },
    });
</script>

<style lang="scss" scoped>
    .scroll-container {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;

        ::v-deep(.q-scrollarea__thumb--h) {
            width: 100% !important;
        }
    }

    .main-message {
        ::v-deep(.message-item) {
            background-color: $subtle-accent !important;
        }
    }

    .empty-solution-chat {
        width: 68%;
        max-width: 320px;
        font-size: 12px;
        line-height: 17px;
    }

    .empty-self-chat {
        width: calc(100% - 16px);
        max-width: 280px;
        font-size: 14px;

    }

    .message-item-separator {
        background-color: $shade-2;
        font-size: 12px;
        line-height: 24px;
    }

    .message-date-separator {
        background-color: transparent;

        &:first-child {
            margin-top: 0;
        }

        div {
            display: inline-block;
            padding: 6px 16px;
            font-size: 14px;
            line-height: 22px;
            background: $shade-1;
            box-shadow: 0px 1px 0px rgba(167, 167, 167, 0.25);
            border-radius: 8px;
        }
    }

    .scroll-to-down-button {
        position: fixed;
        width: 32px;
        height: 32px;
        right: 8px;
        background-color: $shade-2;
        box-shadow: 0px 1px 2px rgba(19, 29, 49, 0.16);
        border-radius: 16px;
        line-height: 32px;
        color: $shade-7;
        text-align: center;
        cursor: pointer;
        z-index: 10;

        .count-unreaded-messages {
            position: absolute;
            width: 16px;
            height: 16px;
            top: -6px;
            left: 6px;
            border-radius: 50%;
            font-size: 8px;
            line-height: 14px;
            text-align: center;
            background-color: $accent;
            color: #fff;
        }
    }

    .float-message-date-block {
        position: absolute;
        z-index: 10;
        left: 50%;
        top: -40px;
        transform: translateX(-50%);
        padding: 6px 16px;
        font-size: 14px;
        line-height: 22px;
        background: $shade-1;
        box-shadow: 0px 1px 0px rgba(167, 167, 167, 0.25);
        border-radius: 8px;
        transition: top 0.5s;
        will-change: top;
    }

    .search-navigation {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 56px;
        background-color: #fff;
        border-top: 1px solid $shade-2;
    }
</style>
