// Фильтрация полей
// При необходимости, список можно расширить
import { dateLanguageName } from 'src/types/LocaleName';
import LocalizationService from 'src/services/LocalizationService';
import { UserBaseInfoDto } from 'src/api/ApiClient';

export type Filters = {
    substringName: (e: string) => string;
    filterDate: (e: string) => string;
    filterDateFull: (e: string) => string;
    filterDateYear: (e: string) => string;
    filterFullDateYear: (e: string) => string;
    filterFullDateYearAndTime: (e: string) => string;
    filterFullDateShortMonthYearAndTime: (e: string) => string;
    filterDateMonthShortAndTime: (e: string) => string;
    filterDateMonthLongAndTime: (e: string) => string;
    filterDateMonthYear: (e: string) => string;
    filterDateTime: (e: string) => string;
    filterMonthNameByNumber: (n: number, capitalize?: boolean) => string;
    formatName: (e: UserBaseInfoDto) => string;
    formatNameFull: (e: UserBaseInfoDto) => string;
    filterFullDateShortYearAndTime: (e: string) => string;
    floatFloor: (e: number | null | undefined) => number | null | undefined;
    formatCurrencyRub: (n: number, fractionDigits: number) => string;
};

// eslint-disable-next-line max-lines-per-function
export default function useFilters(): Filters {
    // Обрезать слово до 35 символов и вставить точку
    const substringName = (e: string): string => {
        return e.length > 35 ? e.substr(0, 35) + '...' : e;
    };

    // Получить строку отформатированную в денежном формате с символом рубля в конце
    const formatCurrencyRub = (n: number, fractionDigits: number): string => {
        return n.toLocaleString(dateLanguageName[LocalizationService.getLocaleName()], {
            style: 'currency',
            currency: 'RUB',
            maximumFractionDigits : fractionDigits
        });
    };

    // Форматирует дату в 28 янв
    const filterDate = (e: string): string => {
        return new Date(e).toLocaleDateString(dateLanguageName[LocalizationService.getLocaleName()], {
            day: 'numeric',
            month: 'short',
        }).toString().replace(/[.]/g, '');
    };

    // Форматирует дату в 28 января
    const filterDateFull = (e: string): string => {
        return new Date(e).toLocaleDateString(dateLanguageName[LocalizationService.getLocaleName()], {
            day: 'numeric',
            month: 'long',
        }).toString().replace(/[.]/g, '');
    };

    // Форматирует дату в 28 янв 2022
    const filterDateYear = (e: string): string => {
        return new Date(e).toLocaleDateString(dateLanguageName[LocalizationService.getLocaleName()], {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        }).toString().replace(/\sг\.$/, ''); // Удаление " г." после года в ru локале
    };

    // Форматирует дату в 28 января 2022 г
    const filterFullDateYear = (e: string): string => {
        return new Date(e).toLocaleDateString(dateLanguageName[LocalizationService.getLocaleName()], {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        }).toString().replace(/[.]/g, '');
    };
    // Форматирует дату в 28 января 2022 г, 13:57
    const filterFullDateYearAndTime = (e: string): string => {
        return new Date(e).toLocaleDateString(dateLanguageName[LocalizationService.getLocaleName()], {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        }).toString().replace(/[.]/g, '') + '., ' + new Date(e).toLocaleTimeString().slice(0, -3);
    };

    // Форматирует дату в 28 января, 2022, 13:57
    const filterFullDateShortYearAndTime = (e: string): string => {
        let date = new Date(e).toLocaleDateString(dateLanguageName[LocalizationService.getLocaleName()], {
            day: 'numeric',
            month: 'long',
        }).toString().replace(/[.]/g, '');

        date += ', ' + new Date(e).toLocaleTimeString(undefined, { year: 'numeric' });

        return date.substring(0, date.length - 3);
    };
    
    // Форматирует дату в 28 янв., 2022, 13:57
    const filterFullDateShortMonthYearAndTime = (e: string): string => {
        let date = new Date(e).toLocaleDateString(dateLanguageName[LocalizationService.getLocaleName()], {
            day: 'numeric',
            month: 'short',
        }).toString().replace(/[.]/g, '');
        date += ' ' + new Date(e).toLocaleTimeString(undefined, { year: 'numeric' });

        return date.substring(0, date.length - 3);
    };

    // Форматирует дату в 28 янв., 13:58
    const filterDateMonthShortAndTime = (e: string): string => {
        return new Date(e).toLocaleDateString(dateLanguageName[LocalizationService.getLocaleName()], {
            day: 'numeric',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit',
        });
    };
    // Форматирует дату в 28 января, 13:59
    const filterDateMonthLongAndTime = (e: string): string => {
        return new Date(e)
            .toLocaleDateString(dateLanguageName[LocalizationService.getLocaleName()], {
                day: 'numeric',
                month: 'long',
                hour: '2-digit',
                minute: '2-digit',
            });
    };
    // Форматирует дату в 28.01.2022
    const filterDateMonthYear = (e: string): string => {
        return new Date(e).toLocaleDateString(dateLanguageName[LocalizationService.getLocaleName()], {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        }).toString();
    };
    // Форматирует дату в 13:58
    const filterDateTime = (e: string): string => {
        return new Date(e).toLocaleDateString(dateLanguageName[LocalizationService.getLocaleName()], {
            hour: '2-digit',
            minute: '2-digit',
        }).split(',')[1];
    };
    // Формат "Фамилия И. О." / "Фамилия И."
    const formatName = (e: UserBaseInfoDto): string => {
        return `${e.lastName} ${e.firstName[0] + '.'} ${e.middleName ? e.middleName[0] + '.' : ''}`;
    };
    // Полное Ф.И.О.
    const formatNameFull = (e: UserBaseInfoDto): string => {
        return `${e.lastName} ${e.firstName} ${e.middleName ? e.middleName : ''}`;
    };

    // Округляет переданное дробное число
    function floatFloor(num: number | null | undefined): number | null | undefined {
        if (!num && num !== 0) {
            return null;
        }

        return Math.round(num * 100) / 100;
    }

    // Получить название месяца по его номеру.
    // capitalize - указывает что нужно вернуть название с заглавной буквы
    // так как в разных локалях месяц может писаться с заглавной буквы, а может с прописной
    // n-1 потому что monthIndex - Integer value representing the month, beginning with 0 for January to 11 for December.
    const filterMonthNameByNumber = (n: number, capitalize: boolean = false): string => {
        const str = new Date(1900, n-1, 1).toLocaleDateString(dateLanguageName[LocalizationService.getLocaleName()], {
            month: 'long',
        });
        return capitalize
            ? str.charAt(0).toUpperCase() + str.slice(1)
            : str.charAt(0).toLowerCase() + str.slice(1);
    };

    return {
        substringName,
        filterDate,
        filterDateFull,
        filterDateYear,
        filterFullDateYear,
        filterFullDateYearAndTime,
        filterDateMonthShortAndTime,
        filterDateMonthLongAndTime,
        filterDateMonthYear,
        filterDateTime,
        filterMonthNameByNumber,
        formatName,
        formatNameFull,
        filterFullDateShortYearAndTime,
        filterFullDateShortMonthYearAndTime,
        floatFloor,
        formatCurrencyRub,
    };
};
