<template>
    <q-dialog
        v-model="isVisible"
        @show="onShow"
        @hide="onHide"
        class="image-cropper-component-dialog"
    >
        <q-card class="image-cropper-component">
            <div class="header row items-center justify-between">
                <div class="title">{{ localize('Миниатюра фотографии') }}</div>
                <q-icon
                    name="close"
                    size="20px"
                    v-close-popup
                    class="cursor-pointer"
                />
            </div>
            <q-separator />
            <div class="cropper-block-wrapper q-pa-md row items-top">
                <div class="cropper-block col">
                    <img ref="imageCropperRef" :src="imageSrc" alt="" />
                    <div class="buttons-block">
                        <q-btn
                            round
                            color="primary"
                            class="flip"
                            @click="rotateImage(-45)"
                            icon="refresh"
                        />
                        <q-btn
                            round
                            color="primary"
                            class="q-ml-sm"
                            @click="rotateImage(45)"
                            icon="refresh"
                        />
                    </div>
                </div>
                <div class="q-ml-md flex column justify-between">
                    <div ref="previewCroppedRef" class="cropped-preview"></div>
                    <div>
                        <q-btn
                            color="primary"
                            :loading="isLoadingSaveRequest"
                            @click="saveCroppedImage"
                            class="full-width q-my-sm"
                        >
                            {{ localize('Сохранить') }}
                        </q-btn>
                        <q-btn
                            flat
                            v-close-popup
                            color="primary"
                            class="q-manual-focusable--focused full-width"
                        >
                            {{ localize('Отмена') }}
                        </q-btn>
                    </div>
                </div>
            </div>
        </q-card>
    </q-dialog>
</template>

<script lang="ts">
    import Cropper from 'cropperjs';
    import 'cropperjs/dist/cropper.css';
    import { localize } from 'src/services/LocalizationService';
    import { FileParameter, LogoClient, PhotoEntityType, PhotoType } from 'src/api/ApiClient';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { computed, defineComponent, PropType, ref } from 'vue';

    // Конвертируем строку base64 в байты
    function base64toBlob(b64Data: string, contentType: string, sliceSize?: number): Blob {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }

    export default defineComponent({
        name: 'ImageCropper',

        emits: [
            'on-save-image',
            'update:model-value'
        ],

        props: {
            value: {
                type: Boolean,
                default: false
            },
            // в FileParameter два поля
            // data - строка base64
            // fileName - название файла
            imageFile: {
                type: Object as PropType<FileParameter | null>,
            },
            // Id сущности для которой загружаем картинку
            entityId: {
                type: Number,
                required: true
            },
            // Тип сущности для которой загружаем картинку
            entityType: {
                type: Number as PropType<PhotoEntityType>,
                required: true
            },
            // Тип загружаемой картинки - лого или обложка
            photoType: {
                type: Number as PropType<PhotoType>,
                required: true
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            const imageCropperRef = ref<HTMLImageElement>();
            const previewCroppedRef = ref<HTMLDivElement>();
            const imageSrc = ref<string>('');
            const isLoadingSaveRequest = ref<boolean>(false);
            let cropper: Cropper | null = null;

            const isVisible = computed({
                get: () => {
                    return props.value;
                },
                set: (newValue: boolean) => {
                    context.emit('update:model-value', newValue);
                }
            });

            async function saveCroppedImage(): Promise<void> {
                if (cropper) {
                    const rect: Cropper.Data = cropper.getData();
                    const apiClient = new LogoClient(getApiClientInitialParams());
                    isLoadingSaveRequest.value = true;

                    // Сохранить файл оригинального изображения
                    const result = await apiClient.upload(
                        {
                            data: getBlobImage(),
                            fileName: props.imageFile?.fileName ?? ''
                        },
                        props.entityId,
                        props.entityType,
                        props.photoType
                    );

                    if (result.isSuccess) {
                        // Сохранить новый preview
                        const previewResult = await apiClient.savePreview({
                            id: props.entityId,
                            type: props.entityType,
                            photoType: props.photoType,
                            cropX: Math.round(rect.x),
                            cropY: Math.round(rect.y),
                            cropWidth: Math.round(rect.width),
                            cropHeight: Math.round(rect.height),
                            rotate: rect.rotate,
                            naturalHeight: cropper.getImageData().naturalHeight,
                            naturalWidth: cropper.getImageData().naturalWidth,
                        });

                        if (previewResult.isSuccess) {
                            const cropped = cropper.getCroppedCanvas();
                            const base64 = cropped.toDataURL(getImageExtension());
                            context.emit('on-save-image', base64);
                            isVisible.value = false;
                        } else {
                            NotifyErrors(previewResult);
                        }
                    } else {
                        NotifyErrors(result);
                    }

                    isLoadingSaveRequest.value = false;
                }
            }

            // получить расширение картинки
            function getImageExtension(): string {
                const nameParts = props.imageFile?.fileName.split('.') ?? '';
                return 'image/' + nameParts[nameParts.length - 1];
            }

            function getBlobImage(): Blob {
                return base64toBlob(props.imageFile?.data.split(',')[1], getImageExtension());
            }

            function rotateImage(deg: number): void {
                const rect: Cropper.Data | undefined = cropper?.getData();

                if (rect) {
                    cropper?.rotate(deg);
                }
            }

            function onShow(): void {
                if (imageCropperRef.value) {
                    // формируем ссылку для картинки из base64
                    imageSrc.value = URL.createObjectURL(getBlobImage());

                    setTimeout(() => {
                        cropper = new Cropper(imageCropperRef.value!, {
                            // 904/226 - пропорции обложки на страницах
                            aspectRatio: props.photoType === PhotoType.Avatar ? 1 : 904/226,
                            minCropBoxWidth: 72,
                            minCropBoxHeight: 72,
                            viewMode: 2,
                            zoomable: true,
                            movable: true,
                            preview: previewCroppedRef.value
                        });
                    }, 500);
                }
            }

            function onHide(): void {
                // тк Cropper - это не vue компонент, дестроим его сами
                cropper?.destroy();
            }

            return {
                imageCropperRef,
                previewCroppedRef,
                imageSrc,
                isLoadingSaveRequest,
                isVisible,
                saveCroppedImage,
                rotateImage,
                onShow,
                onHide,
                localize,
            };
        }

    });
</script>

<style lang="scss" scoped>
    .header {
        padding: 13px 20px;

        .title {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
        }
    }

    .cropper-block {
        position: relative;
        width: 690px;
        height: 430px;

        img {
            max-width: 100%;
        }

        .buttons-block {
            position: absolute;
            bottom: 8px;
            left: calc(50% - 46px);
        }

        .flip {
            transform: rotateY(-180deg);
        }
    }

    .cropped-preview {
        width: 186px;
        height: 186px;
        overflow: hidden;
    }

    @media screen and (max-width: 760px) {
        .cropper-block-wrapper {
            display: block !important;
        }

        .cropped-preview {
            display: none;
        }
    }
</style>

<style lang="scss">
    .image-cropper-component-dialog {
        .q-dialog__inner--minimized > div {
            max-width: 90%;
        }

        .cropper-container {
            width: 100% !important;
            height: 100% !important;
        }
    }
</style>
