﻿import { CommonOdinHub } from 'src/services/hubs/CommonOdinHub';
import {
    IChatHubClientEventListeners,
} from 'src/types/generated/hubs/chatPartialHub/interfaces/IChatHubClientEventListeners';
import { IChatHubServerEvents } from 'src/types/generated/hubs/chatPartialHub/interfaces/IChatHubServerEvents';
import {
    IChatCountUnreadMessagesUpdatedModel,
} from 'src/types/generated/hubs/chatPartialHub/models/IChatCountUnreadMessagesUpdatedModel';
import { IErrorMessageDto } from 'src/types/generated/hubs/chatPartialHub/models/IErrorMessageDto';
import { IChatMessageDto } from 'src/types/generated/hubs/chatPartialHub/models/IChatMessageDto';
import { IChatMessageReactionDto } from 'src/types/generated/hubs/chatPartialHub/models/IChatMessageReactionDto';
import {
    IChatNewMessageNotificationDto,
} from 'src/types/generated/hubs/chatPartialHub/models/IChatNewMessageNotificationDto';
import { localize } from 'src/services/LocalizationService';
import { HubConnectionState } from '@microsoft/signalr';
import { ChatMessageHubDto } from 'src/api/ApiClient';
import { IChatMessageReactionModel } from 'src/types/generated/hubs/chatPartialHub/models/IChatMessageReactionModel';

export abstract class ChatPartialHub extends CommonOdinHub
    implements IChatHubServerEvents, IChatHubClientEventListeners {

    // Обработчик события от вебсокета, вызывается когда пользователь прочитал сообщение
    // когда сообщения этого чата пользователь прочитал в другой вкладке
    onChatCountUnreadMessagesUpdated(e: (arg: IChatCountUnreadMessagesUpdatedModel) => void): void {
        this.connection.on('ChatCountUnreadMessagesUpdatedAsync', e);
    }

    onChatErrorHandler(e: (arg: IErrorMessageDto) => void): void {
        this.connection.on('ChatErrorHandlerAsync', e);
    }

    // Обработчик события от вебсокета, вызывается когда пользователь прочитал сообщение
    onChatLastReadMessagesUpdated(e: (arg: IChatMessageDto) => void): void {
        this.connection.on('ChatLastReadMessagesUpdatedAsync', e);
    }

    // Обработчик события от вебсокета,  вызывается когда кто-то отредактировал сообщение
    onChatMessageEdit(e: (arg: IChatMessageDto) => void): void {
        this.connection.on('ChatMessageEditAsync', e);
    }

    // Обработчик события от вебсокета, вызывается когда кто-то закрепил сообщение
    onChatMessagePin(e: (arg: any) => void): void {
        this.connection.on('ChatMessagePinAsync', e);
    }

    // Обработчик события от вебсокета, вызывается когда кто-то добавил реакцию к сообщению
    onChatMessageReactionReceived(e: (arg: IChatMessageReactionDto) => void): void {
        this.connection.on('ChatMessageReactionReceivedAsync', e);
    }

    // Обработчик события от вебсокета, вызывается когда кто-то удалил реакцию у сообщения
    onChatMessageReactionRemove(e: (arg: IChatMessageReactionDto) => void): void {
        this.connection.on('ChatMessageReactionRemoveAsync', e);
    }

    // Обработчик события от вебсокета, вызывается когда нам пришло сообщение
    onChatMessageReceived(e: (arg: IChatMessageDto) => void): void {
        this.connection.on('ChatMessageReceivedAsync', e);
    }

    // Обработчик события от вебсокета, вызывается когда кто-то удалил сообщение
    onChatMessageRemove(e: (arg: IChatMessageDto) => void): void {
        this.connection.on('ChatMessageRemoveAsync', e);
    }

    // Обработчик события от вебсокета, вызывается когда кто-то открепил сообщение
    onChatMessageUnPin(e: (arg: IChatMessageDto) => void): void {
        this.connection.on('ChatMessageUnPinAsync', e);
    }

    onChatShowNotificationNewMessage(e: (arg: IChatNewMessageNotificationDto) => void): void {
        this.connection.on('ChatShowNotificationNewMessageAsync', e);
    }

    editMessageAsync(message: any): Promise<void> {
        return this.connection.invoke('EditMessageAsync', message);
    }

    pinMessageAsync(messageId: number, notifyEmail: boolean, notifyOdin: boolean): Promise<void> {
        return this.connection.invoke('PinMessageAsync', messageId, notifyEmail, notifyOdin);
    }

    removeMessageAsync(messageId: number): Promise<void> {
        return this.connection.invoke('RemoveMessageAsync', messageId);
    }

    removeMessageReactionAsync(reactionId: number): Promise<void> {
        return this.connection.invoke('RemoveMessageReactionAsync', reactionId);
    }

    sendMessageAsync(message: any, onSendMessageError?: (message: ChatMessageHubDto, error: any) => void): Promise<void> {
        return this.connection.invoke('SendMessageAsync', message).catch((error) => {
            if (onSendMessageError) {
                // Если соединение установлено выводим сообщение от сервера
                if (this.connection.state === HubConnectionState.Connected) {
                    onSendMessageError(message, error);
                } else {
                    // Во всех остальных случаях выводим 'Проверьте соединение с Интернетом' - ODIN-6723
                    onSendMessageError(message, {
                        message: localize('Проверьте соединение с Интернетом'),
                    });
                }
            }
        });
    }

    sendMessageReactionAsync(model: IChatMessageReactionModel): Promise<void> {
        return this.connection.invoke('SendMessageReactionAsync', model);
    }

    unpinMessageAsync(messageId: number): Promise<void> {
        return this.connection.invoke('UnpinMessageAsync', messageId);
    }

    updateCountUnreadMessagesAsync(chatId: number, count: number): Promise<void> {
        return this.connection.invoke('UpdateCountUnreadMessagesAsync', chatId, count);
    }

    updateLastReadMessageAsync(messageId: number): Promise<void> {
        return this.connection.invoke('UpdateLastReadMessageAsync', messageId);
    }
}
