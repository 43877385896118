import { AuthorizationStoreInterface } from './state';
import { IExternalLoginParams } from 'pages/Login/types/interfaces';
import {
    AccountClient,
    AccountExamLoginRequestModel,
    AccountLoginRequestModel,
    AccountLoginResponseModel,
    getApiClientInitialParams,
    MigrationExamAppClient,
    MigrationExamAppLoginResponse,
    Result,
    ResultOf,
} from 'src/api/ApiClient';
import { getCurrentInstance } from 'vue';

const actions = {
    async login(payload: AccountLoginRequestModel): Promise<Result> {
        // Залогиниться в системе и получить токен
        const result: ResultOf<AccountLoginResponseModel> = await new AccountClient(getApiClientInitialParams()).login(payload);

        if (result.isSuccess && result.entity.token) {
            this.setToken(result.entity.token);
        }

        return result;
    },
    async loginInElectronApp(payload: AccountLoginRequestModel): Promise<ResultOf<MigrationExamAppLoginResponse>> {
        // Залогиниться в системе и получить токен
        const result = await new MigrationExamAppClient(getApiClientInitialParams()).migrationExamAppLogin(payload);

        if (result.isSuccess && result.entity.token) {
            this.setToken(result.entity.token);
        }

        return result;
    },
    async examLogin(payload: AccountExamLoginRequestModel): Promise<Result> {
        // Залогиниться в системе со страницы входа для сдачи экзамена
        const result: ResultOf<AccountLoginResponseModel> = await new AccountClient(getApiClientInitialParams()).examLogin(payload);

        if (result.isSuccess && result.entity.token) {
            this.setToken(result.entity.token);
        }

        return result;
    },
    // Залогиниться в системе через Yandex и получить токен
    async yandexLogin(payload: IExternalLoginParams): Promise<Result> {
        const result: ResultOf<AccountLoginResponseModel> = await new AccountClient(getApiClientInitialParams()).yandexLogin(payload.code, payload.errorDescription);

        if (result.isSuccess && result.entity.token) {
            this.setToken(result.entity.token);
        }

        return result;
    },
    // Залогиниться в системе через VK и получить токен
    async vkLogin(payload: IExternalLoginParams): Promise<Result> {
        const result: ResultOf<AccountLoginResponseModel> = await new AccountClient(getApiClientInitialParams()).vkLogin(
            payload.code,
            payload.codeChallenge || '',
            payload.errorDescription);

        if (result.isSuccess && result.entity.token) {
            this.setToken(result.entity.token);
        } 

        return result;
    },
    // Залогиниться в системе через Google и получить токен
    async googleLogin(payload: IExternalLoginParams): Promise<Result> {
        const result: ResultOf<AccountLoginResponseModel> = await new AccountClient(getApiClientInitialParams()).googleLogin(payload.code, payload.errorDescription);

        if (result.isSuccess && result.entity.token) {
            this.setToken(result.entity.token);
        }

        return result;
    },
    // Залогиниться в системе через ТГУ и получить токен
    async tsuLogin(payload: IExternalLoginParams): Promise<Result> {
        const result: ResultOf<AccountLoginResponseModel> = await new AccountClient(getApiClientInitialParams()).tsuLogin(payload.code);

        if (result.isSuccess && result.entity.token) {
            this.setToken(result.entity.token);
        }

        return result;
    },
    // Залогиниться в системе через ссылку быстрого входа и получить токен
    async fastLogin(payload: string): Promise<Result> {
        const result: ResultOf<AccountLoginResponseModel> = await new AccountClient(getApiClientInitialParams()).fastLogin(payload);

        if (result.isSuccess && result.entity.token) {
            this.setToken(result.entity.token);
        }

        return result;
    },
    // Залогиниться в системе через Университет 2035 и получить токен
    async university2035Login(payload: IExternalLoginParams): Promise<Result> {
        const result: ResultOf<AccountLoginResponseModel> = await new AccountClient(getApiClientInitialParams()).university2035Login(payload.code, payload.errorDescription);

        if (result.isSuccess && result.entity.token) {
            this.setToken(result.entity.token);
        }

        return result;
    },
    // Получить данные пользователя у2035 и попробовать привязать их к текущему профилю пользователя Один
    async university2035AttachProfile(payload: IExternalLoginParams): Promise<Result> {
        const result: Result = await new AccountClient(getApiClientInitialParams())
            .attachU2035ToCurrent(payload.code, payload.errorDescription);

        return result;
    },
    // Залогиниться в системе через Университет 2035 и получить токен
    async codeOfFutureTsel2035Login(payload: IExternalLoginParams): Promise<Result> {
        const result: ResultOf<AccountLoginResponseModel> = await new AccountClient(getApiClientInitialParams()).codeOfFutureTsel2035Login(payload.code, payload.errorDescription);

        if (result.isSuccess && result.entity.token) {
            this.setToken(result.entity.token);
        }
        
        return result;
    },
    // Залогиниться под другим пользователем
    async loginAs(payload: number): Promise<ResultOf<AccountLoginResponseModel>> {
        const result: ResultOf<AccountLoginResponseModel> = await new AccountClient(getApiClientInitialParams())
            .loginByUser(payload);

        if (!result.isSuccess) {
            return result;
        }

        this.setToken(result.entity.token);

        return result;
    },

    // Выйти из приложения
    logout() {
        new AccountClient(getApiClientInitialParams()).logout();
        this.setToken('');
    },

    // Записать токен авторизации
    setToken(token: string) {
        const context = this as unknown as AuthorizationStoreInterface;
        context.jwtToken = token;
        context.jwtTokenDateOfCreation = new Date().getTime();
        // Может быть не хорошо, что Vue.prototype.$token записывается в мутации
        // Но очень хочется, чтобы это было в одном месте и не надо было
        // постоянно его везде обновлять и не забывать про эт
        const app = getCurrentInstance();
        if (app) {
            app.appContext.config.globalProperties.$token = token;
        }
    },
};

export default actions;
