<template>
    <div
        class="count-unreaded-messages-components"
        v-if="count"
        :class="{
            'mini': mode === 'mini',
            'muted': isMuted
        }"
    >
        <span v-if="mode !== 'mini'" :aria-label="localize('Количество непрочитанных сообщений ') + count">{{ count }}</span>
        <q-tooltip v-if="isMuted">
            {{ localize('Уведомления отключены') }}
        </q-tooltip>
    </div>
</template>

<script lang="ts">
    import { defineComponent, onBeforeUnmount, onMounted, PropType, ref } from 'vue';
    import { ChatType, ChatUnreadedMessagesCountDto } from 'src/api/ApiClient';
    import { localize } from 'src/services/LocalizationService';
    import { useChatStore } from 'src/store/module-chat';
    import { chatBus } from 'components/EventBuses';
    import { ChatBusEvents } from 'components/EventBuses/emuns';

    export default defineComponent({
        name: 'CountUnreadedMessages',
        props: {
            chatId: {
                type: Number as PropType<number | null>,
                default: 0,
            },
            // Нужен что бы посчитать все непрочитанные для данного типа чата
            // и если он передан, остальные проперти будут игнорироваться
            chatType: {
                type: Number,
                required: false,
            },
            disciplineId: {
                type: Number as PropType<number | null>,
                required: false,
            },
            activityId: {
                type: Number as PropType<number | null>,
                required: false,
            },
            mode: {
                type: String,
                default: 'default',
            },
            isArchive: {
                type: Boolean,
                default: false,
            },
            isMuted: {
                type: Boolean,
                default: false,
            },
        },
        /* eslint-disable-next-line max-lines-per-function  */
        setup(props) {
            const chatStore = useChatStore();

            // максимально отобажаемое число - 99
            const maxCount = 99;
            const count = ref<number>(0);

            // eslint-disable-next-line max-lines-per-function,@typescript-eslint/require-await
            async function calculateCount(): Promise<void> {
                let allCount = 0;
                const allUreadedMessages: ChatUnreadedMessagesCountDto[] = chatStore.unreadedMessagesCount;

                // Посчитать все непрочитанные для конкретного типа чата
                // Используется, например, на странице всех чатов
                // для разделов "Групповые", "Личные" и тд
                if (props.chatType !== undefined) {
                    // для дисциплины считаем еще по чатам решений
                    if (props.chatType === ChatType.Discipline || props.chatType === ChatType.Solution) {
                        for (let i = 0; i < allUreadedMessages.length; i++) {
                            const x = allUreadedMessages[i];

                            if ((x.type === ChatType.Discipline || x.type === ChatType.Solution) && x.isArchive === props.isArchive) {
                                allCount += x.count;
                            }

                            if (allCount >= maxCount) {
                                break;
                            }
                        }
                    } else {
                        for (let i = 0; i < allUreadedMessages.length; i++) {
                            const x = allUreadedMessages[i];

                            if (x.type === props.chatType && x.isArchive === props.isArchive) {
                                allCount += x.count;
                            }

                            if (allCount >= maxCount) {
                                break;
                            }
                        }
                    }
                } else {
                    if (props.disciplineId) {
                        // суммируем все непрочитанные сообщения
                        // в чатах для этой дисциплины
                        for (let i = 0; i < allUreadedMessages.length; i++) {
                            const x = allUreadedMessages[i];

                            if (x.disciplineId === props.disciplineId && x.isArchive === props.isArchive) {
                                allCount += x.count;
                            }

                            if (allCount >= maxCount) {
                                break;
                            }
                        }
                    } else if (props.activityId) {
                        // суммируем все непрочитанные сообщения
                        // в чатах решений данной активности
                        for (let i = 0; i < allUreadedMessages.length; i++) {
                            const x = allUreadedMessages[i];

                            if (x.activityId === props.activityId && x.isArchive === props.isArchive) {
                                allCount += x.count;
                            }

                            if (allCount >= maxCount) {
                                break;
                            }
                        }
                    } else {
                        // суммируем все непрочитанные сообщения для этого чата
                        for (let i = 0; i < allUreadedMessages.length; i++) {
                            const x = allUreadedMessages[i];

                            if (x.chatId === props.chatId && x.isArchive === props.isArchive) {
                                allCount += x.count;
                            }

                            if (allCount >= maxCount) {
                                break;
                            }
                        }
                    }
                }

                // максимально отображаемое число - 99
                if (allCount > maxCount) {
                    allCount = maxCount;
                }

                count.value = allCount;
            }

            onMounted(() => {
                calculateCount();
                chatBus.on(ChatBusEvents.OnChangeUnreadedCountMessage, calculateCount);
            });

            onBeforeUnmount(() => {
                chatBus.off(ChatBusEvents.OnChangeUnreadedCountMessage, calculateCount);
            });

            return {
                count,
                localize,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .count-unreaded-messages-components {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        background-color: $attention;
        color: #fff;

        &.mini {
            width: 8px;
            height: 8px;
        }

        &.muted {
            background-color: #9090A5;
        }
    }
</style>
