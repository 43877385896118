﻿import { CommonOdinHub } from 'src/services/hubs/CommonOdinHub';
import {
    IStatisticHubServerEvents
} from 'src/types/generated/hubs/statisticPartialHub/interfaces/IStatisticHubServerEvents';
import { IUserViewedVideoSegments } from 'src/types/generated/hubs/statisticPartialHub/models/IUserViewedVideoSegments';

export abstract class StatisticPartialHub extends CommonOdinHub
    implements IStatisticHubServerEvents {

    /** Сохранить сегменты просмотренные пользователем */
    storeSegmentsAsync(viewedSegments: IUserViewedVideoSegments): Promise<void> {
        return this.connection.invoke('StoreSegmentsAsync', viewedSegments);
    }
}
