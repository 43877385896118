﻿import { CommonOdinHub } from 'src/services/hubs/CommonOdinHub';
import {
    IWebNotificationHubServerEvents,
} from 'src/types/generated/hubs/webNotificationPartialHub/interfaces/IWebNotificationHubServerEvents';
import {
    IWebNotificationHubClientEventListeners,
} from 'src/types/generated/hubs/webNotificationPartialHub/interfaces/IWebNotificationHubClientEventListeners';
import { IWebNotificationDto } from 'src/types/generated/hubs/webNotificationPartialHub/models/IWebNotificationDto';

export abstract class WebNotificationPartialHub extends CommonOdinHub
    implements IWebNotificationHubServerEvents, IWebNotificationHubClientEventListeners {

    /* Подписаться на событие скрытия уведомления */
    onHideWebNotification(e: (arg: string) => void): void {
        this.connection.on('HideWebNotificationAsync', e);
    }

    /* Подписаться на событие показа уведомления */
    onShowWebNotification(e: (arg: IWebNotificationDto) => void): void {
        this.connection.on('ShowWebNotificationAsync', e);
    }

    /* Отправить серверу событие о том, что уведомление было просмотрено */
    webNotificationViewedAsync(id: string): Promise<void> {
        return this.connection.invoke('WebNotificationViewedAsync', id);
    }
}
