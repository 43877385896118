<template>
    <div />
</template>

<script lang="ts">
    import { Result, RoutePageNameEnum } from 'src/api/ApiClient';
    import LocalizationService from 'src/services/LocalizationService';
    import { Common } from 'src/helpers/Common';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { defineComponent, onBeforeMount } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useAccountStore } from 'src/store/module-account';
    import { useAuthorizationStore } from 'src/store/module-authorization';
    import { useChatStore } from 'src/store/module-chat';

    export default defineComponent({
        name: 'TsuLogin',

        setup() {
            const $router = useRouter();
            const $route = useRoute();
            const chatStore = useChatStore();
            const accountStore = useAccountStore();

            async function checkTsuLogin(): Promise<void> {
                // Берем данные из гет параметров, если они есть
                let code = '';
                if ($route.query.token) {
                    code = $route.query.token.toString();
                }

                // Залогиниться в системе через ТГУ и получить токен
                const result: Result = await useAuthorizationStore().tsuLogin({
                    code,
                    errorDescription: ''
                });

                if (result.isSuccess) {
                    // получаем данные пользователя
                    chatStore.loadChatsUnreadedCount();
                    await accountStore.loadAccountInfo();

                    let redirectUrl = '';

                    if ($route.query.returnUrl) {
                        redirectUrl = $route.query.returnUrl.toString().trim();

                        if (redirectUrl.toLowerCase().search(Common.getRouteName(RoutePageNameEnum.Login).toLowerCase()) !== -1) {
                            redirectUrl = '';
                        } else {
                            if (redirectUrl.substr(0, 4) === `/${LocalizationService.getLocaleName()}/`) {
                                redirectUrl = redirectUrl.substr(3);
                            }
                        }
                    }

                    $router.push(redirectUrl || { name: Common.getRouteName(RoutePageNameEnum.Dashboard) });
                } else {
                    NotifyErrors(result);
                }
            }

            onBeforeMount(checkTsuLogin);

        }
    });
</script>
