<template>
    <q-layout :tabindex="tabIndex">
        <Header ref="headerRef" />
        <Notifications @on-select-chat="onSelectChat"/>
        <LeftMenu />
        <q-page-container
            id="main-page"
            ref="pageContainerRef"
            :class="{
                'view-chats': isVisibleChatBlock,
                'has-breadcrumbs': breadcrumbsBlockHeight > 0
            }"
        >
            <q-scroll-area ref="scrollAreaRef" :style="{ height: pageHeight ? pageHeight : '100%' }">
                <!-- На страницах редактирования для мобильного есть свой компонент ХК -->
                <breadcrumbs :class="{'mobile-hidden': isEdit}"/>
                <Error404 v-if="isVisiblePage404" />
                <Error403 v-else-if="isVisiblePage403" />
                <router-view v-else />
            </q-scroll-area>
        </q-page-container>

        <chat-block
            v-if="isVisibleChatBlock"
            ref="chatBlockRef"
            @change-chat-block="changeChatBlock"
        />

        <SearchBlock v-if="isVisibleSearchBlock"/>
        <ExternalReferenceChecker />
        <ReloadApp />
    </q-layout>
</template>

<script lang="ts">
    import Bowser from 'bowser';
    import Header from './components/Header';
    import LeftMenu from './components/LeftMenu';
    import ChatBlock from './components/ChatBlock';
    import SearchBlock from './components/searchBlock/SearchBlock.vue';
    import Notifications from 'layouts/Main/components/Notifications.vue';
    import { RouteLocation, useRoute } from 'vue-router';
    import { QPageContainer, QScrollArea } from 'quasar';
    import { Common } from 'src/helpers/Common';
    import { AccountClient, RoutePageNameEnum } from 'src/api/ApiClient';
    import { routerBas } from 'components/EventBuses';
    import Error404 from 'pages/Main/Errors/404';
    import Error403 from 'pages/Main/Errors/403';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import {
        computed,
        defineComponent,
        getCurrentInstance,
        nextTick,
        onBeforeMount,
        onBeforeUnmount,
        onMounted,
        ref,
        watch,
    } from 'vue';
    import useSharedLayout from 'layouts/useSharedLayout';
    import ExternalReferenceChecker from './components/ExternalReferenceChecker.vue';
    import ReloadApp from 'layouts/Main/components/ReloadApp.vue';
    import { useAuthorizationStore } from 'src/store/module-authorization';
    import { useMainLayoutStore } from 'src/store/module-main-layout';
    import { RouterBusEvents } from 'components/EventBuses/emuns';

    // Родительский layout для страниц интерфейса
    export default defineComponent({
        name: 'Main',

        components: {
            ReloadApp,
            ExternalReferenceChecker,
            Error403,
            Error404,
            Notifications,
            SearchBlock,
            ChatBlock,
            Header,
            LeftMenu
        },

        // eslint-disable-next-line max-lines-per-function
        setup() {
            const app = getCurrentInstance();
            const $route = useRoute();
            const mainLayoutStore = useMainLayoutStore();
            const authorizationStore = useAuthorizationStore();

            const pageContainerRef = ref<QPageContainer | null>(null);
            const scrollAreaRef = ref<QScrollArea | null>(null);
            const chatBlockRef = ref<typeof ChatBlock | null>(null);
            const headerRef = ref<typeof Header | null>(null);
            const pageHeight = ref<string>('');

            const { initCommonHub } = useSharedLayout();

            const isSafariBrowser = Bowser.getParser(window.navigator.userAgent).getBrowserName() === 'Safari';
            // QLayout по дефолту отрисовывается с tabindex="-1"
            // видимо для того, чтобы при навигации с клавиатуры этот блок пропускался
            // Но в Сафари это даёт неожиданное поведение при работе с текстовым редактором QEditor
            // и при выделении чего в документе в Range и Selection оказываются левые элементы
            // тк видимо это как-то влияет на фокус в документе
            // поэтому для Сафари tabindex делаем undefined, вроде это ни на что особо не влияет
            const tabIndex = isSafariBrowser ? undefined : -1;

            const isVisibleChatBlock = ref<boolean>(true);
            const isVisiblePage404 = ref<boolean>(false);
            const isVisiblePage403 = ref<boolean>(false);
            const breadcrumbsHeight = ref<number>(0);
            const isEdit = ref<boolean>(false);

            watch($route, function (route: RouteLocation) {
                // По умолчанию показываем чат на всех страницах, кроме тех, где он явно не отключен руками через route
                setChatVisibility();

                // При смене роута скролим страницу вверх
                // Иначе положение скрола будет сохраняться
                if (scrollAreaRef.value) {
                    scrollAreaRef.value.setScrollPosition('vertical', 0, 0);
                    setIsEdit();
                }

                nextTick(() => {
                    changeChatBlock(isVisibleChatBlock.value);
                });

                isVisiblePage404.value = false;
                isVisiblePage403.value = false;
                getOnReload(route);
            });

            watch(() => breadcrumbsHeight.value, setSize);

            const isVisibleSearchBlock = computed<boolean>(() => {
                return mainLayoutStore.isVisibleSearchBlock;
            });

            const breadcrumbsBlockHeight = computed<number>(() => {
                breadcrumbsHeight.value = mainLayoutStore.breadcrumbsBlockHeight;
                return breadcrumbsHeight.value;
            });

            // Отключаем перезагрузку по свайпу на мобильном устройстве
            function getOnReload(route: RouteLocation): void {
                if (route.meta.noReload) {
                    document.querySelector('body')?.classList.add('no-reload');
                } else {
                    document.querySelector('body')?.classList.remove('no-reload');
                }
            }

            function setSize(): void {
                let height = 'calc(var(--vh, 1vh) * 100)';

                if (window.innerWidth < 960) {
                    const heightHeader = window.innerHeight - (headerRef.value?.$el.clientHeight ?? 0);
                    height = heightHeader + 'px';
                }

                if (pageContainerRef.value) {
                    (pageContainerRef.value.$el as HTMLDivElement).style.height = height;
                }

                pageHeight.value = height;
                setStyleVars();
            }

            function setStyleVars(): void {
                // Устанавливаем кастомную переменную с высотой хлебных крошек
                // Что бы её можно быть использовать в стилях
                if (pageContainerRef.value) {
                    const el = pageContainerRef.value.$el as HTMLElement;
                    el.style.setProperty('--breadcrumbsHeight', breadcrumbsBlockHeight.value + 'px');
                }
            }

            // Добавляем классы, что бы от них можно быть оттолкнуться
            // И менять стили когда чат есть/нет
            function changeChatBlock(isVisible: boolean): void {
                if (isVisible) {
                    pageContainerRef.value?.$el.classList.add('view-chats');
                } else {
                    pageContainerRef.value?.$el.classList.remove('view-chats');
                }
            }

            function onSelectChat(chatId: number): void {
                chatBlockRef.value?.setChatId(chatId);
                chatBlockRef.value?.onShow();
            }

            // Получение нового токена и Обновление его
            async function updateToken(): Promise<void> {
                const result = await new AccountClient(getApiClientInitialParams()).refreshToken();

                if (result.isSuccess) {
                    authorizationStore.setToken(result.entity.token);
                }
            }

            function setIsEdit(): void {
                if (scrollAreaRef.value) {
                    nextTick(() => {
                        const selector = '.page-form,.activity-info-page-component, .content-edit-page, .library-page-component';
                        isEdit.value = Boolean(scrollAreaRef.value?.$el.querySelector(selector));
                    });
                }
            }

            function setChatVisibility(): void {
                isVisibleChatBlock.value = 'isVisibleChatBlock' in $route.meta ? Boolean($route.meta.isVisibleChatBlock) : true;
            }

            onBeforeMount(() => {
                // Инициализуем хаб для чатов
                initCommonHub();
                setChatVisibility();

                const tokenDateOfCreation = authorizationStore.getTokenDateOfCreation;

                // Если дата токена не установлена значит нужно получить новый токен
                if (!tokenDateOfCreation) {
                    updateToken();
                    return;
                }

                // Прибавляем 1 день к дате создания токена
                const dateOfCreationAddDay = new Date(tokenDateOfCreation);
                dateOfCreationAddDay.setDate(dateOfCreationAddDay.getDate() + 1);

                // Если токен старше одного дня, обновляем его
                if (new Date() > dateOfCreationAddDay) {
                    updateToken();
                }
            });

            onBeforeMount(() => {
                // --bottomActionPanelHeight - переменная с высотой нижней панели
                // которая на некоторых страницах прижата к низу
                document.body.style.setProperty('--bottomActionPanelHeight', '0px');

                // Вешаем обработчик события events bus для роутера
                routerBas.on(RouterBusEvents.Push, (page: RouteLocation) => {
                    if (page.name === Common.getRouteName(RoutePageNameEnum.Error404)) {
                        isVisiblePage404.value = true;
                    }

                    if (page.name === Common.getRouteName(RoutePageNameEnum.Error403)) {
                        isVisiblePage403.value = true;
                    }
                });
            });

            onMounted(() => {
                setStyleVars();
                setSize();
                setIsEdit();
                getOnReload($route);

                window.addEventListener('resize', setSize);
            });

            onBeforeUnmount(() => {
                app?.appContext.config.globalProperties.$commonHub?.closeConnection();

                routerBas.off(RouterBusEvents.Push);
                window.removeEventListener('resize', setSize);
            });

            return {
                pageContainerRef,
                scrollAreaRef,
                chatBlockRef,
                headerRef,
                tabIndex,
                pageHeight,
                isVisibleSearchBlock,
                isVisibleChatBlock,
                isEdit,
                isVisiblePage404,
                isVisiblePage403,
                breadcrumbsBlockHeight,
                onSelectChat,
                changeChatBlock,
            };
        }
    });
</script>
