import { UserBaseInfoDto } from 'src/api/ApiClient';
import { LocaleName } from 'src/types/LocaleName';
import LocalizationService from 'src/services/LocalizationService';
import { ILiveDataUserInfo } from 'src/types/generated/hubs/liveDataPartialHub/models/ILiveDataUserInfo';
import { ILiveDataMultilanguageString } from '../types/generated/hubs/liveDataPartialHub/models/ILiveDataMultilanguageString';
export class LiveDataUtil {

    // Получить UserBaseInfoDto из контракта используемого для передачи информации о пользователе в рамках "живых данных"
    public static liveDataUserToBaseUser(liveDataUserInfo: ILiveDataUserInfo | null | undefined,
                                         localeName?: LocaleName | null | undefined): UserBaseInfoDto | null {
        // Если локаль не передана, то получаем текущую
        const needLocaleName = (localeName || LocalizationService.getLocaleName());
        return liveDataUserInfo
            ? {
                id: liveDataUserInfo.id,
                firstName: LiveDataUtil.getTranslatedString(liveDataUserInfo.firstName, needLocaleName)!,
                lastName: LiveDataUtil.getTranslatedString(liveDataUserInfo.lastName, needLocaleName)!,
                middleName: LiveDataUtil.getTranslatedString(liveDataUserInfo.middleName, needLocaleName),
                photoUrl: liveDataUserInfo.avatarUrl
            }
            : null;
    }

    // Получить строку в нужной локали из контракта многоязыковых строк в "живых данных"
    public static getTranslatedString(liveDataMultilanguageStr: ILiveDataMultilanguageString | undefined,
                                      localeName?: LocaleName | null | undefined): string | undefined {
        // Если локаль не передана, то получаем текущую
        const isDefaultLocale = (localeName || LocalizationService.getLocaleName()) === LocaleName.RU;
        return isDefaultLocale
            ? liveDataMultilanguageStr?.value
            : liveDataMultilanguageStr?.enValue;
    }
}
