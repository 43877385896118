<template>
    <div class="discussion-block-component">
        <q-separator />
        <div class="discussion-block-component__content row items-center justify-between">
            <div class="row items-center">
                <div v-if="message.discussion" class="avatars-block">
                    <q-avatar
                        v-for="(item, index) in message.discussion.users"
                        :key="'avatar' + index"
                        size="24px"
                        class="user-logo"
                        :class="{ primary: !item.photoUrl }"
                        aria-hidden="true"
                    >
                        <img v-if="item.photoUrl" :src="item.photoUrl" alt="" />
                        <custom-avatar
                            v-else
                            :text="item.firstName + ' ' + item.lastName"
                            :color="CustomAvatarColors.User"
                        />
                    </q-avatar>
                    <q-avatar
                        v-if="message.discussion.countOfUsers > message.discussion.users.length"
                        size="24px"
                        class="user-logo primary"
                    >
                        +{{ message.discussion.countOfUsers - message.discussion.users.length }}
                    </q-avatar>
                </div>
                <div @click="openThread" class="q-ml-sm link">
                    {{ countAnswersText }}
                </div>
            </div>
            <div class="text-description q-ml-md">{{ messageTime }}</div>
        </div>
    </div>
</template>

<script lang="ts">
    import { localize } from 'src/services/LocalizationService';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import DateUtil from 'src/helpers/DateUtil';
    import { IChatMessage } from 'components/Chat/types/interfaces';
    import { computed, defineComponent, PropType } from 'vue';
    import _ from 'lodash';
    import { useChatStore } from 'src/store/module-chat';

    // Компонент для вывода информации об обсуждении для сообщения
    export default defineComponent({
        name: 'MessageDiscussion',

        props: {
            // Главное сообщение в треде
            message: {
                type: Object as PropType<IChatMessage>,
                required: true
            },
        },

        setup(props) {
            const chatStore = useChatStore();

            // Количество ответов со склонением
            const countAnswersText = computed<string>(() => {
                return props.message.discussion!.countOfMessages + ' ' +
                    props.message.discussion!.countOfMessages.cased(
                        localize('ответ'),
                        localize('ответа'),
                        localize('ответов'),
                        false
                    );
            });

            // Форматированное время последнего отправленного сообщения
            const messageTime = computed<string>(() => {
                if (props.message.discussion!.lastMessageDateTime) {
                    // Для сегодняшнего сообщения показываем только время
                    if (DateUtil.isToday(props.message.discussion!.lastMessageDateTime)) {
                        return DateUtil.getTime(props.message.discussion!.lastMessageDateTime);
                    } else {
                        return new Date(props.message.discussion!.lastMessageDateTime).toLocaleDateString()
                            + ' '
                            + DateUtil.getTime(props.message.discussion!.lastMessageDateTime);
                    }
                }

                return '';
            });

            // Когда запишем сообщение в стор, откроется блок с обсуждением
            function openThread(): void {
                chatStore.mainMessageInThread = _.cloneDeep(props.message);
            }

            return {
                CustomAvatarColors,
                countAnswersText,
                messageTime,
                openThread,
            };
        }
    });
</script>

<style lang="scss" scoped>
    .discussion-block-component {
        margin: 7px -14px -14px -14px;
        font-size: 10px;
        line-height: 12px;

        &__content {
            padding: 8px 14px;

            .avatars-block {
                margin-right: 12px;

                .user-logo {
                    margin-right: -12px;
                    color: white;
                    border: 2px solid #fff;
                    box-sizing: content-box;

                    &.primary {
                        background-color: $link;
                    }

                    ::v-deep(div) {
                        font-size: 10px;
                    }
                }
            }
        }
    }
</style>
