import { AccountCurrentInfoResponseModel } from 'src/api/ApiClient';

export interface AccountStoreInterface {
    accountInfo: AccountCurrentInfoResponseModel | null;
    // Используется в приложении electron
    examActivityId: number | null;
}

function state(): AccountStoreInterface {
    // проставляем начальные значения для стора
    return {
        accountInfo: null,
        examActivityId: null
    };
}

export default state;
