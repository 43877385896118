<template>
    <div>
        <q-dialog
            v-model="isVisible"
            persistent
            @hide="onHide"
            class="external-link-checker-component"
        >
            <q-card class="confirm-modal q-pa-md">
                <div class="confirm-modal__title row items-center justify-between">
                    {{ localize('Переход на сторонний ресурс') }}
                    <q-icon
                        name="close"
                        size="20px"
                        v-close-popup
                        class="cursor-pointer"
                    />
                </div>
                <div>
                    {{ localize(`Уважаемые слушатели, вы переходите на сторонний ресурс.
                Поскольку в интернете участились случаи провокаций, обращаем ваше внимание,
                 что ссылки на сторонние интернет-ресурсы проверяются образовательными организациями перед их размещением.`) }}
                </div>
                <br>
                <div>
                    {{ localize(`В случае обнаружения на рекомендованных ресурсах экстремистской, противоправной и другой деструктивной информации
                    просьба незамедлительно сообщить на электронный адрес`) }} <a :href="'mailto:' + supportEmail + supportEmailBody">{{ supportEmail }}</a>
                </div>
                <br>
                <div class="q-mb-sm">
                    {{ localize('Благодарим за понимание и сотрудничество!') }}
                </div>

                <q-checkbox
                    v-model="isNotShowEver"
                    :label="localize('Больше не показывать')"
                    @update:model-value="updateNotShow"
                    class="checkbox text-shade-8 q-mb-sm"
                />
                <div class="row items-center justify-end">
                    <q-btn
                        :label="localize('Отмена')"
                        color="primary"
                        flat
                        v-close-popup
                    />
                    <q-btn
                        color="primary"
                        @click="goToLink"
                    >
                        {{ localize('Перейти') }}
                    </q-btn>
                </div>
            </q-card>
        </q-dialog>
    </div>
</template>

<script setup lang="ts">
    import { computed, onMounted, ref } from 'vue';
    import { localize } from 'src/services/LocalizationService';
    import { useAccountStore } from 'src/store/module-account';
    import { useMainLayoutStore } from 'src/store/module-main-layout';

    const accountStore = useAccountStore();
    const mainLayoutStore = useMainLayoutStore();

    const isVisible = ref<boolean>(false);

    // Флаг "Больше не показывать"
    const isNotShowEver = ref<boolean>(false);

    // Ссылка, по которой осуществляется переход
    let link: string = '';

    const userId = computed<number>((): number => {
        return accountStore.getAccountInfo?.id ?? 0;
    });

    const isNotShowExternalReferenceChecker = computed<boolean>((): boolean => {
        const data: Record<string, boolean> | null = mainLayoutStore.externalReferenceCheckerVisibleSettings;
        return !!data && data[userId.value.toString()];
    });

    const supportEmail = computed<string>((): string => {
        return accountStore.getSupportEmail;
    });

    // Информация о текущем пользователе в шаблоне письма на поддержку
    const supportEmailBody = computed<string>((): string => {
        if (!accountStore.getAccountInfo) {
            return '';
        }

        const middleName = !!accountStore.getAccountInfo.middleName
            ? accountStore.getAccountInfo.middleName + ' '
            : '';

        return '?body=%0D%0A%0D%0A- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -%0D%0AТехническая информация (не удалять):%0D%0A' +
            accountStore.getAccountInfo.lastName + ' ' +
            accountStore.getAccountInfo.firstName + ' ' +
            middleName + '(' +
            accountStore.getAccountInfo.id + ')'  +
            '%0D%0A- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -';
    });

    // Сохраняем значени флага "Больше не показывать"
    function updateNotShow(value: boolean): void {
        mainLayoutStore.setExternalReferenceCheckerVisibleSettings({
            isVisible: value,
            userId: userId.value.toString()
        });
    }

    function goToLink(): void {
        window.open(link, '_blank');
        isVisible.value = false;
    }

    function checkExternalLink(event: Event): void {
        const target = event.target as HTMLElement;

        if (target.nodeName.toLowerCase() === 'a') {
            let targetHostname = (target as HTMLAnchorElement).hostname;
            const href = target.getAttribute('href') ?? '';
            // Исключаем ссылки mailto
            const isMailTo = href.substring(0, 7) === 'mailto:';
            const www = 'www.';
            const isContainWWW = location.hostname.substring(0, 4) === www;
            // Исключаем ссылку на форму тех.поддержки
            const isSupportFormLink = href.startsWith('https://forms.yandex.ru/cloud/659fee4673cee70f5b63b79a');
            const isU2035AuthLink = href.startsWith('https://sso.2035.university/oauth2/authorize') ||
                href.startsWith('https://sso.u2035test.ru/oauth2/authorize');
            // Если текущий домен начинается с WWW, а домен, на который мы переходим без www
            // то добавляем к нему www и после этого сравниваем,
            // так как в ссылках домен odin.study может быть как с www так и без
            if (isContainWWW && targetHostname.substring(0, 4) !== www) {
                targetHostname = www + targetHostname;
            }

            if (location.hostname !== targetHostname && !isMailTo && !isSupportFormLink && !isU2035AuthLink) {
                event.preventDefault();
                isVisible.value = true;
                link = href;
            }
        }
    }

    function onHide(): void {
        if (isNotShowEver.value) {
            document.removeEventListener('click', checkExternalLink);
        }
    }

    onMounted(() => {
        // Обрабатываем клики по всем ссылкам в приложении
        // чтобы перехватить переход по внешней ссылке
        if (!isNotShowExternalReferenceChecker.value) {
            document.addEventListener('click', checkExternalLink);
        }
    });
</script>

<style scoped lang="scss">
    .checkbox {
        margin-left: -8px;
    }

    .confirm-modal {
        max-width: 385px;

        @media (max-width: 420px) {
            max-width: 100%;
            width: 100%;
        }
    }
</style>

<style lang="scss">
    .external-link-checker-component {
        @media (max-width: 420px) {
            .q-dialog__inner {
                padding: 8px;
            }
        }
    }
</style>
