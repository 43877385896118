import { MainLayoutStoreInterface } from './state';
import { EntityBaseInfoDto } from 'src/api/ApiClient';

const getters = {
    // получить закрепленные в левом меню дисциплины
    getPinnedDisciplines(state: MainLayoutStoreInterface): EntityBaseInfoDto[] {
        return state.pinnedDisciplines || [];
    },
    getPinnedPrograms(state: MainLayoutStoreInterface): EntityBaseInfoDto[] {
        return state.pinnedPrograms || [];
    },
    getPinnedCohorts(state: MainLayoutStoreInterface): EntityBaseInfoDto[] {
        return state.pinnedCohorts || [];
    },
    getPinnedDivisions(state: MainLayoutStoreInterface): EntityBaseInfoDto[] {
        return state.pinnedDivisions || [];
    },
};

export default getters;
