import { localize } from 'src/services/LocalizationService';

/**
 * Локализация клиентских ошибок валидации
 */
const ValidationRulesText = {
    /**
     * Обязательное поле
     */
    get required() {
        return localize('Обязательное поле');
    },
    /**
     * Некорректный email
     */
    get email() {
        return localize('Некорректный email');
    },
    /**
     * Минимальная длина символов
     */
    minLength(minLength: number) {
        return localize('Минимальная длина {minLength} символов', { minLength });
    },
    /**
     * Максимальная длина символов
     */
    maxLength(maxLength: number) {
        return localize('Максимальная длина {maxLength} символов', { maxLength });
    },
    /**
     * Максимальное значение
     */
    maxValue(maxValue: number) {
        return localize('Максимальное допустимое значение: {maxValue}', { maxValue });
    },
    /**
     * Значения полей должны совпадать
     */
    get mustMatch() {
        return localize('Пароли должны совпадать');
    },
    /**
     * Только числа, запятые и точки
     */
    get number() {
        return localize('Допустимые символы: цифры, точка и запятая');
    },
    /**
     * Вводить можно только цифры
     */
    get onlyNumericSymbols() {
        return localize('Вводить можно только цифры');
    },
    /**
     * Некорректный пароль
     */
    get notValidPassword() {
        return localize('Вы ввели неверный пароль');
    },
    get notValidLink() {
        return localize('Некорректная ссылка');
    },
    get between() {
        return localize('Значение должно быть в диапазоне от 0 до 100000000');
    }
};

export default ValidationRulesText;
