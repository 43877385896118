import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';

// Инициализация MediaPipe
export default (handleSegmentationResults: (results: any) => void): SelfieSegmentation => {
    const selfieSegmentation = new SelfieSegmentation({
        locateFile: (file) => {
            return `${location.protocol + '//' + location.host}/js/plugins/mediapipe/${file}`;
        },
    });

    selfieSegmentation.setOptions({
        modelSelection: 1,
    });

    selfieSegmentation.onResults(handleSegmentationResults);

    return selfieSegmentation;
};
