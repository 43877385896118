<template>
    <div class="message-files-component q-mt-sm">
        <template v-for="(file, index) in files" :key="file.id">
            <div
                v-if="isImage(file)"
                class="image-wrapper q-mr-sm q-mb-sm cursor-pointer"
                @click="showFileViewer(file.id, index)"
                role="button"
                tabindex="0"
                :aria-label="localize('Открыть окно просмотра файла')"
            >
                <q-img
                    :src="file.path"
                    :contain="true"
                    spinner-color="primary"
                    style="height: 200px; max-width: 200px; width: 200px"
                    aria-hidden="true"
                />
                <div v-if="file.size" class="file-size-text q-mt-xs">
                    {{ getFileSize(file.size) }}
                </div>
            </div>
            <div
                v-else
                role="button"
                tabindex="0"
                :aria-label="localize('Открыть окно просмотра файла')"
                @click="showFileViewer(file.id, index)"
            >
                <div class="q-mb-sm">
                    <div class="row items-center cursor-pointer">
                        <component :is="getFileIcon(file)" class="file-icon" aria-hidden="true" />
                        <div class="col q-ml-sm link ellipsis-3-lines">{{ file.fileName }}</div>
                    </div>
                    <div v-if="file.size" class="file-size-text q-mt-xs">
                        {{ getFileSize(file.size) }}
                    </div>
                </div>
            </div>
        </template>

        <FileViewer ref="fileViewerRef" />
    </div>
</template>

<script lang="ts">
    import { ChatFileDto } from 'src/api/ApiClient';
    import FileViewer from 'components/ContentViewer';
    import { Common } from 'src/helpers/Common';
    import { ContentViewerType } from 'components/ContentViewer/types';
    import { defineComponent, FunctionalComponent, PropType, ref, SVGAttributes } from 'vue';
    import { localize } from 'src/services/LocalizationService';

    /**
     * Компонент для отображения прикреплённых к сообщению файлов
     */
    export default defineComponent({
        name: 'MessageFiles',
        methods: { localize },

        components: {
            FileViewer,
        },

        props: {
            /**
             * Список файлов, прикреплённых к сообщению
             */
            files: {
                type: Array as PropType<ChatFileDto[]>,
                required: true
            },
        },

        setup(props) {
            const fileViewerRef = ref<typeof FileViewer>();

            /**
             * Является ли файл картинкой
             */
            function isImage(file: ChatFileDto): boolean {
                return !!file.contentType.toLowerCase().match(/image.*/);
            }

            /**
             * Получаем иконку в зависимости от типа файла
             */
            function getFileIcon(file: ChatFileDto): FunctionalComponent<SVGAttributes> {
                return Common.getFileIcon(file.contentType);
            }

            /**
             * Получить форматированный размер файла
             */
            function getFileSize(size?: number): string {
                return size ? Common.formatBytes(size, 0) : '';
            }

            /**
             * Показать просмотрщик файлов
             */
            function showFileViewer(id: number, index: number): void {
                fileViewerRef.value?.show({
                    type: ContentViewerType.File,
                    fileId: id,
                    indexFile: index,
                    fileIds: props.files.map((x: ChatFileDto) => x.id)
                });
            }

            return {
                fileViewerRef,
                isImage,
                getFileIcon,
                getFileSize,
                showFileViewer,
            };
        }

    });
</script>

<style lang="scss" scoped>
    .image-wrapper {
        display: inline-block;
        max-width: 220px;
        height: auto;
        overflow: hidden;

        ::v-deep(img) {
            width: auto;
            height: 100%;
        }
    }

    .file-size-text {
        font-size: 10px;
        line-height: 12px;
        color: $shade-9;
    }

    .file-icon {
        width: 24px;
    }
</style>
