<template>
    <div
        v-if="currentPinnedMessage"
        class="chat-top-panel pinned-messages flex items-center justify-between cursor-pointer"
        @click="goToPrevPinMessage"
    >
        <div class="relative-position col">
            <div v-if="pinnedMessages.length > 1" class="pagination-block">
                <template v-for="(message, index) in pinnedMessages">
                    <div
                        v-if="index < 5"
                        :key="'pinnedMessage' + message.id"
                        class="pagination-block__item col"
                        :class="{ selected: getSelectState(index) }"
                    ></div>
                </template>
            </div>

            <div
                :class="{ 'pinned-message-block': pinnedMessages.length > 1 }"
                class="cursor-pointer"
            >
                <div
                    class="pinned-chat-name ellipsis"
                    v-if="currentPinnedMessage.text"
                    v-html="currentPinnedMessage.text"
                ></div>

                <div v-if="!currentPinnedMessage.text && hasFileInPinnedMessage">
                    [{{localize('Файл')}}]
                </div>

                <div class="pinned-message">
                    {{ localize('Закрепленное сообщение') }}
                </div>
            </div>
        </div>

        <pin-message-icon
            v-if="canPinnedMessage"
            role="button"
            tabindex="0"
            :aria-label="localize('Открепить сообщение')"
            class="cursor-pointer"
            @click.stop="unPinMessage"
        >
            <q-tooltip>
                {{ localize('Открепить сообщение') }}
            </q-tooltip>
        </pin-message-icon>
    </div>
</template>

<script setup lang="ts">
    /**
     * Компонент для отображения закрепленных сообщений
     */

    import { ChatPinnedMessageDto } from 'src/api/ApiClient';
    import { localize } from 'src/services/LocalizationService';
    import { Notify } from 'quasar';
    import { computed, getCurrentInstance, ref } from 'vue';
    import { ChatPartialHub } from 'src/services/hubs/ChatPartialHub';
    import { useChatStore } from 'src/store/module-chat';

    defineProps({
        // Есть ли у пользователя права на открепление сообщений
        canPinnedMessage: {
            type: Boolean,
            default: false
        },
    });

    const emit = defineEmits(['go-to-message']);

    const app = getCurrentInstance();
    const chatStore = useChatStore();
    const currentMessageIndex = ref<number>(-1);

    // Закрепленный сообщения в верхней панели
    const pinnedMessages = computed<ChatPinnedMessageDto[]>(() => {
        const messages = chatStore.pinnedMessages ?? [];
        currentMessageIndex.value = messages.length - 1;
        return messages;
    });

    const currentPinnedMessage = computed<ChatPinnedMessageDto | undefined>(() => {
        return pinnedMessages.value[currentMessageIndex.value];
    });

    // Есть ли файлы в закрепленном сообщении
    const hasFileInPinnedMessage = computed<undefined | number>(() => {
        return currentPinnedMessage.value?.files?.length;
    });

    function getSelectState(index: number): boolean {
        const isIndexCoincidence = index === currentMessageIndex.value;

        if (
            currentMessageIndex.value === 0 ||
            currentMessageIndex.value === 1 ||
            pinnedMessages.value.length <= 5
        ) {
            return isIndexCoincidence;
        }

        if (pinnedMessages.value.length - 1 === currentMessageIndex.value) {
            return index === 4;
        }

        if (pinnedMessages.value.length - 2 === currentMessageIndex.value) {
            return index === 3;
        }

        // Если выбрано не первое, второе, последнее, предпоследнее
        // сообщение - присваиваем класс индикатору по середине,
        // Делаем сравнение на 2, потому-что индикатор по середке
        // имеет index === 2.
        return index === 2;
    }

    // Открепить сообщение от панели
    function unPinMessage(): void {
        if (!currentPinnedMessage.value) {
            return;
        }

        (app?.appContext?.config.globalProperties.$commonHub as ChatPartialHub)?.unpinMessageAsync(currentPinnedMessage.value.id);
        currentMessageIndex.value = pinnedMessages.value.length - 1;

        Notify.create({
            type: 'positive',
            message: localize('Сообщение откреплено'),
        });
    }

    // Перейти к предыдущему закрепленному
    // сообщению при нажатии на плашку
    function goToPrevPinMessage(): void {
        if (!currentPinnedMessage.value) {
            return;
        }

        emit('go-to-message', currentPinnedMessage.value.id);

        if (currentMessageIndex.value === 0) {
            currentMessageIndex.value = pinnedMessages.value.length;
        }

        currentMessageIndex.value -= 1;
    }
</script>

<style lang="scss" scoped>
    .pinned-message-block {
        margin-left: 18px;
    }

    .pinned-message {
        font-size: 10px;
        line-height: 12px;
        color: $shade-8;
    }

    .pinned-chat-name {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .pagination-block {
        width: 2px;
        height: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;

        &__item {
            margin-bottom: 1px;
            border-radius: 1px;
            background-color: $shade-4;

            &.selected {
                background-color: $accent;
            }
        }
    }
</style>
