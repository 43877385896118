import { ActivityContentItemModel, ActivityPlanContentInfoDto, LibraryNodeDto } from 'src/api/ApiClient';

export interface IContentViewerOptions {
    type: ContentViewerType;
    // передаём для просмотра одного файла
    fileId?: number;
    // передаём для просмотра нескольких файлов
    fileIds?: number[];
    // передаём для просмотра одного материала
    content?: ActivityPlanContentInfoDto | ActivityContentItemModel | LibraryNodeDto;
    // передаём для просмотра нескольких материалов
    contentItems?: ActivityPlanContentInfoDto[] | ActivityContentItemModel[] | LibraryNodeDto[];
    // есть два енама для материалов с разными значениями, надо знать, к которому обращаться
    contentEnumSource?: 'ContentEnum' | 'ContentTypeForActivity'
    leftPosition?: number;
    isAttachmentMode?: boolean;
    node?: LibraryNodeDto;
    indexFile?: number;
}

// Что просматриваем - материал или просто файл
export enum ContentViewerType {
    File,
    Content
}

export enum ContentViewerFileType {
    Video = 1,
    Image = 2,
    PDF = 3,
    OfficeDocument= 4,
    Other = 5,
    LaTeX = 6,
}

export const OfficeDocumentsContentTypes: string[] = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/msword',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.template.macroEnabled.12',
    'application/vnd.ms-powerpoint.template.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.ms-powerpoint.addin.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

export const TextContentTypes: string[] = [
    'application/rtf',
    'text/html',
    'text/plain'
];
