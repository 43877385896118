﻿<template>
    <search-item
        :item="item"
        :name="item.name"
        :item-page-url="activityPageUrl"
        :type="SearchType.Activity"
        @on-item-selected="$emit('on-item-selected')"
    >
        <template #avatar>
            <div class="relative-position q-mr-sm" aria-hidden="true">
                <q-avatar size="24px" class="rounded-borders">
                    <CalendarIcon aria-hidden="true" />
                </q-avatar>
            </div>
        </template>
        <template #info>
            <div class="info">
                <div class="text-tag item demo-tag-color q-mr-xs" :class="getActivityTypeClass()">
                    {{ item.typeOfString }}
                </div>

                <span>
                    • {{ item.disciplineShortName }}
                    • {{ item.eduProgramShortName }}
                    • {{ item.divisionShortName }}
                </span>
            </div>
        </template>
        <template #append>
            <slot name="append"></slot>
        </template>
    </search-item>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import {
        ActivityType,
        RoutePageNameEnum,
        SearchActivityDto,
        SearchType,
    } from 'src/api/ApiClient';
    import { Common } from 'src/helpers/Common';
    import { useGetEntityPageUrl } from '../../hooks/useGetEntityPageUrl';
    import SearchItem from '../SearchItem.vue';
    // Компонент для отображениея данных о найденной активности


    // Компонент для отображение данных о найденном потоке
    const props = defineProps<{
        // Данные отображаемого элемента поиска
        item: SearchActivityDto;
    }>();
    defineEmits<{
        (e: 'on-item-selected'): void;
    }>();
    const {
        getEntityPageUrl: getCohortPageUrl,
    } = useGetEntityPageUrl(Common.getRouteName(RoutePageNameEnum.ActivityInfo));
    // Ссылка на страницу потока
    const activityPageUrl = computed(() => getCohortPageUrl(props.item.id));

    // Цвет активности
    function getActivityTypeClass(): string {
        return `${ActivityType[Number(props.item.type)]}-color`;
    }
</script>
<style lang="scss" scoped>
    .icon {
        position: absolute;
        width: 16px;
        height: 16px;
        left: 12px;
        top: 12px;
    }

    .rounded-borders {
        border-radius: 4px;
    }
</style>
