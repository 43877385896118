/* eslint-disable */
import Janus, { OfferParamsMedia } from './janus';
import { ButtonBusEvents, buttonEventBus, janusEventBus } from '../../components/eventBuses';
import Logger from 'src/services/Logger';
import Bowser from 'bowser';
import * as Sentry from '@sentry/vue';
import { useCallStore } from 'src/store/module-call';

const JANUS_PG_VIDEOROOM = 'janus.plugin.videoroom';

function isFirefoxBrowser(): boolean {
    return Bowser.getParser(window.navigator.userAgent).getBrowserName() === 'Firefox';
}

export default class JanusWrapper {
    maxInterlocutorNumber: number;
    janus: Janus | undefined;
    videoRoomHandle: any;
    sharingScreenHandle: any;
    feeds: any[];
    server: string;
    iceServers: RTCIceServer[];
    callId: number;
    public username: string;
    userId: number;
    canTurnOnRecord: boolean = false;
    callRecordId: number | null | undefined;
    pin: string = '';
    opaqueId: string;
    userStream: MediaStream | undefined;
    userSharingStream: MediaStream | undefined;
    privateId: string;
    simulcast: boolean;
    isRecordingEnabled: boolean; // включена запись видео в настройках чата
    isRecordingEnabledForCurrent: boolean = false; // ужё идёт запись для текущего пользователя
    sharingBitrate: number = 2048000;
    defaultBitrate: number = 1524000;
    feedStreams: any = {};
    subStreams: any = {};
    userRemoteId: string = "";
    creatingSubscription: boolean = false;
    remoteFeed: any = null;
    subscriptions: any = {};
    countSubscribeTries = 0;

    mediaDevices: MediaDeviceInfo[] = [];

    get isVideoInputAvailable() {
        return this.mediaDevices.some((x) => x.kind === 'videoinput');
    }
    get isAudioInputAvailable() {
        return this.mediaDevices.some((x) => x.kind === 'audioinput');
    }

    /* Идентификатор выбранного пользователем устройства для видео ввода, используется для хранения id при пересоздании стрима */
    private selectedVideoInputDeviceId: string | undefined;

    /* Идентификатор выбранного пользователем устройства для аудио ввода, используется для хранения id при пересоздании стрима */
    private selectedAudioInputDeviceId: string | undefined;

    // Использовать дефолтные настройки (те, которые были выбраны на экране настройки звонка)
    // либо при подключении испольжовать текущие настйроки аудио и видео
    private isUseDefaultSetting: boolean = true;

    private isMutedVideoDefaultSetting: boolean = true;

    private isMutedAudioDefaultSetting: boolean = true;

    // Кол-во неуспешных попыток включения записи
    private recordRetryCount: number = 0;

    // Идёт ли процесс переподключения
    private isReconnecting: boolean = false;

    get isMutedVideoByDefaultSetting(): boolean {
        return this.isMutedVideoDefaultSetting;
    }

    /* Идентификатор текущего устройства для видео ввода*/
    get currentVideoInputDeviceId() {
        if (this.selectedVideoInputDeviceId) {
            return this.selectedVideoInputDeviceId;
        } else {
            const videoTracks = this.userStream?.getVideoTracks();
            if (videoTracks && videoTracks.length > 0) {
                return videoTracks[0].getSettings().deviceId;
            } else {
                return undefined;
            }
        }
    }

    /* Идентификатор текущего устройства для аудио ввода*/
    get currentAudioInputDeviceId() {
        if (this.selectedAudioInputDeviceId) {
            return this.selectedAudioInputDeviceId;
        } else {
            if (this.videoRoomHandle) {
                return this.videoRoomHandle.webrtcStuff.audioInputDeviceId;
            } else {
                return undefined;
            }
        }
    }

    get isVideoMuted(): boolean {
        return this.videoRoomHandle?.isVideoMuted();
    }

    get isAudioMuted(): boolean {
        return this.videoRoomHandle?.isAudioMuted();
    }

    get isTestJanusServer(): boolean {
        return this.server.includes('janus3339');
    }

    // -------------- Обработчики, передаваемые в параметрах конструктора -----------

    // Функция передаётся в качестве необязательного параметра конструктора.
    // Обеспечивает вывод строки предупреждения в модальное окно.
    // После ответа запускает коллбек onAccept()
    // Если не передана - используется браузерная alert(), после которого
    // запускается onAccept()
    modal = (message: string, onAccept?: () => void) => {
        /* void */
    };

    // Функция передаётся в качестве необязательного параметра конструктора.
    // Она может рендерить форму ввода пользовательских параметров и ждать ввода
    // Она может получить параметры пользователя другим способом.
    // Когда битрейт получен, она должна вызвать setBitrate(bitrate).
    getBitrate = () => {
        /* void */
    };

    // Функция-обработчик события завершения звонка.
    // Передаётся в качестве необязательного параметра конструктора.
    // Если не передана - просто выводится модальное окно
    // с сообщением об окончании звонка.
    onCallFinished = () => {
        /* void */
    };

    // Функция передаётся в качестве необязательного параметра конструктора.
    // Выводит сообщение о том, что нет вебкамеры.
    noWebcamAvailableShow = () => {
        /* void */
    };

    // Функция передаётся в качестве параметра конструктора.
    // Действия после публикации собственного потока.
    onPublishOwnFeed = (userRemoteId?: number) => {
        /* void */
    };

    // Функция передаётся в качестве необязательного параметра конструктора.
    afterPublishOwnFeedError = () => {
        /* void */
    };

    // Функция передаётся в качестве необязательного параметра конструктора.
    afterRoomDestroyed = () => {
        /* void */
    };

    // Функция передаётся в качестве параметра конструктора.
    // Действия с UI, убирающие со страницы элементы, отвечающие
    // за отображение всего, что связано с удаляемым пользователем.
    removeFeed = (remoteFeedIndex: number) => {
        /* void */
    };

    // Функция передаётся в качестве параметра конструктора.
    // Подключена подписка, но ещё нет потока.
    // В ней можно подключить спиннер загрузки
    // и выполнить другие действия с UI
    afterAttachRemoteFeed = (remoteFeed: any) => {
        /* void */
    };

    // Функция передаётся в качестве параметра конструктора.
    // Здесь можно выполнить действия с UI перед подключением потока к элементу video.
    // Здесь же можно отключить спиннер, если был запущен.
    // Здесь же необходимо подключить поток к элементу video
    connectRemoteStream = (remoteFeed: any, track: MediaStreamTrack) => {
        /* void */
    };

    // Функция передаётся в качестве параметра конструктора.
    // Здесь выполняются необходимые действия в UI при отключении подписки на собеседника.
    // В том числе, отключение спиннера, если был запущен.
    cleanupRemoteFeed = (remoteFeed: any) => {
        /* void */
    };

    changeReconnectingState = (isReconnection: boolean) => {
        /* void */
    };

    onInitCall = () => {
        /* void */
    };

    // Функция передаётся в качестве параметра конструктора.
    afterDestroyed = () => {
        /* void */
    };

    constructor(params: any) {
        this.server = params.server;
        this.iceServers = params.iceServers;
        this.callId = <number>params.callId;
        this.maxInterlocutorNumber = params.maxInterlocutorNumber;
        this.feeds = new Array(params.maxInterlocutorNumber);
        this.username = params.username;
        this.userId = params.userId;
        // this.pin = params.password
        this.opaqueId = `${this.userId}-${Janus.randomString(12)}`;
        this.privateId = params.privateId;
        this.isRecordingEnabled = params.isRecordingEnabled;
        this.simulcast = params.simulcast;
        this.modal = params.modal
            ? params.modal
            : (message: string, onAccept?: () => void) => {
                  alert(message);
                  if (onAccept) {
                      onAccept();
                  }
              };

        this.getBitrate = params.getBitrate
            ? params.getBitrate
            : (): void => {
                  /* empty */
              };
        this.onCallFinished = params.onUnpublishOwnFeed
            ? params.onUnpublishOwnFeed
            : () => {
                  this.modal('Call finished.');
              };
        this.noWebcamAvailableShow = params.noWebcamAvailableShow
            ? params.noWebcamAvailableShow
            : () => {
                  this.modal('No webcam available!');
              };
        this.onPublishOwnFeed = params.onPublishOwnFeed;
        this.afterPublishOwnFeedError = params.afterPublishOwnFeedError
            ? params.afterPublishOwnFeedError
            : (): void => {
                  /* empty */
              };
        this.afterRoomDestroyed = params.afterRoomDestroyed
            ? params.afterRoomDestroyed
            : (): void => {
                  /* empty */
              };
        this.removeFeed = params.removeFeed;
        this.afterAttachRemoteFeed = params.afterAttachRemoteFeed;
        this.connectRemoteStream = params.connectRemoteStream;
        this.cleanupRemoteFeed = params.cleanupRemoteFeed;
        this.afterDestroyed = params.afterDestroyed
            ? params.afterDestroyed
            : (): void => {
                  /* empty */
              };
        this.changeReconnectingState = params.changeReconnectingState;
        this.onInitCall = params.onInitCall;
    }

    public async init() {
        navigator.mediaDevices.ondevicechange = (e) => {
            this.initMediaDevicesAsync();
        };

        await this.initMediaDevicesAsync();

        const currentInstance = this;

        Janus.init({
            debug: ['warn', 'error'],
            callback: this.createJanusObject.bind(this),
            // Отправка ошибок Janus в Sentry
            onError: function (message: string | object, event?: string | object) {
                // Если пришел только message отправлем его
                if (message && !event) {
                    if (currentInstance.janus && currentInstance.janus.isConnected()) {
                        Sentry.captureException(message);
                    }
                    console.error(message);
                    return;
                }

                // Обработка разных типов параметров и их количества
                // Собираем всё в один объект
                let data: any = {};

                if (typeof message === 'string') {
                    data.message = message;
                } else {
                    data = { ...message };
                }

                if (event) {
                    if (typeof event === 'string') {
                        data.description = event;
                    } else {
                        data = {
                            ...data,
                            ...event
                        };
                    }
                }

                if (currentInstance.janus && currentInstance.janus.isConnected()) {
                    Sentry.captureException(data);
                }

                console.error(...arguments);
            }
        });
    }

    async initMediaDevicesAsync() {
        const result = await JanusHelper.getMediaDevicesAsync();
        this.mediaDevices = result.devices;
        janusEventBus.emit(JanusEvents.onMediaDevicesChanged, this.mediaDevices);
    }

    // -------------- Публичные методы -----------
    public setBitrate(bitrate: number): void {
        this.videoRoomHandle.send({ message: { request: 'configure', bitrate } });
    }

    public setSelectedAudioInputDeviceId(deviceId: string) {
        this.selectedAudioInputDeviceId = deviceId;
    }

    public setSelectedVideoInputDeviceId(deviceId: string) {
        this.selectedVideoInputDeviceId = deviceId;
    }

    public setMutedAudioDefaultSetting(isMuted: boolean) {
        this.isMutedAudioDefaultSetting = isMuted;
    }

    public setMutedVideoDefaultSetting(isMuted: boolean) {
        this.isMutedVideoDefaultSetting = isMuted;
    }

    public enableRecording(): void {
        this.recordRetryCount = 0;
        this.isRecordingEnabled = true;
    }

    public disableRecording(): void {
        const videoOn: boolean = this.isVideoInputAvailable && !this.videoRoomHandle.isVideoMuted();
        this.isRecordingEnabled = false;
    }

    public unpublish(): void {
        this.videoRoomHandle?.send({ message: { request: 'unpublish' } });
    }

    public async stopSharingAsync(): Promise<void> {
        return new Promise((resolve: (value: (PromiseLike<void> | void)) => void) => {
            if (this.sharingScreenHandle) {
                //Вроде у send есть onsuccess, лучше дожидаться окончания работы
                this.sharingScreenHandle.send({ message: { request: 'unpublish' } });

                const resolveFunction = () => {
                    janusEventBus.off(JanusInternalEvents.stopSharingAsyncPromiseResolved, resolveFunction);
                    janusEventBus.emit(JanusEvents.onSharingStopped);
                    resolve();
                };
                janusEventBus.on(JanusInternalEvents.stopSharingAsyncPromiseResolved, resolveFunction);
            } else {
                resolve();
            }
        });
    }

    public async startSharingAsync(stream: MediaStream | null): Promise<void> {
        return new Promise(() => {
            this.janus?.attach({
                plugin: JANUS_PG_VIDEOROOM,
                opaqueId: this.opaqueId,
                success: (pluginHandle: any): void => {
                    this.sharingScreenHandle = pluginHandle;
                    this.sharingScreenHandle.send({
                        message: {
                            display: 'sharing-' + this.userId,
                            request: 'join',
                            room: this.callId,
                            pin: this.pin,
                            ptype: 'publisher',
                        },
                    });
                },
                error: (error: string): void => {
                    janusEventBus.emit(JanusEvents.onSharingError, error);
                },
                onmessage: (msg: any, jsep: any) => {
                    this.onUserSharingHandleMessage(msg, stream, jsep);
                },
                //В Firefox это событие не вызывается
                mediaState: async (state: any) => {
                    if (!this.userSharingStream) {
                        return;
                    }
                    const userVideoTrack = this.userSharingStream.getTracks().find((track) => track.kind === 'video');

                    if (!(userVideoTrack?.readyState === 'ended')) {
                        return;
                    }

                    await this.stopSharingAsync();
                },
                onlocaltrack: (track: MediaStreamTrack) => {
                    if (!this.userSharingStream) {
                        this.userSharingStream = new MediaStream();
                    }

                    if (track.kind === 'video') {
                        this.userSharingStream?.getVideoTracks().forEach((x: MediaStreamTrack) => {
                            this.userSharingStream?.removeTrack(x);
                        });
                    }

                    this.userSharingStream?.addTrack(track);

                    //В Firefox не работает mediaState событие, поэтому перепроверяем состояние трека локального стрима шаринга
                    if (isFirefoxBrowser()) {
                        const checkSharingFeedStream = () => {
                            const stream = this.userSharingStream;
                            if (
                                stream &&
                                stream.active &&
                                !(stream.getTracks().find((x) => x.kind === 'video')?.readyState === 'ended')
                            ) {
                                setTimeout(checkSharingFeedStream.bind(this), 5000);
                                return;
                            }

                            this.stopSharingAsync();
                        };
                        setTimeout(checkSharingFeedStream.bind(this), 5000);
                    }

                    janusEventBus.emit(JanusEvents.onSharingStarted, this.userSharingStream);
                },
            });
        });
    }

    private onUserSharingHandleMessage(msg: any, stream: MediaStream | null, jsep: any) {
        const event = msg['videoroom'];
        const enableScreenRecording = this.isRecordingEnabled;

        if (event) {
            if (event === 'joined') {
                this.sharingScreenHandle.createOffer({
                    tracks: [
                        { type: 'screen', capture: stream ? stream.getVideoTracks()[0] : true, recv: false }
                    ],
                    success: (offerJsep: any) => {
                        const isSharing = true;
                        const fileName = this.getRecordFileName(isSharing);
                        this.sharingScreenHandle.send({
                            message: {
                                request: 'configure',
                                bitrate: this.sharingBitrate,
                                filename: fileName
                            },
                            jsep: offerJsep,
                        });
                    },
                    error: (error: string) => {
                        Janus.error(error);
                        janusEventBus.emit(JanusEvents.onSharingError, error);
                    },
                });
            } else if (msg['unpublished']) {
                if (msg['unpublished'] === 'ok') {
                    this.sharingScreenHandle.hangup();
                    this.sharingScreenHandle.detach({
                        success() {
                            this.sharingScreenHandle = undefined;
                            this.userSharingStream = undefined;
                            janusEventBus.emit(JanusInternalEvents.stopSharingAsyncPromiseResolved);
                        },
                        error(e: any) {
                            Logger.log(
                                'JanusWrapper -> onUserSharingHandleMessage -> this.sharingScreenHandle.detach -> error',
                                e
                            );
                        },
                    });
                }
            }
        }
        if (jsep) {
            this.sharingScreenHandle.handleRemoteJsep({ jsep });
        }
    }

    public muteAudio(): void {
        if (!this.videoRoomHandle || this.videoRoomHandle.isAudioMuted() || !this.isAudioInputAvailable) {
            return;
        }

        Janus.log('Muting local stream...');

        this.videoRoomHandle.muteAudio();
    }

    public unmuteAudio(): void {
        if (!this.videoRoomHandle.isAudioMuted() || !this.isAudioInputAvailable) {
            return;
        }

        Janus.log('Unmuting local stream...');
        this.videoRoomHandle.unmuteAudio();

        const videoOn = this.isVideoInputAvailable && !this.videoRoomHandle.isVideoMuted();

        this.videoRoomHandle.send({ message: { request: 'configure', audio: true, video: videoOn } }); // включено по тикету odin-819
    }

    getRecordingPublishObject(audioOn: boolean, videoOn: boolean, enableRecord: boolean, isSharing: boolean = false) {
        const fileName = this.getRecordFileName(isSharing);
        const publish = {
            request: 'configure',
            audio: audioOn,
            video: videoOn,
            data: true,
            record: enableRecord,
            filename: fileName,
            bitrate: this.defaultBitrate + 2,
        };

        return publish;
    }

    getRecordFileName(isSharing: boolean): string {
        const callId = this.callId;
        const userId = this.userId;

        const clientTime = new Date();

        const year = clientTime.getUTCFullYear();
        let month = clientTime.getUTCMonth();
        const day = clientTime.getUTCDate();
        const hour = clientTime.getUTCHours();
        const minutes = clientTime.getMinutes();
        const seconds = clientTime.getSeconds();
        const milliseconds = clientTime.getMilliseconds();
        const dateString = `${year}-${++month}-${day}__${hour}_${minutes}_${seconds}--${milliseconds}`;
        const fileName = !isSharing ? `userid_${userId}_callid_${callId}_time_${dateString}_time_`
            : `userid_${userId}_callid_${callId}_time_${dateString}_time_sharing`;
        const filesPath = '/tmp/';
        return filesPath + fileName;
    }

    getRecordingPublishObjectSharing(enableRecord: boolean) {
        const isSharing = true;
        const fileName = this.getRecordFileName(isSharing);
        const publish = {
            request: 'configure',
            audio: true,
            video: true,
            data: true,
            record: enableRecord,
            bitrate: this.sharingBitrate + 1,
            filename: fileName,
        };

        return publish;
    }

    muteAudioWithDelay(): void {
        setTimeout(this.muteAudio, 200);
    }

    unMuteAudioWithDelay(): void {
        setTimeout(() => this.unmuteAudio(), 500);
    }

    unMuteVideoWithDelay(): void {
        setTimeout(() => this.unmuteVideo(), 300);
    }

    public replaceVideo(stream: MediaStream) {
        this.videoRoomHandle.createOffer({
            tracks: [
                { type: 'video', capture: stream.getVideoTracks()[0], recv: true, replace: true, simulcast: this.simulcast }
            ],
            success: (jsep: any) => {
                this.videoRoomHandle.send({
                    message: {
                        request: 'configure',
                        video: true,
                        data: true,
                    }, jsep
                })
            },
            error: Janus.error
        });
    }

    public muteVideo(callback?: () => void): void {
        if (this.videoRoomHandle.isVideoMuted()) {
            if (callback) {
                callback();
            }
            return;
        }

        Janus.log('Muting local video stream...');

        this.videoRoomHandle.muteVideo();

        const audioIsMuted = this.isAudioMuted ?? true;

        // Удаляем видеодорожку из потока
        this.videoRoomHandle.createOffer({
            tracks: [
                { type: 'video', capture: false, remove: true }
            ],
            success: (jsep: any) => {
                Janus.debug('Got publisher SDP!', jsep);

                const isSharing = false;
                const fileName = this.getRecordFileName(isSharing);

                const publish = {
                    request: 'configure',
                    audio: !(this.isAudioMuted ?? true),
                    video: false,
                    data: true,
                    filename: fileName,
                };

                this.videoRoomHandle.send({ message: publish, jsep });
                this.onPublishOwnFeed();

                // При работе с внешним потоком иногда при выключении видео включается звук, хотя он у нас выключен
                // Возможно это баг в Janus, возможно это можно разрешить через настройки OfferMedia
                // но я другого решения не нашел, поэтому дополнительно выключаем звук при необходимости
                if (audioIsMuted) {
                    this.videoRoomHandle.muteAudio();
                }

                this.videoRoomHandle.send({ message: { request: 'configure', audio: true, video: false } });

                if (callback) {
                    callback();
                }
            },
            error: async (error: any) => {
                if (callback) {
                    callback();
                }
                Janus.debug('Mute error --> ', error);
                Janus.error(error);
            }
        });
    }

    public unmuteVideo(stream?: MediaStream, callback?: () => void): void {
        if (!this.videoRoomHandle.isVideoMuted()) {
            if (callback) {
                callback();
            }
            return;
        }

        Janus.log('Unmuting local video stream...');

        const audioIsMuted = this.isAudioMuted ?? true;

        const offerMedia: OfferParamsMedia = {
            video: true,
        };

        if (this.selectedVideoInputDeviceId && this.isVideoInputAvailable) {
            offerMedia.video = { deviceId: this.selectedVideoInputDeviceId };
        }

        const track: any = {
            type: 'video',
            capture: stream ? stream.getVideoTracks()[0] : offerMedia.video,
            recv: true,
            simulcast: this.simulcast
        }

        if (this.userStream?.getVideoTracks().length) {
            track.replace = true;
        } else {
            track.add = true;
        }

        // Добавляем видео дорожку в поток
        this.videoRoomHandle.createOffer({
            tracks: [track],
            success: (jsep: any) => {
                Janus.debug('Got publisher SDP!', jsep);

                const isSharing = false;
                const fileName = this.getRecordFileName(isSharing);

                const publish = {
                    request: 'configure',
                    audio: !audioIsMuted,
                    video: true,
                    data: true,
                    filename: fileName,
                };

                this.videoRoomHandle.send({ message: publish, jsep });

                this.onPublishOwnFeed();

                if (this.isRecordingEnabled) {
                    this.isRecordingEnabledForCurrent = true;
                }

                this.videoRoomHandle.send({ message: { request: 'configure', audio: true, video: true } });

                if (callback) {
                    callback();
                }
            },
            error: async (error: any) => {
                if (callback) {
                    callback();
                }
                Janus.debug('Unmute error --> ', error);
                Janus.error(error);
            }
        });
    }

    public destroy() {
        this.janus?.destroy({
            cleanupHandles: true,
            success: () => {
                this.userStream = undefined;
                this.userSharingStream = undefined;
                this.remoteFeed = null;
                this.feedStreams = {};
                this.subStreams = {};
                this.subscriptions = {};
                this.creatingSubscription = false;
            }
        });
    }

    public changeVideoInputDevice(deviceId: string) {
        this.selectedVideoInputDeviceId = deviceId;
    }

    public changeAudioInputDevice(deviceId: string) {
        this.selectedAudioInputDeviceId = deviceId;
        this.publishOwnFeed(undefined, false, undefined, undefined, true);
    }

    ensureAudioMuted() {
        if (this.isAudioMuted) {
            this.muteAudioWithDelay();
        }
    }

    managerPluginSuccessAttached(pluginHandle: any): void {
        this.videoRoomHandle = pluginHandle;

        if (this.onInitCall) {
            this.onInitCall();
        }

        Janus.log(
            'Plugin attached! (' + this.videoRoomHandle.getPlugin() + ', id=' + this.videoRoomHandle.getId() + ')'
        );
        Janus.log('  -- This is a publisher/manager');

        const register = {
            request: 'join',
            room: this.callId,
            pin: this.pin,
            ptype: 'publisher',
            display: this.userId.toString(),
        };

        this.videoRoomHandle.send({ message: register });
    }

    managerPluginAttachError(error: any): void {
        Janus.error('  -- Error attaching plugin.', error);
        //this.modal('Error attaching plugin. ' + error)
    }

    addSimulcastButtons(rfindex: any, videoCodecIsSupported: boolean) {
        // Это заглушка
    }

    updateSimulcastButtons(rfindex: any, substream: any, temporal: any) {
        // Это заглушка
    }

    subscribeToRemoteFeed(sources: any) {
        // Проверква созднан ли дескриптор подписки или нет
        if (this.creatingSubscription && this.countSubscribeTries < 10) {
            // Если нет, то ждем пока не будет создан
            setTimeout(() => {
                this.countSubscribeTries++;
                this.subscribeToRemoteFeed(sources);
            }, 500);
            return;
        }

        this.countSubscribeTries = 0;

        // Если у нас уже есть работающий дескриптор подписки, то обновляем его
        if (this.remoteFeed) {
            // Подготовляем потоки для подписки в виде массива: у нас есть список потоков,
            // которые публикуют каналы, поэтому мы можем выбрать, что добавить, а что удлаить.
            let added = null, removed = null;
            for (let s in sources) {
                let streams = sources[s];
                for (let i in streams) {
                    let stream = streams[i];
                    // Если издатель VP8/VP9 - это старая версия Safari, пропускаем его
                    if (stream.type === 'video' && Janus.webRTCAdapter.browserDetails.browser === 'safari' &&
                        ((stream.codec === 'vp9' && !Janus.safariVp9) || (stream.codec === 'vp8' && !Janus.safariVp8))) {
                        Janus.log('Publisher is using ' + stream.codec.toUpperCase + ', but Safari doesn\'t support it: disabling video stream #' + stream.mindex);
                        continue;
                    }
                    if (stream.disabled) {
                        Janus.log('Disabled stream:', stream);
                        // Отписка
                        if (!removed) {
                            removed = [];
                        }
                        removed.push({
                            feed: stream.id,	// Обязательный параметр
                            mid: stream.mid,	// Опциональный (все потоки, если не указать)
                        });

                        if (this.subscriptions[stream.id]) {
                            delete this.subscriptions[stream.id][stream.mid];
                        }

                        const isSharing = stream.display.startsWith('sharing-');
                        this.connectRemoteStream({
                            rfid: stream.id,
                            rfdisplay: isSharing ? stream.display.replace(/^sharing-/g, '') : stream.display,
                            issharing: isSharing
                        }, {
                            ...stream,
                            kind: stream.type
                        });

                        continue;
                    }
                    if (this.subscriptions[stream.id] && this.subscriptions[stream.id][stream.mid]) {
                        Janus.log('Already subscribed to stream, skipping:', stream);
                        continue;
                    }
                    // Подниписка
                    if (!added) {
                        added = [];
                    }
                    added.push({
                        feed: stream.id,	// Обязательный параметр
                        mid: stream.mid,	// Опциональный (все потоки, если не указать)
                    });
                    if (!this.subscriptions[stream.id]) {
                        this.subscriptions[stream.id] = {};
                    }
                    this.subscriptions[stream.id][stream.mid] = true;

                    const feed = this.feedStreams[stream.id];
                    this.afterAttachRemoteFeed({
                        rfid: feed.id,
                        rfdisplay: feed.display,
                    });
                }
            }
            if ((!added || added.length === 0) && (!removed || removed.length === 0)) {
                // ничего не делаем
                return;
            }
            let update: any = { request: 'update' };
            if (added) {
                update.subscribe = added;
            }
            if (removed) {
                update.unsubscribe = removed;
            }

            this.remoteFeed.send({ message: update });
            // дальше делать ни чего не нужно
            return;
        }

        // создаем новый дескриптор для подписок (нам нужен только один)
        this.creatingSubscription = true;
        this.janus?.attach(
            {
                plugin: 'janus.plugin.videoroom',
                opaqueId: this.opaqueId,
                success: (pluginHandle) => {
                    this.remoteFeed = pluginHandle;
                    Janus.log('Plugin attached! (' + this.remoteFeed.getPlugin() + ', id=' + this.remoteFeed.getId() + ')');
                    Janus.log('  -- This is a multistream subscriber');
                    // Подготовляем потоки для подписки в виде массива: у нас есть список потоков,
                    // которые публикуют каналы, поэтому мы можем выбрать, что добавить, а что удлаить.
                    let subscription = [];
                    for (let s in sources) {
                        let streams = sources[s];
                        for (let i in streams) {
                            let stream = streams[i];
                            // Если издатель VP8/VP9 - это старая версия Safari, пропускаем его
                            if (stream.type === 'video' && Janus.webRTCAdapter.browserDetails.browser === 'safari' &&
                                ((stream.codec === 'vp9' && !Janus.safariVp9) || (stream.codec === 'vp8' && !Janus.safariVp8))) {
                                Janus.log('Publisher is using ' + stream.codec.toUpperCase + ', but Safari doesn\'t support it: disabling video stream #' + stream.mindex);
                                continue;
                            }
                            if (stream.disabled) {
                                Janus.log('Disabled stream:', stream);
                                continue;
                            }
                            Janus.log('Subscribed to ' + stream.id + '/' + stream.mid + '?', this.subscriptions);
                            if (this.subscriptions[stream.id] && this.subscriptions[stream.id][stream.mid]) {
                                Janus.log('Already subscribed to stream, skipping:', stream);
                                continue;
                            }
                            subscription.push({
                                feed: stream.id,
                                mid: stream.mid,
                            });
                            if (!this.subscriptions[stream.id]) {
                                this.subscriptions[stream.id] = {};
                            }
                            this.subscriptions[stream.id][stream.mid] = true;

                            const feed = this.feedStreams[stream.id];
                            this.afterAttachRemoteFeed({
                                rfid: feed.id,
                                rfdisplay: feed.display,
                            });
                        }
                    }
                    // Ждем, пока плагин отправит нам предложение
                    let subscribe = {
                        request: 'join',
                        room: this.callId,
                        ptype: 'subscriber',
                        streams: subscription,
                        private_id: this.privateId,
                        pin: this.pin,
                    };

                    this.remoteFeed.send({ message: subscribe });
                },
                error: function (error) {
                    Janus.error('  -- Error attaching plugin...', error);
                },
                iceState: function (state) {
                    Janus.log('ICE state (remote feed) changed to ' + state);
                },
                webrtcState: function (on) {
                    Janus.log('Janus says this WebRTC PeerConnection (remote feed) is ' + (on ? 'up' : 'down') + ' now');
                },
                onmessage: (msg: any, jsep) => {
                    Janus.debug(' ::: Got a message (subscriber) :::', msg);
                    let event = msg['videoroom'];
                    Janus.debug('Event: ' + event);
                    if (msg['error']) {
                        Janus.error('Event: ', msg['error']);
                    } else if (event) {
                        if (event === 'attached') {
                            // Теперь у нас есть рабочий дескриптор, следующие запросы будут его обновять
                            this.creatingSubscription = false;
                            Janus.log('Successfully attached to feed in room ' + msg['room']);
                        } else if (event === 'event') {
                            //
                        } else {
                            // What has just happened?
                        }
                    }
                    if (msg['streams']) {
                        // Словарь подписок по mid
                        for (let i in msg['streams']) {
                            let mid = msg['streams'][i]['mid'];
                            this.subStreams[mid] = msg['streams'][i];
                        }
                    }
                    if (jsep) {
                        Janus.debug('Handling SDP as well...', jsep);

                        this.remoteFeed?.createAnswer(
                            {
                                jsep: jsep,
                                // Здесь мы указываем только каналы данных, тк дорожки добавляются в другом месте
                                // и если он уже есть то они будут включены
                                tracks: [
                                    { type: 'data' },
                                ],
                                success: (jsep: any) => {
                                    Janus.debug('Got SDP!');
                                    Janus.debug(jsep);
                                    let body = {
                                        request: 'start',
                                        room: this.callId
                                    };
                                    this.remoteFeed?.send({ message: body, jsep: jsep });
                                },
                                error: function (error: any) {
                                    Janus.error('WebRTC error:', error);
                                },
                            });
                    }
                },
                // eslint-disable-next-line no-unused-vars
                onlocaltrack: function (track, on) {
                    // The subscriber stream is recvonly, we don't expect anything here
                },
                onremotetrack: (track: MediaStreamTrack, mid: any, on: boolean, metadata: any) => {
                    Janus.debug(
                        'Remote track (mid=' + mid + ') ' +
                        (on ? 'added' : 'removed') +
                        (metadata ? ' (' + metadata.reason + ') ' : '') + ':', track,
                    );
                    // Для какого publisher пришла дорожка?
                    // определяем это по mid?
                    let sub = this.subStreams[mid];
                    let feed = this.feedStreams[sub.feed_id];
                    Janus.debug(' >> This track is coming from feed ' + sub.feed_id + ':', feed);

                    // Здесь можно выполнить действия с UI перед подключением потока к элементу video.
                    // Здесь же можно отключить спиннер, если был запущен.
                    // Здесь же необходимо подключить поток к элементу video
                    if (on && feed) {
                        const isSharing = feed.display.startsWith('sharing-');

                        this.connectRemoteStream({
                            rfid: feed.id,
                            rfdisplay: isSharing ? feed.display.replace(/^sharing-/g, '') : feed.display,
                            issharing: isSharing
                        }, track);
                    }
                },
                oncleanup: () => {
                    Janus.log(' ::: Got a cleanup notification (remote feed) :::');

                    // В multistream варианте здесь делать по идеи ни чего не надо
                    // тк у нас один фид на всех и его уничтожение равносильно
                    // выходу из звонка, ну или что-то сломалось
                }
            });
    }

    unsubscribeFromRemoteFeed(id: number) {
        // Отписаться от этого паблишера
        let feed = this.feedStreams[id];
        if (!feed) {
            return;
        }
        Janus.debug('Feed ' + id + ' (' + feed.display + ') has left the room, detaching');

        delete this.feedStreams[id];

        // Отправить запрос на отписку
        let unsubscribe = {
            request: 'unsubscribe',
            streams: [{ feed: id }],
        };

        if (this.remoteFeed != null) {
            this.remoteFeed.send({ message: unsubscribe });
        }

        delete this.subscriptions[id];

        if (feed.display) {
            const isSharing = feed.display.startsWith('sharing-');
            this.cleanupRemoteFeed({
                rfid: feed.id,
                rfdisplay: isSharing ? feed.display.replace(/^sharing-/g, '') : feed.display,
                issharing: isSharing
            });
        }
    }

    onmessage(msg: any, jsep: any, params: any): void {
        Janus.debug(' ::: Got a message (publisher) :::', msg);
        const event = msg['videoroom'];
        Janus.debug('Event: ' + event);
        if (event) {
            if (event === 'joined') {
                this.privateId = msg['private_id'];
                this.userRemoteId = msg['id'];
                Janus.log('Successfully joined room ' + msg['room'] + ' with ID ' + msg['id']);

                this.publishOwnFeed(undefined, this.isUseDefaultSetting, msg['id'], params);

                if (msg['publishers']) {
                    // Кто-то уже в созвоне с опубликованным фидом
                    // Подготовка данных и вызов функции, подцепляющей под плагином комнаты каждого нового постучавшегося
                    const list = msg['publishers'];
                    Janus.debug('Got a list of available publishers/feeds:', list);
                    for (const p of list) {
                        // Janus.debug('  >> [' + id + '] ' + display + ' (audio: ' + audio + ', video: ' + video + ')')
                        Janus.debug(`  >> [${p.id}] ${p.display} (audio: ${p.audio}), video: ${p.video}`);

                        // Игнорируем свой шаринг
                        if (p.display.startsWith('sharing-')) {
                            if (p.display.replace(/^sharing-/g, '') === this.userId.toString()) {
                                continue;
                            }
                        }

                        let sources = null;
                        for (let f in list) {
                            if (list[f]['dummy']) {
                                continue;
                            }
                            let id = list[f]['id'];
                            let display = list[f]['display'];
                            let streams = list[f]['streams'];
                            for (let i in streams) {
                                let stream = streams[i];
                                stream['id'] = id;
                                stream['display'] = display;
                            }
                            let slot = this.feedStreams[id] ? this.feedStreams[id].slot : null;
                            let remoteVideos = this.feedStreams[id] ? this.feedStreams[id].remoteVideos : 0;
                            this.feedStreams[id] = {
                                id: id,
                                display: display,
                                streams: streams,
                                slot: slot,
                                remoteVideos: remoteVideos,
                            };

                            Janus.debug('  >> [' + id + '] ' + display + ':', streams);

                            if (!sources) {
                                sources = [];
                            }
                            sources.push(streams);
                        }

                        if (sources) {
                            setTimeout(() => this.subscribeToRemoteFeed(sources), 100);
                        }
                    }
                }
                if (msg['attendees']) {
                    // Кто-то уже в созвоне без опубликованного фида
                    for (const attendee of msg['attendees']) {
                        this.processNewAttendee(attendee);
                    }
                }
            } else if (event === 'destroyed') {
                Janus.warn('The room has been destroyed!');
                const callStore = useCallStore();

                // Показываем информацию только в том случае, если звонок активен
                // если мы находимся на странице завешения звонка - не показываем
                if (callStore.roomEnabled) {
                    this.modal('The room has been destroyed', this.afterRoomDestroyed);
                }

            } else if (event === 'event') {
                if(msg["streams"]) {
                    let streams = msg["streams"];
                    for(let i in streams) {
                        let stream = streams[i];
                        stream["id"] = this.userRemoteId;
                    }
                    this.feedStreams[this.userRemoteId] = streams;
                } else if (msg['publishers']) {
                    // Кто-то постучался в созвон
                    // Подготовка данных и вызов функции
                    // подцепляющей плагин комнаты для каждого нового постучавшегося
                    const list = msg['publishers'];
                    Janus.debug('Got a list of available publishers/feeds:', list);
                    for (const p of list) {
                        // Janus.debug('  >> [' + id + '] ' + display + ' (audio: ' + audio + ', video: ' + video + ')')
                        Janus.debug(`  >> [${p.id}] ${p.display} (audio: ${p.audio}), video: ${p.video}`);

                        // Игнорируем свой шаринг
                        if (p.display.startsWith('sharing-')) {
                            if (p.display.replace(/^sharing-/g, '') === this.userId.toString()) {
                                continue;
                            }
                        }

                        let sources = null;
                        for (let f in list) {
                            if (list[f]['dummy']) {
                                continue;
                            }
                            let id = list[f]['id'];
                            let display = list[f]['display'];
                            let streams = list[f]['streams'];
                            for (let i in streams) {
                                let stream = streams[i];
                                stream['id'] = id;
                                stream['display'] = display;
                            }
                            let slot = this.feedStreams[id] ? this.feedStreams[id].slot : null;
                            let remoteVideos = this.feedStreams[id] ? this.feedStreams[id].remoteVideos : 0;
                            this.feedStreams[id] = {
                                id: id,
                                display: display,
                                streams: streams,
                                slot: slot,
                                remoteVideos: remoteVideos,
                            };

                            Janus.debug('  >> [' + id + '] ' + display + ':', streams);
                            if (!sources) {
                                sources = [];
                            }
                            sources.push(streams);
                        }

                        if (sources) {
                            this.subscribeToRemoteFeed(sources);
                        }
                    }
                } else if(msg["leaving"]) {
                    // Один из паблишеров ушел
                    let leaving = msg["leaving"];
                    Janus.log("Publisher left: " + leaving);
                    this.unsubscribeFromRemoteFeed(leaving);
                } else if(msg["unpublished"]) {
                    // Один из паблишеров ушел
                    let unpublished = msg["unpublished"];
                    Janus.log("Publisher left: " + unpublished);
                    if(unpublished === 'ok') {
                        // That's us
                        this.videoRoomHandle?.hangup();
                        return;
                    }
                    this.unsubscribeFromRemoteFeed(unpublished);
                }  else if (msg['joining']) {
                    // Кто-то подключился к комнате
                    this.processNewAttendee(msg['joining']);
                }
            } else if (event === 'talking') {
                janusEventBus.emit(JanusEvents.onStartTalking, msg);
            } else if (event === 'stopped-talking') {
                janusEventBus.emit(JanusEvents.onStopTalking, msg);
            } else if (msg['leaving']) {
                // Кто-то отключился
                const leaving = msg['leaving'];
                Janus.log('Publisher left: ' + leaving);
                // Поиск того, кто отключился, и удаление из интерфейса
                let remoteFeed = null;

                for (let i = 1; i < this.maxInterlocutorNumber; i++) {
                    if (this.feeds[i] && this.feeds[i].rfid == leaving) {
                        remoteFeed = this.feeds[i];
                        break;
                    }
                }

                if (remoteFeed != null) {
                    Janus.debug('Feed ' + remoteFeed.rfid + ' (' + remoteFeed.rfdisplay + ') has left the room, detaching');
                    this.removeFeed(remoteFeed.rfdisplay);
                    this.feeds[remoteFeed.rfindex] = null;
                    remoteFeed.detach();
                }
            } else if (msg['unpublished']) {
                // Кто-то отключился, в т.ч. сам пользователь
                const unpublished = msg['unpublished'];
                Janus.log('Publisher left: ' + unpublished);
                // Проверка - сам пользователь?
                if (unpublished === 'ok') {
                    // That's us
                    this.videoRoomHandle.hangup();
                    return;
                }
                // Кто-то другой
                // Поиск - кто отключился, и удаление из интерфейса
                let remoteFeed = null;

                for (let i = 1; i < this.maxInterlocutorNumber; i++) {
                    if (
                        this.feeds[i] &&
                        (this.feeds[i].rfid == unpublished || this.feeds[i].rfsharingid == unpublished)
                    ) {
                        remoteFeed = this.feeds[i];
                        break;
                    }
                }

                if (remoteFeed != null) {
                    Janus.debug(
                        'Feed ' + remoteFeed.rfid + ' (' + remoteFeed.rfdisplay + ') has left the room, detaching'
                    );
                    this.removeFeed(remoteFeed.rfdisplay);
                    this.feeds[remoteFeed.rfindex] = null;
                    remoteFeed.detach();
                }
            } else if (msg['error']) {
                this.modal(msg['error']);
            }
        }
        if (jsep) {
            Janus.debug('Handling SDP as well...', jsep);
            this.videoRoomHandle.handleRemoteJsep({ jsep });
        }
    }

    /** Обработать подключенного удаленного пользователя у которого пока что нет фида */
    private processNewAttendee(attendee: { id: number; display: string }) {
        if (attendee.display.startsWith('sharing-')) {
            attendee.display = attendee.display.replace(/^sharing-/g, '');
            //Игнорируем свой шаринг
            if (attendee.display === this.userId.toString()) {
                return;
            }
        }
        const userId = Number(attendee.display);
        if (!isNaN(userId)) {
            janusEventBus.emit(JanusEvents.onRemoteUserJoinedRoom, userId);
        }
    }

    removeLocalTracks(track: MediaStreamTrack): void {
        if (track.kind === 'audio') {
            this.userStream?.getAudioTracks().forEach((x: MediaStreamTrack) => {
                this.userStream?.removeTrack(x);
            });
        }

        if (track.kind === 'video') {
            this.userStream?.getVideoTracks().forEach((x: MediaStreamTrack) => {
                this.userStream?.removeTrack(x);
            });
        }
    }

    // Собираем стрим из аудио и видео дорожек, старые дорожки удаляем чтобы были только актуальные
    onlocaltrack(track: MediaStreamTrack, enable: boolean): void {
        Janus.debug(' ::: Got a local stream :::', track);

        if (!this.userStream) {
            this.userStream = new MediaStream();
        }

        // если enabled === false то дорожка была удалена из потока
        // то есть выключили видео или аудио
        if (track.enabled && enable) {
            this.removeLocalTracks(track);
            this.userStream.addTrack(track);
        }

        janusEventBus.emit(JanusEvents.onLocalStreamReady, this.userStream);
    }

    onremotetrack(track: MediaStreamTrack): void {
        // The publisher stream is send only, we don't expect anything here
    }

    // tries - количество попыток переподключения
    // isUseDefaultSettings - используетм дефолтные настройки или текущие
    // params - настройки предыдущего плагина, передается при переподключении
    publishOwnFeed(tries?: number, isUseDefaultSettings?: boolean, userRemoteId?: number, params?: any, keepVideo?: boolean) {
        if (tries && tries >= 5) {
            return;
        }

        // Здесь видео всегда выключено,
        // так как включение видео теперь происходит в компоненте src/pages/Call/components/UserStream.vue
        // Там поток подготавливает и передается в Janus
        let videoIsMuted = true;//isUseDefaultSettings ? this.isMutedVideoDefaultSetting : (this.isVideoMuted ?? true);
        let audioIsMuted = isUseDefaultSettings ? this.isMutedAudioDefaultSetting : (this.isAudioMuted ?? true);

        if (params) {
            audioIsMuted = params.isAudioMuted;
        }

        const offerMedia: OfferParamsMedia = {
            video: false
        };

        if (keepVideo && !this.isVideoMuted) {
            videoIsMuted = false;
        }

        if (!videoIsMuted && this.selectedVideoInputDeviceId && this.isVideoInputAvailable) {
            offerMedia.video = { deviceId: this.selectedVideoInputDeviceId };
            offerMedia.replaceVideo = true;
        }

        if (!audioIsMuted && this.isAudioInputAvailable) {
            offerMedia.audio = this.selectedAudioInputDeviceId ? { deviceId: this.selectedAudioInputDeviceId } : true;
            offerMedia.replaceAudio = true;
        }

        this.videoRoomHandle.createOffer({
            tracks: [
                { type: 'audio', capture: audioIsMuted ? audioIsMuted : offerMedia.audio, recv: true }
            ],
            success: (jsep: any) => {
                Janus.debug('Got publisher SDP!', jsep);

                const isSharing = false;
                const fileName = this.getRecordFileName(isSharing);

                const publish = {
                    request: 'configure',
                    audio: !audioIsMuted,
                    video: !videoIsMuted,
                    data: true,
                    filename: fileName
                };

                if (videoIsMuted && !this.isVideoMuted) {
                    this.videoRoomHandle.muteVideo();
                }

                if (audioIsMuted && !this.isAudioMuted) {
                    this.videoRoomHandle.muteAudio();
                }

                this.videoRoomHandle.send({ message: publish, jsep });

                this.onPublishOwnFeed(userRemoteId);

                if (params && params.callback) {
                    params.callback();
                }
            },
            error: async (error: any) => {
                await this.initMediaDevicesAsync();
                if (!tries) {
                    tries = 0;
                }
                ++tries;
                this.publishOwnFeed(tries, isUseDefaultSettings, userRemoteId, params);
                Janus.error(error);
            },
        });
    }

    startVideoRoomHandlers(params?: any): void {
        this.janus?.attach({
            plugin: JANUS_PG_VIDEOROOM,
            opaqueId: this.opaqueId,
            success: this.managerPluginSuccessAttached.bind(this),
            error: (error: any) => {
                this.managerPluginAttachError(error);
            },
            consentDialog: (on: boolean) => {
                Janus.debug('Consent dialog should be ' + (on ? 'on' : 'off') + ' now');
            },
            iceState: (state: any) => {
                Janus.log('ICE state changed to ' + state);

                if (state === 'disconnected') {
                    this.tryToReconnect(0);
                }
            },
            webrtcState: (on: boolean) => {
                Janus.log('Janus says our WebRTC PeerConnection is ' + (on ? 'up' : 'down') + ' now');

                if (!on) {
                    return;
                }
            },
            onmessage: (msg: any, jsep: any) => {
                this.onmessage(msg, jsep, params);
            },
            onlocaltrack: (track: MediaStreamTrack, on: boolean) => {
                this.onlocaltrack(track, on);
            },
            onremotetrack: (track: MediaStreamTrack) => {
                this.onremotetrack(track);
            },
            oncleanup: () => {
                Janus.log(' ::: Got a cleanup notification: we are unpublished now :::');
                this.onCallFinished();
            },
        });
    }

    // 12 попыток переподключения - одна минута
    tryToReconnect(tries: number): void {
        if (!this.janus || this.isReconnecting || tries >= 12) {
            return;
        }

        this.isReconnecting = true;

        const callbacks = {
            success: () => {
                if (this.changeReconnectingState) {
                    const reconnectHandle = () => {
                        this.changeReconnectingState(false);
                        this.isReconnecting = false;

                        if (this.isRecordingEnabled) {
                            // при реконнекте повторно инициируем запись аудио и видео, так как возможны сценарии при сбое связи,
                            // когда файлы не сформируются при восстановлении связи
                            this.enableRecording();
                        }
                    }

                    // Если отвалился PeerConnection то простым способом переподключиться не получится
                    // Поэтому создаем новый экземпляр плагина комнаты
                    if (this.videoRoomHandle.webrtcStuff.pc &&
                        (this.videoRoomHandle.webrtcStuff.pc.iceConnectionState === 'disconnected'
                        || this.videoRoomHandle.webrtcStuff.pc.iceConnectionState === 'failed'))
                    {
                        // Передаем настройки текущего плагина
                        // тк в publishOwnFeed функция this.isAudioMuted будет вызываться для нового плагина
                        // и будет возвращать для него true (т.е. микрофон выключен)
                        const params = {
                            isAudioMuted: this.videoRoomHandle.isAudioMuted(),
                            isVideoMuted: this.videoRoomHandle.isVideoMuted(),
                            callback: () => {
                                if (!params.isVideoMuted) {
                                    janusEventBus.emit(JanusEvents.startVideoStream);
                                }

                                setTimeout(reconnectHandle, 1000);
                            }
                        };

                        if (!params.isVideoMuted) {
                            janusEventBus.emit(JanusEvents.stopVideoStream);

                            this.userStream?.getVideoTracks().forEach((x: MediaStreamTrack) => {
                                x.stop();
                                this.userStream?.removeTrack(x);
                            });
                        }

                        // this.videoRoomHandle.detach({
                        //     success: () => {
                        //         this.startVideoRoomHandlers(params);
                        //     }
                        // });

                        buttonEventBus.emit(ButtonBusEvents.Relocate);
                    } else {
                        reconnectHandle();
                    }
                }
            },
            error: () => {
                setTimeout(() => {
                    this.isReconnecting = false;
                    this.tryToReconnect(++tries);
                }, 5000);
            }
        }

        if (this.changeReconnectingState) {
            this.changeReconnectingState(true);
        }

        this.janus.reconnect(callbacks);
    }

    createJanusObject(): void {
        if (!Janus.isWebrtcSupported()) {
            this.modal('No WebRTC support...');
            return;
        }

        this.janus = new Janus({
            server: this.server,
            iceServers: this.iceServers,
            success: () => {
                this.startVideoRoomHandlers();
            },
            error: (error: any) => {
                Logger.log('error', error);

                this.tryToReconnect(0);

                Janus.error(error);
            },
            destroyed: () => {
                this.afterDestroyed();
            },
        });
    }
}

enum JanusInternalEvents {
    stopSharingAsyncPromiseResolved = 'stopSharingAsyncPromiseResolved',
}

export enum JanusEvents {
    modal = 'modal',
    getBitrate = 'getBitrate',
    onUnpublishOwnFeed = 'onUnpublishOwnFeed',
    onLocalStreamReady = 'onLocalStreamReady',
    onPublishOwnFeed = 'onPublishOwnFeed',
    removeFeed = 'removeFeed',
    afterAttachRemoteFeed = 'afterAttachRemoteFeed',
    connectRemoteStream = 'connectRemoteStream',
    cleanupRemoteFeed = 'cleanupRemoteFeed',
    afterRoomDestroyed = 'afterRoomDestroyed',
    onSharingStarted = 'onSharingStarted',
    onSharingStopped = 'onSharingStopped',
    onSharingError = 'onSharingError',
    /** Событие происходит когда изменяется список доступных устройств*/
    onMediaDevicesChanged = 'onMediaDevicesChanged',
    /** Событие происходит когда удаленный пользователь подключается к комнате*/
    onRemoteUserJoinedRoom = 'onRemoteUserJoinedRoom',
    onStartTalking = 'onStartTalking',
    onStopTalking = 'onStopTalking',
    /* Вызывается чтобы выключить видео при переподключении к звонку при потере соединения */
    stopVideoStream = 'stopVideoStream',
    /* Вызывается чтобы включить видео при переподключении к звонку при потере соединения */
    startVideoStream = 'startVideoStream',
}

/** Остановка стрима */
export function stopStream(stream: MediaStream) {
    if (stream) {
        const tracks = stream.getTracks();
        for (const track of tracks) {
            if (track) {
                try {
                    track.stop();
                } catch (e) {}
            }
        }
    }
}

/* Класс для статических методов упрощающих работу с библиотекой Janus*/
export class JanusHelper {
    /* Метод для получения списка доступных браузеру устройств ввода/вывода аудио/видео информации*/
    static async getMediaDevicesAsync(): Promise<{ devices: MediaDeviceInfo[], config?: MediaStreamConstraints }> {
        return await this.tryGetMediaDevicesAsync();
    }

    /*
    Пытаемся получить информацию об устройствах доступных браузеру ввода/вывода аудио/видео информации.

    Для это этого создаем стрим с помощью navigator.mediaDevices.getUserMedia.
    Подбираем настройку MediaStreamConstraints - это настройки использовать ли камеру, использовать ли аудио.
    Если у пользователя стоят настройки, что сайту запрещено пользоваться аудио или видео,
    стандартная настройка({audio:true, video:true}) выдаст ошибку - для этого и подбор.
    После того, как мы получили стрим, получаем устройства через navigator.mediaDevices.enumerateDevices()
    и останавливаем стрим останавливая каждый трек в нем.

    По сути это копия метода Janus.listDevices, но дополнительно мы отлавливаем ошибки,
    происходящую при попытке получить доступ к заблокированным устройствам, чтобы подобрать значение MediaStreamConstraints.
    */
    private static tryGetMediaDevicesAsync(
        configForStream?: MediaStreamConstraints
    ): Promise<{ devices: MediaDeviceInfo[]; config?: MediaStreamConstraints}> {
        return new Promise((resolve) => {
            if (!configForStream) {
                // Начальное предположение. Если оно даст ошибку, значения будут подбираться дальше
                configForStream = { audio: true, video: true };
            }
            //Проверяем, что вообще можем стримить стандартными методами(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices
                    .getUserMedia(configForStream)
                    .then(function(stream) {
                        navigator.mediaDevices.enumerateDevices().then(function(devices) {
                            // Избавляемся от стрима
                            try {
                                const tracks = stream.getTracks();
                                for (const mst of tracks) {
                                    if (mst) {
                                        mst.stop();
                                    }
                                }
                            } catch (e) {}
                            if (!configForStream?.video) {
                                devices = devices.filter((x) => x.kind !== 'videoinput');
                            }

                            resolve({ devices, config: configForStream });
                        });
                    })
                    .catch(async () => {
                        if (!configForStream) {
                            configForStream = { audio: false, video: false };
                            resolve({ devices: [], config: configForStream });
                            return;
                        }
                        //Подбираем конфигурацию при ошибке
                        if (configForStream.audio && configForStream.video) {
                            configForStream = { audio: false, video: true };
                        } else if (!configForStream.audio && configForStream.video) {
                            configForStream = { audio: true, video: false };
                        } else if (configForStream.audio && !configForStream.video) {
                            configForStream = { audio: false, video: false };
                            resolve({ devices: [], config: configForStream });
                            return;
                        }

                        const result = await this.tryGetMediaDevicesAsync(configForStream);
                        resolve(result);
                    });
            } else {
                resolve({ devices: [] });
            }
        });
    }
}
