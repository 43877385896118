<template>
    <div class="call-message">
        <div :class="{ 'text-shade-8': !call.isActive }">
            <div class="title">{{ title }}</div>
            <div class="call-duration">
                {{ durationTime }}
            </div>
        </div>

        <div class="flex no-wrap justify-between items-end q-mt-md">
            <div class="flex no-wrap cursor-pointer" @click="showChatMembers">
                <div
                    v-for="member in members"
                    :key="member.id"
                    class="call-avatar"
                    :class="{ grey: !call.isActive }"
                    aria-hidden="true"
                >
                    <q-avatar size="32px">
                        <img
                            v-if="member.photoUrl"
                            :src="member.photoUrl"
                            alt=""
                        />
                        <custom-avatar
                            v-else
                            :color="CustomAvatarColors.User"
                            :text="member.lastName[0] + member.firstName[0]"
                        />
                    </q-avatar>
                </div>
                <div
                    v-if="call.callMembers.length > 3"
                    class="call-avatar more"
                    :class="[call.isActive ? 'bg-link' : 'bg-shade-8']"
                >
                    +{{ call.callMembers.length - 3 }}
                </div>
            </div>

            <q-btn
                v-if="call.isActive"
                color="primary"
                class="flex no-wrap items-center justify-center open-call"
                :style="{ height: '32px' }"
                @click="acceptCall"
            >
                {{ localize('Присоединиться') }}
            </q-btn>

            <div v-if="!call.isActive" class="flex column items-end">
                <div class="members-count" @click="showChatMembers">
                    {{ membersText(call.callMembers.length) }}
                </div>

                <div class="time">{{ messageTime }}</div>
            </div>
        </div>

        <call-chat-members
            ref="callChatMembersRef"
            :call-members="call.callMembers"
        />
    </div>
</template>

<script lang="ts">
    import CallChatMembers from 'components/Chat/components/CallChatMembers.vue';
    import { CallMessageDto, RoutePageNameEnum, UserBaseInfoDto } from 'src/api/ApiClient';
    import { localize } from 'src/services/LocalizationService';
    import { Common } from 'src/helpers/Common';
    import DateUtil from 'src/helpers/DateUtil';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import { callBus } from 'components/EventBuses';
    import {
        computed,
        defineComponent,
        onBeforeMount,
        onBeforeUnmount,
        onMounted,
        PropType,
        ref,
        toRefs,
    } from 'vue';
    import { useRouter } from 'vue-router';
    import { CallBusEvents } from 'components/EventBuses/emuns';

    export default defineComponent({
        name: 'CallMessage',

        components: {
            CallChatMembers,
        },

        emits: ['on-update-call'],

        props: {
            // Информация о звонке
            call: {
                type: Object as PropType<CallMessageDto>,
                required: true
            },
            messageTime: {
                type: String,
                default: ''
            },
            chatId: {
                type: Number,
                default: 0
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props) {
            const $router = useRouter();

            const callChatMembersRef = ref<typeof CallChatMembers>();

            let timerIntervalId: ReturnType<typeof setInterval> | null = null;

            // Превращаем поля объекта в ссылки чтобы можно было менять значение
            const callRef = toRefs(props.call);

            // Продолжительность звонка
            const durationTime = computed<string>(() => {
                callRef.startedDateTimeUtc.value = DateUtil.trimTimeZone(props.call.startedDateTimeUtc);
                let endedDateTimeUtc = props.call.endedDateTimeUtc;

                if (!endedDateTimeUtc) {
                    endedDateTimeUtc = new Date().toServerFormat(false);
                }

                const countSeconds = (new Date(endedDateTimeUtc).getTime() - new Date(props.call.startedDateTimeUtc).getTime()) / 1000;

                return (props.call.isActive ? localize('Идёт ') : localize('Длился ')) +
                    DateUtil.getDurationTimeString(countSeconds, true);
            });

            const title = computed<string>(() => {
                return localize(`Звонок ${props.call.isActive ? 'начат' : 'завершён'}`);
            });

            // Список пользователей для отображения в звонке
            const members = computed<UserBaseInfoDto[]>(() => {
                // Копируем массив, т.к sort мутирует исходные данные
                const copyMembers = [...props.call.callMembers];

                // При помощи sort перемешаем пользователей без
                // аватара в самый конец массива
                return copyMembers
                    .sort((a: UserBaseInfoDto) => {
                        return a.photoUrl ? -1 : 1;
                    })
                    .slice(0, 3);
            });

            // Принять звонок - открываем страницу звонка
            function acceptCall(): void {
                const { href } = $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.CallEnter),
                    params: { id: props.chatId.toString() }
                });

                window.open(href, '_blank');
            }

            function membersText(number: number): string {
                return number.cased(
                    localize('участник'),
                    localize('участника'),
                    localize('участников'),
                    true
                );
            }

            // Показать окно с участниками чата
            function showChatMembers(): void {
                if (callChatMembersRef.value) {
                    callChatMembersRef.value.isVisible = true;
                }
            }

            // Функция вызывается, когда звонок завершен
            function onEndCall(callId: number): void {
                if (props.call.id === callId) {
                    callRef.isActive.value = false;
                    callRef.endedDateTimeUtc!.value = new Date().toServerFormat(false);

                    if (timerIntervalId) {
                        clearInterval(timerIntervalId);
                    }
                }
            }

            // Обновляем длительность звонка раз в минуту
            function startTimer(): void {
                if (props.call.isActive) {
                    timerIntervalId = setInterval(() => {
                        let endedDateTimeUtc = props.call.endedDateTimeUtc;
                        let endTime;

                        if (!endedDateTimeUtc) {
                            endedDateTimeUtc = new Date().toServerFormat(false);
                            endTime = new Date(endedDateTimeUtc).getTime();
                        } else {
                            endTime = new Date(endedDateTimeUtc).getTime();
                            // Шаг в 30 секунд
                            endTime += 1000 * 30;
                        }

                        callRef.endedDateTimeUtc!.value = new Date(endTime).toServerFormat();
                    }, 30000);
                }
            }

            onBeforeMount(() => {
                // Вешаем обрабочик на события окончания звонка
                callBus.on(CallBusEvents.EndOFCall, onEndCall);
            });

            onMounted(startTimer);

            onBeforeUnmount(() => {
                callBus.off(CallBusEvents.EndOFCall);

                if (timerIntervalId) {
                    clearInterval(timerIntervalId);
                }
            });

            return {
                CustomAvatarColors,
                callChatMembersRef,
                title,
                durationTime,
                members,
                acceptCall,
                membersText,
                showChatMembers,
                localize,
            };
        }
    });
</script>

<style lang="scss" scoped>
    .open-call {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.2px;
        text-align: center;
    }

    .call-duration {
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: -0.2px;
    }

    .call-avatar {
        display: flex;

        &:not(:first-child) {
            ::v-deep(.q-avatar) {
                margin-left: -16px;
            }
        }

        &.grey {
            filter: grayscale(1);
        }

        &.bg-link {
            background: $link;
        }

        &.bg-shade-8 {
            background: $shade-8;
        }

        img,
        &.more,
        ::v-deep(.q-avatar) {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }

        img,
        &.more {
            border: 2px solid #FFFFFF;
        }

        &.more {
            z-index: 5;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.2px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            margin-left: -16px;
        }
    }

    .time {
        color: $shade-8;
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: -0.2px;
        margin-top: 8px;
    }

    .members-count {
        cursor: pointer;
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: -0.2px;
        color: $link;
    }
</style>
