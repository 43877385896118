<template>
    <div
        v-if="mainMessageInThread"
        :class="{ 'mobile-view': isMobileView || modeChat === 'inline' }"
        class="thread-chat-block"
    >
        <header class="thread-header row justify-between items-center">
            <div class="col">
                <div class="row items-center">
                    <q-icon
                        v-if="isVisibleBackButton"
                        name="arrow_back"
                        size="20px"
                        class="q-mr-sm cursor-pointer"
                        @click="closeThread"
                    />
                    <div class="relative-position">
                        <q-avatar
                            v-if="parentChat && chatInfo && isSetOrZero(chatInfo.parentChatType)"
                            rounded
                            size="24px"
                        >
                            <img v-if="avatarPath" :src="avatarPath" alt="" />
                            <custom-avatar
                                v-else
                                :text="customAvatar.text"
                                :color="customAvatar.color"
                            />
                        </q-avatar>
                        <CountUnreadedMessages
                            v-if="chatInfo"
                            :chat-id="chatInfo.id"
                            mode="mini"
                            class="absolute-top-right"
                            style="margin: -2px;"
                        />
                    </div>
                    <div class="col q-ml-md">
                        <div>{{ localize('Обсуждение') }}</div>
                        <div class="text-description ellipsis">{{ chatName }}</div>
                    </div>
                </div>
            </div>
        </header>
        <q-separator />
        <MessagesBlock
            ref="messagesBlockRef"
            :main-message-in-thread="mainMessageInThread"
            :chat-info="chatInfo"
            :mode-chat="modeChat"
            :is-archive-chat="isArchiveChat"
            :is-thread-chat="true"
            :is-mobile-view="isMobileView"
            :is-chat-in-muted-section-or-discipline="isChatInMutedSectionOrDiscipline"
            @scroll-mo-main-message="scrollToMainMessage"
        />
        <BottomPanel
            ref="bottomPanelRef"
            :mode-chat="modeChat"
            :chat-info="chatInfo"
            :is-mobile-view="isMobileView"
            :is-thread-chat="true"
            :is-archive-chat="isArchiveChat"
            @on-send-message="onSend"
            @add-new-message-to-chat="addNewMessageToChat"
            @on-send-message-error="onErrorHandler"
        />
    </div>
</template>

<script lang="ts">
    import { localize } from 'src/services/LocalizationService';
    import MessagesBlock from './MessagesBlock.vue';
    import BottomPanel from './BottomPanel.vue';
    import {
        ChatBaseInfoResponseModel,
        ChatClient,
        ChatMessageDto,
        ChatMessageReactionDto,
        ChatType,
        ListChatDto,
        RoutePageNameEnum,
    } from 'src/api/ApiClient';
    import { ErrorMessageDto } from 'src/services/hubs/types/interfaces';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { IChatMessage } from 'components/Chat/types/interfaces';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import CountUnreadedMessages from 'components/CountUnreadedMessages/CountUnreadedMessages.vue';
    import { Common } from 'src/helpers/Common';
    import { computed, defineComponent, PropType, ref } from 'vue';
    import { ModeChat } from 'components/Chat/types/enums';
    import { useRoute } from 'vue-router';
    import { useAccountStore } from 'src/store/module-account';
    import { useChatStore } from 'src/store/module-chat';

    export default defineComponent({
        name: 'ThreadChat',

        components: {
            CountUnreadedMessages,
            BottomPanel,
            MessagesBlock,
        },

        emits: [
            'go-to-message-in-chat',
            'set-chat-id'
        ],

        props: {
            // Чат, в котором находится сообщение, у которое есть тред
            parentChat: {
                type: Object as PropType<ChatBaseInfoResponseModel | undefined | null>,
                required: false,
                default: null
            },
            // Архивный чат, показывается в левой панели на странице чатов
            isArchiveChat: {
                type: Boolean,
                default: false
            },
            // Мобильный вид чата
            isMobileView: {
                type: Boolean,
                default: false
            },
            // Режим отображения чата
            modeChat: {
                type: String,
                default: ModeChat.Inline
            },
            //Замьючена ли чат секция или дисциплина, в которой находится чат
            isChatInMutedSectionOrDiscipline: {
                type: Boolean,
                required: true
            }
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            const $route = useRoute();
            const chatStore = useChatStore();
            const accountStore = useAccountStore();

            const messagesBlockRef = ref<InstanceType<typeof MessagesBlock> | null>(null);
            const bottomPanelRef = ref<InstanceType<typeof BottomPanel> | null>(null);

            const chatInfo = ref<ChatBaseInfoResponseModel | null>(null);
            const isLoadingData = ref<boolean>(false);
            let messageToViewId: number | undefined = undefined;
            let mainTheadMessageCache: IChatMessage | null = null;

            // Данные для отображения кастомного аватара
            const customAvatar = computed<{
                text: string;
                color: CustomAvatarColors;
            }>(() => {
                if (props.parentChat?.type === ChatType.Group || chatInfo.value?.parentChatType === ChatType.Group) {
                    return {
                        text: localize('Гч'),
                        color: CustomAvatarColors.GroupChat,
                    };
                } else if (props.parentChat?.type === ChatType.Discipline || chatInfo.value?.parentChatType === ChatType.Discipline) {
                    return {
                        text: localize('Д'),
                        color: CustomAvatarColors.Discipline,
                    };
                } else {
                    return {
                        text: localize('Л'),
                        color: CustomAvatarColors.User,
                    };
                }
            });

            // Путь к аватарке чата
            const avatarPath = computed<string>(() => {
                return props.parentChat?.chatAvatarPath || chatInfo.value?.chatAvatarPath || '';
            });

            // Главное сообщение обсуждения, т.е. то, которое обсуждают
            const mainMessageInThread = computed<IChatMessage | null>(() => {
                const message: IChatMessage | null = chatStore.mainMessageInThread;

                if (message && (mainTheadMessageCache?.id !== message.id ||
                    (message.discussion && mainTheadMessageCache && !mainTheadMessageCache.discussion))
                ) {
                    onChangeMainMessageInThread(message);
                }

                mainTheadMessageCache = message ? { ...message } : null;
                return message;
            });

            const chatId = computed<number>(() => {
                return mainMessageInThread.value?.discussion?.id ?? 0;
            });

            const chatName = computed<string>(() => {
                return props.parentChat?.nameChat || chatInfo.value?.parentMessage?.chatName || '';
            });

            // Показываем кнопку назад, когда тред открыт в блоке справа
            const isVisibleBackButton = computed<boolean>(() => {
                if (isLoadingData.value) {
                    return false;
                }

                if (props.parentChat && chatInfo.value) {
                    return props.parentChat.id !== chatInfo.value.id || props.isMobileView;
                } else {
                    return !chatInfo.value;
                }
            });

            // проверить что объект задан или равен нулю
            function isSetOrZero(obj: any): boolean {
                return Common.isSetOrZero(obj);
            }

            async function onChangeMainMessageInThread(message: ChatMessageDto): Promise<void> {
                if (message && message.discussion?.id) {
                    chatInfo.value = await loadChatInfo(message.discussion.id);

                    setTimeout(() => {
                        messagesBlockRef.value?.setMessageToView(messageToViewId);
                    }, 100);
                } else {
                    chatInfo.value = null;
                }

                setTimeout(() => {
                    bottomPanelRef.value?.setFocusToMessageEditor();
                }, 100);
            }

            // добавляем наше сообщени сразу ещё до отправки в хаб
            function addNewMessageToChat(data: ChatMessageDto): void {
                messagesBlockRef.value?.addMessage(data);
            }

            async function loadChatInfo(id: number): Promise<ChatBaseInfoResponseModel | null> {
                isLoadingData.value = true;
                const result = await new ChatClient(getApiClientInitialParams()).getChatBaseInfo(id);
                isLoadingData.value = false;

                if (result.isSuccess) {
                    return result.entity;
                } else {
                    NotifyErrors(result);
                    return null;
                }
            }

            // Обработчик события отправки сообщения
            function onSend(): void {
                messagesBlockRef.value?.scrollMessagesToDown();
            }

            // Проскролить основной чат до главного сообщения обсуждения
            function scrollToMainMessage(): void {
                context.emit('go-to-message-in-chat', mainMessageInThread.value);

                // На мобилке и мини-чате скрываем чат обсуждения, тк он открывается поверх основного
                if (props.isMobileView || props.modeChat === ModeChat.Inline) {
                    chatStore.mainMessageInThread = null;
                }
            }

            // Вызывается из родительского компонета
            function setMessageToView(messageId?: number): void {
                messageToViewId = messageId;
            }

            // Обработчик ошибки отправки сообщения
            function onErrorHandler(data: ErrorMessageDto): void {
                if (data.chatId === chatId.value) {
                    messagesBlockRef.value?.setErrorMessage(data);
                }
            }

            // Обработчик события от вебсокета,
            // вызывается когда нам пришло сообщение
            async function onMessageReceivedHandler(data: ChatMessageDto): Promise<void> {
                // Если это условие верно, то значит это первое сообщение в только что созданном чате
                // он создался на сервере и мы получаем о нём информацию
                if (chatId.value === 0 && data.parentMessageId === mainMessageInThread.value?.id) {
                    chatInfo.value = await loadChatInfo(data.chatId);

                    if (!data.receiverNotInDiscussion && $route.name === Common.getRouteName(RoutePageNameEnum.Chat)) {
                        // Если пришло сообщение из чата, которого еще нет, добавляем чат в стор
                        await addChatToStore(data, chatInfo.value);
                    }
                }

                if (data.chatId === chatId.value) {
                    // Если пользователь просматривал обсуждение в котором ранее не участвовал
                    // и отправил в него сообщение, то обсуждения еще не будет в списке, поэтому пробуем его добавить в стор
                    await addChatToStore(data);
                    messagesBlockRef.value?.addMessage(data);
                } else {
                    // Учеличиваем счетчик и добавляем чат только если пользователь состоит в дискуссии
                    if (!data.receiverNotInDiscussion) {
                        if ($route.name === Common.getRouteName(RoutePageNameEnum.Chat)) {
                            // Если пришло сообщение из чата, которого еще нет, добавляем чат в стор
                            await addChatToStore(data);
                        }

                        incrementMessage(data);
                    }
                }
            }

            // Обработчик события от вебсокета,
            // вызывается когда кто-то отредактировал сообщение
            function onEditMessageReceivedHandler(data: ChatMessageDto): void {
                if (data.chatId === chatId.value) {
                    messagesBlockRef.value?.updateMessage(data);
                }
            }

            // Обработчик события от вебсокета,
            // вызывается когда кто-то добавил реакцию к сообщению
            function onSendMessageReactionReceivedHandler(data: ChatMessageReactionDto): void {
                if (data.chatId === chatId.value || data.chatId === chatInfo.value?.parentMessage?.chatId) {
                    messagesBlockRef.value?.updateMessageReaction(data);
                }
            }

            // Обработчик события от вебсокета,
            // вызывается когда кто-то удалил реакцию у сообщения
            function onRemoveMessageReactionReceivedHandler(data: ChatMessageReactionDto): void {
                if (data.chatId === chatId.value || data.chatId === chatInfo.value?.parentMessage?.chatId) {
                    messagesBlockRef.value?.removeMessageReaction(data);
                }
            }

            // Обработчик события от вебсокета,
            // вызывается когда кто-то удалил сообщение
            function onRemoveMessageReceivedHandler(data: ChatMessageDto): void {
                if (data.chatId === chatId.value) {
                    messagesBlockRef.value?.removeMessage(data);
                }
            }

            // Обработчик события от вебсокета,
            // вызывается когда пользователь прочитал сообщение
            function onUpdateReadedMessageHandler(data: ChatMessageDto): void {
                if (data.chatId === chatId.value) {
                    messagesBlockRef.value?.setUpdateLastReadedMessage(data.id!);
                }
            }

            function closeThread(): void {
                updateDraftMessage();
                chatStore.mainMessageInThread = null;

                if (props.isMobileView) {
                    const parentChatId = chatInfo.value?.parentMessage?.chatId;
                    const chatIdStore = chatStore.currentChatId;

                    // Если id равны значит мы открыли обсуждение из родительского чата и должны вернуться в родительский чат
                    if (chatIdStore === parentChatId) {
                        context.emit('set-chat-id', parentChatId || chatIdStore);
                    } else {
                        // Если нет, значит открыли чат из списка слева и должны открыть список чатов
                        chatStore.isShowChatsPanel = true;
                    }
                }

                chatInfo.value = null;
            }

            function updateDraftMessage(): void {
                bottomPanelRef.value?.updateDraftMessage();
            }

            // Добавить чат в стор, если его еще нет
            async function addChatToStore(data: ChatMessageDto, chatBaseInfo?: ChatBaseInfoResponseModel | null): Promise<void> {
                const allChatIds = chatStore.getAllChatsIds;

                // Если пришло сообщение из чата, которого еще нет, добавляем чат в стор
                if (allChatIds.filter((i: number) => i === data.chatId).length === 0) {
                    let chatData;

                    if (chatBaseInfo) {
                        chatData = chatBaseInfo;
                    } else {
                        const result = await new ChatClient(getApiClientInitialParams()).getChatBaseInfo(data.chatId);
                        chatData = result.entity;
                    }

                    const chat: ListChatDto = {
                        chatId: data.chatId,
                        name: chatData.parentMessage?.chatName || chatData.nameChat,
                        type: chatData.type,
                        countMembers: chatData.countMembers,
                        logoPath: chatData.chatAvatarPath,
                        isClosedRoom: chatData.isClosedRoom,
                        discussionParentMessageText: chatData.parentMessage?.text ?? '',
                        isDisabled: false,
                        parentChatType: chatData.parentChatType,
                        isMutedDiscipline: false,
                        isMuted: chatData.isMuted
                    };

                    chatStore.addChats([chat]);
                }
            }

            // Инкрементирование количества непрочитанных сообщений
            function incrementMessage(data: ChatMessageDto): void {
                if (data.author.id !== accountStore.getAccountInfo?.id) {
                    chatStore.incrementUnreadedCountMessage({
                        chatId: data.chatId,
                        count: 1,
                        disciplineId: data.disciplineId,
                        isArchive: data.isDeleted,
                        type: data.chatType
                    });
                }
            }

            return {
                messagesBlockRef,
                bottomPanelRef,
                chatInfo,
                avatarPath,
                mainMessageInThread,
                customAvatar,
                chatId,
                chatName,
                isVisibleBackButton,
                onMessageReceivedHandler,
                onEditMessageReceivedHandler,
                onSendMessageReactionReceivedHandler,
                onRemoveMessageReactionReceivedHandler,
                onRemoveMessageReceivedHandler,
                onUpdateReadedMessageHandler,
                addNewMessageToChat,
                onSend,
                setMessageToView,
                scrollToMainMessage,
                onErrorHandler,
                closeThread,
                localize,
                isSetOrZero,
                updateDraftMessage,
                ModeChat,
            };
        }
    });
</script>

<style lang="scss" scoped>
    .thread-chat-block {
        width: 322px;
        height: 100%;
        display: flex;
        flex-flow: column;
        background-image: url('assets/thread-chat-pattern.png');
        background-size: 220px;
        border-left: 2px solid $shade-4;

        &.mobile-view {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            z-index: 99;
        }

        header {
            position: relative;
            height: 56px;
            padding: 12px 16px;
            background-color: #fff;
            z-index: 11;
        }

        ::v-deep(.content-container) {
            position: relative;
            flex-grow: 1;
        }

        .thread-header {
            position: relative;
            z-index: 11;
        }

    }
</style>
