<template>
    <div v-if="currentNotification" class="call-notifications-component full-window window-height window-width">
        <q-card class="call-block q-pt-sm q-pb-lg q-pl-sm q-pr-sm q-card">
            <div class="call-block__header row justify-between items-center q-mb-md">
                <Icon
                    name="NotificationOff"
                    size="24"
                    :color="ColorValiablesNameEnum.shade7"
                    class="q-ml-sm custom-radio call-block__header-desktop cursor-pointer"
                    role="menuitem"
                    tabindex="0"
                    :aria-label="localize('Отключение уведомлений')"
                >
                    <q-tooltip content-class="call-block__tooltip">
                        {{ localize('Отключение уведомлений') }}
                    </q-tooltip>
                    <q-menu
                        v-model="isOpenMenu"
                        anchor="top left"
                        self="top right"
                        content-class="call-block__menu"
                        :offset="[30, 1]"
                        auto-close
                    >
                        <notifications-list-not-push
                            :chatId="currentNotification.chatId"
                            @change-close="closeCall"
                        />
                    </q-menu>
                </Icon>
                <Icon
                    name="NotificationOff"
                    size="24"
                    :color="ColorValiablesNameEnum.shade7"
                    @click="isOpenDialog = true"
                    role="button"
                    tabindex="0"
                    :aria-label="localize('Открыть окно настроек уведомлений о звонках в данном чате')"
                    class="q-ml-sm custom-radio call-block__header-mobile"
                />

                <q-btn
                    @click="removeNotification"
                    :aria-label="localize('Закрыть окно уведомления о звонке')"
                    round
                >
                    <Icon
                        name="CloseIcon"
                        :color="ColorValiablesNameEnum.shade7"
                        aria-hidden="true"
                    />
                </q-btn>
            </div>
            <q-card-section class="text-center q-pa-none">
                <q-avatar
                    size="112px"
                    :class="{ 'rounded-borders': currentNotification.chatType === ChatType.Discipline ||
                        currentNotification.chatType === ChatType.Group
                    }"
                    aria-hidden="true"
                >
                    <img v-if="currentNotification.image" :src="currentNotification.image" alt="" />
                    <template v-else>
                        <custom-avatar
                            v-if="currentNotification.chatType === ChatType.Private"
                            :text="currentNotification.title"
                            :color="CustomAvatarColors.User"
                            :fontSize="fontSize"
                        />
                        <custom-avatar
                            v-if="currentNotification.chatType === ChatType.Discipline"
                            text="Д"
                            :fontSize="fontSize"
                            :color="CustomAvatarColors.Discipline"
                        />
                        <custom-avatar
                            v-if="currentNotification.chatType === ChatType.Self"
                            :text="currentNotification.title"
                            :fontSize="fontSize"
                            :color="CustomAvatarColors.GroupChat"
                        />
                        <custom-avatar
                            v-if="currentNotification.chatType === ChatType.Group"
                            text="Гч"
                            :fontSize="fontSize"
                            :color="CustomAvatarColors.GroupChat"
                        />
                    </template>
                </q-avatar>

                <div class="call-block__info-text q-mb-sm q-mt-md">
                    <template
                        v-if="currentNotification.chatType === ChatType.Self || currentNotification.chatType === ChatType.Private">
                        {{ localize('Вам звонит...') }}
                    </template>
                    <template v-else>
                        {{ localize('Входящий звонок...') }}
                    </template>
                </div>
                <div class="title">
                    <div v-if="callTitle">
                        {{ callTitle }}
                        <q-tooltip
                            v-if="currentNotification.disciplineName"
                            anchor="top middle"
                            self="bottom middle"
                        >
                            {{ currentNotification.disciplineName }}
                        </q-tooltip>
                    </div>
                    <div
                        v-if="callSubTitle && (currentNotification.chatType === ChatType.Group || currentNotification.chatType === ChatType.Private)">
                        {{ callSubTitle }}
                        <q-tooltip
                            v-if="currentNotification.title.length > 100"
                            anchor="top middle"
                            self="bottom middle"
                        >
                            {{ callSubTitle }}
                        </q-tooltip>
                    </div>
                </div>
                <div class="q-mt-lg q-pt-lg">
                    <q-btn
                        v-if="!isProduction"
                        @click="finishCall"
                        class="finish-call-icon cursor-pointer call-block__btn q-mr-md"
                    >
                        <div class="call-block__btn__body">
                            <div class="call-block__btn__icon warning">
                                <Icon
                                    name="DeclineCallIcon"
                                    :color="ColorValiablesNameEnum.white"
                                    :hover-color="ColorValiablesNameEnum.white"
                                    aria-hidden="true"
                                />
                            </div>
                            <span>{{ localize('Завершить') }}</span>
                        </div>
                    </q-btn>
                    <q-btn
                        @click="declineCall"
                        class="call-block__btn q-mr-md"
                        :aria-label="localize('Отклонить звонок')"
                    >
                        <div class="call-block__btn__body">
                            <div class="call-block__btn__icon error">
                                <Icon
                                    name="DeclineCallIcon"
                                    :color="ColorValiablesNameEnum.white"
                                    :hover-color="ColorValiablesNameEnum.white"
                                    aria-hidden="true"
                                />
                            </div>
                            <span>{{ localize('Отклонить') }}</span>
                        </div>
                    </q-btn>
                    <q-btn
                        @click="acceptCall"
                        class="call-block__btn"
                        :aria-label="localize('Принять звонок')"
                    >
                        <div class="call-block__btn__body">
                            <div class="call-block__btn__icon success shake-animate">
                                <Icon
                                    name="AcceptCallIcon"
                                    :color="ColorValiablesNameEnum.white"
                                    :hover-color="ColorValiablesNameEnum.white"
                                    aria-hidden="true"
                                />
                            </div>
                            <span>{{ localize('Принять') }}</span>
                        </div>
                    </q-btn>
                </div>
            </q-card-section>
        </q-card>
        <q-dialog v-model="isOpenDialog" class="call-block__dialog">
            <notifications-list-not-push
                :chatId="currentNotification.chatId"
                @change-close="closeCall"
            />
        </q-dialog>
    </div>
</template>

<script lang="ts">
    import { Common } from 'src/helpers/Common';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { CallClient, ChatMessageClient, RoutePageNameEnum } from 'src/api/ApiClient';
    import { Notify } from 'quasar';
    import { localize } from 'src/services/LocalizationService';
    import { callBus } from 'components/EventBuses';
    import { CustomAvatarColors } from 'src/components/ui/Avatar/CustomAvatar/enums';
    import { WebNotificationPartialHub } from 'src/services/hubs/WebNotificationPartialHub';
    import { CallPartialHub } from 'src/services/hubs/CallPartialHub';
    import { ChatPartialHub } from 'src/services/hubs/ChatPartialHub';
    import { ICallStartDto } from 'src/types/generated/hubs/callPartialHub/models/ICallStartDto';
    import {
        IChatNewMessageNotificationDto,
    } from 'src/types/generated/hubs/chatPartialHub/models/IChatNewMessageNotificationDto';
    import {
        IWebNotificationDto,
    } from 'src/types/generated/hubs/webNotificationPartialHub/models/IWebNotificationDto';
    import { IUserActiveCallsDto } from 'src/types/generated/hubs/callPartialHub/models/IUserActiveCallsDto';
    import { ChatType } from 'src/types/generated/hubs/chatPartialHub/models/ChatType';
    import {
        computed,
        defineComponent,
        getCurrentInstance,
        onBeforeMount,
        onBeforeUnmount,
        ref,
        Ref,
        watch,
    } from 'vue';
    import NotificationsListNotPush from 'src/layouts/Main/components/NotificationsListNotPush.vue';
    import CustomAvatar from 'src/components/ui/Avatar/CustomAvatar/CustomAvatar.vue';
    import Icon from 'src/components/ui/Icon';
    import { ColorValiablesNameEnum } from 'src/components/ui/Icon/interface';
    import { useRoute, useRouter } from 'vue-router';
    import { useNotificationsStore } from 'src/store/module-notifications';
    import { useMainLayoutStore } from 'src/store/module-main-layout';
    import { useChatStore } from 'src/store/module-chat';
    import { CallBusEvents } from 'components/EventBuses/emuns';

    interface ITabInfo {
        id: number;
        isActive: boolean;
        isPlaying: boolean;
        createTime: number;
    }

    export default defineComponent({
        components: {
            NotificationsListNotPush,
            Icon,
            CustomAvatar,
        },
        emits: [
            'on-select-chat',
        ],
        // eslint-disable-next-line max-lines-per-function
        setup(_, context) {
            const $route = useRoute();
            const $router = useRouter();
            const chatStore = useChatStore();
            const notificationsStore = useNotificationsStore();
            const mainLayoutStore = useMainLayoutStore();

            const audio: Ref<HTMLAudioElement | null> = ref(null);
            const callNotifications: Ref<ICallStartDto[]> = ref([]);
            const chatNotifications: Ref<IChatNewMessageNotificationDto[]> = ref([]);
            const simpleNotifications: Ref<IWebNotificationDto[]> = ref([]);
            // Массив, который хранит в себе результат выполнения функции Notify.create
            const simpleNotifyFunctions: Ref<any[]> = ref([]);
            const browserTabId: number = Common.makeFakeId();
            const fontSize: number = 50;

            let $commonHub: WebNotificationPartialHub | null = null;
            const app = getCurrentInstance();

            if (app) {
                $commonHub = app.appContext.config.globalProperties.$commonHub as WebNotificationPartialHub | null;
            }

            // Сущность для контроля открыто меню отключения уведомления о звонке на десктопе
            const isOpenMenu: Ref<boolean> = ref(false);
            // Сущность для контроля открыто меню отключения уведомления о звонке на мобильном разрешении
            const isOpenDialog: Ref<boolean> = ref(false);
            // Не показываем кнопку принудительного завершения звонка на проде
            // А только на локали и на тестовых серверах
            const isProduction = Common.isProductionHost();

            let chatMessageId: number = 0;
            let chatId: number = 0;

            // Если звонков несколько показываем первый
            const currentNotification = computed((): ICallStartDto | null => {
                return callNotifications.value.length ? callNotifications.value[0] : null;
            });

            // Название дисциплины, если оно есть
            const callTitle = computed((): string => {
                if (currentNotification.value?.disciplineShortName) {
                    return currentNotification.value?.disciplineShortName;
                }

                return '';
            });

            // Подзаголовок звонка, длинный обрезаем
            const callSubTitle = computed((): string => {
                if (currentNotification.value?.title) {
                    if (currentNotification.value?.title.length > 100) {
                        return currentNotification.value?.title.substr(0, 100) + '...';
                    } else {
                        return currentNotification.value?.title;
                    }
                }

                return '';
            });

            // Если появилось оповещения - проигрываем мелодию
            watch(currentNotification, (val: ICallStartDto | null) => {
                if (val) {
                    playSound();
                } else {
                    stopSound();
                }
            });

            // Принять звонок - открываем страницу звонка
            function acceptCall(): void {
                const routeData = $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.CallEnter),
                    params: { id: currentNotification.value!.chatId.toString() },
                });

                new ChatMessageClient(getApiClientInitialParams()).updateLastReadMessageForCall(chatId, chatMessageId);

                window.open(routeData.href, '_blank');
                callNotifications.value = [];
            }

            // Отклонить взодящий звонок
            function declineCall(): void {
                new CallClient(getApiClientInitialParams()).decline(currentNotification.value!.callId);
                new ChatMessageClient(getApiClientInitialParams()).updateLastReadMessageForCall(chatId, chatMessageId);
                removeNotification();
            }

            function closeCall(): void {
                isOpenMenu.value = false;
                isOpenDialog.value = false;
                declineCall();
            }

            // Принудительно завершить звонок
            function finishCall(): void {
                if (currentNotification.value) {
                    new CallClient(getApiClientInitialParams()).finishCallForTestDesk(currentNotification.value.callId);
                    new ChatMessageClient(getApiClientInitialParams()).updateLastReadMessageForCall(chatId, chatMessageId);
                }

                if (currentNotification.value) {
                    notificationsStore.removeCallNotification(currentNotification.value?.callId);
                }

                removeNotification();
            }

            // Когда мы удалим оповещение, то покажется следующее, если оно есть
            function removeNotification(): void {
                const index = callNotifications.value.findIndex((x: ICallStartDto) => x.callId === currentNotification.value?.callId);

                if (index !== -1) {
                    callNotifications.value.splice(index, 1);
                }
            }

            function getShortName(fullName: string): string {
                const [firstName, lastName, middleName] = fullName.split(' ');
                if (!lastName) {
                    return fullName;
                }
                if (!middleName) {
                    return `${firstName} ${lastName[0]}.`;
                }
                return `${firstName} ${lastName[0]}. ${middleName[0]}.`;
            }

            function addCustomAvatar(message: string, notification: IChatNewMessageNotificationDto): string {
                const abbr = notification.title.split(' ').map((s: string) => s[0]).slice(0, 2).join('');
                const avatar = `<div class="notification-avatar" style="background: ${CustomAvatarColors.User}">${abbr}</div>`;
                return message + avatar;
            }

            // Создается оповещение в правом нижнем углу приложения
            function notifyCreate(notify: IChatNewMessageNotificationDto): void {
                const message = getChatMessageText(notify.text, notify.countOfFiles);
                const name = getShortName(notify.title);
                const title = notify.chatName ? `${name} ● ${notify.chatName}` : name;
                const handler = getNotifyAction(notify.chatId);
                const classes = `notifications-components ${notify.image ? '' : ' without-image'}
                    ${notify.countOfFiles > 0 ? 'with-file' : ''}`;
                Notify.create({
                    classes,
                    progress: false,
                    message: title,
                    caption: notify.image ? message : addCustomAvatar(message, notify),
                    position: 'bottom-right',
                    timeout: 10000,
                    color: 'primary',
                    multiLine: true,
                    avatar: notify.image,
                    html: true,
                    actions: [
                        {
                            class: 'link-notifications',
                            label: localize('Перейти'),
                            handler,
                        },
                    ],
                });
            }

            //Получить текст сооющения для показа в уведомлении
            function getChatMessageText(notifyText: string, countOfFiles: number): string {
                let message: string = '';
                const requiredTextLenth = countOfFiles > 0 ? 20 : 45;
                if (notifyText.length > 0) {
                    const textWithoutBrs = notifyText.replace(new RegExp('<br class="nr">', 'g'), ' ');
                    const text = textWithoutBrs.replace(/<(.|\n)*?>/g, '');
                    const clearedMessage = text.length > requiredTextLenth ? text.substring(0, requiredTextLenth) + '...' : text;
                    if (clearedMessage.length > 0) {
                        message = clearedMessage;
                    }
                }
                if (countOfFiles > 0 && message.length > 0) {
                    message += '<br>';
                }
                if (countOfFiles === 1) {
                    message += localize('[Файл]');
                }
                if (countOfFiles > 1) {
                    message += localize('[Файлы]');
                }
                return message;
            }

            function notifyForMessage(notify: IChatNewMessageNotificationDto): void {
                if (!document.hidden) {
                    notifyCreate(notify);
                    $commonHub?.webNotificationViewedAsync(notify.id);
                } else {
                    // Если вкладка не активна, то добавляем уведомление в массив
                    chatNotifications.value.push(notify);
                }
            }

            // Метод отображения уведомлений из массива
            function seeNotifyArr(): void {
                // Отобразить WebSimpleNotification
                simpleNotifications.value.forEach((i: IWebNotificationDto) => {
                    webSimpleNotificationCreate(i);
                    // отправляем по хабу информацию, о том что сообщение прочитано
                    $commonHub?.webNotificationViewedAsync(i.id);
                });

                // Отобразить WebChatNotification
                chatNotifications.value.forEach((i: IChatNewMessageNotificationDto) => {
                    notifyCreate(i);
                    // отправляем по хабу информацию, о том что сообщение прочитано
                    $commonHub?.webNotificationViewedAsync(i.id);
                });

                chatNotifications.value = [];
                simpleNotifications.value = [];
            }

            function setEventHidden(event: () => void): void {
                document.addEventListener('visibilitychange', function () {
                    if (!document.hidden) {
                        event();
                    }
                }, false);
            }

            function onChatNotify(notify: IChatNewMessageNotificationDto): void {
                chatId = chatStore.currentChatId;
                // Скрытым может быть только миничат, на странице чатов он всегда открыт
                const isVisibleChat = $route.name !== Common.getRouteName(RoutePageNameEnum.Chat)
                    ? mainLayoutStore.isVisibleChatBlock
                    : true;

                // Показываем оповещение, если:
                // 1. Это не текущий чат
                // 2. Если это текущий чат, но сам чат при этом скрыт
                // 3. Если это активное оповещение (не isRemove)
                if ((chatId !== notify.chatId || (chatId === notify.chatId && !isVisibleChat))) {
                    notifyForMessage(notify);
                } else {
                    // Если web-уведомление не отображается, то отмечаем его сразу прочитанным,
                    // что бы не отправлялось push-уведомление
                    $commonHub?.webNotificationViewedAsync(notify.id);
                }

                if (!document.hidden && $route.name !== Common.getRouteName(RoutePageNameEnum.CallEnter)) {
                    playChatSound();
                }
            }

            // Получить действие при клике на оповещение
            // Если мы находим на странице чата - то открываем его
            // Иначе редиректим на страницу чата
            function getNotifyAction(notifyChatId: number): () => void {
                if ($route.name !== Common.getRouteName(RoutePageNameEnum.Chat) && $route.name !== Common.getRouteName(RoutePageNameEnum.CallEnter)) {
                    return () => context.emit('on-select-chat', notifyChatId);
                } else {
                    return () => $router.push({
                        name: Common.getRouteName(RoutePageNameEnum.Chat),
                        params: { id: notifyChatId.toString() },
                    });
                }
            }

            function playChatSound(): void {
                const audioInChat = new Audio('/sounds/pik.mp3');
                if (audioInChat) {
                    audioInChat.volume = 0.6;
                    audioInChat?.play();
                }
            }

            function onCallNotify(notify: ICallStartDto): void {
                if ($route.name !== Common.getRouteName(RoutePageNameEnum.CallEnter)) {
                    const existIndex = callNotifications.value.findIndex((x: ICallStartDto) => x.callId === notify.callId);

                    if (existIndex === -1) {
                        callNotifications.value.push(notify);
                    }
                }
            }

            function onEndCallNotify(callId: number, isCallEnd: boolean = false): void {
                if ($route.name !== Common.getRouteName(RoutePageNameEnum.CallEnter)) {
                    const existIndex = callNotifications.value.findIndex((x: ICallStartDto) => x.callId === callId);

                    if (existIndex !== -1) {
                        callNotifications.value.splice(existIndex, 1);
                    }

                    if (isCallEnd) {
                        notificationsStore.removeCallNotification(callId);
                        callBus.emit(CallBusEvents.EndOFCall, callId);
                    }
                }
            }

            // При реконнекте WebSocket очищаем список звонков, так как при коннекте
            // мы получим активные звонки, а сообщения о завершении звонков, которые
            // могли остатся в store - мы могли пропустить при потере соединения
            function onReconnecting(): void {
                notificationsStore.clearCallNotifications();
            }

            function onSimpleNotify(notify: IWebNotificationDto): void {
                if (!document.hidden) {
                    webSimpleNotificationCreate(notify);
                    $commonHub?.webNotificationViewedAsync(notify.id);
                    let count = notificationsStore.getCountNotifications;
                    notificationsStore.setCountNotifications(++count);

                    playChatSound();
                } else {
                    // Если вкладка не активна, то добавляем уведомление в массив
                    simpleNotifications.value.push(notify);
                }
            }

            // Создаем простое уведомление
            function webSimpleNotificationCreate(notify: IWebNotificationDto): void {
                let message = '[Файл]';

                if (notify.text.length > 0) {
                    // Удаляем тег <pre> - из-за него текст может не умещаться в оповещении
                    // Если его нет, то регулярка удалит весь текст, поэтому делаем проверку
                    const text = notify.text.replace(/<(.|\n)*?>/g, '');
                    const clearedMessage = text.length > 255 ? text.substring(0, 255) + '...' : text;
                    // Если после очистки данных уведомления текста нет, то это уведомление с файлом
                    if (clearedMessage) {
                        message = clearedMessage;
                    }
                }

                const handlerGoToNotificationsInfo = (): void => {
                    $router.push({
                        name: Common.getRouteName(RoutePageNameEnum.NotificationsInfo),
                        query: { id: notify.id },
                    });
                };

                const hideNotificationFunction = Notify.create({
                    classes: 'notifications-components',
                    progress: false,
                    message: notify.title,
                    caption: message,
                    position: 'bottom-right',
                    timeout: 10000,
                    color: 'primary',
                    multiLine: true,
                    html: true,
                    actions: [
                        {
                            class: 'link-notifications',
                            label: localize('Перейти'),
                            handler: handlerGoToNotificationsInfo,
                        },
                    ],
                });

                (hideNotificationFunction as { (): void; notificationId: string; }).notificationId = notify.id;
                simpleNotifyFunctions.value.push(hideNotificationFunction);
            }

            // Обновляем информацию о вкладке, активная ли она или воспроизводит мелодию
            function setTabInfo(isPlaying: boolean = false): void {
                const data = localStorage.getItem('tabsInfo');
                const currentTabInfo = {
                    id: browserTabId,
                    isPlaying,
                    isActive: !document.hidden,
                    createTime: Date.now(),
                } as ITabInfo;

                if (data) {
                    const tabsInfo: ITabInfo[] = JSON.parse(data);
                    const tab = tabsInfo.find((x: ITabInfo) => x.id === browserTabId);

                    if (tab) {
                        tabsInfo.forEach((x: ITabInfo) => x.isActive = false);
                        tab.isActive = currentTabInfo.isActive;
                        tab.isPlaying = currentTabInfo.isPlaying;
                        localStorage.setItem('tabsInfo', JSON.stringify(tabsInfo));
                    } else {
                        tabsInfo.push(currentTabInfo);
                        localStorage.setItem('tabsInfo', JSON.stringify(tabsInfo));
                    }
                } else {
                    localStorage.setItem('tabsInfo', JSON.stringify([currentTabInfo]));
                }
            }

            // При закрытии вкладки удалеяем информацию о ней из localStorage
            // так же удаляем старые данные
            function deleteTabInfo(): void {
                const data = localStorage.getItem('tabsInfo');

                if (data) {
                    let tabsInfo: ITabInfo[] = JSON.parse(data);
                    tabsInfo = tabsInfo.filter((x: ITabInfo) => {
                        const isOld = (Date.now() - x.createTime) / 1000 / 60 / 60 > 24;
                        return x.id !== browserTabId && !isOld;
                    });
                    localStorage.setItem('tabsInfo', JSON.stringify(tabsInfo));
                }
            }

            // Событие отрабатывает, когда на вкладку установии фокус
            function focusTab(): void {
                setTabInfo();
                playSound();
            }

            // Событие отрабатывает, когда на вкладка потеряла фокус
            function unFocusTab(): void {
                setTabInfo(false);
                stopSound();
            }

            // Проверяет, может ли вкладка воспроизводить музыку
            function checkNeedPlay(): boolean {
                const data = localStorage.getItem('tabsInfo');

                if (data) {
                    const tabsInfo: ITabInfo[] = JSON.parse(data);
                    let tab = tabsInfo.find((x: ITabInfo) => x.isPlaying);

                    // Если есть вкладка, в которой играет музыка, в текущей её не играем
                    if (tab) {
                        return false;
                    } else {
                        tab = tabsInfo.find((x: ITabInfo) => x.isActive);

                        // Если активная вкладка, мы должны проигрывать музыку в ней
                        if (tab) {
                            return tab.id === browserTabId;
                        } else {
                            // Если нет активных то проигрываем в одной любой вкладке
                            tab = tabsInfo.find((x: ITabInfo) => x.id === browserTabId);
                            return !!tab;
                        }
                    }
                } else {
                    // Если нет ни каких данных, проигрываем мелодию
                    setTabInfo(true);
                    return true;
                }
            }

            // Обновить информацию о вкладке, что она играет музыку
            function setPlayingTabInfo(): void {
                const data = localStorage.getItem('tabsInfo');

                if (data) {
                    const tabsInfo: ITabInfo[] = JSON.parse(data);
                    const tab = tabsInfo.find((x: ITabInfo) => x.id === browserTabId);

                    if (tab) {
                        tab.isPlaying = true;
                        localStorage.setItem('tabsInfo', JSON.stringify(tabsInfo));
                    }
                }
            }

            // Воспроизводим мелодию, если есть оповещение
            // и она уже не играет в другой вкладке
            function playSound(): void {
                if (currentNotification.value && checkNeedPlay()) {
                    setPlayingTabInfo();
                    audio.value?.play();
                }
            }

            function stopSound(): void {
                audio.value?.pause();
            }

            function initCallNotificationHub(): void {
                // Нужна прослойка unknown так как типы не сравнимы
                const callNotificationHub = $commonHub as unknown as CallPartialHub;
                callNotificationHub.onCallStarted((x: ICallStartDto) => {
                    notificationsStore.addCallNotification(x);
                    chatMessageId = x.chatMessageId;
                    chatId = x.chatId;
                });
                callNotificationHub.onNotifyAboutCall(onCallNotify);
                callNotificationHub.onCallDeclined(onEndCallNotify);
                callNotificationHub.onCallUserAccepted(onEndCallNotify);
                callNotificationHub.onCallFinished((callId: number) => {
                    onEndCallNotify(callId, true);
                });

                callNotificationHub.onCallUserActiveCalls((calls: IUserActiveCallsDto[]) => {
                    callNotifications.value = [];

                    calls.forEach((x: IUserActiveCallsDto) => {
                        if (x.isNeedShowNotify) {
                            const callStartDto: ICallStartDto = {
                                chatMessageId,
                                id: x.id,
                                title: x.title,
                                callId: x.callId,
                                chatId: x.chatId,
                                chatType: x.chatType,
                                image: x.image,
                                disciplineName: x.disciplineName,
                                disciplineShortName: x.disciplineShortName,
                            };
                            onCallNotify(callStartDto);
                        }

                        notificationsStore.addCallNotification(x);
                    });
                });
            }

            function initWebNotificationHub(): void {
                $commonHub?.onShowWebNotification(onSimpleNotify);
                $commonHub?.onHideWebNotification((notificationId: string) => {
                    if (document.hidden) {
                        const notification = simpleNotifications.value.find((x: IWebNotificationDto) => x.id === notificationId);

                        if (notification) {
                            let count = notificationsStore.getCountNotifications;
                            notificationsStore.setCountNotifications(--count);
                        }

                        const chatNotifyIndex = chatNotifications.value.findIndex((x: IChatNewMessageNotificationDto) => x.id === notificationId);

                        if (chatNotifyIndex !== -1) {
                            chatNotifications.value.splice(chatNotifyIndex, 1);
                        }

                        const hideNotifyFunction = simpleNotifyFunctions.value.find((x: any) => x.notificationId === notificationId);

                        if (hideNotifyFunction) {
                            hideNotifyFunction(void 0);
                        }
                    }
                });
            }

            onBeforeMount(() => {
                $commonHub?.onReconnecting(onReconnecting);
                ($commonHub as unknown as ChatPartialHub).onChatShowNotificationNewMessage(onChatNotify);
                initCallNotificationHub();
                initWebNotificationHub();

                // На странице звонка оповещения не показываются и
                // поэтому не вешаем обработчики и не загружаем файл call.mp3
                if ($route.name !== Common.getRouteName(RoutePageNameEnum.CallEnter)) {
                    audio.value = new Audio('/sounds/call.mp3');
                    audio.value.loop = true;

                    window.addEventListener('blur', unFocusTab);
                    window.addEventListener('focus', focusTab);
                }

                setEventHidden(seeNotifyArr);
                setTabInfo();

                // При закрытии вкладки удалеяем старые данные из localStorage
                window.addEventListener('beforeunload', () => {
                    deleteTabInfo();
                });
            });

            onBeforeUnmount(() => {
                window.removeEventListener('blur', unFocusTab);
                window.removeEventListener('focus', focusTab);
            });

            return {
                isOpenMenu,
                isOpenDialog,
                removeNotification,
                localize,
                // Енам типа чатов для доступа в шаблоне
                ChatType,
                // Цвета для дефолтных аватаров
                CustomAvatarColors,
                ColorValiablesNameEnum,
                currentNotification,
                callTitle,
                callSubTitle,
                acceptCall,
                declineCall,
                isProduction,
                finishCall,
                fontSize,
                closeCall,
            };
        },
    });
</script>

<style lang="scss">
    .q-notification {
        position: relative;
    }

    .notifications-components {
        background: rgba(4, 9, 31, 0.8) !important;
        max-width: 350px !important;
        min-width: 350px;
        padding: 16px !important;
        min-height: 100px;

        &.without-image {
            .q-notification__content {
                position: relative;
                padding-left: 40px;

                .notification-avatar {
                    // important нужен чтобы перебить затенение при наведении на уведомление
                    -webkit-background-clip: unset;
                    background-clip: unset;
                    -webkit-text-fill-color: white;
                    position: absolute;
                    top: 0;
                    font-weight: 500;
                    left: 0;
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                }
            }
        }

        @media screen and (max-width: 650px) {
            .link-notifications {
                .q-btn__content {
                    opacity: 1;
                }
            }
        }

        &:hover {
            .link-notifications {
                .q-btn__content {
                    opacity: 1 !important;
                    color: #05A5FC !important;
                }
            }

            .q-notification__message {
                .q-notification__caption {
                    background: linear-gradient(90deg, #fff 75%, rgba(255, 255, 255, 0.5) 87%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: #F7F7F7;

                    @media (max-width: 650px) {
                        background: transparent;
                        -webkit-background-clip: unset;
                        -webkit-text-fill-color: unset;
                    }
                }
            }
        }

        .close-btn {
            position: absolute;
            top: -9px;
            right: -8px;
            width: 30px;
            height: 30px;

            .q-btn__content {
                padding: 0;
            }

            i {
                font-size: 15px !important;
                position: relative;
                top: -4px;
            }
        }

        .q-focus-helper {
            background: rgba(4, 9, 31) !important;
        }

        .q-notification__progress {
            bottom: -16px;
        }

        .q-notification__wrapper {
            display: flex;
            flex-wrap: nowrap;
            flex: none;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
        }

        .q-notification__content {
            align-items: flex-start;
            max-width: 410px;
            width: 100%;
            height: 68px;
            position: relative;
        }

        .q-notification__message {
            padding: 0;
            margin: 4px 0 0;
            font-weight: 600;
            line-height: 18px;
            color: $shade-2;
            font-size: 18px;

            & > div:first-child {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
            }

            .q-notification__caption {
                width: calc(100% - 50px) !important;
                margin-top: 7px;
                font-size: 14px;
                color: #fff;
                opacity: 1;
                line-height: 1.5;
                font-weight: normal;
                word-break: break-word;
                transition: .4s all;
                overflow: hidden;
                max-height: 46px;
                text-overflow: ellipsis;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        .link-notifications {
            position: absolute;
            top: -16px;
            left: -16px;
            width: calc(100% + 32px);
            height: calc(100% + 32px);
            color: #05A5FC !important;

            .q-focus-helper {
                opacity: 0;
            }

            .q-hoverable:hover > .q-focus-helper {
                opacity: 0;
            }

            .q-btn__content {
                position: absolute;
                bottom: 22px;
                right: 32px;
                opacity: 0;
                transition: .4s all;

                @media screen and (max-width: 650px) {
                    opacity: 1;
                }
            }
        }

        @media (max-width: 650px) {
            max-width: 350px !important;
            min-width: 350px !important;
            padding: 8px !important;

            .q-notification__avatar--additional {
                margin-right: 6px !important;
            }

            .q-notification__message {
                font-size: 14px;

                .q-notification__caption {
                    font-size: 11px;
                }
            }

            .link-notifications {
                .q-btn__content {
                    bottom: 16px;
                }
            }
        }
    }

    .call-block {
        &__btn {
            .q-btn__wrapper {
                padding: 0;
            }
        }

        &__menu {
            z-index: 10000;
        }

        &__dialog {
            z-index: 10000;
        }

        &__tooltip {
            z-index: 10000;
        }
    }
</style>

<style lang="scss" scoped>
    .call-block {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 320px;
        min-width: 320px;
        transform: translate(-50%, -50%);

        &__header-mobile {
            display: none !important;
        }

        .title {
            font-weight: 500;
            font-size: 14px;
            line-height: 1.4;
            color: #000;
        }

        &__info-text {
            color: $shade-9;
            font-size: 14px;
        }

        &__btn {
            text-align: center;
            width: 70px;

            &__icon {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 48px;
                height: 48px;
                margin: 0 auto;
                margin-bottom: 8px;

                &.success {
                    background-color: $success;
                    position: relative;

                    &:before {
                        content: '';
                        width: calc(100% + 8px);
                        height: calc(100% + 8px);
                        position: absolute;
                        top: -4px;
                        left: -4px;
                        background-color: $success;
                        opacity: .5;
                        border-radius: 50%;
                        z-index: -1;
                    }
                }

                &.error {
                    background-color: $error;
                }

                &.warning {
                    background-color: $warning;
                }
            }

            span {
                font-size: 12px;
                color: $shade-9;
            }
        }

        @media (max-width: 700px) {
            &__header-desktop {
                display: none !important;
            }

            &__header-mobile {
                display: block !important;
            }
        }
    }
</style>
