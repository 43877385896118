﻿<template>
    <search-item
        :avatar-color="CustomAvatarColors.User"
        :avatar-text="item.baseInfo.lastName[0] + item.baseInfo.firstName[0]"
        :picture-url="item.baseInfo.photoUrl"
        :item-page-url="userPageUrl"
        :item="item"
        :type="SearchType.User"
        :name="name"
        @on-item-selected="$emit('on-item-selected')"
    >
        <template #afterlink>
            <div v-if="item.isBlocked" class="text-tag demo-tag-color Test-color">
                {{ localize('Заблокирован') }}
            </div>
        </template>
        <template #info>
            <div class="info ellipsis-2-lines">{{ item.universities.join(' • ') }}</div>
            <div class="info">{{ item.roles.join(' • ') }}</div>
        </template>
        <template #append>
            <slot name="append"></slot>
        </template>
    </search-item>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import {
        SearchUserDto,
        RoutePageNameEnum,
        SearchType,
    } from 'src/api/ApiClient';
    import { localize } from 'src/services/LocalizationService';
    import { Common } from 'src/helpers/Common';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import SearchItem from '../SearchItem.vue';
    import { useGetEntityPageUrl } from '../../hooks/useGetEntityPageUrl';

    defineEmits(['on-item-selected']);

    // Компонент для отображениея данных о найденной пользователе
    const props = defineProps<{
        item: SearchUserDto,
    }>();

    const {
        getEntityPageUrl: getUserPageUrl,
    } = useGetEntityPageUrl(Common.getRouteName(RoutePageNameEnum.UserInfo));

    // Ссылка на роут со страницей пользователя
    const userPageUrl = computed(() => getUserPageUrl(props.item.baseInfo.id));
    // Форматированное имя пользователя
    const name = computed(() => {
        return `${props.item.baseInfo.lastName} ${props.item.baseInfo.firstName} ${props.item.baseInfo.middleName || ''}`;
    });
</script>
