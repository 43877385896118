<template>
    <div class="code-editor-component" :class="{ 'inline-code': inlineCode }">
        <pre :id="editorId"></pre>
    </div>
</template>

<script lang="ts">
    import { defineComponent, onMounted, PropType } from 'vue';
    import { Common } from 'src/helpers/Common';
    import { ProgrammingLanguages } from 'src/api/ApiClient';
    import * as Sources from './TemplateSources';
    import '../../plugins/Ace/ace';
    import '../../plugins/Ace/ext-language_tools';
    import '../../plugins/Ace/theme/theme-tomorrow';
    import '../../plugins/Ace/theme/theme-textmate';
    import '../../plugins/Ace/lang/mode-javascript';
    import '../../plugins/Ace/lang/mode-plain_text';
    import '../../plugins/Ace/lang/mode-c_cpp';
    import '../../plugins/Ace/lang/mode-csharp';
    import '../../plugins/Ace/lang/mode-php';
    import '../../plugins/Ace/lang/mode-java';
    import '../../plugins/Ace/lang/mode-typescript';
    import '../../plugins/Ace/lang/mode-kotlin';
    import '../../plugins/Ace/lang/mode-golang';
    import '../../plugins/Ace/lang/mode-python';

    declare const ace: any;

    // Компонет редактора кода на основе плагина Ace
    export default defineComponent({
        name: 'CodeEditor',

        emits: ['update:model-value'],

        props: {
            // v-model компонента
            modelValue: {
                type: String,
                required: true,
            },
            // язык кода для редактора
            language: {
                type: Number as PropType<ProgrammingLanguages>,
            },
            // режим чтения
            readOnly: {
                type: Boolean,
                default: false
            },
            // режим отображения кода
            inlineCode: {
                type: Boolean,
                default: false
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            // Формируем id для элемента
            const editorId = 'editor' + Common.makeFakeId();
            let editor: any = null;

            function setMode(): void {
                let defaultValue = '';

                // Устанавливаем необходимый режим для редактора
                switch (props.language) {
                    case ProgrammingLanguages.C:
                        defaultValue = Sources.cSource;
                        editor.session.setMode('ace/mode/c_cpp');
                        break;
                    case ProgrammingLanguages.CPP:
                        defaultValue = Sources.cppSource;
                        editor.session.setMode('ace/mode/c_cpp');
                        break;
                    case ProgrammingLanguages.JavaScript:
                        defaultValue = Sources.javaScriptSource;
                        editor.session.setMode('ace/mode/javascript');
                        break;
                    case ProgrammingLanguages.Java:
                        defaultValue = Sources.javaSource;
                        editor.session.setMode('ace/mode/java');
                        break;
                    case ProgrammingLanguages.Kotlin:
                        defaultValue = Sources.kotlinSource;
                        editor.session.setMode('ace/mode/kotlin');
                        break;
                    case ProgrammingLanguages.CSharp:
                        defaultValue = Sources.csharpSource;
                        editor.session.setMode('ace/mode/csharp');
                        break;
                    case ProgrammingLanguages.PHP:
                        defaultValue = Sources.phpSource;
                        editor.session.setMode('ace/mode/php');
                        break;
                    case ProgrammingLanguages.TypeScript:
                        defaultValue = Sources.typescriptSource;
                        editor.session.setMode('ace/mode/typescript');
                        break;
                    case ProgrammingLanguages.Go:
                        defaultValue = Sources.goSource;
                        editor.session.setMode('ace/mode/golang');
                        break;
                    case ProgrammingLanguages.Python:
                        defaultValue = Sources.pythonSource;
                        editor.session.setMode('ace/mode/python');
                        break;
                    default:
                        editor.session.setMode('ace/mode/plain_text');
                }

                // Устанавливаем код или шаблон кода
                editor.setValue(props.modelValue || defaultValue);
                context.emit('update:model-value', editor.getValue());
            }

            function initEditor(): void {
                ace.require('ace/ext/language_tools');
                ace.config.set('workerPath', '/js/');
                ace.config.set('loadWorkerFromBlob', false);
                editor = ace.edit(editorId);

                if (props.inlineCode) {
                    editor.setTheme('ace/theme/textmate');
                } else {
                    editor.setTheme('ace/theme/tomorrow');
                }

                setMode();

                editor.session.on('change', () => {
                    context.emit('update:model-value', editor.getValue());
                });

                editor.setOptions({
                    enableBasicAutocompletion: true,
                    enableSnippets: false,
                    enableLiveAutocompletion: true
                });

                if (props.inlineCode) {
                    editor.setOptions({
                        maxLines: 100,
                        showFoldWidgets: false,
                        showLineNumbers: false,
                        showPrintMargin: false,
                        showGutter: false
                    });
                }

                editor.gotoLine(0);

                if (props.readOnly) {
                    editor.setReadOnly(true);
                }
            }

            onMounted(initEditor);

            return {
                editorId,
            };
        }
    });
</script>

<style lang="scss" scoped>
    .code-editor-component {
        border: 1px solid $shade-4;
        box-sizing: content-box;
        border-radius: 4px;

        &.inline-code {
            pre {
                min-height: auto;
            }

            ::v-deep(.ace_mobile-menu) {
                display: none !important;
            }
        }

        pre {
            margin: 0;
            min-height: 300px;
        }
    }
</style>
