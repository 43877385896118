// Значаения для пагинации указаны для пропорций карточек 4:3
export default {
    pageIsWideThresholdWidth: 1024,
    // Ширина чата 23% (как ширина миничата)
    chatWidthInPercent: 23,
    TILE_REF_PREFIX: 'tile',
    // Максимальное количество плиток, которое мы показываем на одной странице
    MAX_TILES_COUNT_TO_DISPLAY: 32,
    // Максимальное количество плиток,
    // которое мы показываем на одной странице когда открыт чат
    MAX_TILES_COUNT_TO_DISPLAY_WITH_CHAT: 20,
    // Максимальное количество плиток, которое мы показываем на одной странице
    // когда открыт чат и блок с переключением комнат
    MAX_TILES_COUNT_TO_DISPLAY_WITH_CHAT_AND_ROOMS: 16,
    // Максимальное количество плиток,
    // которое мы показываем на одной странице когда открыт блок с переключением комнат
    MAX_TILES_COUNT_TO_DISPLAY_WITH_ROOMS: 28,
    // Максимальное количество плиток,
    // которое мы показываем на одной странице на мобильном устройстве
    MAX_TILES_COUNT_TO_DISPLAY_ON_MOBILE: 6,
    // Максимальное количество плиток,
    // которое мы показываем на одной странице на мобильном устройстве
    // в горизонтальной ориентации
    MAX_TILES_COUNT_TO_DISPLAY_ON_MOBILE_LANDSCAPE: 4
};
