<template>
    <div></div>
</template>

<script lang="ts" setup>
    import { onBeforeUnmount, onMounted } from 'vue';
    import { Common } from 'src/helpers/Common';
    import { RoutePageNameEnum } from 'src/api/ApiClient';
    import { useRoute } from 'vue-router';
    import { Notify } from 'quasar';
    import { localize } from 'src/services/LocalizationService';

    const $route = useRoute();

    let socket: WebSocket | null = null;

    // Находимся ли мы на странице звонка
    const isCallPage: boolean = $route.name === Common.getRouteName(RoutePageNameEnum.CallEnter);

    function init(): void {
        if (Common.isProductionHost()) {
            socket = new WebSocket(`wss://${location.hostname.replace('www.', '')}/spa-reloader`);
            socket.addEventListener('message', onMessage);
        }
    }

    // Показываем сообщение о том, что можно обновить приложение
    function onMessage(event: MessageEvent): void {
        const message = JSON.parse(event.data);

        if (message.namespace.toLowerCase() === 'odin' && !isCallPage) {
            Notify.create({
                classes: 'notifications-components reload-app-notification',
                progress: false,
                message: localize('Доступна новая версия LMS Odin'),
                caption: localize('Пожалуйста, обновите страницу для использования новой версии'),
                position: 'bottom-right',
                timeout: 1000 * 60 * 60,
                color: 'primary',
                multiLine: true,
                html: true,
                avatar: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjAuNjM0MyAxNi4wMDAxTDE0LjM4MzIgNS4zNDc1N0MxMy4zMjk0IDMuNTUwODEgMTAuNjcwMiAzLjU1MDgxIDkuNjE2MzkgNS4zNDc1N0wzLjM2NjE1IDE2LjAwMDFDMi4zMTk2NyAxNy43ODI1IDMuNjQxMDcgMjAgNS43NDk1NyAyMEgxOC4yNTA5QzIwLjM1ODYgMjAgMjEuNjggMTcuNzgyNSAyMC42MzQzIDE2LjAwMDFaIiBzdHJva2U9IiNGRjM3MzciIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CiAgICA8cGF0aCBkPSJNMTIgMTRWOSIgc3Ryb2tlPSIjRkYzNzM3IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgogICAgPHBhdGggZD0iTTEyIDE3VjE3LjAxIiBzdHJva2U9IiNGRjM3MzciIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=',
                actions: [
                    {
                        class: 'link-notifications',
                        label: localize('Обновить'),
                        handler: () => {
                            location.reload();
                        }
                    },
                ],
            });
        }
    }

    // Когда вкладка становится в фокусе, проверяем, не закрылось ли соединение
    // (например долго была неактивна или экран погас)
    // И если закрылось, переоткрываем его
    function reconnect(): void {
        if (socket?.readyState === WebSocket.CLOSED) {
            init();
        }
    }

    onMounted(() => {
        init();
        window.addEventListener('focus', reconnect);
    });

    onBeforeUnmount(() => {
        socket?.close();
        window.removeEventListener('focus', reconnect);
    });
</script>

<style lang="scss">
    .notifications-components.reload-app-notification {
        max-width: 550px !important;

        @media (max-width: 600px) {
            max-width: 98px !important;
        }

        .q-notification__content {
            max-width: none !important;
            height: 80px;
        }

        .q-notification__caption {
            width: 100% !important;
            margin-top: 12px !important;
            font-size: 12px !important;
            line-height: 17px !important;
        }

        .q-notification__avatar--additional {
            margin-right: 12px;
            margin-top: 12px;
        }

        .q-notification__message > div:first-child {
            font-size: 16px;
        }

        .link-notifications {
            .q-btn__content {
                bottom: 12px;
                opacity: 1;
            }
        }
    }
</style>
