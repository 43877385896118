<template>
    <div>
        <div class="text-center" v-if="isLoading">
            <q-spinner-dots color="primary" size="4em"/>
        </div>

        <search-activity-item
            v-else
            v-for="activity in activities"
            :key="activity.id"
            :item="activity"
            @on-item-selected="onItemSelected"
        />

        <show-all-btn v-if="canShowAll" @show-all="showAll"/>
    </div>
</template>

<script lang="ts" setup>
    import { Ref, ref, toRefs } from 'vue';
    import { SearchActivityDto } from 'src/api/ApiClient';
    import SearchActivityItem
        from 'layouts/Main/components/searchBlock/components/activitiesTab/SearchActivityItem.vue';
    import { SearchTabs } from 'layouts/Main/components/searchBlock/types/searchTabs';
    import { SearchEntity } from '../../types/SearchEntity';
    import ShowAllBtn from '../ShowAllBtn.vue';
    import { useSearch } from '../../hooks/useSearch';


    // Компонент с отображением первых 5 найденных активностей
    // Отображается когда выбран поиск по всем сущностям
    const activities: Ref<SearchActivityDto[]> = ref([]);
    const emit = defineEmits<{
        (e: 'on-item-selected'): void;
        (e: 'on-found-items-count', itemsCount: number): void;
        (e: 'show-all', tab: SearchTabs): void;
    }>();
    const props = withDefaults(defineProps<{
        searchText: string;
    }>(), {
        searchText: '',
    });
    const { searchText } = toRefs(props);
    const {
        // Признак, отвечающий за загрузку данных
        isLoading,
        // Нужно ли показывать кнопку "показать все"
        canShowAll,
        // Обработчик выбора элемента
        onItemSelected,
        showAll,
    } = useSearch(SearchEntity.Activities, false, activities, searchText, emit);

</script>
