import { LogLevel, LogSource } from 'src/api/ApiClient';
import * as Sentry from '@sentry/vue';
import { useAccountStore } from 'src/store/module-account';

/** Отправка ошибок из консоли браузера на сервер */
export default class Logger {
    /** Общий список ошибок которой отправили на сервер со страницы */
    /** Защита от спама дубликатами ошибок */
    errorList: string[] = [];

    constructor() {
        const oldOnerror = window.onerror;
        window.onerror = (message: Event | string, errorOnPage: string | undefined, line: number | undefined, column: number | undefined, error: any) => {
            let stackTrace = '';
            const messageError = typeof message === 'string' ? message : message.type;

            if (error && typeof error === 'object') {
                stackTrace = error.stack;
            } else {
                stackTrace = error;
            }

            /** Если такая ошибка уже была в списке, возвращаемся  */
            if (this.errorList.indexOf(messageError) >= 0) {
                return;
            }

            if (Logger.isNeedSend(stackTrace)) {
                const accountStore = useAccountStore();
                const userId = accountStore.getAccountInfo?.id;

                const content = {
                    userId,
                    page: location.href,
                    message: messageError,
                    url: errorOnPage,
                    line,
                    column,
                    stackTrace: stackTrace || undefined,
                    level: LogLevel.Error,
                    source: LogSource.Browser,
                };

                Sentry.captureException(content);
            }

            /** После отправки добавляем ошибку в пул  */
            this.errorList.push(messageError);

            if (oldOnerror) {
                oldOnerror.apply(console, [message, errorOnPage, line, column, error]);
            }
        };

        const oldErrorFunc = console.error;

        console.error = (error: any) => {
            if (oldErrorFunc) {
                oldErrorFunc.apply(console, [error]);
            }

            this.consoleOnError(error);
        };
    }

    consoleOnError(error: any): void {
        let message = '';
        let stackTrace = '';

        if (error && typeof error === 'object') {
            message = error.message;
            stackTrace = error.stack;
        } else {
            message = error;
            stackTrace = error;
        }

        /** Если такая ошибка уже была в списке, возвращаемся  */
        if (this.errorList.indexOf(message) >= 0) {
            return;
        }

        if (Logger.isNeedSend(stackTrace)) {
            const accountStore = useAccountStore();
            const userId = accountStore.getAccountInfo?.id;

            const content = {
                page: location.href,
                message,
                stackTrace,
                userId,
                level: LogLevel.Error,
                source: LogSource.Browser,
            };

            Sentry.captureException(content);
        }

         /** После отправки добавляем ошибку в пул  */
         this.errorList.push(message);
    }

    public static isNeedSend(stackTrace?: string): boolean {
        if (!stackTrace) {
            return true;
        }

        const filterErrors = [
            'ChunkLoadError',
            'Loading chunk',
            'Loading CSS chunk',
            'Unexpected token \'<\'',
            'Connection disconnected with error',
            'Failed to start the connection: Error',
            'Cannot send data if the connection is not',
            'Lost connection to the server',
            'Error connecting to the Janus WebSockets server',
            'ResizeObserver loop completed',
            'chrome-extension',
            'An unexpected server error occurred',
            'Load failed',
            'failed because the user didn\'t interact with the document first',
            'The request is not allowed by the user agent or the platform in the current context',
            '_invokeMethod(captcha)',
            'Permission denied',
            'Failed to fetch'
        ];

        return filterErrors.every((e: string) => {
            return stackTrace.toString().toLowerCase().search(e.toLowerCase()) === -1;
        });
    }

    public static log(...args: any): void {
        // eslint-disable-next-line no-console
        console.log(...args);
    }

    public static err(...args: any): void {
        console.error(...args);
    }

}
