import { AccountCurrentInfoResponseModel, UserClient } from 'src/api/ApiClient';
import { getApiClientInitialParams } from 'src/api/BaseApiClient';
import firebase from 'firebase';
import FirebaseClientConfig from 'src/services/FirebaseClientConfig';
import { notifyError, NotifyErrors, notifySuccess } from 'src/api/ResultOfMethods';
import { Notify } from 'quasar';
import { localize } from 'src/services/LocalizationService';
import { useAccountStore } from 'src/store/module-account';

class FirebaseTokenManagedService {
    /* Токен пользователя для текущего браузера */
    private currentToken: string | null = null;

    /* Все активные токены пользователя */
    private existUserTokens: string[] = [];

    /* Идентификатор пользователя для которого хранятся токены */
    private existTokensFortUserId: number | null = null;

    /* Клиент дял запросов к методам api */
    private userClient: UserClient;

    private constructor() {
        this.userClient = new UserClient(getApiClientInitialParams());

        if (firebase.apps.length === 0) {
            firebase.initializeApp(FirebaseClientConfig.Config);
        }
    }

    /* Включены ли уведомления в профиле пользователя */
    get isUserPushEnabled(): boolean {
        return this.currentAccountInfo?.isPushEnabled ?? false;
    }

    get currentAccountInfo(): AccountCurrentInfoResponseModel | null {
        const accountStore = useAccountStore();
        return accountStore.getAccountInfo;
    }

    /* Создать экземпляр сервиса */
    static async Create(): Promise<FirebaseTokenManagedService> {
        const service = new FirebaseTokenManagedService();
        await service.sync();

        return service;
    }

    /* Получить все токены пользователя с сервера */
    private async getUserTokens(): Promise<void> {
        /* Пересоздаем клиент api и получаем новые токены если сменили пользователя */
        if (!!this.currentAccountInfo && this.currentAccountInfo?.id !== this.existTokensFortUserId) {
            this.userClient = new UserClient(getApiClientInitialParams());
            const tokensResult = await this.userClient.getWebTokens();
            if (tokensResult.isSuccess) {
                this.existUserTokens = tokensResult.entity.tokens;
                this.existTokensFortUserId = this.currentAccountInfo?.id ?? null;
            }
        }
    }

    /* Проверить необходимые разрешения на push уведомления.
    *  Получить и сохранить новые токены все разрешения есть
    * */
    async sync(): Promise<void> {
        await this.getUserTokens();

        if (!this.isPushEnabled()) {
            return;
        }

        /* Получить токен браузера */
        this.currentToken = await this.getFcmToken();
        await this.saveToken();
    }

    /* Поддерживает ли браузер уведомления */
    isNotificationSupport(): boolean {
        // Вычисляем через userAgent браузеры, которые не работаю корректно с веб интерфейсом Notification (например, Mi/Miui Browser)
        // и отключаем для них возможность работы с уведомлениями
        const pattern = new RegExp('xiaomi|miuiBrowser', 'gi');
        const isNotSupportedBrowser = pattern.test(navigator.userAgent);

        return 'Notification' in window && firebase?.messaging?.isSupported() === true && !isNotSupportedBrowser;
    }

    /* Разрешен ли показ уведомлений в настройках браузера */
    isNotificationAllow(): boolean {
        return this.isNotificationSupport() && Notification.permission === 'granted';
    }

    /* Показывать ли плашку с включением push уведомлений */
    canShowPushPanel(): boolean {
        //нужно показывать, если они выключены у пользователя, или они включены, но токена в браузере нет
        return (!this.isUserPushEnabled) || (!this.tokenExist(this.currentToken) && this.isUserPushEnabled);
    }

    /* Включены ли push уведомления (Проверяются настройки браузера и профиля пользователя) */
    isPushEnabled(): boolean {
        return this.isNotificationAllow() && this.isUserPushEnabled;
    }

    /* Принадлежит ли проверяемый токен текущему пользователю */
    private tokenExist(token: string | null): boolean {
        if (!token) {
            return false;
        }

        return !!this.existUserTokens.find((x: string) => x === token);
    }

    /* Получить токен текущего браузера и сохранить для текущего пользователя */
    private async saveToken(): Promise<void> {
        if (this.isPushEnabled()) {
            if (!!this.currentToken && !this.tokenExist(this.currentToken)) {
                await this.userClient.addFcmWebToken(this.currentToken, window.location.hostname);
                this.existUserTokens.push(this.currentToken);
            }
        }
    }

    /* Получить c firebase токен для браузера */
    private async getFcmToken(): Promise<string | null> {
        return this.isNotificationAllow()
            ? firebase
                .messaging()
                .getToken()
            : null;
    }

    /* Запросить системное разрешение на получение уведомлений (Запрос на включение уведомлений в настройках браузера) */
    private async subscribe(): Promise<boolean> {
        if (!this.isNotificationSupport()) {
            notifyError(localize('Браузер не поддерживает push-уведомления'));
            return false;
        }

        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            this.currentToken = await this.getFcmToken();
            await this.saveToken();
            return true;
        } else {
            notifyError(localize('Необходимо разрешить push-уведомления в настройках браузера'));
        }

        return false;
    }

    /* Вклчючить push-уведомления */
    async enableNotification(isNeedNotify: boolean = true): Promise<boolean> {
        const request = await this.userClient.enablePushNotification();
        if (!request.isSuccess) {
            NotifyErrors(request);
            return false;
        }

        const accountStore = useAccountStore();
        if (accountStore.accountInfo) {
            accountStore.accountInfo.isPushEnabled = true;
        }

        const isSubscribed = await this.subscribe();

        if (isNeedNotify) {
            if (isSubscribed) {
                notifySuccess(localize('Уведомления включены'));
            } else {
                notifyError(localize('Уведомления отключены'));
            }
        }

        return isSubscribed;
    }

    /* Отключить уведомления в профиле и удалить текущий токен из базы */
    async disableNotification(isNeedNotify: boolean = true): Promise<boolean> {
        const request = await this.userClient.disablePushNotification();
        if (!request.isSuccess) {
            NotifyErrors(request);
            return false;
        }

        const accountStore = useAccountStore();
        if (accountStore.accountInfo) {
            accountStore.accountInfo.isPushEnabled = false;
        }

        if (isNeedNotify) {
            Notify.create({
                type: 'positive',
                message: localize('Уведомления отключены'),
            });
        }

        await this.removeToken();

        return true;
    }

    /* Удалить действующий токен из базы для данного пользователя
    * Метод необходим при отключении уведомлений со страницы профиля и при выходе из аккаунта
    *  */
    async removeToken(): Promise<boolean> {
        let result = true;
        if (!!this.currentToken) {
            result = (await this.userClient.removeFcmToken(this.currentToken)).isSuccess;
            if (result) {
                const index = this.existUserTokens.findIndex((x: string) => x === this.currentToken);
                this.existUserTokens.splice(index, 1);
            }
        }

        return result;
    }
}

export default FirebaseTokenManagedService;
