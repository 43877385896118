<template>
    <Picker
        set="google"
        :sheetSize="32"
        @select="selectEmoji"
        @click="stopBubble"
        :showPreview="false"
        :perLine="7"
        :color="'#3E53A4'"
        :data="emojiIndex"
        :i18n="localizedEmojiCategories"
        :infiniteScroll="true"
        class="emoji-picker"
    />
</template>

<script lang="ts">
    import { Picker, EmojiIndex } from 'emoji-mart-vue-fast/src';
    import { localize } from 'src/services/LocalizationService';
    import data from 'emoji-mart-vue-fast/data/google.json';
    import { defineComponent } from 'vue';

    // Компонент выбора смайлов
    export default defineComponent({
        name: 'EmojiBlock',

        components: {
            Picker,
        },

        emits: [
            'select',
        ],

        setup(props, context) {
            // Настройки локализации для компонента выбора эмоджи
            const localizedEmojiCategories: Record<string, string | Record<string, string>> = {
                search: localize('Поиск'),
                notfound: localize('Смайлы не найдены'),
                categories: {
                    recent: localize('Часто используемые'),
                    people: localize('Люди'),
                    smileys: localize('Смайлы и эмоции'),
                    nature: localize('Животные и природа'),
                    foods: localize('Еда и напитки'),
                    activity: localize('Занятия'),
                    places: localize('Места и путешествия'),
                    objects: localize('Предметы'),
                    symbols: localize('Символы'),
                    flags: localize('Флаги'),
                },
            };

            const emojiIndex: any = new EmojiIndex(data);

            function selectEmoji(emoji: any): void {
                // вызываем событие чтобы родительский компонент его обработал
                context.emit('select', emoji);
            }

            // Если выбрали смайл - окно должно скрыться
            // в противном случае окно не скрываем
            function stopBubble(event: Event): void | boolean {
                if ((event.target as HTMLDivElement).classList.contains('emoji-type-image')) {
                    return true;
                } else {
                    event.stopPropagation();
                }
            }

            return {
                localizedEmojiCategories,
                emojiIndex,
                selectEmoji,
                stopBubble,
                localize,
            };
        },

    });
</script>

<style src="emoji-mart-vue-fast/css/emoji-mart.css"></style>

<style lang="scss" scoped>
    .emoji-picker {
        ::v-deep(.emoji-mart-anchor) {
            padding: 14px 0;
        }
    }
</style>
