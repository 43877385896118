<template>
    <q-dialog v-model="isVisible">
        <q-card class="confirm-modal q-pa-lg">
            <div class="confirm-modal__title row justify-between">
                {{ localize('Записи звонков') }}

                <q-icon
                    name="close"
                    size="20px"
                    v-close-popup
                    class="cursor-pointer" />
            </div>
            <div class="call-records-block">
                <div v-if="isLoading && !callRecords.length" class="text-center q-mt-xl">
                    <q-spinner-dots color="primary" size="4em" />
                </div>
                <q-scroll-area style="height: 100%;" @scroll="onScroll" v-if="callRecords.length">
                    <q-card class="no-shadow">
                        <q-card-section
                            class="call-records-block__item no-border"
                            v-for="item in callRecords"
                            :key="'callRecord' + item.callId + item.encodedFileId"
                        >
                            <div
                                @click="showFileViewer(item)"
                                class="row record-item q-py-sm cursor-pointer items-center justify-between no-wrap"
                            >
                                <div class="call-records-block__item__left row items-center justify-between no-wrap">
                                    <div class="call-records-block__item__info row items-center no-wrap">
                                        <div class="call-records-block__item__icon row items-center justify-center">
                                            <CallRecordCoding v-if="isEncodingInProgress(item)" />
                                            <CallRecordCodingSuccess
                                                v-if="item.encodingState === EncodingState.EncodingFinished || item.encodingState === EncodingState.NoEncoding" />
                                            <CallRecordCodingError
                                                v-if="item.encodingState === EncodingState.EncodingFailed" />
                                        </div>
                                        <div class="call-records-block__item__date">
                                            {{ filterFullDateYearAndTime(item.date) }}
                                        </div>
                                    </div>
                                    <div class="call-records-block__item__text" v-if="isEncodingInProgress(item)">
                                        <q-tooltip>
                                            {{
                                                localize('Запись будет доступна после кодировки. Процесс завершится автоматически')
                                            }}
                                        </q-tooltip>
                                        {{ localize('Кодируется...') }}
                                    </div>
                                    <div
                                        v-if="item.encodingState === EncodingState.EncodingFailed && !item.isOutdated"
                                        class="call-records-block__item__text"
                                    >
                                        <q-tooltip>
                                            {{
                                                localize('Кодирование завершилось ошибкой. Вы можете скачать видео для просмотра на своём устройстве')
                                            }}
                                        </q-tooltip>
                                        {{ localize('Ошибка кодирования') }}
                                    </div>
                                </div>
                                <div
                                    class="call-records-block__item__button text-right row items-center justify-end"
                                    :class="{actions: !!item.encodedFileId}"
                                >
                                    <q-spinner-ios
                                        v-if="isEncodingInProgress(item) && !item.encodedFileId"
                                        color="primary"
                                        size="24px"
                                    />
                                    <template v-if="item.encodingState === EncodingState.EncodingFinished || item.encodingState === EncodingState.NoEncoding">
                                        <Icon
                                            v-if="item.canManage"
                                            @click.native.stop="saveToMyLibrary(item)"
                                            name="LibraryAddIcon"
                                            class="q-mr-md"
                                            :color="ColorValiablesNameEnum.shade7">
                                            <q-tooltip>
                                                {{
                                                    localize('Сохранить в личную библиотеку')
                                                }}
                                            </q-tooltip>
                                        </Icon>
                                        <q-spinner v-if="item.isDownloading" :size="'24px'" />
                                        <Icon
                                            v-else-if="item.canDownload"
                                            @click.native.stop="downloadFile(item)"
                                            name="DownloadIcon"
                                            size="24"
                                            :color="ColorValiablesNameEnum.shade7"
                                            class="q-mr-md"
                                            v-bind="item.isOutdated ? { disabled: true } : {}"
                                        >
                                            <q-tooltip v-if="item.isOutdated">
                                                {{
                                                    localize('Возможно скачивание записей звонков в течение 30 дней после создания')
                                                }}
                                            </q-tooltip>
                                        </Icon>
                                    </template>
                                    <Icon
                                        v-if="item.canRemove && !item.isDownloading"
                                        name="TrashIcon"
                                        :color="ColorValiablesNameEnum.shade7"
                                        @click.native.stop="showRemoveDialog(item)" />
                                </div>
                            </div>
                            <q-separator />
                        </q-card-section>

                    </q-card>
                    <div v-if="isLoading && callRecords.length" class="text-center q-mt-xl">
                        <q-spinner-dots color="primary" size="4em" />
                    </div>
                </q-scroll-area>
                <no-items v-if="!isLoading && !callRecords.length" />
            </div>
            <div v-if="callRecords.length && canDownloadRecords" class="load-records-warning">
                {{ localize('Доступ к скачиванию записей звонков - 30 дней') }}
            </div>

            <FileViewer ref="fileViewerRef" />

            <custom-modal
                v-model="isShowRemoveDialog"
                @delete-handler="removeCallRecord"
            >
                <template #title>
                    {{ localize('Удалить запись звонка') }}
                </template>

                <template v-if="removingCallRecord" #content>
                    {{ localize('Вы уверены, что хотите удалить запись звонка от') }}
                    {{ filterFullDateYearAndTime(removingCallRecord.date) }} ?
                </template>
            </custom-modal>
        </q-card>
    </q-dialog>
</template>

<script lang="ts">
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { CallClient, CallRecordsModel, FileClient, EncodingState } from 'src/api/ApiClient';
    import { NotifyErrors, notifySuccess } from 'src/api/ResultOfMethods';
    import { localize } from 'src/services/LocalizationService';
    import { ContentViewerType } from 'components/ContentViewer/types';
    import FileViewer from 'components/ContentViewer';
    import { defineComponent, ref } from 'vue';
    import useFilters from 'src/helpers/custom-hooks/useFilters';
    import { ColorValiablesNameEnum } from 'components/ui/Icon/interface';

    export default defineComponent({
        name: 'CallRecords',

        props: {
            // id текущего чата
            chatId: {
                type: Number,
                default: 0,
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props) {
            const fileViewerRef = ref<typeof FileViewer>();

            const isVisible = ref<boolean>(false);

            // Признак, отвечающий за загрузку данных
            const isLoading = ref<boolean>(false);

            const callRecords = ref<CallRecordsModel[]>([]);
            const isShowRemoveDialog = ref<boolean>(false);
            const removingCallRecord = ref<CallRecordsModel | null>(null);
            const canDownloadRecords = ref<boolean>(false);

            // Текущая страница данных. Используется
            // для бесконечного скролла
            let currentPage: number = 1;
            let canLoadRecords: boolean = true;

            // Хук фильтра
            const { filterFullDateYearAndTime } = useFilters();

            function show(): void {
                isVisible.value = true;

                // Загружаем данные при показе окна, но только если их нет
                // иначе будет дублирование
                if (!callRecords.value.length) {
                    loadCallRecords();
                }
            }

            // Обработка загрузки на onScroll, кода доскролили почти до конца
            // (те больше 90%) загружаем запрос
            function onScroll({ verticalPercentage }: { verticalPercentage: number }): void {
                if (verticalPercentage > 0.9 && !isLoading.value && canLoadRecords) {
                    loadCallRecords();
                }
            }

            // Загрузка данные с пагинацией
            async function loadCallRecords(): Promise<void> {
                isLoading.value = true;
                const result = await new CallClient(getApiClientInitialParams()).callRecords(props.chatId, currentPage);

                if (result.isSuccess) {
                    callRecords.value.push(...result.entity.pageItems);
                    canLoadRecords = result.entity.pagedMetaData.hasNextPage;
                    canDownloadRecords.value = callRecords.value.find((x: CallRecordsModel) => x.canDownload === true) != null;

                    // Можно ли загрузить еще данные
                    if (canLoadRecords) {
                        currentPage++;
                    }
                } else {
                    NotifyErrors(result);
                }

                isLoading.value = false;
            }

            function showRemoveDialog(item: CallRecordsModel): void {
                if (item.canManage) {
                    isShowRemoveDialog.value = true;
                    // сохраняем удаляемый элемент
                    removingCallRecord.value = item;
                }
            }

            // Удаление записи
            async function removeCallRecord(): Promise<void> {
                if (removingCallRecord.value && removingCallRecord.value.canManage) {
                    const result = await new CallClient(getApiClientInitialParams()).removeCallRecord(removingCallRecord.value.recordId);

                    if (result.isSuccess) {
                        const index = callRecords.value.findIndex((x: CallRecordsModel) => x.recordId === removingCallRecord.value?.recordId);

                        // удаляем запись из списка
                        if (index !== -1) {
                            callRecords.value.splice(index, 1);
                        }

                        isShowRemoveDialog.value = false;
                    } else {
                        NotifyErrors(result);
                    }
                }
            }

            async function saveToMyLibrary(record: CallRecordsModel): Promise<void> {
                if (record.canManage) {
                    const result = await new CallClient(getApiClientInitialParams()).saveCallRecordToMyLibrary(record.recordId);

                    if (result.isSuccess) {
                        notifySuccess(localize('Запись добавлена в вашу библиотеку'));
                    } else {
                        NotifyErrors(result);
                    }
                }
            }

            // Показать компонент просмотра файлов
            function showFileViewer(item: CallRecordsModel): void {
                if (!item.encodedFileId) {
                    return;
                }

                const recordsWithId = callRecords.value.filter((x: CallRecordsModel) => !!x.encodedFileId);
                const fileIds = recordsWithId.map((x: CallRecordsModel) => x.encodedFileId) as number[];

                fileViewerRef.value?.show({
                    type: ContentViewerType.File,
                    fileId: item.encodedFileId,
                    indexFile: fileIds.indexOf(item.encodedFileId),
                    fileIds,
                });
            }

            // Кодирование в процессе
            function isEncodingInProgress(item: CallRecordsModel): boolean {
                return item.encodingState === EncodingState.NeedEncoding;
            }

            // Скачивание файла
            async function downloadFile(callRecord: CallRecordsModel): Promise<void> {
                if (!callRecord.encodedFileId || callRecord.isOutdated) {
                    return;
                }

                callRecord.isDownloading = true;

                let fileName = localize('Запись звонка от ') + filterFullDateYearAndTime(callRecord.date);
                if (callRecord.chatName) {
                    fileName = fileName + ' ' + callRecord.chatName;
                }

                const isCallRecord = true;
                const request = await new FileClient(getApiClientInitialParams()).getPresignedDownloadUrl(callRecord.encodedFileId, fileName, isCallRecord);

                if (request.isSuccess) {
                    const link = document.createElement('a');
                    link.setAttribute('href', request.entity.value);
                    link.setAttribute('download', 'download');
                    link.click();
                }

                callRecord.isDownloading = false;
            }

            return {
                fileViewerRef,
                isVisible,
                isLoading,
                callRecords,
                canDownloadRecords,
                isShowRemoveDialog,
                removingCallRecord,
                onScroll,
                showRemoveDialog,
                removeCallRecord,
                show,
                downloadFile,
                showFileViewer,
                localize,
                isEncodingInProgress,
                filterFullDateYearAndTime,
                EncodingState,
                saveToMyLibrary,
                ColorValiablesNameEnum,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .confirm-modal {
        width: 556px;
        border-radius: 8px;
    }

    .call-records-block {
        height: 310px;

        .record-item {
            padding: 12px 0;
            margin-right: 14px;
        }

        &__item {
            &__left {
                width: 100%;
            }

            &__info {
                width: 225px;
            }

            &__date {
                margin-left: 15px;
                color: #000;
            }

            &__text {
                color: $shade-8;
            }

            &__button {
                width: 104px;
                min-width: 104px;
            }

            .actions {
                transition: opacity 300ms;
                opacity: 0;
            }

            &:hover .actions {
                opacity: 1;
            }

            @media (max-width: 960px) {
                .actions {
                    opacity: 1;
                }
            }

            @media (max-width: 960px) {
                .confirm-modal {
                    width: 98%;
                }
            }

            @media (max-width: 500px) {
                .record-item {
                    align-items: flex-start;
                }

                &__left {
                    flex-wrap: wrap !important;
                }

                &__info {
                    width: 100%;
                }

                &__text {
                    padding-left: 40px;
                }
            }
        }

        ::v-deep(.record-item) {
            svg {
                transition: opacity 0.3s;
            }
        }
    }

    .load-records-warning {
        display: flex;
        height: 48px;
        align-items: center;
        color: $shade-8;
        font-size: 12px;
        margin-bottom: -24px;
    }
</style>
