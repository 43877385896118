import { CallPageStoreInterface } from 'src/store/module-call/state';
import getInterlocutorInfoRequest from 'pages/Call/utils/getInterlocutorInfoRequest';
import Logger from 'src/services/Logger';
import { IInterlocutor, ISize } from 'pages/Call/types/interfaces';
import { ActionContext } from 'src/store/type';
import * as stateStore from 'src/store/module-call/state';
import { CallEnterResponseModel, CallUserModel } from 'src/api/ApiClient';
import convertInterlocutorFromServer from 'pages/Call/utils/convertInterlocutorFromServer';
import constants from 'pages/Call/data/constants';

// Обновить информацию о пользователе в звонке
function updateInterlocutor(state: CallPageStoreInterface, interlocutor: IInterlocutor): void {
    const interlocutorIndex = state.interlocutors.findIndex((x: IInterlocutor) => x.personId === interlocutor.personId);
    const interlocutors = [...state.interlocutors];
    const oldInterlocutor = interlocutors[interlocutorIndex];

    // Если пользователь подключился к звонку - добавляем его в конец
    // Он переместится в нужное место когда отработает сортировка
    if (interlocutor.needShowInTile === true && oldInterlocutor.needShowInTile === false) {
        interlocutors.splice(interlocutorIndex, 1);
        interlocutors.push({
            ...oldInterlocutor,
            ...interlocutor
        });
    } else {
        interlocutors[interlocutorIndex] = {
            ...oldInterlocutor,
            ...interlocutor
        };
    }

    state.interlocutors = interlocutors;
}

const actions = {
    async addOrUpdateInterlocutor(this: ActionContext<'callStore', CallPageStoreInterface>, interlocutor: IInterlocutor): Promise<void> {
        const interFromStore = this.$state.interlocutors.find((x: IInterlocutor) => x.personId === Number(interlocutor.personId));

        //У нас есть информация об этом пользователе в store
        if (interFromStore) {
            // Если инофрмация обновляется, значит пользователь в звонке
            // поэтому удаляем removeTimeoutId
            if (interFromStore.removeTimeoutId) {
                clearTimeout(interFromStore.removeTimeoutId);
            }

            updateInterlocutor(this.$state, {
                ...interFromStore,
                ...interlocutor,
            });
            return;
        }

        if (!this.$state.callStartingData) {
            Logger.log(`Нет информации о звонке у пользователя с Id ${interlocutor?.personId}`);
            return;
        }

        //Если нет - получаем с бека
        const interFromDB = await getInterlocutorInfoRequest(interlocutor.personId, this.$state.callStartingData.callId);

        if (interFromDB && interFromDB[0]) {
            this.$state.interlocutors.push({
                ...interFromDB[0],
                ...interlocutor,
            });
            return;
        }

        Logger.log(`Нет информации о пользователе с Id ${interlocutor.personId}`);
    },
    updateInterlocutor(this: CallPageStoreInterface, interlocutor: IInterlocutor): void {
        updateInterlocutor(this, interlocutor);
    },
    setCallPanelWidth(this: CallPageStoreInterface, callPanelWidth: number): void {
        if (this.pageIsWide && this.chatIsVisible) {
            this.callPanelWidth = callPanelWidth;
        }
    },
    setUserMicrophoneOn(this: CallPageStoreInterface, userAudioTurnedOn: boolean): void {
        this.userData.microphoneOn = userAudioTurnedOn;

        if (!userAudioTurnedOn) {
            this.userData.isTalking = false;
        }
    },
    // Перестать отображать пользователя в плитках
    setInterlocutorNeedShowInTileToFalse(this: CallPageStoreInterface, personId: number): void {
        const interlocutor = this.interlocutors.find((x: IInterlocutor) => x.personId === personId);

        if (interlocutor) {
            interlocutor.needShowInTile = false;
            interlocutor.videoOn = false;
            interlocutor.stream = null;
        } else {
            Logger.log(`Store -> setInterlocutorNeedShowInTileToFalse: Error: personId = ${personId} not found!`);
        }
    },
    // Проставляем для пользователя id таймаута
    setRemoveTimeoutId(this: CallPageStoreInterface, payload: { personId: number, timeoutId: ReturnType<typeof setTimeout>} ) {
        const user = this.interlocutors.find((x: IInterlocutor) => x.personId === payload.personId);

        if (user) {
            // Выключаем видео, иначе оно зависнет на последнем кадре, либо черным экраном
            user.videoOn = false;
            user.removeTimeoutId = payload.timeoutId;
        }
    },
    // Завершить звонок и установить сообщение, которое будет показано пользователю
    stopCallWithMessage(this: CallPageStoreInterface, message: string): void {
        this.serviceMessage = message;
        this.roomEnabled = false;
        const stream: MediaStream | null = this.userData?.stream ?? null;
        if (stream) {
            stream.getTracks()?.forEach((track: any) => {
                track.stop();
            });
        }
    },
    putDownAllHands(this: CallPageStoreInterface) {
        this.interlocutors.forEach((x: IInterlocutor) => {
            x.riseHandTimeUtc = null;
        });
        this.userData.riseHandTimeUtc = null;
    },
    setUserHandUp(this: CallPageStoreInterface, userHandUp: boolean) {
        if (!userHandUp) {
            this.userData.riseHandTimeUtc = null;
        }
    },
    initState(this: ActionContext<'callStore', CallPageStoreInterface>) {
        const defaultState: CallPageStoreInterface = stateStore.default();
        this.$state.callStartingData = null;

        for (const stateKey in this.$state) {
            // Заполнить стор дефолтными значениями
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.$state[stateKey] = defaultState[stateKey];
        }
    },
    // Сохранить информацию о звонке и сконвертировать участников звонка в нужную модель
    setCallStartingData(this: CallPageStoreInterface, callStartingData: CallEnterResponseModel) {
        this.callStartingData = callStartingData;
        this.interlocutors = callStartingData.expectedInterlocutorsData.map(
            (expectedInterlocutorData: CallUserModel) => ({
                ...convertInterlocutorFromServer(expectedInterlocutorData),
                needShowInTile: false,
            })
        );
        this.isRecordingEnabled = callStartingData.isRecordingEnabled;

        if (callStartingData.userData.riseHandTimeUtc) {
            this.userData.riseHandTimeUtc = callStartingData.userData.riseHandTimeUtc;
        }
    },
    setUserStream(this: CallPageStoreInterface, stream: MediaStream | null) {
        this.userData.stream = stream;

        if (stream) {
            const videoTracks = stream.getVideoTracks();
            if (videoTracks.length) {
                this.userData.videoTrack = videoTracks[0];
            } else {
                this.userData.videoTrack = null;
            }
        } else {
            this.userData.videoTrack = null;
        }
    },
    setPageSize(this: CallPageStoreInterface, pageSize: ISize) {
        if (this.pageWidth >= constants.pageIsWideThresholdWidth && this.chatIsVisible) {
            // ширина чата 23% соотвественно ширина остальной части 77
            this.callPanelWidth = Math.ceil((pageSize.width * (100 - constants.chatWidthInPercent)) / 100);
        }
        this.pageWidth = pageSize.width;
        this.pageHeight = pageSize.height;
    },

    // Сортировка пользователей по трём признакам
    // по riseHandTimeUtc(поднятая рука), по включенному видео и по sortId
    sortInterlocutors(this: CallPageStoreInterface) {
        // Копируем массив
        let interlocutors = this.interlocutors.slice();
        let isNeedSortByHand = true;
        let interlocutorsWithHands: IInterlocutor[] = [];

        // Если это звонок дисциплины, то сортируем только для преподавателей
        if (this.callStartingData?.callDiscipline) {
            isNeedSortByHand = this.callStartingData.userData.isCallManager;
        }

        if (isNeedSortByHand) {
            // Выбираем пользователей с поднятыми руками
            interlocutorsWithHands = interlocutors.filter((x: IInterlocutor) => !!x.riseHandTimeUtc);

            if (this.userData.riseHandTimeUtc) {
                // Если у текущего пользователя поднята рука, то добавляем его в массив
                // что бы у всех была одинковая нумерация в порядке поднятия руки
                const index = interlocutorsWithHands.findIndex((x: IInterlocutor) => {
                    return x.personId === this.callStartingData!.userData.id;
                });

                if (index === -1) {
                    interlocutorsWithHands.push(this.userData);
                }
            }

            // Сортируем пользователей с видео по riseHandTimeUtc
            interlocutorsWithHands.sort((left: IInterlocutor, right: IInterlocutor) => {
                return new Date(left.riseHandTimeUtc!).getTime() - new Date(right.riseHandTimeUtc!).getTime();
            });

            for (let i = 0; i < interlocutorsWithHands.length; i++) {
                interlocutorsWithHands[i].handOrder = i + 1;
            }
        }

        // Исключаем пользователей с поднятыми руками
        interlocutors = interlocutors.filter((x: IInterlocutor) => {
            return !interlocutorsWithHands.find((u: IInterlocutor) => x.personId === u.personId);
        });

        // Выбираем пользователй с видео
        const interlocutorsWithVideo = interlocutors.filter((x: IInterlocutor) => x.videoOn);

        // Выбираем пользователй без видео
        const interlocutorsWithoutVideo = interlocutors.filter((x: IInterlocutor) => !x.videoOn);

        // Сортируем пользователей с видео по sortId
        interlocutorsWithVideo.sort((left: IInterlocutor, right: IInterlocutor) => {
            return right.sortId - left.sortId;
        });

        // Сортируем пользователей без видео по sortId
        interlocutorsWithoutVideo.sort((left: IInterlocutor, right: IInterlocutor) => {
            return right.sortId - left.sortId;
        });

        // Собираем новый массив - сначала пользователи с видео, потом - без
        this.interlocutors = interlocutorsWithHands.concat(interlocutorsWithVideo.concat(interlocutorsWithoutVideo));
    },
};

export default actions;
