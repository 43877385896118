<template>
    <q-dialog v-model="isVisible">
        <q-card class="confirm-modal q-py-lg">
            <div class="confirm-modal__title q-px-lg row justify-between">
                {{ localize('Прикреплённые файлы') }}

                <q-icon
                    name="close"
                    size="20px"
                    v-close-popup
                    class="cursor-pointer" />
            </div>
            <div class="files-block q-mt-lg">
                <div v-if="isLoading && !files.length" class="text-center q-mt-xl">
                    <q-spinner-dots color="primary" size="4em" />
                </div>
                <template v-if="files.length">
                    <q-scroll-area style="height: 100%;" @scroll="onScroll">
                        <template v-if="isMobileView">
                            <q-separator/>
                            <div
                                v-for="file in files"
                                :key="file.id"
                                @click="showFileViewer(file.id)"
                                class="file-row-item">
                                <div class="row items-center justify-between">
                                    <div class="col">
                                        <div class="row">
                                            <component :is="getFileIcon(file)" class="file-icon q-mr-xs"></component>
                                            <div class="col">{{ file.fileName }}</div>
                                        </div>
                                    </div>
                                    <q-icon @click.stop name="more_vert" size="22px">
                                        <q-menu ref="menu">
                                            <q-list>
                                                <q-item
                                                    clickable
                                                    v-close-popup
                                                    v-if="file.chatMessageId"
                                                    @click="goToMessage(file.chatMessageId)"
                                                >
                                                    <q-item-section>
                                                        <div class="row items-center">
                                                            <main-icon class="q-mr-sm" />
                                                            {{ localize('Перейти к сообщению') }}
                                                        </div>
                                                    </q-item-section>
                                                </q-item>
                                                <q-item clickable v-close-popup @click="downloadFile(file.id)">
                                                    <q-item-section>
                                                        <div class="row items-center">
                                                            <download-icon class="grey q-mr-sm" />
                                                            {{ localize('Скачать') }}
                                                        </div>
                                                    </q-item-section>
                                                </q-item>
                                                <q-item
                                                    v-if="file.author && isCanDeleteFile(file.author)"
                                                    clickable
                                                    v-close-popup
                                                    @click="removeFile(file)"
                                                >
                                                    <q-item-section>
                                                        <div class="row items-center">
                                                            <trash-icon class="grey grey-path q-mr-sm" />
                                                            {{ localize('Удалить') }}
                                                        </div>
                                                    </q-item-section>
                                                </q-item>
                                            </q-list>
                                        </q-menu>
                                    </q-icon>
                                </div>
                                <div class="info row justify-between items-center q-mt-sm">
                                    <div class="row">
                                        <file-size-icon class="q-mr-xs" />
                                        <div> {{ getFileSize(file.size) }}</div>
                                    </div>
                                    <div class="row">
                                        <calendar2-icon class="q-mr-xs" />
                                        <div v-if="file.createDateTime">
                                            {{ new Date(file.createDateTime).toLocaleDateString() }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <table v-if="files.length">
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td class="text-center">{{ localize('Название') }}</td>
                                        <td>
                                            <div style="margin-left: 32px;">
                                                {{ localize('Отправитель') }}
                                            </div>
                                        </td>
                                        <td class="text-center">{{ localize('Размер') }}</td>
                                        <td class="text-center">{{ localize('Дата создания') }}</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="file in files"
                                        :key="file.id"
                                        @click="showFileViewer(file.id)">
                                        <td>
                                            <component :is="getFileIcon(file)" class="file-icon"></component>
                                        </td>
                                        <td>
                                            {{ file.fileName }}
                                        </td>
                                        <td>
                                            <div v-if="file.author" class="row items-center">
                                                <q-avatar size="24px" aria-hidden="true">
                                                    <img v-if="file.author.photoUrl" :src="file.author.photoUrl" alt="" />
                                                    <custom-avatar
                                                        v-else
                                                        :text="getFullName(file.author)"
                                                        :color="CustomAvatarColors.User"
                                                    />
                                                </q-avatar>
                                                <div class="col q-ml-sm">
                                                    {{ getFullName(file.author) }}
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{ getFileSize(file.size) }}
                                        </td>
                                        <td class="text-center">
                                            <div v-if="file.createDateTime">
                                                {{ new Date(file.createDateTime).toLocaleDateString() }}
                                            </div>
                                        </td>
                                        <td @click.stop>
                                            <q-icon name="more_vert" size="22px">
                                                <q-menu ref="menu">
                                                    <q-list>
                                                        <q-item
                                                            clickable
                                                            v-close-popup
                                                            v-if="file.chatMessageId"
                                                            @click="goToMessage(file.chatMessageId)"
                                                        >
                                                            <q-item-section>
                                                                <div class="row items-center">
                                                                    <main-icon class="q-mr-sm" />
                                                                    {{ localize('Перейти к сообщению') }}
                                                                </div>
                                                            </q-item-section>
                                                        </q-item>
                                                        <q-item clickable v-close-popup @click="downloadFile(file.id)">
                                                            <q-item-section>
                                                                <div class="row items-center">
                                                                    <download-icon class="grey q-mr-sm" />
                                                                    {{ localize('Скачать') }}
                                                                </div>
                                                            </q-item-section>
                                                        </q-item>
                                                        <q-item
                                                            v-if="file.author && isCanDeleteFile(file.author)"
                                                            clickable
                                                            v-close-popup
                                                            @click="removeFile(file)"
                                                        >
                                                            <q-item-section>
                                                                <div class="row items-center">
                                                                    <trash-icon class="grey grey-path q-mr-sm" />
                                                                    {{ localize('Удалить') }}
                                                                </div>
                                                            </q-item-section>
                                                        </q-item>
                                                    </q-list>
                                                </q-menu>
                                            </q-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                        <div v-if="isLoading && files.length" class="text-center q-mt-xl">
                            <q-spinner-dots color="primary" size="4em" />
                        </div>
                    </q-scroll-area>
                </template>
                <NoItems v-else-if="!isLoading" />
            </div>

            <FileViewer ref="fileViewerRef" />
        </q-card>
    </q-dialog>
</template>

<script lang="ts">
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import {
        ChatBaseInfoResponseModel,
        ChatFileDto,
        ChatMessageClient,
        ChatType, FileClient,
        UserBaseInfoDto,
    } from 'src/api/ApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { localize } from 'src/services/LocalizationService';
    import { ContentViewerType, IContentViewerOptions } from 'components/ContentViewer/types';
    import FileViewer from 'components/ContentViewer';
    import { Common } from 'src/helpers/Common';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import { defineComponent, FunctionalComponent, getCurrentInstance, PropType, ref, SVGAttributes } from 'vue';
    import { QMenu } from 'quasar';
    import { useAccountStore } from 'src/store/module-account';

    // Компонент для отображения файлов, которые были загружены в чат
    export default defineComponent({
        name: 'ChatFiles',

        emits: [
            'go-to-message',
            'on-message-file-deleted'
        ],

        props: {
            // id текущего чата
            chatId: {
                type: Number,
                default: 0
            },
            // Мобильный вид чата
            isMobileView: {
                type: Boolean,
                default: false
            },
            // Модель базовой информации о чате
            chatInfo: {
                type: Object as PropType<ChatBaseInfoResponseModel> | undefined | null,
                required: true,
                default: undefined
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            const app = getCurrentInstance();

            const accountStore = useAccountStore();
            const isVisible = ref<boolean>(false);

            const fileViewerRef = ref<typeof FileViewer>();

            // Признак, отвечающий за загрузку данных
            const isLoading = ref<boolean>(false);
            const files = ref<ChatFileDto[]>([]);

            let canLoadFiles = true;
            let currentPage: number = 1;

            // Показ модалки с прикреплёнными файлами
            function show(): void {
                // Очищаем загруженные ранее файлы
                files.value = [];
                isVisible.value = true;
                currentPage = 1;

                loadFiles();
            }

            // Получить форматированный размер файла
            function getFileSize(size?: number | null): string {
                return size ? Common.formatBytes(size, 0) : '';
            }

            // Получить полное имя пользователя
            function getFullName(userBaseInfo: UserBaseInfoDto): string {
                return Common.getFullName(userBaseInfo);
            }

            // В чатах дисциплин (и комнатах) удалять файлы из окна “Файлы чата” должны иметь возможность все модераторы чата.
            // Обычные участники этих чатов должны иметь возможность удалять только свои файлы.
            // В обычных личных и групповых чатах удалить файл должен иметь возможность только тот, кто его отправил.
            function isCanDeleteFile(author: UserBaseInfoDto): boolean {
                if (author.id === accountStore.getAccountInfo?.id) {
                    return true;
                } else {
                    if (props.chatInfo?.type === ChatType.Discipline) {
                        return props.chatInfo.canEdit;
                    } else {
                        return false;
                    }
                }
            }

            function goToMessage(messageId: number): void {
                // вызываем событие чтобы родительский компонент его обработал
                context.emit('go-to-message', messageId);
                isVisible.value = false;
            }

            // Получить presigned ссылку для скачивания файла с ограниченным сроком действия
            async function downloadFile(fileId: number): Promise<void> {
                const result = await new FileClient(getApiClientInitialParams()).getPresignedDownloadUrl(fileId);

                if (result.isSuccess) {
                    const link = document.createElement('a');
                    link.setAttribute('href', result.entity.value);
                    link.setAttribute('download', 'download');
                    link.click();
                }
            }

            // Удалить файл в сообщении и само сообщение, если кроме файла там ни чего нет
            async function removeFile(file: ChatFileDto): Promise<void> {
                const result = await new ChatMessageClient(getApiClientInitialParams()).removeFileInMessage(file.chatMessageId!, file.id);

                if (result.isSuccess) {
                    const index = files.value.indexOf(file);

                    if (index !== -1) {
                        files.value.splice(index, 1);
                        // вызываем событие чтобы родительский компонент его обработал
                        context.emit('on-message-file-deleted', file);
                    }
                } else {
                    NotifyErrors(result);
                }
            }

            // Обработчик скрола - если почти доскролили до конца то загружаем еще страницу
            function onScroll({ verticalPercentage }: { verticalPercentage: number }): void {
                const menus = (app?.refs.menu as InstanceType<typeof QMenu>[]);

                if (menus && menus.forEach) {
                    menus.forEach((item) => item.hide());
                }

                if (verticalPercentage > 0.9 && !isLoading.value && canLoadFiles) {
                    loadFiles();
                }
            }

            // загрузка файлов с пагинацией
            async function loadFiles(): Promise<void> {
                isLoading.value = true;
                const result = await new ChatMessageClient(getApiClientInitialParams()).getFilesInChat(props.chatId, currentPage);

                if (result.isSuccess) {
                    files.value.push(...result.entity.pageItems);
                    canLoadFiles = result.entity.pagedMetaData.hasNextPage;

                    if (canLoadFiles) {
                        currentPage++;
                    }
                } else {
                    NotifyErrors(result);
                }

                isLoading.value = false;
            }

            // Окрываем компонент для просмотра файлов
            function showFileViewer(fileId: number): void {
                const fileIds = files.value.map(({ id }) => id);
                const indexFile = fileIds.indexOf(fileId);
                fileViewerRef.value?.show({
                    type: ContentViewerType.File,
                    fileId,
                    fileIds,
                    indexFile,
                } as IContentViewerOptions);
            }

            // Получаем иконку в зависимости от типа файла
            function getFileIcon(file: ChatFileDto): FunctionalComponent<SVGAttributes> {
                return Common.getFileIcon(file.contentType);
            }

            return {
                fileViewerRef,
                CustomAvatarColors,
                isVisible,
                isLoading,
                files,
                show,
                getFileSize,
                getFullName,
                isCanDeleteFile,
                goToMessage,
                downloadFile,
                removeFile,
                onScroll,
                showFileViewer,
                getFileIcon,
                localize,
            };
        }
    });

</script>

<style lang="scss" scoped>
    .confirm-modal {
        width: 576px;

        table {
            width: 100%;

            thead {
                color: $shade-8;

                td {
                    position: sticky;
                    top: 0;
                    background: #fff;
                    z-index: 1;
                }
            }

            td {
                padding: 8px;
                font-size: 12px;
                line-height: 17px;
                border-bottom: 1px solid $shade-2;
                cursor: pointer;
                word-break: break-word;

                &:first-child {
                    padding-right: 0;
                }

                &:nth-child(2), &:nth-child(3) {
                    width: 30%;
                }

                &.text-center {
                    text-align: center;
                }
            }
        }

        .file-row-item  {
            font-size: 12px;
            line-height: 17px;
            padding: 8px;
            border-bottom: 1px solid $shade-2;

            .info {
                margin: 0 8px 0 14px;
                color: $shade-9;
            }
        }
    }

    .file-icon {
        width: 12px;
        vertical-align: bottom;
    }

    .files-block {
        height: 310px;
    }

    @media (max-width: 960px) {
        .confirm-modal {
            width: 98%;
        }
    }
</style>
