<template>
    <q-header elevated class="header-component">
        <q-toolbar>
            <q-btn
                flat
                dense
                round
                icon="menu"
                class="menu-icon relative-position"
                @click="setStateLeftMenu"
                :aria-label="localize('Открыть главное меню')"
            ></q-btn>

            <router-link to="/" aria-hidden="true">
                <q-toolbar-title class="logo" aria-hidden="true">
                    <OdinIcon class="logo-icon" aria-hidden="true" />
                </q-toolbar-title>
            </router-link>

            <div
                @click="setStateChatBlock"
                v-if="isVisibleOpenChatButton"
                class="open-chat-icon"
                role="button"
                tabindex="0"
                :aria-label="localize('Открыть чат')"
            >
                <ChatMessageIcon aria-hidden="true" />
                <div class="count-notifications" v-if="countUnreadedMessages"></div>
            </div>
            <div v-else>
                <!--
                 Пустой блок здесь для того чтобы работал justify-content: space-between;
                 иначе лого уедет в правую часть
                 -->
            </div>
        </q-toolbar>
    </q-header>
</template>

<script lang='ts'>
    import { computed, defineComponent } from 'vue';
    import { useRoute } from 'vue-router';
    import { useMainLayoutStore } from 'src/store/module-main-layout';
    import { useChatStore } from 'src/store/module-chat';
    import { localize } from 'src/services/LocalizationService';

    export default defineComponent({
        name: 'Header',

        setup() {
            const $route = useRoute();
            const chatStore = useChatStore();
            const mainLayoutStore = useMainLayoutStore();

            // Не показывает кнопки чатов на странице чатов и решений
            // Потому что там своим чаты
            const isVisibleOpenChatButton = computed<boolean>((): boolean => {
                return ('isVisibleChatBlock' in $route.meta) ? !!$route.meta.isVisibleChatBlock : true;
            });

            // Количество непрочитанных сообщений по всем чатам
            const countUnreadedMessages = computed<number>(() => {
                return chatStore.getAllUreadedMessagesCount;
            });

            // Видимость левого меню
            function setStateLeftMenu(): void {
                const newIsVisible = !mainLayoutStore.isVisibleLeftMenu;
                mainLayoutStore.isVisibleLeftMenu = newIsVisible;

                if (newIsVisible) { // Если показываем левое меню, то прячем миничат
                    mainLayoutStore.isVisibleChatBlock = !newIsVisible;
                }
            }

            // Видимость блока с чатом
            function setStateChatBlock(): void {
                const newIsVisible = !mainLayoutStore.isVisibleChatBlock;
                mainLayoutStore.isVisibleChatBlock = newIsVisible;

                if (newIsVisible) { // Если показываем миничат, то прячем левое меню
                    mainLayoutStore.isVisibleLeftMenu = !newIsVisible;
                }
            }

            return {
                isVisibleOpenChatButton,
                countUnreadedMessages,
                localize,
                setStateLeftMenu,
                setStateChatBlock,
            };
        }

    });
</script>

<style scoped lang="scss" src="./style.scss" />
