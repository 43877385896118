﻿<template>
    <div></div>
</template>

<script lang="ts">
    import { Common } from 'src/helpers/Common';
    import { RoutePageNameEnum } from 'src/api/ApiClient';
    import { defineComponent, getCurrentInstance, onBeforeMount } from 'vue';
    import { useRouter } from 'vue-router';
    import { useAccountStore } from 'src/store/module-account';
    import { useAuthorizationStore } from 'src/store/module-authorization';
    import { useMainLayoutStore } from 'src/store/module-main-layout';
    import { useChatStore } from 'src/store/module-chat';

    // Страница выхода из приложения
    export default defineComponent({
        name: 'LogOut',

        setup() {
            const app = getCurrentInstance();
            const $router = useRouter();
            const chatStore = useChatStore();
            const mainLayoutStore = useMainLayoutStore();
            const accountStore = useAccountStore();

            onBeforeMount(async () => {
                // При выходе из приложения трем данные в сторе и удаляем действующий fcm токен
                await app?.appContext.config.globalProperties.$fcmService?.removeToken();
                useAuthorizationStore().logout();
                accountStore.accountInfo = null;
                chatStore.unreadedMessagesCount = [];
                mainLayoutStore.isShowBackBreadcrumbsButton = false;
                mainLayoutStore.breadcrumbs = [];

                $router.push({
                    name: Common.getRouteName(RoutePageNameEnum.Login)
                }).then(() => {
                    // Удаляем черновики пользователя ODIN-9662
                    chatStore.drafts = null;
                });
            });
        }
    });
</script>
