<template>
    <q-dialog
        v-model="isVisible"
        :persistent="false"
    >
        <q-card class="confirm-modal members-modal">
            <div class="confirm-modal__title row justify-between">
                {{ localize('Участники звонка') }}
                <q-icon
                    name="close"
                    size="20px"
                    v-close-popup
                    class="cursor-pointer"
                />
            </div>

            <div class="members-block">
                <q-scroll-area style="height: 100%;">
                    <div
                        v-for="member in callMembers"
                        class="member-item"
                        :key="member.id"
                    >
                        <div class="row items-center" aria-hidden="true">
                            <q-avatar size="24px">
                                <img
                                    v-if="member.photoUrl"
                                    :src="member.photoUrl"
                                    alt=""
                                />
                                <custom-avatar
                                    v-else
                                    :text="member.lastName[0] + member.firstName[0]"
                                    :color="CustomAvatarColors.User"
                                />
                            </q-avatar>

                            <div class="col q-ml-sm">
                                <router-link :to="getUserPageUrl(member.id)" :target="isCallPage ? '_blank' : ''">
                                    {{ member.lastName }} {{ member.firstName }}
                                </router-link>

                                <div
                                    v-if="getRoleIconName(member.role)"
                                    class="flex no-wrap"
                                >
                                    <q-icon
                                        :name="getRoleIconName(member.role)"
                                        size="14px"
                                        class="q-mr-xs"
                                    />
                                    <span class="text-description">
                                        {{ getRoleTitle(member.role) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </q-scroll-area>
            </div>
        </q-card>
    </q-dialog>
</template>

<script lang="ts">
    /* eslint comment-density/comment-density: 0 */
    import { ChatMembersDto, RoutePageNameEnum } from 'src/api/ApiClient';
    import { computed, defineComponent, PropType } from 'vue';
    import useChatMembers from 'components/Chat/hooks/useChatMembers';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import { localize } from 'src/services/LocalizationService';
    import { Common } from 'src/helpers/Common';
    import { useRoute } from 'vue-router';

    // Компонент для отображения
    // пользователей звонка
    export default defineComponent({
        name: 'CallChatMembers',

        props: {
            // Список пользователей для отображения
            callMembers: {
                required: true,
                type: Array as PropType<ChatMembersDto[]>,
                default: () => []
            }
        },

        setup() {
            const $route = useRoute();

            // Подключаем фукнционал компонента участников чата
            const {
                isVisible,
                getRoleIconName,
                getRoleTitle,
                getUserPageUrl,
            } = useChatMembers();

            // Находимся ли мы на странице звонка
            const isCallPage = computed<boolean>(() => {
                return $route.name === Common.getRouteName(RoutePageNameEnum.CallEnter);
            });

            return {
                CustomAvatarColors,
                isVisible,
                isCallPage,
                getRoleIconName,
                getRoleTitle,
                getUserPageUrl,
                localize,
            };
        }

    });
</script>

<style lang="scss" scoped>
    ::v-deep(.q-scrollarea) {
        .scroll {
            .absolute {
                display: flex;
                flex-wrap: wrap;
                min-height: 1px;

                @media (max-width: 500px) {
                    flex-direction: column;
                }
            }
        }
    }

    .members-modal {
        max-width: 464px;
        min-height: 346px;
        width: 100%;
        padding: 14px;

        @media (max-height: 500px) {
            min-height: auto;
        }

        .member-item {
            display: inline-block;
            width: 50%;
            height: 48px;

            @media (max-width: 500px) {
                width: 100%;
            }
        }
    }

    .members-block {
        height: 261px;
        margin-top: 20px;
    }

    @media (max-width: 960px) {
        .members-modal {
            width: 98%;
        }
    }
</style>
