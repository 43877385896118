import { MainLayoutStoreInterface } from './state';
import { EntityBaseInfoDto } from 'src/api/ApiClient';

const actions = {
    setExternalReferenceCheckerVisibleSettings(this: MainLayoutStoreInterface, data: { userId: string, isVisible: boolean }) {
        if (!this.externalReferenceCheckerVisibleSettings) {
            this.externalReferenceCheckerVisibleSettings = {};
        }

        this.externalReferenceCheckerVisibleSettings[data.userId] = data.isVisible;
    },
    // закрепление дисциплины в левом меню
    addPinnedDiscipline(this: MainLayoutStoreInterface, pinnedDiscipline: EntityBaseInfoDto) {
        const index = this.pinnedDisciplines.findIndex((x: EntityBaseInfoDto) => x.id === pinnedDiscipline.id);

        if (index === -1) {
            this.pinnedDisciplines.push(pinnedDiscipline);
        }
    },
    // открепить дисциплину из левом меню
    removePinnedDiscipline(this: MainLayoutStoreInterface, id: number) {
        const index = this.pinnedDisciplines.findIndex((x: EntityBaseInfoDto) => x.id === id);

        if (index !== -1) {
            this.pinnedDisciplines.splice(index, 1);
        }
    },

    // закрепить подразделеоение в боковом меню
    addPinnedDivision(this: MainLayoutStoreInterface, pinnedDivision: EntityBaseInfoDto) {
        const index = this.pinnedDivisions
            .findIndex((division: EntityBaseInfoDto) => division.id === pinnedDivision.id);

        if (index === -1) {
            this.pinnedDivisions.push(pinnedDivision);
        }
    },

    // открепить подразделение из бокового меню
    removePinnedDivision(this: MainLayoutStoreInterface, id: number) {
        const index = this.pinnedDivisions.findIndex((division: EntityBaseInfoDto) => division.id === id);

        if (index !== -1) {
            this.pinnedDivisions.splice(index, 1);
        }
    },

    addPinnedProgram(this: MainLayoutStoreInterface, pinnedProgram: EntityBaseInfoDto) {
        const index = this.pinnedPrograms.findIndex((program: EntityBaseInfoDto) => program.id === pinnedProgram.id);

        if (index === -1) {
            this.pinnedPrograms.push(pinnedProgram);
        }
    },

    removePinnedProgram(this: MainLayoutStoreInterface, id: number) {
        const index = this.pinnedPrograms.findIndex((program: EntityBaseInfoDto) => program.id === id);

        if (index !== -1) {
            this.pinnedPrograms.splice(index, 1);
        }
    },

    addPinnedCohort(this: MainLayoutStoreInterface, pinnedCohort: EntityBaseInfoDto) {
        const index = this.pinnedCohorts.findIndex((cohort: EntityBaseInfoDto) => cohort.id === pinnedCohort.id);

        if (index === -1) {
            this.pinnedCohorts.push(pinnedCohort);
        }
    },

    removePinnedCohort(this: MainLayoutStoreInterface, id: number) {
        const index = this.pinnedCohorts.findIndex((cohort: EntityBaseInfoDto) => cohort.id === id);

        if (index !== -1) {
            this.pinnedCohorts.splice(index, 1);
        }
    }
};

export default actions;
