import { computed, ComputedRef, ref, Ref, watch } from 'vue';
import { SearchTabs } from '../types/searchTabs';
import { localize } from 'src/services/LocalizationService/LocalizationService';

interface SearchTabEmits {
    (e: 'on-load', count: number): void,
    (e: 'on-item-selected'): void,
    (e: 'show-all', tab: SearchTabs): void,
}

// Хук с общим для табов поиска функционалом
export function useSearchTab(emit: SearchTabEmits, searchText: Ref<string>): {
    totalItemsCount: Ref<number | null>;
    isNoItems: ComputedRef<boolean>;
    onFoundItemsCount: (itemsCount: number) => void;
    onItemSelected: () => void;
    showAll: (tabName: SearchTabs) => void;
    localize: (text: string) => string;
} {
    const totalItemsCount = ref<number | null>(null);
    const isNoItems = computed(() => {
        return totalItemsCount.value === 0;
    });

    watch(() => searchText.value, () => {
        totalItemsCount.value = null;
    });

    function onFoundItemsCount(itemsCount: number): void {
        totalItemsCount.value = itemsCount;
        // вызываем событие чтобы родительский компонент его обработал
        emit('on-load', itemsCount);
    }

    function onItemSelected(): void {
        // вызываем событие чтобы родительский компонент его обработал
        emit('on-item-selected');
    }

    function showAll(tabName: SearchTabs): void {
        // вызываем событие чтобы родительский компонент его обработал
        emit('show-all', tabName);
    }

    return {
        totalItemsCount,
        isNoItems,
        onFoundItemsCount,
        onItemSelected,
        showAll,
        localize
    };
}
