import { NotificationsStoreInterface } from 'src/store/module-notifications/state';
import { getApiClientInitialParams } from 'src/api/BaseApiClient';
import { NotificationClient } from 'src/api/ApiClient';
import { ActionContext } from 'src/store/type';
import { ICallStartDto } from 'src/types/generated/hubs/callPartialHub/models/ICallStartDto';

const actions = {
    async getUnreadedNotificationsCount(this: ActionContext<'notificationsStore', NotificationsStoreInterface>) {
        // получаем количество непрочитанныех оповещений
        const result = await new NotificationClient(getApiClientInitialParams()).getUnreadedNotificationsCount();

        if (result.isSuccess) {
            this.$patch({
                countNotifications: result.entity || 0
            });
        } else {
            this.$patch({
                countNotifications: 0
            });
        }
    },
    setCountNotifications(this: NotificationsStoreInterface, count: number) {
        this.countNotifications = count > 0 ? count : 0;
    },
    // добавляем оповещений о звонке в список оповещений
    // тк оповещений может прийти несколько раз проверяем наличие по callId
    addCallNotification(this: NotificationsStoreInterface, payload: ICallStartDto) {
        const index = this.callNotifications.findIndex((x: ICallStartDto) => x.callId === payload.callId);

        if (index === -1) {
            this.callNotifications.push(payload);
        }
    },
    removeCallNotification(this: NotificationsStoreInterface, callId: number) {
        const index = this.callNotifications.findIndex((x: ICallStartDto) => x.callId === callId);

        if (index !== -1) {
            this.callNotifications.splice(index, 1);
        }
    },
    clearCallNotifications(this: NotificationsStoreInterface) {
        this.callNotifications = [];
    }
};

export default actions;
