<template>
    <ui-select
        ref="selectRef"
        dense
        options-dense
        v-model="inputValue"
        :options="items"
        :option-value="optionValue"
        :option-label="optionLabel"
        :is-server-filter="true"
        map-options
        :multiple="multiple"
        :label="label"
        :loading="isLoadingItems"
        :rules="rules"
        :is-compact-style="isCompactStyle"
        @virtual-scroll="onScrollItems"
        @filter="filterItems"
        @clear="onChange"
        @update:model-value="$emit('update:model-value', $event)"
        @on-change="$emit('on-change', $event)"
        :error="error"
        :error-message="errorMessage"
        :disable="disable"
        :readonly="!!isReadOnly"
        :hide-bottom-space="!isRequired"
        popup-content-class="select"
    >
        <template v-if="$slots['option']" v-slot:option="scope">
            <slot name="option" v-bind="scope" />
        </template>
    </ui-select>
</template>

<script lang="ts">
    import { defineComponent, SetupContext } from 'vue';
    import useDataSelect, { componentProps } from './useDataSelect';

    /**
     * Дропдаун для выбора сущностей
     * Для загрузки данные нужно передавать loadAction
     */
    export default defineComponent({
        name: 'DataSelect',

        emits: [
            'update:model-value',
            'on-change'
        ],

        props: {
            // eslint-disable-next-line vue/no-unused-properties
            modelValue: componentProps.value,
            label: componentProps.label,
            multiple: componentProps.multiple,
            rules: componentProps.rules,
            error: componentProps.error,
            errorMessage: componentProps.errorMessage,
            disable: componentProps.disable,
            isReadOnly: componentProps.isReadOnly,
            isRequired: componentProps.isRequired,
            // eslint-disable-next-line vue/no-unused-properties
            loadAction: componentProps.loadAction,
            // eslint-disable-next-line vue/no-unused-properties
            isNeedInit: componentProps.isNeedInit,
            optionValue: componentProps.optionValue,
            optionLabel: componentProps.optionLabel,
            isCompactStyle: componentProps.isCompactStyle,
        },

        setup(props, context) {
            // Импортируем необходимые переменные и функции
            const {
                selectRef,
                inputValue,
                searchItemFilter,
                currentPage,
                canLoadItems,
                isLoadingItems,
                items,
                hasError,
                validate,
                checkPagination,
                onChange,
                resetFilter,
                filterItems,
                onScrollItems,
            } = useDataSelect(props, context as SetupContext);

            return {
                selectRef,
                inputValue,
                searchItemFilter,
                currentPage,
                canLoadItems,
                isLoadingItems,
                items,
                hasError,
                validate,
                checkPagination,
                onChange,
                resetFilter,
                filterItems,
                onScrollItems,
            };
        }
    });
</script>
