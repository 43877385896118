import getters from './getters';
import state from './state';
import actions from './actions';
import { defineStore } from 'pinia';

// store для страницы звонка

export const useCallStore = defineStore('callStore', {
    state,
    getters,
    actions,
    persist: {
        storage: window.localStorage,
        paths: [
            'selectedVideoInputDevice',
            'selectedAudioInputDevice',
            'selectedAudioOutputDevice',
            'selectedBackgroundOption',
        ]
    }
});
