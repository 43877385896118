﻿// Настройки Firebase для push-уведомлений
export default class FirebaseClientConfig {
    public static Config: any = {
        apiKey: 'AIzaSyDNCRexNkxvC5OS-JyNzGjfsv8hhcwtrpg',
        authDomain: 'odin-b3d79.firebaseapp.com',
        databaseURL: 'https://odin-b3d79.firebaseio.com',
        projectId: 'odin-b3d79',
        storageBucket: 'odin-b3d79.appspot.com',
        messagingSenderId: '499306430915',
        appId: '1:499306430915:web:ed357cc9117e8cd4643d76',
    };
}
