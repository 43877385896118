﻿<template>
    <router-link :to="getPageUrl(page.id)">
        <q-avatar size="24px" class="rounded-borders" aria-hidden="true">
            <img v-if="page.photoUrl" :src="page.photoUrl" />

            <custom-avatar v-else :text="avatarText" :color="avatarColor" />
        </q-avatar>

        <div class="menu-title">
            <span class="menu-title__name">
                {{ page.name }}
            </span>
        </div>
    </router-link>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import { EntityBaseInfoDto } from 'src/api/ApiClient';
    import { useRouter } from 'vue-router';


    export default defineComponent({
        name: 'PinnedPage',
        props: {
            // Модель для представления базовой
            // информации о какой-либо сущности
            page: {
                type: Object as PropType<EntityBaseInfoDto>,
                required: true,
            },
            routeName: {
                type: String,
                required: true,
            },
            avatarColor: {
                type: String as PropType<CustomAvatarColors>,
                required: true,
            },
            avatarText: {
                type: String,
                required: true,
            },
        },
        /* eslint-disable-next-line max-lines-per-function  */
        setup(props) {
            const $router = useRouter();

            // URL для перехода на страницу сущности
            function getPageUrl(id: number): string {
                const route = $router.resolve({
                    name: props.routeName,
                    params: { id: id.toString() }
                });

                return route.path;
            }

            return  {
                getPageUrl,
            };
        }
    });
</script>
