﻿<template>
    <div />
</template>

<script lang="ts">
    import { defineComponent, onBeforeMount } from 'vue';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { chatBus } from 'components/EventBuses';
    import { useRoute } from 'vue-router';
    import { useAuthorizationStore } from 'src/store/module-authorization';
    import { useChatStore } from 'src/store/module-chat';
    import { ChatBusEvents } from 'components/EventBuses/emuns';

    // Компонент для входа под другим пользователем
    export default defineComponent({
        name: 'LoginAs',

        setup() {
            const $route = useRoute();
            const chatStore = useChatStore();

            async function loginAs(id: number): Promise<void> {
                // Войти под пользователем по userId
                const result = await useAuthorizationStore().loginAs(id);

                if (result.isSuccess) {
                    location.href = '/';
                } else {
                    NotifyErrors(result);
                }
            }

            onBeforeMount(() => {
                chatBus.emit(ChatBusEvents.NotSavedDraftOnUnload);
                // Удаляем черновики предыдущего пользователя ODIN-9662
                chatStore.drafts = null;
                const userId = Number($route.params.id);
                loginAs(userId);
            });
        }
    });
</script>
