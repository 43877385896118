<template>
    <q-scroll-area class="scroll-area">
        <q-infinite-scroll @load="onLoad" :offset="180" ref="infinityScrollRef">
            <search-people-item
                v-for="user in users"
                :key="user.baseInfo.id"
                :item="user"
                @on-item-selected="onItemSelected">
                <template #append >
                    <router-link v-if="user.canLoginAs" :to="getLoginAsLink(user.baseInfo.id)">
                        <SubdivisionIcon @click="onItemSelected"/>
                    </router-link>
                </template>
            </search-people-item>

            <template v-slot:loading>
                <div class="text-center">
                    <q-spinner-dots color="primary" size="4em"/>
                </div>
            </template>
        </q-infinite-scroll>
    </q-scroll-area>
</template>

<script lang="ts" setup>
    import { Ref, ref, toRefs } from 'vue';
    import { SearchUserDto, RoutePageNameEnum } from 'src/api/ApiClient';
    import { QInfiniteScroll } from 'quasar';
    import SearchPeopleItem from 'layouts/Main/components/searchBlock/components/peopleTabs/SearchPeopleItem.vue';
    import { Common } from 'src/helpers/Common';
    import { useRouter } from 'vue-router';
    import { SearchTabs } from '../../types/searchTabs';
    import { useSearch } from '../../hooks/useSearch';
    import { SearchEntity } from '../../types/SearchEntity';

    // Компонент с бесконечной загрузкой
    // Отображается когда выбран поиск только по пользователям

    const props = withDefaults(defineProps<{
        searchText: string;
    }>(), {
        searchText: '',
    });
    const emit = defineEmits<{
        (e: 'on-item-selected'): void;
        (e: 'on-found-items-count', itemsCount: number): void;
        (e: 'show-all', tab: SearchTabs): void;
    }>();

    const { searchText } = toRefs(props);

    const $router = useRouter();
    const infinityScrollRef = ref<InstanceType<typeof QInfiniteScroll>>();

    const users: Ref<SearchUserDto[]> = ref([]);
    const {
        onLoad,
        onItemSelected,
    } = useSearch(SearchEntity.People, true, users, searchText, emit, infinityScrollRef);

    // Перенаправляем на страницу на которой будет осуществлен
    // вход в приложение под выбранным пользователем
    function getLoginAsLink(userId: number): string {
        const link = $router.resolve({
            name: Common.getRouteName(RoutePageNameEnum.LoginAs),
            params: { id: userId.toString() }
        });
        return link.path;
    }
</script>
