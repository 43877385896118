<template>
    <q-card-section class="big-height flex items-start">
        <slot name="avatar">
            <q-avatar v-if="hasAvatar" size="24px" class="rounded-borders" aria-hidden="true">
                <img v-if="pictureUrl" :src="pictureUrl" alt="" />
                <custom-avatar
                    v-else
                    :text="avatarText"
                    :color="avatarColor"
                />
            </q-avatar>
        </slot>
        <div class="col">
            <div class="title">
                <router-link :to="itemPageUrl">
                    <span @click="onItemSelected">{{ name }}</span>
                </router-link>
                <slot name="afterlink"></slot>
            </div>
            <slot name="info"></slot>
        </div>
        <slot name="append" />
    </q-card-section>
</template>

<script lang="ts" setup>
    // Компонент для отображения данных о найденном подразделении
    import {
        EntityBaseInfoDto,
        getApiClientInitialParams,
        SearchClient,
        SearchTermDto,
        SearchType,
        SearchUserDto,
    } from 'src/api/ApiClient';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import { computed } from 'vue';

    const emit = defineEmits<{
        (e: 'on-item-selected'): void;
    }>();

    const props = defineProps<{
        // Ссылка на изображение отображающееся как аватарка элемента
        pictureUrl?: string | null;
        // Текст, отображающийся при отсутствии аватарки, например 'П', для подразделений
        avatarText?: string;
        // Цвет аватарки, при отсутствии изображения
        avatarColor?: CustomAvatarColors;
        // Ссылка на страницу сущности представленной элементом
        itemPageUrl: string;
        // Наименование элемента
        name: string;
        // Данные элемента
        item: EntityBaseInfoDto | SearchUserDto;
        // Тип сущности для поиска
        type: SearchType;
    }>();

    const hasAvatar = computed(() => {
        return props.pictureUrl || props.avatarColor && props.avatarText;
    });

    function onItemSelected(): void {
        const searchTerm: SearchTermDto = {
            type: props.type,
            body: JSON.stringify(props.item),
        };
        new SearchClient(getApiClientInitialParams()).saveSearchTerm(searchTerm);

        // вызываем событие чтобы родительский компонент его обработал
        emit('on-item-selected');
    }


</script>

<style scoped>
    .rounded-borders {
        border-radius: 4px;
    }
</style>
