import { ChatMessageDto, MessageFile, ProgrammingLanguages } from 'src/api/ApiClient';
import { IFile } from 'components/Dropzone/interfaces';

export interface IChatMessage extends ChatMessageDto {
    isMyMessage: boolean;
    isSending?: boolean;
    error?: string;
    filesForDropzone?: IFile[];
    messageFiles?: MessageFile[] | null;
}

// Типы ссылок в тексте сообщения
export enum TypeTextEnum {
    Link,
    Email,
    Text,
    LinkWithText,
    Code,
}

export interface IMessagePart {
    id?: number;
    text: string;
    link?: string;
    typeText?: TypeTextEnum;
    textItems?: IMessagePart[];
    codeLang?: ProgrammingLanguages;
}

export interface IDraftInfo {
    [key: number]: IDraftMessage;
}

// Модель черновика сообщения
export interface IDraftMessage {
    time: number;
    message: IChatMessage;
}
