<template>
    <div>
        <template v-for="room in chatRooms" :key="'room-separator' + (room.chatId || room.activityId)">
            <div @click="selectRoom(room, $event)">
                <q-item clickable>
                    <q-item-section class="q-mx-sm relative-position">
                        <div class="row items-center">
                            <CountUnreadedMessages
                                :chat-id="room.chatId"
                                :activity-id="room.activityId"
                                :is-muted="room.isMuted"
                                mode="mini"
                                class="count-unreaded-messages"
                            />
                            <div class="col q-mr-sm">
                                <div class="ellipsis-3-lines">{{ room.name }}</div>
                                <div class="sub-item text-shade-8">
                                    <div v-if="room.type === ChatType.Solution" :aria-label="localize('Чат с бсуждением решения')">
                                        {{ localize('Обсуждение решения') }}
                                    </div>
                                    <div
                                        v-else-if="room.countMembers"
                                        class="inline-block"
                                        @click.stop="showMembers(room)"
                                    >
                                        {{ room.countMembers }}
                                        {{ getDeclinationOfMembers(room.countMembers) }}
                                    </div>
                                </div>
                            </div>
                            <q-icon
                                v-if="room.type === ChatType.Solution"
                                :name="room.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'" />
                        </div>
                    </q-item-section>
                </q-item>
                <div v-if="room.isOpen">
                    <div
                        v-for="(solutionChat, index) in room.solutionChats"
                        :key="'solutionChat' + (solutionChat.chatId || index)"
                    >
                        <q-item
                            @click="selectSolutionChat(solutionChat, room)"
                            v-close-popup
                            clickable
                        >
                            <q-item-section>
                                <div class="row items-center">
                                    <q-avatar size="24px" aria-hidden="true">
                                        <img
                                            v-if="solutionChat.logoPath"
                                            :src="solutionChat.logoPath"
                                            alt=""
                                        />
                                        <UserNoPhotoIcon v-else />
                                    </q-avatar>
                                    <div class="col flex items-center q-ml-sm">
                                        {{ solutionChat.name }}
                                        <CountUnreadedMessages
                                            :chat-id="solutionChat.chatId"
                                            :is-muted="room.isMuted"
                                            mode="mini"
                                            style="position: static;"
                                            class="count-unreaded-messages q-ml-sm"
                                        />
                                    </div>
                                </div>
                            </q-item-section>
                        </q-item>
                        <q-separator v-if=" room.solutionChats && index !== room.solutionChats.length - 1" />
                    </div>
                    <q-item v-if="!room.isLoading && room.canLoadSolutionChats" class="text-center">
                        <q-item-section @click.stop="loadSolutionChats(room)" class="link">
                            {{ localize('Загрузить ещё') }}
                        </q-item-section>
                    </q-item>
                    <div v-if="room.isLoading" class="text-center q-my-sm">
                        <q-spinner-dots color="primary" size="2em" />
                    </div>
                </div>
            </div>
            <q-separator />
        </template>

        <chat-members ref="chatMembersRef" :chat-id="chatId" />
    </div>
</template>

<script lang="ts">
    import { IChatRoom } from 'pages/Main/Chat/types/interfaces';
    import { ChatClient, ChatType, ListChatDto } from 'src/api/ApiClient';
    import CountUnreadedMessages from 'components/CountUnreadedMessages/CountUnreadedMessages.vue';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { localize } from 'src/services/LocalizationService';
    import ChatMembers from 'components/Chat/components/ChatMembers.vue';
    import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
    import { chatBus } from 'components/EventBuses';
    import { useChatStore } from 'src/store/module-chat';
    import { ChatBusEvents } from 'components/EventBuses/emuns';

    // Компонент для отображения списка чатов комнат для дисциплины
    // и списка активностей для отображения чатов решений
    export default defineComponent({
        name: 'ListRooms',

        components: {
            ChatMembers,
            CountUnreadedMessages,
        },

        emits: [
            'on-select-room',
        ],

        props: {
            // id текущего чата
            chatId: {
                type: Number,
                default: 0,
            },
            // нужно ли делайть запрос на получение комнат
            // или нам их передадут через пропс roomItems
            isNeedRoomsRequest: {
                type: Boolean,
                default: true,
            },
            // Мобильный вид чата
            roomItems: {
                type: Array as PropType<IChatRoom[]>,
                default: () => [],
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            const chatStore = useChatStore();
            const rooms = ref<IChatRoom[]>([]);
            const isLoadingRooms = ref<boolean>(true);

            const chatMembersRef = ref<typeof ChatMembers>();

            const chatRooms = computed<IChatRoom[]>(() => {
                if (!props.isNeedRoomsRequest) {
                    // проставляем флаг, что комнаты загружены
                    isLoadingRooms.value = false;
                    return props.roomItems;
                }

                return rooms.value;
            });

            function showMembers({ chatId }: IChatRoom): void {
                if (!chatId) {
                    return;
                }

                chatMembersRef.value?.show(chatId);
            }

            function selectRoom(chat: IChatRoom, event?: MouseEvent): void {
                if (chat.type === ChatType.Solution) {
                    event?.stopPropagation();
                    chat.isOpen = !chat.isOpen;

                    if (chat.isOpen && chat.currentSolutionsChatsPage === 0) {
                        loadSolutionChats(chat);
                    }
                } else {
                    context.emit('on-select-room', chat);
                }
            }

            function selectSolutionChat(chat: ListChatDto, room: ListChatDto): void {
                // Посылаем событие, что бы компонент BottomPanel
                // сохранил черновик чата при выборе другого чата из выпадающего списка
                chatBus.emit(ChatBusEvents.SaveDraftMessage);
                context.emit('on-select-room', chat);
                // Сохраняем в стор информацию о выбранном чате и активности
                chatStore.selectedSolutionChat = chat;
                chatStore.selectedSolution = { ...room };
            }

            // Получить чаты решений для активности
            async function loadSolutionChats(chat: IChatRoom): Promise<void> {
                if (!chat.solutionChats) {
                    chat.solutionChats = [];
                }

                chat.isLoading = true;

                if (!chat.currentSolutionsChatsPage) {
                    chat.currentSolutionsChatsPage = 1;
                }

                const result = await new ChatClient(getApiClientInitialParams()).getSolutionChats(chat.activityId!, chat.currentSolutionsChatsPage);

                if (result.isSuccess) {
                    chat.solutionChats.push(...result.entity.pageItems);
                    chat.canLoadSolutionChats = result.entity.pagedMetaData.hasNextPage;

                    if (chat.canLoadSolutionChats) {
                        chat.currentSolutionsChatsPage++;
                    }
                } else {
                    NotifyErrors(result);
                }

                chat.isLoading = false;
            }

            // Получить вложенные комнаты дисциплины (за исключением комнаты по умолчанию)
            async function loadRooms(): Promise<void> {
                if (!props.chatId) {
                    return;
                }

                rooms.value = [];
                isLoadingRooms.value = true;

                const result = await new ChatClient(getApiClientInitialParams()).getChatRooms(props.chatId, false);

                if (result.isSuccess) {
                    rooms.value = (result.entity.chats || []).map((x: ListChatDto) => {
                        return {
                            ...x,
                            disciplineChatId: props.chatId,
                            isOpen: false,
                            isLoading: false,
                            currentSolutionsChatsPage: 0,
                            solutionChats: [],
                        };
                    });
                } else {
                    NotifyErrors(result);
                }

                isLoadingRooms.value = false;
            }

            function getDeclinationOfMembers(count: number): string {
                return count.cased('участник', 'участника', 'участников', false);
            }

            onMounted(() => {
                // Загружаем чаты комнаты, если нам их не передали через пропсы
                if (props.isNeedRoomsRequest) {
                    loadRooms();
                }
            });

            return {
                chatMembersRef,
                ChatType,
                isLoadingRooms,
                chatRooms,
                showMembers,
                selectRoom,
                selectSolutionChat,
                loadSolutionChats,
                localize,
                getDeclinationOfMembers,
            };
        },

    });
</script>
