import state from './state';
import actions from './actions';
import getters from './getters';
import { defineStore } from 'pinia';

// store для компонента чата

export const useChatStore = defineStore('chatStore', {
    state,
    getters,
    actions,
    persist: {
        storage: window.localStorage,
        paths: [
            'drafts',
            'isSortByAlphabetInDiscussionChats',
            'isSortByAlphabetInGroupChats',
            'isSortByAlphabetInDisciplineChats',
            'isSortByAlphabetInPrivateChats',
            'disciplineChatsIdsForAlphabetSorting',
        ]
    }
});
