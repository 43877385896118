import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
import {
    ChatBaseInfoResponseModel,
    ChatsInfoListItem,
    ChatType,
    ListChatDto,
    SearchListChatDto,
} from 'src/api/ApiClient';
import { ISearchListChat } from 'pages/Main/Chat/types/interfaces';
import { groupBy, uniqBy } from 'lodash';

/**
 * Получение подписи для аватара чата
 * @param item данные чата
 * @returns Подпись для аватара
 */
function getCustomAvatarText(item: ListChatDto): string {
    if (item) {
        const { Private, Group, Self, Discipline, Solution } = ChatType;

        switch (item.type) {
            case Private:
                return item.name;
            case Group:
                return 'Гч';
            case Self:
                return 'Лч';
            case Discipline:
                return 'Д';
            case Solution:
                return 'Р';
        }
    }

    return '';
};

/**
 * Получение цвета кастомного аватара для чата
 * @param item данные чата
 * @returns цвет аватара
 */
function getCustomAvatarColor(item: ListChatDto): CustomAvatarColors | string {
    if (item) {
        const { Private, Group, Self, Discipline } = ChatType;

        switch (item.type) {
            case Private:
                return CustomAvatarColors.User;
            case Group:
            case Self:
                return CustomAvatarColors.GroupChat;
            case Discipline:
                return CustomAvatarColors.Discipline;
        }
    }

    return '';
}

/**
 * Конвертирование модели чата
 * @param chatInfo модель ChatBaseInfoResponseModel
 * @returns модель ListChatDto
 */
function convertChatBaseInfoToListChatDto(chatInfo: ChatBaseInfoResponseModel): ListChatDto {
    return {
        chatId: chatInfo.id,
        name: chatInfo.nameChat,
        type: chatInfo.type,
        logoPath: chatInfo.chatAvatarPath,
        isClosedRoom: chatInfo.isClosedRoom,
        countMembers: chatInfo.countMembers,
        isDisabled: chatInfo.isDisabled,
        activityId: chatInfo.activityId,
        disciplineId: chatInfo.disciplineId,
        userId: chatInfo.userId,
        parentChatId: chatInfo.parentMessage?.chatId,
        isMutedDiscipline: false,
        isMuted: chatInfo.isMuted
    };
}

/**
 * Конвертирование модели чата
 * @param chatInfo модель ChatBaseInfoResponseModel
 * @returns модель ListChatDto
 */
function convertChatBaseInfoToInfoListItem(chatInfo: ChatBaseInfoResponseModel): ChatsInfoListItem {
    return {
        chatId: chatInfo.id,
        name: chatInfo.nameChat,
        type: chatInfo.type,
        logoPath: chatInfo.chatAvatarPath,
        disciplineId: chatInfo.disciplineId,
        isMuted: chatInfo.isMuted,
        lastMessageDateTime: null,
        isDisabled: chatInfo.isDisabled,
        pinnedAt: chatInfo.pinnedAt
    };
}

function convertListChatDtoToInfoListItem(chatDto: ListChatDto): ChatsInfoListItem {
    return {
        chatId: chatDto.chatId,
        name: chatDto.name,
        type: chatDto.type,
        logoPath: chatDto.logoPath,
        disciplineId: chatDto.disciplineId,
        isMuted: chatDto.isMuted,
        lastMessageDateTime: chatDto.lastMessageDateTime,
        isDisabled: chatDto.isDisabled,
        pinnedAt: null,
    };
}

/**
 * Функция группирует чаты по disciplineId тк чаты решений и комнаты
 * должны отображаться как вложенные у дисциплины
 * @param items список найденных чатов
 * @returns список сгруппированных чатов
 */
function prepareSearchedChats(items: SearchListChatDto[]): ISearchListChat[] {
    const allChats: ISearchListChat[] = [];
    const disciplineChats = items.filter((x: SearchListChatDto) => {
        return x.type === ChatType.Discipline || x.type === ChatType.Solution;
    });

    const groupedChats = groupBy(disciplineChats, 'disciplineId');

    for (const key in groupedChats) {
        const chatItems: SearchListChatDto[] = groupedChats[key].filter((x: SearchListChatDto) => {
            return x.type !== ChatType.Solution;
        });
        let solutionChatItems = groupedChats[key].filter((x: SearchListChatDto) => {
            return x.type === ChatType.Solution;
        });
        // Убираем дубли чатов решений
        solutionChatItems = uniqBy(solutionChatItems, 'activityId');
        // Данные о дисциплне берем у первого элемента, тк они у всех одинаковые
        const firstItem = chatItems.length ? chatItems[0] : solutionChatItems[0];

        allChats.push({
            chatId: 0,
            disciplineId: firstItem.disciplineId,
            disciplineName: firstItem.disciplineName,
            disciplineLogoPath: firstItem.disciplineLogoPath,
            name: firstItem.name,
            type: firstItem.type,
            activityId: firstItem.activityId,
            isDisabled: firstItem.isDisabled,
            chats: chatItems.concat(solutionChatItems),
            isMutedDiscipline: firstItem.isMutedDiscipline,
            isMuted: firstItem.isMuted
        });
    }

    items.forEach((x: SearchListChatDto) => {
        if (x.type !== ChatType.Discipline && x.type !== ChatType.Solution) {
            allChats.push(x);
        }
    });

    return allChats;
}

export {
    getCustomAvatarColor,
    getCustomAvatarText,
    prepareSearchedChats,
    convertChatBaseInfoToListChatDto,
    convertChatBaseInfoToInfoListItem,
    convertListChatDtoToInfoListItem,
};
