// Enum, используемый для локализации приложения
export enum LocaleName {
  RU = 'ru',
  EN = 'en'
}

// Enum, используемый для работы с датами
export enum dateLanguageName {
    ru = 'ru-ru',
    en = 'en-US'
}
