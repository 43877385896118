import { AccountStoreInterface } from './state';
import { AccountCurrentInfoResponseModel, RoleEnum } from 'src/api/ApiClient';

const getters = {
    // Получить информацию об аккаунте
    getAccountInfo(state: AccountStoreInterface): AccountCurrentInfoResponseModel | null {
        return state.accountInfo;
    },
    // Получить id библиотеки пользователя
    getUserLibraryId(state: AccountStoreInterface): number | null {
        return state.accountInfo?.libraryId || null;
    },
    // Получить email поддержки
    getSupportEmail(state: AccountStoreInterface): string {
        return state.accountInfo?.supportEmail || '';
    },
    // Получить признак, что текущий пользователь является суперадминистратором
    getIsSuperAdministrator(state: AccountStoreInterface): boolean {
        const roles = state?.accountInfo?.roles;
        return roles?.includes(RoleEnum.SuperAdministratorRole) || false;
    },
    // Получить признак, что текущий пользователь является суперадминистратором,
    // администратором всех уровней, преподавателем
    getIsAdministratorsTeacherRole(state: AccountStoreInterface): boolean {
        const roles = state?.accountInfo?.roles;
        return roles?.includes(RoleEnum.SuperAdministratorRole) ||
            roles?.includes(RoleEnum.DivisionAdministratorRole) ||
            roles?.includes(RoleEnum.EducationalProgramAdministratorRole) ||
            roles?.includes(RoleEnum.TeacherRole) ||
            roles?.includes(RoleEnum.GroupCuratorRole) ||
            false;
    },
    // Получить признак, что текущий пользователь является каким-либо администратором
    getHasAnyAdministratorRole(state: AccountStoreInterface): boolean {
        const roles = state?.accountInfo?.roles;
        return roles?.includes(RoleEnum.SuperAdministratorRole) ||
            roles?.includes(RoleEnum.DivisionAdministratorRole) ||
            roles?.includes(RoleEnum.EducationalProgramAdministratorRole) ||
            roles?.includes(RoleEnum.CohortAdministratorRole) ||
            roles?.includes(RoleEnum.UniversityAdministratorRole) ||
            false;
    },
    //Задизейблин ли у пользователя пункт меню "Университет"
    getIsDisabledUniversityMenu(state: AccountStoreInterface): boolean {
        const roles = state?.accountInfo?.roles;
        if (roles) {
            return (
                !roles.length || roles.filter((x: RoleEnum) => x !== RoleEnum.DemoRole && x !== RoleEnum.CartographerRole).length === 0);
        }
        return true;
    }
};

export default getters;
