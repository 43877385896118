<template>
    <div class="birthday-picker">
        <p class="text-shade-8 q-mb-sm q-ml-sm">
            {{ localize('Дата рождения') }}
            {{ isRequired ? '*' : '' }}
        </p>
        <div class="row q-gutter-sm">
            <ui-select
                v-model="birthdayModel.day"
                :class="{ 'high-field': isHighFields }"
                :label="birthdayModel.day ? '' : localize('День')"
                :options="days"
                :error="(!isValidDate && isEditBirthday) || isRequiredError"
                :is-use-raw-option="true"
                :disable="disable"
                hide-bottom-space
                class="col select"
            >
                <template v-if="birthdayModel.day" v-slot:append>
                    <q-icon
                        name="close"
                        class="cursor-pointer"
                        @click.stop="deleteValue(birthdayModelTypes.Day)"
                    />
                </template>
            </ui-select>

            <ui-select
                v-model="birthdayModel.month"
                option-value="value"
                option-label="name"
                :class="{ 'high-field': isHighFields }"
                :label="birthdayModel.month ? '' : localize('Месяц')"
                :options="months"
                :error="(!isValidDate && isEditBirthday) || isRequiredError"
                :disable="disable"
                hide-bottom-space
                class="col select"
            >
                <template v-if="birthdayModel.month" v-slot:append>
                    <q-icon
                        name="close"
                        class="cursor-pointer"
                        @click.stop="deleteValue(birthdayModelTypes.Month)"
                    />
                </template>
            </ui-select>

            <ui-select
                v-model="birthdayModel.year"
                :class="{ 'high-field': isHighFields }"
                :label="birthdayModel.year ? '' : localize('Год')"
                :options="years"
                :error="(!isValidDate && isEditBirthday) || isRequiredError"
                :error-message="yearNotInRangeErrorMessage"
                :is-use-raw-option="true"
                :disable="disable"
                hide-bottom-space
                class="col select"
            >
                <template v-if="birthdayModel.year" #append>
                    <q-icon
                        name="close"
                        class="cursor-pointer"
                        @click.stop="deleteValue(birthdayModelTypes.Year)"
                    />
                </template>
            </ui-select>
        </div>
        <div v-if="isRequiredError" class="text-error q-field__bottom q-pa-none q-mt-xs">
            {{ localize('Обязательное поле') }}
        </div>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, PropType, Ref, ref, watch } from 'vue';
    import { birthdayModelTypes, IBirthdayModel, IMonth } from '../BirthdayPicker/types';
    import { localize } from 'src/services/LocalizationService';

    export default defineComponent({
        name: 'BirthdayPicker',
        emits: ['update-birthday', 'update-is-valid-date'],
        props: {
            /**
             * Нужно ли делать поля большими (используется на странице регистрации)
             */
            isHighFields: {
                type: Boolean,
                default: false,
            },
            /**
             * Если нужно указать изначальные значения для дня рождения,
             * получаем их из пропсов
             */
            initialValue: {
                type: String as PropType<string | null>,
                default: null,
            },

            /**
            * Максимально допустимый возраст
            */
            maxYears: {
                type: Number,
                default: null,
            },
            /**
            * Минимально допустимый возраст
            */
            minYears: {
                type: Number,
                default: null,
            },

            /**
            * Ввод заблокирован
            */
            disable: {
                type: Boolean,
                default: false,
            },

            /**
            * Обязательное поле или нет
            */
            isRequired: {
                type: Boolean,
                default: false,
            },
        },
        /* eslint-disable-next-line max-lines-per-function  */
        setup(props, context) {
            /**
             * Признак, отвечающий за то, началось редактирование
             * дня рождения или нет
             */
            const isEditBirthday: Ref<boolean> = ref(false);

            const isRequiredError: Ref<boolean> = ref(false);

            /**
            * Текст ошибки, если возраст пользователя вне допустимого диапазона
            */
            const yearNotInRangeErrorMessage: Ref<string | null> = ref(null);

            /**
             * Модель дня рождения (день, месяц, год)
             */
            const birthdayModel: Ref<IBirthdayModel> = ref({
                day: null,
                month: null,
                year: null,
            });

            /**
             * Список дней, необходимый для выбора дня рождения
             */
            const days: Ref<number[]> = ref(Array.from(
                { length: 31 },
                (_: number, i: number) => i + 1,
            ));

            /**
             * Список месяцев, необходимый для выбора месяца рождения
             */
            const months: Ref<IMonth[]> = ref([
                {
                    value: 0,
                    name: localize('янв'),
                }, {
                    value: 1,
                    name: localize('фев'),
                }, {
                    value: 2,
                    name: localize('март'),
                }, {
                    value: 3,
                    name: localize('апр'),
                }, {
                    value: 4,
                    name: localize('май'),
                }, {
                    value: 5,
                    name: localize('июнь'),
                }, {
                    value: 6,
                    name: localize('июль'),
                }, {
                    value: 7,
                    name: localize('авг'),
                }, {
                    value: 8,
                    name: localize('сен'),
                }, {
                    value: 9,
                    name: localize('окт'),
                }, {
                    value: 10,
                    name: localize('нояб'),
                }, {
                    value: 11,
                    name: localize('дек'),
                },
            ]);

            /**
             * Следим за изменеием внутренней модели и в случае
             * необходимости отдаем ее родителю
             */
            watch(birthdayModel.value, (value: IBirthdayModel) => {
                isEditBirthday.value = true;
                const { day, month, year } = value;

                /**
                 * Если не заполнено хотя бы одно поле из трех
                 * не сохраняем информацию в модель
                 */
                if (!year || !month || !day) {
                    context.emit('update-birthday', '');
                } else {
                    context.emit('update-birthday', new Date(year, month.value, day).toServerFormat());
                }

                context.emit('update-is-valid-date', isValidDate.value);
                isRequiredError.value = false;

                if (!value.day && !value.year && !value.month) {
                    isEditBirthday.value = false;
                }
            });

            /**
             * Список лет для выбора года рождения.
             */
            const years = computed(() => {
                const yearsData = [];

                for (let i = new Date().getFullYear() - 90; i <= new Date().getFullYear() - 12; i++) {
                    yearsData.push(i);
                }

                return yearsData;
            });

            /**
             * Проверка на корректность введеной даты.
             * Помогает предотвратить такие случаи как:
             * 30 февраля и 31 июня.
             */
            const isValidDate = computed((): boolean => {
                yearNotInRangeErrorMessage.value = '';
                const { day, month, year } = birthdayModel.value;

                if (props.maxYears && props.minYears && year) {
                    const userYears = new Date().getFullYear() - (year ?? 0);
                    const isCorrectBirthDate = userYears >= (props.minYears ?? 0) && userYears <= (props.maxYears ?? 0);
                    if (!isCorrectBirthDate) {
                        yearNotInRangeErrorMessage.value = localize('Возраст должен находиться в диапазоне от {Min} до {Max} лет',
                                                                    {
                                                                        Max: props.maxYears,
                                                                        Min: props.minYears
                                                                    });
                        return false;
                    }
                }

                if (!year && !month && !day) {
                    return true;
                }
                /**
                 * Если не заполнено хотябы одно поле из трех
                 * валидация проходит, т.к для бэка нужны все 3 поля
                 */
                if (!year || !month || !day) {
                    return false;
                }

                const d = new Date(year, month.value, day);

                return (
                    d.getFullYear() === year &&
                    d.getMonth() === month.value &&
                    d.getDate() === day
                );
            });

            // Находится ли компонент в состоянии ошибки
            const hasError = computed(() => {
                return !isValidDate.value || isRequiredError.value;
            });

            // Валидация копмонента
            function validate(): void {
                if (props.isRequired) {
                    const { day, month, year } = birthdayModel.value;

                    if (!year || !month || !day) {
                        isRequiredError.value = true;
                        return;
                    }

                    isRequiredError.value = false;
                } else {
                    isRequiredError.value = false;
                }
            }

            /**
             * Очистка значения поля из даты рождения
             */
            function deleteValue(type: birthdayModelTypes): void {
                birthdayModel.value[type] = null;
            }

            /**
             * Если у нас есть изначальные значения, присваиваем
             * их в локальную модель
             */
            onMounted(() => {
                if (props.initialValue) {
                    birthdayModel.value.year = new Date(props.initialValue).getFullYear();
                    birthdayModel.value.day = new Date(props.initialValue).getDate();
                    birthdayModel.value.month = months.value.find((m: IMonth) => {
                        return m.value === new Date(props.initialValue || new Date()).getMonth();
                    }) || months.value[0];
                }
            });

            return {
                birthdayModel,
                days,
                isEditBirthday,
                isRequiredError,
                months,
                birthdayModelTypes,
                years,
                yearNotInRangeErrorMessage,
                isValidDate,
                validate,
                hasError,
                deleteValue,
                localize,
            };
        },
    });
</script>

<style lang="scss" scoped>
    ::v-deep(.q-field:not(.default) .q-field__control .q-field__native) {
        padding: 0 !important;
        padding-left: 15px !important;
    }
</style>
