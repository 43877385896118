﻿<template>
    <search-item
        :name="item.name"
        :item-page-url="groupPageUrl"
        :item="item"
        :type="SearchType.Group"
        @on-item-selected="$emit('on-item-selected')"
    >
        <template #avatar>
            <group-avatar :isArchive="item.isArchive" />
        </template>
        <template #info>
            <div class="info">
                {{ studentCount }}
                <template v-if="item.cohortName.length"> • {{ item.cohortName }}</template>
            </div>
        </template>
        <template #append>
            <slot name="append" />
        </template>
    </search-item>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import {
        RoutePageNameEnum,
        SearchGroupDto,
        SearchType,
    } from 'src/api/ApiClient';
    import { Common } from 'src/helpers/Common';
    import { localize } from 'src/services/LocalizationService';
    import SearchItem from '../SearchItem.vue';
    import { useGetEntityPageUrl } from '../../hooks/useGetEntityPageUrl';

    /**
     * Компонент для отображение данных о найденной группе
     */

    defineEmits<{
        (e: 'on-item-selected'): void;
    }>();

    const props = defineProps<{
        item: SearchGroupDto;
    }>();

    const {
        getEntityPageUrl: getGroupPageUrl
    } = useGetEntityPageUrl(Common.getRouteName(RoutePageNameEnum.GroupInfo));

    // Ссылка на страницу группы
    const groupPageUrl = computed(() => getGroupPageUrl(props.item.id));

    // Количество студентов
    const studentCount: string = props.item.studentCount.cased(localize('студент'), localize('студента'), localize('студентов'), true);
</script>
