<template>
    <q-input
        ref="inputRef"
        no-error-icon
        :model-value="modelValue"
        :type="isShowPassword ? 'text' : 'password'"
        :error-message="errorMessage"
        :label="label"
        :error="error"
        @update:model-value="$emit('update:model-value', $event)"
        :rules="rules"
        lazy-rules
    >
        <template #append>
            <q-icon
                class="cursor-pointer"
                :name="isShowPassword ? 'visibility_off' : 'visibility'"
                @click="isShowPassword = !isShowPassword"
            />
        </template>
    </q-input>
</template>

<script lang="ts">
    import { defineComponent, Ref, ref, PropType, getCurrentInstance } from 'vue';
    import useValidateAllFields from 'src/helpers/custom-hooks/useValidateAllFields';

    export default defineComponent({
        name: 'InputPassword',
        emits: ['update:model-value'],
        props: {
            modelValue: {
                type: String,
                required: true,
            },
            // Имя для q-input (например "Пароль", "Подтверждение пароля")
            label: {
                required: true,
                type: String,
            },
            // Произошла ли ошибка в поле ввода
            error: {
                required: true,
                type: Boolean,
            },
            // Сообщения об ошибке, которые нужно выводить
            errorMessage: {
                required: true,
                type: String,
            },
            rules: {
                required: true,
                type: Array as PropType<any[]>
            },
        },
        /* eslint-disable-next-line max-lines-per-function  */
        setup() {
            const app = getCurrentInstance();
            // Признак показа/скрытия пароля
            const isShowPassword: Ref<boolean> = ref(false);
            const hasError = ref(false);

            // Валидация полей критериев
            function validate(): void {
                const { isInvalid } = useValidateAllFields(app?.refs);
                hasError.value = isInvalid;
            }

            return {
                hasError,
                isShowPassword,
                validate
            };
        }
    });

</script>
