<template>
    <div :class="{'thread-message': isThreadChat}" :data-id="message.id">
        <div
            v-if="message.isMyMessage && !isServiceMessage && !message.isDeleted && !isMainMessageInThreadChat"
            :data-id="message.id"
            class="message-block q-pt-sm q-pb-sm right"
        >
            <div
                ref="myMessageRef"
                class="message-item right"
                :style="{ width: isCallMessage ? '279px' : '' }"
                :class="{
                    sending: Number(message.id) < 0,
                    pinned: message.isPinnedMessage,
                    error: !!message.error && !message.isSending
                }"
            >
                <div
                    v-if="!isArchiveChat && !message.isDeleted && Number(message.id) > 0 && !isCallMessage"
                    ref="actionPanelRef"
                    class="action-panel flex items-center"
                >
                    <Icon
                        name="EmojiIcon"
                        size="18"
                        role="button"
                        tabindex="0"
                        :aria-label="localizeText('Добавить реакцию')"
                        class="q-mr-sm"
                    >
                        <q-menu ref="emojiMenuMyMessageRef" cover class="emoji-menu overflow-hidden">
                            <q-list>
                                <EmojiBlock @select="selectEmoji" />
                            </q-list>
                        </q-menu>
                        <q-tooltip>{{ localizeText('Добавить реакцию') }}</q-tooltip>
                    </Icon>
                    <Icon
                        v-if="!isThreadChat && !isActivitySolutionChat"
                        name="ChatThreadIcon"
                        size="18"
                        @click.native="replyInThread"
                        role="button"
                        tabindex="0"
                        :aria-label="localizeText('Ответить в обсуждении')"
                        class="q-mr-sm"
                    >
                        <q-tooltip>{{ localizeText('Ответить в обсуждении') }}</q-tooltip>
                    </Icon>
                    <Icon
                        name="QuoteIcon"
                        @click.native="replyMessage"
                        role="button"
                        tabindex="0"
                        :aria-label="localizeText('Цитировать')"
                        class="q-mr-sm"
                    >
                        <q-tooltip>{{ localizeText('Цитировать') }}</q-tooltip>
                    </Icon>
                    <Icon
                        v-if="!isThreadChat"
                        name="CopyContentIcon"
                        @click.native="copyMessage"
                        role="button"
                        tabindex="0"
                        :aria-label="localizeText('Копировать')"
                        size="18"
                    >
                        <q-tooltip>{{ localizeText('Копировать') }}</q-tooltip>
                    </Icon>

                    <q-icon name="more_vert" size="16px" role="menu" tabindex="0">
                        <q-menu ref="menuRef">
                            <q-list>
                                <q-item
                                    v-if="chatInfo && chatInfo.canPinnedMessage && !isThreadChat"
                                    clickable
                                    v-close-popup
                                    @click="pinUnpinMessages"
                                >
                                    <q-item-section>
                                        {{ message.isPinnedMessage ? localizeText('Открепить') : localizeText('Закрепить') }}
                                    </q-item-section>
                                </q-item>
                                <q-item
                                    v-if="isThreadChat"
                                    clickable
                                    v-close-popup
                                    @click="copyMessage"
                                >
                                    <q-item-section>
                                        {{ localizeText('Копировать') }}
                                    </q-item-section>
                                </q-item>
                                <q-item
                                    v-if="isCanEditMessage"
                                    clickable
                                    v-close-popup
                                    @click="editMessage"
                                >
                                    <q-item-section>
                                        {{ localizeText('Редактировать') }}
                                    </q-item-section>
                                </q-item>
                                <q-item
                                    clickable
                                    v-close-popup
                                    @click="isShowDeleteModal = true"
                                    class="text-error"
                                >
                                    <q-item-section>
                                        {{ localizeText('Удалить') }}
                                    </q-item-section>
                                </q-item>
                                <q-item v-close-popup>
                                    <q-item-section>
                                        <div class="sub-item text-shade-8" style="line-height: 16px">
                                            {{
                                                localizeText('Редактирование возможно в течение {count} часов', {
                                                    count: 4,
                                                })
                                            }}
                                        </div>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                        </q-menu>
                    </q-icon>
                </div>
                <div
                    v-if="!!message.error"
                    ref="actionPanel"
                    class="action-panel flex items-center"
                >
                    <q-icon name="more_vert" size="16px" role="menu" tabindex="0">
                        <q-menu>
                            <q-list>
                                <q-item clickable v-close-popup @click="sendMessageAgain">
                                    <q-item-section>
                                        {{ localizeText('Отправить повторно') }}
                                    </q-item-section>
                                </q-item>
                            </q-list>
                        </q-menu>
                    </q-icon>
                </div>
                <div
                    v-if="message.answerToMessage"
                    class="message-item answer-to-message full-width q-mb-sm"
                    @click="scrollToAnswerMessage"
                >
                    <div class="user-name flex items-center">
                        <q-icon
                            v-if="getRoleIconName(message.answerToMessage.authorRole)"
                            :name="getRoleIconName(message.answerToMessage.authorRole)"
                            size="14px"
                            color="success"
                            class="q-mr-xs"
                            aria-hidden="true"
                        />
                        {{ getFullUserName(message.answerToMessage.author) }}
                    </div>
                    <MessageText
                        v-if="message.answerToMessage.text"
                        :message="message.answerToMessage.text"
                        :mode-chat="modeChat"
                        :is-mobile-view="isMobileView"
                        :is-answer-to-message="true"
                    />
                    <div v-else>
                        [{{ message.answerToMessage.files && message.answerToMessage.files.length > 1 ? localizeText('Файлы') : localizeText('Файл') }}]
                    </div>
                </div>

                <!-- Если сообщение имеет тип "Звонок" -->
                <call-message
                    v-if="isCallMessage && message.call"
                    :call="message.call"
                    :message-time="messageTime"
                    :chat-id="message.chatId"
                />

                <MessageText
                    :message="message.text"
                    :mode-chat="modeChat"
                    :is-mobile-view="isMobileView"
                />
                <MessageFiles
                    v-if="message.files && message.files.length"
                    :files="message.files"
                    :is-activity-solution-chat="isActivitySolutionChat"
                />
                <div
                    v-if="!isCallMessage"
                    class="row justify-between items-center text-description q-mt-sm time"
                >
                    <div class="q-mr-md">
                        <template v-if="Number(message.id) < 0">
                            <template v-if="message.isSending">
                                <q-spinner-ios color="primary" size="1em" />
                                <span class="q-ml-xs">{{ localizeText('Отправляется...') }}</span>
                            </template>
                            <div v-else class="flex items-center">
                                <alert-error-icon class="cursor-pointer">
                                    <q-tooltip
                                        anchor="top middle"
                                        self="bottom middle"
                                    >
                                        {{ message.error }}
                                    </q-tooltip>
                                </alert-error-icon>
                                <span class="text-error q-ml-xs">{{ localizeText('Не отправлено') }}</span>
                            </div>
                        </template>
                        <template v-else-if="message.messageReactions && message.messageReactions.length && !message.isDeleted">
                            <MessageReactions :reactions="message.messageReactions" />
                        </template>
                    </div>
                    <div class="flex">
                        <Icon
                            v-if="message.isPinnedMessage && !message.isDeleted"
                            name="UnpinIcon"
                            size="11"
                            :use-raw-color="true"
                            class="q-mr-xs no-pointer-events"
                            :aria-label="localizeText('Сообщение закреплено')"
                        />
                        <q-icon
                            v-if="message.isEditedMessage && !message.isDeleted"
                            name="edit"
                            size="12px"
                            class="q-mr-xs"
                            :aria-label="localizeText('Сообщение отредактировано')"
                        />
                        {{ messageTime }}
                    </div>
                </div>
                <MessageDiscussion
                    v-if="!isThreadChat && message.discussion && message.discussion.countOfMessages > 0"
                    :message="message"
                />
            </div>
        </div>
        <div
            v-if="((!message.isMyMessage || isServiceMessage) && !message.isDeleted) || isMainMessageInThreadChat"
            :data-id="message.id"
            class="message-block q-pt-sm q-pb-sm"
        >
            <div>
                <q-avatar
                    v-if="!isServiceMessage"
                    @click="openUserPage(message.author.id)"
                    size="24px"
                    color="white"
                    :style="{ 'margin-bottom': message.discussion ? '40px' : '' }"
                    class="user-avatar cursor-pointer q-mr-xs"
                >
                    <img
                        v-if="message.author.photoUrl"
                        :src="message.author.photoUrl"
                        alt=""
                    />
                    <custom-avatar
                        v-else
                        :text="message.author.lastName[0] + message.author.firstName[0]"
                        :color="CustomAvatarColors.User"
                    />
                </q-avatar>

                <div
                    class="message-item"
                    :style="{
                        background: message.isPinnedMessage && !message.isDeleted ? '#FFFAE1' : 'white',
                        width: isCallMessage ? '279px' : ''
                    }"
                    :class="{
                        'service-message': isServiceMessage,
                        'solution-chat': isActivitySolutionChat
                    }"
                >
                    <div
                        v-if="!isArchiveChat && !message.isDeleted && !isCallMessage && !isServiceMessage"
                        ref="actionPanelFromRef"
                        class="action-panel flex items-center"
                    >
                        <Icon
                            name="EmojiIcon"
                            size="18"
                            class="q-mr-sm"
                            role="button"
                            tabindex="0"
                            :aria-label="localizeText('Добавить реакцию')"
                        >
                            <q-menu ref="emojiMenuRef" cover class="emoji-menu overflow-hidden">
                                <q-list>
                                    <EmojiBlock @select="selectEmoji" />
                                </q-list>
                            </q-menu>
                            <q-tooltip>{{ localizeText('Добавить реакцию') }}</q-tooltip>
                        </Icon>
                        <Icon
                            v-if="isMainMessageInThreadChat"
                            @click.native="scrollToAnswerMessage"
                            name="MainIcon"
                            size="18"
                            color="#9090A5"
                            role="button"
                            tabindex="0"
                            :aria-label="localizeText('Перейти к сообщению')"
                            class="q-mr-sm"
                        >
                            <q-tooltip>{{ localizeText('Перейти к сообщению') }}</q-tooltip>
                        </Icon>
                        <Icon
                            v-if="!isThreadChat && !isActivitySolutionChat"
                            name="ChatThreadIcon"
                            size="18"
                            @click.native="replyInThread"
                            role="button"
                            tabindex="0"
                            :aria-label="localizeText('Ответить в обсуждении')"
                            class="q-mr-sm"
                        >
                            <q-tooltip>{{ localizeText('Ответить в обсуждении') }}</q-tooltip>
                        </Icon>
                        <Icon
                            v-if="chatInfo"
                            name="QuoteIcon"
                            @click.native="replyMessage"
                            role="button"
                            tabindex="0"
                            :aria-label="localizeText('Цитировать')"
                            class="q-mr-sm"
                        >
                            <q-tooltip>{{ localizeText('Цитировать') }}</q-tooltip>
                        </Icon>
                        <Icon
                            v-if="!isThreadChat"
                            name="CopyContentIcon"
                            @click.native="copyMessage"
                            role="button"
                            tabindex="0"
                            :aria-label="localizeText('Копировать')"
                            size="18"
                        >
                            <q-tooltip>{{ localizeText('Копировать') }}</q-tooltip>
                        </Icon>
                        <Icon
                            v-if="isThreadChat && chatInfo && (chatInfo.isMuted || isChatInMutedSectionOrDiscipline || chatInfo.isMutedParentChat)"
                            name="NotificationOff"
                            @click.native="muteChat"
                            role="button"
                            tabindex="0"
                            :aria-label="localizeText('Получать уведомления')"
                            v-bind="isChatInMutedSectionOrDiscipline || chatInfo.isMutedParentChat ? { disabled: true } : {}"
                        >
                            <q-tooltip v-if="chatInfo.isMutedParentChat || isChatInMutedSectionOrDiscipline">
                                {{ localizeText(getDisabledNotificationText()) }}
                            </q-tooltip>
                            <q-tooltip v-else>{{ localizeText('Получать уведомления') }}</q-tooltip>
                        </Icon>
                        <Icon
                            v-if="isThreadChat && chatInfo && !(chatInfo.isMuted || isChatInMutedSectionOrDiscipline || chatInfo.isMutedParentChat)"
                            name="NotificationOn"
                            @click.native="muteChat"
                            role="button"
                            tabindex="0"
                            :aria-label="localizeText('Отключить уведомления')"
                        >
                            <q-tooltip>{{ localizeText('Отключить уведомления') }}</q-tooltip>
                        </Icon>

                        <q-icon
                            v-if="chatInfo && (chatInfo.canDeleteNotOwnedMessages || isThreadChat || (chatInfo.canPinnedMessage && !isThreadChat))"
                            name="more_vert"
                            size="16px"
                            class="q-ml-xs"
                            role="menu"
                            tabindex="0"
                        >
                            <q-menu>
                                <q-list style="min-width: 210px;">
                                    <q-item
                                        v-if="chatInfo.canPinnedMessage && !isThreadChat"
                                        clickable
                                        v-close-popup
                                        @click="pinUnpinMessages"
                                    >
                                        <q-item-section>
                                            {{ message.isPinnedMessage ? localizeText('Открепить') : localizeText('Закрепить') }}
                                        </q-item-section>
                                    </q-item>
                                    <q-item
                                        v-if="isThreadChat"
                                        clickable
                                        v-close-popup
                                        @click="copyMessage"
                                    >
                                        <q-item-section>
                                            {{ localizeText('Копировать') }}
                                        </q-item-section>
                                    </q-item>
                                    <q-item
                                        v-if="chatInfo.canDeleteNotOwnedMessages || (isMainMessageInThreadChat && message.isMyMessage)"
                                        clickable
                                        v-close-popup
                                        @click="isShowDeleteModal = true"
                                        class="text-error">
                                        <q-item-section>
                                            {{ localizeText('Удалить') }}
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-menu>
                        </q-icon>
                    </div>
                    <div class="user-name flex items-center" v-if="!isServiceMessage">
                        <q-icon
                            v-if="getRoleIconName(message.authorRole)"
                            :name="getRoleIconName(message.authorRole)"
                            size="14px"
                            color="success"
                            class="q-mr-xs"
                            aria-hidden="true"
                        />
                        {{ getFullUserName(message.author) }}
                    </div>
                    <div :class="{ 'text-center': isServiceMessage }">
                        <div
                            v-if="message.answerToMessage"
                            class="message-item answer-to-message full-width q-mb-sm"
                            @click="scrollToAnswerMessage"
                        >
                            <div class="user-name flex items-center">
                                <q-icon
                                    v-if="getRoleIconName(message.answerToMessage.authorRole)"
                                    :name="getRoleIconName(message.answerToMessage.authorRole)"
                                    size="14px"
                                    color="success"
                                    class="q-mr-xs"
                                    aria-hidden="true"
                                />
                                {{ getFullUserName(message.answerToMessage.author) }}
                            </div>
                            <MessageText
                                v-if="message.answerToMessage.text"
                                :message="message.answerToMessage.text"
                                :mode-chat="modeChat"
                                :is-mobile-view="isMobileView"
                                :is-answer-to-message="true"
                            />
                            <div v-else>
                                [{{ message.answerToMessage.files && message.answerToMessage.files.length > 1 ? localizeText('Файлы') : localizeText('Файл') }}]
                            </div>
                        </div>

                        <!-- Если сообщение имеет тип "Звонок" -->
                        <call-message
                            v-if="isCallMessage && message.call"
                            :call="message.call"
                            :message-time="messageTime"
                            :chat-id="message.chatId"
                        />

                        <MessageText
                            :message="message.text"
                            :mode-chat="modeChat"
                            :is-mobile-view="isMobileView"
                        />
                        <MessageFiles
                            v-if="message.files && message.files.length"
                            :files="message.files"
                            :is-activity-solution-chat="isActivitySolutionChat"
                        />
                    </div>
                    <div class="row justify-between items-start q-mt-sm">
                        <template v-if="message.messageReactions && message.messageReactions.length">
                            <MessageReactions
                                :reactions="message.messageReactions"
                                class="col"
                            />
                        </template>
                        <div
                            v-if="!isCallMessage"
                            class="flex text-description"
                            :class="{
                                'text-center full-width justify-center': isServiceMessage,
                                'text-right': !isServiceMessage
                            }"
                        >
                            <Icon
                                v-if="message.isPinnedMessage && !message.isDeleted"
                                name="UnpinIcon"
                                size="11"
                                :use-raw-color="true"
                                :aria-label="localizeText('Сообщение закреплено')"
                                class="q-mr-xs no-pointer-events"
                            />
                            <q-icon
                                v-if="message.isEditedMessage && !message.isDeleted"
                                name="edit"
                                size="12px"
                                :aria-label="localizeText('Сообщение отредактировано')"
                                class="q-mr-xs"
                            />
                            {{ messageTime }}
                        </div>
                    </div>
                    <MessageDiscussion
                        v-if="!isThreadChat && message.discussion && message.discussion.countOfMessages > 0"
                        :message="message"
                    />
                </div>
            </div>
        </div>
        <div
            :id="'message-observer' + message.id"
            :data-id="message.id"
            class="message-observer"
        ></div>

        <q-dialog v-model="isShowPinModal">
            <q-card class="confirm-modal q-pa-lg">
                <div class="confirm-modal__title q-mb-md">{{ localizeText('Закрепить сообщение') }}</div>
                <p class="q-mb-none"> {{ pinModalInfo }}:</p>
                <div class="q-mb-none">
                    <q-checkbox v-model="isSendOdinNotify" label="Odin" />
                </div>
                <div class="q-mb-md">
                    <q-checkbox v-model="isSendEmailAndOdinNotify" :label="'Email ' + localizeText('и') + ' Odin'" />
                </div>
                <div class="text-right">
                    <q-btn flat :label="localizeText('Закрыть')" color="primary" v-close-popup />
                    <q-btn
                        flat
                        :label="localizeText('Закрепить')"
                        @click="pinMessage"
                        color="primary"
                        class="q-manual-focusable--focused q-ml-md"
                        v-close-popup
                    />
                </div>
            </q-card>
        </q-dialog>

        <custom-modal
            v-model="isShowDeleteModal"
            @delete-handler="removeMessage"
        >
            <template #title>
                {{ localizeText('Удалить сообщение') }}
            </template>

            <template #content>
                <div
                    v-if="message.discussion"
                    v-html="localizeText('Вы действительно хотите удалить обсуждение? Нажимая кнопку <b>удалить</b>, вы также удалите все сообщения в данном обсуждении')"
                ></div>
                <div v-else>
                    {{ localizeText('Вы действительно хотите удалить сообщение? Отменить данное действие будет нельзя') }}
                </div>
            </template>
        </custom-modal>
    </div>
</template>

<script lang="ts">
    import {
        ChatClient,
        ChatBaseInfoResponseModel,
        ChatMessageHubDto,
        ChatMessageReactionDto,
        ChatMessageType,
        ChatType, MuteChatTime,
        RoutePageNameEnum,
        UserBaseInfoDto,
    } from 'src/api/ApiClient';
    import { IChatMessage } from 'components/Chat/types/interfaces';
    import DateUtil from 'src/helpers/DateUtil';
    import { localize } from 'src/services/LocalizationService';
    import MessageReactions from './MessageReactions.vue';
    import MessageText from './MessageText.vue';
    import MessageFiles from 'components/Chat/components/MessageFiles.vue';
    import EmojiBlock from 'components/EmojiBlock';
    import { Notify, QMenu } from 'quasar';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import { Common } from 'src/helpers/Common';
    import CallMessage from './CallMessage.vue';
    import MessageDiscussion from 'components/Chat/components/MessageDiscussion.vue';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { computed, defineComponent, getCurrentInstance, onMounted, PropType, ref } from 'vue';
    import { ChatPartialHub } from 'src/services/hubs/ChatPartialHub';
    import { useRoute, useRouter } from 'vue-router';
    import useChatMembers from 'components/Chat/hooks/useChatMembers';
    import _ from 'lodash';
    import { useAccountStore } from 'src/store/module-account';
    import { useChatStore } from 'src/store/module-chat';
    import { ModeChat } from 'components/Chat/types/enums';

    export default defineComponent({
        name: 'MessageItem',

        components: {
            MessageDiscussion,
            MessageFiles,
            MessageText,
            MessageReactions,
            EmojiBlock,
            CallMessage,
        },

        emits: [
            'scroll-to-answer-message',
            'on-send-message-error',
            'select-emoji',
            'on-update-message',
        ],

        props: {
            // В чате обсуждения у сообщений есть кнопка
            // настройки уведомлений, нам нужна эта модель
            chatInfo: {
                type: Object as PropType<ChatBaseInfoResponseModel | undefined | null>,
                default: undefined,
            },
            message: {
                type: Object as PropType<IChatMessage>,
                required: true,
            },
            // Архивный чат, показывается в левой панели на странице чатов
            isArchiveChat: {
                type: Boolean,
                default: false,
            },
            // Является ли чат чатом решения
            isActivitySolutionChat: {
                type: Boolean,
                default: false,
            },
            // Находимся ли мы в чате обсуждения
            isThreadChat: {
                type: Boolean,
                default: false,
            },
            // Является ли это сообщение главным в обсуждении
            isMainMessageInThreadChat: {
                type: Boolean,
                default: false,
            },
            //Замьючена ли чат секция или дисциплина, в которой находится чат
            isChatInMutedSectionOrDiscipline: {
                type: Boolean,
                default: false,
                required: false,
            },
            // Режим отображения чата
            modeChat: {
                type: String,
                default: ModeChat.Inline
            },
            // Мобильный вид чата
            isMobileView: {
                type: Boolean,
                default: false
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            const $router = useRouter();
            const $route = useRoute();
            const chatStore = useChatStore();
            const accountStore = useAccountStore();

            const myMessageRef = ref<HTMLDivElement>();
            const actionPanelRef = ref<HTMLDivElement>();
            const actionPanelFromRef = ref<HTMLDivElement>();
            const emojiMenuRef = ref<QMenu>();
            const emojiMenuMyMessageRef = ref<QMenu>();
            const menuRef = ref<QMenu>();

            const app = getCurrentInstance();
            const chatHub = app?.appContext?.config.globalProperties.$commonHub as ChatPartialHub | null;
            const { getRoleIconName } = useChatMembers();

            const isShowPinModal = ref<boolean>(false);
            const isShowDeleteModal = ref<boolean>(false);

            // Отправить ли уведомление участникам чата что сообщение закреплено
            const isSendEmailAndOdinNotify = ref<boolean>(false);
            const isSendOdinNotify = ref<boolean>(false);

            // Разница в минутах между UTC и часовым поясом пользователя
            const userTimeZoneOffsetInMinutes = accountStore.getAccountInfo?.timeZoneOffsetMinutes ?? 0;
            const currentDeviceOffsetInMinutes = DateUtil.getDeviceTimezoneOffset();

            const pinModalInfo = computed<string>(() => {
                return localizeText('Отправить уведомление {count} участникам', { count: countChatMembers.value });
            });

            // Количество пользователей данного чата
            const countChatMembers = computed<number>(() => {
                return props.chatInfo?.countMembers ?? 0;
            });

            // Форматированное время сообщения
            const messageTime = computed<string>(() => {
                return DateUtil.getTime(props.message.createDateTime);
            });

            // Проверка, не конлилось ли время на возможность редактирования сообщения
            const isCanEditMessage = computed<boolean>(() => {
                if (props.message.isDeleted) {
                    return false;
                }
                const createdTime = new Date(props.message.createDateTime).getTime();
                // 14400000 - редактировать можем в течение 4 часов
                const allowEditTimeInMilliseconds = 14400000;
                // Используя смещение относительно UTC получаем текущее время пользователя в системе
                const timeZoneOffsetsDiff = userTimeZoneOffsetInMinutes - currentDeviceOffsetInMinutes;
                const millisecondsInMinute = 60000;
                const timeNowWithOdinTimezone = Date.now() + (timeZoneOffsetsDiff * millisecondsInMinute);
                return timeNowWithOdinTimezone - createdTime < allowEditTimeInMilliseconds;
            });

            const isServiceMessage = computed<boolean>(() => {
                return props.message.type === ChatMessageType.Service;
            });

            const isCallMessage = computed<boolean>(() => {
                return props.message.type === ChatMessageType.Call;
            });

            function openUserPage(id: number): void {
                const techUserId: number = -1;
                // если технический пользователь, никуда не переходим
                if (id === techUserId) {
                    return;
                }

                const routeData = {
                    name: Common.getRouteName(RoutePageNameEnum.UserInfo),
                    params: { id: id.toString() },
                };

                // Если находимся в звонке, то открываем профиль в новой вкладке
                if ($route.name === Common.getRouteName(RoutePageNameEnum.CallEnter)) {
                    const path = $router.resolve(routeData);
                    window.open(path.href, '_blank');
                } else {
                    $router.push(routeData);
                }
            }

            // Сформировать полное имя пользователя
            function getFullUserName(author: UserBaseInfoDto): string {
                if (author.id === -1) {
                    return localizeText('Автоматический звонок');
                }
                let fullName = author.lastName + ' ' + author.firstName;

                if (author.middleName) {
                    fullName += ' ' + author.middleName;
                }

                return fullName;
            }

            // Проскролить чат до указанного сообщения
            function scrollToAnswerMessage(): void {
                context.emit('scroll-to-answer-message', props.message.answerToMessage);
            }

            // Записываем в стор сообщение, которое будем редактировать
            // Если до этого было выбрано сообщение для ответа то убираем его
            // Тк окно ввода сообщения одно
            function editMessage(): void {
                chatStore.editingMessage = props.message;
                chatStore.replyingMessage = null;
            }

            // Записываем в стор сообщение, на которое будем отвечать
            // Если до этого было выбрано сообщение для редактирования то убираем его
            // Тк окно ввода сообщения одно
            function replyMessage(): void {
                const message = { ...props.message };

                // Для главного сообщения проставляем id чата обсуждения
                // а не родительского, иначе цитирование будет идти в родительском чате
                if (props.isMainMessageInThreadChat) {
                    message.chatId = props.chatInfo!.id;
                }

                chatStore.replyingMessage = message;
                chatStore.editingMessage = null;
            }

            // Скопировать сообщение в буфер обмена
            function copyMessage(): void {
                const text = props.message.text.replace(/(<br class="nr">)/g, '\n');

                Common.copyTextToClipboardAsync(Common.stripTags(text)).then(() => {
                    Notify.create({
                        type: 'positive',
                        message: localizeText('Текст сообщения скопирован'),
                    });
                });
            }

            // Записываем в стор сообщение, для которого будет отображаться ветка обсуждения
            function replyInThread(): void {
                chatStore.mainMessageInThread = _.cloneDeep(props.message);
            }

            // Повторная отправка не отправленного сообщения
            function sendMessageAgain(): void {
                context.emit('on-update-message', {
                    ...props.message,
                    isSending: true,
                });

                chatHub?.sendMessageAsync({
                    chatId: props.message.chatId,
                    text: props.message.text,
                    messageFiles: props.message.messageFiles,
                    frontendId: props.message.frontendId,
                    ForcedPushNotificationUserIds: Common.getForcedPushNotificationUserIds(props.message.text)
                }, (message: ChatMessageHubDto, error: any) => {
                    // ставим небольшую задержку, чтобы показался прелоадер "Отправляется"
                    // потому что второй раз и больше catch отрабатывает моментально
                    setTimeout(() => {
                        context.emit('on-send-message-error', {
                            id: message.id,
                            chatId: message.chatId,
                            error: error.message,
                            frontendId: message.frontendId,
                        });
                    }, 500);
                });
            }

            function removeMessage(): void {
                //region Удаляем сообщение и если до этого делали что-то с ним
                // То удаляем информацию из стора
                if (chatStore.editingMessage && chatStore.editingMessage.id === props.message.id) {
                    chatStore.editingMessage = null;
                }

                if (chatStore.replyingMessage && chatStore.replyingMessage.id === props.message.id) {
                    chatStore.replyingMessage = null;
                }

                if (chatStore.mainMessageInThread && chatStore.mainMessageInThread.id === props.message.id) {
                    chatStore.mainMessageInThread = null;
                }
                //endregion

                // Если для удаляемого сообщения есть чат-обсуждение
                // то удаляем его из списка чатов
                if (props.message.discussion) {
                    chatStore.removeDiscussionChat(props.message.discussion.id);
                    chatStore.setCountUnreadedMessages({
                        chatId: props.message.discussion.id,
                        count: 0,
                    });
                }

                // Если оно было закреплено то открепляем
                if (props.message.isPinnedMessage && props.message.id) {
                    chatHub?.unpinMessageAsync(props.message.id);
                }

                if (props.message.id) {
                    chatHub?.removeMessageAsync(props.message.id);
                }
            }

            // Закрепить или открепить сообщение
            function pinUnpinMessages(): void {
                if (props.message.isPinnedMessage) {
                    unpinMessage();
                } else {
                    isShowPinModal.value = true;
                }
            }

            // Открепить сообщение в верхней панели чата
            function unpinMessage(): void {
                if (props.message.id) {
                    chatHub?.unpinMessageAsync(props.message.id);

                    context.emit('on-update-message', {
                        ...props.message,
                        isPinnedMessage: false,
                    });

                    Notify.create({
                        type: 'positive',
                        message: localizeText('Сообщение откреплено'),
                    });
                }
            }

            // Закрепить сообщение в верхней панели чата
            function pinMessage(): void {
                if (props.message.id) {
                    chatHub?.pinMessageAsync(props.message.id, isSendEmailAndOdinNotify.value, isSendOdinNotify.value);

                    context.emit('on-update-message', {
                        ...props.message,
                        isPinnedMessage: true,
                    });

                    Notify.create({
                        type: 'positive',
                        message: localizeText('Сообщение закреплено'),
                    });
                }
            }

            // Проверить, что в какой-то реакции есть реакция и текущего пользователя
            function getMyReaction(emoji: string): ChatMessageReactionDto | undefined {
                const reactions = (props.message.messageReactions || []).filter((x: ChatMessageReactionDto) => x.emoji === emoji);

                return reactions.find((x: ChatMessageReactionDto) => {
                    return x.author.id === accountStore.getAccountInfo?.id;
                });
            }

            // Отключить/включить уведомления чата
            function muteChat(): void {
                const chatApi = new ChatClient(getApiClientInitialParams());

                if (props.chatInfo) {
                    const chatInfo = props.chatInfo;

                    if (chatInfo.isMuted) {
                        chatApi.changeUserNotificationSettings(chatInfo.id, MuteChatTime.Unmute);
                    } else {
                        chatApi.changeUserNotificationSettings(chatInfo.id, MuteChatTime.Forever);
                    }

                    chatStore.setMutedToChat({
                        id: props.chatInfo.id,
                        isMuted: !props.chatInfo.isMuted,
                        type: props.chatInfo.type,
                    });

                    chatInfo.isMuted = !chatInfo.isMuted;
                }
            }

            // Текст тултипа для кнопки включения/отключения уведомлений
            function getDisabledNotificationText(): string {
                if (props.chatInfo && props.chatInfo.isMutedParentChat) {
                    return 'Уведомления отключены в родительском чате';
                }

                if (props.chatInfo && props.chatInfo.parentChatType !== null) {
                    switch (props.chatInfo.parentChatType) {
                        case ChatType.Private:
                            return 'Уведомления отключены для всех личных чатов';
                        case ChatType.Group:
                            return 'Уведомления отключены для всех групповых чатов';
                        case ChatType.Discipline:
                        case ChatType.Solution:
                            if (chatStore.isDisciplineChatsMuted) {
                                return 'Уведомления отключены для всех дисциплин';
                            } else {
                                return 'Уведомления отключены для всех чатов дисциплины';
                            }
                    }
                }

                return '';
            }

            function selectEmoji(emoji: any): void {
                const myReaction = getMyReaction(emoji.native);

                // Если такая реакция уже есть то повторным кликом удаляем ее
                if (myReaction) {
                    chatHub?.removeMessageReactionAsync(myReaction.id);
                } else {
                    // иначе добавляем
                    chatHub?.sendMessageReactionAsync({
                        emoji: emoji.native,
                        chatId: props.message.chatId,
                        messageId: props.message.id!,
                    });

                    setTimeout(() => {
                        context.emit('select-emoji');
                    }, 0);
                }

                emojiMenuRef.value?.hide();
                emojiMenuMyMessageRef.value?.hide();
            }

            function hideMenu(): void {
                if (menuRef.value && menuRef.value.hide) {
                    menuRef.value.hide();
                }
            }

            function localizeText(text: string, formatterArguments: Record<string, string | number> | null = null): string {
                return localize(text, formatterArguments);
            }

            onMounted(() => {
                // на коротких сообщениях верстка кнопок ломается, приходится позиционировать их вручную
                // задержку ставим для того случая, когда в сообщении нет текста
                // а только картинки, чтобы дождаться когда они начнут отрисовываться браузером
                // и у блока была реальная clientWidth
                setTimeout(() => {
                    const isMyMessageNeedOffset = myMessageRef.value && myMessageRef.value.clientWidth < 110;

                    // Для отправленных сообщений
                    if (isMyMessageNeedOffset && actionPanelRef.value) {
                        actionPanelRef.value.style.left = '-56px';
                    }
                    // Для полученных сообщений
                    if (isMyMessageNeedOffset && actionPanelFromRef.value) {
                        actionPanelFromRef.value.style.right = '-56px';
                    }
                }, 1000);
            });

            return {
                myMessageRef,
                actionPanelRef,
                actionPanelFromRef,
                emojiMenuRef,
                emojiMenuMyMessageRef,
                menuRef,
                CustomAvatarColors,
                isShowPinModal,
                isShowDeleteModal,
                isSendEmailAndOdinNotify,
                isSendOdinNotify,
                pinModalInfo,
                countChatMembers,
                messageTime,
                isCanEditMessage,
                isServiceMessage,
                isCallMessage,
                openUserPage,
                getRoleIconName,
                getFullUserName,
                scrollToAnswerMessage,
                editMessage,
                replyMessage,
                copyMessage,
                replyInThread,
                sendMessageAgain,
                removeMessage,
                pinUnpinMessages,
                hideMenu,
                pinMessage,
                getMyReaction,
                muteChat,
                selectEmoji,
                localizeText,
                getDisabledNotificationText,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .message-block {
        transition: background-color 0.3s;

        &.right {
            text-align: right;
        }

        &.highlight {
            background-color: rgba(61, 98, 245, 0.1);
        }

        .user-avatar {
            vertical-align: bottom;
        }

        .message-item {
            position: relative;
            max-width: 550px;
            min-width: 105px;
            display: inline-block;
            padding: 14px;
            box-shadow: 0px 1px 2px rgba(19, 29, 49, 0.16);
            background-color: #ffffff;
            border-radius: 8px 8px 8px 0px;
            text-align: left;
            word-break: break-word;

            &.answer-to-message {
                max-width: 100% !important;
                border-radius: 8px 8px;
                overflow: hidden;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 2px;
                    height: 100%;
                    background-color: $link;
                }
            }

            &.right {
                background-color: #e5ffe1;
                border-radius: 8px 8px 0 8px;

                .action-panel {
                    right: auto;
                    left: -22px;
                }
            }

            &.pinned {
                background-color: #FFFAE1;
            }

            &.sending {
                background-color: $shade-1;
            }

            &.error {
                background-color: $subtle-attention;
            }

            &.full-width {
                max-width: 100%;
            }

            &.service-message {
                display: block;
                margin: 0 auto;
                font-size: 12px;
                border-radius: 8px;

                &.solution-chat {
                    max-width: 155px;
                }

                .action-panel {
                    right: -10px;
                }
            }

            &:hover {
                .action-panel {
                    opacity: 1;
                }
            }

            .user-name {
                margin-bottom: 8px;
                font-size: 10px;
                line-height: 12px;
                color: $success;
            }

            .action-panel {
                position: absolute;
                top: -15px;
                right: -5%;
                padding: 7px 10px;
                background-color: #ffffff;
                box-shadow: 0px 1px 0px rgba(167, 167, 167, 0.25);
                border-radius: 4px;
                opacity: 0;
                transition: opacity 0.3s;
                display: flex;
                flex-wrap: nowrap;
                z-index: 6;
            }
        }

        ::v-deep(i) {
            i {
                color: $shade-8;
            }
        }

        ::v-deep(i),
        ::v-deep(svg) {
            cursor: pointer;
        }
    }

    .thread-message {
        ::v-deep(.reactions-block-component) {
            max-width: 165px;
        }
    }

    .message-observer {
        width: 100%;
        height: 1px;
        opacity: 0;
        background-color: transparent;
    }

    @media (max-width: 960px) {
        .message-block {
            .message-item {
                max-width: 90%;
            }
        }
    }
</style>
