<template>
    <div>
        <div v-if="!isHiddenTopPanel" class="chat-top-panel flex justify-between items-center">
            <q-icon
                v-if="isShowBackArrow"
                name="arrow_back"
                size="20px"
                class="q-mr-sm cursor-pointer"
                @click="isCallPage ? closeSearchModeAndEmitChangeState() : showChatsPanel()"
            />
            <div v-if="isSearchMode && (isMobileView || modeChat === 'inline')" class="col flex items-center">
                <q-input
                    v-model="searchText"
                    for="search-chats-on-chat-page"
                    @update:model-value="searchMessages"
                    debounce="500"
                    :label="searchText ? '' : localizeText('Поиск')"
                    class="col no-background active-field">
                    <template v-slot:prepend>
                        <q-icon name="search" />
                    </template>
                    <template v-slot:append>
                        <q-icon
                            name="close"
                            v-if="!!searchText"
                            @click="closeSearchMode(true)"
                            class="cursor-pointer"
                        />
                    </template>
                </q-input>
            </div>
            <template v-else>
                <template v-if="userDataForNewPrivateChat">
                    <div class="col">
                        <q-avatar
                            size="24px"
                            class="q-mr-sm"
                            aria-hidden="true"
                        >
                            <custom-avatar
                                :text="userDataForNewPrivateChat.firstName"
                                :color="CustomAvatarColors.User"
                            />
                        </q-avatar>
                        {{ userDataForNewPrivateChat.firstName }}
                    </div>
                    <Icon
                        name="AcceptCallIcon"
                        @click="createChatAndCall"
                        id="callInChatBtn"
                        role="button"
                        tabindex="0"
                        :aria-label="localizeText('Звонок в чате')"
                        class="call-icon q-mx-md cursor-pointer"
                    ></Icon>
                </template>
                <div
                    v-else-if="(isDisciplineChat || isSolutionChat) && modeChat === 'inline' && !isCallPage && !isActivitySolutionPage"
                    class="col flex items-center"
                >
                    <div
                        class="row items-center select-rooms justify-between q-px-sm q-py-xs cursor-pointer">
                        <div v-if="chatInfo && chatInfo.isDefaultRoom || !selectedRoom" style="width: 90%;">
                            <template v-if="isSolutionChat">
                                <div class="font-bold ellipsis">
                                    {{ selectedSolutionChat ? selectedSolutionChat.name : '' }}
                                </div>
                                <div class="sub-item text-shade-8">
                                    {{ localizeText('Чат задания') }}
                                </div>
                            </template>
                            <template v-else>
                                <div class="font-bold ellipsis">{{ localizeText('Комната дисциплины') }}</div>
                                <div v-if="chatInfo" class="sub-item text-shade-8">
                                    {{ chatInfo.countMembers }}
                                    {{ getDeclinationOfMembers(chatInfo.countMembers) }}
                                </div>
                            </template>
                        </div>
                        <div v-else class="col">
                            <div class="font-bold ellipsis">{{ selectedRoom?.name }}</div>
                            <div v-if="selectedRoom && selectedRoom.countMembers" class="sub-item text-shade-8">
                                {{ selectedRoom.countMembers }}
                                {{ getDeclinationOfMembers(selectedRoom.countMembers) }}
                            </div>
                        </div>

                        <q-icon name="arrow_drop_down" size="20px" />

                        <count-unreaded-messages
                            v-if="chatInfo"
                            mode="mini"
                            class="count-unreaded-messages all-count-unreaded-messages"
                            :discipline-id="chatInfo.disciplineId"
                            :activity-id="chatInfo.activityId"
                            :chat-id="chatInfo.id"
                            :is-muted="isChatInMutedSectionOrDiscipline"
                        />
                        <q-menu ref="listRoomsRef" :class="isMobileView ? 'mobile-select-rooms' : 'list-select-rooms'">
                            <q-list v-close-popup>
                                <ListRooms
                                    :chat-id="chatInfo ? chatInfo.id : 0"
                                    :room-items="rooms"
                                    :is-need-rooms-request="false"
                                    @on-select-room="selectRoom"
                                />
                            </q-list>
                        </q-menu>
                    </div>
                </div>
                <div class="col flex items-center" v-else>
                    <template v-if="!userDataForNewPrivateChat">
                        <template v-if="isSolutionChat && !isCallPage">
                            <div v-if="student" class="row items-center">
                                <q-avatar size="24px" class="q-mr-md" aria-hidden="true">
                                    <img v-if="student.photoUrl" :src="student.photoUrl" alt="" />
                                    <custom-avatar
                                        v-else
                                        :text="student.lastName[0] + student.firstName[0]"
                                        :color="CustomAvatarColors.User"
                                    />
                                </q-avatar>
                                <div class="col text-left">
                                    <div class="text-description ellipsis">{{ localizeText('Обсуждение решения') }}
                                    </div>
                                    <router-link :to="studentUserPageUrl">
                                        {{ student.lastName }} {{ student.firstName }} {{ student.middleName }}
                                    </router-link>
                                </div>
                            </div>
                            <div v-else class="text-left">
                                <div class="chat-name ellipsis">{{ localizeText('Обсуждение решения') }}</div>
                                <div class="text-description ellipsis" v-if="isMutualCheck">
                                    {{ localizeText('Видят все участники активности') }}
                                </div>
                                <div v-else class="text-description ellipsis">
                                    {{ localizeText('Видят все преподаватели активности') }}
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <q-avatar
                                v-if="chatInfo"
                                size="24px"
                                class="q-mr-md"
                                :class="{
                                    'rounded-borders': chatInfo.type === ChatType.Discipline || chatInfo.type === ChatType.Group || chatInfo.type === ChatType.Self && !chatInfo.chatAvatarPath
                                }"
                                aria-hidden="true"
                            >
                                <img
                                    v-if="chatInfo.chatAvatarPath && chatInfo.type !== ChatType.Group"
                                    :src="chatInfo.chatAvatarPath"
                                />

                                <template v-else>
                                    <custom-avatar
                                        v-if="chatInfo.type === ChatType.Discipline"
                                        text="Д"
                                        :color="CustomAvatarColors.Discipline"
                                    />

                                    <cover-uploader
                                        v-if="chatInfo.type === ChatType.Group"
                                        :text="localizeText('Гч')"
                                        :entity-id="chatInfo.id"
                                        :entity-type="PhotoEntityType.Chat"
                                        :photo-type="PhotoType.Avatar"
                                        :can-edit="chatInfo.canEdit"
                                        :cover="chatInfo.chatAvatarPath"
                                        :uploader-color="CustomAvatarColors.GroupChat"
                                    />

                                    <custom-avatar
                                        v-if="chatInfo.type === ChatType.Private"
                                        :text="chatInfo.nameChat"
                                        :color="CustomAvatarColors.User"
                                    />

                                    <custom-avatar
                                        v-if="chatInfo.type === ChatType.Self"
                                        :text="chatInfo.nameChat"
                                        :color="CustomAvatarColors.GroupChat"
                                    />
                                </template>
                            </q-avatar>
                            <div v-if="chatInfo && chatInfo.userId" class="col chat-name ellipsis-2-lines">
                                <router-link :to="userPageUrl" :target="isCallPage ? '_blank' : ''">
                                    {{ chatName }}
                                </router-link>
                            </div>
                            <template v-else>
                                <div v-if="chatInfo && chatInfo.isDefaultRoom" class="col chat-name ellipsis-2-lines">
                                    <router-link :to="disciplinePageUrl" :target="isCallPage ? '_blank' : ''" style="color: black">
                                        {{ chatName }}
                                    </router-link>
                                </div>
                                <div v-if="chatInfo && !chatInfo.isDefaultRoom" class="col chat-name ellipsis-2-lines">
                                    {{ chatName }}
                                </div>
                                <div v-if="chatInfo && !chatInfo.isDefaultRoom && isDisciplineChat" class="col discipline-name ellipsis-2-lines">
                                    <router-link :to="disciplinePageUrl" :target="isCallPage ? '_blank' : ''" style="color: #9090A5">
                                        {{ chatInfo.nameDiscipline }}
                                    </router-link>
                                </div>
                            </template>
                        </template>
                    </template>
                </div>
                <div v-if="!userDataForNewPrivateChat" class="row items-center">
                    <template v-if="isShowMembersBlock && chatInfo">
                        <div class="text-shade-8 q-mr-sm" v-if="!isSolutionChat" :aria-label="localizeText('Количество участников') + ' ' + chatInfo.countMembers">
                            {{ chatInfo.countMembers }}
                        </div>
                        <div
                            class="avatars-block cursor-pointer"
                            v-if="chatInfo"
                            @click="showChatMembers"
                        >
                            <template v-if="firstTreeUsersLogo.length">
                                <q-avatar
                                    v-for="(item, index) in firstTreeUsersLogo"
                                    :key="'logo' + index"
                                    size="32px"
                                    class="user-logo"
                                    aria-hidden="true"
                                >
                                    <img v-if="item.avatar" :src="item.avatar" />
                                    <custom-avatar
                                        v-else
                                        :text="getShortUserName(item.name)"
                                        :color="CustomAvatarColors.User"
                                    />
                                </q-avatar>
                            </template>
                            <template v-else>
                                <template v-if="chatInfo.countMembers > 3">
                                    <q-avatar
                                        v-for="item in 3"
                                        :key="'countMembers' + item"
                                        size="32px"
                                        class="user-logo q-avatar"
                                        aria-hidden="true"
                                    >
                                        <custom-avatar
                                            text="U"
                                            :color="CustomAvatarColors.User"
                                        />
                                    </q-avatar>
                                </template>
                                <template v-else>
                                    <q-avatar
                                        v-for="item in chatInfo.countMembers"
                                        :key="'countMembers' + item"
                                        size="32px"
                                        class="user-logo q-avatar"
                                        aria-hidden="true"
                                    >
                                        <custom-avatar
                                            text="U"
                                            :color="CustomAvatarColors.User"
                                        />
                                    </q-avatar>
                                </template>
                            </template>
                        </div>
                        <ChatMembers
                            ref="chatMembersRef"
                            v-if="chatInfo.id && !isShowAddEdinChatModal"
                            :key="chatInfo.id"
                            :chat-id="chatInfo.id"
                        />
                    </template>
                    <template v-if="!isSolutionChat && chatInfo && chatInfo.type !== ChatType.Discussion">
                        <Icon
                            v-if="!isMobileView && !isArchiveChat && !isSelfChat && !isCallPage && isShowCallIconIfDisciplineChat"
                            name="AcceptCallIcon"
                            @click="openCallInNewTab"
                            id="callInChatBtn"
                            role="button"
                            tabindex="0"
                            :aria-label="localizeText('Звонок в чате')"
                            :aria-hidden="!chatInfo.isCallsEnabled || chatInfo.isMigrationExam"
                            class="call-icon q-mx-md cursor-pointer"
                            v-bind="!chatInfo.isCallsEnabled || chatInfo.isMigrationExam ? { disabled: true } : {}"
                        >
                            <q-tooltip v-if="!chatInfo?.isCallsEnabled">
                                {{ localizeText('Звонки недоступны, т.к. превышен лимит в 500 участников чата') }}
                            </q-tooltip>
                        </Icon>
                        <UnarchiveIcon
                            v-if="chatInfo.canEdit && !isMobileView && modeChat !== 'inline' && !isSelfChat && !isPrivateChat && !chatInfo.isDefaultRoom && isArchiveChat"
                            @click="isShowArchiveModal = true"
                            role="button"
                            tabindex="0"
                            :aria-label="localizeText('Разархивировать чат')"
                            class="q-mx-md cursor-pointer">
                            <q-tooltip>{{ localizeText('Разархивировать чат') }}</q-tooltip>
                        </UnarchiveIcon>
                        <q-icon
                            name="more_vert"
                            id="showChatsPanelBtn"
                            size="22px"
                            role="menu"
                            tabindex="0"
                            aria-hidden="false"
                            class="cursor-pointer"
                        >
                            <q-menu>
                                <q-list class="q-list--semy-dense" style="width: 260px;">
                                    <q-item
                                        clickable
                                        v-if="!isCallPage && !isArchiveChat && !isSelfChat"
                                        @click="openModalMuted(false)"
                                        v-bind="isChatInMutedSectionOrDiscipline ? { disabled: true } : {}"
                                    >
                                        <q-item-section v-if="chatInfo.isMuted || isChatInMutedSectionOrDiscipline">
                                            {{ localizeText('Включить уведомления чата') }}
                                        </q-item-section>
                                        <q-item-section v-else>
                                            {{ localizeText('Отключить уведомления чата') }}
                                        </q-item-section>
                                        <q-tooltip v-if="isChatInMutedSectionOrDiscipline">
                                            {{ localizeText(getDisabledNotificationText()) }}
                                        </q-tooltip>
                                    </q-item>
                                    <q-item
                                        clickable
                                        v-if="!isCallPage && !isArchiveChat && !isSelfChat"
                                        @click="openModalMuted(true)"
                                    >
                                        <q-item-section v-if="chatInfo.isCallsMuted">
                                            {{ localizeText('Включить уведомления о звонке') }}
                                        </q-item-section>
                                        <q-item-section v-else>
                                            {{ localizeText('Отключить уведомления о звонке') }}
                                        </q-item-section>
                                    </q-item>
                                    <q-item clickable v-close-popup>
                                        <q-item-section @click="setSearchInChatMode">
                                            {{ localizeText('Поиск') }}
                                        </q-item-section>
                                    </q-item>
                                    <q-item
                                        clickable
                                        v-if="!isCallPage && isMobileView && !isArchiveChat && !isSelfChat"
                                    >
                                        <q-item-section @click="openCallInNewTab">
                                            {{ localizeText('Позвонить') }}

                                            <q-tooltip v-if="!chatInfo.isCallsEnabled">
                                                {{ localizeText('Звонки недоступны, т.к. превышен лимит в 500 участников чата') }}
                                            </q-tooltip>
                                        </q-item-section>
                                    </q-item>
                                    <q-item clickable v-if="!isCallPage && chatInfo && chatInfo.canPinChat && !isArchiveChat">
                                        <q-item-section @click="pinChat(false)" v-if="chatIsPinned">
                                            {{ localizeText('Открепить') }}
                                        </q-item-section>
                                        <q-item-section @click="pinChat(true)" v-else>
                                            {{ localizeText('Закрепить') }}
                                        </q-item-section>
                                    </q-item>
                                    <q-item clickable v-if="!isCallPage && isCanEditChat" @click="editChat">
                                        <q-item-section>
                                            {{ localizeText('Редактировать') }}
                                        </q-item-section>
                                    </q-item>
                                    <q-item clickable v-close-popup>
                                        <q-item-section @click="showChatFiles">
                                            {{ localizeText('Прикрепленные файлы') }}
                                        </q-item-section>
                                    </q-item>
                                    <q-item clickable v-if="!isSelfChat">
                                        <q-item-section @click="showCallRecords">
                                            {{ localizeText('Записи звонков') }}
                                        </q-item-section>
                                    </q-item>
                                    <q-item clickable v-if="!isCallPage && chatInfo.canEdit && !isSelfChat && !isPrivateChat && !chatInfo.isDefaultRoom && !isArchiveChat">
                                        <q-item-section @click="isShowArchiveModal = true">
                                            {{ localizeText('Перенести в архив') }}
                                        </q-item-section>
                                    </q-item>
                                    <q-item
                                        clickable
                                        v-if="chatInfo.canEdit && (isMobileView || modeChat === 'inline') && !isSelfChat && !isPrivateChat && isArchiveChat"
                                    >
                                        <q-item-section @click="isShowArchiveModal = true">
                                            {{ localizeText('Разархивировать чат') }}
                                        </q-item-section>
                                    </q-item>
                                    <q-item
                                        clickable
                                        v-if="chatInfo.canEditDiscipline && isDisciplineChat"
                                        @click="isShowAddChatRoomModal = true"
                                    >
                                        <q-item-section>
                                            {{ localizeText('Добавить комнату') }}
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-menu>
                        </q-icon>
                    </template>
                </div>
            </template>
        </div>
        <div v-if="isShowSolutionChatTopPanel && !isActivitySolutionPage" class="chat-top-panel flex justify-between items-center">
            <q-icon
                v-if="isMobileView && modeChat === 'page'"
                name="arrow_back"
                size="20px"
                class="q-mr-sm cursor-pointer"
                @click="showChatsPanel"
            />
            <div class="col">
                <div class="ellipsis">{{ selectedSolution?.name }}</div>
                <div class="text-description">{{ localizeText('Обсуждение решения') }}</div>
            </div>
            <OpenNewIcon
                @click="openActivitySolutionPage"
                class="cursor-pointer grey q-ml-md"
            />
        </div>
        <q-separator class="separator" />

        <!-- Компонент для отображения закрепленных сообщений -->
        <pinned-messages
            :can-pinned-message="!!chatInfo && chatInfo.canPinnedMessage"
            @go-to-message="goToMessage"
        />

        <ChatFiles
            ref="chatFilesRef"
            v-if="chatInfo"
            :chat-id="chatInfo.id"
            :key="'chatFiles' + chatInfo.id"
            :chat-info="chatInfo"
            :is-mobile-view="isMobileView"
            @go-to-message="goToMessage"
            @on-message-file-deleted="onMessageFileDeleted" />

        <CallRecords
            ref="callRecordsRef"
            v-if="chatInfo"
            :chat-id="chatInfo.id"
            :key="'callRecords' + chatInfo.id" />

        <edit-chat
            v-if="chatInfo && isShowAddEdinChatModal && !isSolutionChat"
            :chatInfo="chatInfo"
            @on-set-is-show-add-edin-chat-modal="onSetIsShowAddEdinChatModal"
        />

        <q-dialog v-model="isShowArchiveModal">
            <q-card class="confirm-modal q-pa-lg">
                <div class="confirm-modal__title q-mb-md" v-if="isArchiveChat">
                    {{ localizeText('Разархивировать чат') }}
                </div>
                <div class="confirm-modal__title q-mb-md" v-else>
                    {{ localizeText('Перенести в архив') }}
                </div>
                <div class="word-break q-mb-md" v-if="isArchiveChat">
                    {{ localizeText('Вы уверены что хотите разархиваровать чат') }} "{{ chatName }}" ?
                </div>
                <div class="word-break q-mb-md" v-else>
                    {{ localizeText('Вы уверены что хотите перенести в архив чат') }} "{{ chatName }}" ?
                </div>
                <div class="text-right">
                    <q-btn
                        flat
                        :label="localizeText('Закрыть')"
                        color="primary"
                        v-close-popup />
                    <q-btn
                        flat
                        :label="isArchiveChat ? localizeText('Разархивировать чат') : localizeText('Перенести в архив')"
                        @click="archiveChat(!isArchiveChat)"
                        color="primary"
                        class="q-manual-focusable--focused q-ml-md"
                        v-close-popup />
                </div>
            </q-card>
        </q-dialog>
        <q-dialog v-model="isShowMutedPush" @hide="closeModalMutedPush">
            <q-card class="confirm-modal q-pa-lg">
                <div v-if="isCallNotify" class="confirm-modal__title q-mb-md">
                    {{ localizeText('Отключить уведомления о звонке') }}
                </div>
                <div v-else class="confirm-modal__title q-mb-md">
                    {{ localizeText('Отключить уведомления чата') }}
                </div>
                <div class="confirm-modal__body notification-settings-block q-mb-md">
                    <q-radio v-model="typeMuted" :val="MuteChatTime.OneHour" :label="localizeText('На 1 час')" />
                    <br>
                    <q-radio v-model="typeMuted" :val="MuteChatTime.EightHours" :label="localizeText('На 8 часов')" />
                    <br>
                    <q-radio v-model="typeMuted" :val="MuteChatTime.TwentyFourHours" :label="localizeText('На 24 часа')" />
                    <br>
                    <q-radio v-model="typeMuted" :val="MuteChatTime.ThreeDays " :label="localizeText('На 3 дня')" />
                    <br>
                    <q-radio v-model="typeMuted" :val="MuteChatTime.Forever " :label="localizeText('Навсегда')" />
                </div>
                <div class="text-left">
                    <q-btn
                        :label="localizeText('Сохранить')"
                        color="primary"
                        @click="saveTypeMuted"
                        v-close-popup
                    />
                    <q-btn
                        flat
                        :label="localizeText('Отмена')"
                        color="primary"
                        class="q-manual-focusable--focused q-ml-md"
                        v-close-popup
                    />
                </div>
            </q-card>
        </q-dialog>

        <AddChat
            v-if="chatInfo && isShowAddChatRoomModal"
            :discipline-id="chatInfo.disciplineId"
            :need-redirect-to-chat-page="false"
            entity-type="room"
            @on-set-is-show-add-chat-modal="onSetIsShowAddChatModal"
            @on-create-chat="onCreateChatRoom"
        />
    </div>
</template>

<script lang="ts">
    import {
        ChatBaseInfoResponseModel,
        ChatClient,
        ChatFileDto,
        ChatMessageClient,
        ChatType,
        ListChatDto,
        MuteChatTime,
        PhotoEntityType,
        PhotoType,
        RoutePageNameEnum,
        UserBaseInfoDto,
    } from 'src/api/ApiClient';
    import { localize } from 'src/services/LocalizationService';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import ChatFiles from './ChatFiles.vue';
    import { IChatRoom } from 'pages/Main/Chat/types/interfaces';
    import EditChat from 'components/Chat/components/EditChat.vue';
    import CountUnreadedMessages from 'components/CountUnreadedMessages';
    import { Common } from 'src/helpers/Common';
    import ChatMembers from './ChatMembers.vue';
    import CallRecords from './CallRecords.vue';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import CoverUploader from 'components/CoverUploader/CoverUploader.vue';
    import PinnedMessages from 'components/Chat/components/PinnedMessages.vue';
    import ListRooms from 'components/Chat/components/ListRooms.vue';
    import {
        computed,
        defineComponent,
        onBeforeMount,
        PropType,
        ref,
        toRefs,
        watch,
    } from 'vue';
    import { ModeChat } from 'components/Chat/types/enums';
    import { convertChatBaseInfoToInfoListItem, convertChatBaseInfoToListChatDto } from 'pages/Main/Chat/helpers';
    import { useRoute, useRouter, RouteLocationRaw } from 'vue-router';
    import { chatBus } from 'components/EventBuses';
    import { QMenu } from 'quasar';
    import MessagesBlock from 'components/Chat/components/MessagesBlock.vue';
    import { useChatStore } from 'src/store/module-chat';
    import AddChat from 'pages/Main/Chat/components/AddChat.vue';
    import { ChatBusEvents } from 'src/components/EventBuses/emuns';


    export default defineComponent({
        name: 'TopPanel',

        components: {
            AddChat,
            ListRooms,
            PinnedMessages,
            CoverUploader,
            ChatMembers,
            ChatFiles,
            CallRecords,
            EditChat,
            CountUnreadedMessages,
        },

        emits: [
            'show-chats-panel',
            'on-select-room',
            'go-to-message',
            'change-state',
            'on-message-file-deleted',
        ],

        props: {
            // Модель базовой информации о чате
            chatInfo: {
                type: Object as PropType<ChatBaseInfoResponseModel | undefined | null>,
                required: false,
                default: undefined,
            },
            // Режим отображения чата
            modeChat: {
                type: String,
                default: ModeChat.Inline,
            },
            // Чат закреплен в левой панели на странице чатов
            isPinnedChat: {
                type: Boolean,
                default: false,
            },
            // Архивный чат, показывается в левой панели на странице чатов
            isArchiveChat: {
                type: Boolean,
                default: false,
            },
            // Мобильный вид чата
            isMobileView: {
                type: Boolean,
                default: false,
            },
            // Включена ли взаимопроверка, передаётся в чате решений
            isMutualCheck: {
                type: Boolean,
                default: false,
            },
            // если вид от препода - это студент
            // с которым он ведёт переписку
            // передаётся в чате решений в случае
            student: {
                type: Object as PropType<UserBaseInfoDto | undefined | null>,
            },
            // Данные пользователя для которого нужно будет создать приватный чат
            userDataForNewPrivateChat: {
                type: Object as PropType<UserBaseInfoDto | undefined | null>,
            },
            // Является ли чат чатом решения
            isActivitySolutionChat: {
                type: Boolean,
                default: false,
            },
            //Замьючена ли чат секция или дисциплина, в которой находится чат
            isChatInMutedSectionOrDiscipline: {
                type: Boolean,
                required: true,
            },
            messagesBlockRef: {
                type: Object as PropType<InstanceType<typeof MessagesBlock> | undefined | null>,
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            const $router = useRouter();
            const $route = useRoute();
            const chatStore = useChatStore();

            const chatMembersRef = ref<InstanceType<typeof ChatMembers>>();
            const listRoomsRef = ref<QMenu>();
            const chatFilesRef = ref<InstanceType<typeof ChatFiles>>();
            const callRecordsRef = ref<InstanceType<typeof CallRecords>>();
            const chatIsPinned = ref<boolean>(false);
            const isShowArchiveModal = ref<boolean>(false);
            const isShowAddEdinChatModal = ref<boolean>(false);

            const chatInfoRef = toRefs(props.chatInfo || {} as ChatBaseInfoResponseModel);

            // Флаг ставится в true когда издёт запрос на создание чата
            let isLoadingCreateChatRequest = false;

            // поле для поиска по сообщениям
            const searchText = ref<string>('');

            const rooms = ref<IChatRoom[]>([]);
            const selectedRoom = ref<IChatRoom | undefined | null>(null);

            // Модальное окно Отключение уведомление о звонках
            const isShowMutedPush = ref<boolean>(false);

            // Отключение уведомления у звонка или у сообщения
            const isCallNotify = ref<boolean>(false);

            // Время выключения уведомлений
            const typeMuted = ref<MuteChatTime>(MuteChatTime.OneHour);

            // Флаг показа модального окна добавления комнаты
            const isShowAddChatRoomModal = ref<boolean>(false);

            const isSelfChat = computed<boolean>(() => {
                return props.chatInfo ? props.chatInfo.type === ChatType.Self : false;
            });

            const isPrivateChat = computed<boolean>(() => {
                return props.chatInfo ? props.chatInfo.type === ChatType.Private : false;
            });

            const isDisciplineChat = computed<boolean>(() => {
                return props.chatInfo ? props.chatInfo.type === ChatType.Discipline || props.chatInfo.type === ChatType.Solution : false;
            });

            const isSolutionChat = computed<boolean>(() => {
                return props.chatInfo ? props.chatInfo.type === ChatType.Solution : props.isActivitySolutionChat;
            });

            const isShowCallIconIfDisciplineChat = computed<boolean>(() => {
                return props.chatInfo !== undefined &&
                    props.chatInfo !== null &&
                    ((props.chatInfo.type === ChatType.Discipline && props.chatInfo.canEditDiscipline) ||
                        props.chatInfo.type !== ChatType.Discipline);
            });

            const isShowBackArrow = computed<boolean>(() => {
                // Показываем кнопку "Назад" на странице чатов в мобильном отображении
                return (props.isMobileView && props.modeChat === ModeChat.Page)
                    // В мобильном отображении чата звонка
                    || (isCallPage.value && props.isMobileView)
                    // В режиме поиска в миничате
                    || (isSearchMode.value && props.modeChat === ModeChat.Inline)
                    // и в мобильном отображении в чате решения на странице решений
                    || (props.isMobileView && isSolutionChat.value && isActivitySolutionPage.value);
            });

            const isShowMembersBlock = computed<boolean>(() => {
                if (props.chatInfo) {
                    // В чате решения блок с участниками показывает только на странице решения
                    // и для тех, что может смотреть другие решения
                    // для обычного студента там будет просто строка "Обсуждение решения"
                    if (isSolutionChat.value) {
                        return isActivitySolutionPage.value && !props.student;
                    } else {
                        // На странице чатов показывает не в мобильно отображении
                        return isChatWithMembers.value && !props.isMobileView && props.modeChat === ModeChat.Page;
                    }
                } else {
                    return false;
                }
            });

            // Проверяем на то, может ли в чате быть много участников
            const isChatWithMembers = computed<boolean>(() => {
                if (props.chatInfo) {
                    return props.chatInfo.type === ChatType.Discipline || props.chatInfo.type === ChatType.Group || props.chatInfo.type === ChatType.Solution;
                } else {
                    return false;
                }
            });

            const isActivitySolutionPage = computed<boolean>(() => {
                return $route.name === Common.getRouteName(RoutePageNameEnum.ActivitySolution);
            });

            // Мы не можем редактировать чат дефолтной комнаты
            // архивный, чат решения, чат с самим собой и приватный
            const isCanEditChat = computed<boolean>(() => {
                if (props.chatInfo?.type === ChatType.Group) {
                    return props.chatInfo.canEdit;
                }

                return !!props.chatInfo &&
                    props.chatInfo.canEdit &&
                    !props.chatInfo.isDefaultRoom &&
                    !props.isArchiveChat &&
                    !isSelfChat.value &&
                    !isSolutionChat.value &&
                    !isPrivateChat.value;
            });

            const chatName = computed<string>(() => {
                if (props.chatInfo) {
                    if (props.chatInfo.type === ChatType.Self) {
                        return localizeText('Личный чат для заметок');
                    } else {
                        return props.chatInfo.nameChat || '';
                    }
                } else {
                    return '';
                }
            });

            // Показываем аватарки первых трех пользователей, у кого они есть
            const firstTreeUsersLogo = computed<{ avatar?: string, name?: string }[]>(() => {
                if (props.chatInfo) {
                    let userAvatars: { avatar?: string, name?: string }[] = [];
                    userAvatars = props.chatInfo.userAvatars.photoPaths?.map((x: string) => {
                        return {
                            avatar: x,
                        };
                    }) || [];

                    if (userAvatars.length < 3) {
                        props.chatInfo.userAvatars.names?.forEach((x: string) => {
                            userAvatars.push({ name: x });
                        });
                    }

                    return userAvatars;
                } else {
                    return [];
                }
            });

            // Включен ли режим поиска
            const isSearchMode = computed<boolean>(() => {
                return chatStore.isSearchInChatMode;
            });

            const studentUserPageUrl = computed<string>(() => {
                if (props.student) {
                    const path = $router.resolve({
                        name: Common.getRouteName(RoutePageNameEnum.UserInfo),
                        params: { id: props.student.id.toString() },
                    });
                    return path.path;
                } else {
                    return '';
                }
            });

            const userPageUrl = computed<string>(() => {
                if (props.chatInfo?.userId) {
                    const path = $router.resolve({
                        name: Common.getRouteName(RoutePageNameEnum.UserInfo),
                        params: { id: props.chatInfo.userId.toString() },
                    });
                    return path.path;
                } else {
                    return '';
                }
            });

            const disciplinePageUrl = computed<string>(() => {
                if (props.chatInfo?.disciplineId) {
                    const path = $router.resolve({
                        name: Common.getRouteName(RoutePageNameEnum.DisciplineInfo),
                        params: { id: props.chatInfo.disciplineId.toString() },
                    });
                    return path.path;
                } else {
                    return '';
                }
            });

            // Находимся ли мы на странице звонка
            const isCallPage = computed<boolean>(() => {
                return $route.name === Common.getRouteName(RoutePageNameEnum.CallEnter);
            });

            // Выбранный чат выбранного решения задания
            const selectedSolutionChat = computed<ListChatDto | null>(() => {
                return chatStore.selectedSolutionChat;
            });

            // Выбранное решение задания
            const selectedSolution = computed<ListChatDto | null>(() => {
                return chatStore.selectedSolution;
            });

            // отображаем или нет панель с решением
            const isShowSolutionChatTopPanel = computed<boolean | null>(() => {
                return !!selectedSolution.value && isSolutionChat.value;
            });

            const isHiddenTopPanel = computed<boolean | null>(() => {
                return $route.name === Common.getRouteName(RoutePageNameEnum.Chat) && isSolutionChat.value;
            });

            const chatInfoComp = computed<ChatBaseInfoResponseModel | undefined | null>(() => {
                return props.chatInfo;
            });

            watch(chatInfoComp, (chatInfo?: ChatBaseInfoResponseModel | undefined | null) => {
                if (props.isActivitySolutionChat && chatInfo) {
                    chatStore.loadPinnedMessages(chatInfo.id);
                    const solutionChat: ListChatDto | null = chatStore.selectedSolutionChat;

                    if (solutionChat?.chatId !== chatInfo.id) {
                        loadRoomInfo(chatInfo);
                    }
                }
            });

            function showChatsPanel(): void {
                if (!isSearchMode.value && props.modeChat === ModeChat.Page) {
                    chatStore.isShowChatsPanel = true;
                }

                closeSearchMode();

                // Инициируем событие, что бы можно было перехватить в родительских компонентах
                if (isSolutionChat.value) {
                    context.emit('show-chats-panel');
                }
            }

            function closeSearchModeAndEmitChangeState(): void {
                if (!isSearchMode.value) {
                    context.emit('change-state');
                }

                closeSearchMode();
            }

            // Включает режим поиска на странице чатов и в самом чате
            // В мобильной версии или сайд чате поиск работает только по чату
            // и у него другая логика нежели на старнице чатов
            function setSearchInChatMode(): void {
                chatStore.isSearchInChatMode = true;

                // так как поле поиска находится вообще на другой странице
                // приходится искать его через в DOM через JS
                setTimeout(function () {
                    const el: HTMLElement | null = document.querySelector('#search-chats-on-chat-page');

                    if (el) {
                        el.focus();
                    }
                }, 100);
            }

            // Срабатывает при закрытие модального окна по выключению уведомлений
            function closeModalMutedPush(): void {
                isCallNotify.value = false;
                typeMuted.value = MuteChatTime.OneHour;
            }

            function openModalMuted(isCall: boolean): void {
                if (!isCall && props.isChatInMutedSectionOrDiscipline) {
                    return;
                }

                // Проверряем выключены ли уведомления
                // Есил выключены просто включаем их
                if (isCall && props.chatInfo!.isCallsMuted) {
                    muteCall();
                    return;
                }

                if (!isCall && props.chatInfo!.isMuted) {
                    muteChat();
                    return;
                }

                isShowMutedPush.value = true;
                isCallNotify.value = isCall;
            }

            // Сохраняем время выключение уведмолений
            function saveTypeMuted(): void {
                if (isCallNotify.value) {
                    muteCall();
                    return;
                }

                muteChat();
            }

            // Включение и выключение уведомлений о звонке
            function muteCall(): void {
                const chatApi = new ChatClient(getApiClientInitialParams());

                if (props.chatInfo) {
                    if (props.chatInfo.isCallsMuted) {
                        chatApi.changeUserCallNotificationSettings(props.chatInfo.id, MuteChatTime.Unmute);
                    } else {
                        chatApi.changeUserCallNotificationSettings(props.chatInfo.id, typeMuted.value);
                    }

                    chatInfoRef.isCallsMuted.value = !props.chatInfo.isCallsMuted;
                }
            }

            // Отключить/включить уведомления чата
            function muteChat(): void {
                const chatApi = new ChatClient(getApiClientInitialParams());
                if (props.chatInfo) {
                    if (props.chatInfo.isMuted) {
                        chatApi.changeUserNotificationSettings(props.chatInfo.id, MuteChatTime.Unmute);
                    } else {
                        chatApi.changeUserNotificationSettings(props.chatInfo.id, typeMuted.value);
                    }

                    chatStore.setMutedToChat({
                        id: props.chatInfo.id,
                        isMuted: !props.chatInfo.isMuted,
                        type: props.chatInfo.type,
                        disciplineId: props.chatInfo.disciplineId,
                    });

                    chatInfoRef.isMuted.value = !props.chatInfo.isMuted;
                }
            }

            // Закрепить чат в левой панели на странице чатов и в топ панели миничата
            async function pinChat(isPin: boolean): Promise<void> {
                if (!props.chatInfo) {
                    return;
                }
                const result = await new ChatClient(getApiClientInitialParams()).pinChat(props.chatInfo.id, !isPin);
                if (!result.isSuccess) {
                    NotifyErrors(result);
                }
                chatIsPinned.value = !chatIsPinned.value;
                chatBus.emit(ChatBusEvents.SwitchChatPinnedState, {
                    chatDto: convertChatBaseInfoToInfoListItem(props.chatInfo),
                    isPinned: chatIsPinned.value
                });
            }

            // Архивировать/разархивировать чат
            async function archiveChat(isArchive: boolean): Promise<void> {
                if (!props.chatInfo) {
                    return;
                }

                const result = await new ChatClient(getApiClientInitialParams()).archive(props.chatInfo.id, !isArchive);

                if (result.isSuccess) {
                    chatInfoRef.isArchive.value = !props.chatInfo.isArchive;
                    archivateProcess(isArchive);
                } else {
                    NotifyErrors(result);
                }
            }

            function archivateProcess(isArchive: boolean): void {
                if (!props.chatInfo) {
                    return;
                }

                // если мы находимся на странице чатов работаем со стором чатов
                if ($route.name === Common.getRouteName(RoutePageNameEnum.Chat)) {
                    // если чат - это комната дисциплины
                    // то обновляем её в этой дисциплине
                    if (props.chatInfo.disciplineChatId) {
                        if (isArchive) {
                            const listChat = getConvertedListChatDto()!;
                            chatStore.removeRoomFromDisciplineChat(listChat);
                            chatStore.addArchiveRoomToDisciplineChat(listChat);
                        } else {
                            const listChat = getConvertedListChatDto()!;
                            chatStore.unArchiveRoomFromDisciplineChat(listChat);
                            chatStore.addRoomToDisciplineChat(listChat);
                        }
                    } else {
                        // обычные чаты
                        if (isArchive) {
                            const listChat = getConvertedListChatDto()!;
                            chatStore.removeChat(listChat);
                            chatStore.addArchiveChats([listChat]);
                        } else {
                            const listChat = getConvertedListChatDto()!;
                            chatStore.unArchiveChat(listChat);
                            chatStore.addChats([listChat]);
                        }
                    }
                } else {
                    // Если мы находися в миничате

                    // если чат - это комната дисцилины
                    // то обновляем её в этой дисциплине
                    if (props.chatInfo.disciplineChatId) {
                        if (isArchive) {
                            const index = rooms.value.findIndex((x: ListChatDto) => x.chatId === props.chatInfo?.id);

                            if (index !== -1) {
                                rooms.value.splice(index, 1);
                            }
                        } else {
                            const index = rooms.value.findIndex((x: ListChatDto) => x.chatId === props.chatInfo?.id);

                            if (index === -1 && getConvertedListChatDto()) {
                                rooms.value.push(getConvertedListChatDto()!);
                            }
                        }
                    } else {
                        // остальные чаты
                        if (isArchive) {
                            chatBus.emit(ChatBusEvents.RemoveChatFromMiniChats, props.chatInfo.id);
                        } else {
                            // Получаем последне сообщение чтобы установить lastMessageDateTime
                            // Чтобы в миничате корректно отработала сортировка
                            const lastMessage = props.messagesBlockRef?.getLastMessage() ?? null;
                            const miniChat = convertChatBaseInfoToInfoListItem(props.chatInfo);
                            miniChat.lastMessageDateTime = lastMessage ? lastMessage.createDateTime : null;
                            chatBus.emit(ChatBusEvents.AddChatsToMiniChats, [miniChat]);
                        }
                    }
                }
            }

            function openCallInNewTab(): void {
                if (!props.chatInfo) {
                    return;
                }

                if (!props.chatInfo.isCallsEnabled || props.chatInfo.isMigrationExam) {
                    return;
                }

                const path = $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.CallEnter),
                    params: { id: props.chatInfo.id.toString() },
                });

                window.open(path.href + '?start=true', '_blank');
            }

            function editChat(): void {
                isShowAddEdinChatModal.value = true;
            }

            function onSetIsShowAddEdinChatModal(isView: boolean): void {
                isShowAddEdinChatModal.value = isView;
            }

            function getDisabledNotificationText(): string {
                if (!props.chatInfo) {
                    return '';
                }

                switch (props.chatInfo.type) {
                    case ChatType.Private:
                        return 'Уведомления отключены для всех личных чатов';
                    case ChatType.Group:
                        return 'Уведомления отключены для всех групповых чатов';
                    case ChatType.Discipline:
                    case ChatType.Solution:
                        if (chatStore.isDisciplineChatsMuted) {
                            return 'Уведомления отключены для всех дисциплин';
                        } else {
                            return 'Уведомления отключены для всех чатов дисциплины';
                        }
                    default:
                        return '';
                }
            }

            //region Функционал поиска
            function closeSearchMode(isClearSearchField: boolean = false): void {
                searchText.value = '';
                chatStore.searchedMessageIds = null;

                if (!isClearSearchField) {
                    chatStore.isSearchInChatMode = false;
                }
            }

            async function searchMessages(): Promise<void> {
                if (!props.chatInfo) {
                    return;
                }

                const result = await new ChatMessageClient(getApiClientInitialParams()).searchMessagesInMobileChat(props.chatInfo.id, searchText.value);

                if (result.isSuccess) {
                    chatStore.searchedMessageIds =  result.entity.messageIds || [];
                }
            }

            //endregion

            //region Работа с комнатами

            // Получить вложенные комнаты дисциплины (за исключением комнаты по умолчанию)
            async function loadRooms(idChat?: number | null): Promise<void> {
                let chatId: number = 0;
                rooms.value = [];

                if (idChat) {
                    chatId = idChat;
                } else if (isSolutionChat.value) {
                    chatId = chatStore.selectedRoomId;
                } else if (props.chatInfo) {
                    chatId = props.chatInfo.id;
                }

                if (!chatId) {
                    return;
                }

                const result = await new ChatClient(getApiClientInitialParams()).getChatRooms(chatId, false);

                if (result.isSuccess) {
                    rooms.value = (result.entity.chats || []).map((x: ListChatDto) => {
                        return {
                            ...x,
                            disciplineChatId: chatId,
                            isOpen: false,
                            isLoading: false,
                            canLoadSolutionChats: true,
                            currentSolutionsChatsPage: 0,
                            solutionChats: [],
                        };
                    });

                    if (props.chatInfo?.type === ChatType.Discipline) {
                        selectedRoom.value = rooms.value.find((x: IChatRoom) => x.chatId === chatId);
                    }

                    chatStore.selectedRoomId = chatId;
                } else {
                    NotifyErrors(result);
                }
            }

            function selectRoom(chat: IChatRoom): void {
                if (chat.type === ChatType.Solution) {
                    selectedRoom.value = null;
                } else {
                    selectedRoom.value = rooms.value.find((x: IChatRoom) => x.chatId === chat.chatId);
                }

                context.emit('on-select-room', chat.chatId);
                listRoomsRef.value?.hide();
            }

            function getConvertedListChatDto(): IChatRoom | null {
                if (!props.chatInfo) {
                    return null;
                }

                const model = convertChatBaseInfoToListChatDto(props.chatInfo);

                return {
                    ...model,
                    disciplineChatId: props.chatInfo.disciplineChatId,
                };
            }

            function getDeclinationOfMembers(count: number): string {
                return count.cased('участник', 'участника', 'участников', false);
            }

            // В нормальной ситуации мы не можем сразу открыть чат решения в мини-чате
            // сначала мы выбираем чат дефолтной комнаты и потом из списка выбираем чат решения
            // но мы можем открыть его по оповещению о новом сообщении и тогда у нас есть только chatId
            // Поэтому мы восстанавливаем все недостающие данные
            async function loadRoomInfo(chatInfo: ChatBaseInfoResponseModel): Promise<void> {
                const result = await new ChatClient(getApiClientInitialParams()).getDefaultInfoChatByActivityId(chatInfo.activityId!);

                if (result.isSuccess) {
                    await loadRooms(result.entity.defaultChatInfo.chatId);
                    const solutionChat = getConvertedListChatDto();
                    const activityChat = rooms.value.find((x: IChatRoom) => x.activityId === chatInfo.activityId);
                    chatStore.selectedSolutionChat = solutionChat;
                    chatStore.selectedSolution = activityChat ?? null;
                }
            }

            // Обработчик события из ChatFiles - чтобы перейти к сообщению, в котором этот файл
            function goToMessage(messageId: number): void {
                // вызываем событие чтобы родительский компонент его обработал
                context.emit('go-to-message', messageId);
            }

            // Обработчик события из ChatFiles - чтобы обновить сообщение, в котором этот файл удалили
            function onMessageFileDeleted(file: ChatFileDto): void {
                // вызываем событие чтобы родительский компонент его обработал
                context.emit('on-message-file-deleted', file);
            }

            function showChatFiles(): void {
                chatFilesRef.value?.show();
            }

            function showCallRecords(): void {
                callRecordsRef.value?.show();
            }

            function showChatMembers(): void {
                chatMembersRef.value?.show(props.chatInfo!.id);
            }

            function openActivitySolutionPage(): void {
                if (selectedSolution.value) {
                    const options: RouteLocationRaw = {
                        name: Common.getRouteName(RoutePageNameEnum.ActivitySolution),
                        params: {
                            id: selectedSolution.value.activityId!.toString(),
                        },
                    };

                    if (selectedSolutionChat.value) {
                        options.query = {
                            userName: encodeURIComponent(selectedSolutionChat.value.name),
                        };
                    }

                    const path = $router.resolve(options);
                    window.open(path.fullPath, '_blank');

                    // Посылаем событие, что бы компонент BottomPanel
                    // сохранил черновик чата при открытии страницы решения в новом окне.
                    chatBus.emit(ChatBusEvents.SaveDraftMessage);
                }
            }

            // При создании комнаты сразу добавляем её в список
            function onCreateChatRoom(chat: IChatRoom): void {
                selectedRoom.value = chat;
                context.emit('on-select-room', chat.chatId);
            }

            // Создать приватный чат и позвонить в него
            async function createChatAndCall(): Promise<void> {
                if (!props.userDataForNewPrivateChat || isLoadingCreateChatRequest) {
                    return;
                }

                isLoadingCreateChatRequest = true;
                const result = await new ChatClient(getApiClientInitialParams()).getOrCreateChatWithUser(props.userDataForNewPrivateChat.id);

                if (result.isSuccess) {
                    context.emit('on-select-room', result.entity);

                    const path = $router.resolve({
                        name: Common.getRouteName(RoutePageNameEnum.CallEnter),
                        params: { id: result.entity.toString() },
                    });

                    window.open(path.href + '?start=true', '_blank');
                } else {
                    NotifyErrors(result);
                }

                isLoadingCreateChatRequest = false;
            }

            // Обработка события закрытия модельного окна
            function onSetIsShowAddChatModal(isShow: boolean): void {
                isShowAddChatRoomModal.value = isShow;
            }

            function localizeText(text: string, formatterArguments: Record<string, string | number> | null = null): string {
                return localize(text, formatterArguments);
            }

            onBeforeMount(() => {
                chatStore.pinnedMessages = [];

                if (props.chatInfo) {
                    if (isSolutionChat.value && !selectedSolution.value && !isActivitySolutionPage.value) {
                        loadRoomInfo(props.chatInfo);
                    }

                    chatStore.loadPinnedMessages(props.chatInfo.id);

                    // для чата дисциплины в сайд чате и мобильном чате
                    // показывается селект и дисциплины
                    if (props.chatInfo.type === ChatType.Discipline && props.modeChat === ModeChat.Inline) {
                        loadRooms();
                    }

                    chatIsPinned.value = props.isPinnedChat;
                }

                if (props.isActivitySolutionChat && !isActivitySolutionPage.value && props.modeChat === ModeChat.Inline) {
                    loadRooms();
                }
            });

            return {
                chatMembersRef,
                listRoomsRef,
                chatFilesRef,
                callRecordsRef,
                ChatType,
                MuteChatTime,
                PhotoEntityType,
                PhotoType,
                CustomAvatarColors,
                isShowAddChatRoomModal,
                isShowArchiveModal,
                isShowAddEdinChatModal,
                searchText,
                rooms,
                selectedRoom,
                isShowMutedPush,
                isCallNotify,
                typeMuted,
                isSelfChat,
                isPrivateChat,
                isDisciplineChat,
                isSolutionChat,
                isShowCallIconIfDisciplineChat,
                isShowMembersBlock,
                isShowBackArrow,
                isActivitySolutionPage,
                isCanEditChat,
                chatName,
                firstTreeUsersLogo,
                isSearchMode,
                studentUserPageUrl,
                userPageUrl,
                disciplinePageUrl,
                isCallPage,
                selectedSolutionChat,
                selectedSolution,
                isShowSolutionChatTopPanel,
                isHiddenTopPanel,
                showChatsPanel,
                setSearchInChatMode,
                closeModalMutedPush,
                openModalMuted,
                saveTypeMuted,
                pinChat,
                archiveChat,
                openCallInNewTab,
                editChat,
                onSetIsShowAddEdinChatModal,
                closeSearchMode,
                closeSearchModeAndEmitChangeState,
                searchMessages,
                selectRoom,
                getDeclinationOfMembers,
                goToMessage,
                onMessageFileDeleted,
                showChatFiles,
                showCallRecords,
                showChatMembers,
                openActivitySolutionPage,
                localizeText,
                getShortUserName: Common.getShortUserName,
                getDisabledNotificationText,
                chatIsPinned,
                onCreateChatRoom,
                onSetIsShowAddChatModal,
                createChatAndCall,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .all-count-unreaded-messages {
        position: absolute;
        right: 10px;
        top: 15px;
    }

    .chat-top-panel {
        position: relative;
        z-index: 11;
        padding: 12px 16px;
        background-color: white;

        &.pinned-messages {
            background: #FFFAE1;
        }

        .rounded-borders {
            border-radius: 4px;
        }

        .chat-name {
            word-break: break-word;
        }

        .discipline-name {
            word-break: break-word;
            font-size: 12px;
            margin-top: -5px;
        }

        .avatars-block {
            margin-right: 12px;

            .user-logo {
                margin-right: -12px;
                background-color: $shade-1;

                img {
                    border: 2px solid #FFFFFF;
                }
            }
        }

        .call-icon {
            ::v-deep(path) {
                stroke: $shade-7;
            }
        }

        .select-rooms {
            position: relative;
            width: 100%;
            max-width: 400px;
            margin: -8px 0;
            border-radius: 4px;
            background-color: $shade-2;

            .sub-item {
                font-size: 12px;
                line-height: 17px;
            }
        }
    }

    .separator {
        position: relative;
        z-index: 11;
    }
</style>

<style lang="scss">
    .notification-settings-block {
        margin-left: -10px;
    }

    .q-menu {
        &.mobile-select-rooms,
        &.list-select-rooms {
            .count-unreaded-messages {
                position: absolute;
                top: 8px;
                left: -12px;
            }
        }

        &.mobile-select-rooms {
            left: 0 !important;
            width: 100%;
        }

        &.list-select-rooms {
            .q-list {
                max-width: 400px;
                width: 350px;
            }
        }
    }

    .chat-top-panel {
        .q-field--focused {
            .q-field__label {
                display: none;
            }
        }
    }
</style>
