import { LocalizationItemDto } from 'src/api/ApiClient';

// Некоторые строки могут отображаться даже если нет данных локализации,
// например в случае окна с 500 ошибкой - она должна показываться в том числе если
// отвалилися запрос на получение данных, и тк этих даннных нет
// то переводы для некоторых строк храним локально
const localizableStrings: LocalizationItemDto[] = [{
    hash: '',
    origin: 'Авторизация',
    en: 'Authorization'
}, {
    hash: '',
    origin: 'Ошибка сервера',
    en: 'Server error'
}, {
    hash: '',
    origin: 'Упс... Что-то пошло не так!',
    en: 'Oops, something went wrong!'
}, {
    hash: '',
    origin: 'На главную',
    en: 'To the home page'
}];

export default localizableStrings;
