
// Глобальные события чата
export enum ChatBusEvents {
    OnChangeUnreadedCountMessage = 'onChangeUnreadedCountMessage',
    SetChatId = 'setChatId',
    SetPrivateChatUserData = 'setPrivateChatUserData',
    AddChatsToMiniChats = 'addChatsToMiniChats',
    SaveDraftMessage = 'save-draft-message',
    NotSavedDraftOnUnload = 'not-saved-draft-on-unload',
    SwitchChatPinnedState = 'switchChatPinnedState',
    UpdateChatAvatar = 'updateChatAvatar',
    RemoveChatFromMiniChats = 'removeChatFromMiniChats',
    OnMessageReceivedHandler = 'onMessageReceivedHandler',
    OnRemoveMessageReceivedHandler = 'onRemoveMessageReceivedHandler',
    OnEditMessageReceivedHandler = 'onEditMessageReceivedHandler',
    OnSendMessageReactionReceivedHandler = 'onSendMessageReactionReceivedHandler',
    OnRemoveMessageReactionReceivedHandler = 'onRemoveMessageReactionReceivedHandler',
    OnPinMessageReceivedHandler = 'onPinMessageReceivedHandler',
    OnUnpinMessageReceivedHandler = 'onUnpinMessageReceivedHandler',
    OnUpdateReadedMessageHandler = 'onUpdateReadedMessageHandler',
    OnUpdateCountUnreadedMessagesHandler = 'onUpdateCountUnreadedMessagesHandler',
    OnErrorHandler = 'onErrorHandler',
}

// Глобальные события роутера
export enum RouterBusEvents {
    Push = 'push',
    ToLogin = 'toLogin',
}

// Глобальные события звонков
export enum CallBusEvents {
    EndOFCall = 'end-of-call'
}
