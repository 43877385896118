<template>
    <q-page class="bg-shade-10 auth-page row justify-center items-center" :class="{'full-height-page' :isLoading}">
        <div v-if="isLoading" class="text-center q-pt-xl">
            <q-spinner-dots color="primary" size="4em"/>
        </div>
        <template v-else>
            <div class="column">
                <div class="auth-page__top justify-center row items-end">
                    <OdinIcon class="login-odin-icon" aria-hidden="true" />
                    <h1 class="odin-title">{{ localize('Odin') }}</h1>
                </div>
                <q-card class="auth-page__form">
                    <q-tabs
                        v-model="currentTab"
                        active-color="primary"
                        indicator-color="primary"
                    >
                        <q-tab
                            name="login"
                            :label="localize('Войти')"
                            @click="toLogin"
                        />
                        <q-tab
                            name="register"
                            :label="localize('Зарегистрироваться')"
                        />
                    </q-tabs>
                    <q-tab-panels v-model="currentTab">
                        <q-tab-panel name="register">
                            <q-form @keydown.enter="register">
                                <q-input
                                    ref="emailRef"
                                    clearable
                                    v-model="form.email"
                                    type="email"
                                    name="email"
                                    label="Email*"
                                    :rules="[isRequired, isRequiredEmail]"
                                    lazy-rules="ondemand"
                                    :error="Boolean(serverErrorsModel.email)"
                                    :error-message="serverErrorsModel.email"
                                    @update:model-value="serverErrorsModel.email = ''"
                                    :no-error-icon="true"
                                    :hint="localize('Будет отправлено письмо для подтверждения')"
                                    class="email-field q-mb-md"
                                />
                                <input-password
                                    ref="passwordRef"
                                    v-model="form.password"
                                    :label="localize('Пароль*')"
                                    :rules="[isRequired, (val: string | null | undefined) => minLength(val, 10)]"
                                    :error="Boolean(serverErrorsModel.password)"
                                    :error-message="serverErrorsModel.password"
                                    @update:model-value="serverErrorsModel.password = ''"
                                />
                                <input-password
                                    ref="confirmPasswordRef"
                                    v-model="form.confirmPassword"
                                    :label="localize('Повторите пароль*')"
                                    :rules="[isRequired, (val: string | null | undefined) => isEqualPassword(val, form.password)]"
                                    :error="Boolean(serverErrorsModel.confirmPassword)"
                                    :error-message="serverErrorsModel.confirmPassword"
                                    @update:model-value="serverErrorsModel.confirmPassword = ''"
                                />
                                <q-input
                                    ref="lastNameRef"
                                    clearable
                                    v-model="form.lastName"
                                    type="text"
                                    :label="localize('Фамилия*')"
                                    :rules="[isRequired]"
                                    :error="Boolean(serverErrorsModel.lastName)"
                                    :error-message="serverErrorsModel.lastName"
                                    @update:model-value="serverErrorsModel.lastName = ''"
                                    :no-error-icon="true"
                                />
                                <q-input
                                    ref="firstNameRef"
                                    clearable
                                    v-model="form.firstName"
                                    type="text"
                                    :label="localize('Имя*')"
                                    :rules="[isRequired]"
                                    :error="Boolean(serverErrorsModel.firstName)"
                                    :error-message="serverErrorsModel.firstName"
                                    @update:model-value="serverErrorsModel.firstName = ''"
                                    :no-error-icon="true"
                                />
                                <q-input
                                    v-if="!isNeedHideUnrequiredFields"
                                    clearable
                                    v-model="form.middleName"
                                    type="text"
                                    :label="localize('Отчество')"
                                    :no-error-icon="true"
                                    :error="Boolean(serverErrorsModel.middleName)"
                                    :error-message="serverErrorsModel.middleName"
                                    @update:model-value="serverErrorsModel.middleName = ''"
                                />
                                <q-input
                                    v-if="!isNeedHideUnrequiredFields"
                                    clearable
                                    v-model="form.phoneNumber"
                                    type="text"
                                    :label="localize('Телефон')"
                                    :no-error-icon="true"
                                    :error="Boolean(serverErrorsModel.phoneNumber)"
                                    :error-message="serverErrorsModel.phoneNumber"
                                    @update:model-value="serverErrorsModel.phoneNumber = ''"
                                />
                                <birthday-picker
                                    v-if="!isNeedHideUnrequiredFields"
                                    @update-birthday="updateBirthday"
                                    @update-is-valid-date="isInvalidBirthday = !$event"
                                    class="q-mb-md"
                                />
                                <DataSelect
                                    ref="selectedCountryRef"
                                    v-model="selectedCountry"
                                    :load-action="loadCountryAction()"
                                    :label="localize('Страна*')"
                                    :rules="[isRequired]"
                                    :is-required="true"
                                    :error="Boolean(serverErrorsModel.contryId)"
                                    :error-message="serverErrorsModel.contryId"
                                ></DataSelect>

                                <city-select
                                    ref="selectedCityRef"
                                    v-model="selectedCity"
                                    :filter-by-country-id="selectedCountry ? selectedCountry.value : undefined"
                                    :is-required="true"
                                    :rules="[isRequired]"
                                    :error="Boolean(serverErrorsModel.cityId)"
                                    :error-message="serverErrorsModel.cityId"
                                    @on-time-zone="onTimeZone"
                                ></city-select>
                                <time-zone-select
                                    ref="timeZoneRef"
                                    v-model="form.timeZone"
                                    :error="Boolean(serverErrorsModel.timeZone)"
                                    :error-message="serverErrorsModel.cityId"
                                    :rules="[isRequiredID]"
                                    :is-required="true"
                                />
                                <vue-smartcaptcha
                                    :site-key="config.SmartCaptchaSiteKey"
                                    @success="verifyCaptcha"
                                />
                                <div class="text-center q-mt-md" style="line-height: 1;">
                                    <div class="auth-page__link without-border">{{ localize('Нажимая на кнопку “Зарегистрироваться”, вы даете свое') }}</div>
                                    <router-link
                                        :to="getAgreementLink()"
                                        target="_blank"
                                        class="auth-page__link"
                                    >
                                        {{ localize('согласие на обработку персональных данных') }}
                                    </router-link>
                                </div>
                                <div class="row justify-end items-center q-mt-md">
                                    <q-btn
                                        flat
                                        color="primary"
                                        type="link"
                                        class="q-mr-sm"
                                        :to="backLink"
                                    >
                                        {{ localize('Назад') }}
                                    </q-btn>
                                    <q-btn
                                        unelevated
                                        color="primary"
                                        :disable="isInvalidBirthday || !keySmartCaptcha"
                                        :label="localize('Зарегистрироваться')"
                                        @click="register()"
                                    />
                                </div>
                            </q-form>
                        </q-tab-panel>
                    </q-tab-panels>
                </q-card>
            </div>
            <div class="auth-page__bottom row justify-between full-width q-mt-lg">
                <div class="auth-page__support text-center">
                    <div class="auth-page__support_icon">
                        <HelpIcon/>
                    </div>
                    <a href="mailto:support@odin.study" target="_blank" class="link">support@odin.study</a>
                </div>
                <LocaleSettings />
            </div>
        </template>
    </q-page>
</template>

<script lang="ts">
    import { localize } from 'src/services/LocalizationService';
    import ValidationRulesText from 'src/helpers/ValidationRulesText';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { AppConfig, getConfig } from 'src/services/config';
    import {
        AccountClient,
        AccountCreateRequestModel,
        CityClient,
        DropDownItem,
        PagedResultOfDropDownItem,
        ResultOf,
        RoutePageNameEnum,
        TimeZoneDto,
        UserSelfRegClient,
    } from 'src/api/ApiClient';
    import VueSmartcaptcha from 'src/components/VueSmartcaptcha/VueSmartcaptcha.vue';
    import { Notify } from 'quasar';
    import { getServerErrorsModel, handleServerErrors, scrollToFirstInvalidField } from 'src/helpers/serverValidation';
    import { Common } from 'src/helpers/Common';
    import InputPassword from 'components/ui/InputPassword/InputPassword.vue';
    import CitySelect from 'components/ui/selects/CitySelect';
    import DataSelect from 'components/ui/selects/DataSelect/DataSelect.vue';
    import BirthdayPicker from 'components/ui/BirthdayPicker/BirthdayPicker.vue';
    import TimeZoneSelect from 'components/ui/selects/TimeZoneSelect/TimeZoneSelect.vue';
    import {
        computed,
        defineComponent,
        getCurrentInstance,
        onBeforeMount,
        reactive,
        Ref,
        ref,
    } from 'vue';
    import useValidateAllFields from 'src/helpers/custom-hooks/useValidateAllFields';
    import useValidationRules from 'src/helpers/custom-hooks/useValidationRules';
    import { useRoute, useRouter } from 'vue-router';
    import { useAccountStore } from 'src/store/module-account';
    import { useAuthorizationStore } from 'src/store/module-authorization';
    import { useChatStore } from 'src/store/module-chat';
    import LocaleSettings from 'pages/Login/components/LocaleSettings.vue';

    export default defineComponent({
        name: 'Register',

        components: {
            LocaleSettings,
            TimeZoneSelect,
            BirthdayPicker,
            DataSelect,
            VueSmartcaptcha,
            InputPassword,
            CitySelect,
        },

        // eslint-disable-next-line max-lines-per-function
        setup() {
            const app = getCurrentInstance();
            const $router = useRouter();
            const $route = useRoute();
            const chatStore = useChatStore();
            const accountStore = useAccountStore();

            const currentTab: Ref<string> = ref('register');

            // Правила для валидации
            const { isRequired, minLength, isEqualPassword, isRequiredID, isRequiredEmail } = useValidationRules();

            const form: AccountCreateRequestModel = reactive({
                email: '',
                firstName: '',
                lastName: '',
                middleName: '',
                password: '',
                confirmPassword: '',
                birthday: undefined,
                phoneNumber: '',
                cityId: 0,
                contryId: 0,
                timeZone: {
                    id: 'Russian Standard Time',
                    name: '(UTC+03:00) ' + localize('Москва, Санкт-Петербург'),
                }
            });

            const selectedCity = ref<DropDownItem | null>({
                value: 1,
                text: localize('Москва'),
            });

            const selectedCountry = ref<DropDownItem | null>({
                value: 1,
                text: localize('Россия'),
            });

            const serverErrorsModel: Record<string, string> = reactive(getServerErrorsModel(form));

            const keySmartCaptcha = ref<string | null>(null);
            const isLoading = ref<boolean>(false);
            const isInvalidBirthday = ref<boolean>(false);

            // При самомстоятельной регистрации на поток или программу
            // Скрываем необязательные поля
            const isNeedHideUnrequiredFields = ref<boolean>(false);

            // Получить настройки конфигурации приложения
            const config: AppConfig = getConfig();

            // Enum на страницу логина
            const loginPageName: string = Common.getRouteName(RoutePageNameEnum.Login);

            // API получение стран
            const loadCountryAction = (): (search?: string | null | undefined, page?: number | null | undefined) => Promise<ResultOf<PagedResultOfDropDownItem>> => {
                const api = new CityClient(getApiClientInitialParams());
                return api.getCountries.bind(api);
            };

            const backLink = computed<string>(() => {
                if (Number($route.params.cohortId) > 0) {
                    const path = $router.resolve({
                        name: Common.getRouteName(RoutePageNameEnum.CohortRegister),
                        params: { id: String($route.params.cohortId) }
                    });
                    return path.path;
                } else {
                    return '/account/login';
                }
            });

            function onTimeZone(timeZone: TimeZoneDto): void {
                // Если имя не задано, значит в списке таймзон не нашлось соответствия по ID,
                // поэтому сбрасываем значение, чтобы не отправить на бэк кривые данные
                if (!timeZone || !timeZone.name) {
                    form.timeZone = {
                        id: '',
                        name: ''
                    } as TimeZoneDto;
                } else {
                    form.timeZone = timeZone;
                }
            }

            // Обновление поля Дата Рождение
            function updateBirthday(value: string): void {
                form.birthday = value;
                isInvalidBirthday.value = !value;
            }

            // captcha которую ввел пользователь
            function verifyCaptcha(key: string): void {
                keySmartCaptcha.value = key;
            }

            function toLogin(): void {
                $router.push({ name:  Common.getRouteName(RoutePageNameEnum.Login) });
            }

            // Открыть страницу Соглашение
            function getAgreementLink(): string {
                return $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.Agreement),
                    query: { type: 'PersonalData' },
                }).fullPath;
            }

            // Регистрация нового пользователя
            async function register(): Promise<void> {
                const { isInvalid } = useValidateAllFields(app?.refs);

                // Прокрутить страницу до первого не валидного элемента
                if (isInvalid) {
                    // Прокрутить страницу до первого не валидного элемента
                    setTimeout(scrollToFirstInvalidField, 300);
                    return;
                }

                // Проверка на заполненность формы и на капчу
                if (keySmartCaptcha.value) {
                    isLoading.value = true;

                    // записываем выбранное значение для cityId
                    if (selectedCity.value?.value) {
                        form.cityId = selectedCity.value.value;
                    }

                    // записываем выбранное значение для contryId
                    if (selectedCountry.value?.value) {
                        form.contryId = selectedCountry.value.value;
                    }

                    // Регистрация пользователя
                    const request = await new AccountClient(getApiClientInitialParams()).registerAccount(keySmartCaptcha.value, form);

                    if (!request.isSuccess) {
                        handleServerErrors(request, serverErrorsModel);
                    } else {
                        Notify.create({
                            type: 'positive',
                            message: localize('Регистрация прошла успешно. Мы рады приветствовать Вас в LMS Odin!'),
                        });

                        // Записываем токен и получаем данные о пользователе
                        useAuthorizationStore().setToken(request.entity.token);
                        chatStore.loadChatsUnreadedCount();
                        await accountStore.loadAccountInfo();

                        // Данные параметры передаются при самостоятельной регистрации слушателей в поток
                        const cohortId = Number($route.params.cohortId);

                        if (cohortId > 0) {
                            const result: ResultOf<number> = await new UserSelfRegClient(getApiClientInitialParams()).registerForCohort(cohortId);

                            if (!result.isSuccess) {
                                handleServerErrors(result, serverErrorsModel);
                                await $router.push({ name: Common.getRouteName(RoutePageNameEnum.Dashboard) });
                            } else {
                                await toDisciplineInfo(result.entity);
                            }
                        } else {
                            await $router.push({ name: Common.getRouteName(RoutePageNameEnum.Dashboard) });
                        }
                    }

                    isLoading.value = false;
                }
            }

            async function toDisciplineInfo(id: number): Promise<void> {
                await $router.push({
                    name: Common.getRouteName(RoutePageNameEnum.DisciplineInfo),
                    params: { id: id.toString() }
                });
            }

            onBeforeMount(() => {
                if ($route.params.email) {
                    form.email = $route.params.email.toString();
                }

                const cohortId = Number($route.params.cohortId);

                if (cohortId) {
                    isNeedHideUnrequiredFields.value = true;
                }
            });

            return {
                ValidationRulesText,
                config,
                form,
                selectedCity,
                selectedCountry,
                serverErrorsModel,
                keySmartCaptcha,
                isLoading,
                isInvalidBirthday,
                isNeedHideUnrequiredFields,
                loginPageName,
                backLink,
                currentTab,
                minLength,
                isEqualPassword,
                isRequired,
                isRequiredID,
                isRequiredEmail,
                loadCountryAction,
                onTimeZone,
                updateBirthday,
                verifyCaptcha,
                localize,
                toLogin,
                register,
                getAgreementLink,
            };
        }

    });
</script>

<style lang="scss">
    .auth-page {
        flex-direction: column;
        flex-wrap: nowrap;

        .q-tabs__content--align-justify {
            .q-tab {
                width: 50%;
            }
        }
    }

    @media screen and (max-width: 420px) {
        .auth-page {
            .q-tabs__content--align-justify {
                .q-tab:last-child {
                    width: 53%;
                }
            }
        }
    }
</style>

<style lang="scss" scoped src='../Login/style.scss'></style>
