<template>
    <div />
</template>

<script lang="ts">
    import { Result, RoutePageNameEnum } from 'src/api/ApiClient';
    import { Common } from 'src/helpers/Common';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { defineComponent, onBeforeMount } from 'vue';
    import { useRoute, useRouter, RouteLocationRaw, RouteLocationNamedRaw } from 'vue-router';
    import { useAccountStore } from 'src/store/module-account';
    import { useAuthorizationStore } from 'src/store/module-authorization';
    import { useChatStore } from 'src/store/module-chat';

    export default defineComponent({
        name: 'FastLogin',

        // eslint-disable-next-line max-lines-per-function
        setup() {
            const $router = useRouter();
            const $route = useRoute();
            const chatStore = useChatStore();
            const accountStore = useAccountStore();

            // Редирект на дашборд
            async function goToDashboard(): Promise<void> {
                const dashboard = Common.getRouteName(RoutePageNameEnum.Dashboard);
                await $router.push({ name: dashboard });
            }

            // Редирект на страницу информации о потоке
            async function goToCohort(cohortId: number): Promise<void> {
                const cohortInfo = Common.getRouteName(RoutePageNameEnum.CohortInfo);
                await $router.push({
                    name: cohortInfo,
                    params: {
                        id: cohortId
                    }
                });
            }

            // Переход на login форму с указанием редиректа на поток (если нужно)
            async function goToLoginPage(cohortId: number): Promise<void> {
                useAuthorizationStore().setToken('');

                const loginRoute: RouteLocationRaw = {
                    name: Common.getRouteName(RoutePageNameEnum.Login),
                };

                if (cohortId > 0) {
                    const cohortInfoRoute: RouteLocationNamedRaw = {
                        name: Common.getRouteName(RoutePageNameEnum.CohortInfo),
                        params: {
                            id: cohortId
                        }
                    };
                    const redirectStr = $router.resolve(cohortInfoRoute).fullPath;
                    loginRoute.query = { redirect: redirectStr };
                }
                await $router.replace(loginRoute);
            }

            onBeforeMount(async () => {
                // Берем данные из гет параметров, если они есть
                let fcode = '';
                if ($route.query.fcode) {
                    fcode = $route.query.fcode.toString();
                }
                let cohortToRedirect = 0;
                if ($route.query.cohortId) {
                    cohortToRedirect = parseInt($route.query.cohortId.toString());
                }

                // Залогиниться в системе через ссылку быстрого входа
                const result: Result = await useAuthorizationStore().fastLogin(fcode);

                if (result.isSuccess) {
                    // Получаем информацию о пользователе
                    chatStore.loadChatsUnreadedCount();
                    await accountStore.loadAccountInfo();
                    if (cohortToRedirect) {
                        await goToCohort(cohortToRedirect);
                    } else {
                        await goToDashboard();
                    }
                } else {
                    NotifyErrors(result);
                    await goToLoginPage(cohortToRedirect);
                }
            });
        },
    });
</script>
