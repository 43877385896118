<template>
    <q-scroll-area class="scroll-area">
        <q-infinite-scroll @load="onLoad" :offset="180" ref="infinityScrollRef">
            <search-material-item
                v-for="content in contents"
                :key="content.id"
                :item="content"
                @on-item-selected="onItemSelected"/>

            <template v-slot:loading>
                <div class="text-center">
                    <q-spinner-dots color="primary" size="4em"/>
                </div>
            </template>
        </q-infinite-scroll>
    </q-scroll-area>
</template>

<script lang="ts" setup>
    import { Ref, ref, toRefs } from 'vue';
    import { SearchContentDto } from 'src/api/ApiClient';
    import { QInfiniteScroll } from 'quasar';
    import { useSearch } from '../../hooks/useSearch';
    import { SearchEntity } from '../../types/SearchEntity';
    import SearchMaterialItem from './SearchMaterialItem.vue';
    import { SearchTabs } from '../../types/searchTabs';
    // Компонент с бесконечной загрузкой
    // Отображается когда выбран поиск только в материалах
    const props = withDefaults(defineProps<{
        searchText: string;
    }>(), {
        searchText: '',
    });
    const emit = defineEmits<{
        (e: 'on-item-selected'): void;
        (e: 'on-found-items-count', itemsCount: number): void;
        (e: 'show-all', tab: SearchTabs): void;
    }>();

    const contents: Ref<SearchContentDto[]> = ref([]);
    // Ссылка на бесконечный скролл
    const infinityScrollRef = ref<InstanceType<typeof QInfiniteScroll>>();
    const { searchText } = toRefs(props);
    const {
        onLoad,
        onItemSelected,
    } = useSearch(SearchEntity.Materials, true, contents, searchText, emit, infinityScrollRef);
</script>
