<template>
    <search-item
        :avatar-color="CustomAvatarColors.University"
        avatar-text="О"
        :picture-url="item.photoUrl"
        :item-page-url="universityPageUrl"
        :item="item"
        :type="SearchType.University"
        :name="item.name"
        @on-item-selected="$emit('on-item-selected')"
    >
        <template #append>
            <slot name="append"></slot>
        </template>
    </search-item>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import {
        RoutePageNameEnum,
        SearchType,
        SearchUniversityDto,
    } from 'src/api/ApiClient';
    import { Common } from 'src/helpers/Common';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import { useGetEntityPageUrl } from '../../hooks/useGetEntityPageUrl';
    import SearchItem from '../SearchItem.vue';


    // Компонент для отображения данных о найденном университете
    const props = defineProps<{
        item: SearchUniversityDto;
    }>();
    // Выбор элемента
    defineEmits(['on-item-selected']);

    const {
        getEntityPageUrl: getUniversityPageUrl,
    } = useGetEntityPageUrl(Common.getRouteName(RoutePageNameEnum.UniversityInfo));

    // Ссылка на роут со страницей пользователя
    const universityPageUrl = computed(() => getUniversityPageUrl(props.item.id.toString()));
</script>
