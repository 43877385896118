import state from './state';
import actions from './actions';
import getters from './getters';
import { defineStore } from 'pinia';

// store для хранения количества непрочитанных оповещений
// и оповещений о текущих звонках

export const useNotificationsStore = defineStore('notificationsStore', {
    state,
    getters,
    actions,
});
