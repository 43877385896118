<template>
    <div v-if="isVisible" @click="close" class="install-app-component full-window">
        <div :style="{ bottom: isShowBlock ? '0' : '-300px' }" @click.stop class="install-app-component__info q-pa-md">
            <div :class="{ 'text-center q-pb-xs' : !isIOS }" class="font-bold text-center q-mb-md">
                {{ localize('Откройте ODIN в приложении') }}
            </div>
            <Icon
                v-if="isIOS"
                name="CloseIcon"
                @click="close"
                class="absolute-top-right q-mt-md q-mr-sm"
            />
            <div v-if="isIOS" class="ios-info-block">
                {{ localize('Установите приложение: нажмите на') }}
                <ShareIcon />
                {{ localize('и затем добавьте его на экран “Домой”') }}
            </div>
            <template v-else>
                <div class="row justify-between items-center q-mb-md">
                    <div class="row items-center">
                        <Icon
                            name="OdinSquareIcon"
                            size="24"
                            use-raw-color
                        />
                        <div>ODIN {{ localize('Приложение') }}</div>
                    </div>
                    <q-btn
                        type="a"
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=study.odin.www.twa"
                        color="primary"
                        @click="isVisible = false"
                    >
                        {{ localize('Открыть') }}
                    </q-btn>
                </div>
                <div class="row justify-between items-center q-mb-xs">
                    <div class="row items-center">
                        <PlanetIcon />
                        <div>{{ localize('Браузер') }}</div>
                    </div>
                    <q-btn
                        flat
                        color="primary"
                        class="q-manual-focusable--focused"
                        @click="close"
                    >
                        {{ localize('Продолжить') }}
                    </q-btn>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { ref, onMounted, onBeforeMount } from 'vue';
    import { localize } from 'src/services/LocalizationService';
    import Bowser from 'bowser';
    import { Common } from 'src/helpers/Common';

    // Для iOS и Android показываем разные действия
    const isIOS = Bowser.getParser(window.navigator.userAgent).getOSName() === 'iOS';

    // Определяем, находимся ли мы в браузере или в WebView/PWA
    // Чтобы этот блок не показывался в приложении
    let isRunningStandalone = window.matchMedia('(display-mode: standalone)').matches;

    // Если false, то дополнительно проверим гет параметры
    if (!isRunningStandalone) {
        isRunningStandalone = Common.getUrlSearchParam(location.search, 'mode') === 'standalone';
    }

    // Показывать или нет предложение с установкой приложения
    // Показывает только для мобильных устройств
    const isVisible = ref<boolean>(window.innerWidth <= 600 && !isRunningStandalone);

    // Анимация появления блока с предложением
    const isShowBlock = ref<boolean>(false);

    function close(): void {
        isVisible.value = false;
        localStorage.setItem('noShowAppInstallPopupExpireAt', Date.now().toString());
    }

    onBeforeMount(() => {
        const timeStamp = localStorage.getItem('noShowAppInstallPopupExpireAt');

        if (timeStamp) {
            const milliseconds = Number(timeStamp);
            // Для iOS не показываем 3 дня, для Android - 30 дней
            const countDays = isIOS ? 3 : 30;
            const countDaysLeft = (Date.now() - milliseconds) / 1000 / 60 / 60 / 24;

            if (countDaysLeft > countDays) {
                localStorage.removeItem('noShowAppInstallPopupExpireAt');
            } else {
                isVisible.value = false;
            }
        }
    });

    onMounted(() => {
        // Показываем блок с действиями
        isShowBlock.value = true;
    });
</script>

<style lang="scss" scoped>
    .install-app-component {
        background-color: rgba(0, 0, 0, 0.2);

        &__info {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: #fff;
            transition: bottom 0.3s;
            border-radius: 24px 24px 0 0;

            ::v-deep(.q-btn) {
                width: 120px;
            }

            ::v-deep(svg) {
                margin-right: 12px;
            }

            .ios-info-block {
                ::v-deep(svg) {
                    vertical-align: bottom;
                    margin-right: 0;
                }
            }
        }
    }
</style>
