import { CallPageStoreInterface } from './state';
import constants from 'pages/Call/data/constants';
import { IInterlocutor } from 'pages/Call/types/interfaces';
import convertInterlocutorFromServer from 'pages/Call/utils/convertInterlocutorFromServer';

const getters = {
    /** Проверка на то, является ли текущий пользователь создателем звонка */
    getUserIsCallCreator: (state: CallPageStoreInterface): boolean => {
        return state.callStartingData?.callCreatorId === state.callStartingData?.userData.id;
    },
    /** Получение ширины экрана занимаемой чатом */
    getChatWidth: (state: CallPageStoreInterface): number => {
        if (state.pageWidth >= constants.pageIsWideThresholdWidth && state.chatIsVisible) {
            return (state.pageWidth * constants.chatWidthInPercent) / 100;
        }
        return state.pageWidth;
    },
    /** Получение ширины экрана занимаемой плиткой с собеседниками звонка */
    getTilesBlockWidth: (state: CallPageStoreInterface): number => {
        if (state.pageWidth >= constants.pageIsWideThresholdWidth && state.chatIsVisible) {
            return state.callPanelWidth;
        }
        return state.pageWidth;
    },
    /** Получить отступ сверху для плитки с собеседниками звонка */
    getTilesBlockTop: (state: CallPageStoreInterface): number => {
        return state.topPanelHeight || 80;
    },
    getInterlocutorByInterlocutorId: (state: CallPageStoreInterface) => (personId: number): IInterlocutor | undefined => {
        return state.interlocutors.find((person: IInterlocutor) => person.personId === personId);
    },
    getCallChatName(state: CallPageStoreInterface) {
        const callStartingData = state.callStartingData;
        if (!callStartingData) {
            return '';
        }
        return state.callStartingData?.callChatName ?? '';
    },
    getIsDisciplineChat(state: CallPageStoreInterface) {
        const callStartingData = state.callStartingData;
        if (!callStartingData) {
            return false;
        }
        return !!state.callStartingData?.callDiscipline;
    },
    isCallManager(state: CallPageStoreInterface) {
        const callStartingData = state.callStartingData;
        if (!callStartingData) {
            return false;
        }
        return state.callStartingData?.userData?.isCallManager ?? false;
    },
    getIsGroupChat(state: CallPageStoreInterface) {
        const callStartingData = state.callStartingData;
        if (!callStartingData) {
            return false;
        }
        return !!state.callStartingData?.userData?.isGroupChat;
    },
    getUserStream: (state: CallPageStoreInterface): MediaStream | null => {
        return state.userData?.stream ?? null;
    },
    getUserVideoTrack: (state: CallPageStoreInterface): MediaStreamTrack | null => {
        return state.userData?.videoTrack ?? null;
    },
    getNeedShowInTileInterlocutors: (state: CallPageStoreInterface) => {
        return state.interlocutors.filter((interlocutor: IInterlocutor) => interlocutor.needShowInTile);
    },
    getInterlocutorByPersonId: (state: CallPageStoreInterface) => (personId: number | string) => {
        return state.interlocutors.find((interlocutor: IInterlocutor) => interlocutor.personId === Number(personId));
    },
    getInterlocutorWithSharing: (state: CallPageStoreInterface): IInterlocutor | undefined => {
        // Ищем пользователя, который шарит экран
        let userWithSharing = state.interlocutors.find(
            (interlocutor: IInterlocutor) => interlocutor.sharingStream && interlocutor.personId !== state.userData.personId,
        );

        // Проверяем, не шарим ли мы сами экран
        if (!userWithSharing && state.userData.sharingStream) {
            userWithSharing = {
                ...convertInterlocutorFromServer(state.callStartingData!.userData),
                sharingStream: state.userData.sharingStream,
            };
        }

        return userWithSharing;
    },
    // Прошло ли 10 минут с момента начала записи звонка (для экзаменов мигрантов)
    // Описываем как функцию, чтобы избежать кэширования
    getMigrationExamMinimalRecordTimePassed: (state: CallPageStoreInterface) => () : boolean => {
        const callStartingData = state.callStartingData;
        if (!callStartingData || !callStartingData.callOrRecordStartDate) {
            return true; // непредвиденная ситуация, поэтому не будем мешать функционалу
        }

        const date = Date.parse(callStartingData.callOrRecordStartDate);
        const now = Date.parse((new Date()).toISOString().replace('Z',''));

        return now - date >= 1000*60*10;
    },
};

export default getters;
