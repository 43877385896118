<template>
    <div
        class="cover-uploader-component"
        :class="{ avatar: isAvatar }"
        :style="{ backgroundColor: !getCover ? uploaderColor : 'transparent' }"
    >
        <form class="cover-uploader-component_form-load-cover" v-if="!getCover && canEdit">
            <input type="file" accept="image/*" :id="'fileCover' + uiId" class="hidden" @change="uploadImage">
            <label :for="'fileCover' + uiId" class="cover-btn">
                <template v-if="!isAvatar">
                    <PhotoIcon />
                    <div>{{localize('Загрузить обложку')}}</div>
                </template>
                <template v-else>
                    <span class="text-white" :style="{ 'font-size': `${fontSize}px` }">
                        {{ text }}
                    </span>
                    <div class="cover-uploader-component__images absolute-button">
                        <div class="cover-uploader-component__images_form absolute-button">
                            <PhotoIcon />
                        </div>
                    </div>
                </template>
            </label>
        </form>
        <div
            v-else
            class="cover-uploader-component__images"
            :style="{backgroundImage: 'url(' + getCover + ')'}"
        >
            <template v-if="!canEdit && !getCover">
                <span class="text-white" :style="{ 'font-size': `${fontSize}px` }">
                    {{ text }}
                </span>
            </template>

            <div
                v-if="canEdit"
                class="cover-uploader-component__images_form"
            >
                <q-btn
                    v-if="!isGroupChat"
                    class="cover-btn red none-style"
                    flat
                    color="red"
                >
                    <TrashIcon @click="removeCover" />
                    <div v-if="!isAvatar" @click="removeCover">
                        {{localize('Удалить обложку')}}
                    </div>
                </q-btn>
                <div
                    class="cover-uploader-component__images_form__field"
                    :class="{ 'q-ma-none q-pa-none no-border-radius': isGroupChat }"
                >
                    <input type="file" accept="image/*" :id="'newFileCover' + uiId" class="hidden" @change="uploadImage">
                    <label :for="'newFileCover' + uiId" class="cover-btn">
                        <icon name="PhotoIcon" :use-raw-color="true"  :color="CustomAvatarColors.User" />
                        <div v-if="!isAvatar">{{localize('Загрузить обложку')}}</div>
                    </label>
                </div>
            </div>
        </div>
        <ImageCropper
            v-model="isShowImageCropper"
            :entity-id="entityId"
            :entity-type="entityType"
            :photo-type="photoType"
            :image-file="imageFile"
            @on-save-image="onSaveImage"
            @update:model-value="isShowImageCropper = $event"
        />
    </div>
</template>

<script lang="ts">
    import { localize } from 'src/services/LocalizationService';
    import { FileParameter, LogoClient, PhotoEntityType, PhotoType } from 'src/api/ApiClient';
    import ImageCropper from 'components/ImageCropper';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import { computed, defineComponent, PropType, ref } from 'vue';
    import { Notify } from 'quasar';
    import { chatBus } from 'components/EventBuses';
    import { useAccountStore } from 'src/store/module-account';
    import { useChatStore } from 'src/store/module-chat';
    import { ChatBusEvents } from 'components/EventBuses/emuns';

    // Компонент для загрузки обложек и логотипов
    export default defineComponent({
        name: 'CoverUploader',

        components: {
            ImageCropper
        },

        emits: ['update-cover', 'on-delete-cover'],

        props: {
            // Размер текста в аватаре если фото не загружено
            fontSize: {
                type: Number
            },
            // Текст, отображаемый если фото не загружено
            text: {
                type: String,
                default: ''
            },
            // Цвет для дефолтного аватара
            uploaderColor: {
                type: String as PropType<CustomAvatarColors>,
                default: ''
            },
            // Ссылка на картинку (лого или обложка)
            cover: {
                type: String as PropType<string | null>,
                default: ''
            },
            // Id сущности для которой загружаем картинку
            entityId: {
                type: Number,
                required: true
            },
            // Тип сущности для которой загружаем картинку
            entityType: {
                type: Number as PropType<PhotoEntityType>,
                required: true
            },
            // Тип загружаемой картинки - лого или обложка
            photoType: {
                type: Number as PropType<PhotoType>,
                required: true
            },
            isMyPage: {
                type: Boolean,
                default: false
            },
            // Может ли пользователь загружать картинки
            canEdit: {
                type: Boolean,
                default: false
            }
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            const chatStore = useChatStore();
            const accountStore = useAccountStore();

            // Флаг показа компонент для обрезки картинок
            const isShowImageCropper = ref<boolean>(false);
            // Находится ли операция удаления в процессе выполнения
            const isRemovingInProgress = ref<boolean>(false);

            const imageFile = ref<FileParameter | null>(null);
            const coverImage = ref<string>('');
            const uiId: string = Math.round(Math.random() * Date.now()).toString();

            const isGroupChat = computed<boolean>(() => {
                return props.uploaderColor === CustomAvatarColors.GroupChat;
            });

            const getCover = computed<string | null>(() => {
                return coverImage.value ? coverImage.value : (props.cover || '');
            });

            const isAvatar = computed<boolean>(() => {
                return props.photoType === PhotoType.Avatar;
            });

            // Сохраняем обрезанную картинку
            function onSaveImage(image: string): void {
                coverImage.value = image;

                // Если это аватар пользователя, обновляем его в сторе
                // чтобы он сразу показался в левом меню
                if (props.entityType === PhotoEntityType.User && isAvatar.value && props.isMyPage && accountStore.accountInfo) {
                    accountStore.accountInfo.photoPreviewPath = image;
                }

                // Если это аватар группового чата, обновляем его в сторе
                if (props.entityType === PhotoEntityType.Chat && isAvatar.value && isGroupChat.value) {
                    const data = {
                        id: props.entityId,
                        image,
                    };

                    chatStore.updateSelectedGroupChatAvatar(data);
                    chatBus.emit(ChatBusEvents.UpdateChatAvatar, data);
                }

                context.emit('update-cover', image);
            }

            function uploadImage(event: Event): void {
                // Загрузка изображения
                if (event.target) {
                    const target = event.target as HTMLInputElement;

                    // Если в Input есть файл
                    if (target.files && target.files.length > 0) {
                        const file = target.files[0];

                        // ODIN-10594
                        if (file.type.search(/svg/gi) > -1) {
                            Notify.create({
                                type: 'negative',
                                message: localize('Файлы svg недоступны для загрузки'),
                            });
                            return;
                        }

                        const reader = new FileReader();

                        reader.onloadend = (e: ProgressEvent<FileReader>) => {
                            imageFile.value = {
                                data: e.target?.result,
                                fileName: file.name
                            };

                            isShowImageCropper.value = true;
                        };

                        reader.readAsDataURL(file);
                    }

                    target.value = '';
                }
            }

            async function removeCover(): Promise<void> {
                if (isRemovingInProgress.value) {
                    return;
                }

                isRemovingInProgress.value = true;

                // Удаление изоображения
                const result = await new LogoClient(getApiClientInitialParams()).remove({
                    id: props.entityId,
                    type: props.entityType,
                    photoType: props.photoType,
                });

                if (result.isSuccess) {
                    coverImage.value = '';
                    context.emit('on-delete-cover');
                } else {
                    NotifyErrors(result);
                }

                isRemovingInProgress.value = false;
            }

            return {
                uiId,
                isShowImageCropper,
                isRemovingInProgress,
                coverImage,
                imageFile,
                isGroupChat,
                getCover,
                isAvatar,
                onSaveImage,
                uploadImage,
                removeCover,
                localize,
                CustomAvatarColors
            };
        }

    });
</script>

<style lang="scss">
    .cover-uploader-component {
        .none-style {
            padding: 0;
            margin: 0;

            .q-focus-helper {
                display: none !important;
            }

            .q-ripple {
                display: none !important;
            }

            .q-btn__wrapper {
                padding: 0;
                margin: 0;

                &:before {
                    display: none !important;
                }
            }
        }
    }
</style>

<style lang="scss" scoped>
    .hidden {
        display: none;
    }

    .cover-btn {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 1em;
        color: $accent;
        cursor: pointer;
        transition: .4s all;

        &:active {
            transform: scale(0.9);
        }

        div {
            margin-left: 10px;
        }
    }

    .cover-uploader-component {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(180deg, rgba(247, 249, 250, 0.2) 27.6%, rgba(180, 185, 209, 0.2) 100%);
        overflow: hidden;

        &.avatar {
            background: $shade-2;
        }

        &.mini {
            .cover-uploader-component__images_form__field {
                margin-left: 4px;
                padding-left: 4px;
            }
        }

        &__images {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                .cover-uploader-component__images_form {
                    opacity: 1;
                }
            }

            &_form {
                display: flex;
                align-items: center;
                background-color: rgba(255, 255, 255, 0.8);
                padding: 0 28px;
                transition: .4s all;
                opacity: 0;

                &__field {
                    position: relative;
                    margin-left: 12px;
                    padding-left: 12px;

                    &:before {
                        content: '';
                        width: 1px;
                        height: 18px;
                        background-color: $shade-4;
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin-top: 4px;
                    }
                }
            }
        }
    }

    .absolute-button {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0 !important;
        padding: 0 !important;
        text-align: center;
        justify-content: center;
    }
</style>
