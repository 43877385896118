<template>
    <q-page>
        <q-card class="server-error-block text-center q-mt-lg">
            <q-card-section class="no-border">
                <div class="title">
                    {{ localize('Проблемы с доступом') }}
                </div>
            </q-card-section>
            <q-separator/>
            <q-card-section>
                <div class="q-mb-md">
                    <img
                        src="~assets/forbidden.png"
                        class="q-mt-sm"
                        :style="{ width: '150px' }"
                        alt=""
                    />
                </div>
                <div class="description text-shade-8">
                    {{ localize('У вас нет прав для доступа к этой странице') }}
                </div>
                <q-btn
                    type="a"
                    href="/"
                    color="primary"
                    class="q-mt-sm q-mb-md"
                >
                    {{ localize('На главную') }}
                </q-btn>
            </q-card-section>
        </q-card>
    </q-page>
</template>

<script lang='ts'>
    /* eslint comment-density/comment-density: 0 */

    import { defineComponent } from 'vue';
    import { localize } from 'src/services/LocalizationService';

    // Страница Forbidden
    export default defineComponent({
        setup() {
            return {
                // Локализация строк
                localize
            };
        }
    });
</script>
