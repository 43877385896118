<template>
    <search-tab
        class="search-materials-tab"
        :infinity-mode="infinityMode"
        :title="localize('Организации')"
        :is-no-items="isNoItems"
        :total-items-count="totalItemsCount"
    >
        <search-universities-infinity-card
            v-if="infinityMode"
            :search-text="searchText"
            @on-found-items-count="onFoundItemsCount"
            @on-item-selected="onItemSelected" />
        <search-universities-static-card
            v-else
            :search-text="searchText"
            @show-all="showAll"
            @on-found-items-count="onFoundItemsCount"
            @on-item-selected="onItemSelected" />
    </search-tab>
</template>

<script lang="ts" setup>
    import SearchUniversitiesInfinityCard
        from 'layouts/Main/components/searchBlock/components/universitiesTab/SearchUniversitiesInfinityCard.vue';
    import SearchUniversitiesStaticCard
        from 'layouts/Main/components/searchBlock/components/universitiesTab/SearchUniversitiesStaticCard.vue';
    import { SearchTabs } from 'layouts/Main/components/searchBlock/types/searchTabs';
    import { toRefs } from 'vue';
    import { useSearchTab } from '../../hooks/useSearchTab';
    import SearchTab from '../SearchTab.vue';

    // Компонент вывода результатов поиска по университетам

    // События которые может эмитить таб поиска
    interface SearchTabEmits {
        (e: 'on-load', count: number): void,
        (e: 'on-item-selected'): void,
        (e: 'show-all', tab: SearchTabs): void,
    }

    const props = withDefaults(defineProps<{
        // Поисковая строка
        searchText: string;
        // Включен ли режим бесконечного скролла
        infinityMode?: boolean;
    }>(), {
        searchText: '',
        infinityMode: false,
    });
    const emit = defineEmits<SearchTabEmits>();
    const { searchText } = toRefs(props);
    const {
        totalItemsCount,
        isNoItems,
        onItemSelected,
        onFoundItemsCount,
        showAll,
        localize,
    } = useSearchTab(emit, searchText);
</script>
