import state from './state';
import actions from './actions';
import getters from './getters';
import { defineStore } from 'pinia';

// store для хранения информации об аккаунте

export const useAccountStore = defineStore('accountStore', {
    state,
    getters,
    actions,
});
