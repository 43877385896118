<template>
    <search-item
        :item="item"
        :name="item.name"
        :avatar-color="CustomAvatarColors.Division"
        :picture-url="item.photoUrl"
        avatar-text="П"
        :type="SearchType.Division"
        :item-page-url="divisionPageUrl"
        @on-item-selected="$emit('on-item-selected')"
    >
        <template #info>
            <div class="info">{{ item.universityShortName }}</div>
        </template>
        <template #append><slot name="append"></slot></template>
    </search-item>
</template>

<script lang="ts" setup>
    import {
        RoutePageNameEnum, SearchDivisionDto, SearchType,
    } from 'src/api/ApiClient';
    import { Common } from 'src/helpers/Common';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import SearchItem from '../SearchItem.vue';
    import { useGetEntityPageUrl } from '../../hooks/useGetEntityPageUrl';
    import { computed } from 'vue';
    // Компонент для отображения данных о найденном подразделении

    const props = defineProps<{
        // Данные отображаемого элемента поиска
        item: SearchDivisionDto;
    }>();
    defineEmits<{
        (e: 'on-item-selected'): void;
    }>();
    const {
        getEntityPageUrl: getDivisionPageUrl,
    } = useGetEntityPageUrl(Common.getRouteName(RoutePageNameEnum.DivisionInfo));
    // Ссылка на страницу подразделения
    const divisionPageUrl = computed(() => getDivisionPageUrl(props.item.id));
</script>
