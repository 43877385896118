<template>
    <q-layout>
        <q-page-container>
            <router-view />
        </q-page-container>
    </q-layout>
</template>

<script lang='ts'>
    import { defineComponent } from 'vue';

    // Родительский layout для страниц авторизации
    export default defineComponent({
        name: 'Login'
    });
</script>
