<template>
    <div
        class="uploaded-files flex column items-end"
        :style="uploadFilesStyles"
    >
        <div class="wrapper full-width">
            <div
                v-for="{
                    id,
                    fileName,
                    secretKey,
                } in slicedFiles"
                :key="id"
                class="file-item"
            >
                <div class="file-item-name ellipsis">
                    {{ fileName }}
                </div>

                <Icon
                    v-if="files.length <= 3"
                    name="TrashIcon"
                    size="16"
                    :style="{ opacity: iconOpacity }"
                    :color="ColorValiablesNameEnum.white"
                    :hover-color="ColorValiablesNameEnum.white"
                    @click.stop.native="$emit('on-deleted-file', secretKey)"
                    class="delete-icon"
                />
            </div>

            <div
                v-if="files.length > 3"
                class="files-count"
            >
                +{{ files.length - 3 }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { IFile } from 'components/Dropzone/interfaces';
    import { Common } from 'src/helpers/Common';
    import { RoutePageNameEnum } from 'src/api/ApiClient';
    import { computed, defineComponent, PropType } from 'vue';
    import { useRoute } from 'vue-router';
    import { ColorValiablesNameEnum } from 'components/ui/Icon/interface';
    import { useChatStore } from 'src/store/module-chat';

    // Компонент для отображения загруженных
    // файлов над панелью с вводом сообщения
    export default defineComponent({
        name: 'UploadedFiles',

        emits: [
            'on-deleted-file'
        ],

        props: {
            // Список файлов для отображения
            files: {
                type: Array as PropType<IFile[]>,
                required: true
            },
            // Мобильный вид чата
            isMobileView: {
                type: Boolean,
                default: false
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props) {
            const chatStore = useChatStore();
            const $route = useRoute();

            const messageSize = computed<number>(() => {
                return chatStore.newMessageBlockHeight;
            });

            // Стили для главного контейнера
            const uploadFilesStyles = computed(() => {
                const defaultMessageHeight = 56;
                const bottom = (messageSize.value || defaultMessageHeight) + 'px';

                return {
                    bottom,
                };
            });

            // Прозрачность для иконки корзины
            const iconOpacity = computed<string>(() => {
                return props.isMobileView ? '1' : '0';
            });

            // Находимся ли мы на странице чата
            const isChatPage = computed<boolean>(() => {
                return $route.name === Common.getRouteName(RoutePageNameEnum.Chat);
            });

            // Первые три файла из общего списка
            const slicedFiles = computed<IFile[]>(() => {
                return props.files.slice(0, 3);
            });

            return {
                ColorValiablesNameEnum,
                uploadFilesStyles,
                iconOpacity,
                isChatPage,
                slicedFiles,
            };
        }
    });
</script>

<style lang="scss">
    .chat-component {
        &.inline {
            .uploaded-files {
                width: 100% !important;
            }
        }
    }

    .thread-chat-block {
        .uploaded-files {
            width: 100% !important;
        }

        &.wide-thread-chat {
            .uploaded-files {
                width: 376px !important;
            }
        }
    }
</style>

<style lang="scss" scoped>
    .uploaded-files {
        width: 376px;
        position: absolute;
        right: 0;
        cursor: pointer;

        @media (max-width: 500px) {
            width: 100%;
        }

        .wrapper {
            background: $link;
            border-radius: 8px 8px 0 0;
            padding: 0 40px 0px 16px;
            position: relative;

            @media (max-width: 500px) {
                width: 100%;
            }

            &:hover {
                .file-item {
                    .delete-icon {
                        opacity: 1 !important;
                    }
                }
            }

            .files-count {
                width: 22px;
                height: 22px;
                border-radius: 50%;
                background: white;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $link;
                font-size: 10px;
                font-weight: 500;
                line-height: 12px;
                letter-spacing: -0.2px;
                position: absolute;
                right: 9px;
                bottom: 6px;
            }

            .file-item {
                padding: 11px 0;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: -0.2px;
                color: white;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                position: relative;

                &:last-child {
                    border-bottom: none;
                }

                .delete-icon {
                    position: absolute;
                    right: -25px;
                    opacity: 0;
                    cursor: pointer;
                }
            }
        }
    }
</style>
