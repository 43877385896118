<template>
    <q-page class="bg-shade-10 blocked-bg window-height">
        <q-card class="blocked-block fixed-center q-pa-md">
            <div class="title">{{ ($route.meta as any).getTitle() }}</div>

            <div class="text">
                {{ localize('Ваша учётная запись была заблокирована. Вы можете написать службе поддержки по адресу:') }}
                <a :href="'mailto:' + supportEmail" target="_blank" class="link">{{ supportEmail }}</a>
            </div>
            <q-btn
                flat
                :label="localize('К авторизации')"
                color="primary"
                class="q-manual-focusable--focused"
                @click="toLogin"
            />
        </q-card>
    </q-page>
</template>

<script lang="ts">
    import { localize } from 'src/services/LocalizationService';
    import { Common } from 'src/helpers/Common';
    import { RoutePageNameEnum } from 'src/api/ApiClient';
    import { computed, defineComponent, getCurrentInstance } from 'vue';
    import { useRouter } from 'vue-router';
    import { useAccountStore } from 'src/store/module-account';
    import { useAuthorizationStore } from 'src/store/module-authorization';

    // Страница Блокировка учетной записи
    export default defineComponent({
        name: 'Blocked',

        setup() {
            const app = getCurrentInstance();
            const $router = useRouter();
            const accountStore = useAccountStore();

            // Получение почты тех. поддержки
            const supportEmail = computed<string>(() => {
                return accountStore.getSupportEmail;
            });

            /**
             * К странице аутентификации
             */
            function toLogin(): void {
                // Обнуляем данные в сторе
                app?.appContext.config.globalProperties.$fcmService?.removeToken();
                useAuthorizationStore().logout();
                accountStore.accountInfo = null;
                // Вызываем replace чтобы нельзя было вернуться на страницу по стрелке Назад
                $router.replace({ name: Common.getRouteName(RoutePageNameEnum.Login) });
            }

            return {
                supportEmail,
                localize,
                toLogin,
            };
        }

    });
</script>

<style lang="scss" scoped>
    .blocked-bg {
        background-image: url('assets/bg-auth.png');
        background-repeat: repeat-y;
        background-position: center;
        background-size: cover;
        position: relative;
    }

    .title {
        margin-bottom: 12px;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
    }

    .blocked-block {
        width: 96%;
        max-width: 576px;
        font-size: 12px;
        line-height: 17px;
        background: url('assets/server-error.png') #fff 94% center no-repeat;
        background-size: 100px;

        @media (max-width: 400px) {
            background-image: none;
        }
    }

    .text {
        width: calc(100% - 116px);
        margin-bottom: 20px;

        @media (max-width: 400px) {
            width: 100%;
        }
    }
</style>
