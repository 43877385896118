import { ICallStartDto } from 'src/types/generated/hubs/callPartialHub/models/ICallStartDto';

// классы для описания стора
// для работы с оповещениями

export interface NotificationsStoreInterface {
    // количество непрочитанных оповещений
    countNotifications: number;
    // оповещения о текущих звонках
    callNotifications: ICallStartDto[];
}

function state(): NotificationsStoreInterface {
    // проставляем начальные значения для стора
    return {
        countNotifications: 0,
        callNotifications: []
    };
}

export default state;
