// Модель месяца
export interface IMonth {
    value: number;
    name: string;
}

// Модель поля "День рождения"
export interface IBirthdayModel {
    day: number | null;
    month: IMonth | null;
    year: number | null;
}

export enum birthdayModelTypes {
    Day = 'day',
    Month = 'month',
    Year = 'year',
}
