<template>
    <q-card
        v-if="isVisible"
        :style="{left: leftPosition + 'px'}"
        class="search-users-component"
    >
        <div v-if="users.length" class="header-list row justify-between items-center">
            <div class="font-bold">{{ localize('Участники чата') }}</div>
            <Icon
                name="CloseIcon"
                :color="ColorValiablesNameEnum.shade7"
                :hover-color="ColorValiablesNameEnum.shade7"
                @click.native="hide"
                class="cursor-pointer"
            />
        </div>
        <div ref="scrollBlockRef" @scroll="onScroll" class="scroll-block scroll">
            <q-list>
                <q-item
                    clickable
                    v-for="(user, index) in users"
                    :key="user.id"
                    :class="{ 'active-item': currentIndex === index }"
                    @click="selectUser(user)"
                    class="cursor-pointer q-pa-sm"
                >
                    <q-item-section avatar aria-hidden="true">
                        <q-avatar size="24px" style="margin-left: 6px;">
                            <img v-if="user.photoUrl" :src="user.photoUrl" alt="" />
                            <custom-avatar
                                v-else
                                :text="user.lastName + ' ' + user.firstName"
                                :color="CustomAvatarColors.User"
                            />
                        </q-avatar>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ user.lastName + ' ' + user.firstName }}</q-item-label>
                        <q-item-label v-if="getRoleIconName(user.role)" caption>
                            <q-icon
                                :name="getRoleIconName(user.role)"
                                size="16px"
                            />
                            <span class="text-description">
                                {{ localize(getRoleTitle(user.role)) }}
                            </span>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <div v-if="isSearchLoading && users.length" class="text-center q-mt-sm">
                    <q-spinner-dots color="primary" size="4em" />
                </div>
            </q-list>
        </div>
    </q-card>
</template>

<script lang="ts">
    import { ChatMembersDto, UserClient } from 'src/api/ApiClient';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import { defineComponent, nextTick, ref, watch } from 'vue';
    import { debounce } from 'quasar';
    import { localize } from 'src/services/LocalizationService';
    import { ColorValiablesNameEnum } from 'components/ui/Icon/interface';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import useChatMembers from 'components/Chat/hooks/useChatMembers';
    import { useChatStore } from 'src/store/module-chat';


    // Когда мы в сообщении ставим знак @
    // то показывается этот компонент для поиска пользователя
    // которого мы хотим упомянуть
    export default defineComponent({
        name: 'SearchUsers',

        emits: [
            'on-select-user',
            'on-hide',
        ],

        props: {
            searchUserName: {
                type: String,
                default: ''
            }
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            const chatStore = useChatStore();
            const { getRoleIconName, getRoleTitle } = useChatMembers();
            const scrollBlockRef = ref<HTMLDivElement>();
            const isVisible = ref<boolean>(false);
            const isSearchLoading = ref<boolean>(true);
            const leftPosition = ref<number>(0);
            const users = ref<ChatMembersDto[]>([]);
            const currentIndex = ref<number>(0);
            let currentPage = 1;
            let canLoadData = true;

            watch(() => props.searchUserName, () => {
                onChangeUserName();
            });

            const onChangeUserName: () => void = debounce(() => {
                users.value = [];
                currentPage = 1;
                canLoadData = true;
                isSearchLoading.value = true;
                searchUsers();
            }, 500);

            // Загрузка данных с пагинацией
            async function searchUsers(): Promise<void> {
                if (!isVisible.value) {
                    return;
                }

                isSearchLoading.value = true;
                const chatId = chatStore.currentChatId;
                const result = await new UserClient(getApiClientInitialParams()).getChatUsersInfoForSelect(props.searchUserName, 10, currentPage, chatId);

                if (result.isSuccess) {
                    users.value.push(...result.entity.pageItems);
                    canLoadData = result.entity.pagedMetaData.hasNextPage;

                    if (currentIndex.value > users.value.length - 1) {
                        currentIndex.value = 0;
                    }

                    if (!users.value.length) {
                        currentIndex.value = 0;
                    }
                } else {
                    NotifyErrors(result);
                }

                isSearchLoading.value = false;
            }

            // Выбрать пользователя из списка по Enter
            // Возвращает результат выполения - успешно или нет
            function selectActiveUser(): boolean {
                if (!isVisible.value) {
                    return false;
                }

                const user = users.value[currentIndex.value];

                if (user) {
                    selectUser(user);
                    return true;
                } else {
                    hide();
                    return false;
                }
            }

            function selectUser(user: ChatMembersDto): void {
                isVisible.value = false;
                users.value = [];
                currentIndex.value = 0;
                context.emit('on-select-user', user);
            }

            // При показе передается позиция
            // которая высчитана на основе позиции курсора
            function show(leftPos: number = 0): void {
                leftPosition.value = leftPos;
                isVisible.value = true;
                users.value = [];

                nextTick(function () {
                    if (scrollBlockRef.value) {
                        const width = scrollBlockRef.value.clientWidth;
                        const left = scrollBlockRef.value.getBoundingClientRect().left;

                        if (left + width >= window.innerWidth) {
                            // 16 - просто отступ от края экрана
                            leftPos -= ((left + width) - (window.innerWidth - 16));
                            leftPosition.value = leftPos;
                        }
                    }
                });

                // Сразу отображаем участников чата
                searchUsers();
            }

            function hide(): void {
                isVisible.value = false;
                users.value = [];
                currentIndex.value = 0;
                context.emit('on-hide');
            }

            // Навигация стрелками вверх/вниз по списку
            function navigate(key: 'ArrowUp' | 'ArrowDown'): void {
                if (!isVisible.value) {
                    return;
                }

                // 50px - высота одого элемента списка
                const heightItem = 50;

                if (key === 'ArrowUp') {
                    if (currentIndex.value === 0) {
                        currentIndex.value = users.value.length - 1;
                    } else {
                        currentIndex.value--;
                    }

                    scrollBlockRef.value?.scrollTo({ top: currentIndex.value * heightItem });
                } else {
                    if (currentIndex.value === users.value.length - 1) {
                        currentIndex.value = 0;
                    } else {
                        currentIndex.value++;
                    }

                    // Вниз начинаем скролить не с самого начала, а примерно с середины блока
                    if (currentIndex.value > 3) {
                        scrollBlockRef.value?.scrollTo({ top: currentIndex.value * heightItem });
                    }
                }
            }

            // Обработка загрузки на onScroll, кода доскролили почти до конца
            // (те больше 90%) загружаем запрос
            function onScroll(event: Event): void {
                const scrollBlock = (event.target as HTMLDivElement);
                const scrollHeight = scrollBlock.scrollHeight - scrollBlock.clientHeight;
                const scrollPercent = (scrollBlock.scrollTop * 100) / scrollHeight;

                if (!isSearchLoading.value && scrollPercent > 90 && canLoadData) {
                    ++currentPage;
                    searchUsers();
                }
            }

            return {
                scrollBlockRef,
                ColorValiablesNameEnum,
                CustomAvatarColors,
                currentIndex,
                isVisible,
                isSearchLoading,
                leftPosition,
                users,
                hide,
                show,
                selectUser,
                selectActiveUser,
                navigate,
                onScroll,
                localize,
                getRoleIconName,
                getRoleTitle,
            };
        }
    });
</script>

<style lang="scss" scoped>
    .search-users-component {
        position: absolute;
        min-width: 228px;
        bottom: 50px;
        left: 0;
        z-index: 15;

        .header-list {
            min-width: 228px;
            padding: 14px;
        }

        .scroll-block {
            max-height: 310px;
        }

        .active-item {
            background-color: $shade-2;
        }

        @media (max-width: 960px) {
            bottom: 85px;
        }
    }
</style>

<style lang="scss">
    .chat-component {
        &.inline {
            .search-users-component {
                bottom: 85px;
            }
        }
    }
</style>
