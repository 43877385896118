<template>
    <component
        :is="componentBySearchType[searchTerm.type]"
        :item="data"
        @on-item-selected="onItemSelected">
        <template #append >
            <div @click="onRemoveClicked" class="cursor-pointer">
                <delete-circle-icon />
            </div>
        </template>
    </component>
</template>

<script lang="ts" setup>
    import { PropType } from 'vue';
    import { SearchTermDto, SearchType, SearchClient, getApiClientInitialParams } from 'src/api/ApiClient';
    import SearchActivityItem from 'layouts/Main/components/searchBlock/components/activitiesTab/SearchActivityItem.vue';
    import SearchDisciplineItem from 'layouts/Main/components/searchBlock/components/disciplinesTab/SearchDisciplineItem.vue';
    import SearchPeopleItem from 'layouts/Main/components/searchBlock/components/peopleTabs/SearchPeopleItem.vue';
    import SearchMaterialItem from 'layouts/Main/components/searchBlock/components/materialsTab/SearchMaterialItem.vue';
    import SearchUniversityItem from 'layouts/Main/components/searchBlock/components/universitiesTab/SearchUniversityItem.vue';
    import SearchDivisionItem from 'layouts/Main/components/searchBlock/components/divisionsTab/SearchDivisionItem.vue';
    import SearchProgramItem from 'layouts/Main/components/searchBlock/components/eduProgramsTab/SearchProgramItem.vue';
    import SearchCohortItem from 'layouts/Main/components/searchBlock/components/cohortsTab/SearchCohortItem.vue';
    import SearchGroupItem from 'layouts/Main/components/searchBlock/components/groupsTab/SearchGroupItem.vue';

    const componentBySearchType = {
        [SearchType.Activity]: SearchActivityItem,
        [SearchType.Cohort]: SearchCohortItem,
        [SearchType.Content]: SearchMaterialItem,
        [SearchType.Discipline]: SearchDisciplineItem,
        [SearchType.Division]: SearchDivisionItem,
        [SearchType.EduProgram]: SearchProgramItem,
        [SearchType.Group]: SearchGroupItem,
        [SearchType.University]: SearchUniversityItem,
        [SearchType.User]: SearchPeopleItem,
    };
    // Компонент для отображения одного элемента истории поиска
    // По сути проксирует уже используемые элементы поиска

    const emit = defineEmits<{
        (e: 'on-remove'): void;
        (e: 'on-item-selected'): void;
    }>();

    const props = defineProps({
        searchTerm: {
            required: true,
            type: Object as PropType<SearchTermDto>
        }
    });
    
    // Тело элемента истории
    const data = JSON.parse(props.searchTerm.body);

    // обработка нажатия кнопки удаления из истории
    function onRemoveClicked(): void {
        emit('on-remove');
    }

    // Обработка нажатия на элемент истории
    function onItemSelected(): void {
        // Сохраним еще раз элемент истории, чтобы он всплыл наверх после повторного перехода
        new SearchClient(getApiClientInitialParams()).saveSearchTerm(props.searchTerm);

        // Передадим событие на обработку выше стоящим элементам
        emit('on-item-selected');
    }

</script>
