import { getCurrentInstance } from 'vue';
import {
    RouteLocation,
    NavigationGuardNext,
    createRouter,
    createWebHistory,
    RouteLocationRaw,
} from 'vue-router';
import routes from './routes';
import LocalizationService from '../services/LocalizationService';
import { LocaleName } from '../types/LocaleName';
import { Common } from 'src/helpers/Common';
import {
    AccountCurrentInfoResponseModel,
    RoutePageNameEnum,
    NotificationClient,
    getApiClientInitialParams,
    Result,
} from 'src/api/ApiClient';
import MetaRouter from 'src/router/types/MetaRouter';
import { NotifyErrors, notifySuccess } from 'src/api/ResultOfMethods';
import { useAccountStore } from 'src/store/module-account';
import { useAuthorizationStore } from 'src/store/module-authorization';
import { useMainLayoutStore } from 'src/store/module-main-layout';
import { AuthorizationStoreInterface } from 'src/store/module-authorization/state';


const pathItems = location.pathname.split('/').filter((x: string) => !!x.trim());
const langPrefix = decodeURIComponent(pathItems[0]);
let currentLangPrefix = LocalizationService.getLocaleName();

if (langPrefix && langPrefix.length === 2) {
    if (langPrefix.toLowerCase() !== currentLangPrefix.toLowerCase()) {
        if (Object.values(LocaleName).includes(langPrefix as LocaleName)) {
            LocalizationService.setLocaleName(langPrefix as LocaleName);
        }

        // если такого языка нету, префикс всё равно оставляем, чтобы работал роутинг
        // но настройки локали будут браться из localStorage
        currentLangPrefix = langPrefix as LocaleName;
    }
}

const router = createRouter({
    routes,
    sensitive: undefined,
    strict: undefined,
    scrollBehavior: () => ({
        top: 0,
        left: 0,
    }),
    history: createWebHistory(currentLangPrefix),
});

router.isReady().then(() => {
    const mainLayoutStore = useMainLayoutStore();
    // Записываем данные о том, что router загружен и его можно использовать
    mainLayoutStore.isReadyRouter = true;
});

/* eslint-disable max-lines-per-function */
router.beforeEach((to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) => {
    if (!to.name || !router.hasRoute(to.name)) {
        next({ name: Common.getRouteName(RoutePageNameEnum.Error404) });

        return;
    }

    // Проверяем является ли адрес переходом на старницу отписки от emal рассылки.
    // Нужно проверять в роутере, так как отписатья может незалогиневшийся пользователь, при этом он будет отправлен на страницу логина
    // с уведомлением об успешности отписки. А залогиневшийся пользователь редиректится на дашборд, так же с уведослением об успешности
    if (isUnsubscribeFromEmails(to)) {
        unsubscribeFromEmails(to);
    }

    // Признак что пользователь залогинен
    const isLogged = useAuthorizationStore().getIsLogged;

    if (!to.meta.isNeedLogin) {
        if (isLogged &&
            // Если пользователь залогинен и пытается попасть на логин форму то оставляем его на той же странице
            (to.name === Common.getRouteName(RoutePageNameEnum.Login) ||
                // Если залогинен и пытается попасть на страницу регистрации
                (to.name === Common.getRouteName(RoutePageNameEnum.Register) &&
                    !to.params['id']))) // Условие чтобы отсеять страницы саморегистрации
            // eslint-disable-next-line brace-style
        {
            next({ name: from.name ?? Common.getRouteName(RoutePageNameEnum.Dashboard) });

            return;
        }
        MetaRouter.manageRoute(to);
        next();

        return;
    }

    if (!isLogged) {
        const rawLocation: RouteLocationRaw = {
            name: Common.getRouteName(RoutePageNameEnum.Login),
        };

        // ODIN-11412
        if (location.pathname.toLowerCase().search(Common.getRouteName(RoutePageNameEnum.Agreement).toLowerCase()) === -1) {
            let redirectUrl = location.pathname;

            // Сохраняем гет параметры
            if (location.search) {
                redirectUrl += location.search;
            }

            rawLocation.query = { redirect: redirectUrl };
        }

        router.push(rawLocation);
        return;
    }

    if (isChangeToken()) {
        // Если токен изменился то переходим на страницу
        // Но перезагруаем вкладку
        location.href = to.fullPath;

        return;
    }

    const accountStore = useAccountStore();

    //если пользователь впервые зашёл, ему нужно проверить свою информацию, поэтому редиректим на странциу редактирования ифнормации
    if (needToUserEditPage(accountStore.accountInfo, to)) {
        const userId = accountStore.accountInfo?.id.toString();
        router.push({
            name: Common.getRouteName(RoutePageNameEnum.UserEdit),
            params: {
                id: userId,
                redirect: to.path
            },
        });

        return;
    }

    if (accountStore.accountInfo?.isBlocked) {
        if (from.name === Common.getRouteName(RoutePageNameEnum.Blocked)) {
            next();
        }

        router.push({
            name: Common.getRouteName(RoutePageNameEnum.Blocked),
        });

        return;
    }

    MetaRouter.manageRoute(to);
    next();
});

// Проверяем изменился ли токен в localStorage
// Измениться он может когда мы вошли под пользователем в другой вкладке
// Либо мы разлонигинились
function isChangeToken(): boolean {
    const vuex = localStorage.getItem('authorizationStore');

    if (vuex) {
        const vuexData: AuthorizationStoreInterface = JSON.parse(vuex);
        const app = getCurrentInstance();

        if (vuexData && app?.appContext.config.globalProperties.$token) {
            const storeToken = vuexData.jwtToken;
            const currentToken = app.appContext.config.globalProperties.$token;

            if (storeToken !== currentToken) {
                // Дополнительно обновим токен в localStorage через Vuex
                // Если он изменился в другой вкладке
                useAuthorizationStore().setToken(storeToken);
            }

            return storeToken !== currentToken;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

// Проверка, не находимся ли мы уже на странице редактирования
function needToUserEditPage(accountInfo: AccountCurrentInfoResponseModel | null, to: RouteLocation): boolean {
    return !!accountInfo && accountInfo.isFirstVisit
        && to.name !== Common.getRouteName(RoutePageNameEnum.UserEdit);
}

// Открыта ли страница после перехода из email по клике на "Отписаться от рассылки"
function isUnsubscribeFromEmails(to: RouteLocation): boolean {
    return to.name === Common.getRouteName(RoutePageNameEnum.UnsubscribeFromEmails);
}

// Отписаться от уведомлений
async function unsubscribeFromEmails(to: RouteLocation): Promise<void> {
    // Пока не удален эндпоинт для старой отписки, у нас могут оставаться старые
    // неотправленные письма в очереди. Так же может быть такое, что у пользователей
    // еще сохранились старые ссылки. Для обратной совместимости пока сохраняем обработку
    // обоих ссылок
    const userId: string = to.query.userid as string;
    const token: string = to.query.token as string;
    if (!userId && !token) {
        return; // Если query не содержит нужных параметров - не выполняем запрос
    }
    const notificationApi: NotificationClient = new NotificationClient(getApiClientInitialParams());
    const res: Result = userId === undefined
        ? await notificationApi.unsubscribeFromNotifications(token)
        : await notificationApi.unsubscribeFromEmails(userId);

    if (res.isSuccess) {
        notifySuccess(LocalizationService.localize('Вы успешно отписались от рассылки'));
    } else {
        NotifyErrors(res);
    }
}

export default router;
