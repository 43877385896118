import { NotificationsStoreInterface } from './state';
import { ICallStartDto } from 'src/types/generated/hubs/callPartialHub/models/ICallStartDto';

const getters = {
    getCountNotifications(state: NotificationsStoreInterface): number {
        return state.countNotifications;
    },
    // возвращает количество текущих звонков
    getCallNotifications(state: NotificationsStoreInterface): ICallStartDto[] {
        return state.callNotifications;
    },
};

export default getters;
