// publish subscribe паттерн,
// который используется для передачи данных между любыми компонентами
import { EventBus } from 'quasar';

// для роутера
const routerBas = new EventBus();

// для чата
const chatBus = new EventBus();

// для звонков
const callBus = new EventBus();

// для событиый в приложении
const electronAppBus = new EventBus();

export {
    routerBas,
    chatBus,
    callBus,
    electronAppBus
};
