<template>
    <div class="top-panel-disabled-chat">
        <div class="row items-center select-rooms justify-between q-px-sm q-py-sm cursor-pointer">
            {{ localize('Выберите чат') }}
            <q-icon name="arrow_drop_down" size="20px" />

            <q-menu :class="isMobileView ? 'mobile-select-rooms' : 'list-select-rooms'">
                <q-list v-close-popup>
                    <ListRooms
                        :chat-id="chatId"
                        @on-select-room="onSelectRoom"
                    />
                </q-list>
            </q-menu>
        </div>
    </div>
</template>

<script lang="ts">
    import ListRooms from 'components/Chat/components/ListRooms.vue';
    import { localize } from 'src/services/LocalizationService';
    import { ListChatDto } from 'src/api/ApiClient';
    import { defineComponent } from 'vue';

    // Компонент для отображения селекта с чатами решений
    // в комнате, в которой чат отключен
    export default defineComponent({
        name: 'TopPanelDisabledChat',

        components: {
            ListRooms,
        },

        emits: [
            'on-select-room'
        ],

        props: {
            // id чата комнаты, которая отключена
            chatId: {
                required: true,
                type: Number,
                default: 0,
            },
            // Мобильное отображение чата
            isMobileView: {
                type: Boolean,
                default: false,
            }
        },

        setup(props, context) {
            // вызываем событие чтобы родительский компонент его обработал
            function onSelectRoom(chat: ListChatDto): void {
                context.emit('on-select-room', chat.chatId);
            }

            return {
                onSelectRoom,
                localize,
            };
        }
    });
</script>

<style lang="scss" scoped>
    .top-panel-disabled-chat {
        padding: 12px 16px;
        background-color: #fff;
    }

    .select-rooms {
        position: relative;
        width: 100%;
        max-width: 400px;
        margin: 0;
        border-radius: 4px;
        background-color: $shade-2;

        ::v-deep(.sub-item ) {
            font-size: 12px;
            line-height: 17px;
        }
    }
</style>
