<template>
    <q-page>
        <NoItems
            :message="localize('Страница не найдена')"
            :description="localize('По данному запросу ни чего не найдено')"
            :is-show-button="true"
            :button-text="localize('На главную')"
            @on-click="toHomePage"
            class="q-mt-lg"
        />
    </q-page>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { localize } from 'src/services/LocalizationService';
    import { Common } from 'src/helpers/Common';
    import { RoutePageNameEnum } from 'src/api/ApiClient';
    import { useRouter } from 'vue-router';
    import { PageNames } from 'app/src-electron/types/enums';

    export default defineComponent({
        setup() {
            const $router = useRouter();

            // чтобы не было ошибки NavigationDuplicated
            // перенапраявляем не на '/' а на '/dashboard'
            function toHomePage(): void {
                const page = $router.resolve({
                    name: process.env.BUILD_MODE !== 'electron'
                        ? Common.getRouteName(RoutePageNameEnum.Dashboard)
                        : PageNames.CheckDevices
                });
                $router.push(`/${page.name?.toString()}`);
            }

            return {
                toHomePage,
                localize,
            };
        },
    });
</script>
