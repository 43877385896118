import { BreadcrumbItemDto, EntityBaseInfoDto } from 'src/api/ApiClient';

// классы для описания стора
// для корневого лайаута Main
export interface MainLayoutStoreInterface {
    isReadyRouter: boolean;
    isVisibleLeftMenu: boolean;
    isVisibleChatBlock: boolean;
    isVisibleCallsBlock: boolean;
    isVisibleSearchBlock: boolean;
    isVisibleSmthWentWrongBlock: boolean;
    isShowBackBreadcrumbsButton: boolean;
    breadcrumbs: BreadcrumbItemDto[];
    pinnedDisciplines: EntityBaseInfoDto[];
    pinnedPrograms: EntityBaseInfoDto[];
    pinnedDivisions: EntityBaseInfoDto[];
    pinnedCohorts: EntityBaseInfoDto[];
    breadcrumbsBlockHeight: number;
    isOpenFullDiscipline: boolean;
    // Нужно ли показывать подсказку по перемещению графа (показывается один раз)
    isShowMoveGraphPrompt: boolean;
    // Признак того, что какой-то из компонентов AudioPlayer.vue проигрывает аудио
    isAudioPlayerPlaying: boolean;
    externalReferenceCheckerVisibleSettings: Record<string, boolean> | null;
}

function state(): MainLayoutStoreInterface {
    return {
        isReadyRouter: false,
        isVisibleLeftMenu: false,
        isVisibleChatBlock: false,
        isVisibleSearchBlock: false,
        isVisibleSmthWentWrongBlock: false,
        isShowBackBreadcrumbsButton: false,
        isVisibleCallsBlock: false,
        breadcrumbsBlockHeight: 0,
        breadcrumbs: [],
        pinnedDisciplines: [],
        pinnedPrograms: [],
        pinnedDivisions: [],
        pinnedCohorts: [],
        isOpenFullDiscipline: false,
        externalReferenceCheckerVisibleSettings: null,
        isShowMoveGraphPrompt: true,
        isAudioPlayerPlaying: false,
    };
}

export default state;
