<template>
    <div>
        <div class="text-center" v-if="isLoading">
            <q-spinner-dots color="primary" size="4em"/>
        </div>
        <search-program-item
            v-else
            v-for="program in programs"
            :key="program.id"
            :item="program"
            @on-item-selected="onItemSelected"/>
        <show-all-btn v-if="canShowAll" @show-all="showAll"/>
    </div>
</template>

<script lang="ts" setup>
    import { Ref, ref, toRefs } from 'vue';
    import { SearchEduProgramDto } from 'src/api/ApiClient';
    import SearchProgramItem from 'layouts/Main/components/searchBlock/components/eduProgramsTab/SearchProgramItem.vue';
    import { SearchTabs } from 'layouts/Main/components/searchBlock/types/searchTabs';
    import ShowAllBtn from '../ShowAllBtn.vue';
    import { useSearch } from '../../hooks/useSearch';
    import { SearchEntity } from '../../types/SearchEntity';


    // Компонент с отображением первых 5 найденных образовательных программ
    // Отображается когда выбран поиск по всем сущностям
    const programs: Ref<SearchEduProgramDto[]> = ref([]);
    const emit = defineEmits<{
        (e: 'on-item-selected'): void;
        (e: 'on-found-items-count', itemsCount: number): void;
        (e: 'show-all', tab: SearchTabs): void;
    }>();
    const props = withDefaults(defineProps<{
        searchText: string;
    }>(), {
        searchText: '',
    });
    const { searchText } = toRefs(props);
    const {
        // Признак, отвечающий за загрузку данных
        isLoading,
        // Нужно ли показывать кнопку "показать все"
        canShowAll,
        // Обработчик выбора элемента
        onItemSelected,
        showAll,
    } = useSearch(SearchEntity.Programs, false, programs, searchText, emit);

</script>
