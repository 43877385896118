import LocalizationService from 'src/services/LocalizationService';
import * as Sentry from '@sentry/vue';
import { Common } from 'src/helpers/Common';
import { useAuthorizationStore } from 'src/store/module-authorization';
import { useMainLayoutStore } from 'src/store/module-main-layout';
import { routerBas } from 'components/EventBuses';
import { RouterBusEvents } from 'components/EventBuses/emuns';

// класс BaseApiClient используется для генерации файла ApiClient.ts
export class BaseApiClient {
    private readonly config: ApiClientConfig;
    // AbortController используется для отмены асинхронных задач
    // в данном случае это будет fetch
    private abortController: AbortController = new AbortController();

    // на объекте abortController.signal генерируется событие abort
    private get abortSignal(): AbortSignal {
        return this.abortController.signal;
    }

    protected constructor(config: ApiClientConfig) {
        this.config = config;
    }

    // функция для отмены http запроса
    abortRequest(): void {
        this.abortController.abort();
    }

    // функция вызывается при каждом запросе
    transformOptions(options: RequestInit): Promise<RequestInit> {
        if (options && options.headers) {
            options.headers = {
                ...options.headers,
                'Cache-Control': 'no-cache, no-store, must-revalidate'
            };

            if (options.method && this.config.AntiforgeryToken) {
                const method = options.method.toUpperCase();
                if (method !== 'GET' && method !== 'HEAD' && method !== 'TRACE' && method !== 'OPTIONS') {
                    options.headers = {
                        ...options.headers,
                        RequestVerificationToken: this.config.AntiforgeryToken
                    };
                }
            }
            if (this.config.AuthToken) {
                // добавление токена JWT в заголовок авторизации
                options.headers = {
                    ...options.headers,
                    'Authorization': 'Bearer ' + this.config.AuthToken,
                };
            }
            if (this.config.Lang) {
                // добавление заголовка с язкыом
                // используется для локализации приложения
                options.headers = {
                    ...options.headers,
                    'Accept-Language': this.config.Lang,
                };
            }

            // Всегда добавляем кастомный заголовок
            // для маршрутизации при канареечных релизах
            options.headers = {
                ...options.headers,
                'Canary': Common.isCanary() ? 'always': 'never'
            };
        }

        if (options) {
            options.signal = this.abortSignal;
        }

        return Promise.resolve(options);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getBaseUrl(_1: string, baseUrl?: string): string {
        return getBaseApiUrl();
    }

    // вызывается при обработке результатов запроса
    transformResult(url: string, response: Response, callback: (_response: Response) => Promise<any>): Promise<any> {
        response.headers.forEach((value: string, key: string) => {
            if (key === 'set-cookie') {
                if (value.startsWith('.AspNetCore.Antiforgery')) {
                    const equalIndex = value.indexOf('=');
                    this.config.AntiforgeryToken = value.substring(equalIndex + 1);
                }
            }
            if (key === 'cache' &&
                value === 'HIT') {
                Sentry.captureMessage('Внимание! Получен ответ от сервера из кэша. Необходимо срочно переключить отправку запросов не через cdn', 'fatal');
            }
        });

        if (response.status === 500) {
            useMainLayoutStore().isVisibleSmthWentWrongBlock = true;
        }

        if (response.status === 401) {
            routerBas.emit(RouterBusEvents.ToLogin);
        }

        return callback(response);
    }
}

export class ApiClientConfig {
    public AntiforgeryToken: string | undefined = undefined;
    public AuthToken: string | undefined = undefined;
    public Lang: string | undefined = undefined;
}

export function getBaseApiUrl(): string {
    return `https://${process.env.BASE_API_URL}`;
}

// функция для получения данных
// для инициализации ApiClient'a
export function getApiClientInitialParams(): ApiClientConfig {
    const authorizationStore  = useAuthorizationStore();
    return {
        AntiforgeryToken: '',
        AuthToken: authorizationStore.getToken,
        Lang: LocalizationService.getLocaleName(),
    };
}
