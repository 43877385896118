<template>
    <div class="notifications-list-not-push q-pa-md">
        <div class="notifications-list-not-push__title q-mb-md">
            {{ localize('Отключить уведомления о звонках в данном чате') }}
        </div>
        <div class="notifications-list-not-push__body">
            <q-radio v-model="typeMuted" :val="MuteChatTime.OneHour" @update:model-value="changeMuted" :label="localize('На 1 час')" clickable />
            <br>
            <q-radio v-model="typeMuted" :val="MuteChatTime.EightHours" @update:model-value="changeMuted" :label="localize('На 8 часов')" clickable />
            <br>
            <q-radio v-model="typeMuted" :val="MuteChatTime.TwentyFourHours" @update:model-value="changeMuted" :label="localize('На 24 часа')" clickable />
            <br>
            <q-radio v-model="typeMuted" :val="MuteChatTime.ThreeDays" @update:model-value="changeMuted" :label="localize('На 3 дня')" clickable />
            <br>
            <q-radio v-model="typeMuted" :val="MuteChatTime.Forever" @update:model-value="changeMuted" :label="localize('Навсегда')" clickable />
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent, ref } from 'vue';
    import { localize } from 'src/services/LocalizationService';
    import { ChatClient, MuteChatTime, Result } from 'src/api/ApiClient';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';

    export default defineComponent({
        name: 'NotificationsListNotPush',

        // События компонента
        emits: [
            'change',
            'change-close',
        ],

        props: {
            chatId: {
                required: true,
                type: Number,
            },
        },

        /* eslint-disable-next-line max-lines-per-function  */
        setup(props, context) {
            // Время выключения уведомлений
            const typeMuted = ref<MuteChatTime | null>(null);

            // Изменение настройки уведомлений звонков для чата
            async function changeMuted(): Promise<void> {
                if (typeMuted.value !== null) {
                    const result: Result = await new ChatClient(getApiClientInitialParams()).changeUserCallNotificationSettings(props.chatId, typeMuted.value);
                    if (result.isSuccess) {
                        context.emit('change-close', true);
                    }
                }
            }

            return {
                typeMuted,
                MuteChatTime,
                localize,
                changeMuted,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .notifications-list-not-push {
        background-color: #fff;

        &__title {
            font-size: 14px;
            color: $black;
            line-height: 1.4;
            font-weight: bold;
            max-width: 180px;
        }

        &__body {
            margin-left: -5px;
        }
    }
</style>
