<template>
    <q-btn-dropdown
        split
        color="primary"
        :label="linkText"
        :href="linkToDownload"
        :class="{ 'open' : isOpen }"
        class="no-shadow"
        content-class="download-app-list"
        @before-show="isOpen = true"
        @before-hide="isOpen = false"
    >
        <q-list>
            <q-item
                v-show="platform !== AppPlatform.Win32"
                clickable
                v-close-popup
                @click="platform = AppPlatform.Win32">
                <q-item-section >
                    Windows
                </q-item-section>
            </q-item>
            <q-item
                v-show="platform !== AppPlatform.Linux"
                clickable
                v-close-popup
                @click="platform = AppPlatform.Linux">
                <q-item-section>
                    Linux
                </q-item-section>
            </q-item>
        </q-list>
    </q-btn-dropdown>
</template>

<script lang="ts">
    import { computed, ComputedRef, defineComponent, ref, Ref } from 'vue';
    import { localize } from 'src/services/LocalizationService';
    import {
        AppPlatform
    } from 'src/api/ApiClient';
    import { useRouter } from 'vue-router';

    /**
     * Компонент с кнопкой скачивания приложения для экзаменов
     */
    export default defineComponent({
        name: 'DownloadExamAppButton',

        setup() {
            const isOpen = ref<boolean>(false);

            // Целевая платформа для скачивания
            const platform: Ref<AppPlatform> = ref(AppPlatform.Win32);
            const $router = useRouter();

            // Ссылка на скачивание в зависимости от выбранной платформы
            const linkToDownload: ComputedRef<string> = computed((): string => {
                return $router.resolve({
                    path: `/api/migrationExamApp/download/${AppPlatform[platform.value]}`
                }).fullPath;
            });

            // Ссылка на скачивание в зависимости от выбранной платформы
            const linkText: ComputedRef<string> = computed((): string => {
                return localize('Скачать для {platform}', {
                    platform: platform.value === AppPlatform.Win32 ? 'Windows' : 'Linux'
                });
            });

            return {
                isOpen,
                AppPlatform,
                localize,
                linkToDownload,
                linkText,
                platform
            };
        }
    });
</script>

<style lang="scss" scoped>
    .open {
        ::v-deep(.q-btn) {
            background: $link !important;
        }
    }

    ::v-deep(.q-icon) {
        color: white;
    }

    ::v-deep(.q-btn-item) {
        border: none !important;
    }
</style>

<style lang="scss">
    .download-app-list {
        margin-top: -2px !important;
        border-color: $link;
        border-radius: 0 0 3px 3px;

        .q-list {
            background: $link !important;
            color: #fff;

            .q-item {
                background: inherit !important;
            }
        }

    }
</style>
