<template>
    <ui-select
        ref="selectRef"
        dense
        options-dense
        v-model="inputValue"
        :options="users"
        multiple
        map-options
        option-value="id"
        option-label="fullName"
        hide-bottom-space
        :label="label"
        :placeholder="placeholder"
        :loading="isLoadingItems"
        :is-server-filter="true"
        @virtual-scroll="onScrollUsers"
        @filter="filterUsers"
        @on-change="onChange"
        @update:model-value="$emit('update:model-value', $event)"
        :error-message="errorMessage"
        :error="error"
        :rules="rules"
        :disable="disable"
        :is-compact-style="isCompactStyle"
        popup-content-class="select"
        :use-input-force="true"
    >
        <template v-if="$slots['prepend']" v-slot:prepend="scope">
            <slot name="prepend" v-bind="scope"/>
        </template>

        <template v-slot:selected-item="scope">
            <template v-if="$slots['selected-item']">
                <slot name="selected-item" v-bind="scope" />
            </template>
            <template v-else>
                <div class="text-tag q-mr-xs">
                    {{ scope.opt.fullName }}
                </div>
            </template>
        </template>

        <template v-slot:option="scope">
            <template v-if="$slots['option']">
                <slot name="option" v-bind="scope" />
            </template>
            <template v-else>
                <q-item v-bind="scope.itemProps" class="q-pa-sm">
                    <q-item-section avatar aria-hidden="true">
                        <q-avatar size="32px">
                            <img v-if="scope.opt.photoPath" :src="scope.opt.photoPath" />
                            <custom-avatar
                                v-else
                                :font-size="14"
                                :text="scope.opt.fullName"
                                :color="CustomAvatarColors.User"
                            />
                        </q-avatar>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label v-html="scope.opt.fullName" />
                        <q-item-label caption class="ellipsis-2-lines">
                            {{ scope.opt.cityName }}
                            <template v-if="scope.opt.universities.length">•</template>
                            {{ scope.opt.universities.join(', ') }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </template>
        </template>
    </ui-select>
</template>

<script lang="ts">
    import {
        PagedResultOfUserSelectModel,
        ResultOf,
        UserClient,
        UserSelectModel,
        UsersForSelectRequestType,
        UsersForSelectCommonRequest,
    } from 'src/api/ApiClient';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { QVirtualScroll } from 'quasar';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import {
        computed,
        defineComponent,
        nextTick,
        onBeforeMount,
        PropType,
        ref,
        SetupContext,
    } from 'vue';
    import useDataSelect, { componentProps } from 'components/ui/selects/DataSelect/useDataSelect';

    /**
     * Дропдаун множественного выбора пользователя
     */
    export default defineComponent({
        name: 'UserMultiselect',

        emits: ['update:model-value'],

        props: {
            modelValue: Array as PropType<UserSelectModel[] | null>,
            label: componentProps.label,
            rules: componentProps.rules,
            error: componentProps.error,
            errorMessage: componentProps.errorMessage,
            placeholder: componentProps.placeholder,
            disable: componentProps.disable,
            isCompactStyle: componentProps.isCompactStyle,
            searchRequestType: {
                type: Number as PropType<UsersForSelectRequestType>,
                default: UsersForSelectRequestType.Users,
                nullable: true,
            },
            /* Коллекция идентификаторов пользователей для дефолтного отображения */
            selectedIds: {
                type: Array as PropType<number[] | null>
            },
            /* Id дисциплины, чтобы в списке первыми шли преподаватели */
            disciplineId: {
                type: Number as PropType<number | null>
            },
            /* Id потока, чтобы отфильтровать по потоку */
            cohortId: {
                type: Number as PropType<number | null>
            },
            universityId: {
                type: Number
            }
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            // Импортируем необходимые переменные и функции
            const {
                selectRef,
                inputValue,
                hasError,
                validate,
                searchItemFilter,
                isLoadingItems,
                onChange,
            } = useDataSelect(props, context as SetupContext);

            let currentPage: number = 1;
            let canLoadItems: boolean = true;

            const users = ref<UserSelectModel[]>([]);

            // Список выбранных id пользователей
            const idsSelect = computed<number[] | null>(() => {
                if (props.modelValue && props.modelValue.every((item: UserSelectModel) => item.id > 0)) {
                    return props.modelValue && props.modelValue.length > 0 ? props.modelValue.map((item: UserSelectModel) => item.id) : null;
                }
                return [];
            });

            // Получить список выбранных пользователей для выпадающего списка при инициализации компонента
            async function loadUserSelect(): Promise<UserSelectModel[]> {
                if (props.modelValue && props.modelValue.length > 0) {
                    // Проверка. Если пришла не целая модель, а только id, то мы шлем запрос по получению их данных
                    const userNotFull: UserSelectModel[] = props.modelValue.filter((el: UserSelectModel) => !el.fullName);

                    if (userNotFull.length > 0) {
                        const getByIdsRequest: UsersForSelectCommonRequest = {
                            pageSize: 1000,
                            page: currentPage,
                            ids: idsSelect.value,
                            requestType: UsersForSelectRequestType.UsersByIds
                        };
                        const result = await new UserClient(getApiClientInitialParams()).getUsersInfoForSelect(getByIdsRequest);

                        if (result.isSuccess) {
                            return result.entity.pageItems;
                        }
                    } else {
                        return props.modelValue;
                    }
                } else if (!!props.selectedIds && props.selectedIds.length > 0) {
                    const getBySelectedIdsRequest: UsersForSelectCommonRequest = {
                        pageSize: 1000,
                        page: currentPage,
                        ids: props.selectedIds,
                        requestType: UsersForSelectRequestType.UsersByIds
                    };
                    const result = await new UserClient(getApiClientInitialParams()).getUsersInfoForSelect(
                        getBySelectedIdsRequest
                    );

                    if (result.isSuccess) {
                        return result.entity.pageItems;
                    }
                }

                return [];
            }

            // Получить список пользователей для выпадающего списка
            async function loadUsers(): Promise<UserSelectModel[]> {
                isLoadingItems.value = true;

                const request: UsersForSelectCommonRequest = {
                    pageSize: 20,
                    page: currentPage,
                    searchString: searchItemFilter.value,
                    disciplineId: props.disciplineId,
                    cohortId: props.cohortId,
                    universityId: props.universityId,
                    requestType: props.searchRequestType
                };

                const result: ResultOf<PagedResultOfUserSelectModel> = await new UserClient(getApiClientInitialParams()).getUsersInfoForSelect(
                    request
                );

                canLoadItems = result.entity.pagedMetaData.hasNextPage;

                if (canLoadItems) {
                    currentPage++;
                }

                return result.entity.pageItems || [];
            }

            async function onScrollUsers(params: { to: number, ref: QVirtualScroll }): Promise<void> {
                const lastIndex = selectRef.value?.allOptions.length - 1;

                if (!isLoadingItems.value && canLoadItems && params.to === lastIndex) {
                    const userItems = await loadUsers();

                    userItems.forEach((el: UserSelectModel) => {
                        users.value.push(el);
                    });

                    nextTick(() => {
                        params.ref.refresh();
                        isLoadingItems.value = false;
                    });
                }
            }

            function filterUsers(val: string, update: (val: () => void) => void): void {
                currentPage = 1;
                searchItemFilter.value = val;

                loadUsers().then((values: UserSelectModel[]) => {
                    update(() => {
                        users.value = values;
                        // после вызова update почему-то два раза отрабатывает скролл
                        // и вместо одного запроса идут 3
                        // поэтому обновляем флаг с небольшой задержкой
                        setTimeout(() => {
                            selectRef.value?.scrollTo(0);
                            isLoadingItems.value = false;
                        }, 100);
                    });
                });
            }

            onBeforeMount(async () => {
                const items = await loadUserSelect();

                nextTick(() => {
                    inputValue.value = items;
                    context.emit('update:model-value', items);
                });
            });

            return {
                selectRef,
                CustomAvatarColors,
                inputValue,
                users,
                isLoadingItems,
                hasError,
                validate,
                onScrollUsers,
                filterUsers,
                onChange,
            };
        }
    });
</script>
