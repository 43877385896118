import { CommonOdinHub } from 'src/services/hubs/CommonOdinHub';
import {
    ILiveDataHubClientEventListeners,
} from 'src/types/generated/hubs/liveDataPartialHub/interfaces/ILiveDataHubClientEventListeners';
import { ILiveDataMessageCallMembers } from 'src/types/generated/hubs/liveDataPartialHub/models/ILiveDataMessageCallMembers';
import { ILiveDataMessageDisciplineMarks } from 'src/types/generated/hubs/liveDataPartialHub/models/ILiveDataMessageDisciplineMarks';
import { ILiveDataMessageSolutionEvaluates } from 'src/types/generated/hubs/liveDataPartialHub/models/ILiveDataMessageSolutionEvaluates';
import { ILiveDataMessageDisciplineVisits } from 'src/types/generated/hubs/liveDataPartialHub/models/ILiveDataMessageDisciplineVisits';

// объект транслирующий события обновления данных в реальном времени ("живые данные")
export abstract class LiveDataPartialHub extends CommonOdinHub
    implements ILiveDataHubClientEventListeners {
    // Обновление проверяющих и статуса решения на странице решений заданий
    onSolutionEvaluateLiveUpdate(e: (arg: ILiveDataMessageSolutionEvaluates) => void): void {
        this.connection.on('SolutionEvaluateLiveUpdateAsync', e);
    }
    // Обновление участников звонка в информации о звонке в миничате
    onCallMembersLiveUpdate(e: (arg: ILiveDataMessageCallMembers) => void): void {
        this.connection.on('CallMembersLiveUpdateAsync', e);
    }
    // Обновление оценок на странице дисциплины
    onDisciplineMarkLiveUpdate(e: (arg: ILiveDataMessageDisciplineMarks) => void): void {
        this.connection.on('DisciplineMarkLiveUpdateAsync', e);
    }
    // Обновление посещений на странице дисциплины
    onDisciplineVisitLiveUpdate(e: (arg: ILiveDataMessageDisciplineVisits) => void): void {
        this.connection.on('DisciplineVisitLiveUpdateAsync', e);
    }
}
