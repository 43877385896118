import { IInterlocutor } from '../types/interfaces';
import convertInterlocutorFromServer from './convertInterlocutorFromServer';
import { CallClient, CallUserModel } from 'src/api/ApiClient';
import { getApiClientInitialParams } from 'src/api/BaseApiClient';

// Получить информацию о пользователе
export default async (personId: number, callId: number): Promise<IInterlocutor[] | null> => {
    const result = await new CallClient(getApiClientInitialParams()).getUsersInfo([personId], callId);

    if (!result.isSuccess) {
        return null;
    }

    return (result.entity.users || []).map((x: CallUserModel) => {
        return convertInterlocutorFromServer(x);
    });
};
