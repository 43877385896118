import { QInfiniteScroll } from 'quasar';
import { getApiClientInitialParams, SearchClient } from 'src/api/ApiClient';
import { computed, ComputedRef, onMounted, Ref, ref, watch } from 'vue';
import { mapSearchEntityToTab } from '../helpers/mapSearchEntityToTab';
import { SearchEmits } from '../types/SearchEmits';
import { SearchEntity } from '../types/SearchEntity';


// Общий функционал для поиска
// eslint-disable-next-line max-lines-per-function
export function useSearch(
    entity: SearchEntity,
    infinite: boolean,
    items: Ref<any[]>,
    searchText: Ref<string>,
    emit: SearchEmits,
    infinityScrollRef?: Ref<InstanceType<typeof QInfiniteScroll> | undefined>,
): {
    canShowAll: ComputedRef<boolean>;
    isLoading: Ref<boolean>;
    searchClient: null;
    onLoad: (page?: number, done?: ((isDone: boolean) => void) | undefined) => Promise<void>;
    showAll: () => void;
    onItemSelected: () => void;
} {

    const isLoading = ref<boolean>(false);
    const totalItemCount = ref<number>(0);
    let searchClient: SearchClient | null = null;
    const canShowAll = computed((): boolean => !isLoading.value && totalItemCount.value > items.value.length);

    // Обработка выбора элемента
    function onItemSelected(): void {
        emit('on-item-selected');
    }

    // Обработчик поиска, перед новым запросом обнуляем данные
    watch(searchText, () => {
        searchClient?.abortRequest();
        items.value = [];

        if (infinite && infinityScrollRef) {
            infinityScrollRef.value?.reset();
            infinityScrollRef.value?.resume();
            infinityScrollRef.value?.poll();
        } else {
            onLoad();
        }
    });

    /**
     * Загрузка данных поиска
     */
    async function onLoad(page?: number, done?: (isDone: boolean) => void): Promise<void> {
        isLoading.value = true;

        // Отменяем запущенный запрос
        if (searchClient) {
            searchClient.abortRequest();
        }

        searchClient = new SearchClient(getApiClientInitialParams());

        try {
            const result = await searchClient[entity](searchText.value, infinite ? page : null, infinite ? undefined : 5);

            if (result.isSuccess) {
                totalItemCount.value = result.entity.pagedMetaData.totalItemCount;
                emit('on-found-items-count', result.entity.pagedMetaData.totalItemCount);

                if (infinite) {
                    items.value.push(...result.entity.pageItems);
                } else {
                    items.value = result.entity.pageItems;
                }

                if (done) {
                    done(!result.entity.pagedMetaData.hasNextPage);
                }
            }

            isLoading.value = false;

            setTimeout(function () {
                if (infinityScrollRef?.value?.$parent?.$el) {
                    const height = infinityScrollRef.value.$el.clientHeight;
                    const defaultHeight = '56px';
                    infinityScrollRef.value.$parent.$el.style.height = !!height ? height + 'px' : defaultHeight;
                }
            }, 100);
        } catch (err: any) {
            if (err.name !== 'AbortError') {
                throw err;
            } else {
                if (done) {
                    done(false);
                }
            }
        }
    }

    // Обработчик события "показать все"
    function showAll(): void {
        emit('show-all', mapSearchEntityToTab(entity));
    }

    if (!infinite) {
        onMounted(() => {
            onLoad();
        });
    }

    return {
        canShowAll,
        isLoading,
        searchClient,
        onLoad,
        showAll,
        onItemSelected,
    };
}
