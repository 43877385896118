﻿<template>
    <search-item
        :item="item"
        :name="item.name"
        :item-page-url="cohortPageUrl"
        :type="SearchType.Cohort"
        @on-item-selected="$emit('on-item-selected')"
    >
        <template #avatar>
            <cohort-avatar :isArchive="item.isArchive" />
        </template>
        <template #info>
            <div class="info">
                {{ item.universityShortName }} • {{ item.eduProgramShortName }} • {{ item.divisionShortName }}
            </div>
        </template>
        <template #append>
            <slot name="append" />
        </template>
    </search-item>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import {
        RoutePageNameEnum,
        SearchCohortDto,
        SearchType,
    } from 'src/api/ApiClient';
    import { Common } from 'src/helpers/Common';
    import { useGetEntityPageUrl } from '../../hooks/useGetEntityPageUrl';
    import SearchItem from '../SearchItem.vue';

    // Компонент для отображение данных о найденном потоке
    const props = defineProps<{
        // Данные отображаемого элемента поиска
        item: SearchCohortDto;
    }>();
    defineEmits<{
        (e: 'on-item-selected'): void;
    }>();
    const {
        getEntityPageUrl: getCohortPageUrl,
    } = useGetEntityPageUrl(Common.getRouteName(RoutePageNameEnum.CohortInfo));
    // Ссылка на страницу потока
    const cohortPageUrl = computed(() => getCohortPageUrl(props.item.id));
</script>
