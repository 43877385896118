import state from './state';
import actions from './actions';
import getters from './getters';
import { defineStore } from 'pinia';

// store для данных авторизации

export const useAuthorizationStore = defineStore('authorizationStore', {
    state,
    getters,
    actions,
    persist: {
        storage: window.localStorage,
        paths: [
            'jwtToken',
            'jwtTokenDateOfCreation',
        ]
    }
});
