﻿import * as signalR from '@microsoft/signalr';
import { HubConnectionState } from '@microsoft/signalr';
import { getBaseApiUrl } from 'src/api/BaseApiClient';
import {
    IOdinHubClientEventListeners
} from 'src/types/generated/hubs/commonOdinHub/interfaces/IOdinHubClientEventListeners';
import { Common } from 'src/helpers/Common';
import { useAuthorizationStore } from 'src/store/module-authorization';

/*
* Общий хаб (ws подключение) через SignalR
* */
export class CommonOdinHub implements IOdinHubClientEventListeners {
    protected readonly connection: signalR.HubConnection;
    protected readonly delayReconnect: number;
    
    /* Callback обработки события завершения соединения со стороны сервера */
    protected onAbortCallback = (): void => {};

    constructor() {
        const minReconnectDelayMilliseconds = 1000;
        const maxReconnectDelayMilliseconds = 10000;
        this.delayReconnect = Math.floor(Math.random() * (maxReconnectDelayMilliseconds - minReconnectDelayMilliseconds + 1) + minReconnectDelayMilliseconds);

        // Подключение по сокету через cdn не открывается, выдается ошибка 400 Bad Request
        // поэтому оставляем только основную часть хоста без www
        const baseApiUrlWithoutWWW = getBaseApiUrl().replace('www.', '');
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(baseApiUrlWithoutWWW + '/hub/common', {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: (): string | Promise<string> => {
                    const authorizationStore = useAuthorizationStore();
                    // Get and return the access token.
                    return authorizationStore.getToken;
                }
            })
            .build();
        
        this.connection.onclose(() => {
            this.reconnect();
        });
        this.connection.on('AbortAsync', () => {
            this.connection.stop();
            if (this.onAbortCallback) {
                this.onAbortCallback();
            }
        });
        
        this.start();
    }

    start(): void {
        this.connection.start()
            .catch((error: any) => {
                console.error('Catch CommonOdinHub.ts: ', error);
                this.reconnect();
            });

        this.onIsCanary((isCanaryArg: boolean) => {
            if (isCanaryArg !== Common.isCanary()) {
                location.reload();
            }
        });
    }

    onReconnecting(callback: () => void): void {
        this.connection.onreconnecting(callback);
    }

    closeConnection(): void {
        this.connection.stop();
    }

    onAbort(e: () => void): void {
        this.onAbortCallback = e;
    }

    onIsCanary(e: (arg: boolean) => void): void {
        this.connection.on('IsCanaryAsync', e);
    }
    
    /* Запустить переподключение с установленной задержкой */
    protected reconnect(): void {
        setTimeout(() => {
            switch (this.connection.state) {
                case HubConnectionState.Connected:    
                case HubConnectionState.Connecting:    
                case HubConnectionState.Reconnecting:    
                    return;
                case HubConnectionState.Disconnected:
                    this.start();
                    break;
                default:
                    this.reconnect();
                    break;
            }
        }, this.delayReconnect);
    }
}
