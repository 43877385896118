<template>
    <q-layout>
        <q-page-container>
        </q-page-container>
    </q-layout>
</template>

<script lang='ts'>
    import { defineComponent, onBeforeMount } from 'vue';
    import {
        ExchangeableResolveClient,
        getApiClientInitialParams,
        ExchangeableEntityInfoPages,
    } from 'src/api/ApiClient';
    import { localize } from 'src/services/LocalizationService';
    import { NotifyErrors, notifyError } from 'src/api/ResultOfMethods';
    import { useRoute, useRouter } from 'vue-router';
    import { Common } from 'src/helpers/Common';

    // Компонент для перенаправления на страницу информации о сущности по ее externalGuid
    export default defineComponent({
        name: 'ExchangeableGuidResolve',
        setup() {
            const $router = useRouter();
            const $route = useRoute();

            onBeforeMount(async () => {
                const entityType: ExchangeableEntityInfoPages = ExchangeableEntityInfoPages[$route.params.entitytype as keyof typeof ExchangeableEntityInfoPages];
                const exchangeableGuidStr = $route.params.exchangeableguid?.toString() ?? '';
                if (!entityType || !exchangeableGuidStr) {
                    notifyError(localize('Не верно переданы параметры запроса'));
                    return;
                }
                // Получаем данные для перенаправления на страницу информации о сущности
                const response = await new ExchangeableResolveClient(getApiClientInitialParams()).exchangeableEntityInfo(entityType, exchangeableGuidStr);
                if (response.isSuccess) {
                    //Перенаправляем на страницу, согласно полученным данным
                    await $router.push({
                        name: Common.getRouteName(response.entity.pageName),
                        params: { id: response.entity.entityId },
                    });
                } else {
                    NotifyErrors(response, '', true);
                }
            });

            return {};
        }
    });
</script>
