﻿export function getConfig(): AppConfig {
    let smartCaptchaSiteKey = 'py6H64fC54vsA5grqbhlyPisqc9UWohhdqoL5N07';

    if (process.env.NODE_ENV === 'production') {
        smartCaptchaSiteKey = '2rDhSRfE7OWgNhCAR2Mq7BuFCwicLUkIHPnthwsc';
    }

    return { SmartCaptchaSiteKey: smartCaptchaSiteKey };
}

// Настройки конфигурации приложения
export interface AppConfig {
    SmartCaptchaSiteKey: string;
}
