// eslint-disable-next-line comment-density/comment-density
import { ProgrammingLanguages } from 'src/api/ApiClient';

// Модель названия языка программирования
// и массив его алиасов
export interface ILangNames {
    lang: ProgrammingLanguages;
    names: string[];
}

const langNames: ILangNames[] = [];

langNames.push({
    lang: ProgrammingLanguages.JavaScript,
    names: ['JavaScript', 'JS']
});
langNames.push({
    lang: ProgrammingLanguages.CSharp,
    names: ['CSharp', 'C#', 'cs']
});
langNames.push({
    lang: ProgrammingLanguages.Go,
    names: ['Go', 'golang']
});
langNames.push({
    lang: ProgrammingLanguages.Java,
    names: ['Java']
});
langNames.push({
    lang: ProgrammingLanguages.PHP,
    names: ['PHP']
});
langNames.push({
    lang: ProgrammingLanguages.Python,
    names: ['Python', 'py']
});
langNames.push({
    lang: ProgrammingLanguages.TypeScript,
    names: ['TypeScript', 'TS']
});
langNames.push({
    lang: ProgrammingLanguages.CPP,
    names: ['CPP', 'C++']
});
langNames.push({
    lang: ProgrammingLanguages.Kotlin,
    names: ['Kotlin']
});

export default langNames;
