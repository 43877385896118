// Файл с шаблонами кода для языков программирования

// C
const cSource = '\
#include <stdio.h>\n\
\n\
int main(void) {\n\
    printf("Hello world!\\n");\n\
    return 0;\n\
}\n\
';

// C#
const csharpSource = '\
public class Hello {\n\
    public static void Main() {\n\
        System.Console.WriteLine("hello, world");\n\
    }\n\
}\n\
';

// C++
const cppSource = '\
#include <iostream>\n\
\n\
int main() {\n\
    std::cout << "hello, world" << std::endl;\n\
    return 0;\n\
}\n\
';

// Go
const goSource = '\
package main\n\
\n\
import "fmt"\n\
\n\
func main() {\n\
    fmt.Println("hello, world")\n\
}\n\
';

// Java
const javaSource = '\
public class Main {\n\
    public static void main(String[] args) {\n\
        System.out.println("hello, world");\n\
    }\n\
}\n\
';

// JavaScript
const javaScriptSource = 'console.log("hello, world");';

// Kotlin
const kotlinSource = '\
fun main() {\n\
    println("hello, world")\n\
}\n\
';

// PHP
const phpSource = '\
<?php\n\
print("hello, world\\n");\n\
?>\n\
';

// Python
const pythonSource = 'print("hello, world")';

// TypeScript
const typescriptSource = 'console.log("hello, world");';

export {
    cSource,
    cppSource,
    csharpSource,
    pythonSource,
    goSource,
    javaScriptSource,
    javaSource,
    kotlinSource,
    phpSource,
    typescriptSource
};
