<template>
    <div
        ref="rootElRef"
        class="dropzone-component"
        :class="dropzoneComponentClasses"
    >
        <div
            v-if="isFadeWindow"
            v-show="isVisibleFadeBlock"
            class="full-window full-height full-width"
        >
        </div>
        <div
            :id="dropZoneId"
            :action="baseApiUrl + '/MultipartUpload/AddPart'"
            :style="{ zIndex: isVisibleFadeBlock ? 3500 : '' }"
            class="dropzone"
        ></div>
        <div class="q-mt-md">
            <div
                v-for="item in errors"
                :key="item.id"
                class="text-error">{{ item.text }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import * as DropzoneJS from 'dropzone';
    import { ErrorDto, FileClient, MultipartUploadClient, UserArea } from 'src/api/ApiClient';
    import 'dropzone/dist/dropzone.css';
    import { getApiClientInitialParams, getBaseApiUrl } from 'src/api/BaseApiClient';
    import { localize } from 'src/services/LocalizationService';
    import Logger from 'src/services/Logger';
    import { IDropzoneFile, IFile, IFileError } from './interfaces';
    import {
        computed,
        defineComponent,
        onBeforeUnmount,
        onMounted,
        PropType,
        ref,
        watch,
    } from 'vue';
    import Bowser from 'bowser';
    import Dropzone from 'dropzone';

    export default defineComponent({
        name: 'Dropzone',

        emits: [
            'on-added-file',
            'on-deleted-file',
            'on-upload-completed'
        ],

        props: {
            dropZoneId: {
                type: String,
                default: 'myDropzone'
            },
            // Проверяем если пользователь уже загружал такой файл - получаем информацию для дропзоны.
            // !!Осторожно, использовать когда это действительно необходимо
            checkIfFileAlreadyUploaded: {
                type: Boolean,
                default: false
            },
            needToEncode: {
                type: Boolean,
                default: null
            },
            toCompress: {
                type: Boolean,
                default: false
            },
            // Область для сохранения, по умолчанию используется значение UserArea.Protected
            area: {
                type: Number as PropType<UserArea>,
                default: UserArea.Protected
            },
            countOfFiles: {
                type: Number,
                default: 20
            },
            maxFileSize: {
                type: Number,
                default: 20480
            },
            acceptedExtensions: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            files: {
                type: Array as PropType<IFile[]>,
                default: () => []
            },
            // Нужно ли затемнять экран при перетаскивании файлов
            isFadeWindow: {
                type: Boolean,
                default: false
            },
            // Элемент, на который нужно навешивать события по перетаскиванию файла
            dragAreaSelector: {
                type: String,
                default: ''
            }
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            let dropzone: DropzoneJS | null = null;
            const errors = ref<IFileError[]>([]);
            const baseApiUrl: string = getBaseApiUrl();
            const rootElRef = ref<HTMLDivElement>();
            const isVisibleFadeBlock = ref<boolean>(false);

            // Количество загруженных файлов
            const countUploadedFiles = ref<number>(0);

            watch(() => props.disabled, (disabled: boolean) => {
                if (!dropzone) {
                    return;
                }

                if (disabled) {
                    dropzone.disable();
                } else {
                    dropzone.enable();
                }
            });

            const dropzoneComponentClasses = computed(() => {
                // Описание чисел в выражении this.isMobile ? 2 : 4
                // Число 2 - переносим иконку с плюсом после 2-х загруженных файлов
                // Число 4 - переносим иконку с плюсом после 4-х загруженных файлов
                const countFilesForNextLine = countUploadedFiles.value % (isMobile.value ? 2 : 4) === 0;

                return {
                    'is-show-more-files-button': isShowMoreFilesButton.value,
                    'more-files-button-next-line': isShowMoreFilesButton.value && countFilesForNextLine,
                    'disabled': props.disabled,
                };
            });

            // Запускается ли сайт на мобильном устройстве
            const isMobile = computed<boolean>(() => {
                return window.innerWidth <= 500;
            });

            const isShowMoreFilesButton = computed<boolean>(() => {
                return !!countUploadedFiles.value && countUploadedFiles.value < props.countOfFiles;
            });

            //region Функции вызываются из других модулей
            function getAcceptedFiles(): IDropzoneFile[] {
                return dropzone?.getAcceptedFiles() as IDropzoneFile[] || [];
            }

            function getFileSecretIds(): { id: number, secretKey: string}[] {
                return getAcceptedFiles().map((file: IDropzoneFile) => {
                    return {
                        id: file.fileId,
                        secretKey: file.secretKey
                    };
                });
            }

            function removeAllFiles(cancelIfNecessary?: boolean): void {
                dropzone?.removeAllFiles(cancelIfNecessary);
            }

            function addFile(file: DropzoneJS.DropzoneFile): void {
                dropzone?.addFile(file);
            }
            //endregion

            function acceptFile(file: Dropzone.DropzoneFile, done: (error?: string | Error) => void): void {
                // так как onAcceptFile асинхронная и дожидается запросов
                // валидация отрабатывает не всегда корректно
                // поэтому количество загружаемых файлов контролируем вручную
                if (dropzone?.options.maxFiles && dropzone.files.length > dropzone.options.maxFiles) {
                    const fileIndex = dropzone.files.indexOf(file);

                    if (fileIndex >= dropzone.options.maxFiles) {
                        done(dropzone?.options.dictMaxFilesExceeded);
                    } else {
                        onAcceptFile(file as IDropzoneFile, done);
                    }
                } else {
                    onAcceptFile(file as IDropzoneFile, done);
                }
            }

            async function onAcceptFile(file: IDropzoneFile, done: (msg: string | undefined) => void): Promise<void> {
                // Сделано для файлов, которые уже были загружены и сохранены за сущностью
                if (file.isDropzoneMockFile) {
                    // костыль, что бы не отображался прогресс бар
                    file.previewElement.classList.add('dz-processing');
                    // добавляем файл во внутреннее хранилище файлов дропзоны
                    dropzone?.files.push(file as Dropzone.DropzoneFile);
                    done(undefined);
                    return;
                }

                if (file.size === undefined) {
                    done(localize('Не удалось узнать размер файла'));
                    return;
                }

                if (props.checkIfFileAlreadyUploaded) {
                    const res = await new MultipartUploadClient(getApiClientInitialParams()).checkIfAlreadyUploaded(file.name, file.size);
                    Logger.log('Dropzone -> onAcceptFile -> checkIfFileAlreadyUploaded -> res', res);

                    if (res.isSuccess) {
                        file.noNeedDeleteFromServer = true;
                        dropzone?.removeFile(file as Dropzone.DropzoneFile);
                        mockFiles([{
                            ...res.entity,
                            secretKey: res.entity.secretKey || '',
                            previewForDropzone: ''
                        }]);
                        done(undefined);
                        return;
                    }
                }

                const result = await new MultipartUploadClient(getApiClientInitialParams()).initial({
                    name: file.name,
                    contentType: undefined,
                    fileSize: file.size,
                    toCompress: props.toCompress,
                    needToEncode: props.needToEncode,
                    uuid: file.secretKey,
                    area: props.area,
                });

                if (result.isSuccess) {
                    if (file.upload !== undefined) {
                        file.upload.uuid = result.entity.uuid;
                    }

                    file.secretKey = result.entity.uuid;
                } else {
                    done(
                        localize('Ошибка') +
                            ':\n' +
                            result.errors.map((re: ErrorDto) => (re.key ? `${re.key}: ${re.value}` : re.value)).join('\n')
                    );
                    return;
                }

                done(undefined);
            }

            async function chunksUploaded(file: IDropzoneFile, done: (error?: string | Error) => void): Promise<void> {
                if (file.upload && file.upload.uuid) {
                    const result = await new MultipartUploadClient(getApiClientInitialParams()).complete(file.upload.uuid);

                    if (result.isSuccess) {
                        file.fileId = result.entity.id;
                        file.secretKey = result.entity.secretKey;

                        try {
                            file.path = result.entity.url ?? '';
                        } catch (e) {}

                        done();
                    } else {
                        file.accepted = false;
                        file.status = DropzoneJS.ERROR;
                        dropzone?.emit('error', file, 'error', result.errors[0] ? result.errors[0].value : 'error');
                        dropzone?.emit('complete', file);
                    }
                } else {
                    Logger.log('chunksUploaded --> file.upload.uuid is undefined');
                }
            }

            function onAddedFile(file: IDropzoneFile): void {
                context.emit('on-added-file', file);
                countUploadedFiles.value = dropzone?.files.length ?? 0;
                isVisibleFadeBlock.value = false;

                // Сделано для файлов, которые уже были загружены и сохранены за сущностью
                if (file.isDropzoneMockFile) {
                    // костыль, что бы не отображался прогресс бар
                    file.previewElement.classList.add('dz-processing');
                    // добавляем файл во внутреннее хранилище файлов дропзоны
                    dropzone?.files.push(file as Dropzone.DropzoneFile);
                }

                filterNameFile();
            }

            function onError(file: IDropzoneFile, error: string | Error): void {
                Logger.err('Dropzone -> error -> file', file);
                Logger.err('Dropzone -> error -> error', error);

                removeError(file);
                errors.value.push({
                    id: makeFileId(file),
                    text: file.name + ': ' + error
                });
            }

            function onRemovedFile(file: IDropzoneFile): void {
                context.emit('on-deleted-file', file);
                removeError(file);
                countUploadedFiles.value = dropzone?.files.length ?? 0;

                // флаг noNeedDeleteFromServer ставится в true, когда не нужно удалять
                // файл на сервере при нажатии на кнопку "Удалить файл"
                // обычно это делается для уже загруженных файлов,
                // которые нам нужно отобразить, например, на странице редактирования
                // потому что пользователь может удалить файл, но не нажать кнопку "Сохранить"
                // для только что загруженных файлов это флаг будет false и они будут удаляться
                if (file.noNeedDeleteFromServer) {
                    return;
                }

                if (file.secretKey) {
                    new FileClient(getApiClientInitialParams()).removeFile(file.secretKey);
                }
            }

            function onComplete(): void {
                filterNameFile();

                if (dropzone?.getUploadingFiles().length === 0 && dropzone?.getQueuedFiles().length === 0) {
                    context.emit('on-upload-completed');
                }

                countUploadedFiles.value = dropzone?.files.length ?? 0;
            }

            // Сокрашаем текст в превью
            function filterNameFile(): void {
                const arrImages: NodeList | undefined = rootElRef.value?.querySelectorAll('.dropzone-component .dz-preview');

                if (arrImages && arrImages.length) {
                    arrImages.forEach((el: Node) => {
                        let text: string = (el as HTMLElement).querySelector('.dz-filename span')!.textContent!;
                        text = text.length > 30 ? text.substr(0, 30) + '...' : text;
                        (el as HTMLElement).querySelector('.dz-filename span')!.textContent = text;
                    });
                }
            }

            function onCanceled(file: IDropzoneFile): void {
                countUploadedFiles.value = dropzone?.files.length ?? 0;

                const uuid = file.upload?.uuid ?? file.secretKey;
                if (!uuid) {
                    return;
                }

                new MultipartUploadClient(getApiClientInitialParams()).abort(uuid);
            }

            function makeFileId(file: IDropzoneFile): string {
                let id = file.name.replace(/[\.\s]/g, '_');
                id = id.replace(/[^A-Za-z0-9_]/g, '');
                return 'errorMessage_' + id;
            }

            function removeError(file: IDropzoneFile): void {
                const id = makeFileId(file);
                const index = errors.value.findIndex((x: IFileError) => x.id === id);

                if (index !== -1) {
                    errors.value.splice(index, 1);
                }
            }

            function getInitOptions(): DropzoneJS.DropzoneOptions {
                const apiConfig = getApiClientInitialParams();
                const headers = {
                    'Authorization': 'Bearer ' + apiConfig.AuthToken,
                    'Accept-Language': apiConfig.Lang || '',
                };

                return {
                    paramName: 'receivedFile',
                    maxFilesize: props.maxFileSize,
                    maxFiles: props.countOfFiles,
                    acceptedFiles: props.acceptedExtensions,
                    addRemoveLinks: true,
                    timeout: 0,
                    autoProcessQueue: true,
                    uploadMultiple: false,
                    // в dropzone.js поле params не типизировано
                    params(files: any, xhr: any, chunk: any) {
                        if (chunk) {
                            return {
                                partIndex: chunk.index,
                                partSize: chunk.dataBlock.data.size,
                                partTotalCount: chunk.file.upload.totalChunkCount,
                                uuid: chunk.file.upload.uuid,
                            };
                        }
                    },
                    parallelUploads: 3,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: false,
                    chunkSize: 10000000,
                    retryChunks: true,
                    retryChunksLimit: 40,
                    headers,
                    accept: acceptFile,
                    chunksUploaded,

                    dictDefaultMessage: `
                        <span class="file-preview"></span>
                        ${localizeText('Нажмите или перетащите файлы сюда')}
                    `,
                    dictRemoveFile: localizeText('Удалить'),
                    dictInvalidFileType: localizeText('Формат файла не разрешен для загрузки.'),
                    dictMaxFilesExceeded: props.countOfFiles === 1 ?
                        localizeText('Невозможно загрузить более одного файла за один раз.') :
                        localizeText('Невозможно загрузить более {maxFiles} файлов за один раз.', { maxFiles: props.countOfFiles }),
                    dictCancelUpload: localizeText('Отменить загрузку'),
                    dictFallbackMessage: localizeText('Ваш браузер не поддерживает загрузку файлов перетаскиванием.'),
                    dictFallbackText: localizeText('Пожалуйста, воспользуйтесь формой ниже, чтобы загрузить файл обычным способом.'),
                    dictFileTooBig: localizeText('Файл слишком большой ({{filesize}} Мб). Максимальный размер файла: {{maxFilesize}} Мб.'),
                    dictResponseError: localizeText('Произошла ошибка, попробуйте снова.'),
                    dictCancelUploadConfirmation: localizeText('Вы уверены, что хотите отменить загрузку?'),
                };
            }

            function mockFiles(files: IFile[]): void {
                files.forEach((file: IFile) => {
                    let preview = 'images/icons/rect.svg';
                    if (file.previewForDropzone) {
                        preview = file.previewForDropzone;
                    }

                    const mockFile = {
                        fileId: file.id,
                        secretKey: file.secretKey,
                        name: file.fileName,
                        size: file.size,
                        isDropzoneMockFile: true,
                        status: 'success',
                        accepted: true,
                        noNeedDeleteFromServer: true
                    };

                    dropzone?.displayExistingFile(mockFile, preview);
                });
            }

            function startDrag(event: DragEvent): void {
                event.preventDefault();
                isVisibleFadeBlock.value = true;
            }

            function isSafariBrowser(): boolean {
                return Bowser.getParser(window.navigator.userAgent).getBrowserName() === 'Safari';
            }

            function stopDrag(event: DragEvent): void {
                event.preventDefault();

                if (event.type === 'dragleave') {
                    // Событие dragleave обрататываем только когда курс вышел за пределы окна браузера
                    if ((event.clientX === 0 && event.clientY === 0) || (!event.relatedTarget && !isSafariBrowser())) {
                        isVisibleFadeBlock.value = false;
                    }
                } else {
                    isVisibleFadeBlock.value = false;
                }
            }

            function getDropzoneArea(): HTMLElement | null {
                if (props.dragAreaSelector) {
                    return document.querySelector(props.dragAreaSelector);
                }

                return document.body;
            }

            function localizeText(text: string, formatterArguments: Record<string, string | number> | null = null): string {
                return localize(text, formatterArguments);
            }

            onMounted(() => {
                dropzone = new DropzoneJS.default('#' + props.dropZoneId, getInitOptions());
                dropzone.on('addedfile', onAddedFile);
                dropzone.on('error', onError);
                dropzone.on('removedfile', onRemovedFile);
                dropzone.on('complete', onComplete);
                dropzone.on('canceled', onCanceled);

                if (props.files.length) {
                    mockFiles(props.files);
                }

                if (props.isFadeWindow) {
                    const dropzoneAreaEl = getDropzoneArea();

                    dropzoneAreaEl?.addEventListener('dragenter', startDrag);
                    dropzoneAreaEl?.addEventListener('dragover', startDrag);
                    dropzoneAreaEl?.addEventListener('dragend', stopDrag);
                    dropzoneAreaEl?.addEventListener('drop', stopDrag);
                    dropzoneAreaEl?.addEventListener('dragleave', stopDrag);
                }
            });

            onBeforeUnmount(() => {
                // Когда у дропзоны вызывается destroy, удаляются все файлы
                // так как обработчик удаления у нас свой, то идут запросы
                // на удаление файла на сервере, чего нам не нужно,
                // логика удаления на сервере завязана на флаге noNeedDeleteFromServer
                // поэтому сначала отключаем обработчик removedfile, а потом вызываем destroy
                dropzone?.off('removedfile', onRemovedFile);
                dropzone?.destroy();

                if (props.isFadeWindow) {
                    const dropzoneAreaEl = getDropzoneArea();

                    dropzoneAreaEl?.removeEventListener('dragenter', startDrag);
                    dropzoneAreaEl?.removeEventListener('dragover', startDrag);
                    dropzoneAreaEl?.removeEventListener('dragend', stopDrag);
                    dropzoneAreaEl?.removeEventListener('drop', stopDrag);
                    dropzoneAreaEl?.removeEventListener('dragleave', stopDrag);
                }
            });

            return {
                rootElRef,
                errors,
                isVisibleFadeBlock,
                baseApiUrl,
                dropzoneComponentClasses,
                isMobile,
                isShowMoreFilesButton,
                countUploadedFiles,
                getAcceptedFiles,
                getFileSecretIds,
                removeAllFiles,
                addFile,
            };
        }
    });
</script>

<style lang="scss">
    .dropzone-component {
        &.is-show-more-files-button {
            .dropzone::after {
                content: '+';
                display: inline-flex;
                font-size: 40px;
                justify-content: center;
                align-items: center;
                width: 90px;
                height: 90px;
                border-radius: 20px;
                border: 2px solid $shade-2;
                position: absolute;
                margin: 15px 0 0 15px;
            }
        }

        &.more-files-button-next-line {
            .dropzone {
                padding-bottom: 120px;

                &::after {
                    display: flex;
                }
            }
        }

        &.disabled {
            opacity: 1 !important;

            .dropzone {
                color: $shade-7 !important;
            }
        }

        .dropzone {
            position: relative;
            min-height: auto;
            padding: 0;
            border: 1px dashed #D8DEE1;
            border-radius: 8px;
            color: $accent;
            z-index: 1;

            .file-preview {
                display: inline-block;
                width: 93px;
                height: 77px;
                background-image: url('assets/drop-files.svg');
                background-repeat: no-repeat;
                background-size: contain;
                vertical-align: middle;
            }

            .dz-preview {
                .dz-progress .dz-upload {
                    background: $success;
                }

                .dz-details {
                    min-width: auto;
                    width: 100%;
                    max-width: 90px;
                    padding: 0.6em;
                    opacity: 1;
                }

                .dz-progress {
                    left: 5px;
                    margin-left: 0;
                    z-index: 10;
                }

                .dz-image {
                    width: 90px;
                    height: 90px;

                    img {
                        margin: 0 auto;
                        max-height: 90px;
                    }
                }

                .dz-remove {
                    max-width: 90px;
                    margin-top: 4px;
                }

                .dz-filename:hover {
                    span {
                        display: block;
                        max-width: 150px;
                        white-space: normal;
                        width: 150px;
                        position: relative;
                        left: -33px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        z-index: 1000;
                    }
                }
            }

            .dz-error-message {
                top: 125px;
                left: -55px;
                background: $black !important;
                font-size: 12px;
                max-width: 300px;
                width: 200px;

                &:after {
                    border-bottom: 6px solid $black;
                    left: 95px;
                }

                @media (max-width: 700px) {
                    width: 125px;
                    left: -20px;

                    &:after {
                        left: 60px;
                    }
                }
            }
        }
    }
</style>
