<template>
    <q-page v-if="isLoading" class="bg-shade-10 auth-page window-height row justify-center">
        <div class="text-center q-pt-xl">
            <q-spinner-dots color="primary" size="4em" />
        </div>
    </q-page>
    <q-page v-else class="bg-shade-10 auth-page row justify-center items-center">
        <div
            :class="{ 'justify-center' : changePasswordEmailConfirmationSent }"
            class="column q-mb-md"
            style="flex: 1;"
        >
            <template v-if="!changePasswordEmailConfirmationSent && !isLoading">
                <div class="auth-page__top justify-center row items-end">
                    <OdinIcon class="login-odin-icon" aria-hidden="true" />
                    <h1 class="odin-title">{{ localize('Odin') }}</h1>
                </div>
                <q-card class="auth-page__form q-pa-md">
                    <q-form @keydown.enter="sendForgot">
                        <div class="text-primary text-center q-mb-lg">{{ localize('Восстановить пароль') }}</div>
                        <q-input
                            ref="emailRef"
                            clearable
                            v-model="form.email"
                            type="email"
                            lazy-rules="ondemand"
                            :label="localize('Email')"
                            :rules="[isRequiredEmail]"
                            @keydown.enter.prevent
                        />
                    </q-form>
                    <vue-smartcaptcha
                        :site-key="getConfig().SmartCaptchaSiteKey"
                        @success="verifyCaptcha"
                    ></vue-smartcaptcha>
                    <div class="text-center q-mt-md" style="line-height: 1;">
                        <span class="auth-page__link without-border">{{ localize('Нажимая на кнопку “Отправить”, вы даете свое') }}</span>
                        <router-link
                            :to="getAgreementLink()"
                            target="_blank"
                            class="auth-page__link without-border"
                        >
                            {{ localize('согласие на обработку персональных данных') }}
                        </router-link>
                    </div>
                    <div class="row justify-end items-center q-mt-md">
                        <q-btn
                            flat
                            color="primary"
                            type="link"
                            class="q-mr-sm"
                            :to="{name: loginPageName}"
                        >
                            {{ localize('Назад') }}
                        </q-btn>
                        <q-btn
                            unelevated
                            color="primary"
                            :disable="!form.captchaToken"
                            :label="localize('Отправить')"
                            @click="sendForgot"
                        />
                    </div>
                </q-card>
            </template>
            <div class="send-email-success-message with-image" v-if="changePasswordEmailConfirmationSent && !isLoading">
                <img src="~assets/lock-password.png" class="top-image" alt="" />
                <div class="row">
                    <div class="content-wrapper">
                        <div class="title-block">{{ localize('Восстановление пароля') }}</div>
                        <div class="row">
                            <div
                                class="text"
                                v-html="localize('Вам на почту {email} было отправлено письмо с адреса <b>noreply@odin.study</b>. Перейдите по ссылке, указанной в письме для создания нового пароля', { email: form.email })"
                            ></div>
                        </div>
                    </div>
                    <img src="~assets/lock-password.png" class="q-mt-sm right-image" alt="" />
                </div>
                <q-btn
                    flat
                    color="primary"
                    type="link"
                    class="q-ml-lg"
                    :to="{name: loginPageName}"
                >
                    {{ localize('Назад') }}
                </q-btn>
            </div>
        </div>
        <div class="auth-page__bottom row justify-between full-width q-mt-lg">
            <div class="auth-page__support text-center">
                <div class="auth-page__support_icon">
                    <HelpIcon/>
                </div>
                <a href="mailto:support@odin.study" target="_blank" class="link">support@odin.study</a>
            </div>
            <LocaleSettings />
        </div>
    </q-page>
</template>

<script lang="ts">
    import { localize } from 'src/services/LocalizationService';
    import VueSmartcaptcha from 'src/components/VueSmartcaptcha';
    import { AccountClient, ForgotPasswordRequestModel, Result, RoutePageNameEnum } from 'src/api/ApiClient';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { getConfig } from 'src/services/config';
    import { scrollToFirstInvalidField } from 'src/helpers/serverValidation';
    import { Common } from 'src/helpers/Common';
    import { defineComponent, getCurrentInstance, reactive, ref } from 'vue';
    import useValidateAllFields from 'src/helpers/custom-hooks/useValidateAllFields';
    import useValidationRules from 'src/helpers/custom-hooks/useValidationRules';
    import { useRouter } from 'vue-router';
    import LocaleSettings from 'pages/Login/components/LocaleSettings.vue';

    export default defineComponent({
        name: 'ForgotPassword',

        components: {
            LocaleSettings,
            VueSmartcaptcha
        },

        // eslint-disable-next-line max-lines-per-function
        setup() {
            const app = getCurrentInstance();
            const $router = useRouter();

            // Правила валидации
            const { isRequiredEmail } = useValidationRules();

            // Модель для смены пароля
            const form = reactive<ForgotPasswordRequestModel>({
                email: '',
                captchaToken: ''
            });

            // Флаг загрузки данных
            const isLoading = ref<boolean>(false);

            // Нужно ли показывать блок с инфо об отправке
            const changePasswordEmailConfirmationSent = ref<boolean>(false);

            // Ссылка на страницу авторизации
            const loginPageName: string = Common.getRouteName(RoutePageNameEnum.Login);

            // Отправить запрос на восстановление пароля
            async function sendForgot(): Promise<void> {
                const { isInvalid } = useValidateAllFields(app?.refs);

                if (isInvalid) {
                    // Прокрутить страницу до первого не валидного элемента
                    scrollToFirstInvalidField();
                    return;
                }

                isLoading.value = true;

                // Запрос для восстановления забытого пароля
                const request: Result = await new AccountClient(getApiClientInitialParams()).forgotPassword(form);

                if (!request.isSuccess) {
                    NotifyErrors(request);
                } else {
                    changePasswordEmailConfirmationSent.value = true;
                }

                isLoading.value = false;
            }

            // captcha которую ввел пользователь
            function verifyCaptcha(captcha: string): void {
                form.captchaToken = captcha;
            }

            // Открыть страницу Соглашение
            function getAgreementLink(): string {
                return $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.Agreement),
                    query: { type: 'PersonalData' },
                }).fullPath;
            }

            return {
                isRequiredEmail,
                form,
                isLoading,
                changePasswordEmailConfirmationSent,
                getConfig,
                loginPageName,
                localize,
                sendForgot,
                verifyCaptcha,
                getAgreementLink,
            };
        }

    });
</script>

<style scoped lang="scss">
    .auth-page__link {
        display: inline !important;

        &:last-child {
            text-decoration: underline;
        }
    }
</style>

<style lang="scss" scoped src='../Login/style.scss'></style>
