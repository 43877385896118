﻿// Типы сущностей для поиска
export enum SearchTabs {
    All = 'all',
    Activities = 'activities',
    Disciplines = 'disciplines',
    Materials = 'materials',
    People = 'people',
    Universities = 'universities',
    Divisions = 'divisions',
    Cohorts = 'cohorts',
    Groups = 'groups',
    Programs = 'programs'
}
