<template>
    <div class="custom-avatar" :style="styles" aria-hidden="true">
        {{ getShortLabel(text) }}
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, PropType } from 'vue';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import { Common } from 'src/helpers/Common';


    export default defineComponent({
        name: 'CustomAvatar',
        props: {
            // Размер букв в аватаре, если нет изображения
            fontSize: {
                type: Number,
                default: 10,
            },
            // Цвет аватара
            color: {
                type: String as PropType<CustomAvatarColors>,
                required: true,
            },
            // Текст, который будет отображаться в аватаре
            // в случае отсутствия изображения
            text: {
                type: String,
                required: true,
            },
        },
        /* eslint-disable-next-line max-lines-per-function  */
        setup(props) {
            // Стили для кастомного аватара
            const styles = computed(() => {
                return {
                    backgroundColor: props.color,
                    fontSize: `${props.fontSize}px`,
                };
            });

            function getShortLabel(name: string): string {
                // Если имя аватара разбивается на несколько частей
                // то значит скорее всего это имя студента и мы
                // прогоняем его через getShortUserName
                if (name) {
                    if (name.split(' ').length === 1) {
                        return name;
                    }

                    return Common.getShortUserName(name);
                }
                return '';
            }

            return {
                styles,
                getShortLabel,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .custom-avatar {
        justify-content: center;
        letter-spacing: -0.2px;
        align-items: center;
        line-height: 12px;
        font-size: 10px;
        display: flex;
        color: white;
        height: 100%;
        width: 100%;
    }
</style>
