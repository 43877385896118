﻿<template>
    <search-item
        @on-item-selected="$emit('on-item-selected')"
        :item-page-url="itemUrl"
        :item="item"
        :name="item.name"
        :type="SearchType.Content"
    >
        <template v-slot:info>
            <div class="info">
                {{ item.typeOfString }}
                <span v-for="author in authors" :key="author.id">• {{ author.formatedName }}</span>
            </div>
        </template>
        <template #append>
            <slot name="append"></slot>
        </template>
    </search-item>
</template>

<script lang="ts" setup>
    // Компонент для отображения данных о найденных материалах
    import {
        RoutePageNameEnum,
        SearchContentDto,
        SearchType,
        UserBaseInfoDto,
    } from 'src/api/ApiClient';
    import { Common } from 'src/helpers/Common';
    import SearchItem from '../SearchItem.vue';
    import { useGetEntityPageUrl } from '../../hooks/useGetEntityPageUrl';
    import { computed } from 'vue';

    const props = defineProps<{
        item: SearchContentDto;
    }>();
    const {
        getEntityPageUrl: getContentPageUrl,
    } = useGetEntityPageUrl(Common.getRouteName(RoutePageNameEnum.ContentInfo));

    defineEmits<{
        (e: 'on-item-selected'): void;
    }>();

    const authors = computed((): (UserBaseInfoDto & {formatedName: string})[] => {
        return props.item.authors.map((author) => ({
            ...author,
            formatedName: getFormatedName(author),
        }));
    });
    // URL для перехода на страницу подробной
    // информации о материале
    const itemUrl = computed(() => getContentPageUrl(props.item.id));

    function getFormatedName(user: UserBaseInfoDto): string {
        let name = `${user.lastName} ${user.firstName?.[0]}`;

        if (user.middleName) {
            name += `.${user.middleName[0]}`;
        }

        return name;
    }

</script>
