<template>
    <div class="auth-page__lang flex justify-center items-center no-wrap">
        <a
            :href="'/' + LocaleName.RU + $route.fullPath"
            @click.prevent="setLocale(LocaleName.RU)"
            class="link"
            :class="{ active: isActiveLang(LocaleName.RU) }"
        >
            РУС
        </a>
        <div class="separator">|</div>
        <a
            :href="'/' + LocaleName.EN + $route.fullPath"
            @click.prevent="setLocale(LocaleName.EN)"
            class="link"
            :class="{ active: isActiveLang(LocaleName.EN) }"
        >
            ENG
        </a>
    </div>
</template>

<script setup lang="ts">
    import { LocaleName } from 'src/types/LocaleName';
    import LocalizationService from 'src/services/LocalizationService';

    // Изменить язык интерфейса, если не совпадает с текущим языком интерфейса
    function setLocale(locale: LocaleName): void {
        const currentLocale = LocalizationService.getLocaleName();

        if (currentLocale !== locale) {
            LocalizationService.setLocaleName(locale);
            location.href = location.href.replace(new RegExp(`/${currentLocale}/`, 'g'), `/${locale}/`);
        }
    }

    // Проверка на текущий язык
    function isActiveLang(locale: LocaleName): boolean {
        return LocalizationService.getLocaleName() === locale;
    }
</script>

<style lang="scss" scoped>
    .link {
        margin: 0 3px;

        &.active {
            color: $shade-8;
        }
    }
</style>
