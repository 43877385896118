﻿<template>
    <div v-if="isLoading" class="text-center q-my-md">
        <q-spinner-dots color="primary" size="4em"/>
    </div>
    <template v-else>
        <div v-if="isPdfContent" class="latex-components">
            <iframe :src="url" id="latex" width="100%" height="99%" title="pdfLatex" class="m-0"></iframe>
        </div>
        <div
            v-else
            v-html="plainContent"
            class="plain-block">
        </div>
    </template>
</template>

<script lang="ts">
    import { ContentClient, getApiClientInitialParams } from 'src/api/ApiClient';
    import { defineComponent, onMounted, ref } from 'vue';

    // Компонент для отображения формул в формате LaTeX
    export default defineComponent({
        name: 'LatexViewer',

        props: {
            // Идентификатор материала
            contentId: {
                type: Number,
                default: 0,
                required: true,
            },
        },

        setup(props) {
            const url = ref<string>('');
            const isLoading = ref<boolean>(true);

            /* Является ли контентом для отображения pdf-файл */
            const isPdfContent = ref<boolean>(false);

            /* Контент для отображения html */
            const plainContent = ref<string|null>(null);

            onMounted(async () => {
                // Компиляция LaTeX материала в Pdf файл
                const response = await new ContentClient(getApiClientInitialParams()).tryGetCompiledLatex(props.contentId);
                isPdfContent.value = response.isSuccess;

                if (response.isSuccess) {
                    url.value = response.entity.value;
                } else {
                    plainContent.value = response.errors.map((e) => e.value)[0];
                }

                isLoading.value = false;
            });

            return {
                url,
                isLoading,
                isPdfContent,
                plainContent
            };
        },

    });
</script>

<style lang="scss">
    .latex-components {
        iframe {
            min-height: 650px;
        }

        @media (max-width: 700px) {
            iframe {
                min-height: 400px;
            }
        }

        .plain-block {
            max-height: 65vh;
        }
    }
</style>
