﻿<template>
    <div class="search-activities-tab search-card">
        <div class="header q-mb-sm flex justify-between items-baseline">
            <span class="title q-mr-sm">{{ localize('История') }}</span>
            <div class="clear-button" @click="clearHistory">{{ localize('Очистить историю') }}</div>
        </div>
        <q-card>
            <div v-if="isLoading" class="text-center">
                <q-spinner-dots color="primary" size="4em" />
            </div>

            <history-item
                v-else
                v-for="item in searchTerms"
                :searchTerm="item.searchTerm"
                :key="item.id"
                @on-remove="() => removeItem(item)"
                @on-item-selected="onItemSelected"
            />

            <no-items
                v-if="!isLoading && searchTerms.length === 0"
                :noShadow="true"
                :showTitle="false" />
        </q-card>
    </div>
</template>

<script lang="ts">
    import { defineComponent, Ref, ref, onMounted } from 'vue';
    import {
        getApiClientInitialParams,
        Result,
        ResultOf,
        SearchClient,
        SearchTermDto,
        SearchTermsResponseModel,
    } from 'src/api/ApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { localize } from 'src/services/LocalizationService';
    import HistoryItem from 'layouts/Main/components/searchBlock/components/historyTab/HistoryItem.vue';

    // Компонент отображается когда еще ни чего не ввели в поле поиска
    export default defineComponent({
        name: 'HistoryTab',
        components: { HistoryItem },
        emits: ['on-item-selected'],
        /* eslint-disable-next-line max-lines-per-function  */
        setup(props, context) {
            // Элементы истории поиска
            const searchTerms: Ref<{ id: number, searchTerm: SearchTermDto }[]> = ref([]);

            // Клиент для работы с историей поиска
            const apiClient: SearchClient = new SearchClient(getApiClientInitialParams());

            // Признак запущенной загрузки
            const isLoading: Ref<boolean> = ref(true);

            // Подтянуть историю поиска
            async function loadHistory(): Promise<void> {
                isLoading.value = true;

                const result: ResultOf<SearchTermsResponseModel> = await apiClient.searchTerms();
                if (!result.isSuccess) {
                    NotifyErrors(result);
                    return;
                }

                searchTerms.value = result.entity.searchTerms.map((term, index) => {
                    return {
                        id: index,
                        searchTerm: term,
                    };
                });
                isLoading.value = false;
            }

            // Очистить историю поиска
            async function clearHistory(): Promise<void> {
                const result: Result = await apiClient.removeAllSearchTerms();
                if (!result.isSuccess) {
                    NotifyErrors(result);
                    return;
                }

                searchTerms.value = [];
            }

            // Удалить элемент истории поиска по индексу
            async function removeItem(item: { id: number, searchTerm: SearchTermDto }): Promise<void> {
                const result: Result = await apiClient.removeSearchTerm(item.searchTerm);
                if (!result.isSuccess) {
                    NotifyErrors(result);
                    return;
                }

                searchTerms.value = searchTerms.value.filter((value) => value.id !== item.id);
            }

            function onItemSelected(): void {
                context.emit('on-item-selected');
            }

            onMounted(() => loadHistory());

            return {
                isLoading,
                searchTerms,
                localize,
                clearHistory,
                removeItem,
                onItemSelected,
            };
        },
    });

</script>
