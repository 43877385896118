
// Режим отображения чата
// inline - в блоке справа
// page - чат на отдельной странице
enum ModeChat {
    Inline = 'inline',
    Page = 'page',
}

export {
    ModeChat
};
