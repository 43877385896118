import { getApiClientInitialParams } from 'src/api/BaseApiClient';
import { AccountClient, AccountCurrentInfoResponseModel, ResultOf } from 'src/api/ApiClient';
import { NotifyErrors } from 'src/api/ResultOfMethods';
import { routerBas } from 'components/EventBuses';
import { AccountStoreInterface } from 'src/store/module-account/state';
import { ActionContext } from 'src/store/type';
import { RouterBusEvents } from 'components/EventBuses/emuns';


const actions = {
    async loadAccountInfo(this: ActionContext<'accountStore', AccountStoreInterface>): Promise<AccountCurrentInfoResponseModel | null> {
        let accountInfo = null;

        try {
            // Получение информации о текущем пользователе
            const result: ResultOf<AccountCurrentInfoResponseModel> = await new AccountClient(getApiClientInitialParams()).currentInfo();

            if (result.isSuccess) {
                accountInfo = result.entity;
                this.$patch({
                    accountInfo
                });
            } else {
                // Отображаем ошибки которые вернул запрос
                NotifyErrors(result);
            }
        } catch (e) {
            routerBas.emit(RouterBusEvents.ToLogin);
        }

        return accountInfo;
    },

};

export default actions;
