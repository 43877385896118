// Типы сущностей для поиска
export enum SearchEntity {
    Activities = 'activities',
    Cohorts = 'cohorts',
    Disciplines = 'disciplines',
    Divisions = 'divisions',
    Programs = 'eduPrograms',
    Groups = 'groups',
    Materials = 'contents',
    People = 'users',
    Universities = 'universities'
}