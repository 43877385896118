<template>
    <div>
        <div class="text-center" v-if="isLoading">
            <q-spinner-dots color="primary" size="4em"/>
        </div>
        <search-discipline-item
            v-else
            v-for="discipline in disciplines"
            :key="discipline.id"
            :item="discipline"
            @on-item-selected="onItemSelected"/>
        <show-all-btn v-if="canShowAll" @show-all="showAll"/>
    </div>
</template>

<script lang="ts" setup>
    import { Ref, ref, toRefs } from 'vue';
    import { SearchDisciplineDto } from 'src/api/ApiClient';
    import SearchDisciplineItem
        from 'layouts/Main/components/searchBlock/components/disciplinesTab/SearchDisciplineItem.vue';
    import { SearchTabs } from 'layouts/Main/components/searchBlock/types/searchTabs';
    import ShowAllBtn from '../ShowAllBtn.vue';
    import { useSearch } from '../../hooks/useSearch';
    import { SearchEntity } from '../../types/SearchEntity';

    // Компонент с отображением первых 5 найденных дисциплин
    // Отображается когда выбран поиск по всем сущностям
    const disciplines: Ref<SearchDisciplineDto[]> = ref([]);
    const emit = defineEmits<{
        (e: 'on-item-selected'): void;
        (e: 'on-found-items-count', itemsCount: number): void;
        (e: 'show-all', tab: SearchTabs): void;
    }>();
    const props = withDefaults(defineProps<{
        searchText: string;
    }>(), {
        searchText: '',
    });
    const { searchText } = toRefs(props);
    const {
        // Признак, отвечающий за загрузку данных
        isLoading,
        // Нужно ли показывать кнопку "показать все"
        canShowAll,
        // Обработчик выбора элемента
        onItemSelected,
        showAll,
    } = useSearch(SearchEntity.Disciplines, false, disciplines, searchText, emit);
</script>
