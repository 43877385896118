<template>
    <ui-select
        ref="selectRef"
        dense
        options-dense
        v-model="inputValue"
        :options="users"
        option-value="id"
        option-label="fullName"
        map-options
        :label="label"
        :loading="isLoadingUsers"
        :is-server-filter="true"
        @virtual-scroll="onScrollUsers"
        @filter="filterUsers"
        @on-change="onChange"
        @update:model-value="$emit('update:model-value', $event)"
        :error-message="errorMessage"
        :error="error"
        :rules="rules"
        popup-content-class="select"
        :use-input-force="true"
    >
        <template #selected-item="scope">
            <div class="ellipsis-2-lines">{{ scope.opt.fullName }}</div>
        </template>

        <template v-slot:option="scope">
            <template v-if="$slots['option']">
                <slot name="option" v-bind="scope" />
            </template>
            <template v-else>
                <q-item v-bind="scope.itemProps" class="q-pa-sm">
                    <q-item-section avatar aria-hidden="true">
                        <q-avatar size="32px">
                            <img v-if="scope.opt.photoPath" :src="scope.opt.photoPath" />
                            <custom-avatar
                                v-else
                                :font-size="14"
                                :text="scope.opt.fullName"
                                :color="CustomAvatarColors.User"
                            />
                        </q-avatar>
                    </q-item-section>

                    <q-item-section>
                        <q-item-label v-html="scope.opt.fullName" />
                        <q-item-label caption>{{ scope.opt.cityName }}</q-item-label>
                    </q-item-section>
                </q-item>
            </template>
        </template>

        <template v-slot:no-option>
            <q-item>
                <q-item-section class="text-grey">
                    {{ localize('Ничего не найдено') }}
                </q-item-section>
            </q-item>
        </template>

        <template v-if="inputValue" #append>
            <q-icon
                name="close"
                class="cursor-pointer"
                @click.stop="resetFilter"
            />
        </template>
    </ui-select>
</template>

<script lang="ts">
    import {
        PagedResultOfUserSelectModel,
        ResultOf,
        UserClient,
        UserSelectModel,
        UsersForSelectRequestType,
        UsersForSelectCommonRequest,
    } from 'src/api/ApiClient';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { QVirtualScroll } from 'quasar';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import { defineComponent, nextTick, ref, SetupContext } from 'vue';
    import useDataSelect, { componentProps } from 'components/ui/selects/DataSelect/useDataSelect';
    import { localize } from 'src/services/LocalizationService';

    /**
     * Дропдаун выбора пользователя
     */
    export default defineComponent({
        name: 'UserSelect',

        emits: [
            'on-change',
            'update:model-value'
        ],

        props: {
            modelValue: componentProps.value,
            label: componentProps.label,
            rules: componentProps.rules,
            error: componentProps.error,
            errorMessage: componentProps.errorMessage,
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            // Импортируем необходимые переменные и функции
            const {
                selectRef,
                inputValue,
                hasError,
                validate,
                resetFilter,
            } = useDataSelect(props, context as SetupContext);

            const users = ref<UserSelectModel[]>([]);
            const searchUserFilter = ref<string>('');
            const isLoadingUsers = ref<boolean>(false);
            let pageUser: number = 1;
            let canLoadUsers: boolean = true;

            // Получить список пользователей для выпадающего списка
            async function loadUsers(): Promise<UserSelectModel[]> {
                isLoadingUsers.value = true;
                const request: UsersForSelectCommonRequest = {
                    searchString: searchUserFilter.value,
                    pageSize: 20,
                    page: pageUser,
                    requestType: UsersForSelectRequestType.Users
                };
                const result: ResultOf<PagedResultOfUserSelectModel> = await new UserClient(getApiClientInitialParams()).getUsersInfoForSelect(request);
                canLoadUsers = result.entity.pagedMetaData.hasNextPage;

                if (canLoadUsers) {
                    pageUser++;
                }

                return result.entity.pageItems || [];
            }

            // Обработчик виртуального скрола
            async function onScrollUsers(params: { to: number, ref: QVirtualScroll }): Promise<void> {
                const lastIndex = selectRef.value?.allOptions.length - 1;

                if (!isLoadingUsers.value && canLoadUsers && params.to === lastIndex) {
                    const userItems = await loadUsers();

                    userItems.forEach((el: UserSelectModel) => {
                        users.value.push(el);
                    });

                    nextTick(() => {
                        params.ref.refresh();
                        isLoadingUsers.value = false;
                    });
                }
            }

            function filterUsers(val: string, update: (val: () => void) => void): void {
                pageUser = 1;
                searchUserFilter.value = val;

                loadUsers().then((values: UserSelectModel[]) => {
                    update(() => {
                        users.value = values;
                        // после вызова update почему-то два раза отрабатывает скролл
                        // и вместо одного запроса идут 3
                        // поэтому обновляем флаг с небольшой задержкой
                        setTimeout(() => {
                            isLoadingUsers.value = false;
                        }, 100);
                    });
                });
            }

            // Обработчик изменения значения
            function onChange(): void {
                if (props.modelValue) {
                    selectRef.value?.updateInputValue('', true);
                    context.emit('on-change', props.modelValue);
                } else {
                    context.emit('on-change', null);
                }
            }

            return {
                selectRef,
                CustomAvatarColors,
                hasError,
                validate,
                users,
                inputValue,
                isLoadingUsers,
                onScrollUsers,
                filterUsers,
                onChange,
                resetFilter,
                localize,
            };
        },
    });
</script>
