﻿import { CallEnterResponseModel } from 'src/api/ApiClient';
import { IInterlocutor } from 'pages/Call/types/interfaces';

// классы для описания стора для страницы звонка

export interface CallPageStoreInterface {
    userData: IInterlocutor;
    callInitialized: boolean;
    roomEnabled: boolean;
    interlocutors: IInterlocutor[];
    isEnablingVideo: boolean;
    callOnlyWithVideo: boolean;
    userRemoteFeedId?: number | null;
    selectedAudioInputDevice: string | null;
    selectedVideoInputDevice: string | null;
    selectedAudioOutputDevice: string | null;
    selectedBackgroundOption: number | null;
    callStartingData: CallEnterResponseModel | null;
    // Причина, по которой звонок завершился
    serviceMessage: string | null;
    ownFeedIsPublished: boolean;
    isDialed: boolean;
    chatIsVisible: boolean;
    roomsBlockIsVisible: boolean;
    tilesWidth: number;
    tilesHeight: number;
    pageIsWide: boolean;
    pageWidth: number;
    pageHeight: number;
    topPanelHeight: number;
    tilesBlockHeight: number;
    bottomPanelHeight: number;
    callPanelWidth: number;
    chatPanelWidth: number;
    isRecordingEnabled: boolean;
    isRecordingContinue: boolean;
    canEdit: boolean;
    isFullSharingMode: boolean;
    startCallTime: number;
    // флаг показа окна оценки звонка
    isVisibleEvaluationBlock: boolean;
    // Звонок ли это экзамена для мигрантов
    isMigrationExam: boolean;
    // Использовать ли Full HD в звонках
    isUseFullHd: boolean;
}

// eslint-disable-next-line max-lines-per-function
function state(): CallPageStoreInterface {
    // проставляем начальные значения для стора
    return {
        // информация о пользователе который начал звонок
        userData: {
            personId: 0, // Здесь пишется personId пользователя из базы. Тип - number
            rfid: null,
            nickname: null,
            avatarUrl: null,
            stream: null,
            videoTrack: null,
            sharingStream: null,
            isMicrophoneAllowed: true,
            isSharingAllowed: true,
            isTalking: false,
            isCallManager: false,
            isAutoPlayFailed: false,
            microphoneOn: false,
            videoOn: false,
            handOrder: 0,
            sortId: 0,
            riseHandTimeUtc: null
        },
        callInitialized: false,
        // Пользователи в звонке
        interlocutors: [],
        // Происходит ли процесс включения/выключения видео
        isEnablingVideo: false,
        // Подключиться можно только с камерой
        callOnlyWithVideo: false,
        // Id текущего пользователя в комнате janus
        userRemoteFeedId: null,
        // Идентификатор аудиоустройства, которое будет использоваться для вывода звука
        selectedAudioOutputDevice: null,
        // Идентификатор аудиоустройства, которое будет использоваться для ввода аудиоинформации
        selectedAudioInputDevice: null,
        // Идентификатор видеоустройства, которое будет использоваться для ввода видеоинформации
        selectedVideoInputDevice: null,
        // Выбранные фон для видео пользователя
        selectedBackgroundOption: null,
        // Информация о звонке и пользователе который начал звонок
        callStartingData: null,
        // Сообщение с которым завершился звонок
        serviceMessage: null,
        // Активен ли поток текущего пользователя
        ownFeedIsPublished: false,
        // Идет ли звонок или он завершен
        isDialed: false,
        // Завершен звонок или нет, отличие от isDialed в том
        // что isDialed меняется в зависимости от того, есть ли пользователи или нет
        // а roomEnabled мы меняем сами, изначально он roomEnabled что бы показывались компоненты
        // а когда завершаем ставим его в false
        roomEnabled: true,
        // Показан/скрыт чат
        chatIsVisible: false,
        // Показан/скрыт блок выбора комнаты
        roomsBlockIsVisible: false,
        // Ширина блока с плитками
        tilesWidth: 0,
        // Высота блока с плитками
        tilesHeight: 0,
        pageIsWide: true,
        pageWidth: 0,
        pageHeight: 0,
        topPanelHeight: 0,
        tilesBlockHeight: 0,
        bottomPanelHeight: 0,
        callPanelWidth: 0,
        chatPanelWidth: 0,
        // Включена ли запись звонка
        isRecordingEnabled: false,
        isRecordingContinue: false,
        canEdit: false,
        // Включен ли шаринг экрана
        isFullSharingMode: false,
        // Время начала звонка
        startCallTime: 0,
        isVisibleEvaluationBlock: false,
        isMigrationExam: false,
        isUseFullHd: false,
    };
}

export default state;
