﻿<template>
    <search-item
        avatar-text="Пр"
        :item="item"
        :name="item.name"
        :avatar-color="CustomAvatarColors.Program"
        :item-page-url="propgramPageUrl"
        :picture-url="item.photoUrl"
        :type="SearchType.EduProgram"
        @on-item-selected="$emit('on-item-selected')"
    >
        <template #info>
            <div class="info">{{ item.universityShortName }} • {{ item.divisionShortName }}</div>
            <div class="info" v-if="item.isAdditionalEducation">{{ localize('ДО') }}</div>
        </template>
        <template #append>
            <slot name="append"></slot>
        </template>
    </search-item>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import {
        RoutePageNameEnum,
        SearchEduProgramDto,
        SearchType,
    } from 'src/api/ApiClient';
    import { Common } from 'src/helpers/Common';
    import { localize } from 'src/services/LocalizationService';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import SearchItem from '../SearchItem.vue';
    import { useGetEntityPageUrl } from '../../hooks/useGetEntityPageUrl';

    /**
     * Компонент для отображение данных о найденной образовательной программе
     */

    const props = defineProps<{
        item: SearchEduProgramDto;
    }>();

    // События компонента
    defineEmits<{
        (e: 'on-item-selected'): void;
    }>();

    const {
        getEntityPageUrl: getProgramPageUrl,
    } = useGetEntityPageUrl(Common.getRouteName(RoutePageNameEnum.EducationalProgramInfo));

    // Ссылка на страницу программы
    const propgramPageUrl = computed(() => getProgramPageUrl(props.item.id));

</script>
