<template>
    <div class="chat-header-component">
        <div v-if="isLoading" class="text-center" style="margin-top: -8px;">
            <q-spinner-dots color="primary" size="4em" />
        </div>

        <div v-else class="relative-position" aria-hidden="true">
            <template v-if="!isVisibleCallsBlock">
                <div
                    v-if="currentScrollIndex > 0 && chats.length > countElementsInViewScroll"
                    @mousedown="() => scrollToLeft(true)"
                    @mouseup="stopScroll"
                    class="arrow-block left cursor-pointer"
                >
                    <Icon
                        name="DisclosureIcon"
                        size="12"
                        use-raw-color
                    />
                </div>
                <q-virtual-scroll
                    ref="virtualScrollRef"
                    :items="chats"
                    :virtual-scroll-item-size="tabWidth"
                    virtual-scroll-slice-size="20"
                    virtual-scroll-horizontal
                    v-slot="{ item: chat }"
                    @virtual-scroll="onScroll"
                    @wheel="scrollChats"
                    :aria-label="localize('Список чатов')"
                >
                    <div
                        :id="'sideChat' + chat.chatId"
                        :key="'sideChat' + chat.chatId"
                        :style="chatTabStyles"
                        class="tab-item text-center"
                    >
                        <div
                            @click="selectChat(chat)"
                            role="button"
                            tabindex="0"
                            :class="{
                                active: selectedChat && selectedChat.chatId === chat.chatId || !selectedChat && chat.type === ChatType.Self
                            }"
                            class="chat-item"
                            :aria-label="localize('Открыть чат') + ' ' + chat.name"
                        >
                            <q-avatar
                                size="24px"
                                class="chat-item__images"
                                :class="{
                                    'rounded-borders': chat.type === ChatType.Discipline || chat.type === ChatType.Group || chat.type === ChatType.Self && !chat.logoPath
                                }"
                                aria-hidden="true"
                            >
                                <count-unreaded-messages
                                    :discipline-id="chat.disciplineId"
                                    :chat-id="chat.chatId"
                                    :is-muted="chat.isMuted"
                                    mode="mini"
                                    class="count-unreaded-messages"
                                />
                                <img v-if="chat.logoPath" :src="chat.logoPath" alt="" />
                                <template v-else>
                                    <custom-avatar
                                        v-if="chat.type === ChatType.Private"
                                        :text="chat.name"
                                        :color="CustomAvatarColors.User"
                                    />

                                    <template v-else>
                                        <custom-avatar
                                            v-if="chat.type === ChatType.Discipline"
                                            text="Д"
                                            :color="CustomAvatarColors.Discipline"
                                        />
                                        <custom-avatar
                                            v-if="chat.type === ChatType.Self"
                                            :text="chat.name"
                                            :color="CustomAvatarColors.GroupChat"
                                        />
                                        <custom-avatar
                                            v-if="chat.type === ChatType.Group"
                                            text="Гч"
                                            :color="CustomAvatarColors.GroupChat"
                                        />
                                    </template>
                                </template>
                            </q-avatar>
                            <q-icon class="chat-tab_pinned" v-if="!!chat.pinnedAt" name="o_push_pin" />
                            <div class="ellipsis link">{{ chat.name }}</div>
                        </div>
                        <q-tooltip v-if="chats.length > 3">{{ chat.name }}</q-tooltip>
                    </div>
                </q-virtual-scroll>
                <div
                    v-if="currentScrollIndex < chats.length - 1 && chats.length > countElementsInViewScroll"
                    @mousedown="() => scrollToRight(true)"
                    @mouseup="stopScroll"
                    class="arrow-block right cursor-pointer"
                >
                    <Icon
                        name="DisclosureIcon"
                        size="12"
                        use-raw-color
                    />
                </div>
            </template>
            <q-tabs
                v-else
                v-model="currentTab"
                dense
                align="left"
                class="hide-arrows"
            >
                <q-tab
                    v-for="notify in callNotifications"
                    :id="'callNotify' + notify.callId"
                    :key="'callNotify' + notify.callId"
                    :name="'callNotify' + notify.callId"
                    class="tab-item"
                >
                    <div
                        @click="selectCallNotify(notify)"
                        :class="{ 'active': currentTab === 'callNotify' + notify.callId }"
                        class="chat-item"
                    >
                        <q-avatar size="24px" class="call-avatar" aria-hidden="true">
                            <img v-if="notify.image" :src="notify.image" alt="" />
                            <icon
                                v-else
                                name="AcceptCallIcon"
                                size="22"
                                class="call-icon absolute-center"
                            />
                        </q-avatar>
                        <div class="ellipsis link">{{ notify.disciplineName || notify.title }}</div>
                    </div>
                </q-tab>
            </q-tabs>
        </div>
        <div v-if="selectedNotify && isVisibleCallsBlock" class="call-notify-block">
            <div class="text-center" v-if="!callInfo">
                <q-spinner-dots color="primary" size="4em" />
            </div>
            <div v-else class="full-height">
                <div class="call-info-header row items-center justify-between">
                    <div class="col q-ml-md">
                        <div class="ellipsis">{{ callInfo.callChatName }}</div>
                        <div class="text-description">
                            {{ callInfo.expectedInterlocutorsData.length }}
                            {{ localize(getDeclMembers(callInfo.expectedInterlocutorsData.length)) }}
                        </div>
                    </div>
                    <ChatIcon @click="openCallChat" class="cursor-pointer q-mr-md">
                        <q-tooltip
                            anchor="top middle"
                            self="bottom middle"
                        >
                            {{ localize('Открыть чат звонка') }}
                        </q-tooltip>
                    </ChatIcon>
                </div>
                <q-separator />
                <div class="call-info-content">
                    <div class="call-info-content__logo-block text-center">
                        <q-avatar
                            size="70px"
                            class="bg-success cursor-pointer shake-animate"
                            text-color="white"
                            @click="acceptCall"
                        >
                            <AcceptCall2Icon width="34" height="34" viewBox="0 0 24 24"/>
                        </q-avatar>
                        <div class="q-my-lg q-mx-auto" style="max-width: 240px">
                            {{ localize('Присоединитесь, чтобы начать разговор') }}
                        </div>
                    </div>
                    <div class="q-px-md">
                        <q-separator />
                        <div class="row q-py-md">
                            <div class="col">
                                <div class="description q-mb-xs">{{ localize('Время начала') }}</div>
                                <div class="row items-center">
                                    <Icon name="TimeIcon" size="24" class="q-mr-xs" />
                                    <div>{{ getFormattedTime(callInfo.callStartDateTime) }}</div>
                                </div>
                            </div>
                            <div>
                                <div class="description q-mb-xs">{{ localize('Участники') }}</div>
                                <div class="row items-center">
                                    <GroupIcon class="q-mr-xs" />
                                    <div>{{ usersInCall.length }}</div>
                                </div>
                            </div>
                        </div>
                        <q-separator />
                        <div v-if="isWebCamRequired" class="row q-py-md">
                            <div class="col">
                                <div class="description q-mb-xs">{{ localize('Доступ к звонку') }}</div>
                                <div class="row items-center">
                                    <Icon
                                        name="CameraIcon"
                                        size="22"
                                        color="#B4B9D1"
                                        class="q-mr-xs"
                                    />
                                    <div>{{ localize('С видеокамерой') }}</div>
                                </div>
                            </div>
                        </div>
                        <q-separator v-if="isWebCamRequired" />
                    </div>
                    <q-scroll-area class="scroll-block">
                        <div class="q-px-md">
                            <div v-for="user in usersInCall" :key="user.id">
                                <div class="row items-center q-py-md">
                                    <q-avatar size="24px" class="bg-shade-2" text-color="white" aria-hidden="true">
                                        <img v-if="user.photoPreviewPath" :src="user.photoPreviewPath" alt="" />
                                        <custom-avatar
                                            v-else
                                            :text="user.lastName[0] + user.firstName[0]"
                                            :color="CustomAvatarColors.User"
                                        />
                                    </q-avatar>
                                    <div class="col q-ml-sm">{{ user.lastName }} {{ user.firstName }}</div>
                                </div>
                                <q-separator />
                            </div>
                        </div>
                    </q-scroll-area>
                </div>
                <!-- Кнопка отключения звонка остаётся на прежнем месте, но теперь на проде скрываем всю панель,
                так как кнопка "принять звонок" перенесена наверх -->
                <div v-if="!isProduction" class="call-action-panel text-center">
                    <div class="call-buttons">
                        <div class="call-button decline q-ml-sm">
                            <DeclineCallIcon class="absolute-center" @click="finishCall" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <q-resize-observer @resize="checkVisibleElements" :debounce="500" />
    </div>
</template>

<script lang="ts">
    import {
        ActivityOnlineMeetingFormat,
        CallClient,
        CallInfoResponseModel,
        CallInfoUserModel,
        ChatClient,
        ChatMessageClient,
        ChatMessageDto,
        ChatsInfoListItem,
        ChatType,
        ListChatDto,
        RoutePageNameEnum,
    } from 'src/api/ApiClient';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import CountUnreadedMessages from 'components/CountUnreadedMessages';
    import { Common } from 'src/helpers/Common';
    import { localize } from 'src/services/LocalizationService';
    import DateUtil from 'src/helpers/DateUtil';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import { ICallStartDto } from 'src/types/generated/hubs/callPartialHub/models/ICallStartDto';
    import {
        computed,
        defineComponent,
        onMounted,
        onBeforeMount,
        onBeforeUnmount,
        ref,
        Ref,
        watch,
        getCurrentInstance
    } from 'vue';
    import { useRouter } from 'vue-router';
    import { chatBus } from 'components/EventBuses';
    import { QVirtualScroll } from 'quasar';
    import { useNotificationsStore } from 'src/store/module-notifications';
    import { useMainLayoutStore } from 'src/store/module-main-layout';
    import { useChatStore } from 'src/store/module-chat';
    import { LiveDataPartialHub } from 'src/services/hubs/LiveDataPartialHub';
    import { ILiveDataMessageCallMembers } from 'src/types/generated/hubs/liveDataPartialHub/models/ILiveDataMessageCallMembers';
    import { ILiveDataUserInfo } from 'src/types/generated/hubs/liveDataPartialHub/models/ILiveDataUserInfo';
    import { LiveDataUtil } from 'src/helpers/LiveDataUtil';
    import { ChatBusEvents } from 'src/components/EventBuses/emuns';

    interface IScrollDetails {
        index: number;
        from: number;
        to: number;
        direction: 'increase' | 'decrease';
    }

    export default defineComponent({
        name: 'Header',

        emits: [
            'on-select-chat',
        ],

        components: {
            CountUnreadedMessages,
        },

        // eslint-disable-next-line max-lines-per-function
        setup(_, context) {
            const $router = useRouter();
            const chatStore = useChatStore();
            const notificationsStore = useNotificationsStore();
            const mainLayoutStore = useMainLayoutStore();

            const virtualScrollRef = ref<QVirtualScroll | null>(null);
            const selectedChat: Ref<ChatsInfoListItem | null> = ref(null);
            const selectedNotify: Ref<ICallStartDto | null> = ref(null);
            const callInfo: Ref<CallInfoResponseModel | null> = ref(null);
            const isLoading: Ref<boolean> = ref(true);
            const currentTab: Ref<string> = ref('');
            const currentScrollIndex: Ref<number> = ref<number>(0);
            const countElementsInViewScroll: Ref<number> = ref<number>(0);
            let scrollTimeout: ReturnType<typeof setTimeout> | undefined = undefined;
            let currentScrollDetails: IScrollDetails = {
                index: 0,
                from: 0,
                to: 0,
                direction: 'increase'
            };
            // Ширина одного таба - 64 пикселя
            const tabWidth: number = 64;

            // Не показываем кнопку принудительного завершения звонка на проде
            // А только на локали и на тестовых серверах
            const isProduction = Common.isProductionHost();

            const chatTabStyles = computed(() => {
                let size: string = '';
                const parentWidth = virtualScrollRef.value?.$el.clientWidth ?? 0;

                switch (chats.value.length) {
                    case 1:
                        size = `${parentWidth}px`;
                        break;
                    case 2:
                        size = !!parentWidth ? ((parentWidth / 2) + 'px') : '50%';
                        break;
                    case 3:
                        size = !!parentWidth ? ((parentWidth / 3) + 'px') : '33.33%';
                        break;
                    default:
                        size = `${tabWidth}px`;
                }

                return {
                    width: size,
                    minWidth: size,
                    maxWidth: size
                };
            });

            const chats = ref<ChatsInfoListItem[]>([]);

            // Список текущих звонков
            const callNotifications = computed<ICallStartDto[]>((): ICallStartDto[] => {
                return notificationsStore.getCallNotifications;
            });

            // Список пользователей, которые уже в звонке
            const usersInCall = computed<CallInfoUserModel[]>((): CallInfoUserModel[] => {
                if (callInfo.value) {
                    return callInfo.value.expectedInterlocutorsData.filter((x: CallInfoUserModel) => {
                        return callInfo.value!.inCallUserIdList.indexOf(x.id) > -1;
                    });
                } else {
                    return [];
                }
            });

            // Отображать текущие звонки
            const isVisibleCallsBlock = computed<boolean>((): boolean => {
                return mainLayoutStore.isVisibleCallsBlock;
            });

            // Доступ к звонку возможен только с камерой
            const isWebCamRequired = computed<boolean>((): boolean => {
                return callInfo.value?.activityOnlineMeetingFormat === ActivityOnlineMeetingFormat.WebCamRequired;
            });

            // Когда открываем текущие звонка
            // сразу выбираем первый для показа
            watch(isVisibleCallsBlock, (val: boolean) => {
                if (val && callNotifications.value.length) {
                    selectCallNotify(callNotifications.value[0]);
                }
            });

            // Если текущие звонки закончатся - скрываем отображение звонков
            // Если если закончился звонок, который выбрали для отображения
            // Тоже сразу скрываем его
            watch(callNotifications.value, (values: ICallStartDto[]) => {
                if (values.length === 0) {
                    selectedNotify.value = null;
                    callInfo.value = null;
                    mainLayoutStore.isVisibleCallsBlock = false;
                } else {
                    const index = values.findIndex((x: ICallStartDto) => x.callId === selectedNotify.value?.callId);

                    if (index === -1) {
                        selectedNotify.value = null;
                        callInfo.value = null;
                    }
                }
            });

            // Обработка сообщения "живых данных" о том что изменился список участников звонка
            function handleCallMembersLiveData(message: ILiveDataMessageCallMembers): void {
                if (!callInfo.value || callInfo.value.callId !== message.callId || message.isFinished) {
                    return;
                }

                const newUsersInCallList: number[] = message.members.map((x: ILiveDataUserInfo) => x.id);

                message.members.forEach((messageUser: ILiveDataUserInfo) => {
                    // Если в звонок зайдет "неожиданный пользователь" у которого есть на это права - его не будет в изначальном списке
                    if (callInfo.value?.expectedInterlocutorsData.findIndex((x: CallInfoUserModel) => x.id === messageUser.id) === -1) {
                        const newUser: CallInfoUserModel = {
                            id: messageUser.id,
                            firstName: LiveDataUtil.getTranslatedString(messageUser.firstName) || '',
                            lastName: LiveDataUtil.getTranslatedString(messageUser.lastName) || '',
                            middleName: LiveDataUtil.getTranslatedString(messageUser.middleName),
                            photoPreviewPath: messageUser.avatarUrl
                        };
                        callInfo.value.expectedInterlocutorsData.push(newUser);
                    }
                });

                // Список Id'шек пользователей в звонке просто переприсваиваем. Он может как увеличиться так и уменьшиться.
                callInfo.value.inCallUserIdList = newUsersInCallList;
            }

            // Получение информации о звонке
            async function selectCallNotify(notify: ICallStartDto): Promise<void> {
                selectedNotify.value = notify;
                callInfo.value = null;
                currentTab.value = 'callNotify' + notify.callId;

                const result = await new CallClient(getApiClientInitialParams()).info(notify.chatId);

                if (result.isSuccess) {
                    callInfo.value = result.entity;
                } else {
                    NotifyErrors(result);
                }
            }

            function selectChat(chat: ChatsInfoListItem): void {
                selectedChat.value = chat;

                if (chat.type !== ChatType.Solution) {
                    chatStore.selectedSolution = null;
                    chatStore.selectedSolutionChat = null;
                }

                // Вызываем событие чтобы родительский компонент его обработал
                context.emit('on-select-chat', chat);
            }

            /* Выбрать вкладку с чатом */
            function setChat(chatId: number, chatInfo?: ChatsInfoListItem): void {
                const chat = chats.value.find((sideChat: ChatsInfoListItem) => sideChat.chatId === chatId);

                if (chat) {
                    selectedChat.value = chat;
                } else if (chatInfo) {
                    // Сохраняем данные в стор, что бы отобразить чат среди всех чатов
                    // если его нет в списке загруженных
                    selectedChat.value = chatInfo;
                    addChatsToMiniChats([chatInfo]);
                }

                currentTab.value = `sideChat${chatId}`;

                setTimeout(function () {
                    const index = chats.value.findIndex((sideChat: ChatsInfoListItem) => sideChat.chatId === chatId);

                    if (index !== -1) {
                        virtualScrollRef.value?.scrollTo(index);
                    }
                }, 300);
            }

            // Открыть чат звонка
            function openCallChat(): void {
                // Вызываем событие чтобы родительский компонент его обработал
                context.emit('on-select-chat', {
                    chatId: callInfo.value?.chatId
                });
                selectedNotify.value = null;
                callInfo.value = null;
                mainLayoutStore.isVisibleCallsBlock = false;
            }

            // Принять звонок - открываем страницу звонка
            function acceptCall(): void {
                if (!callInfo.value) {
                    return;
                }

                const routeData = $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.CallEnter),
                    params: { id: callInfo.value.chatId.toString() }
                });

                window.open(routeData.href, '_blank');

                if (selectedNotify.value !== null) {
                    new ChatMessageClient(getApiClientInitialParams()).updateLastReadMessageForCall(selectedNotify.value.chatId, selectedNotify.value.chatMessageId);
                }
            }

            // Принудительно завершить звонок
            function finishCall(): void {
                if (!callInfo.value) {
                    return;
                }

                new CallClient(getApiClientInitialParams()).finishCallForTestDesk(callInfo.value.callId);

                if (selectedNotify.value !== null) {
                    new ChatMessageClient(getApiClientInitialParams()).updateLastReadMessageForCall(selectedNotify.value.chatId, selectedNotify.value.chatMessageId);
                }

                if (selectedNotify.value?.callId) {
                    notificationsStore.removeCallNotification(selectedNotify.value.callId);
                }

                selectedNotify.value = null;
                callInfo.value = null;
            }

            // Загружаем чаты для отображения в верхней панели чата
            async function loadChats(): Promise<void> {
                isLoading.value = true;

                const result = await new ChatClient(getApiClientInitialParams()).getMinichats();

                if (result.isSuccess) {
                    chats.value = [];
                    addChatsToMiniChats(result.entity.chats);
                } else {
                    NotifyErrors(result);
                }

                isLoading.value = false;

                setTimeout(checkVisibleElements, 100);
            }

            // Форматирование даты в нужный формат
            function getFormattedTime(dateString: string): string {
                return new Date(dateString).toLocaleDateString() + ', ' + DateUtil.getTime(dateString);
            }

            function getDeclMembers(count: number): string {
                return count.cased('участник', 'участника', 'участников', false);
            }

            function addChatsToMiniChats(chatsInfoListItems: ChatsInfoListItem[]): void {
                const resultItems = chats.value.slice();

                chatsInfoListItems.forEach((chat: ChatsInfoListItem) => {
                    const index = resultItems.findIndex((x: ChatsInfoListItem) => x.chatId === chat.chatId);

                    if (index === -1) {
                        resultItems.push(chat);
                    }
                });

                const sorted: ChatsInfoListItem[] = [];
                // Ставим чат с самим собой всегда на первое место
                const selfChatIndex = resultItems.findIndex((x: ChatsInfoListItem) => x.type === ChatType.Self);
                if (selfChatIndex !== -1) {
                    sorted.push(resultItems.splice(selfChatIndex, 1)[0]);
                }

                resultItems.sort((left: ChatsInfoListItem, right: ChatsInfoListItem) => {
                    if (right.pinnedAt && !left.pinnedAt) {
                        return 1;
                    }
                    if (!right.pinnedAt && left.pinnedAt) {
                        return -1;
                    }
                    if (right.pinnedAt && left.pinnedAt) {
                        return new Date(left.pinnedAt ?? 0).getTime() - new Date(right.pinnedAt ?? 0).getTime();
                    }

                    return new Date(right.lastMessageDateTime ?? 0).getTime() - new Date(left.lastMessageDateTime ?? 0).getTime();
                });

                chats.value = sorted.concat(resultItems);
            }

            // Обработчик события от вебсокета,
            // вызывается когда нам пришло сообщение
            function onMessageReceivedHandler(message: ChatMessageDto): void {
                const chat = chats.value.find((x: ChatsInfoListItem) => x.chatId === message.chatId);

                if (chat) {
                    chat.lastMessageDateTime = message.createDateTime;
                }

                // Вызываем функцию чтобы изменилась сортировка
                addChatsToMiniChats([]);
            }

            // Вызывается при отправке чата в архив
            function removeChatFromMiniChats(chatId: number): void {
                const index = chats.value.findIndex((x: ChatsInfoListItem) => x.chatId === chatId);

                if (index !== -1) {
                    chats.value.splice(index, 1);
                }
            }

            // Вызывается при обновлении аватарки у чата
            function onUpdateChatAvatar(data: { id: number, image: string }): void {
                const chat = chats.value.find((c) => c.chatId === data.id);

                if (chat) {
                    chat.logoPath = data.image;
                }
            }

            // Вызывается при обновлении аватарки у чата
            function onSwitchChatPinnedState(data: { chatDto: ListChatDto, isPinned: boolean }): void {
                const chat = chats.value.find((c) => c.chatId === data.chatDto.chatId);
                if (!chat) {
                    return;
                }

                chat.pinnedAt = data.isPinned
                    ? new Date().toUTCString()
                    : null;

                // Вызываем функцию чтобы изменилась сортировка
                addChatsToMiniChats([]);
                const itemIndex = chats.value.indexOf(chat);
                virtualScrollRef.value?.scrollTo(itemIndex);
            }

            // Определяем, сколько элементов умещается в зоне видимости
            function checkVisibleElements(): void {
                let scrollElWidth = (virtualScrollRef.value?.$el as HTMLElement | null)?.clientWidth ?? 0;

                if (scrollElWidth > 0) {
                    // 20px - место под иконку "вправо"
                    scrollElWidth = scrollElWidth - 20;
                    // делаем - 1 потому что в scrollToLeft и scrollToRight мы работаем с индексами
                    countElementsInViewScroll.value = Math.round(scrollElWidth / tabWidth) - 1;
                }
            }

            function onScroll(data: IScrollDetails): void {
                currentScrollIndex.value = data.index;
                currentScrollDetails = data;

                if (currentScrollDetails.from === 0) {
                    currentScrollDetails.direction = 'increase';
                }
            }

            function scrollChats(event: WheelEvent): void {
                if (event.deltaY > 5) {
                    scrollToRight(true);
                } else if (event.deltaY < -5) {
                    scrollToLeft(true);
                }
            }

            function scrollToLeft(once: boolean = false): void {
                if (currentScrollIndex.value <= 0) {
                    return;
                }

                currentScrollIndex.value -= countElementsInViewScroll.value;

                if (currentScrollIndex.value < countElementsInViewScroll.value) {
                    currentScrollIndex.value = 0;
                }

                if (currentScrollDetails.direction === 'increase') {
                    currentScrollIndex.value -= countElementsInViewScroll.value;
                }

                stopScroll();

                virtualScrollRef.value?.scrollTo(currentScrollIndex.value);

                if (!once) {
                    scrollTimeout = setTimeout(scrollToLeft, 200);
                }
            }

            function scrollToRight(once: boolean = false): void {
                if (currentScrollIndex.value >= chats.value.length - 1) {
                    return;
                }

                // Когда currentScrollIndex === 0, то есть все элементы в начале и скрол вправо еще не делался
                // у нас в миничате отображается какое-то количество элементов
                // и +1 к индексу мы должны делать от последнего видимого элемента
                if (currentScrollIndex.value === 0) {
                    currentScrollIndex.value = countElementsInViewScroll.value;
                }

                stopScroll();
                currentScrollIndex.value += countElementsInViewScroll.value;

                if (currentScrollDetails.direction === 'decrease') {
                    currentScrollIndex.value += countElementsInViewScroll.value;
                }

                virtualScrollRef.value?.scrollTo(currentScrollIndex.value);

                if (!once) {
                    scrollTimeout = setTimeout(scrollToRight, 200);
                }
            }

            function stopScroll(): void {
                clearTimeout(scrollTimeout);
            }

            onMounted(() => {
                const hub = getCurrentInstance()?.appContext.config.globalProperties.$commonHub as LiveDataPartialHub;
                hub.onCallMembersLiveUpdate(handleCallMembersLiveData);
            });

            // Инициализация компонента
            onBeforeMount(() => {
                loadChats();

                chatBus.on(ChatBusEvents.OnMessageReceivedHandler, onMessageReceivedHandler);
                chatBus.on(ChatBusEvents.AddChatsToMiniChats, addChatsToMiniChats);
                chatBus.on(ChatBusEvents.RemoveChatFromMiniChats, removeChatFromMiniChats);
                chatBus.on(ChatBusEvents.UpdateChatAvatar, onUpdateChatAvatar);
                chatBus.on(ChatBusEvents.SwitchChatPinnedState, onSwitchChatPinnedState);
            });

            onBeforeUnmount(() => {
                chatBus.off(ChatBusEvents.OnMessageReceivedHandler, onMessageReceivedHandler);
                chatBus.off(ChatBusEvents.AddChatsToMiniChats, addChatsToMiniChats);
                chatBus.off(ChatBusEvents.RemoveChatFromMiniChats, removeChatFromMiniChats);
                chatBus.off(ChatBusEvents.UpdateChatAvatar, onUpdateChatAvatar);
                chatBus.off(ChatBusEvents.SwitchChatPinnedState, onSwitchChatPinnedState);
                stopScroll();
            });

            return {
                virtualScrollRef,
                tabWidth,
                // Цвета для дефолтных аватаров
                CustomAvatarColors,
                // Енам типа чатов для доступа в шаблоне
                ChatType,
                chatTabStyles,
                isProduction,
                usersInCall,
                chats,
                isLoading,
                currentTab,
                isVisibleCallsBlock,
                callNotifications,
                selectedChat,
                currentScrollIndex,
                countElementsInViewScroll,
                selectCallNotify,
                selectChat,
                selectedNotify,
                callInfo,
                localize,
                acceptCall,
                getFormattedTime,
                isWebCamRequired,
                finishCall,
                getDeclMembers,
                openCallChat,
                onScroll,
                scrollChats,
                setChat,
                scrollToLeft,
                scrollToRight,
                checkVisibleElements,
                stopScroll,
            };
        }
    });
</script>

<style scoped lang="scss" src="./header.scss"></style>

<style lang="scss">
    .chat-tab_pinned {
        position: absolute;
        top: 2px;
        margin-right: -35px;
        zoom: 1.1;
    }

    .chat-header-component {
        overflow: hidden;

        .hide-arrows {
            .q-tabs__arrow {
                display: none;
            }
        }

        .q-tabs__arrow {
            top: -5px;

            &.q-tabs__arrow--right {
                background: linear-gradient(90deg, rgba(242, 245, 247, 0) 0%, #F7F9FA 22.35%, #F7F9FA 100%);
                right: -10px;
            }

            &.q-tabs__arrow--left {
                background: linear-gradient(-90deg, rgba(242, 245, 247, 0) 0%, #F7F9FA 22.35%, #F7F9FA 100%);
                left: -10px;
            }
        }
    }
</style>
