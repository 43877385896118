<template>
    <div class="settings-page-component col absolute-center">
        <template v-if="!isMigrationExam">
            <div class="row q-pa-lg settings-block rounded-borders" v-if="!isParticipants">
                <div class="col">
                    <div class="row">
                        <div class="col title">
                            <div>{{ localize('Вы присоединяетесь к звонку') }}</div>
                            <p v-if="callName" class="ellipsis">{{ callName }}</p>
                        </div>
                    </div>
                    <div class="row q-col-gutter-md">
                        <div class="col-md col-xs-12">
                            <div class="video" :style="{ backgroundColor: isMutedVideo || !selectedVideoInputDevice ? 'black' : '' }">
                                <div v-if="isMutedVideo || !selectedVideoInputDevice" class="absolute-center">
                                    {{ localize('Камера выключена') }}
                                </div>
                                <div class="video-wrapper">
                                    <video
                                        ref="userVideoSourceRef"
                                        muted
                                        playsinline
                                        @playing="playingVideo"
                                        class="hidden"
                                    />
                                    <video
                                        ref="previewVideoRef"
                                        muted
                                        playsinline
                                        :style="{
                                            opacity: isMutedVideo ? 0 : 1,
                                            maxWidth: isMutedVideo ? '100%' : ''
                                        }"
                                    />
                                    <canvas ref="canvasRef" class="hidden absolute full-width full-height" style="z-index: 9;"></canvas>
                                    <canvas ref="blurCanvasRef" class="hidden absolute full-width full-height" style="z-index: 9;"></canvas>
                                    <div v-if="isShowMicrophoneVolume" class="volume-block row items-center">
                                        <q-icon
                                            :name="!isMutedAudio ? 'mic' : 'mic_off'"
                                            size="18px"
                                            color="white"
                                        />
                                        <div v-if="!isMutedAudio" class="volume-meter-container col">
                                            <div class="volume-meter" :style="{ width: microphoneVolume + '%' }"></div>
                                        </div>
                                    </div>
                                </div>
                                <q-spinner-dots
                                    v-if="isTurningVideo"
                                    color="primary"
                                    size="4em"
                                    class="absolute-center"
                                />
                            </div>
                            <p class="volume-error" v-if="!audioInputDevices.length">
                                {{ localize('Кажется, вас не слышно') }}
                            </p>
                        </div>
                        <div class="col-md col-xs-12">
                            <div v-if="audioInputDevices.length || isLoadingDevices" class="q-mb-md">
                                <div class="q-mb-sm">
                                    {{ localize('Микрофон') }}
                                </div>
                                <ui-select
                                    v-model="selectedAudioInputDevice"
                                    :options="audioInputDevices"
                                    :loading="isLoadingDevices"
                                    :hide-remove-button="true"
                                    option-value="deviceId"
                                    option-label="label"
                                    map-options
                                    popup-content-class="select"
                                    hide-bottom-space
                                    @on-change="onChangeAudioInputDevice"

                                >
                                    <template #selected-item="scope">
                                        <div class="ellipsis">{{ scope.opt.label }}</div>
                                    </template>
                                </ui-select>
                            </div>
                            <div v-if="audioOutputDevices.length || isLoadingDevices" class="q-mb-md">
                                <div class="row">
                                    <div class="col q-mb-sm">
                                        {{ localize('Динамики') }}
                                    </div>
                                    <div>
                                        <span
                                            @click="checkDynamics"
                                            :class="{ 'disabled': isMusicSounding }"
                                            class="check-dynamics"
                                        >
                                            {{ localize('Проверить') }}
                                        </span>
                                    </div>
                                </div>
                                <ui-select
                                    v-model="selectedAudioOutputDevice"
                                    :options="audioOutputDevices"
                                    :loading="isLoadingDevices"
                                    :hide-remove-button="true"
                                    option-value="deviceId"
                                    option-label="label"
                                    map-options
                                    popup-content-class="select"
                                    hide-bottom-space
                                    @on-change="onChangeAudioOutputDevice"
                                >
                                    <template #selected-item="scope">
                                        <div class="ellipsis">{{ scope.opt.label }}</div>
                                    </template>
                                </ui-select>
                            </div>
                            <div v-if="videoInputDevices.length || isLoadingDevices" class="q-mb-md">
                                <div class="q-mb-sm">
                                    {{ localize('Камера') }}
                                </div>
                                <ui-select
                                    v-model="selectedVideoInputDevice"
                                    :options="videoInputDevices"
                                    :loading="isLoadingDevices"
                                    :hide-remove-button="true"
                                    option-value="deviceId"
                                    option-label="label"
                                    map-options
                                    popup-content-class="select"
                                    hide-bottom-space
                                    @on-change="onChangeVideoInputDevice"
                                >
                                    <template #selected-item="scope">
                                        <div class="ellipsis">{{ scope.opt.label }}</div>
                                    </template>
                                </ui-select>
                            </div>
                            <div v-if="videoInputDevices.length || isLoadingDevices" class="q-mb-md">
                                <div class="row items-center q-mb-sm">
                                    {{ localize('Фон') }}
                                    <InfoIcon
                                        @click="isVisibleVirtualBackgroundInfo = true"
                                        class="q-ml-xs cursor-pointer"
                                    />
                                </div>
                                <ui-select
                                    v-model="selectedBackgroundItem"
                                    :options="backgroundOptionItems"
                                    :loading="isLoadingDevices"
                                    map-options
                                    popup-content-class="select"
                                    hide-bottom-space
                                    @on-change="onChangeBackgroundOption"
                                >
                                    <template #selected-item="scope">
                                        <div>
                                            <template v-if="!scope.opt.value">
                                                {{ localize('По умолчанию') }} —
                                            </template>
                                            {{ scope.opt.text }}
                                        </div>
                                    </template>
                                </ui-select>
                            </div>
                            <div v-if="!videoInputDevices.length && !audioInputDevices.length && !isLoadingDevices" class="volume-error">
                                {{ localize('Нет доступных устройств ввода аудио и видео. Возможно, доступ заблокирован браузером и необходимо дать разрешение в настройках (см. ') }}
                                <a
                                    :href="gitbookLink"
                                    target="_blank"
                                >
                                    {{ localize('как это сделать)') }}
                                </a>
                            </div>
                            <div>
                                <q-btn
                                    unelevated
                                    color="primary"
                                    @click="loadStartData"
                                    :label="localize('Присоединиться')"
                                    :loading="isLoadingStartingData"
                                    :disable="isDisabledConnectButton"
                                    class="full-width q-mt-md"
                                />
                                <q-tooltip v-if="isDisabledConnectButton">
                                    <template v-if="callOnlyWithVideo">
                                        {{ localize('Для данной активности установлен доступ только с видеокамерой.') }}
                                    </template>
                                    <template v-if="videoInputDevices.length">
                                        {{ localize('Вы не можете отключить камеру') }}
                                    </template>
                                    <template v-else>
                                        {{ localize('Подключите видеокамеру к вашему устройству') }}
                                    </template>
                                </q-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" :class="!isMobileView ? 'q-mt-xl' : 'q-mt-md'" v-if="!isParticipants">
                <div class="col-7 footer" :class="!isMobileView ? 'q-pl-md' : ''">
                    {{ localize('Отключите микрофон, если планируете только слушать.') }}
                    <template v-if="callOnlyWithVideo">
                        {{ localize('Для данной активности установлен доступ только с видеокамерой.') }}
                        <template v-if="videoInputDevices.length">
                            {{ localize('Вы не можете отключить камеру') }}
                        </template>
                        <template v-else>
                            {{ localize('Подключите видеокамеру к вашему устройству') }}
                        </template>
                    </template>
                    <template v-else>
                        {{
                            localize('Не отключайте камеру: спикеру проще понимать аудиторию, когда видны лица слушателей.')
                        }}
                    </template>
                </div>
                <div class="col">
                    <div class="row justify-center">
                        <div class="col-md col-xs-0"></div>
                        <div class="col-xs footer-btn">
                            <q-btn
                                round
                                color="grey-10"
                                size="14px"
                                class="q-mb-xs"
                                :disable="!audioInputDevices.length"
                                @click="toggleMuteAudio"
                            >
                                <q-icon :name="!isMutedAudio ? 'mic' : 'mic_off'" />
                                <div class="footer-badge" :class="{ offline: !!isMutedAudio }"></div>
                            </q-btn>
                            <span class="footer-title">
                                {{ isMutedAudio ? localize('Микрофон выключен') : localize('Микрофон включен') }}
                            </span>
                            <q-tooltip v-if="!audioInputDevices.length && !isLoadingDevices" anchor="top middle" self="bottom middle" :hide-delay="1000" class="all-pointer-events">
                                {{ localize('Нет доступных аудио устройств. Возможно, доступ заблокирован браузером и необходимо дать разрешение в настройках (см. ') }}
                                <a
                                    :href="gitbookLink"
                                    target="_blank"
                                    class="text-primary"
                                >
                                    {{ localize('как это сделать)') }}
                                </a>
                            </q-tooltip>
                        </div>

                        <div
                            v-if="!(callOnlyWithVideo && !videoInputDevices.length)"
                            class="col-xs footer-btn"
                            :class="{ 'idle-button': callOnlyWithVideo }"
                        >
                            <q-btn
                                round
                                color="grey-10"
                                size="14px"
                                class="q-mb-xs"
                                :loading="isTurningVideo"
                                :disable="!videoInputDevices.length"
                                @click="toggleMuteVideo"
                            >
                                <q-icon :name="!isMutedVideo && selectedVideoInputDevice ? 'videocam' : 'videocam_off'" />
                                <div class="footer-badge" :class="{ offline: (!!isMutedVideo || !selectedVideoInputDevice)}"></div>
                            </q-btn>
                            <span class="footer-title">
                                {{ isMutedVideo ? localize('Камера выключена') : localize('Камера включена') }}
                            </span>
                            <q-tooltip v-if="!videoInputDevices.length && !isLoadingDevices" anchor="top middle" self="bottom middle" :hide-delay="1000" class="all-pointer-events">
                                {{ localize('Нет доступных видеоустройств. Возможно, доступ заблокирован браузером и необходимо дать разрешение в настройках (см. ') }}
                                <a
                                    :href="gitbookLink"
                                    target="_blank"
                                    class="text-primary"
                                >
                                    {{ localize('как это сделать)') }}
                                </a>
                            </q-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <participants-noitems v-else />
        </template>
        <div v-else class="text-center fixed-center">
            <q-spinner-dots color="primary" size="4em" />
        </div>

        <q-dialog v-model="isVisibleVirtualBackgroundInfo">
            <q-card class="confirm-modal q-pa-lg">
                <div class="confirm-modal__title q-mb-md row justify-between items-center">
                    {{ localize('Виртуальный фон') }}
                    <Icon
                        name="CloseIcon"
                        @click.native="isVisibleVirtualBackgroundInfo = false"
                        class="cursor-pointer"
                    />
                </div>
                <div class="q-mb-md">
                    <div class="q-mb-md">
                        {{ localize('Для применения визуальных эффектов должны быть соблюдены следующие требования:') }}
                    </div>
                    <div class="q-mb-sm">
                        <span class="middot-icon">&middot;</span>
                        {{ localize('Последняя версия браузера, с поддержкой WebGL:') }}
                    </div>
                    <div class="q-mb-md text-shade-8" style="margin-left: 11px;">
                        {{ localize('Chrome 91, Firefox 91 и более поздние версии') }}
                    </div>
                    <div class="q-mb-md">
                        <span class="middot-icon">&middot;</span>
                        {{ localize('Включенное аппаратное ускорение') }}
                    </div>
                    <div>
                        <span class="middot-icon">&middot;</span>
                        {{ localize('64-разрядная операционная система') }}
                    </div>
                </div>
            </q-card>
        </q-dialog>

        <q-resize-observer @resize="onResize" :debounce="500" />
    </div>
</template>

<script lang="ts">
    import { janusEventBus } from 'pages/Call/components/eventBuses';
    import JanusWrapper, { JanusEvents } from 'pages/Call/libs/janus/JanusWrapper';
    import { IAudioElement } from '../types/interfaces';
    import { ActivityOnlineMeetingFormat, CallClient, CallConnectionState, DropDownItem } from 'src/api/ApiClient';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import {
        computed,
        watch,
        defineComponent,
        nextTick,
        onBeforeMount,
        onBeforeUnmount,
        onMounted,
        ref,
        Ref,
        getCurrentInstance,
    } from 'vue';
    import { Notify } from 'quasar';
    import CallMode from '../types/CallMode.enum';
    import ParticipantsNoitems from './ParticipantsNoitems.vue';
    import { CallPartialHub } from 'src/services/hubs/CallPartialHub';
    import { IUserActiveCallsDto } from 'src/types/generated/hubs/callPartialHub/models/IUserActiveCallsDto';
    import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
    import BackgroundOptions, { BackgroundItem, getBackground } from 'pages/Call/data/backgroundOptions';
    import { setBackgroundImage, setBlurEffect } from 'pages/Call/utils/videoEffect';
    import { HTMLCanvasElementWithCaptureStream, IImageElement } from 'pages/Call/data/interfaces';
    import initMediaPipeSelfieSegmentation from 'pages/Call/utils/initMediaPipeSelfieSegmentation';
    import { useRoute, useRouter } from 'vue-router';
    import MetaRouter from 'src/router/types/MetaRouter';
    import { localize } from 'src/services/LocalizationService';
    import { useCallStore } from 'src/store/module-call';

    export default defineComponent({
        name: 'SettingsPage',

        components: {
            ParticipantsNoitems,
        },

        emits: ['connect-to-call'],

        /* eslint-disable-next-line max-lines-per-function */
        setup(_, context) {
            const $route = useRoute();
            const $router = useRouter();
            const callStore = useCallStore();
            const app = getCurrentInstance();
            let $janus: JanusWrapper | null = null;

            if (app) {
                $janus = app.appContext.config.globalProperties.$janus as JanusWrapper;
            }

            // Ссылки на элементы
            const userVideoSourceRef = ref<HTMLVideoElement>();
            const previewVideoRef = ref<HTMLVideoElement>();
            const canvasRef = ref<HTMLCanvasElementWithCaptureStream>();
            const blurCanvasRef = ref<HTMLCanvasElement>();
            // Звонок ли это экзамена для мигрантов
            const isMigrationExam = ref<boolean>(false);

            const image: IImageElement = new Image();
            image.onload = function () {
                // Проставляем флаг, что картинка загружена
                image.isLoaded = true;
            };

            // Стрим, полученный из getUserMedia
            let userStream: MediaStream | null = null;

            // Экземпляр MediaPipe для работы вирнуального фона
            let selfieSegmentation: SelfieSegmentation | null = null;
            // Canvas для работы вирнуального фона
            let renderingContext: CanvasRenderingContext2D | null = null;

            // Используется ли картинка для фона видео
            let useBackground: boolean = false;
            // Используется ли размытие фона видео
            let useBlurEffect: boolean = false;
            let requestedAnimationFrameId: number | null = 0;

            // Режим мобильного отображения
            const isMobileView: Ref<boolean> = ref(false);
            const isParticipants: Ref<boolean> = ref(false);
            // Название исходящего/входящего звонка
            const callName: Ref<string> = ref('');

            // Видимость модального окна с требованиями к виртуальному фону
            const isVisibleVirtualBackgroundInfo: Ref<boolean> = ref(false);

            // Подключиться можно только с камерой
            const callOnlyWithVideo: Ref<boolean> = ref(false);

            const audioInputDevices: Ref<MediaDeviceInfo[]> = ref([]);
            const videoInputDevices: Ref<MediaDeviceInfo[]> = ref([]);
            const audioOutputDevices: Ref<MediaDeviceInfo[]> = ref([]);
            const backgroundOptionItems: DropDownItem[] = BackgroundOptions.items.slice();

            const isMutedVideo: Ref<boolean> = ref(true);
            const isMutedAudio: Ref<boolean> = ref(true);
            const isTurningVideo: Ref<boolean> = ref(false);
            // Играет мелодия проверки динамиков
            const isMusicSounding: Ref<boolean> = ref(false);
            // Показывать полоску громкости микрофона
            const isShowMicrophoneVolume: Ref<boolean> = ref(false);

            const callAudio: Ref<HTMLAudioElement | null> = ref(null);
            let microphoneMediaStreamSource: MediaStreamAudioSourceNode | null = null;
            let audioWorkletNode: AudioWorkletNode | null = null;
            const microphoneVolume: Ref<number> = ref(0);
            const gitbookLink: string = 'https://informa.gitbook.io/odin/kommunikaciya/videozvonki/kak-dat-dostup-k-kamere-i-mikrofonu-dlya-zvonkov-v-odin';

            const defaultMediaDeviceInfoValue: MediaDeviceInfo = {
                deviceId: '',
                groupId: '',
                kind: 'videoinput' as MediaDeviceKind,
                label: '',
                toJSON: () => undefined,
            };

            const calls: Ref<IUserActiveCallsDto[]> = ref<IUserActiveCallsDto[]>([]);
            const selectedAudioInputDevice: Ref<MediaDeviceInfo> = ref({ ...defaultMediaDeviceInfoValue });
            const selectedAudioOutputDevice: Ref<MediaDeviceInfo> = ref({ ...defaultMediaDeviceInfoValue });
            const selectedVideoInputDevice: Ref<MediaDeviceInfo> = ref({ ...defaultMediaDeviceInfoValue });
            const selectedBackgroundItem = ref<DropDownItem>(BackgroundOptions.items[0]);

            // Признак, отвечающий за загрузку информации об устройствах
            const isLoadingDevices: Ref<boolean> = ref(true);

            // Признак, отвечающий за загрузку информации об звонке
            const isLoadingStartingData: Ref<boolean> = ref(false);

            const isDisabledConnectButton = computed((): boolean => {
                if (isLoadingDevices.value || isLoadingStartingData.value) {
                    return true;
                }

                // Если доступ возможен только с видео, то не даем подключиться
                // если нет камеры или она выключена
                if (callOnlyWithVideo.value) {
                    return !videoInputDevices.value.length || isMutedVideo.value;
                } else {
                    return false;
                }
            });

            // Вотчер для страницы участников звонков
            watch(calls, (value: IUserActiveCallsDto[]) => {
                if (!isParticipants.value) {
                    return;
                }
                // Отслеживаем наличие звонков и если есть звонок с текущим ID, запрашиваем его данные
                if (value.find((el: IUserActiveCallsDto) => String(el.chatId) === $route.params.id)) {
                    loadCallData();
                }
            });

            function onChangeAudioInputDevice(device: MediaDeviceInfo): void {
                selectedAudioInputDevice.value = device;
                $janus?.setSelectedAudioInputDeviceId(device.deviceId);
                callStore.selectedAudioInputDevice = device.deviceId;
            }

            function onChangeVideoInputDevice(device: MediaDeviceInfo): void {
                selectedVideoInputDevice.value = device;
                $janus?.setSelectedVideoInputDeviceId(device.deviceId);
                callStore.selectedVideoInputDevice = device.deviceId;
                stopVideoPreview();
                userStream?.getTracks().forEach((x: MediaStreamTrack) => x.stop());
                isTurningVideo.value = true;
                setTimeout(showVideoPreview, 1000);
            }

            function onChangeAudioOutputDevice(device: MediaDeviceInfo): void {
                selectedAudioOutputDevice.value = device;
            }

            function onChangeBackgroundOption(item: DropDownItem | null): void {
                setBackgroundSettings(item);

                if (!isMutedVideo.value && userVideoSourceRef.value?.srcObject) {
                    playingVideo();
                }

                callStore.selectedBackgroundOption = item ? item.value : null;
            }

            // Определяем, нужно ли использовать какой-то эффект для видео
            function setBackgroundSettings(item?: DropDownItem | null): void {
                if (!item) {
                    selectedBackgroundItem.value = BackgroundOptions.items[0];
                    useBackground = false;
                    useBlurEffect = false;
                } else {
                    selectedBackgroundItem.value = item;
                    const imagePath = getBackground(item.value);

                    if (imagePath) {
                        image.isLoaded = false;
                        image.src = imagePath;
                        useBackground = true;
                        useBlurEffect = false;
                    } else if (item.value === BackgroundItem.BlurEffect) {
                        useBlurEffect = true;
                        useBackground = false;
                    } else {
                        useBackground = false;
                        useBlurEffect = false;
                    }
                }
            }

            function toggleMuteVideo(): void {
                if (!isMutedVideo.value && callOnlyWithVideo.value) {
                    return;
                }

                isMutedVideo.value = !isMutedVideo.value;
                $janus?.setMutedVideoDefaultSetting(isMutedVideo.value);
                callStore.userData.videoOn = !isMutedVideo.value;

                if (!isMutedVideo.value) {
                    showVideoPreview();
                } else {
                    stopVideoPreview();
                }
            }

            function toggleMuteAudio(): void {
                isMutedAudio.value = !isMutedAudio.value;
                $janus?.setMutedAudioDefaultSetting(isMutedAudio.value);
                callStore.setUserMicrophoneOn(!isMutedAudio.value);
            }

            // Проверить работу динамиков
            async function checkDynamics(): Promise<void> {
                if (callAudio.value && 'setSinkId' in callAudio.value) {
                    try {
                        // Устанавливаем идентификатор аудиоустройства, которое будет использоваться для вывода
                        await (callAudio.value as IAudioElement).setSinkId(selectedAudioOutputDevice.value.deviceId);
                    } catch (e) {
                        console.error(e);
                    }
                }
                callAudio.value?.pause();
                callAudio.value?.play().then(() => {
                    isMusicSounding.value = true;

                    setTimeout(() => {
                        isMusicSounding.value = false;
                        callAudio.value?.pause();
                    }, 1000);
                }).catch((e) => {
                    console.error(e);
                });
            }

            // Заполняем данные в зависимости от типа устройства (аудио/видео)
            function fillDevicesLists(devices?: MediaDeviceInfo[]): void {
                if (!devices) {
                    devices = [];
                }
                // Заполняем данными соотвествующие массивы
                videoInputDevices.value = devices.filter(({ kind }: MediaDeviceInfo) => kind === 'videoinput');
                audioInputDevices.value = devices.filter(({ kind }: MediaDeviceInfo) => kind === 'audioinput');
                audioOutputDevices.value = devices.filter(({ kind }: MediaDeviceInfo) => kind === 'audiooutput');

                if (devices.length) {
                    setCurrentDevices();
                }

                if (devices.length && !isParticipants.value) {
                    nextTick(() => {
                        // Включаем видео и аудио
                        toggleMuteVideo();
                        toggleMuteAudio();
                    });
                } else {
                    isMutedVideo.value = true;
                    isMutedAudio.value = true;
                }

                // Выжидаем секунду так как устройства не успевают записаться в сторе
                // И при быстром переходе будет выдавать ошибку
                setTimeout(() => {
                    isLoadingDevices.value = false;
                }, 1000);
            }

            // Проставляем дефолтные значения,
            // сначала ищем в сторе, потом ищем default устройства
            // если их нет то выбираем первое из списка
            function setCurrentDevices(): void {
                let currentVideoInputId = videoInputDevices.value.find((x: MediaDeviceInfo) => {
                    return x.deviceId === callStore.selectedVideoInputDevice;
                });

                if (!currentVideoInputId) {
                    currentVideoInputId = videoInputDevices.value.find((x: MediaDeviceInfo) => x.deviceId === 'default');
                }

                selectedVideoInputDevice.value = currentVideoInputId ?? videoInputDevices.value[0];

                if (selectedVideoInputDevice.value) {
                    $janus?.setSelectedVideoInputDeviceId(selectedVideoInputDevice.value.deviceId);
                } else {
                    isTurningVideo.value = true;
                    isMutedVideo.value = !!selectedVideoInputDevice.value;
                }

                let currentAudioDeviceId = audioInputDevices.value.find((x: MediaDeviceInfo) => {
                    return x.deviceId === callStore.selectedAudioInputDevice;
                });

                if (!currentAudioDeviceId) {
                    currentAudioDeviceId = audioInputDevices.value.find((x: MediaDeviceInfo) => x.deviceId === 'default');
                }

                selectedAudioInputDevice.value = currentAudioDeviceId ?? audioInputDevices.value[0];

                if (selectedAudioInputDevice.value) {
                    $janus?.setSelectedAudioInputDeviceId(selectedAudioInputDevice.value.deviceId);
                }

                let currentAudioOutputDeviceId = audioOutputDevices.value.find((x: MediaDeviceInfo) => {
                    return x.deviceId === callStore.selectedAudioOutputDevice;
                });

                if (!currentAudioOutputDeviceId) {
                    currentAudioOutputDeviceId = audioOutputDevices.value.find((x: MediaDeviceInfo) => x.deviceId === 'default');
                }

                selectedAudioOutputDevice.value = currentAudioOutputDeviceId ?? audioOutputDevices.value[0];
            }

            function getVideoConstraints(): boolean | MediaTrackConstraints {
                if (isMigrationExam.value || callStore.isUseFullHd) {
                    return selectedVideoInputDevice.value ? {
                        width: { ideal: 1920 },
                        height: { ideal: 1080 },
                        deviceId: selectedVideoInputDevice.value.deviceId
                    } : {
                        width: { ideal: 1920 },
                        height: { ideal: 1080 },
                    };
                } else {
                    return selectedVideoInputDevice.value ? { deviceId: selectedVideoInputDevice.value.deviceId } : true;
                }
            }

            // Показываем видео из выбранного видео входа для проверки работоспособности
            function showVideoPreview(): void {
                stopVideoPreview();
                isTurningVideo.value = true;

                navigator.mediaDevices
                    .getUserMedia({
                        video: getVideoConstraints(),
                        audio: selectedAudioInputDevice.value ? { deviceId: selectedAudioInputDevice.value.deviceId } : true,
                    })
                    .then((stream: MediaStream) => {
                        userStream?.getTracks().forEach((x: MediaStreamTrack) => x.stop());
                        userStream = stream;

                        if (userVideoSourceRef.value) {
                            userVideoSourceRef.value.srcObject = stream;
                            userVideoSourceRef.value.play();
                        }

                        isTurningVideo.value = false;
                        createMicrophoneProcessing(stream);
                    })
                    .catch((reason: any) => {
                        Notify.create({
                            type: 'negative',
                            message: reason.message,
                        });

                        toggleMuteVideo();
                        isTurningVideo.value = false;
                    });
            }

            async function createMicrophoneProcessing(stream: MediaStream): Promise<void> {
                const audioContext = new AudioContext();

                // не во всех браузерах есть audioWorklet
                if (!audioContext.audioWorklet) {
                    return;
                }

                // Добавление AudioWorkletProcessor из другого скрипта с помощью метода addModule
                await audioContext.audioWorklet.addModule('/js/volume-meter-processor.js');
                const audioMicrophone = stream.getTracks().filter((el: MediaStreamTrack) => el.kind === 'audio');
                if (audioMicrophone.length) {
                    microphoneMediaStreamSource = audioContext.createMediaStreamSource(stream) || undefined;
                }

                // Создание контекста отправки AudioWorkletNode и имени процессора,
                // зарегистрированного в volume-meter-processor.js
                audioWorkletNode = new AudioWorkletNode(audioContext, 'volume-meter');

                // Слушать события от AudioWorkletProcessor
                // здесь мы можем отследить громкость звука
                audioWorkletNode.port.onmessage = (event) => {
                    let volume = 0;
                    if (event.data.volume) {
                        volume = event.data.volume;
                    }
                    microphoneVolume.value = Math.round(volume * 100);
                };

                // Подключаем AudioWorkletNode к нашему стриму
                if (microphoneMediaStreamSource) {
                    microphoneMediaStreamSource.connect(audioWorkletNode).connect(audioContext.destination);
                    isShowMicrophoneVolume.value = true;
                }
            }

            function stopVideoPreview(): void {
                if (userVideoSourceRef.value && userVideoSourceRef.value.srcObject && 'getTracks' in userVideoSourceRef.value.srcObject) {
                    userVideoSourceRef.value.srcObject.getTracks().forEach((track: MediaStreamTrack) => {
                        track.stop();
                    });
                }

                if (previewVideoRef.value && previewVideoRef.value.srcObject && 'getTracks' in previewVideoRef.value.srcObject) {
                    previewVideoRef.value.srcObject.getTracks().forEach((track: MediaStreamTrack) => {
                        track.stop();
                    });
                }

                isTurningVideo.value = false;

                if (requestedAnimationFrameId) {
                    cancelAnimationFrame(requestedAnimationFrameId);
                }
            }

            async function loadCallData(): Promise<void> {
                const activityId = $route?.query.activityId ? Number($route?.query.activityId) : undefined;
                let videoServerId = 1;
                const requestTimeout = 3000;

                const checkConnectionResult = await new CallClient(getApiClientInitialParams()).turnServerCheck(videoServerId);

                const isVideoServerAlive = await checkTURNServer(
                    {
                        urls: checkConnectionResult.entity.iceServers[1].url ?? '',
                        username: checkConnectionResult.entity.iceServers[1].username ?? '',
                        credential: checkConnectionResult.entity.iceServers[1].credential ?? ''
                    },
                    requestTimeout,
                );
                // 1 и 2 - это id серверов, в зависимости от того
                // к какому подключились посылаем нужный id,
                // остальные пользователи должны подключиться к этому же серверу
                videoServerId = isVideoServerAlive ? 1 : 1; // сейчас везде 1 для тестирования commit 77b2da91b703d0b131bb018af9cbda02b00040c9
                const connectionState = isVideoServerAlive
                    ? CallConnectionState.Established
                    : CallConnectionState.NoConnection;

                const result = await new CallClient(getApiClientInitialParams()).enter(
                    Number($route.params.id),
                    $route.query?.start === 'true',
                    videoServerId,
                    activityId,
                    connectionState,
                );

                callStore.startCallTime = Date.now();

                if (result.isSuccess) {
                    if ($route.query?.start === 'true') {
                        callStore.isMigrationExam = isMigrationExam.value;
                        await $router.push($route.path);
                    }

                    callStore.setCallStartingData(result.entity);
                    if (result.entity.callRecordId) {
                        callStore.isRecordingEnabled = result.entity.callRecordId > 0;
                    }
                    context.emit('connect-to-call', true);
                } else {
                    NotifyErrors(result);
                }
            }

            /**
             * Начало звонка или вход в имеющийся звонок чата.
             */
            async function loadStartData(): Promise<void> {
                if (!$route?.params?.id || isDisabledConnectButton.value) {
                    return;
                }

                isLoadingStartingData.value = true;

                await loadCallData();
                isLoadingStartingData.value = false;
            }

            /**
             * Проверка статуса сервера TURN
             */
            function checkTURNServer(turnConfig: { urls: string; username: string; credential: string }, timeout: number): Promise<void | boolean> {
                return new Promise(function (
                    resolve: (value: PromiseLike<boolean> | boolean) => void,
                ) {
                    setTimeout(function () {
                        if (promiseResolved) {
                            return;
                        }
                        resolve(false);
                        promiseResolved = true;
                    }, timeout || 8000);

                    let promiseResolved = false;
                    const myPeerConnection = window.RTCPeerConnection || (window as any).webkitRTCPeerConnection;
                    const pc = new myPeerConnection({ iceServers: [turnConfig] });
                    pc.createDataChannel('');

                    pc.createOffer().then(function (sdp: any) {
                        if (sdp.sdp.indexOf('typ relay') > -1) {
                            // sometimes sdp contains the ice candidates...
                            promiseResolved = true;
                            pc.close();
                            resolve(true);
                        }
                        pc.setLocalDescription(sdp);
                    }); // create offer and set local description
                    pc.onicecandidate = function (ice: any) {
                        //listen for candidate events
                        if (
                            promiseResolved ||
                            !ice ||
                            !ice.candidate ||
                            !ice.candidate.candidate ||
                            !(ice.candidate.candidate.indexOf('typ relay') > -1)
                        ) {
                            return;
                        }
                        promiseResolved = true;
                        pc.close();
                        resolve(true);
                    };
                }).catch(() => {
                    return new Promise(function (
                        resolve: (value: PromiseLike<boolean> | boolean) => void,
                    ) {
                        resolve(true);
                    });
                });
            }

            async function initDevices(): Promise<void> {
                janusEventBus.on(JanusEvents.onMediaDevicesChanged, fillDevicesLists);

                if ($janus) {
                    // получаем спискок доступных браузеру устройств ввода/вывода аудио/видео информации
                    await $janus.initMediaDevicesAsync();
                    navigator.mediaDevices.addEventListener('devicechange', $janus.initMediaDevicesAsync);
                }
            }

            function playingVideo(): void {
                let stream = userVideoSourceRef.value!.srcObject;

                if (useBackground || useBlurEffect) {
                    if (!selfieSegmentation) {
                        initSelfieSegmentation();
                    }

                    if (requestedAnimationFrameId) {
                        cancelAnimationFrame(requestedAnimationFrameId);
                    }

                    selfieSegmentation?.reset();

                    const videoEl = userVideoSourceRef.value as HTMLVideoElement;
                    const canvas = canvasRef.value as HTMLCanvasElementWithCaptureStream;
                    const blurCanvas = blurCanvasRef.value as HTMLCanvasElement;

                    canvas.width = videoEl.videoWidth;
                    canvas.height = videoEl.videoHeight;
                    blurCanvas.width = videoEl.videoWidth;
                    blurCanvas.height = videoEl.videoHeight;
                    videoEl.width = videoEl.videoWidth;
                    videoEl.height = videoEl.videoHeight;

                    let lastTime = new Date().getTime();

                    const getFrames = async (): Promise<void> => {
                        if (isMutedVideo.value) {
                            return;
                        }
                        const now = videoEl.currentTime;
                        if (now > lastTime) {
                            await selfieSegmentation?.send({ image: videoEl });
                        }
                        lastTime = now;
                        requestedAnimationFrameId = requestAnimationFrame(getFrames);
                    };

                    getFrames();

                    stream = canvas.captureStream();
                }

                if (previewVideoRef.value) {
                    previewVideoRef.value.srcObject = stream;
                    previewVideoRef.value.play();
                }

            }

            function handleSegmentationResults(results: any): void {
                if (!renderingContext || !canvasRef.value) {
                    return;
                }

                if (useBackground) {
                    setBackgroundImage(results, canvasRef.value, renderingContext, image);
                }

                if (useBlurEffect) {
                    const blurCanvas = blurCanvasRef.value as HTMLCanvasElement;
                    const blurContext = blurCanvas.getContext('2d');
                    setBlurEffect(results, canvasRef.value, blurCanvas, renderingContext, blurContext!);
                }
            }

            // Инициализация плагина для наложения эффектов
            function initSelfieSegmentation(): void {
                selfieSegmentation = initMediaPipeSelfieSegmentation(handleSegmentationResults);
            }

            function onResize(): void {
                isMobileView.value = window.innerWidth <= 960;
            }

            onBeforeMount(async () => {
                if (!$route?.params?.id) {
                    return;
                }

                if ($route.query.useFullHd === 'true') {
                    callStore.isUseFullHd = true;
                }

                // Если экзамен для мигрантов, то сразу открываем звонок
                if ($route.query.isMigration === 'true') {
                    isMigrationExam.value = true;
                    $janus?.setMutedVideoDefaultSetting(false);
                    $janus?.setMutedAudioDefaultSetting(false);
                    callStore.setUserMicrophoneOn(true);
                    callStore.userData.videoOn = true;
                    isLoadingDevices.value = false;
                    loadStartData();
                    return;
                }

                initSelfieSegmentation();

                if (callStore.selectedBackgroundOption) {
                    setBackgroundSettings(BackgroundOptions.items.find((x: DropDownItem) => x.value === callStore.selectedBackgroundOption));
                }

                if (!isParticipants.value) {
                    // Для режима участников не нужна инициализация устройств
                    initDevices();
                }

                callAudio.value = new Audio('/sounds/call.mp3');
                callAudio.value.loop = false;

                const activityId = $route?.query.activityId ? Number($route?.query.activityId) : null;

                const result = await new CallClient(getApiClientInitialParams()).callSettings(Number($route.params.id), activityId);

                if (result.isSuccess) {
                    callName.value = result.entity.name;
                    callOnlyWithVideo.value = result.entity.activityOnlineMeetingFormat === ActivityOnlineMeetingFormat.WebCamRequired;
                    callStore.callOnlyWithVideo = callOnlyWithVideo.value;
                    MetaRouter.setTitle(callName.value);
                }
            });

            onMounted(() => {
                onResize();
                isParticipants.value = $route.query.mode === CallMode.participants;
                const callNotificationHub = app?.appContext.config.globalProperties.$commonHub as CallPartialHub;
                callNotificationHub?.onCallUserActiveCalls((newCalls: IUserActiveCallsDto[]) => {
                    calls.value = newCalls;
                });

                nextTick(() => {
                    if (canvasRef.value) {
                        renderingContext = canvasRef.value.getContext('2d');
                    }
                });
            });

            // После уничтожения компонента
            // удаляем все слушатели в глобалной шине событий
            onBeforeUnmount(() => {
                renderingContext = null;
                stopVideoPreview();
                userStream?.getTracks().forEach((x: MediaStreamTrack) => x.stop());
                audioWorkletNode?.disconnect();
                microphoneMediaStreamSource?.disconnect();
                selfieSegmentation?.close();
                janusEventBus.off(JanusEvents.onMediaDevicesChanged, fillDevicesLists);

                if ($janus) {
                    navigator.mediaDevices.removeEventListener('devicechange', $janus.initMediaDevicesAsync);
                }
            });

            return {
                userVideoSourceRef,
                previewVideoRef,
                canvasRef,
                blurCanvasRef,
                isMigrationExam,
                localize,
                callName,
                isMutedAudio,
                microphoneVolume,
                isShowMicrophoneVolume,
                audioInputDevices,
                isLoadingDevices,
                selectedAudioInputDevice,
                backgroundOptionItems,
                selectedBackgroundItem,
                isVisibleVirtualBackgroundInfo,
                onChangeAudioInputDevice,
                audioOutputDevices,
                checkDynamics,
                isMusicSounding,
                selectedAudioOutputDevice,
                onChangeAudioOutputDevice,
                onChangeVideoInputDevice,
                onChangeBackgroundOption,
                gitbookLink,
                loadStartData,
                isLoadingStartingData,
                isDisabledConnectButton,
                callOnlyWithVideo,
                isMobileView,
                isTurningVideo,
                toggleMuteVideo,
                onResize,
                toggleMuteAudio,
                playingVideo,
                isMutedVideo,
                selectedVideoInputDevice,
                videoInputDevices,
                isParticipants,
            };
        },
    });

</script>

<style scoped lang="scss">
    .settings-page-component {
        max-width: 716px;
        min-height: 460px;
        font-size: 14px;

        .settings-block {
            background-color: $shade-5;
        }

        ::v-deep(.q-btn) {
            &.disabled {
                background-color: $shade-1 !important;
                color: $shade-7 !important;
            }
        }

        .idle-button {
            ::v-deep(.footer-badge) {
                background-color: $shade-7;
            }

            ::v-deep(.footer-title) {
                color: $shade-7;
            }
        }
    }

    .video {
        position: relative;
        width: 326px;
        margin: 0 auto 8px;
        background-color: #fff;
        color: #fff;
        overflow: hidden;
        text-align: center;

        .video-wrapper {
            position: relative;
            display: inline;
        }

        video {
            width: 100%;
            max-width: 100%;
            height: auto;
            transform: scaleX(-1);
        }

        .volume-block {
            position: absolute;
            width: 100%;
            padding: 0 8px;
            bottom: 8px;
            left: 50%;
            transform: translate(-50%, 0);

            .volume-meter-container {
                position: relative;
                height: 8px;
                background-color: rgba(255, 255, 255, 0.5);
                border-radius: 4px;
                overflow: hidden;

                .volume-meter {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 100%;
                    background-color: #fff;
                }
            }
        }
    }

    .title div,
    .volume-error {
        color: #888c9b;
    }

    .title p {
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
    }

    .check-dynamics {
        padding-bottom: 3px;
        color: #346cb0;
        border-bottom: 1px dashed;
        cursor: pointer;

        &.disabled {
            color: $shade-7;
            pointer-events: none;
            cursor: not-allowed;
        }
    }

    .footer {
        color: #888c9b;
        line-height: 21px;
    }

    .footer-btn {
        max-width: 82px;
        text-align: center;
        margin: 0 5px;

        .footer-badge {
            position: absolute;
            width: 12px;
            height: 12px;
            background: #00a28a;
            right: 0;
            top: 0;
            border-radius: 12px;

            &.offline {
                background: #ea6759;
            }
        }

        .footer-title {
            display: block;
            color: #888c9b;
            font-size: 12px;
            line-height: 17px;
        }

    }

    .middot-icon {
        font-size: 28px;
        vertical-align: sub;
        color: $primary;
    }

    @media (max-width: 1366px) {
        .settings-page-component {
            width: 90%;
        }
    }

    @media (max-width: 600px) {
        .settings-page-component {
            width: auto;
            position: static;
            transform: none;
            padding: 3%;

            .footer {
                display: none;
            }
        }
    }
</style>

<style lang="scss">
    .settings-page-component {
        .q-field__native {
            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        p {
            margin: 0 0 6px;
        }
    }
</style>
