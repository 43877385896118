<template>
    <q-dialog
        v-model="isVisible"
        :persistent="false"
    >
        <q-card class="confirm-modal members-modal q-pa-lg">
            <div class="confirm-modal__title row justify-between">
                {{ localize('Участники чата') }}

                <q-icon
                    name="close"
                    size="20px"
                    v-close-popup
                    class="cursor-pointer" />
            </div>
            <div class="members-block q-mt-lg">
                <div v-if="isLoading && !members.length" class="text-center q-mt-xl">
                    <q-spinner-dots color="primary" size="4em" />
                </div>

                <q-scroll-area style="height: 100%;" @scroll="onScroll">
                    <div v-for="member in members" :key="member.id" class="member-item">
                        <div class="row items-center q-mb-md">
                            <q-avatar size="24px" aria-hidden="true">
                                <img v-if="member.photoUrl" :src="member.photoUrl" alt="" />
                                <custom-avatar
                                    v-else
                                    :text="member.lastName[0] + member.firstName[0]"
                                    :color="CustomAvatarColors.User"
                                />
                            </q-avatar>

                            <div class="col q-ml-sm">
                                <router-link :to="getUserPageUrl(member.id)">
                                    <div>{{ member.lastName }} {{ member.firstName }}</div>
                                </router-link>

                                <div v-if="getRoleIconName(member.role)">
                                    <q-icon
                                        :name="getRoleIconName(member.role)"
                                        size="14px"
                                        class="q-mr-xs" />
                                    <span class="text-description">
                                        {{ localize(getRoleTitle(member.role)) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </q-scroll-area>
            </div>
        </q-card>
    </q-dialog>
</template>

<script lang="ts">
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { ChatClient, ChatMembersDto } from 'src/api/ApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { CustomAvatarColors } from 'components/ui/Avatar/CustomAvatar/enums';
    import { defineComponent, ref } from 'vue';
    import useChatMembers from 'components/Chat/hooks/useChatMembers';
    import { localize } from 'src/services/LocalizationService';


    export default defineComponent({
        name: 'ChatMembers',

        props: {
            // id текущего чата
            chatId: {
                type: Number,
                default: 0
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props) {
            const {
                isVisible,
                getRoleIconName,
                getRoleTitle,
                getUserPageUrl,
            } = useChatMembers();

            const isLoading = ref<boolean>(false);

            // Список участников чата
            const members = ref<ChatMembersDto[]>([]);

            let canLoadFiles: boolean = true;
            let currentPage: number = 1;

            // Загружаем данные при показе окна, если их нет,
            // иначе они будут дублироваться
            function show(chatId: number): void {
                isVisible.value = true;

                if (props.chatId !== chatId) {
                    members.value.length = 0;
                }

                if (chatId && !members.value.length) {
                    loadMembers(chatId);
                }
            }

            // Обработчик скрола - если почти доскролили до конца то загружаем еще страницу
            function onScroll({ verticalPercentage }: { verticalPercentage: number }): void {
                if (verticalPercentage > 0.9 && !isLoading.value && canLoadFiles) {
                    loadMembers();
                }
            }

            // загрузка участников чата с пагинацией
            async function loadMembers(chatId?: number): Promise<void> {
                isLoading.value = true;

                const result = await new ChatClient(getApiClientInitialParams()).getMembers(chatId || props.chatId, currentPage);

                if (result.isSuccess) {
                    members.value.push(...result.entity.pageItems);
                    canLoadFiles = result.entity.pagedMetaData.hasNextPage;

                    if (canLoadFiles) {
                        currentPage++;
                    }
                } else {
                    NotifyErrors(result);
                }

                isLoading.value = false;
            }

            return {
                CustomAvatarColors,
                isVisible,
                isLoading,
                members,
                onScroll,
                getRoleIconName,
                getRoleTitle,
                getUserPageUrl,
                localize,
                show,
            };
        }

    });
</script>

<style lang="scss" scoped>
    .members-modal {
        width: 560px;

        .member-item {
            display: inline-block;
            width: 50%;
        }
    }

    .members-block {
        height: 310px;
    }

    @media (max-width: 960px) {
        .members-modal {
            width: 98%;
        }
    }
</style>
