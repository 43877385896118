import { ErrorDto, Result } from 'src/api/ApiClient';
import { notifyError, NotifyErrors } from 'src/api/ResultOfMethods';
import { nextTick } from 'vue';

/**
 * Преобразует модель формы в модель для хранения серверных ошибок валидации, подходит только для моделей без вложенных полей,
 * для моделей с вложенными полями создаётся вручную
 *
 * Пример входа:
 * loginForm = {
 *     email: 'foo',
 *     password: 42,
 * }
 *
 * Пример выхода:
 * model = {
 *     email: '',
 *     password: '',
 * }
 *
 * @param model модель формы
 * @returns модель для хранения ошибок валидации
 */
export function getServerErrorsModel(model: { [key: string]: any }): Record<string, string> {
    const errorsModel: Record<string, string> = {};
    Object.keys(model).forEach((key: string) => {
        errorsModel[key] = '';
    });
    return errorsModel;
}

/* Преобразует модель формы в модель для хранения серверных ошибок валидации.
   Включает вложенные поля первого уровня вложенности
*/
export function getServerErrorsModelWithChilds(model: { [key: string]: any }): Record<string, string> {
    const errorsModel: Record<string, string> = {};
    Object.keys(model).forEach((key: string) => {
        if (model[key] && Object.keys(model[key]).length > 0) {
            Object.keys(model[key]).forEach((childKey: string) => {
                errorsModel[key + '.' + childKey] = '';
            });
        } else {
            errorsModel[key] = '';
        }
    });
    return errorsModel;
}

/*
    Пример errorsModel с вложенными полями:
    serverErrorsModel = {
        name: '',
        id: '',
        'foo.bar': '',
        'foo.bazz': '',
        something: '',
    }
*/

export function handleServerErrors(result: Result, errorsModel: Record<string, string>, html: boolean = false): void {
    clearServerErrors(errorsModel);
    if (result.httpStatusCode === 422) {
        result.errors.forEach((serverError: ErrorDto) => {
            if (serverError) {
                let serverErrorKey = serverError.key;

                // Если поле в модели - массив, то ошибки там будут возвращаться для каждого элемента
                // и сравнение просто по ключу ни чего не даст, поэтому проверяем,
                // что ключ содержит в себе обращение к индексу например [0]
                if (serverErrorKey.search(/\[/) > -1 && serverErrorKey.search(/\]/) > -1) {
                    serverErrorKey = serverErrorKey.substring(0, serverErrorKey.search(/\[/));
                }

                const fieldKey: string =
                    Object.keys(errorsModel).find((key: string) => key.toLowerCase() === serverErrorKey.toLowerCase()) ||
                    '';

                if (fieldKey) {
                    errorsModel[fieldKey] = serverError.value;
                }
                if (!serverError.key) {
                    notifyError(serverError.value, 'form-notification', html);
                }
            }
        });
        scrollToFirstInvalidField();
    } else {
        NotifyErrors(result, '', true, 'form-notification', html);
    }
}

// Прокрутить страницу до первого не валидного элемента
export function scrollToFirstInvalidField(): void {
    nextTick(() => {
        const firstDOMWithError: HTMLElement | null = document.querySelector(`
            .q-field--error,
            .date-picker__label--invalid,
            .main-picker-date--invalid,
            .editor-component--invalid,
            .attach-file-error,
            .date-ranger-component__field.error
        `);

        if (firstDOMWithError) {
            firstDOMWithError.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    });
}

export function clearServerErrors(errorsModel: Record<string, string>): void {
    Object.keys(errorsModel).forEach((key: string) => {
        errorsModel[key] = '';
    });
}
