<template>
    <div class="search-tab search-card" v-show="isShowCard">
        <div class="header q-mb-sm flex justify-between items-baseline">
            <div>
                <span class="title q-mr-sm">{{ title }}</span>
                <span>{{ getTotalItemsCountText }}</span>
            </div>
        </div>
        <q-card class="card-wrapper">
            <no-items v-if="isNoItems" />
            <div v-show="!isNoItems" class="full-height">
                <slot></slot>
            </div>
        </q-card>
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import { localize } from 'src/services/LocalizationService';

    // Компонент обёртка для табов поиска

    const props = withDefaults(defineProps<{
        infinityMode: boolean;
        title: string,
        isNoItems: boolean;
        totalItemsCount: number | null;
    }>(), {
        infinityMode: false,
    });

    // Подпись для количества найденных соответствий
    const getTotalItemsCountText = computed((): string | null | undefined => {
        // Количество найденных элементов после которого не детализируем значение
        const maxTotalItemCount = 100; 
        
        return props.totalItemsCount !== null && props.totalItemsCount > maxTotalItemCount 
            ? localize('>{maxTotalItemCount}  результатов', { maxTotalItemCount })
            : props.totalItemsCount?.cased(localize('результат'), localize('результата'), localize('результатов'), true);
    });

    // Отображается ли карточка с данным компонентом
    const isShowCard = computed((): boolean => {
        return props.infinityMode || (props.totalItemsCount === null || props.totalItemsCount > 0);
    });

</script>
