import getters from './getters';
import state from './state';
import actions from './actions';
import { defineStore } from 'pinia';

// store для корневого лайаута Main

export const useMainLayoutStore = defineStore('mainLayoutStore', {
    state,
    getters,
    actions,
    persist: {
        storage: window.localStorage,
        paths: [
            'isVisibleChatBlock',
            'isOpenFullDiscipline',
            'isShowMoveGraphPrompt',
            'isVisibleIntegrationAiTooltip',
            'externalReferenceCheckerVisibleSettings',
        ]
    }
});
