import { RoutePageNameEnum, UserRoleForChatEnum } from 'src/api/ApiClient';
import { Ref, ref } from 'vue';
import { Common } from 'src/helpers/Common';
import { localize } from 'src/services/LocalizationService';
import { useRouter } from 'vue-router';

export type ChatMembers = {
    isVisible: Ref<boolean>;
    getRoleIconName: (authorRole?: UserRoleForChatEnum | null) => string;
    getRoleTitle: (authorRole?: UserRoleForChatEnum | null) => string;
    getUserPageUrl: (id: number) => string;
    localize: (text: string) => string;
};

export default function useChatMembers(): ChatMembers {
    const $router = useRouter();

    const isVisible = ref<boolean>(false);

    // в зависимости от роли пользователя показываются разные иконки
    // у студента и бота их нет
    function getRoleIconName(authorRole?: UserRoleForChatEnum | null): string {
        switch (authorRole) {
            case UserRoleForChatEnum.AdministratorRole:
                return 'star';
            case UserRoleForChatEnum.TeacherRole:
                return 'business_center';
            default:
                return '';
        }
    }

    // в зависимости от роли пользователя показываются разные иконки
    function getRoleTitle(authorRole?: UserRoleForChatEnum | null): string {
        switch (authorRole) {
            case UserRoleForChatEnum.AdministratorRole:
                return 'Администратор';
            case UserRoleForChatEnum.TeacherRole:
                return 'Преподаватель';
            default:
                return '';
        }
    }

    // Ссылка на страницу профиля пользователя
    function getUserPageUrl(id: number): string {
        const path = $router.resolve({
            name: Common.getRouteName(RoutePageNameEnum.UserInfo),
            params: { id: id.toString() }
        });
        return path.path;
    }

    return {
        isVisible,
        getRoleIconName,
        getRoleTitle,
        localize,
        getUserPageUrl,
    };

}
