import { ListChatDto, SearchListChatDto } from 'src/api/ApiClient';

// Расширяем интерфейс полями для флагов
// И для хранения чатов комнат
export interface IDisciplineChat extends ListChatDto {
    isOpen?: boolean;
    isLoading?: boolean;
    isCanCreateChatRoom?: boolean;
    rooms: IChatRoom[];
}

// Расширяем интерфейс полями для флагов
// И для хранения чатов решения
export interface IChatRoom extends ListChatDto {
    disciplineChatId?: number | null;
    isOpen?: boolean;
    isLoading?: boolean;
    canLoadSolutionChats?: boolean;
    currentSolutionsChatsPage?: number;
    solutionChats?: ListChatDto[];
}

export interface ISearchListChat extends SearchListChatDto {
    chats?: ListChatDto[];
}

export enum RoomAccessType {
    AllContingent,
    ConcreteStudents
}
