import { ChatStoreInterface } from './state';
import {
    ChatType,
    ChatUnreadedMessagesCountDto,
    ListChatDto
} from 'src/api/ApiClient';

const getters = {
    // Получение количества непрочитанных сообщений по всем чатам пользователя.
    getAllUreadedMessagesCount(state: ChatStoreInterface): number {
        let count = 0;

        state.unreadedMessagesCount.forEach((x: ChatUnreadedMessagesCountDto) => {
            if (!x.isArchive) {
                count += x.count;
            }
        });

        return count;
    },
    // Проверить, отключены ли оповещения для чатов
    getIsChatInMutedSectionOrDiscipline(this: ChatStoreInterface): (type?: ChatType | null, disciplineId?: number | null) => boolean {
        return (type?: ChatType | null, disciplineId?: number | null) => {
            switch (type) {
                case ChatType.Solution:
                case ChatType.Discipline: {
                    const mutedDiscipline = this.disciplineChats.find((x: ListChatDto) => x.disciplineId === disciplineId && x.isMutedDiscipline);
                    return this.isDisciplineChatsMuted || mutedDiscipline != null;
                }
                case ChatType.Private: {
                    return this.isPrivateChatsMuted;
                }
                case ChatType.Group: {
                    return this.isGroupChatsMuted;
                }
                default:
                    return false;
            }
        };
    },
    // Получить все ИД чатов, в сторе
    getAllChatsIds(state: ChatStoreInterface): number[] {
        const result: number[] = [];
        const chats = state.privateChats
            .concat(state.groupChats)
            .concat(state.disciplineChats)
            .concat(state.discussionChats);

        chats.forEach((i: ListChatDto) => {
            if (i.chatId) {
                result.push(i.chatId);
            }
        });

        return result;
    },

};

export default getters;
