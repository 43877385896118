<template>
    <div class="search-component full-window window-height window-width row justify-center items-center">
        <div
            role="button"
            tabindex="0"
            @click="hideSearchBlock"
            :aria-label="localize('Закрыть окно поиска')"
            class="close-button cursor-pointer"
        >
            <span>ESC</span>
            <q-icon name="close" size="32px" />
        </div>

        <div class="search-block">
            <div class="search-block__form content q-mb-md">
                <q-input
                    v-model="searchText"
                    debounce="500"
                    ref="searchInputRef"
                    class="high-field"
                    :aria-label="localize('Введите слово для поиска')"
                >
                    <template v-slot:prepend>
                        <q-icon name="search" />
                    </template>
                </q-input>
                <q-tabs
                    v-model="currentFilter"
                    dense
                    align="left"
                    class="q-mt-md q-mb-md"
                    narrow-indicator
                >
                    <q-tab :name="SearchTabs.All">
                        <div class="filter-item row items-center no-wrap">
                            <DashboardIcon aria-hidden="true" />
                            <span class="q-ml-sm">{{ tabNameAll }}</span>
                        </div>
                    </q-tab>
                    <q-tab :name="SearchTabs.Activities">
                        <div class="filter-item row items-center no-wrap">
                            <CalendarCheckIcon aria-hidden="true" />
                            <span class="q-ml-sm">{{ tabNameActivities }}</span>
                        </div>
                    </q-tab>
                    <q-tab :name="SearchTabs.Disciplines">
                        <div class="filter-item row items-center no-wrap">
                            <DisciplineIcon aria-hidden="true" />
                            <span class="q-ml-sm">{{ tabNameDisciplines }}</span>
                        </div>
                    </q-tab>
                    <q-tab :name="SearchTabs.People">
                        <div class="filter-item row items-center no-wrap">
                            <PeopleIcon aria-hidden="true" />
                            <span class="q-ml-sm">{{ tabNamePeople }}</span>
                        </div>
                    </q-tab>
                    <q-tab :name="SearchTabs.Materials">
                        <div class="filter-item row items-center no-wrap">
                            <OpenBookIcon aria-hidden="true" />
                            <span class="q-ml-sm">{{ tabNameMaterials }}</span>
                        </div>
                    </q-tab>
                    <q-tab :name="SearchTabs.Universities">
                        <div class="filter-item row items-center no-wrap">
                            <BuildingIcon aria-hidden="true" />
                            <span class="q-ml-sm">{{ tabNameUniversities }}</span>
                        </div>
                    </q-tab>
                    <q-tab :name="SearchTabs.Divisions">
                        <div class="filter-item row items-center no-wrap">
                            <CohortsIcon aria-hidden="true" />
                            <span class="q-ml-sm">{{ tabNameDivisions }}</span>
                        </div>
                    </q-tab>
                    <q-tab :name="SearchTabs.Programs">
                        <div class="filter-item row items-center no-wrap">
                            <CohortsIcon aria-hidden="true" />
                            <span class="q-ml-sm">{{ tabNamePrograms }}</span>
                        </div>
                    </q-tab>
                    <q-tab :name="SearchTabs.Cohorts">
                        <div class="filter-item row items-center no-wrap">
                            <CohortsIcon aria-hidden="true" />
                            <span class="q-ml-sm">{{ tabNameCohorts }}</span>
                        </div>
                    </q-tab>
                    <q-tab :name="SearchTabs.Groups">
                        <div class="filter-item row items-center no-wrap">
                            <CohortsIcon aria-hidden="true" />
                            <span class="q-ml-sm">{{ tabNameGroups }}</span>
                        </div>
                    </q-tab>
                </q-tabs>
            </div>
            <div class="row">
                <div class="content">
                    <q-scroll-area
                        v-if="currentFilter === SearchTabs.All"
                        class="scroll-block all-tab">
                        <history-tab v-if="!searchText" @on-item-selected="hideSearchBlock" />
                        <search-activities-tab
                            v-if="searchText"
                            :search-text="searchText"
                            @show-all="setCurrentFilter"
                            @on-load="checkShowCards"
                            @on-item-selected="hideSearchBlock" />
                        <search-disciplines-tab
                            v-if="searchText"
                            :search-text="searchText"
                            @show-all="setCurrentFilter"
                            @on-load="checkShowCards"
                            @on-item-selected="hideSearchBlock" />
                        <search-people-tab
                            v-if="searchText"
                            :search-text="searchText"
                            @show-all="setCurrentFilter"
                            @on-load="checkShowCards"
                            @on-item-selected="hideSearchBlock" />
                        <search-materials-tab
                            v-if="searchText"
                            :search-text="searchText"
                            @show-all="setCurrentFilter"
                            @on-load="checkShowCards"
                            @on-item-selected="hideSearchBlock" />
                        <search-universities-tab
                            v-if="searchText"
                            :search-text="searchText"
                            @show-all="setCurrentFilter"
                            @on-load="checkShowCards"
                            @on-item-selected="hideSearchBlock" />
                        <search-divisions-tab
                            v-if="searchText"
                            :search-text="searchText"
                            @show-all="setCurrentFilter"
                            @on-load="checkShowCards"
                            @on-item-selected="hideSearchBlock" />
                        <search-programs-tab
                            v-if="searchText"
                            :search-text="searchText"
                            @show-all="setCurrentFilter"
                            @on-load="checkShowCards"
                            @on-item-selected="hideSearchBlock" />
                        <search-cohorts-tab
                            v-if="searchText"
                            :search-text="searchText"
                            @show-all="setCurrentFilter"
                            @on-load="checkShowCards"
                            @on-item-selected="hideSearchBlock" />
                        <search-groups-tab
                            v-if="searchText"
                            :search-text="searchText"
                            @show-all="setCurrentFilter"
                            @on-load="checkShowCards"
                            @on-item-selected="hideSearchBlock" />
                        <no-items v-if="searchText && isNotFound" />
                    </q-scroll-area>
                    <div v-else class="scroll-block">
                        <search-activities-tab
                            v-if="showTab(SearchTabs.Activities)"
                            :search-text="searchText"
                            :infinity-mode="true"
                            @on-item-selected="hideSearchBlock" />
                        <search-disciplines-tab
                            v-if="showTab(SearchTabs.Disciplines)"
                            :search-text="searchText"
                            :infinity-mode="true"
                            @on-item-selected="hideSearchBlock" />
                        <search-people-tab
                            v-if="showTab(SearchTabs.People)"
                            :search-text="searchText"
                            :infinity-mode="true"
                            @on-item-selected="hideSearchBlock" />
                        <search-materials-tab
                            v-if="showTab(SearchTabs.Materials)"
                            :search-text="searchText"
                            :infinity-mode="true"
                            @on-item-selected="hideSearchBlock" />
                        <search-universities-tab
                            v-if="showTab(SearchTabs.Universities)"
                            :search-text="searchText"
                            :infinity-mode="true"
                            @on-item-selected="hideSearchBlock" />
                        <search-divisions-tab
                            v-if="showTab(SearchTabs.Divisions)"
                            :search-text="searchText"
                            :infinity-mode="true"
                            @on-item-selected="hideSearchBlock" />
                        <search-programs-tab
                            v-if="showTab(SearchTabs.Programs)"
                            :search-text="searchText"
                            :infinity-mode="true"
                            @on-item-selected="hideSearchBlock" />
                        <search-cohorts-tab
                            v-if="showTab(SearchTabs.Cohorts)"
                            :search-text="searchText"
                            :infinity-mode="true"
                            @on-item-selected="hideSearchBlock" />
                        <search-groups-tab
                            v-if="showTab(SearchTabs.Groups)"
                            :search-text="searchText"
                            :infinity-mode="true"
                            @on-item-selected="hideSearchBlock" />
                    </div>
                </div>
                <div class="filters-block column q-pl-md">
                    <div
                        role="button"
                        tabindex="0"
                        :class="{ active: currentFilter === SearchTabs.All }"
                        @click="currentFilter = SearchTabs.All"
                        class="filter-item flex items-center"
                    >
                        <DashboardIcon aria-hidden="true" />
                        <span>{{ tabNameAll }}</span>
                    </div>
                    <div
                        role="button"
                        tabindex="0"
                        :class="{ active: currentFilter === SearchTabs.Activities }"
                        @click="currentFilter = SearchTabs.Activities"
                        class="filter-item flex items-center"
                    >
                        <CalendarCheckIcon aria-hidden="true" />
                        <span>{{ tabNameActivities }}</span>
                    </div>
                    <div
                        role="button"
                        tabindex="0"
                        :class="{ active: currentFilter === SearchTabs.Disciplines }"
                        @click="currentFilter = SearchTabs.Disciplines"
                        class="filter-item flex items-center"
                    >
                        <DisciplineIcon aria-hidden="true" />
                        <span>{{ tabNameDisciplines }}</span>
                    </div>
                    <div
                        role="button"
                        tabindex="0"
                        :class="{ active: currentFilter === SearchTabs.People }"
                        @click="currentFilter = SearchTabs.People"
                        class="filter-item flex items-center"
                    >
                        <PeopleIcon aria-hidden="true" />
                        <span>{{ tabNamePeople }}</span>
                    </div>
                    <div
                        role="button"
                        tabindex="0"
                        :class="{ active: currentFilter === SearchTabs.Materials }"
                        @click="currentFilter = SearchTabs.Materials"
                        class="filter-item flex items-center"
                    >
                        <OpenBookIcon aria-hidden="true" />
                        <span>{{ tabNameMaterials }}</span>
                    </div>
                    <div
                        role="button"
                        tabindex="0"
                        :class="{ active: currentFilter === SearchTabs.Universities }"
                        @click="currentFilter = SearchTabs.Universities"
                        class="filter-item flex items-center"
                    >
                        <BuildingIcon aria-hidden="true" />
                        <span>{{ tabNameUniversities }}</span>
                    </div>
                    <div
                        role="button"
                        tabindex="0"
                        :class="{ active: currentFilter === SearchTabs.Divisions }"
                        @click="currentFilter = SearchTabs.Divisions"
                        class="filter-item flex items-center"
                    >
                        <CohortsIcon aria-hidden="true" />
                        <span>{{ tabNameDivisions }}</span>
                    </div>
                    <div
                        role="button"
                        tabindex="0"
                        :class="{ active: currentFilter === SearchTabs.Programs }"
                        @click="currentFilter = SearchTabs.Programs"
                        class="filter-item flex items-center"
                    >
                        <Icon name="ProgramIcon" />
                        <span>{{ tabNamePrograms }}</span>
                    </div>
                    <div
                        role="button"
                        tabindex="0"
                        :class="{ active: currentFilter === SearchTabs.Cohorts }"
                        @click="currentFilter = SearchTabs.Cohorts"
                        class="filter-item flex items-center"
                    >
                        <Icon name="GroupIcon" />
                        <span>{{ tabNameCohorts }}</span>
                    </div>
                    <div
                        role="button"
                        tabindex="0"
                        :class="{ active: currentFilter === SearchTabs.Groups }"
                        @click="currentFilter = SearchTabs.Groups"
                        class="filter-item flex items-center"
                    >
                        <PeopleIcon aria-hidden="true" />
                        <span>{{ tabNameGroups }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {
        defineComponent,
        Ref,
        ref,
        watch,
        computed,
        onMounted,
        onBeforeUnmount,
        nextTick,
    } from 'vue';
    import { localize } from 'src/services/LocalizationService';
    import { QInput } from 'quasar';
    import HistoryTab from './components/historyTab/HistoryTab.vue';
    import { SearchTabs } from 'layouts/Main/components/searchBlock/types/searchTabs';
    import SearchActivitiesTab from './components/activitiesTab/SearchActivitiesTab.vue';
    import SearchDisciplinesTab from './components/disciplinesTab/SearchDisciplinesTab.vue';
    import SearchMaterialsTab from './components/materialsTab/SearchMaterialsTab.vue';
    import SearchPeopleTab from './components/peopleTabs/SearchPeopleTab.vue';
    import SearchUniversitiesTab from './components/universitiesTab/SearchUniversitiesTab.vue';
    import SearchDivisionsTab from './components/divisionsTab/SearchDivisionsTab.vue';
    import SearchCohortsTab from './components/cohortsTab/SearchCohortsTab.vue';
    import SearchGroupsTab from './components/groupsTab/SearchGroupsTab.vue';
    import SearchProgramsTab from './components/eduProgramsTab/SearchProgramsTab.vue';
    import { useMainLayoutStore } from 'src/store/module-main-layout';


    // Компонент глобального поиска
    export default defineComponent({
        name: 'SearchBlock',
        components: {
            SearchProgramsTab,
            SearchGroupsTab,
            SearchCohortsTab,
            SearchDivisionsTab,
            SearchUniversitiesTab,
            SearchPeopleTab,
            SearchMaterialsTab,
            SearchDisciplinesTab,
            SearchActivitiesTab,
            HistoryTab,
        },
        /* eslint-disable-next-line max-lines-per-function  */
        setup() {
            const mainLayoutStore = useMainLayoutStore();

            const searchInputRef = ref<QInput | null>(null);

            // Текущий фильтр
            const currentFilter: Ref<string> = ref(SearchTabs.All);

            // Значения для поля поиска
            const searchText: Ref<string> = ref('');

            // Общее кол-во найденных элементов
            const totalItemsCount: Ref<number | null> = ref(null);

            //region Локализация строк
            const tabNameAll: string = localize('Все');
            const tabNameActivities: string = localize('Активности');
            const tabNameDisciplines: string = localize('Дисциплины');
            const tabNameMaterials: string = localize('Материалы');
            const tabNamePeople: string = localize('Люди');
            const tabNameUniversities: string = localize('Организации');
            const tabNameDivisions: string = localize('Подразделения');
            const tabNameCohorts: string = localize('Потоки');
            const tabNameGroups: string = localize('Группы');
            const tabNamePrograms: string = localize('Программы');
            //endregion
            const loadingTabs: Ref<number> = ref(0);

            // При изменении поисковой строки обнуляем информацию
            watch(searchText, () => {
                nextTick(() => {
                    const tabsCount = 9;
                    totalItemsCount.value = null;
                    loadingTabs.value = tabsCount;
                });
            });

            /*Ничего не найдено */
            const isNotFound = computed((): boolean => {
                return currentFilter.value === SearchTabs.All && totalItemsCount.value === 0 && loadingTabs.value === 0;
            });

            // Состояние блока поиска(показан/скрыт)
            const stateSearchBlock = computed(() => {
                return mainLayoutStore.isVisibleSearchBlock;
            });

            // Обновить флаг в сторе
            function hideSearchBlock(): void {
                mainLayoutStore.isVisibleSearchBlock = false;
            }

            // Скрываем компонент по клавише esc
            function hideSearchBlockByEsc(event: KeyboardEvent): void {
                if (event.key === 'Escape') {
                    hideSearchBlock();
                }
            }

            // Проверка на выбранный тип поиска
            function showTab(checkedTabName: SearchTabs): boolean {
                return !!searchText.value && currentFilter.value === checkedTabName;
            }

            // Выбрать поиск по какой-то сущности
            function setCurrentFilter(tabName: SearchTabs): void {
                currentFilter.value = tabName;
            }

            // Количество найденных элементов
            function checkShowCards(loadCount: number): void {
                // Декрементируем количество табов в состоянии загрузки
                loadingTabs.value -= 1;
                // первая загрузка данных
                if (totalItemsCount.value === null) {
                    totalItemsCount.value = loadCount;
                } else {
                    // При загрузке данных по скролу прибавляем
                    totalItemsCount.value += loadCount;
                }
            }

            // Закрыть форму для поиска
            function closeSearchModal(e: any): void {
                if (stateSearchBlock.value) {
                    // Собирает классы по котором не будет закрываться окно поиска
                    const elements = ['.search-block .search-block__form', '.search-card', '.filters-block', '.filter-item', '.menu-item'];
                    if (!(e.target.closest(elements))) {
                        hideSearchBlock();
                    }
                }
            }

            // Инициализация компонента
            onMounted(() => {
                // Сразу стравим фокус в инпут
                searchInputRef.value?.focus();
                // Вешаем обработчик закрытия по esc
                document.addEventListener('keyup', hideSearchBlockByEsc);
                // Вешаем обработчик закрытия клика вне блоков
                nextTick(() => {
                    document.addEventListener('click', closeSearchModal);
                });
            });

            onBeforeUnmount(() => {
                // Удаляем обработчик
                document.removeEventListener('keyup', hideSearchBlockByEsc);
                document.removeEventListener('click', closeSearchModal);
            });

            // Возращаем сущности
            return {
                searchInputRef,
                currentFilter,
                searchText,
                tabNameAll,
                tabNameActivities,
                tabNameDisciplines,
                tabNameMaterials,
                tabNamePeople,
                tabNameUniversities,
                tabNameDivisions,
                tabNameCohorts,
                tabNameGroups,
                tabNamePrograms,
                // Енам для доступа к нему из шаблона
                SearchTabs,
                isNotFound,
                loadingTabs,
                totalItemsCount,
                stateSearchBlock,
                showTab,
                setCurrentFilter,
                checkShowCards,
                hideSearchBlock,
                localize,
            };
        },
    });
</script>

<style lang="scss" src="./SearchBlock.scss"></style>
