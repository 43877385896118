<template>
    <div class="noto-color-emoji">
        <span v-for="(m, i) in textItems" :key="i">
            <a
                v-if="m.id"
                :class="getCssClasses(m, textItems[i + 1])"
                :href="getUserPageUrl(m.id)"
                @click="openUserPage(m.id, $event)"
                class="userBlockName"
            >
                @{{ m.text }}
            </a>
            <template v-else>
                <span v-for="(str, j) in m.textItems" :key="'str' + j">
                    <a v-if="str.typeText === TypeTextEnum.Link" :href="str.text" rel="noopener noreferrer" target="_blank">
                        {{ str.text }}
                    </a>
                    <a
                        v-if="str.typeText === TypeTextEnum.Email"
                        :href="'mailto:' + str.text"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {{ str.text }}
                    </a>
                    <template v-if="str.typeText === TypeTextEnum.Text">
                        <template v-if="str.textItems?.length">
                            <template v-for="(item, index) in str.textItems" :key="index">
                                <CodeEditor
                                    :key="codeEditorId"
                                    v-if="item.typeText === TypeTextEnum.Code"
                                    v-model="item.text"
                                    :language="item.codeLang || ProgrammingLanguages.JavaScript"
                                    :read-only="true"
                                    :inline-code="true"
                                    :style="{ width: editorWidth + 'px' }"
                                />
                                <span v-else v-html="item.text"></span>
                            </template>
                        </template>
                        <span v-else v-html="str.text"></span>
                    </template>
                    <a v-if="str.typeText === TypeTextEnum.LinkWithText" :href="str.link" rel="noopener noreferrer" target="_blank">
                        {{ str.text }}
                    </a>
                </span>
            </template>
        </span>
        <q-resize-observer @resize="setEditorWidth" />
    </div>
</template>

<script lang="ts">
    import { IMessagePart, TypeTextEnum } from 'components/Chat/types/interfaces';
    import { Common } from 'src/helpers/Common';
    import { ProgrammingLanguages, RoutePageNameEnum } from 'src/api/ApiClient';
    import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
    import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, ref, RendererNode } from 'vue';
    import { useAccountStore } from 'src/store/module-account';
    import CodeEditor from 'components/CodeEditor/CodeEditor.vue';
    import langNames from 'components/CodeEditor/LangNames';
    import { ModeChat } from 'components/Chat/types/enums';

    export default defineComponent({
        name: 'MessageText',

        components: {
            CodeEditor
        },

        props: {
            message: {
                required: true,
                type: String,
            },
            // Режим отображения чата
            modeChat: {
                type: String,
                default: ModeChat.Inline
            },
            // Мобильный вид чата
            isMobileView: {
                type: Boolean,
                default: false
            },
            // Является ли соотбещние цитатой
            isAnswerToMessage: {
                type: Boolean,
                default: false
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props) {
            const codeEditorId = ref<string>('codeEditorId' + Common.makeFakeId());

            const textItems = computed<IMessagePart[]>(() => {
                // Меняем codeEditorId чтобы перерендерился редактор при редактировании кода в сообщении
                codeEditorId.value = 'codeEditorId' + Common.makeFakeId();
                return convertMessageToHTML(props.message);
            });

            const $router = useRouter();
            const $route = useRoute();
            const accountStore = useAccountStore();

            const instance = getCurrentInstance();
            let parentEl: RendererNode | undefined | null = null;

            const editorWidth = ref<number>(0);

            const currentUserId = computed<number>(() => {
                return accountStore.getAccountInfo?.id ?? 0;
            });

            function convertMessageToHTML(text: string): IMessagePart[] {
                const messageParts: IMessagePart[] = convertTextToUsersLink(text);
                const items: IMessagePart[] = [];

                messageParts.forEach((x: IMessagePart) => {
                    items.push({
                        id: x.id,
                        text: x.text,
                        textItems: convertTextToLink(x.text),
                    });
                });

                return items;
            }

            // функция ищет упоминания пользователей
            // вида <div id="24" name="Корзинкина Кира" class="userBlockName">@Корзинкина Кира</div>
            // и разбивает строку на массив составных частей
            // если в какой-то есть id - значит тут должна быть ссылка на пользователя
            function convertTextToUsersLink(text: string): IMessagePart[] {
                const items: IMessagePart[] = [];

                if (/\<div/g.test(text)) {
                    const arr = text.split(/(?:\<div|div>)+/);

                    arr.forEach((el: string) => {
                        if (/id/g.test(el)) {
                            // сафари не поддерживает Lookbehind в регулярных выражениях
                            // поэтому обрабатываем строку регуляркой
                            // а потом через replace удаляем ненужное
                            const id = el.match(/(?:id=\")(.*?)(?=\")/g);
                            const name = el.match(/(?:name=\")(.*?)(?=\")/g);

                            if (id && name) {
                                const rawId = el.match(/(?:id=\")(.*?)(?=\")/g)![0];
                                const rawName = el.match(/(?:name=\")(.*?)(?=\")/g)![0];

                                items.push({
                                    id: +rawId.replace('id="', ''),
                                    text: rawName.replace('name="', ''),
                                });
                            } else {
                                items.push({ text: el.replace(/&nbsp;/, '').replace(/<br>/, '') });
                            }
                        } else {
                            items.push({ text: el.replace(/&nbsp;/, '').replace(/<br>/, '') });
                        }
                    });

                    return items;
                } else {
                    return [{
                        text,
                        typeText: TypeTextEnum.Text
                    }];
                }
            }

            // функция ищет в тексте ссылки и разбивает текст
            // на массив строк с типом этой строки
            // чтобы знать какой текст надо сделать ссылкой
            function convertTextToLink(text: string): IMessagePart[] {
                const result: IMessagePart[] = [];
                const exp = /((?:(?:https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])|(?:(?:(?:[0-9A-Za-z]{1}[-0-9A-z\.]{1,}[0-9A-Za-z]{1}))@(?:[-A-Za-z]{1,}\.){1,2}[-A-Za-z]{2,})|(?:\[(?:[^\|]+)\|(?:[^\]]*)\]))/i;
                const array = text.split(exp);

                array.forEach((item: string) => {
                    const type = getTextType(item);

                    if (type === TypeTextEnum.LinkWithText) {
                        const match = item.match(/(\[([^\|]+)\|([^\]]*)\])/i);

                        if (match) {
                            const add: IMessagePart = {
                                text: match[2],
                                link: match[3],
                                typeText: type,
                            };

                            result.push(add);
                        }
                    } else if (type === TypeTextEnum.Text) {
                        // Удаляем из текста возврат каретки
                        item = item.replace(/\r/g, '');
                        item = item.replace(/\n/g, '');
                        // ищем в тексте вставки кода
                        const matches = item.match(/\`\`\`(.+?)\`\`\`/g);

                        if (matches && matches.length) {
                            const items = getCodeParts(item, matches);

                            const add: IMessagePart = {
                                textItems: items,
                                text: item,
                                typeText: type
                            };

                            result.push(add);
                        } else {
                            const add: IMessagePart = {
                                text: item,
                                typeText: type,
                            };

                            result.push(add);
                        }
                    } else {
                        const add: IMessagePart = {
                            text: item,
                            typeText: type,
                        };

                        result.push(add);
                    }
                });

                return result;
            }

            /**
             * Распарсить в тексте все вставки кода и просто текст в массив элементов сообщения
             * @param item - текст сообщения
             * @param matches - найденые совпадения
             */
            function getCodeParts(item: string, matches: string[]): IMessagePart[] {
                const items: IMessagePart[] = [];
                let startIndex = 0;

                for (let i = 0; i < matches.length; i++) {
                    let index = 0;

                    // try/catch нужен для ситуации,
                    // когда внутри будут какие-то символы
                    // который будут ломать регулярку (которая внутри search)
                    try {
                        // Сначала экранируем символы, потом ищем
                        index = item.search(matches[i].replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
                    } catch (e) {
                        console.warn(e);
                        break;
                    }

                    const partText: IMessagePart = {
                        text: item.substring(startIndex, index),
                        typeText: TypeTextEnum.Text,
                    };

                    if (partText.text.trim()) {
                        items.push(partText);
                    }

                    // Убираем тройной апостроф, тег переноса заменяем на символ
                    // и заменяем код некоторых символов на символы
                    const code = matches[i]
                        .replace(/\`{3}/g, '')
                        .replace(/(<br class="nr">)/g, '\n')
                        .replace(/&gt;/gi, '>')
                        .replace(/&lt;/gi, '<');

                    if (code.trim()) {
                        const partCode: IMessagePart = {
                            text: Common.stripTags(code).trim(),
                            typeText: TypeTextEnum.Code,
                        };

                        const codeLang = getCodeLang(partCode.text);

                        if (codeLang) {
                            partCode.codeLang = codeLang.lang;
                            // Вырезаем название языка из текста
                            partCode.text = partCode.text.substring(codeLang.langName.length, partCode.text.length).trim();
                        }

                        items.push(partCode);
                    }

                    startIndex = index + matches[i].length;
                }

                // После последнего блока с кодом может быть текст, который не будет обработан в цикле выше
                startIndex = item[startIndex] === '`' ?  startIndex + 1 : startIndex;
                const lastTextPart = item.substring(startIndex, item.length);

                if (lastTextPart.trim()) {
                    const partText: IMessagePart = {
                        text: lastTextPart.trim(),
                        typeText: TypeTextEnum.Text,
                    };

                    items.push(partText);
                }

                return items;
            }

            // В самом начале текста может быть указано нащвание языка, ищем его
            function getCodeLang(code: string): { lang: ProgrammingLanguages, langName: string } | null {
                let codeLang: { lang: ProgrammingLanguages, langName: string } | null = null;

                for (let i = 0; i < langNames.length; i++) {
                    for (let j = 0; j < langNames[i].names.length; j++) {
                        const langName = langNames[i].names[j].toLowerCase();
                        const firstPartText = code.substring(0, langName.length).toLowerCase();

                        if (langName === firstPartText) {
                            codeLang = {
                                lang: langNames[i].lang,
                                langName: firstPartText
                            };
                            break;
                        }
                    }

                    if (codeLang) {
                        break;
                    }
                }

                return codeLang;
            }

            // функция получает тип строки
            // просто сслыка это, либо сыылка с текстом, email
            // либо же это просто строка
            function getTextType(str: string): TypeTextEnum {
                const httpExp = /((?:https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;
                const emailExp = /((?:(?:[0-9A-Za-z]{1}[-0-9A-z\.]{1,}[0-9A-Za-z]{1}))@(?:[-A-Za-z]{1,}\.){1,2}[-A-Za-z]{2,})/i;
                const linkWithTextExp = /(\[([^\|]+)\|([^\]]*)\])/i;

                if (str.match(linkWithTextExp)) {
                    return TypeTextEnum.LinkWithText;
                }
                if (str.match(httpExp)) {
                    return TypeTextEnum.Link;
                }
                if (str.match(emailExp)) {
                    return TypeTextEnum.Email;
                }

                return TypeTextEnum.Text;
            }

            function getRouteInfoForUserPage(id: number): RouteLocationRaw {
                return {
                    name: Common.getRouteName(RoutePageNameEnum.UserInfo),
                    params: { id: id.toString() },
                };
            }

            function getCssClasses(data: IMessagePart, next?: IMessagePart): string {
                let css = '';

                if (Number(data.id) === currentUserId.value) {
                    css += 'userBlockName_current';

                    // Если после упоминания идет запятая - убираем отступ
                    if (next && next.textItems && next.textItems[0]?.text[0] === ',') {
                        css += ' no-right-margin';
                    }
                }

                return css;
            }

            function getUserPageUrl(id: number): string {
                return $router.resolve(getRouteInfoForUserPage(id)).path;
            }

            function openUserPage(id: number, event: Event): void {
                event.preventDefault();
                const routeData = getRouteInfoForUserPage(id);

                // Если находимся в звонке, то открываем профиль в новой вкладке
                if ($route.name === Common.getRouteName(RoutePageNameEnum.CallEnter)) {
                    const path = $router.resolve(routeData);
                    window.open(path.href, '_blank');
                } else {
                    $router.push(routeData);
                }
            }

            // Сообщение у нас максимум 550px на странице чатов, а в миничате 87%
            // вычисляем такую же ширину (но меньше чтобы не вылазил за пределы) для редактора
            function setEditorWidth(): void {
                const parentWidth = parentEl?.clientWidth ?? 0;
                // Переводим 550px в %
                const desktopPercent = ((550 * 100) / parentWidth) - 3;
                const widthPercent = props.modeChat === ModeChat.Inline
                    ? (props.isAnswerToMessage ? 74 : 79)
                    : (props.isMobileView ? 85 : desktopPercent);

                editorWidth.value = parentWidth * widthPercent / 100;
            }

            onMounted(function () {
                nextTick(function () {
                    parentEl = instance?.parent?.vnode.el;
                    setEditorWidth();
                });
            });

            return {
                TypeTextEnum,
                ProgrammingLanguages,
                currentUserId,
                editorWidth,
                textItems,
                codeEditorId,
                getUserPageUrl,
                openUserPage,
                getCssClasses,
                setEditorWidth,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .userBlockName {
        text-decoration: none;
    }

    .userBlockName_current {
        border-radius: 7px;
        background-color: $primary;
        color: #fff;
        margin-right: 4px;
        padding: 5px 7px;
        line-height: 2;
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;

        &.no-right-margin {
            margin-right: 0;
        }
    }
</style>
