import { AuthorizationStoreInterface } from './state';

const getters = {
    // Залогинен ли пользователь
    getIsLogged(state: AuthorizationStoreInterface): boolean {
        return !!state.jwtToken;
    },
    // Получить токен авторизации
    getToken(state: AuthorizationStoreInterface): string {
        return state.jwtToken;
    },
    // Получить дату создания токена авторизации
    getTokenDateOfCreation(state: AuthorizationStoreInterface): number | undefined {
        return state.jwtTokenDateOfCreation;
    },
};

export default getters;
