import { SearchEntity } from '../types/SearchEntity';
import { SearchTabs } from '../types/searchTabs';

/**
 * Отображение значения SearchEntity в значение SearchTabs
 */
export function mapSearchEntityToTab(entity: SearchEntity): SearchTabs {
  const map = {
      [SearchEntity.Activities]: SearchTabs.Activities,
      [SearchEntity.Cohorts]: SearchTabs.Cohorts,
      [SearchEntity.Disciplines]: SearchTabs.Disciplines,
      [SearchEntity.Divisions]: SearchTabs.Divisions,
      [SearchEntity.Groups]: SearchTabs.Groups,
      [SearchEntity.Materials]: SearchTabs.Materials,
      [SearchEntity.People]: SearchTabs.People,
      [SearchEntity.Programs]: SearchTabs.Programs,
      [SearchEntity.Universities]: SearchTabs.Universities,
  };

  return map[entity];
}
