<template>
    <div>
        <div class="text-center" v-if="isLoading">
            <q-spinner-dots color="primary" size="4em"/>
        </div>
        <search-cohort-item
            v-else
            v-for="cohort in cohorts"
            :key="cohort.id"
            :item="cohort"
            @on-item-selected="onItemSelected"/>
        <show-all-btn v-if="canShowAll" @show-all="showAll"/>
    </div>
</template>

<script lang="ts" setup>
    import { Ref, ref, toRefs } from 'vue';
    import { SearchCohortDto } from 'src/api/ApiClient';
    import SearchCohortItem from 'layouts/Main/components/searchBlock/components/cohortsTab/SearchCohortItem.vue';
    import { SearchTabs } from 'layouts/Main/components/searchBlock/types/searchTabs';
    import { SearchEntity } from '../../types/SearchEntity';
    import { useSearch } from '../../hooks/useSearch';
    import ShowAllBtn from '../ShowAllBtn.vue';

    /**
     * Компонент с отображением первых 5 найденных потоков
     * Отображается когда выбран поиск по всем сущностям
     */
    const cohorts: Ref<SearchCohortDto[]> = ref([]);
    const emit = defineEmits<{
        (e: 'on-item-selected'): void;
        (e: 'on-found-items-count', itemsCount: number): void;
        (e: 'show-all', tab: SearchTabs): void;
    }>();
    const props = withDefaults(defineProps<{
        searchText: string;
    }>(), {
        searchText: '',
    });
    const { searchText } = toRefs(props);
    const {
        // Признак, отвечающий за загрузку данных
        isLoading,
        // Нужно ли показывать кнопку "показать все"
        canShowAll,
        // Обработчик выбора элемента
        onItemSelected,
        showAll,
    } = useSearch(SearchEntity.Cohorts, false, cohorts, searchText, emit);
</script>
