
// Родительский layout для layout'ов интерфейса приложения
// но только для тех, которые показываются залогинненому пользователю
// не залогиненному чаты не нужны
import { ChatMessageDto, ChatMessageReactionDto } from 'src/api/ApiClient';
import { chatBus } from 'components/EventBuses';
import { UpdateCountUnreadedMessagesDto } from 'src/services/hubs/types/interfaces';
import { IErrorMessageDto } from 'src/types/generated/hubs/chatPartialHub/models/IErrorMessageDto';
import { CommonOdinHub } from 'src/services/hubs/CommonOdinHub';
import { ChatPartialHub } from 'src/services/hubs/ChatPartialHub';
import { getCurrentInstance } from 'vue';
import { ChatBusEvents } from 'components/EventBuses/emuns';

export type SharedLayout = {
    initCommonHub: () => void;
};

export default function useSharedLayout(): SharedLayout {
    const app = getCurrentInstance();

    //#region Слушатели событий чата
    function onMessageReceivedHandler(data: ChatMessageDto): void {
        chatBus.emit(ChatBusEvents.OnMessageReceivedHandler, data);
    }

    function onRemoveMessageReceivedHandler(data: ChatMessageDto): void {
        chatBus.emit(ChatBusEvents.OnRemoveMessageReceivedHandler, data);
    }

    function onEditMessageReceivedHandler(data: ChatMessageDto): void {
        chatBus.emit(ChatBusEvents.OnEditMessageReceivedHandler, data);
    }

    function onSendMessageReactionReceivedHandler(data: ChatMessageReactionDto): void {
        chatBus.emit(ChatBusEvents.OnSendMessageReactionReceivedHandler, data);
    }

    function onRemoveMessageReactionReceivedHandler(data: ChatMessageReactionDto): void {
        chatBus.emit(ChatBusEvents.OnRemoveMessageReactionReceivedHandler, data);
    }

    function onPinMessageReceivedHandler(data: ChatMessageDto): void {
        chatBus.emit(ChatBusEvents.OnPinMessageReceivedHandler, data);
    }

    function onUnpinMessageReceivedHandler(data: ChatMessageDto): void {
        chatBus.emit(ChatBusEvents.OnUnpinMessageReceivedHandler, data);
    }

    function onUpdateReadedMessageHandler(data: ChatMessageDto): void {
        chatBus.emit(ChatBusEvents.OnUpdateReadedMessageHandler, data);
    }

    function onUpdateCountUnreadedMessagesHandler(data: UpdateCountUnreadedMessagesDto): void {
        chatBus.emit(ChatBusEvents.OnUpdateCountUnreadedMessagesHandler, data);
    }

    function onErrorHandler(data: IErrorMessageDto): void {
        chatBus.emit(ChatBusEvents.OnErrorHandler, data);
    }
    //#endregion

    function initCommonHub(): void {
        const hub = new CommonOdinHub() as ChatPartialHub;

        hub.onChatMessageReceived(onMessageReceivedHandler);
        hub.onChatMessageRemove(onRemoveMessageReceivedHandler);
        hub.onChatMessageEdit(onEditMessageReceivedHandler);
        hub.onChatMessageReactionReceived(onSendMessageReactionReceivedHandler);
        hub.onChatMessageReactionRemove(onRemoveMessageReactionReceivedHandler);
        hub.onChatMessagePin(onPinMessageReceivedHandler);
        hub.onChatMessageUnPin(onUnpinMessageReceivedHandler);
        hub.onChatCountUnreadMessagesUpdated(onUpdateCountUnreadedMessagesHandler);
        hub.onChatLastReadMessagesUpdated(onUpdateReadedMessageHandler);
        hub.onChatErrorHandler(onErrorHandler);

        if (app) {
            app.appContext.config.globalProperties.$commonHub = hub;
        }
    }

    return {
        initCommonHub
    };
}
