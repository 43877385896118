import { ICallHubServerEvents } from 'src/types/generated/hubs/callPartialHub/interfaces/ICallHubServerEvents';
import {
    ICallHubClientEventListeners,
} from 'src/types/generated/hubs/callPartialHub/interfaces/ICallHubClientEventListeners';
import { ICallEventDto } from 'src/types/generated/hubs/callPartialHub/models/ICallEventDto';
import { ICallEventForUserDto } from 'src/types/generated/hubs/callPartialHub/models/ICallEventForUserDto';
import { ICallChangeFlagEventDto } from 'src/types/generated/hubs/callPartialHub/models/ICallChangeFlagEventDto';
import { ICallAdminErrorMessageDto } from 'src/types/generated/hubs/callPartialHub/models/ICallAdminErrorMessageDto';
import { ICallAdminStateDto } from 'src/types/generated/hubs/callPartialHub/models/ICallAdminStateDto';
import {
    ICallForceMuteUserMicrophoneDto,
} from 'src/types/generated/hubs/callPartialHub/models/ICallForceMuteUserMicrophoneDto';
import { ICallStartDto } from 'src/types/generated/hubs/callPartialHub/models/ICallStartDto';
import { CommonOdinHub } from 'src/services/hubs/CommonOdinHub';
import { ICallUserHandRaisedDto } from 'src/types/generated/hubs/callPartialHub/models/ICallUserHandRaisedDto';
import { IUserActiveCallsDto } from 'src/types/generated/hubs/callPartialHub/models/IUserActiveCallsDto';
import { ICallStartRecordDto } from 'src/types/generated/hubs/callPartialHub/models/ICallStartRecordDto';

export abstract class CallPartialHub extends CommonOdinHub
    implements ICallHubServerEvents, ICallHubClientEventListeners {

    // Пользователю получившему сообщение запретили микрофон
    onCallMicrophonePermissionChangedAsync(e: (arg: ICallChangeFlagEventDto) => void): void {
        this.connection.on('CallMicrophonePermissionChangedAsync', e);
    }

    // Пользователю получившему сообщение запретили демонстрацию экрана
    onCallSharingPermissionChangedAsync(e: (arg: ICallChangeFlagEventDto) => void): void {
        this.connection.on('CallSharingPermissionChangedAsync', e);
    }

    // Изменилось состояние админ-панели
    onCallAdminStateChangedAsync(e: (arg: ICallAdminStateDto) => void): void {
        this.connection.on('CallAdminStateChangedAsync', e);
    }

    // В процессе администрирования звонка произошла ошибка
    onCallAdminErrorHandlerAsync(e: (arg: ICallAdminErrorMessageDto) => void): void {
        this.connection.on('CallAdminErrorHandlerAsync', e);
    }

    // Событие вызывается, когда кто-то принудительно опусил всем поднятые руки
    onCallAllHandsPutDown(e: (arg: ICallEventDto) => void): void {
        this.connection.on('CallAllHandsPutDownAsync', e);
    }

    onCallUserAccepted(e: (arg: number) => void): void {
        this.connection.on('CallAcceptedAsync', e);
    }

    // Событие вызвается когда пользователь отклонил входящий звонок
    onCallDeclined(e: (arg: number) => void): void {
        this.connection.on('CallDeclinedAsync', e);
    }

    // Событие вызвается когда звонок завершился
    onCallFinished(e: (arg: number, arg1: boolean) => void): void {
        this.connection.on('CallFinishedAsync', e);
    }

    // Событие вызывается, когда кто-то принудительно выключил всем микрофоны
    onCallForceAllMicrophonesMuted(e: (arg: ICallEventDto) => void): void {
        this.connection.on('CallForceAllMicrophonesMutedAsync', e);
    }

    // Событие вызывается, когда кто-то принудительно выключил кому-то микрофон
    onCallForceUserMicrophoneMuted(e: (arg: ICallForceMuteUserMicrophoneDto) => void): void {
        this.connection.on('CallForceUserMicrophoneMutedAsync', e);
    }

    // Событие вызывается, когда выключили запись звонка
    onCallRecordingDisabled(e: (arg: ICallEventDto) => void): void {
        this.connection.on('CallRecordingDisabledAsync', e);
    }

    // Событие вызывается, когда включили запись звонка
    onCallRecordingEnabled(e: (arg: ICallStartRecordDto) => void): void {
        this.connection.on('CallRecordingEnabledAsync', e);
    }

    // Событие вызывается, когда запись звонка длиться 3 часа или больше (далее - каждый час)
    onCallRecordingBreakNotify(e: (arg: ICallEventDto) => void): void {
        this.connection.on('CallRecordingBreakNotifyAsync', e);
    }

    // Событие вызывается, когда кто-то из пользователей в звонке выбрал продолжать звонок
    onCallRecordingContinue(e: (arg: ICallEventDto) => void): void {
        this.connection.on('CallRecordingContinueAsync', e);
    }

    // Событие вызывается, когда начался какой-либо звонок
    onCallStarted(e: (arg: ICallStartDto) => void): void {
        this.connection.on('CallStartedAsync', e);
    }

    onNotifyAboutCall(e: (arg: ICallStartDto) => void): void {
        this.connection.on('NotifyAboutCallAsync', e);
    }

    // Событие вызывается когда пользователь userId опустил руку или когда пользователю userId опустил руку пользователь actorId
    onCallUserHandPutDown(e: (arg: ICallEventForUserDto) => void): void {
        this.connection.on('CallUserHandPutDownAsync', e);
    }

    // Событие вызывается, когда пользователь поднял руку
    onCallUserHandRaised(e: (arg: ICallUserHandRaisedDto) => void): void {
        this.connection.on('CallUserHandRaisedAsync', e);
    }

    onCallUserLeave(e: (arg: ICallEventDto) => void): void {
        this.connection.on('CallLeaveAsync', e);
    }

    // Событие вызывается, когда пользователь выключил микрофон
    onCallUserMicrophoneMuted(e: (arg: ICallEventDto) => void): void {
        this.connection.on('CallUserMicrophoneMutedAsync', e);
    }

    // Событие вызывается, когда пользователь включил микрофон
    onCallUserMicrophoneUnMuted(e: (arg: ICallEventDto) => void): void {
        this.connection.on('CallUserMicrophoneUnMutedAsync', e);
    }

    onCallUserActiveCalls(e: (arg: IUserActiveCallsDto[]) => void): void {
        this.connection.on('CallUserActiveCallsAsync', e);
    }

    // Событие вызывается за 15 минут до окончания звонка, продолжительность которого составляет 8 часов
    onCallTerminateNotify(e: (arg: ICallEventDto) => void): void {
        this.connection.on('CallTerminateNotifyAsync', e);
    }

    getActiveCallsAsync(): Promise<void> {
        return this.connection.invoke('GetActiveCallsAsync');
    }

    // Поменять состояние признака разрешения микрофона для участника в звонке
    changeUserMicrophonePermissionsAsync(callId: number, userId: number, isAllowedState: boolean): Promise<void> {
        return this.connection.invoke('ChangeUserMicrophonePermissionsAsync', callId, userId, isAllowedState);
    }

    // Поменять состояние признака разрешения демонстации экрана для участника в звонке
    changeUserSharingPermissionAsync(callId: number, userId: number, isAllowedState: boolean): Promise<void> {
        return this.connection.invoke('ChangeUserSharingPermissionAsync', callId, userId, isAllowedState);
    }

    // Поменять состояние признака разрешения демонстрации для всех в звонке
    changeAllSharingPermissionAsync(callId: number, isAllowedState: boolean): Promise<void> {
        return this.connection.invoke('ChangeAllSharingPermissionAsync', callId, isAllowedState);
    }

    // Поменять состояние признака разрешения микрофона для всех в звонке
    changeAllMicrophonePermissionAsync(callId: number, isAllowedState: boolean): Promise<void> {
        return this.connection.invoke('ChangeAllMicrophonePermissionAsync', callId, isAllowedState);
    }

    // Вызвать опускание руки у пользователя в звонке
    handPutDownForUserAsync(callId: number, userId: number): Promise<void> {
        return this.connection.invoke('HandPutDownForUserAsync', callId, userId);
    }

    // Вызвать опускание всех рук в звонке
    handPutDownForAllAsync(callId: number): Promise<void> {
        return this.connection.invoke('HandPutDownForAllAsync', callId);
    }
}
