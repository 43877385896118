<template>
    <ui-select
        ref="selectRef"
        v-model="inputValue"
        dense
        options-dense
        :options="tempTimeZones"
        option-value="id"
        option-label="name"
        map-options
        :label="getLabel"
        :is-server-filter="true"
        @update:model-value="$emit('update:model-value', $event)"
        @on-change="onChange"
        :hide-bottom-space="!isRequired"
        :loading="isLoadingItems"
        :error="error"
        :error-message="errorMessage"
        :disable="disable"
        popup-content-class="select"
        @filter="filteredOptions"
        :rules="rules"
        class="auto-height-select"
    />
</template>

<script lang="ts">
    import {
        AccountClient,
        getApiClientInitialParams,
        TimeZoneDto
    } from 'src/api/ApiClient';
    import { computed, defineComponent, onBeforeMount, ref, SetupContext } from 'vue';
    import useDataSelect, { componentProps } from 'components/ui/selects/DataSelect/useDataSelect';
    import { localize } from 'src/services/LocalizationService';

    export default defineComponent({
        name: 'TimeZoneSelect',

        emits: [
            'on-change',
            'on-time-zone',
            'update:model-value'
        ],

        props: {
            // eslint-disable-next-line vue/no-unused-properties
            modelValue: componentProps.value,
            rules: componentProps.rules,
            error: componentProps.error,
            errorMessage: componentProps.errorMessage,
            isRequired: componentProps.isRequired,
            disable: componentProps.disable,
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            // Импортируем необходимые переменные и функции
            const {
                selectRef,
                inputValue,
                isLoadingItems,
                hasError,
                onChange,
                validate,
            } = useDataSelect(props, context as SetupContext);

            const timeZones: TimeZoneDto[] = [];
            const tempTimeZones = ref<TimeZoneDto[]>([]);

            const getLabel = computed<string>(() => {
                const isRequired = props.isRequired ? '*' : '';
                return localize('Часовой пояс') + isRequired ;
            });

            // Загрузить список таймзон
            async function loadTimeZones(): Promise<void> {
                const result = await new AccountClient(getApiClientInitialParams()).getTimeZones();

                result.entity.timeZones.forEach((el: TimeZoneDto) => {
                    timeZones.push(el);
                });
            }

            // Поиск среди таймзон
            function filteredOptions(val: string, update: any): void {
                update(() => {
                    tempTimeZones.value = timeZones.filter((v: TimeZoneDto) => {
                        return v.name.toLowerCase().includes(val.toLowerCase());
                    });
                });
            }

            onBeforeMount(loadTimeZones);

            return {
                selectRef,
                inputValue,
                isLoadingItems,
                hasError,
                tempTimeZones,
                getLabel,
                localize,
                filteredOptions,
                onChange,
                validate
            };
        }
    });
</script>
