/* Класс с набором статических методов управляющих разделом head и событиями для счетчиков */
import { RouteLocation } from 'vue-router';
import LocalizationService from 'src/services/LocalizationService';

export default class MetaRouter {
    static manageRoute(route: RouteLocation): void {
        if (route.meta.isIndexed) { // Если роуту не назначен признак isIndexed (индексируемая), то нужно удалить meta noindex
            this.removeNoIndexMeta();
        } else { // По умолчанию роуты не индексируемые - поэтому добавляем meta noindex
            this.addNoIndexMeta();
        }

        // Если путь без параметров не равен пути с параметрами, то добавляем canonical link, иначе удаляем
        if (route.path !== route.fullPath) {
            this.setCanonical(route.path);
        } else {
            this.removeCanonical();
        }

        const manageTitle = (): void => {
            const meta = route.meta as any;
            this.setTitle(meta.getTitle ? meta.getTitle() : ''); // Установка титла из свойства Route
            this.hitUrl(route.fullPath, (route.meta as any).title || ''); // Отправляем данные об изменении url в метрику и аналитик
        };

        // Проверяем, есть ли данные локализации
        // Если их нет, то при обращении к route.meta.getTitle()
        // будет отправлен запрос на перевод
        if (LocalizationService.isReady) {
            manageTitle();
        } else {
            LocalizationService.onReady(manageTitle);
        }
    }

    static hitUrl(url: string, title:string): void {
        const ym = (window as any)['ym'];
        if (ym) {
            ym(85243030, 'hit', url, { title });
        }
        const gtag = (window as any)['gtag'];
        if (gtag) {
            gtag('set', 'page_path', url);
        }
    }

    static removeNoIndexMeta(): void {
        document.querySelector('meta[name=\'robots\']')?.remove();
    }

    static addNoIndexMeta(): void {
        if (!document.querySelector('meta[name=\'robots\']')) {
            const noindexMeta = document.createElement('meta');
            noindexMeta.name = 'robots';
            noindexMeta.content = 'noindex';
            document.getElementsByTagName('head')[0]?.appendChild(noindexMeta);
        }
    }

    static setTitle(title: string): void {
        document.title = title;
    }

    static setCanonical(url: string): void {
        const link = document.createElement('link');
        link.rel = 'canonical';
        link.href = location.protocol + '//' + location.host + url;
        document.getElementsByTagName('head')[0]?.appendChild(link);
    }

    static removeCanonical(): void {
        document.querySelector('link[rel=\'canonical\']')?.remove();
    }
}
