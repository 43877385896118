<template>
    <div />
</template>

<script lang="ts">
    import { RoutePageNameEnum } from 'src/api/ApiClient';
    import { Common } from 'src/helpers/Common';
    import { defineComponent, onBeforeMount } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useAccountStore } from 'src/store/module-account';
    import { useAuthorizationStore } from 'src/store/module-authorization';
    import { useChatStore } from 'src/store/module-chat';

    export default defineComponent({
        name: 'MoodleLogin',

        setup() {
            const $router = useRouter();
            const $route = useRoute();
            const chatStore = useChatStore();
            const accountStore = useAccountStore();

            //Перенаправление на нужную страницу курса/активности
            async function redirectToPath(disciplineId: number, activityId: number | null): Promise<void> {
                if (activityId) {
                    await $router.push({
                        name: Common.getRouteName(RoutePageNameEnum.ActivityInfo),
                        params: { id: activityId.toString() },
                    });
                } else {
                    await $router.push({
                        name: Common.getRouteName(RoutePageNameEnum.DisciplineInfo),
                        params: { id: disciplineId.toString() },
                    });
                }
            }

            // Метод логина для пользователя
            async function logIn(jwtToken: string): Promise<void> {
                chatStore.unreadedMessagesCount = [];
                useAuthorizationStore().setToken(jwtToken);
                chatStore.loadChatsUnreadedCount();
                await accountStore.loadAccountInfo();
            }

            onBeforeMount(async () => {
                const token = $route.query.token?.toString() ?? '';
                const activityId = Number($route.query.activityId) ?? null;
                const disciplineId = Number($route.query.disciplineId);

                await logIn(token);

                //Редирект на нужную страницу
                await redirectToPath(disciplineId, activityId);
            });
        },
    });
</script>
