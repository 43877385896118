import {
    computed, ComputedRef,
    nextTick,
    onBeforeMount,
    PropType, Ref,
    ref,
    SetupContext, WritableComputedRef,
} from 'vue';
import {
    CohortGradeUserFilterResponseModel,
    DropDownItem,
    PagedResultOfDropDownItem, PagedResultOfLibrarySelectModel,
    ResultOf,
} from 'src/api/ApiClient';
import { QSelect, QVirtualScroll } from 'quasar';
import { localize } from 'src/services/LocalizationService';
import UiSelect from 'components/ui/selects/UiSelect';
import { ValidationRule } from 'quasar/dist/types/api/validation';

export const componentProps = {
    // v-model компонента
    value: {
        type: Object as PropType<any | Array<any> | null>,
        default: null
    },
    error: {
        type: Boolean,
        default: false
    },
    errorMessage: {
        type: String,
        default: ''
    },
    label: {
        type: String,
        default: ''
    },
    optionValue: {
        type: String,
        default: 'value'
    },
    optionLabel: {
        type: String,
        default: 'text'
    },
    placeholder: {
        type: String,
        default: ''
    },
    // Обязательно поле для заполнения или нет
    isRequired: {
        type: Boolean,
        default: false
    },
    disable: {
        type: Boolean,
        default: false
    },
    multiple: {
        type: Boolean,
        default: false
    },
    isReadOnly: {
        type: Boolean,
        default: false
    },
    loadAction: {
        type: Function as PropType<(search: string | null, page: number, ids?: number[] | null | undefined) => Promise<ResultOf<PagedResultOfDropDownItem | PagedResultOfLibrarySelectModel | CohortGradeUserFilterResponseModel>>>,
    },
    // Массив функций/строк; Если строка, то это должно быть имя одного из встроенных правил проверки в квазаре
    rules: {
        type: Array as PropType<ValidationRule[]>,
    },
    // Нужно ли делани инициализацию в onBeforeMount
    // У некоторых компонентов она своя
    isNeedInit: {
        type: Boolean,
        default: true
    },
    // Компактное отображение мультиселектов
    isCompactStyle: {
        type: Boolean,
        default: false
    },
};

export type DataSelect = {
    selectRef: Ref<typeof UiSelect | undefined>;
    inputValue: WritableComputedRef<any>;
    searchItemFilter: Ref<string>;
    currentPage: number;
    canLoadItems: boolean;
    isLoadingItems: Ref<boolean>;
    items: Ref<DropDownItem[]>;
    hasError: ComputedRef<boolean>;
    idsSelect: ComputedRef<number[] | null>;
    validate: () => void;
    checkPagination: (hasNextPage: boolean) => void;
    onChange: (event?: any | null) => void;
    resetFilter: () => void;
    filterItems: (val: string, update: (val: () => void) => void) => void;
    onScrollItems: (params: { to: number, ref: QVirtualScroll }) => Promise<void>;
    localize: (text: string, options?: Record<string, string | number> | null) => string;
};

// eslint-disable-next-line max-lines-per-function
export default function useDataSelect(props: any, context: SetupContext): DataSelect {
    const selectRef = ref<typeof UiSelect>();
    const searchItemFilter = ref<string>('');
    const isLoadingItems = ref<boolean>(false);
    const items = ref<DropDownItem[]>([]);

    let currentPage: number = 1;
    let canLoadItems: boolean = true;

    const inputValue = computed({
        get: () => {
            return props.modelValue;
        },
        set: (value) => () => {
            context.emit('update:model-value', value);
            onChange();
        },
    });

    // Список выбранных id сущностей
    const idsSelect = computed<number[] | null>(() => {
        return props.modelValue && Array.isArray(props.modelValue) && props.modelValue.length > 0 ? props.modelValue.map((item: DropDownItem) => item.value) : null;
    });

    // Находится ли компонент в состоянии ошибки
    const hasError = computed<boolean>(() => {
        return selectRef.value?.hasError ?? false;
    });

    // Триггер валидации
    function validate(): void {
        selectRef.value?.validate();
    }

    function onChange(event?: any | null): void {
        if (selectRef.value?.updateInputValue) {
            (selectRef.value as unknown as QSelect).updateInputValue('', true);
        }

        nextTick(function () {
            context.emit('on-change', event ?? inputValue.value);
        });
    }

    // Этот метод для загрузки данных может быть переопределен в дочернем компоненте
    async function loadData(): Promise<DropDownItem[]> {
        if (props.loadAction) {
            isLoadingItems.value = true;
            const result: ResultOf<PagedResultOfDropDownItem> = await props.loadAction(searchItemFilter.value, currentPage);

            if (result.entity.pagedMetaData) {
                checkPagination(result.entity.pagedMetaData.hasNextPage);
            }

            return result.entity.pageItems || [];
        } else {
            return await new Promise<DropDownItem[]>((resolve: (items: DropDownItem[]) => void) => {
                resolve([]);
            });
        }
    }

    function checkPagination(hasNextPage: boolean): void {
        canLoadItems = hasNextPage;

        if (canLoadItems) {
            currentPage++;
        }
    }

    // Обработчик виртуального скрола
    async function onScrollItems(params: { to: number, ref: QVirtualScroll }): Promise<void> {
        const lastIndex = selectRef.value?.allOptions.length - 1;

        if (!isLoadingItems.value && canLoadItems && params.to === lastIndex) {
            const loadedItems = await loadData();

            loadedItems.forEach((el: DropDownItem) => {
                items.value.push(el);
            });

            nextTick(() => {
                params.ref.refresh();
                isLoadingItems.value = false;
            });
        }
    }

    function resetFilter(): void {
        if (!Array.isArray(inputValue.value)) {
            inputValue.value = null;
            context.emit('update:model-value', null);
        }

        onChange();
    }

    function filterItems(val: string, update: (val: () => void) => void): void {
        currentPage = 1;
        searchItemFilter.value = val;

        loadData().then((values: DropDownItem[]) => {
            update(() => {
                items.value = values;
                // после вызова update почему-то два раза отрабатывает скролл
                // и вместо одного запроса идут 3
                // поэтому обновляем флаг с небольшой задержкой
                setTimeout(() => {
                    isLoadingItems.value = false;
                }, 100);
            });
        });
    }

    // Получение данных с сервера для отображения в селекте с множнственным выбором, если это необходимо
    async function loadInitialDataForMultipleSelect(): Promise<DropDownItem[]> {
        if (idsSelect.value?.length && props.loadAction) {
            //Проверка. Если пришла не целая модель, а только id, то мы шлем запрос по получению их данных
            const itemsNotFull: DropDownItem[] = props.modelValue.filter((el: DropDownItem) => !el.text);

            if (itemsNotFull.length > 0) {
                const result: ResultOf<PagedResultOfDropDownItem> = await props.loadAction(searchItemFilter.value, currentPage, idsSelect.value);

                if (result.isSuccess) {
                    return result.entity.pageItems;
                } else {
                    return [];
                }
            } else {
                return props.modelValue;
            }
        } else {
            return [];
        }
    }

    // Получение данных с сервера для отображения в селекте c выбором одного значение
    async function loadInitialDataForSelect(): Promise<DropDownItem[]> {
        if (props.modelValue?.value && props.loadAction) {
            const result: ResultOf<PagedResultOfDropDownItem> = await props.loadAction(searchItemFilter.value, currentPage, [props.modelValue.value]);

            if (result.isSuccess) {
                return result.entity.pageItems.length ? result.entity.pageItems[0] : props.modelValue;
            } else {
                return props.modelValue;
            }
        } else {
            return props.modelValue;
        }
    }

    // Инициализируем начальное значение в зависимости от того что это - массив или объект
    async function init(): Promise<void> {
        if (props.isNeedInit && props.modelValue) {
            if (props.multiple) {
                const dataItems = await loadInitialDataForMultipleSelect();

                nextTick(() => {
                    inputValue.value = dataItems;
                    context.emit('update:model-value', dataItems);
                });
            } else {
                if (props.modelValue.value && props.modelValue.text) {
                    inputValue.value = {
                        value: props.modelValue.value,
                        text: props.modelValue.text,
                    };
                } else {
                    const item = await loadInitialDataForSelect();

                    nextTick(() => {
                        inputValue.value = item;
                        context.emit('update:model-value', item);
                    });
                }
            }
        }
    }

    onBeforeMount(init);

    return {
        selectRef,
        inputValue,
        searchItemFilter,
        currentPage,
        canLoadItems,
        isLoadingItems,
        items,
        hasError,
        idsSelect,
        validate,
        checkPagination,
        onChange,
        resetFilter,
        filterItems,
        onScrollItems,
        localize,
    };
}
