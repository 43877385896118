<template>
    <q-menu context-menu auto-close ref="menuRef">
        <q-list>
            <q-item clickable @click="$emit('open-in-new-tab')">
                <q-item-section class="row nowrap items-center">
                    <Icon name="open-new-icon" size="16px" class="open-new-icon q-mr-sm"/>
                    {{ localize('Открыть в новой вкладке') }}
                </q-item-section>
            </q-item>
            <q-item clickable @click="$emit('open-in-new-window')">
                <q-item-section class="row nowrap items-center">
                    <Icon name="open-new-icon" size="16px" class="open-new-icon q-mr-sm"/>
                    {{ localize('Открыть в новом окне') }}
                </q-item-section>
            </q-item>
            <q-item clickable @click="$emit('unpin')">
                <q-item-section class="row nowrap items-center">
                    <unpin-icon class="q-mr-sm"/>
                    {{ localize('Открепить') }}
                </q-item-section>
            </q-item>
        </q-list>
    </q-menu>
</template>

<script lang="ts">
    import { defineComponent, ref } from 'vue';
    import { QMenu } from 'quasar';
    import { localize } from 'src/services/LocalizationService/LocalizationService';
    /**
     * Контекстное меню открепления сущностей
     */
    export default defineComponent({
        name: 'UnpinContextMenu',
        emits: [
            'open-in-new-tab',
            'open-in-new-window',
            'unpin'
        ],
        setup() {
            /**
             * Ссылк на корневой элемент
             */
            const menuRef = ref<QMenu>();

            /**
             * Скрыть меню
             */
            function hide(): void {
                menuRef.value?.hide();
            }

            return {
                menuRef,
                localize,
                hide,
            };
        }
    });
</script>

.<style lang="scss" scoped>
    .open-new-icon {
        ::v-deep(path) {
            stroke: $shade-8;
        }
    }

    .row {
        justify-content: start;
        flex-direction: row;
    }
</style>
