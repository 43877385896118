
export interface AuthorizationStoreInterface {
    electronAppToken: string;
    jwtToken: string;
    jwtTokenDateOfCreation: number | undefined;
}

// классы для описания стора для данных авторизации

function state(): AuthorizationStoreInterface {
    return {
        electronAppToken: '',
        jwtToken: '',
        jwtTokenDateOfCreation: new Date().getTime(),
    };
}

export default state;
