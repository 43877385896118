﻿<template>
    <div class="pinned-discipline-page-block-component" ref="elementRef">
        <template v-if="isVisible">
            <slot></slot>
        </template>
    </div>
</template>

<script lang="ts">
    import { defineComponent, Ref, ref, onMounted, onUnmounted, nextTick, getCurrentInstance } from 'vue';

    // Компонет для вывода закрепленных дисциплин в меню
    // показывает только те элементы, которые находятся в области видимости
    export default defineComponent({
        name: 'PinnedDisciplinePageBlock',
        /* eslint-disable-next-line max-lines-per-function  */
        setup() {
            const elementRef = ref<HTMLDivElement>();
            const app = getCurrentInstance();

            // Находится ли строка в зоне видимости или нет
            const isVisible: Ref<boolean> = ref(false);

            let intersectionObserver: IntersectionObserver | null = null;

            // Отслеживает видимость элемента меню через intersectionObserver
            function checkVisibility(): void {
                // this.$parent.$el - это q-scroll-area в родительском LeftMenu.vue
                const options = {
                    root: app?.parent?.vnode.el as HTMLElement,
                    rootMargin: '0px',
                    threshold: 0.01
                };

                const callback: IntersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
                    isVisible.value = !!entries.length && entries[0].isIntersecting;
                };
                intersectionObserver = new IntersectionObserver(callback, options);

                if (elementRef.value) {
                    intersectionObserver.observe(elementRef.value);
                }
            }

            onMounted(() => {
                nextTick(() => {
                    checkVisibility();
                });
            });

            onUnmounted(() => {
                intersectionObserver?.disconnect();
            });

            return {
                elementRef,
                isVisible
            };
        }
    });
</script>

<style scoped>
    .pinned-discipline-page-block-component {
        min-height: 36px;
    }
</style>
