import {
    ChatMessageDto,
    ChatPinnedMessageDto,
    ChatUnreadedMessagesCountDto,
    ListChatDto
} from 'src/api/ApiClient';
import { IDisciplineChat } from 'pages/Main/Chat/types/interfaces';
import { IChatMessage, IDraftInfo } from 'components/Chat/types/interfaces';

// классы для описания стора для компонента чата

export interface ChatStoreInterface {
    isSearchInChatMode: boolean;
    isShowChatsPanel: boolean;
    pinnedMessages: ChatPinnedMessageDto[];
    unreadedMessagesCount: ChatUnreadedMessagesCountDto[];
    currentChatId: number;
    // Сообщение, которое редактируем
    editingMessage: ChatMessageDto | null;
    // Сообщение, на которое отвечаем
    replyingMessage: ChatMessageDto | null;
    // Главное сообщение треда
    mainMessageInThread: IChatMessage | null;
    // поле используется при мобильном поиске
    searchedMessageIds: number[] | null;
    //region Поля используются на странице со всеми чатами
    // в компонентах левой панели
    disciplineChats: IDisciplineChat[];
    groupChats: ListChatDto[];
    discussionChats: ListChatDto[];
    privateChats: ListChatDto[];
    archiveDisciplineChats: IDisciplineChat[];
    archiveGroupChats: ListChatDto[];
    archiveDiscussionChats: ListChatDto[];
    archivePrivateChats: ListChatDto[];
    //endregion
    //region используется в чате решения
    selectedSolutionChat: ListChatDto | null;
    selectedSolution: ListChatDto | null;
    selectedRoomId: number;
    //endregion
    // Высота блока нового сообщения, нужна для того
    // чтобы подвинуть кнопку скрола к новым сообщениям
    // потому что при некоторых размерах она налезает
    // на кнопку добавления файлов
    newMessageBlockHeight: number;
    isPrivateChatsMuted: boolean;
    isGroupChatsMuted: boolean;
    isDisciplineChatsMuted: boolean;
    drafts: IDraftInfo | null;
    disciplineChatsIdsForAlphabetSorting: number[];
    isSortByAlphabetInDiscussionChats: boolean;
    isSortByAlphabetInGroupChats: boolean;
    isSortByAlphabetInDisciplineChats: boolean;
    isSortByAlphabetInPrivateChats: boolean;
}

function state(): ChatStoreInterface {
    // проставляем начальные значения для стора
    return {
        pinnedMessages: [],
        unreadedMessagesCount: [],
        isSearchInChatMode: false,
        isShowChatsPanel: false,
        currentChatId: 0,
        editingMessage: null,
        replyingMessage: null,
        mainMessageInThread: null,
        selectedSolutionChat: null,
        selectedSolution: null,
        disciplineChats: [],
        groupChats: [],
        discussionChats: [],
        privateChats: [],
        archiveDisciplineChats: [],
        archiveGroupChats: [],
        archiveDiscussionChats: [],
        archivePrivateChats: [],
        searchedMessageIds: null,
        selectedRoomId: 0,
        newMessageBlockHeight: 0,
        isDisciplineChatsMuted: false,
        isGroupChatsMuted: false,
        isPrivateChatsMuted: false,
        disciplineChatsIdsForAlphabetSorting: [],
        isSortByAlphabetInDiscussionChats: false,
        isSortByAlphabetInGroupChats: false,
        isSortByAlphabetInDisciplineChats: false,
        isSortByAlphabetInPrivateChats: false,
        drafts: null,
    };
}

export default state;
