<template>
    <div>
        <div class="text-center" v-if="isLoading">
            <q-spinner-dots color="primary" size="4em"/>
        </div>
        <search-people-item
            v-else
            v-for="user in users"
            :key="user.baseInfo.id"
            :item="user"
            @on-item-selected="onItemSelected">
            <template #append >
                <router-link v-if="user.canLoginAs" :to="getLoginAsLink(user.baseInfo.id)">
                    <SubdivisionIcon @click="onItemSelected"/>
                </router-link>
            </template>
        </search-people-item>
        <show-all-btn v-if="canShowAll" @show-all="showAll" />
    </div>
</template>

<script lang="ts" setup>
    import { Ref, ref, toRefs } from 'vue';
    import { SearchUserDto, RoutePageNameEnum } from 'src/api/ApiClient';
    import SearchPeopleItem from 'layouts/Main/components/searchBlock/components/peopleTabs/SearchPeopleItem.vue';
    import { SearchTabs } from 'layouts/Main/components/searchBlock/types/searchTabs';
    import { Common } from 'src/helpers/Common';
    import { useRouter } from 'vue-router';
    import { useSearch } from '../../hooks/useSearch';
    import { SearchEntity } from '../../types/SearchEntity';
    import ShowAllBtn from '../ShowAllBtn.vue';

    // Компонент с отображением первых 5 найденных пользователей
    // Отображается когда выбран поиск по всем сущностям
    const props = withDefaults(defineProps<{
        searchText: string;
    }>(), {
        searchText: '',
    });
    const emit = defineEmits<{
        (e: 'on-item-selected'): void;
        (e: 'on-found-items-count', itemsCount: number): void;
        (e: 'show-all', tab: SearchTabs): void;
    }>();

    const $router = useRouter();

    const users: Ref<SearchUserDto[]> = ref([]);
    const { searchText } = toRefs(props);

    const {
        canShowAll,
        onItemSelected,
        showAll,
        isLoading,
    } = useSearch(SearchEntity.People, false, users, searchText, emit);


    // Перенаправляем на страницу на которой будет осуществлен
    // вход в приложение под выбранным пользователем
    function getLoginAsLink(userId: number): string {
        const link = $router.resolve({
            name: Common.getRouteName(RoutePageNameEnum.LoginAs),
            params: { id: userId.toString() }
        });
        return link.path;
    }
</script>
