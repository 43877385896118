<template>
    <q-dialog v-model="isView" persistent>
        <q-card class="confirm-modal q-pa-lg" style="width: 600px">
            <q-card-section class="row items-center q-pb-none no-border">
                <div class="confirm-modal__title q-mb-md">
                    {{ entityType === 'room' ? localize('Новая комната') : localize('Новый чат') }}
                </div>
                <q-space />
                <q-btn
                    class="confirm-modal__title q-mb-md"
                    icon="close"
                    flat
                    round
                    dense
                    size="16px"
                    v-close-popup
                    @click="closeModal"
                />
            </q-card-section>
            <div>
                <q-tabs
                    v-if="entityType === 'chat'"
                    v-model="tab"
                    active-color="primary"
                    indicator-color="primary"
                >
                    <q-tab name="groupChat" :label="localize('Групповой')" />
                    <q-tab name="privateChat" :label="localize('Личный')" />
                </q-tabs>
                <q-tab-panels v-model="tab">
                    <q-tab-panel
                        name="groupChat"
                        class="q-pa-none"
                        :class="{
                            'q-mt-md': entityType !== 'room',
                            'q-mt-xs': entityType === 'room'
                        }"
                    >
                        <q-input
                            :rules="[ val => val.length > 0 || enterNameChatText, val => val.length <= 190 || invalidLengthNameChatText ]"
                            v-model="nameChat"
                            @update:model-value="onValidModel"
                            :label="nameChatText"
                            class="high-field q-pb-lg"
                        />

                        <ui-select
                            v-if="entityType === 'room'"
                            dense
                            options-dense
                            v-model="selectedRoomAccessType"
                            :options="accessOptions"
                            :rules="[ isRequired ]"
                            map-options
                            emit-value
                            @update:model-value="onValidModel"
                            popup-content-class="select"
                            :label="localize('Доступ к комнате') + '*'"
                            class="high-field q-pb-lg"
                        />

                        <DataSelect
                            v-if="entityType !== 'room'"
                            v-model="selectedCohorts"
                            :label="localize('Поток')"
                            :load-action="loadCohortsAction"
                            @on-change="onValidModel"
                            multiple
                            class="high-field q-pb-lg"
                        />

                        <DataSelect
                            v-if="entityType === 'chat' || selectedRoomAccessType === RoomAccessType.ConcreteStudents"
                            v-model="selectedGroups"
                            :label="localize('Группы')"
                            :load-action="loadGroupsAction"
                            @on-change="onValidModel"
                            multiple
                            class="high-field q-pb-lg"
                        />

                        <UserMultiselect
                            v-if="entityType === 'chat' || selectedRoomAccessType === RoomAccessType.ConcreteStudents"
                            v-model="selectedUsers"
                            :label="localize('Пользователи')"
                            @on-change="onValidModel"
                            class="high-field q-pb-lg"
                            :discipline-id="entityType === 'room' ? disciplineId : undefined"
                            :search-request-type="entityType === 'room' ? UsersForSelectRequestType.StudentsByDiscipline : undefined"
                        />

                        <UserMultiselect
                            v-if="entityType !== 'room'"
                            v-model="selectedAdmins"
                            :label="localize('Администратор чата') + '*'"
                            @on-change="onValidModel"
                            class="high-field q-pb-md"
                        />
                    </q-tab-panel>
                    <q-tab-panel name="privateChat" class="q-mt-md">
                        <UserSelect
                            v-model="selectedUser"
                            :label="localize('Пользователь')"
                            @on-change="onValidModel"
                            class="high-field"
                        />
                    </q-tab-panel>
                </q-tab-panels>
            </div>
            <div v-if="entityType === 'chat' && tab === 'groupChat'" class="field-description q-mb-lg q-pr-lg q-mr-xl">
                {{ localize('Звонки в групповых чатах доступны, если количество участников чата не превышает 500 человек') }}
            </div>
            <div class="row items-center justify-end">
                <q-btn
                    :label="localize('Отмена')"
                    color="primary"
                    flat
                    @click="closeModal"
                />
                <q-btn
                    :disable="!isValid"
                    color="primary"
                    class="q-manual-focusable--focused"
                    @click="saveChat"
                >
                    {{ localize('Создать') }}
                </q-btn>
            </div>
        </q-card>
    </q-dialog>
</template>

<script lang="ts">
    import { Notify } from 'quasar';
    import { localize } from 'src/services/LocalizationService';
    import UserMultiselect from 'components/ui/selects/UserMultiselect/UserMultiselect.vue';
    import UserSelect from 'components/ui/selects/UserSelect/UserSelect.vue';
    import {
        AddEditChatRoomRequestModel,
        ChatBaseInfoResponseModel,
        ChatClient,
        ChatEditRequestModel,
        ChatType,
        CohortClient,
        DropDownItem,
        GroupClient,
        ListChatDto,
        PagedResultOfDropDownItem,
        ResultOf,
        RoutePageNameEnum,
        UserSelectModel,
        UsersForSelectRequestType
    } from 'src/api/ApiClient';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { Common } from 'src/helpers/Common';
    import DataSelect from 'components/ui/selects/DataSelect/DataSelect.vue';
    import { computed, ComputedRef, defineComponent, onMounted, PropType, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import useValidationRules from 'src/helpers/custom-hooks/useValidationRules';
    import { RoomAccessType } from 'pages/Main/Chat/types/interfaces';
    import { useAccountStore } from 'src/store/module-account';
    import { useChatStore } from 'src/store/module-chat';

    export default defineComponent({
        name: 'AddChat',

        components: {
            DataSelect,
            UserMultiselect,
            UserSelect,
        },

        emits: [
            'on-set-is-show-add-chat-modal',
            'on-create-chat',
        ],

        props: {
            // Что создаем, обычный чат иил чат для комнаты дисциплины
            entityType: {
                type: String as PropType<'chat' | 'room'>,
                default: 'chat',
            },
            disciplineId: {
                type: Number as PropType<number | undefined | null>,
                default: undefined,
            },
            needRedirectToChatPage: {
                type: Boolean,
                default: true,
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            const $router = useRouter();
            const chatStore = useChatStore();
            const accountStore = useAccountStore();

            const tab = ref<string>('groupChat');
            const nameChat = ref<string>('');
            const { isRequired } = useValidationRules();

            //region Массивы выбранных элементов для селектов
            const selectedCohorts = ref<DropDownItem[] | undefined>([]);
            const selectedGroups = ref<DropDownItem[] | undefined>([]);
            const selectedUsers = ref<UserSelectModel[] | undefined>([]);
            const selectedAdmins = ref<UserSelectModel[] | undefined>([]);
            const selectedUser = ref<UserSelectModel | null>(null);
            const selectedRoomAccessType = ref<number | null>(null);
            //endregion

            const accessOptions: DropDownItem[] = [{
                text: localize('Всем участникам дисциплины'),
                value: RoomAccessType.AllContingent
            }, {
                text: localize('Отдельным студентам'),
                value: RoomAccessType.ConcreteStudents
            }];

            // флаг показа модального окна
            const isView = ref<boolean>(true);
            const isValid = ref<boolean>(false);

            const nameChatText = computed<string>(() => {
                return props.entityType === 'room' ? localize('Название комнаты*') : localize('Название чата*');
            });

            const enterNameChatText = computed<string>(() => {
                return props.entityType === 'room' ? localize('Введите название комнаты') : localize('Введите название чата');
            });

            // Ограничиваем название чата длинной в 190 символов
            const invalidLengthNameChatText = computed<string>(() => {
                return localize('Название чата должно быть меньше {count} символов', { count: 190 });
            });

            //region Экшены для получения данных для селектов
            const loadCohortsAction: ComputedRef<(search: string | null, page: number, ids?: number[] | null | undefined) => Promise<ResultOf<PagedResultOfDropDownItem>>> = computed(() => {
                const api = new CohortClient(getApiClientInitialParams());
                return api.getCohorts.bind(api);
            });

            async function loadGroupsAction(search: string | null, page: number, ids: number[] | null = null): Promise<ResultOf<PagedResultOfDropDownItem>> {
                const api = new GroupClient(getApiClientInitialParams());
                return api.getGroups(search, page, ids, null, props.disciplineId);
            }

            //endregion

            function closeModal(): void {
                // вызываем событие чтобы родительский компонент его обработал
                context.emit('on-set-is-show-add-chat-modal', false);
            }

            function getNewChatType(): ChatType {
                if (props.entityType === 'room') {
                    return ChatType.Discipline;
                } else {
                    return tab.value === 'groupChat'
                        ? ChatType.Group
                        : selectedUser.value?.id === accountStore.getAccountInfo?.id
                            ? ChatType.Self
                            : ChatType.Private;
                }
            }

            async function saveChat(): Promise<void> {
                //region Валидация данных
                onValidModel();

                if (!isValid.value) {
                    Notify.create({
                        type: 'negative',
                        message: tab.value === 'groupChat' ?
                            localize('Название чата является обязательным') :
                            localize('Выберите пользователя, с которым нужно создать чат'),
                    });

                    return;
                }
                //endregion

                // После нажатия на кнопку сохранить, нужно задизейблить кнопку, для предотвращения создания нескольких чатов
                isValid.value = false;

                const model: ChatEditRequestModel = {
                    cohorts: selectedCohorts.value,
                    groups: selectedGroups.value,
                    id: 0,
                    name: nameChat.value.trim(),
                    serverId: undefined,
                    servers: undefined,
                    users: undefined,
                    userIds: tab.value === 'groupChat' ?
                        convertUsersSelectModelToUsersList(selectedUsers.value)
                        : convertUserSelectModelToUsersList(selectedUser.value!),
                    type: getNewChatType(),
                    adminIds: convertUsersSelectModelToUsersList(selectedAdmins.value),
                };

                let result;

                // Посылаем запрос в зависимости от того чта комнаты это или просто чат
                if (props.entityType === 'room') {
                    result = await new ChatClient(getApiClientInitialParams()).editChatRoomForDiscipline(convertEditChatModelToRoomChatModel(model));
                } else {
                    result = await new ChatClient(getApiClientInitialParams()).editPOST(model);
                }

                if (!result.isSuccess) {
                    NotifyErrors(result);
                } else {
                    await setChatInfo(result.entity, model.type);
                }
            }

            // Получить информацию о созданном чате
            // и перейти к чату
            async function setChatInfo(chatId: number, type: ChatType): Promise<void> {
                const chatInfo = await new ChatClient(getApiClientInitialParams()).getChatBaseInfo(chatId);

                const chat: ListChatDto = convertBaseInfoModelToListChatDto(chatInfo.entity, type);

                // Добавляем информацию о чате в стор, если это не комната
                // комнаты хранятся как дочерние для чата дисциплины
                if (type !== ChatType.Discipline &&
                    type !== ChatType.Self) {
                    chatStore.addChats([chat]);
                }

                isView.value = false;

                // вызываем событие чтобы родительский компонент его обработал
                context.emit('on-set-is-show-add-chat-modal', false);
                context.emit('on-create-chat', chat);

                if (props.needRedirectToChatPage) {
                    $router.push({
                        name: Common.getRouteName(RoutePageNameEnum.Chat),
                        params: { id: chatInfo.entity.id.toString() },
                    });
                }
            }

            function onValidModel(): void {
                if (tab.value !== 'groupChat') {
                    isValid.value = !!selectedUser.value;
                } else {
                    isValid.value = !!nameChat.value.trim() && !!selectedAdmins.value?.length;

                    if (nameChat.value && nameChat.value.length > 190) {
                        isValid.value = false;
                    }

                    if (isValid.value && props.entityType === 'room') {
                        isValid.value = selectedRoomAccessType.value !== null && selectedRoomAccessType.value !== undefined;
                    }
                }
            }

            //region Конвертация моделей
            function convertBaseInfoModelToListChatDto(chatInfo: ChatBaseInfoResponseModel, chatType: ChatType): ListChatDto {
                return {
                    chatId: chatInfo.id,
                    name: chatInfo.nameChat,
                    type: chatType,
                    countMembers: chatInfo.countMembers,
                    logoPath: chatInfo.chatAvatarPath,
                    isDisabled: chatInfo.isDisabled,
                    parentChatType: 0,
                    isMutedDiscipline: false,
                    isMuted: chatInfo.isMuted,
                };
            }

            function convertEditChatModelToRoomChatModel(chat: ChatEditRequestModel): AddEditChatRoomRequestModel {
                const isClosed = selectedRoomAccessType.value === RoomAccessType.ConcreteStudents;

                return {
                    chatRoomId: 0,
                    nameChat: chat.name!,
                    studentIds: isClosed ? (chat.users?.map((x: UserSelectModel) => x.id) || chat.userIds || []) : [],
                    groupIds: isClosed ? (chat.groups?.map((x: DropDownItem) => x.value) || []) : [],
                    cohortIds: [],
                    isClosed,
                    disciplineId: props.disciplineId ?? 0,
                    isContingentFromDiscipline: selectedRoomAccessType.value === RoomAccessType.AllContingent,
                };
            }

            function convertUsersSelectModelToUsersList(model: UserSelectModel[] | undefined): number[] {
                if (!model) {
                    return [];
                }
                const result: number[] = [];

                model.forEach((x: UserSelectModel) => {
                    const add: number = x.id;
                    result.push(add);
                });
                return result;
            }

            function convertUserSelectModelToUsersList(model: UserSelectModel | undefined): number[] | undefined {
                if (!model) {
                    return undefined;
                }
                const result: number = model.id;

                return [result];
            }

            //endregion

            onMounted(() => {
                // Добавляем текущего пользователя в администраторы чата
                const current = Common.getCurrentUserAsSelectModel();
                selectedAdmins.value?.push(current);
            });

            return {
                isRequired,
                RoomAccessType,
                accessOptions,
                selectedRoomAccessType,
                tab,
                nameChat,
                selectedCohorts,
                selectedGroups,
                selectedUsers,
                selectedAdmins,
                selectedUser,
                isView,
                isValid,
                nameChatText,
                enterNameChatText,
                invalidLengthNameChatText,
                loadGroupsAction,
                loadCohortsAction,
                localize,
                closeModal,
                saveChat,
                onValidModel,
                UsersForSelectRequestType
            };
        },

    });
</script>
