import { useRouter } from 'vue-router';

/**
 * Функционал с получением url страницы сущности
 */
export function useGetEntityPageUrl(routeName: string): {
    getEntityPageUrl: (id: number | string) => string;
} {
    const $router = useRouter();

    // URL для перехода на страницу подробной информации о материале
    function getEntityPageUrl(id: number | string): string {
        const path = $router.resolve({
            name: routeName,
            params: { id: id.toString() },
        });
        return path.path;
    }
    return {
        getEntityPageUrl
    };
}
