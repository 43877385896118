import { ref, Ref } from 'vue';

// Проверка на корректность заполненных данных
// Можно использовать в разных компонентах, все
// что необходимо, передать список refs данного
// компонента, если элемент формы имеет ref и
// правило для валидации, оно будет проверено
export default function useValidateAllFields(refs: any): { isInvalid: boolean } {
    const isInvalid: Ref<boolean> = ref(false);
    // Учитываем массивы refs полученные с v-for
    const flattenRefs = Object.values(refs).flat();

    const validateFields = flattenRefs
        .filter((item: any) => item && item.validate);

    validateFields.forEach((item: any) => item.validate());

    isInvalid.value = validateFields.some((item: any) => item.hasError);

    return { isInvalid: isInvalid.value };
}
