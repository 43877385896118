﻿import { Result, ErrorDto, RoutePageNameEnum } from './ApiClient';
import { Notify } from 'quasar';
import { routerBas } from 'components/EventBuses';
import { Common } from 'src/helpers/Common';
import { NotifyUtil } from 'src/shims-vue';
import { useMainLayoutStore } from 'src/store/module-main-layout';
import { RouterBusEvents } from 'components/EventBuses/emuns';

/**
 * Оповещение пользователя о том, что запрос к API прошел не удачно
 * Составляет сообщение из списка ошибок из Result.errors. Дополнительно можно добавить свое сообщение
 * @param result Неудачный результат запроса к API
 * @param additionalStartingMessage Неудачный результат запроса к API
 * @param redirectToErrorPage нужно ли перенаправлять на страницу ошибки
 * */
export function NotifyErrors(result: Result, additionalStartingMessage?: string, redirectToErrorPage?: boolean, classes?: string, html: boolean = false): void {
    if (result.errors.length > 0) {
        let message = result.errors.map((re: ErrorDto) => (re.key ? `${re.key}: ${re.value}` : re.value)).join('\n');
        if (additionalStartingMessage) {
            message = additionalStartingMessage + '\n' + message;
        }
        notifyError(message, classes, html);
    }

    if (redirectToErrorPage) {
        // редирект на страницу 404 Not found
        if (result.httpStatusCode === 404) {
            routerBas.emit(RouterBusEvents.Push, { name: Common.getRouteName(RoutePageNameEnum.Error404) });
        }

        // редирект на страницу 403 Forbidden
        if (result.httpStatusCode === 403) {
            routerBas.emit(RouterBusEvents.Push, { name: Common.getRouteName(RoutePageNameEnum.Error403) });
        }
    }

    // Показываем окно "Что-то пошло не так" ддя всех 500-тых ошибок
    if (result.httpStatusCode.toString()[0] === '5') {
        useMainLayoutStore().isVisibleSmthWentWrongBlock = true;
    }

    // редирект на страницу авторизации
    if (result.httpStatusCode === 401) {
        routerBas.emit(RouterBusEvents.ToLogin);
    }
}

export function notifyError(message: string, classes?: string, html: boolean = false, timeout: number = 8000): void {
    const functionNotify = Notify.create({
        message,
        group: false,
        timeout,
        position: 'bottom',
        color: 'red',
        html,
        classes
    });

    const notifyUtil = Notify as NotifyUtil;

    if (!notifyUtil.$closeNotify) {
        notifyUtil.$closeNotify = [];
    }

    notifyUtil.$closeNotify.push(functionNotify);

    const bottomPanelEl = document.querySelector('.page-form .bottom-button-panel');

    // на страницах с формами есть нижняя панель с кнопками
    // кнопки чата/звонков должны быть выше неё
    if (bottomPanelEl) {
        setTimeout(() => {
            const notificationEl = document.querySelector('.q-notification');

            if (notificationEl) {
                (notificationEl as HTMLDivElement).style.marginBottom = bottomPanelEl.clientHeight + 'px';
            }
        }, 100);
    }
}

export function notifySuccess(message: string, classes?: string): void {
    Notify.create({
        message,
        group: false,
        timeout: 4000,
        position: 'bottom',
        color: 'success',
        classes,
    });
}
