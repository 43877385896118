import {
    ChatClient,
    ChatMessageClient,
    ChatMessageDto, ChatPinnedMessageDto, ChatSection,
    ChatType,
    ChatUnreadedMessagesCountDto,
    ListChatDto,
} from 'src/api/ApiClient';
import { getApiClientInitialParams } from 'src/api/BaseApiClient';
import { ActionContext } from 'src/store/type';
import { ChatStoreInterface } from 'src/store/module-chat/state';
import { IChatRoom, IDisciplineChat } from 'pages/Main/Chat/types/interfaces';
import { UpdateCountUnreadedMessagesDto } from 'src/services/hubs/types/interfaces';
import { IChatMessage } from 'components/Chat/types/interfaces';
import { chatBus } from 'components/EventBuses';
import { ChatBusEvents } from 'components/EventBuses/emuns';

// Получить список чатов по типу
const getChatsByType = (state: ChatStoreInterface, type: ChatType): ListChatDto[] => {
    switch (type) {
        case ChatType.Discipline:
        case ChatType.Solution:
            return state.disciplineChats;
        case ChatType.Group:
            return state.groupChats;
        case ChatType.Discussion:
            return state.discussionChats;
        default:
            return state.privateChats;
    }
};

const unArchiveDiscussionChats = (state: ChatStoreInterface, chatId?: number | undefined | null): void => {
    // Получаем чаты-обсуждения, которые есть в этом чате
    const discussionChats = state.archiveDiscussionChats.filter((x: ListChatDto) => {
        return x.parentChatId === chatId;
    });

    // И их мы тоже разархивируем
    discussionChats.forEach((x: ListChatDto) => {
        const index = state.discussionChats.findIndex((i: ListChatDto) => i.chatId === x.chatId);

        if (index === -1) {
            state.discussionChats.push(x);
        }
    });

    // Обновляем массив без учета разархивированных
    state.archiveDiscussionChats = state.archiveDiscussionChats.filter((x: ListChatDto) => {
        return x.parentChatId !== chatId;
    });
};

// Получить список чатов по ChatSection
const getChatsBySection = (state: ChatStoreInterface, section: ChatSection): ListChatDto[] => {
    switch (section) {
        case ChatSection.Discipline:
            return state.disciplineChats;
        case ChatSection.Group:
            return state.groupChats;
        case ChatSection.Discussion:
            return state.discussionChats;
        default:
            return state.privateChats;
    }
};

const archiveDiscussionChats = (state: ChatStoreInterface, chat: ListChatDto): void => {
    // Получаем чаты-обсуждения, которые есть в этом чате
    const discussionChats = state.discussionChats.filter((x: ListChatDto) => {
        return x.parentChatId === chat.chatId;
    });

    // И их мы тоже архивируем
    discussionChats.forEach((x: ListChatDto) => {
        const index = state.archiveDiscussionChats.findIndex((i: ListChatDto) => i.chatId === x.chatId);

        if (index === -1) {
            state.archiveDiscussionChats.push(x);
        }
    });

    // Обновляем массив без учета архивных
    state.discussionChats = state.discussionChats.filter((x: ListChatDto) => {
        return x.parentChatId !== chat.chatId;
    });
};

// Получить список архивных чатов по типу
const getArchiveChatsByType = (state: ChatStoreInterface, type: ChatType): ListChatDto[] => {
    switch (type) {
        case ChatType.Discipline:
        case ChatType.Solution:
            return state.archiveDisciplineChats;
        case ChatType.Group:
            return state.archiveGroupChats;
        case ChatType.Discussion:
            return state.archiveDiscussionChats;
        default:
            return state.archivePrivateChats;
    }
};

const actions = {
    // Получить закрепленные сообщения данного чата
    async loadPinnedMessages(this: ActionContext<'chatStore', ChatStoreInterface>, chatId: number) {
        const result = await new ChatMessageClient(getApiClientInitialParams()).getPinnedMessages(chatId);

        if (result.isSuccess) {
            this.$patch({
                pinnedMessages: result.entity.messages || []
            });
        }
    },
    // Получение количества непрочитанных сообщений по всем чатам пользователя
    async loadChatsUnreadedCount(this: ActionContext<'chatStore', ChatStoreInterface>) {
        const result = await new ChatClient(getApiClientInitialParams()).getChatsUnreadedCount();

        if (result.isSuccess) {
            this.$patch({
                unreadedMessagesCount: result.entity.unreadedMessagesCount || []
            });

            chatBus.emit(ChatBusEvents.OnChangeUnreadedCountMessage);
        }
    },
    updateSelectedGroupChatAvatar(this: ChatStoreInterface, payload: { id: number, image: string }) {
        const groupChat = this.groupChats.find((c) => c.chatId === payload.id);

        if (groupChat) {
            groupChat.logoPath = payload.image;
        }
    },
    // Добавление чата в конец списка с проверкой на существование
    addChats(this: ChatStoreInterface, chats: ListChatDto[]) {
        if (chats.length) {
            const stateChats = getChatsByType(this, chats[0].type);

            chats.forEach((chat: ListChatDto) => {
                const index = stateChats.findIndex((x: ListChatDto) => x.chatId === chat.chatId);

                if (index === -1) {
                    stateChats.push(chat);
                }
            });
        }
    },
    // здесь в message поле count - это количество непрочитанных сообщений
    // поэтому мы их прибавляем
    incrementUnreadedCountMessage(this: ChatStoreInterface, messagesCount: ChatUnreadedMessagesCountDto) {
        const index = this.unreadedMessagesCount.findIndex((x: ChatUnreadedMessagesCountDto) => x.chatId === messagesCount.chatId);

        if (index === -1) {
            this.unreadedMessagesCount.push(messagesCount);
        } else {
            const editedMessage = { ...this.unreadedMessagesCount[index] };
            editedMessage.count += messagesCount.count;
            this.unreadedMessagesCount.splice(index, 1, editedMessage);
        }

        chatBus.emit(ChatBusEvents.OnChangeUnreadedCountMessage);
    },
    updateLastMessageDateTime(this: ChatStoreInterface, message: ChatMessageDto) {
        const chats = getChatsByType(this, message.chatType);
        if (message.chatType === ChatType.Discipline) {
            const room = this.disciplineChats.find((x: ListChatDto) => x.disciplineId === message.disciplineId);
            if (room) {
                const chat = room.rooms.find((x: IChatRoom) => x.chatId === message.chatId);
                if (chat) {
                    chat.lastMessageDateTime = message.createDateTime;
                }
            }
        } else if (message.chatType === ChatType.Solution) {
            const solutionChat = this.disciplineChats.find((x: ListChatDto) => x.disciplineId === message.disciplineId);
            if (solutionChat) {
                const chat = solutionChat.rooms.find((x: IChatRoom) => x.activityId === message.activityId);
                if (chat) {
                    chat.lastMessageDateTime = message.createDateTime;
                }
            }
        } else {
            const chat = chats.find((x: ListChatDto) => x.chatId === message.chatId);
            if (chat) {
                chat.lastMessageDateTime = message.createDateTime;
            }
        }
    },
    setCountUnreadedMessages(this: ChatStoreInterface, data: { chatId: number, count: number }) {
        // Уменьшаем или убираем количество не прочитанных у чата
        const unreadedIndex: number = this.unreadedMessagesCount.findIndex((el: ChatUnreadedMessagesCountDto) => el.chatId === data.chatId);

        if (unreadedIndex > -1) {
            this.unreadedMessagesCount[unreadedIndex].count = data.count;
            chatBus.emit(ChatBusEvents.OnChangeUnreadedCountMessage);
        }
    },
    // удалить чат-обсуждени
    removeDiscussionChat(this: ChatStoreInterface, chatId: number) {
        const index = this.discussionChats.findIndex((x: ListChatDto) => x.chatId === chatId);

        if (index !== -1) {
            this.discussionChats.splice(index, 1);
        }
    },
    addPinnedMessage(this: ChatStoreInterface, message: ChatPinnedMessageDto) {
        const index = this.pinnedMessages.findIndex((x: ChatPinnedMessageDto) => x.id === message.id);

        if (index === -1) {
            this.pinnedMessages.push(message);
        }
    },
    removePinnedMessage(this: ChatStoreInterface, id: number) {
        const index = this.pinnedMessages.findIndex((x: ChatPinnedMessageDto) => x.id === id);

        if (index !== -1) {
            this.pinnedMessages.splice(index, 1);
        }
    },
    // здесь в message поле count - это количество прочитанных сообщений
    // поэтому мы их вычитаем
    decrementUnreadedCountMessage(this: ChatStoreInterface, messagesCount: UpdateCountUnreadedMessagesDto) {
        const index = this.unreadedMessagesCount.findIndex((x: ChatUnreadedMessagesCountDto) => x.chatId === messagesCount.chatId);

        if (index !== -1) {
            const editedMessage = { ...this.unreadedMessagesCount[index] };
            editedMessage.count -= messagesCount.count;

            if (editedMessage.count < 0) {
                editedMessage.count = 0;
            }

            this.unreadedMessagesCount.splice(index, 1, editedMessage);
            chatBus.emit(ChatBusEvents.OnChangeUnreadedCountMessage);
        }
    },
    // У чата дисциплины в rooms могут быть чаты комнат
    // Активности с типом решение - они отображаются как дочерние
    addRoomToDisciplineChat(this: ChatStoreInterface, room: IChatRoom) {
        const chat: IDisciplineChat | undefined = this.disciplineChats.find((x: ListChatDto) => x.chatId === room.disciplineChatId);

        if (chat) {
            const index = (chat.rooms || []).findIndex((x: ListChatDto) => {
                if (x.chatId) {
                    return x.chatId === room.chatId;
                } else {
                    // у комнат, которые не комнаты, а решение задания нет chatId
                    return x.activityId === room.activityId;
                }
            });

            if (index === -1) {
                chat.rooms.push(room);
            }
        }
    },
    updateRoomInDisciplineChat(this: ChatStoreInterface, room: IChatRoom) {
        const chat: IDisciplineChat | undefined = this.disciplineChats.find((x: ListChatDto) => x.chatId === room.disciplineChatId);

        if (chat) {
            const editingRoom = chat.rooms.find((x: ListChatDto) => x.chatId === room.chatId);

            if (editingRoom) {
                editingRoom.name = room.name;
            }
        }
    },
    editChat(this: ChatStoreInterface, chatEdit: ListChatDto) {
        const chats = getChatsByType(this, chatEdit.type);
        const chat = chats.find((x: ListChatDto) => x.chatId === chatEdit.chatId);
        if (chat) {
            chat.name = chatEdit.name;
            chat.countMembers = chatEdit.countMembers;
        }
    },
    // передается чат, который надо удалить
    removeChat(this: ChatStoreInterface, chat: ListChatDto) {
        const chats = getChatsByType(this, chat.type);
        const index = chats.findIndex((x: ListChatDto) => x.chatId === chat.chatId);

        if (index !== -1) {
            chats.splice(index, 1);
        }
    },
    saveDraftMessage(this: ChatStoreInterface, { key, message }: { key?: number | null, message: IChatMessage }) {
        if (!key) {
            return;
        }

        const drafts = this.drafts || {};

        drafts[key] = {
            message,
            time: Date.now(),
        };

        this.drafts = drafts;
    },
    deleteDraftMessage(this: ChatStoreInterface, key: number) {
        if (this.drafts) {
            delete this.drafts[key];
        }
    },
    // установить состояние мьюта у чата
    setMutedToChat(this: ChatStoreInterface, payload: { id: number, isMuted: boolean, type: ChatType, disciplineId?: number | null }) {
        switch (payload.type) {
            case ChatType.Solution:
            case ChatType.Discipline: {
                const disciplineDefaultChat = this.disciplineChats.find((x: ListChatDto) => x.disciplineId === payload.disciplineId);
                if (disciplineDefaultChat) {
                    if (disciplineDefaultChat.chatId === payload.id) {
                        disciplineDefaultChat.isMuted = payload.isMuted;
                        break;
                    }

                    const room = disciplineDefaultChat.rooms.find((x: ListChatDto) => x.chatId === payload.id);
                    if (room) {
                        room.isMuted = payload.isMuted;
                    }
                }
                break;
            }
            case ChatType.Group: {
                const groupChat = this.groupChats.find((x: ListChatDto) => x.chatId === payload.id);
                if (groupChat) {
                    groupChat.isMuted = payload.isMuted;
                }
                break;
            }
            case ChatType.Private: {
                const privateChat = this.privateChats.find((x: ListChatDto) => x.chatId === payload.id);
                if (privateChat) {
                    privateChat.isMuted = payload.isMuted;
                }
                break;
            }
            case ChatType.Discussion: {
                const discusionChat = this.discussionChats.find((x: ListChatDto) => x.chatId === payload.id);
                if (discusionChat) {
                    discusionChat.isMuted = payload.isMuted;
                }
                break;
            }
        }
    },
    removeRoomFromDisciplineChat(this: ChatStoreInterface, room: IChatRoom) {
        const chat: IDisciplineChat | undefined = this.disciplineChats.find((x: ListChatDto) => x.chatId === room.disciplineChatId);

        if (chat) {
            const index = chat.rooms.findIndex((x: ListChatDto) => x.chatId === room.chatId);

            if (index !== -1) {
                chat.rooms.splice(index, 1);
            }
        }
    },
    addArchiveRoomToDisciplineChat(this: ChatStoreInterface, room: IChatRoom) {
        const chat: IDisciplineChat | undefined = this.archiveDisciplineChats.find((x: ListChatDto) => x.chatId === room.disciplineChatId);

        if (chat) {
            const index = chat.rooms.findIndex((x: ListChatDto) => x.chatId === room.chatId);

            if (index === -1) {
                chat.rooms.push(room);
            }
        }

        archiveDiscussionChats(this, room);
    },
    unArchiveRoomFromDisciplineChat(this: ChatStoreInterface, room: IChatRoom) {
        const chat: IDisciplineChat | undefined = this.archiveDisciplineChats.find((x: ListChatDto) => x.chatId === room.disciplineChatId);

        if (chat) {
            const index = chat.rooms.findIndex((x: ListChatDto) => x.chatId === room.chatId);

            if (index !== -1) {
                chat.rooms.splice(index, 1);
            }

            if (chat.rooms.length === 0) {
                const chatIndex = this.archiveDisciplineChats.findIndex((x: IDisciplineChat) => x.chatId === chat.chatId);
                this.archiveDisciplineChats.splice(chatIndex, 1);
            }
        }

        unArchiveDiscussionChats(this, room.chatId);
    },
    // Добавление чата в конец списка архивных чатов с проверкой на существование
    addArchiveChats(this: ChatStoreInterface, chats: ListChatDto[]) {
        if (chats.length) {
            const stateChats = getArchiveChatsByType(this, chats[0].type);

            chats.forEach((chat: ListChatDto) => {
                const index = stateChats.findIndex((x: ListChatDto) => x.chatId === chat.chatId);

                if (index === -1) {
                    stateChats.push(chat);
                }

                archiveDiscussionChats(this, chat);
            });
        }
    },
    // передается чат, который мы разархивировали
    // и его надо удалить из архивных
    unArchiveChat(this: ChatStoreInterface, chat: ListChatDto) {
        const chats = getArchiveChatsByType(this, chat.type);
        const index = chats.findIndex((x: ListChatDto) => x.chatId === chat.chatId);

        if (index !== -1) {
            chats.splice(index, 1);
        }

        unArchiveDiscussionChats(this, chat.chatId);
    },

    // Добавление чата в начало списка архивных чатов с проверкой на существование
    unshiftArchiveChats(this: ChatStoreInterface, chats: IDisciplineChat[]) {
        if (chats.length) {
            const stateChats = getArchiveChatsByType(this, chats[0].type);

            chats.forEach((chat: ListChatDto) => {
                const index = stateChats.findIndex((x: ListChatDto) => x.chatId === chat.chatId);

                if (index === -1) {
                    stateChats.push(chat);
                }
            });
        }
    },
    sortAlphabetChat(this: ChatStoreInterface, section: ChatSection) {
        const chats: ListChatDto[] = getChatsBySection(this, section);

        chats.sort(function (a: ListChatDto, b: ListChatDto) {
            // Дефолтную комнату дисциплины всегда возвращаем первой
            if (a.isDefaultDisciplineChat) {
                return -1;
            } else if (b.isDefaultDisciplineChat) {
                return 1;
            }

            return a.name.localeCompare(b.name);
        });
    },

    // Добавление чата в начало списка с проверкой на существование
    unshiftChats(this: ChatStoreInterface, chats: IDisciplineChat[]) {
        if (chats.length) {
            const stateChats = getChatsByType(this, chats[0].type);

            chats.forEach((chat: ListChatDto) => {
                const index = stateChats.findIndex((x: ListChatDto) => x.chatId === chat.chatId);

                if (index === -1) {
                    stateChats.unshift(chat);
                }
            });
        }
    },

    // Удалить все чаты данной секции из стора
    removeChatsForSection(this: ChatStoreInterface, chatSection: ChatSection) {
        switch (chatSection) {
            case ChatSection.Discipline:
                this.disciplineChats = [];
                break;
            case ChatSection.Group:
                this.groupChats = [];
                break;
            case ChatSection.Discussion:
                this.discussionChats = [];
                break;
            default:
                this.privateChats = [];
        }
    },

    clearArchiveChats(this: ChatStoreInterface) {
        this.archiveDisciplineChats = [];
        this.archiveDiscussionChats = [];
        this.archiveGroupChats = [];
        this.archivePrivateChats = [];
    },

    clearAllChats(this: ChatStoreInterface) {
        this.disciplineChats = [];
        this.groupChats = [];
        this.privateChats = [];
        this.discussionChats = [];
        this.archiveDisciplineChats = [];
        this.archiveGroupChats = [];
        this.archivePrivateChats = [];
    },

    // очистить сообщения определённой секции чата
    cleanUnreadedMessageCountInSection(this: ChatStoreInterface, section: ChatSection) {
        switch (section) {
            case ChatSection.Discipline: {
                this.unreadedMessagesCount.forEach((x: ChatUnreadedMessagesCountDto) => {
                    if (x.type === ChatType.Discipline || x.type === ChatType.Solution) {
                        x.count = 0;
                    }
                });
                break;
            }
            case ChatSection.Group: {
                this.unreadedMessagesCount.forEach((x: ChatUnreadedMessagesCountDto) => {
                    if (x.type === ChatType.Group) {
                        x.count = 0;
                    }
                });
                break;
            }
            case ChatSection.Private: {
                this.unreadedMessagesCount.forEach((x: ChatUnreadedMessagesCountDto) => {
                    if (x.type === ChatType.Private) {
                        x.count = 0;
                    }
                });
                break;
            }
        }

        chatBus.emit(ChatBusEvents.OnChangeUnreadedCountMessage);
    },

    // очистить сообщения чатов дисциплины
    cleanUnreadedMessageCountInDiscipline(this: ChatStoreInterface, disciplineId: number) {
        this.unreadedMessagesCount.forEach((x: ChatUnreadedMessagesCountDto) => {
            if (x.disciplineId === disciplineId) {
                x.count = 0;
            }
        });

        chatBus.emit(ChatBusEvents.OnChangeUnreadedCountMessage);
    },

    sortAlphabetRoomInDisciplineChat(this: ChatStoreInterface, disciplineId: number) {
        const chat = this.disciplineChats.find((x: ListChatDto) => x.disciplineId === disciplineId);

        if (chat) {
            chat.rooms.sort(function (a: IChatRoom, b: IChatRoom) {
                // Дефолтную комнату дисциплины всегда возвращаем первой
                if (a.isDefaultDisciplineChat) {
                    return -1;
                } else if (b.isDefaultDisciplineChat) {
                    return 1;
                }

                return a.name > b.name ? 1 : -1;
            });
        }
    },

    sortDateRoomInDisciplineChat(this: ChatStoreInterface, disciplineId: number) {
        const chat = this.disciplineChats.find((x: ListChatDto) => x.disciplineId === disciplineId);

        if (chat) {
            chat.rooms.sort(function (a: IChatRoom, b: IChatRoom) {
                if (a.isDefaultDisciplineChat) {
                    return -1;
                } else if (b.isDefaultDisciplineChat) {
                    return 1;
                }

                if (a.lastMessageDateTime && b.lastMessageDateTime) {
                    return a.lastMessageDateTime < b.lastMessageDateTime ? 1 : -1;
                } else {
                    return 1;
                }
            });
        }
    },

    muteChatsInSectionOrDiscipline(this: ChatStoreInterface, payload: { disciplineId?: number | null, isMuted: boolean, section: ChatSection | null }) {
        if (payload.disciplineId) {
            const disciplineChat: IDisciplineChat | undefined = this.disciplineChats
                .find((x: ListChatDto) => x.disciplineId === payload.disciplineId);

            if (disciplineChat) {
                disciplineChat.isMutedDiscipline = payload.isMuted;
                disciplineChat.isMuted = payload.isMuted;
                disciplineChat.rooms.forEach((room: ListChatDto) => room.isMuted = payload.isMuted);
            }
        } else {
            switch (payload.section) {
                case ChatSection.Discipline: {
                    this.isDisciplineChatsMuted = payload.isMuted;
                    this.disciplineChats.forEach((chat: IDisciplineChat) => {
                        if (!chat.isMutedDiscipline) {
                            chat.isMuted = payload.isMuted;
                            chat.rooms.forEach((room: ListChatDto) => room.isMuted = payload.isMuted);
                        }
                    });
                    break;
                }
                case ChatSection.Group: {
                    this.isGroupChatsMuted = payload.isMuted;
                    this.groupChats.forEach((chat: ListChatDto) => chat.isMuted = payload.isMuted);
                    break;
                }
                case ChatSection.Private: {
                    this.isPrivateChatsMuted = payload.isMuted;
                    this.privateChats.forEach((chat: ListChatDto) => chat.isMuted = payload.isMuted);
                    break;
                }
            }
        }
    },

};

export default actions;
