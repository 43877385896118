﻿import LocalizationService from 'src/services/LocalizationService';
import { LocaleName } from 'src/types/LocaleName';

declare global {
    interface Number {
        cased: (this: number | null | undefined, one: string, two: string, five: string, includeNumber?: boolean) => string;
    }

    interface Date {
        toServerFormat: (this: Date, isLocal?: boolean) => string;
    }
}

/**
 * Форматирует объект Date в формат YYYY-MM-DDTHH:mm:ss (2021-08-31T05:00:00) часовой пояс UTC±0:00
 * @param date Объект даты для форматирования
 */
function convertDateToServerFormat(date: Date): string {
    const isoDate = date.toJSON();
    return isoDate.slice(0, isoDate.indexOf('.'));
}
/**
 * Форматирует объект Date в формат YYYY-MM-DDTHH:mm:ss (2021-08-31T05:00:00) в местном часовом поясе
 * @param date Объект даты для форматирования
 * @returns
 */
function convertDateToServerFormatLocal(date: Date): string {
    const utcFromLocal = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    return convertDateToServerFormat(utcFromLocal);
}

/**
 * Форматирует объект Date в формат YYYY-MM-DDTHH:mm:ss (2021-08-31T05:00:00)
 * @param isLocal Использовать ли локальный часовой пояс клиента (если false, то используется часовой пояс UTC±0:00)
 */
function toServerFormat(this: Date, isLocal: boolean = true): string {
    return isLocal ? convertDateToServerFormatLocal(this) : convertDateToServerFormat(this);
}

Date.prototype.toServerFormat = toServerFormat;

Number.prototype.cased = function (
    this: number | null | undefined,
    one: string,
    two: string,
    five: string,
    includeNumber: boolean = true
) {
    if (this === null || this === undefined) {
        return '';
    }

    const res: string = includeNumber ? this + ' ' : '';

    if (LocalizationService.getLocaleName() === LocaleName.EN) {
        if (this === 2) {
            return res + two;
        }
        if (this > 2) {
            return res + five;
        }
        return res + one;
    }

    if (Math.floor(this % 100 / 10) === 1) {
        return res + five;
    }

    if (this % 10 === 1) {
        return res + one;
    }

    if (this % 10 > 1 && this % 10 < 5) {
        return res + two;
    }

    return res + five;
};

export {};
