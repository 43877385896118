<template>
    <ui-select
        ref="selectRef"
        v-model="inputValue"
        dense
        options-dense
        :options="items"
        option-label="text"
        map-options
        :label="getLabel"
        :loading="isLoadingItems"
        :error="error"
        :error-message="errorMessage"
        :rules="rules"
        :is-server-filter="true"
        @virtual-scroll="onScrollItems"
        @filter="filterCityItems"
        @update:model-value="$emit('update:model-value', $event)"
        @on-change="onChange"
        class="auto-height-select"
    />
</template>

<script lang="ts">
    import {
        CityClient,
        CityResponseModel,
        DropDownItem,
        PagedResultOfCityResponseModel,
        ResultOf,
    } from 'src/api/ApiClient';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { computed, defineComponent, nextTick, onBeforeMount, SetupContext, watch } from 'vue';
    import useDataSelect, { componentProps } from 'components/ui/selects/DataSelect/useDataSelect';
    import { QVirtualScroll } from 'quasar';
    import { localize } from 'src/services/LocalizationService';

    export default defineComponent({
        name: 'CitySelect',

        emits: [
            'on-change',
            'on-time-zone',
            'update:model-value'
        ],

        props: {
            modelValue: componentProps.value,
            rules: componentProps.rules,
            error: componentProps.error,
            errorMessage: componentProps.errorMessage,
            isRequired: componentProps.isRequired,
            // id страны, для которой будут загружаться города
            filterByCountryId: {
                type: Number
            }
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            const {
                selectRef,
                inputValue,
                items,
                isLoadingItems,
                searchItemFilter,
                hasError,
                validate,
            } = useDataSelect(props, context as SetupContext);

            // Полная модель которая будет содержать в себе и данные по таймзоне
            let allCityModal: CityResponseModel[] = [];

            let currentPage: number = 1;
            let canLoadItems: boolean = true;

            const getLabel = computed<string>(() => {
                const isRequired = props.isRequired ? '*' : '';
                return localize('Город') + isRequired ;
            });

            // Сброс фильтров и выбор первого в списке города
            watch(() => props.filterByCountryId, () => {
                context.emit('update:model-value', null);

                currentPage = 1;

                loadData().then((values: DropDownItem[]) => {
                    updateCities(values);

                    if (props.filterByCountryId && values.length) {
                        inputValue.value = values[0];
                        onChange(values[0]);
                    } else {
                        onChange();
                    }
                });
            });

            function onChange(data?: DropDownItem): void {
                nextTick(function () {
                    const value =  data || inputValue.value;
                    context.emit('on-change', value);
                    context.emit('update:model-value',value);

                    if (value) {
                        const findTimeZona: CityResponseModel | undefined = allCityModal.find((el: CityResponseModel) => el.city.value === (value as DropDownItem).value);

                        if (findTimeZona) {
                            context.emit('on-time-zone', findTimeZona.timeZone);
                        } else {
                            context.emit('on-time-zone', {
                                id: '',
                                name: ''
                            });
                        }
                    } else {
                        context.emit('on-time-zone', {
                            id: '',
                            name: ''
                        });
                    }
                });
            }

            // Чтобы очищать список загруженных городов (из него получаем таймзону) когда вводим поисковой запрос
            function filterCityItems(val: string, update: (val: () => void) => void): void {
                allCityModal = [];
                currentPage = 1;
                searchItemFilter.value = val;

                loadData().then((values: DropDownItem[]) => {
                    update(() => {
                        items.value = values;
                        // после вызова update почему-то два раза отрабатывает скролл
                        // и вместо одного запроса идут 3
                        // поэтому обновляем флаг с небольшой задержкой
                        setTimeout(() => {
                            isLoadingItems.value = false;
                        }, 100);
                    });
                });
            }

            // Обработчик виртуального скрола
            async function onScrollItems({ to, ref }: { to: number, ref: QVirtualScroll }): Promise<void> {
                const lastIndex = items.value.length - 1;

                if (!isLoadingItems.value && canLoadItems && to === lastIndex) {
                    const loadedItems = await loadData();

                    loadedItems.forEach((el: DropDownItem) => {
                        items.value.push(el);
                    });

                    nextTick(() => {
                        ref.refresh();
                        isLoadingItems.value = false;
                    });
                }
            }

            function updateCities(values: DropDownItem[]): void {
                items.value = values;
                // после вызова update почему-то два раза отрабатывает скролл
                // и вместо одного запроса идут 3
                // поэтому обновляем флаг с небольшой задержкой
                setTimeout(() => {
                    isLoadingItems.value = false;
                }, 100);
            }

            async function loadData(): Promise<DropDownItem[]> {
                isLoadingItems.value = true;

                const result: ResultOf<PagedResultOfCityResponseModel> = await new CityClient(getApiClientInitialParams()).getCities(
                    searchItemFilter.value,
                    currentPage,
                    props.filterByCountryId
                );

                canLoadItems = result.entity.pagedMetaData.hasNextPage;

                if (canLoadItems) {
                    currentPage++;
                }

                setTimeout(() => {
                    isLoadingItems.value = false;
                }, 100);

                allCityModal.push(...result.entity.pageItems);

                return result.entity.pageItems.map((el: CityResponseModel) => {
                    return { ...el.city };
                }) || [];
            }

            onBeforeMount(() => {
                if (props.modelValue && !Array.isArray(props.modelValue) && props.modelValue.value) {
                    const value = {
                        value: props.modelValue.value,
                        text: props.modelValue.text
                    };

                    inputValue.value = value;
                    context.emit('update:model-value', value);
                }
            });

            return {
                selectRef,
                inputValue,
                isLoadingItems,
                items,
                getLabel,
                hasError,
                validate,
                localize,
                onScrollItems,
                filterCityItems,
                onChange,
            };
        }
    });
</script>
