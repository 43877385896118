<template>
    <q-scroll-area class="scroll-area">
        <q-infinite-scroll @load="onLoad" :offset="180" ref="infinityScrollRef">
            <search-group-item
                v-for="group in groups"
                :key="group.id"
                :item="group"
                @on-item-selected="onItemSelected"/>

            <template v-slot:loading>
                <div class="text-center">
                    <q-spinner-dots color="primary" size="4em"/>
                </div>
            </template>
        </q-infinite-scroll>
    </q-scroll-area>
</template>

<script lang="ts" setup>
    import { Ref, ref, toRefs } from 'vue';
    import { SearchGroupDto } from 'src/api/ApiClient';
    import { QInfiniteScroll } from 'quasar';
    import SearchGroupItem from 'layouts/Main/components/searchBlock/components/groupsTab/SearchGroupItem.vue';
    import { SearchTabs } from '../../types/searchTabs';
    import { SearchEntity } from '../../types/SearchEntity';
    import { useSearch } from '../../hooks/useSearch';

    // Компонент с бесконечной загрузкой
    // Отображается когда выбран поиск только в группах
    const props = withDefaults(defineProps<{
        searchText: string;
    }>(), {
        searchText: '',
    });
    const emit = defineEmits<{
        (e: 'on-item-selected'): void;
        (e: 'on-found-items-count', itemsCount: number): void;
        (e: 'show-all', tab: SearchTabs): void;
    }>();

    const groups: Ref<SearchGroupDto[]> = ref([]);
    // Ссылка на бесконечный скролл
    const infinityScrollRef = ref<InstanceType<typeof QInfiniteScroll>>();
    const { searchText } = toRefs(props);
    const {
        onLoad,
        onItemSelected,
    } = useSearch(SearchEntity.Groups, true, groups, searchText, emit, infinityScrollRef);
</script>
