import { LocaleName } from 'src/types/LocaleName';
import { ILocalizationStore } from './interfaces';
import localizableStrings from 'src/services/LocalizationService/localizableStrings';
import {
    getApiClientInitialParams,
    GetDataResponseModel,
    HttpStatusCode,
    LocalizationClient,
    LocalizationItemDto,
    ResultOf
} from 'src/api/ApiClient';
import { Md5 } from 'ts-md5';
import * as Sentry from '@sentry/vue';
import { useMainLayoutStore } from 'src/store/module-main-layout';

const LOCALIZATION_KEY = 'localization-resources';
// сколько часов мы храним данные в LS
const COUNT_HOURS_STORE = 24;

class Localization {
    private requestPromise: Promise<LocalizationItemDto[]> | null = null;

    private localizationItems: Record<string, LocalizationItemDto> = {};

    // Хэши строк для которых уже был сделан запрос к api на перевод
    private requestedTexts: Set<string> = new Set<string>();

    private localeName: LocaleName = LocaleName.RU;

    private readyCallbacks: Array<() => void> = [];

    private localizationStore!: IDBDatabase;

    constructor() {
        this.localeName = this.getLocaleName();
    }

    public async init(): Promise<void> {
        return new Promise((resolve: () => void, reject: () => void) => {
            const localizationStoreRequest = indexedDB.open('OdinLocalization', 1);

            localizationStoreRequest.onsuccess = async () => {
                this.localizationStore = localizationStoreRequest.result;
                await this.getData(reject);
                resolve();
            };

            localizationStoreRequest.onupgradeneeded = () => {
                this.localizationStore = localizationStoreRequest.result;
                this.localizationStore.createObjectStore(LOCALIZATION_KEY, {
                    keyPath: 'hash'
                });
                this.removeOldDatabase();
            };

            localizationStoreRequest.onerror = (event: any) => {
                console.error('IndexedDB onerror', event);
                Sentry.captureException('IndexedDB onerror', {
                    extra: {
                        message: event.target.error.toString()
                    }
                });
            };
        });
    }

    public get isReady(): boolean {
        return Object.keys(this.localizationItems).length > 0;
    }

    public getLocaleName(): LocaleName {
        const storedData = localStorage.getItem(LOCALIZATION_KEY);

        if (storedData) {
            const locData: ILocalizationStore = JSON.parse(storedData);
            return locData.currentLocale || LocaleName.RU;
        }

        return this.localeName;
    }

    public setLocaleName(locale: LocaleName): void {
        this.localeName = locale;
        const storedData = localStorage.getItem(LOCALIZATION_KEY);

        if (storedData) {
            const locData: ILocalizationStore = JSON.parse(storedData);

            const newLocData: ILocalizationStore = {
                createTime: locData.createTime,
                currentLocale: this.localeName,
            };

            localStorage.setItem(LOCALIZATION_KEY, JSON.stringify(newLocData));
        }
    }

    // Добавляем обработчики события, которые вызовутся
    // когда данные локализации будут инициализированы
    public onReady(callback: () => void): void {
        this.readyCallbacks.push(callback);
    }

    private async getData(reject: () => void): Promise<Record<string, LocalizationItemDto>> {
        let objectStore = this.getObjectStore();
        const localizationMetaString = localStorage.getItem(LOCALIZATION_KEY);
        const dbResult = await this.dbRequest(objectStore.count.bind(objectStore));
        const resourcesIsEmpty: boolean = !dbResult || dbResult.result === 0;

        if (localizationMetaString && !resourcesIsEmpty) {
            const localizationMeta: ILocalizationStore = JSON.parse(localizationMetaString);
            this.localeName = localizationMeta.currentLocale || LocaleName.RU;

            // если данные устарели, то грузим заново
            if (COUNT_HOURS_STORE * 60 * 60 * 1000 < Date.now() - localizationMeta.createTime) {
                localStorage.removeItem(LOCALIZATION_KEY);
                objectStore = this.getObjectStore();
                await this.dbRequest(objectStore.clear.bind(objectStore));
                return this.getData(reject);
            }

            const lastModifyDate = await this.getLastModifyDate(reject);

            // lastModifyDate - в секундах
            if (localizationMeta.createTime < lastModifyDate * 1000) {
                localStorage.removeItem(LOCALIZATION_KEY);
                objectStore = this.getObjectStore();
                await this.dbRequest(objectStore.clear.bind(objectStore));
                return this.getData(reject);
            }

            objectStore = this.getObjectStore();
            const data = await this.dbRequest(objectStore.getAll.bind(objectStore));

            data?.result.forEach((item: LocalizationItemDto) => {
                this.localizationItems[item.hash] = item;
            });

            this.callReadyCallbacks();
            return new Promise((resolve: (value: Record<string, LocalizationItemDto>) => void) => resolve(this.localizationItems));
        } else {
            const loadedData = await this.loadData(reject);

            for (const item of loadedData) {
                this.localizationItems[item.hash] = item;
            }

            await this.writeDataToIndexedDB(loadedData);

            const localizationMeta: ILocalizationStore = {
                createTime: Date.now(),
                currentLocale: this.localeName,
            };

            localStorage.setItem(LOCALIZATION_KEY, JSON.stringify(localizationMeta));

            this.callReadyCallbacks();
            return new Promise((resolve: (value: Record<string, LocalizationItemDto>) => void) => resolve(this.localizationItems));
        }
    }

    private async loadData(reject: () => void): Promise<LocalizationItemDto[]> {
        // kind of pattern Singleton
        // вызовов может быть несколько одновременно
        // поэтому сохраняем промис и всем его возвращаем
        // что бы был только один запрос к серверу
        // и все компоненты дожидались его
        if (!this.requestPromise) {
            this.requestPromise = new Promise(async (resolve: (value: LocalizationItemDto[]) => void) => {
                try {
                    const response: ResultOf<GetDataResponseModel> = await new LocalizationClient(getApiClientInitialParams()).getData();
                    if (response.isSuccess) {
                        resolve(response.entity.localizationData);
                    } else {
                        // Показываем окно "Что-то пошло не так" ддя всех 500-тых ошибок
                        if (response.httpStatusCode !== HttpStatusCode.OK) {
                            useMainLayoutStore().isVisibleSmthWentWrongBlock = true;
                        }
                    }
                } catch (error) {
                    this.requestPromise = null;
                    reject();
                }
            });
        }

        return this.requestPromise;
    }

    private removeOldDatabase(): void {
        try {
            indexedDB.deleteDatabase('Odin');
            indexedDB.deleteDatabase('localization');
            localStorage.removeItem('localization');
            localStorage.removeItem('localization-resources-data');
        } catch (e) {}
    }

    // Создать новую транзацицю тк предыдущая может быть закрыта из-за асинхронных операций
    private getObjectStore(): IDBObjectStore {
        const transaction = this.localizationStore.transaction(LOCALIZATION_KEY, 'readwrite');
        return transaction.objectStore(LOCALIZATION_KEY);
    }

    // Записать сразу все данные в indexedDB
    private writeDataToIndexedDB(data: LocalizationItemDto[]): Promise<void> {
        return new Promise(async (resolve: () => void) => {
            const transaction = this.localizationStore.transaction(LOCALIZATION_KEY, 'readwrite');
            const objectStore = transaction.objectStore(LOCALIZATION_KEY);
            await this.dbRequest(objectStore.clear.bind(objectStore));

            data.forEach((item: LocalizationItemDto) => {
                transaction.objectStore(LOCALIZATION_KEY).add(item);
            });

            transaction.oncomplete = resolve;
            transaction.onerror = (error: Event) => {
                console.error('Ошибка при добавлении данных локализации в indexedDB', error);
            };
        });
    }

    // Вспомогательная функция для запросов к indexedDB
    // Дело в том, что функции получения данных синхронные, но результат они не возвращают
    // результат нужно обрабатывать в колбэке onsuccess
    // И эта функция для удобства оборачивает запрос в Promise
    private async dbRequest(requestFunc: () => IDBRequest): Promise<IDBRequest | null> {
        return new Promise((resolve: (data: IDBRequest | null) => void) => {
            const request = requestFunc();
            request.onsuccess = () => {
                resolve(request);
            };
            request.onerror = (event: any) => {
                resolve(null);
                console.error('Ошибка выполнения запроса к indexedDB', event);
                Sentry.captureException('Ошибка выполнения запроса к indexedDB', {
                    extra: {
                        message: event.target.error.toString()
                    }
                });
            };
        });
    }

    private callReadyCallbacks(): void {
        this.readyCallbacks.forEach((callback: () => void, index: number) => {
            callback();
            this.readyCallbacks.splice(index, 1);
        });
    }

    private async getLastModifyDate(reject: () => void): Promise<number> {
        return new Promise(async (resolve: (value: number) => void) => {
            try {
                const response: ResultOf<number> = await new LocalizationClient(getApiClientInitialParams()).getLastModified();
                if (response.httpStatusCode === 200) {
                    resolve(response.entity);
                } else {
                    // Показываем окно "Что-то пошло не так" ддя всех 500-тых ошибок
                    if (String(response.httpStatusCode)[0] === '5') {
                        useMainLayoutStore().isVisibleSmthWentWrongBlock = true;
                    } else {
                        // В случае ошибки проставляем текущее время и грузим переводы ODIN-8824
                        resolve(Math.round(Date.now() / 1000));
                    }
                }
            } catch (e) {
                reject();
            }
        });
    }

    private async addNewText(text: string, hash: string): Promise<void> {
        // чтобы не было много запросов для одной фразы, проверяем
        // был ли для неё запрос или нет
        if (this.requestedTexts.has(hash)) {
            return;
        }

        this.requestedTexts.add(hash);

        const response: ResultOf<LocalizationItemDto> = await new LocalizationClient(getApiClientInitialParams()).addNewText(text);

        if (response.isSuccess) {
            this.localizationItems[response.entity.hash] = response.entity;
            const objectStore = this.getObjectStore();
            objectStore.add(response.entity);
        }
    }

    private formatWith(text: string, formatterArguments: Record<string, string | number> | null = null): string {
        if (typeof formatterArguments !== 'object' || formatterArguments == null) {
            return text;
        }

        for (const key in formatterArguments) {
            if (formatterArguments.hasOwnProperty(key) && formatterArguments[key]) {
                text = text.replace(new RegExp('{' + key + '}', 'g'), formatterArguments[key].toString());
            }
        }

        return text;
    }

    localize(text: string, formatterArguments: Record<string, string | number> | null = null): string {
        if (!this.isReady) {
            // сохраняем данные, чтобы каждый раз не лазить в localStorage
            const objectStore = this.getObjectStore();

            this.dbRequest(objectStore.getAll.bind(objectStore)).then((data: IDBRequest | null) => {
                data?.result.forEach((item: LocalizationItemDto) => {
                    this.localizationItems[item.hash] = item;
                });
            });

            // Из-за того, что операция считывания из хранилища является асинхронной
            // приходится делать костыль на случай, если оно ещё пустое, т.к. если сейчас сделать метод ассинхронным,
            // то придется менять абсолютно во всем проекте вызов метода translate
            return text;
        }

        const hash = Md5.hashStr(text);
        const resourceItem = this.localizationItems[hash];

        if (!resourceItem) {
            this.addNewText(text, hash);
        }

        const result = resourceItem == null || this.localeName === LocaleName.RU ? text : resourceItem.en;
        return this.formatWith(result, formatterArguments);
    }
}

const LocalizationService = new Localization();

export default LocalizationService;

// Пока не удаляем параметр scope, т.к. получится слишком много изменений. Лучше выпилить его отдельным PR
export function localize(text: string, formatterArguments: Record<string, string | number> | null = null): string {
    return LocalizationService.localize(text, formatterArguments);
}

// Перевести строку, но данные брать не из стора, а из массива localizableStrings
export function translateFromLocalizableStrings(text: string): string {
    const translateItem = localizableStrings.find((x: LocalizationItemDto) => x.origin === text);

    if (translateItem) {
        if (LocalizationService.getLocaleName() === LocaleName.RU) {
            return translateItem.origin;
        } else {
            return translateItem.en;
        }
    } else {
        return text;
    }
}
