// Список цветов для дефолтных аватаров
export enum CustomAvatarColors {
    Discipline = '#00BFA5',
    University = '#FF6D00',
    Division = '#F5009F',
    User = '#0081E3',
    GroupChat = '#00C853',
    Program = '#AA00FF',
    Cohort = '#F1CB00'
}
